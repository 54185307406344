import React from 'react';
import { Alert } from 'antd';
const parseMessage = (message) => {
    if (!message) {
        return;
    }
    if (typeof message == 'string') {
        return message;
    }
    if (Array.isArray(message)) {
        return parseArray(message);
    }
    if (typeof message === 'object') {
        return parseObject(message);
    }
    return message;
};
const parseArray = (messages) => {
    return messages.map(message => {
        return parseMessage(message);
    });
};
const parseObject = (messages) => {
    return Object.keys(messages).map(key => {
        return React.createElement(ErrorItem, { key: key, field: key, label: messages[key] });
    });
};
const ErrorItem = ({ field, label }) => {
    if (!field || field === 'non_field_errors' || field === 'detail') {
        return React.createElement("span", null,
            " ",
            label);
    }
    field = field.replace(/_/g, ' ');
    return (React.createElement("span", null,
        React.createElement("span", { style: { fontSize: '300' } }, field),
        " : ",
        label,
        " \u00A0"));
};
const ErrorRender = ({ error }) => {
    if (!error) {
        return null;
    }
    if (!error.response) {
        return React.createElement(Alert, { message: JSON.stringify(error.message), type: "error", showIcon: true });
    }
    if (error.response.status === 400) {
        const items = Object.keys(error.response.data).map(key => {
            const message = parseMessage(error?.response?.data[key]);
            return (React.createElement("li", { key: key },
                React.createElement(ErrorItem, { field: key, label: message })));
        });
        return React.createElement(Alert, { message: React.createElement("ul", null, items), type: "error", showIcon: true });
    }
    if (error.response.status === 401) {
        return React.createElement(Alert, { message: "Not Authorized", type: "error", showIcon: true });
    }
    if (error.response.status === 403) {
        return React.createElement(Alert, { message: "Access denied", type: "error", showIcon: true });
    }
    if (error.response.status === 404) {
        return React.createElement(Alert, { message: "Not found", type: "error", showIcon: true });
    }
    if (error.response.data.detail) {
        return React.createElement(Alert, { message: JSON.stringify(error.response.data.detail), type: "error", showIcon: true });
    }
    return null;
};
export default ErrorRender;
