import React from 'react';
const Pill = ({ label, checked, onClick }) => {
    let pillStyles = {
        border: '1px solid #1da1f2',
        borderRadius: '24px',
        color: '#1c94e0',
        padding: 10,
        margin: '5px',
        cursor: 'pointer',
        backgroundColor: '#fff',
    };
    if (checked) {
        pillStyles = {
            ...pillStyles,
            backgroundColor: '#1c94e0',
            color: '#fff',
        };
    }
    return (React.createElement("span", { style: pillStyles, onClick: onClick }, label));
};
export default Pill;
