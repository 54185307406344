import React, { useEffect } from 'react';
import { Button, Table } from 'antd';
import TemplatesStore from '../../components/Templates/Stores/TemplatesStore';
import { ColumnsType } from 'antd/es/table';
import { ITemplate } from '../../components/Templates/types';
import { Link, useHistory } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

//Templates
import { BadgerPage, BadgerSearch } from '../../components/badger-ui';

const TemplatesListPage = () => {
    const history = useHistory();

    useEffect(() => {
        TemplatesStore.getAll();
        return () => {};
    }, []);

    const columns: ColumnsType<ITemplate> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Theme',
            dataIndex: 'theme',
            key: 'theme',
        },
        {
            title: 'Model',
            dataIndex: 'model_name',
            key: 'model_name',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                TemplatesStore.setTemplate(record);
                return (
                    <div>
                        <Link to={`/templates/${record.id}/`}>Edit</Link>
                    </div>
                );
            },
        },
    ];

    return (
        <BadgerPage
            title={'Templates'}
            topActions={[
                <Button key={'new'} type={'primary'} onClick={() => history.push('/templates/new/')}>
                    New Template
                </Button>,
            ]}
        >
            <Table<ITemplate> rowKey={'id'} columns={columns} dataSource={TemplatesStore.AllTemplates} />
        </BadgerPage>
    );
};
export default observer(TemplatesListPage);
