import React, { useState } from 'react';
import { Button, Radio } from 'antd';
import { AddressForm, AddressItem } from '../Addresses';
import { observer } from 'mobx-react';
import ContactStore from './stores/ContactStore';
import { RightOutlined } from '@ant-design/icons';
const AddressEdit = observer((props) => {
    const [isChangingOfAddress, setIsChangingOfAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState();
    const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
    const [editBillingAddress, setEditBillingAddress] = useState();
    const [addressList, setAddressList] = useState();
    const updateOrderBillingAddress = () => {
        if (selectedAddress) {
            const payload = {
                name: selectedAddress.name ?? '',
                street: selectedAddress.street ?? '',
                city: selectedAddress.city ?? '',
                state: selectedAddress.state ?? '',
                country: selectedAddress.country?.code ?? '',
                zip_code: selectedAddress.zip_code ?? '',
                type: selectedAddress.type ?? '',
            };
            props.onSelectAddress(payload);
        }
    };
    const handleSaveAddress = (payload, address_id) => {
        if (address_id) {
            ContactStore.patchAddress(props.contact_id, address_id, payload).then(() => {
                ContactStore.getContactById(props.contact_id).then(() => {
                    setAddressList(ContactStore?.addresses);
                });
                props.onSelectAddress({
                    name: payload.name ?? '',
                    street: payload.street ?? '',
                    city: payload.city ?? '',
                    state: payload.state ?? '',
                    country: payload.country ?? '',
                    zip_code: payload.zip_code ?? '',
                    type: payload?.type ?? '',
                });
                setIsChangingOfAddress(false);
            });
        }
        else {
            ContactStore.saveAddress(props.contact_id, payload).then(() => {
                ContactStore.getContactById(props.contact_id).then(() => {
                    setAddressList(ContactStore?.addresses);
                });
                props.onSelectAddress({
                    name: payload.name ?? '',
                    street: payload.street ?? '',
                    city: payload.city ?? '',
                    state: payload.state ?? '',
                    country: payload.country ?? '',
                    zip_code: payload.zip_code ?? '',
                    type: payload?.type ?? '',
                });
                setIsChangingOfAddress(false);
            });
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            isChangingOfAddress ? (React.createElement("div", null,
                addressList && addressList.length > 0 && (React.createElement("div", { className: "font-thin" }, "Select a an address below")),
                addressList
                    ?.filter(t => t.type == props.type || t.type === 'Billing and Shipping')
                    .map((address) => {
                    return (React.createElement("div", { className: "py-2 w-full", key: address.id },
                        React.createElement("div", { className: "flex flex-col justify-center p-4 space-y-2", style: {
                                border: 'solid 1px #d9d9d9',
                            } },
                            React.createElement("div", { className: "flex flex-row content-center", onClick: () => setSelectedAddress(address), style: { cursor: 'pointer' } },
                                React.createElement("div", { className: "flex flex-wrap content-center" },
                                    React.createElement(Radio, { name: "address", checked: selectedAddress && selectedAddress.id === address.id })),
                                React.createElement("div", { className: "flex flex-col" },
                                    React.createElement("div", null,
                                        React.createElement("strong", null, address.name)),
                                    React.createElement("div", null,
                                        address.street,
                                        ", ",
                                        address.city,
                                        ","),
                                    React.createElement("div", null,
                                        address.state,
                                        " ",
                                        address.zip_code,
                                        ' ',
                                        address.country && address.country.code))),
                            selectedAddress && selectedAddress.id === address.id && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "text-center" },
                                    React.createElement(Button, { className: "w-full lg:w-1/2", type: "primary", onClick: () => {
                                            updateOrderBillingAddress();
                                            setIsChangingOfAddress(false);
                                        } }, "Use this address")),
                                React.createElement("div", { className: "text-center" },
                                    React.createElement(Button, { className: "w-full lg:w-1/2", type: "default", onClick: () => {
                                            setEditBillingAddress(address);
                                            setIsChangingOfAddress(false);
                                            setIsAddingNewAddress(true);
                                        } }, "Edit Address")))))));
                }),
                React.createElement("div", { className: "flex flex-row w-full justify-between p-2", style: {
                        border: 'solid 1px #d9d9d9',
                        cursor: 'pointer',
                    }, onClick: () => {
                        setEditBillingAddress(undefined);
                        setIsChangingOfAddress(false);
                        setIsAddingNewAddress(true);
                    } },
                    React.createElement(Button, { type: "link" }, "Add a New Address"),
                    React.createElement(RightOutlined, { className: "p-2" })))) : (React.createElement("div", { className: 'flex flex-row justify-between items-center' }, !props.currentAddress?.name && props.addresses && props.addresses.length === 0 ? (React.createElement("div", { className: "flex flex-row w-full justify-between p-2", style: {
                    border: 'solid 1px #d9d9d9',
                    cursor: 'pointer',
                }, onClick: () => {
                    setEditBillingAddress(undefined);
                    setIsChangingOfAddress(false);
                    setIsAddingNewAddress(true);
                } },
                React.createElement(Button, { type: "link" }, "Add a New Address"),
                React.createElement(RightOutlined, { className: "p-2" }))) : (React.createElement("div", { className: "flex flex-row justify-between items-center w-full" },
                props.currentAddress && React.createElement(AddressItem, { address: props.currentAddress }),
                React.createElement(Button, { type: 'link', onClick: () => {
                        !addressList && setAddressList(props.addresses);
                        setIsChangingOfAddress(true);
                    }, className: "mt-1", style: { width: 150 } }, "Change Address"))))),
            isAddingNewAddress && (React.createElement("div", { className: "py-2" },
                React.createElement(AddressForm, { defaultAddressName: props.account_name, addressType: props.type, address: editBillingAddress, handleSave: async (payload) => {
                        handleSaveAddress(payload, editBillingAddress && editBillingAddress.id);
                        setIsAddingNewAddress(false);
                    }, handleClose: () => {
                        setIsChangingOfAddress(true);
                        setIsAddingNewAddress(false);
                    } }))))));
});
export default AddressEdit;
