import React from 'react';
import { Comment, Typography } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
const { Text } = Typography;
export const NotesListCard = ({ sales_order_notes }) => {
    return (React.createElement(React.Fragment, null, sales_order_notes?.map(note => {
        return (React.createElement(React.Fragment, null,
            React.createElement(Comment, { key: note.id, author: note.created_by?.fullname, avatar: React.createElement(FileSearchOutlined, null), content: React.createElement("p", null,
                    React.createElement("b", null,
                        note.title,
                        ": "),
                    note.content), datetime: React.createElement(Text, null, dayjs(note.created_time).format('LLLL')) })));
    })));
};
