import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { WarningOutlined } from '@ant-design/icons';
const DrawingFiles = ({ master_product, showAddDrawingFile, removeFile, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("strong", null, "2D File:"),
        " \u00A0",
        master_product?.drawing_file ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: 'link', onClick: e => {
                    e.stopPropagation();
                    const width = 960;
                    const height = 720;
                    const left = window.screen.width - width;
                    const external_window = window.open(`/drawing-viewer/${master_product.drawing_file.id}/?drawing_file_id=${master_product.drawing_file.id}`, '', `width=${width},height=${height},left=${left}`);
                    external_window.document.title = 'Drawing File Viewer';
                } }, master_product.drawing_file.filename),
            ' ',
            master_product.analysis_status.file_2d.status == 'FAILURE' && (React.createElement(React.Fragment, null,
                React.createElement(WarningOutlined, { style: {
                        color: 'red',
                    }, title: master_product.analysis_status.file_2d.message != null &&
                        typeof master_product.analysis_status.file_2d.message === 'string'
                        ? master_product.analysis_status.file_2d.message
                        : 'Error analyzing the file' }))),
            "\u00A0",
            React.createElement("span", { className: 'link', onClick: e => {
                    e.stopPropagation();
                    window.open(master_product.drawing_file.file, '_blank');
                } },
                React.createElement(LegacyIcon, { title: 'Download 2D file', type: 'download' }),
                ' '),
            ' ',
            "\u00A0",
            master_product.editable && (React.createElement(React.Fragment, null,
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: showAddDrawingFile }),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Remove 2D file', className: 'link', type: 'close', style: { color: 'red' }, onClick: removeFile }),
                "\u00A0")))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { style: { color: 'red' } }, " Pending"),
            " \u00A0",
            React.createElement("span", { title: 'Add 2D file', className: 'link' },
                React.createElement(LegacyIcon, { type: 'plus', onClick: showAddDrawingFile }))))));
};
export default DrawingFiles;
