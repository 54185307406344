import React from 'react';
import { Divider } from 'antd';
import { in_to_mm, mm_to_in } from '@partsbadger/utils';
export const PartDimensions = ({ length = 0, width = 0, height = 0, unit = 'MM' }) => {
    if (!length || !width || !height) {
        return null;
    }
    const original = `${length}${unit} x ${width}${unit} x ${height}${unit}`;
    let converted = '';
    if (unit === 'MM') {
        converted = `${mm_to_in(length)}IN x ${mm_to_in(width)}IN x ${mm_to_in(height)}IN`;
    }
    else {
        converted = `${in_to_mm(length)}MM x ${in_to_mm(width)}MM x ${in_to_mm(height)}MM`;
    }
    return (React.createElement("span", { style: {
            fontSize: '12px',
            textTransform: 'lowercase',
            fontWeight: 400,
        } },
        original,
        React.createElement(Divider, { type: 'vertical' }),
        converted));
};
