import { MailOutlined, MinusCircleOutlined, PlusCircleOutlined, PrinterOutlined, SaveOutlined, } from '@ant-design/icons';
import { ErrorRender, FormErrorParser, ShippingCarriersPurchasesOrders, ShippingMethodsPurchasesOrders, } from '@partsbadger/library';
import { Alert, Button, Card, Checkbox, Col, Form, Input, InputNumber, notification, Popconfirm, Radio, Row, Skeleton, Tag, Select, } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { DownloadFile, get, patch, post } from '@partsbadger/utils';
import { forEach } from 'lodash';
import axios from 'axios';
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
    },
};
const CARRIER_WITH_API_INTEGRATION = ['FedEX', 'UPS', 'USPS', 'DHL'];
const Shipment = observer((props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingTemplates, setIsLoadingTemplate] = React.useState(false);
    const [isGettingRates, setIsGettingRates] = React.useState(false);
    const [isCreatingShipment, setIsCreatingShipment] = React.useState(false);
    const [isPrinting, setIsPrinting] = React.useState(false);
    const [form] = Form.useForm();
    const [box, setBox] = React.useState(null);
    const [packageType, setPackageType] = React.useState([]);
    const [packageTypeByCarrier, setPackageTypeByCarrier] = React.useState([]);
    const [selectedPackageType, setSelectedPackageType] = React.useState(null);
    const [shippingRates, setShippingRates] = React.useState({
        rates: [],
        messages: [],
    });
    const [hasApiIntegration, setHasApiIntegration] = React.useState('unknown');
    const handleChange = (value) => {
        if (packageTypeByCarrier && packageTypeByCarrier.length > 0) {
            const foundPackageTypeByCarrier = packageTypeByCarrier.find(pkg => pkg.token === value);
            if (foundPackageTypeByCarrier) {
                setSelectedPackageType(foundPackageTypeByCarrier);
            }
            else {
                setSelectedPackageType(null);
            }
        }
        else {
            console.warn('Package type data is not available');
            setSelectedPackageType(null);
        }
    };
    const getPackageByCarrier = (value) => {
        if (packageType && packageType.length > 0) {
            const foundPackageType = packageType.filter(pkg => pkg.carrier.toLowerCase() === value.toLowerCase());
            if (foundPackageType.length > 0) {
                setPackageTypeByCarrier(foundPackageType);
            }
            else {
                setPackageTypeByCarrier([]);
            }
        }
        else {
            console.warn('Package type data is not available');
            setPackageTypeByCarrier([]);
        }
    };
    const handleChangeCarrier = (value) => {
        getPackageByCarrier(value);
        setSelectedPackageType(null);
        form.setFieldsValue({ template: null, carrier_one_rate: false });
        cleanRates();
    };
    const cleanRates = () => {
        setShippingRates({
            rates: [],
            messages: [],
        });
        setHasApiIntegration('unknown');
    };
    const getShipment = async () => {
        await get('/staff/outtakes/' + props.outtakeId + '/shipment/')
            .then(data => {
            setBox(data);
            if (data.carrier) {
                getPackageType();
            }
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error getting shipment',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const getPackageType = async () => {
        await get('/staff/outtakes/package-types/')
            .then(data => {
            if (data) {
                setIsLoadingTemplate(true);
                setPackageType(data);
                setIsLoadingTemplate(false);
            }
            else {
                setPackageType([]);
            }
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error getting shipment',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const updateShipment = async (payload) => {
        cleanRates();
        await patch('/staff/outtakes/' + props.outtakeId + '/update-shipment/', payload)
            .then(data => {
            setBox(data.box);
            setShippingRates(data.shipping_rates);
            setHasApiIntegration(data.has_api_integration);
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error updating shipment',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const createShipment = async (rate_object_id) => {
        setIsCreatingShipment(true);
        await post('/staff/outtakes/' + props.outtakeId + '/create-shipment/', {
            rate_object_id: rate_object_id,
        })
            .then(data => {
            setBox(data);
            cleanRates();
            notification.success({
                duration: 8,
                message: 'Shipment sent to carrier',
                description: 'Shipping label created',
            });
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error updating shipment',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        })
            .finally(() => {
            setIsCreatingShipment(false);
        });
    };
    const createManualShipment = async () => {
        setIsCreatingShipment(true);
        await post('/staff/outtakes/' + props.outtakeId + '/complete-shipment/', {})
            .then(data => {
            setBox(data);
            cleanRates();
            notification.success({
                duration: 8,
                message: "Shipment marked as 'Shipped'",
                description: 'The shipment has been marked as shipped',
            });
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error updating shipment',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        })
            .finally(() => {
            setIsCreatingShipment(false);
        });
    };
    const cancelShipment = async () => {
        setIsCreatingShipment(true);
        await post('/staff/outtakes/' + props.outtakeId + '/cancel-shipment/')
            .then(data => {
            setBox(data);
            notification.success({
                duration: 8,
                message: 'Shipment canceled',
                description: 'Shipment canceled',
            });
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error canceling shipment, please try again or contact support',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        })
            .finally(() => {
            setIsCreatingShipment(false);
        });
    };
    const sendCustomerNotification = async () => {
        setIsCreatingShipment(true);
        await post('/staff/outtakes/' + props.outtakeId + '/send-customer-notification/')
            .then(() => {
            notification.success({
                duration: 8,
                message: 'Notification sent',
                description: 'Notification sent to customer',
            });
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error canceling shipment, please try again or contact support',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        })
            .finally(() => {
            setIsCreatingShipment(false);
        });
    };
    const deleteShipment = async () => {
        setIsCreatingShipment(true);
        await post('/staff/outtakes/' + props.outtakeId + '/delete-shipment/')
            .then(() => {
            props.handleClose();
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error deleting shipment, please try again or contact support',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        })
            .finally(() => {
            setIsCreatingShipment(false);
        });
    };
    const printLabel = async () => {
        setIsPrinting(true);
        await post('/staff/outtakes/' + props.outtakeId + '/print-label/')
            .then(() => {
            notification.success({
                key: 'form-success',
                duration: 8,
                message: 'Label printed successfully',
                placement: 'top',
                maxCount: 1,
            });
        })
            .catch(err => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Error printing label, please try again or contact support',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'top',
                maxCount: 1,
            });
        })
            .finally(() => {
            setIsPrinting(false);
        });
    };
    const downloadLabels = async () => {
        forEach(box?.packages, async (p) => {
            if (p.label?.file) {
                const response = await axios.get(p.label.file, { responseType: 'blob' });
                DownloadFile(response.data, `label-${p.id}.zpl`);
            }
        });
    };
    useEffect(() => {
        if (packageType.length > 0 && box?.carrier) {
            getPackageByCarrier(box?.carrier);
        }
    }, [packageType]);
    useEffect(() => {
        if (props.outtakeId) {
            setIsLoading(true);
            getShipment().then(() => {
                setIsLoading(false);
            });
            setIsLoading(true);
        }
    }, [props.outtakeId]);
    if (!box) {
        return React.createElement(Skeleton, null);
    }
    const PackageDetails = () => (React.createElement(Form.List, { name: "packages" }, (fields, { add, remove }, { errors }) => (React.createElement("div", { className: 'flex flex-col w-full' },
        fields.map((field, _index) => (React.createElement(Row, { key: `index-${_index}` },
            React.createElement(Form.Item, { labelAlign: 'left', label: _index == 0 ? 'Packages Information' : '', required: true, style: {
                    marginBottom: 0,
                } },
                React.createElement("div", { className: 'flex md:flex-row flex-col' },
                    React.createElement(Form.Item, { labelAlign: 'left', ...formItemLayout, ...field, name: [field.name, 'package_length'], rules: [
                            {
                                required: true,
                                message: 'Length is required',
                            },
                            {
                                type: 'number',
                                max: selectedPackageType
                                    ? Number(selectedPackageType.length)
                                    : undefined,
                                message: `Length cannot exceed ${selectedPackageType?.length} IN`,
                            },
                        ], style: {
                            marginBottom: 0,
                        } },
                        React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Length (IN)', tabIndex: parseInt(`${field.key}` + '1') })),
                    React.createElement(Form.Item, { labelAlign: 'left', ...formItemLayout, ...field, name: [field.name, 'package_width'], rules: [
                            {
                                required: true,
                                message: 'Width is required',
                            },
                            {
                                type: 'number',
                                max: selectedPackageType
                                    ? Number(selectedPackageType.width)
                                    : undefined,
                                message: `Width cannot exceed ${selectedPackageType?.width} IN`,
                            },
                        ], style: {
                            marginBottom: 0,
                        } },
                        React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Width (IN)', tabIndex: parseInt(`${field.key}` + '2') })),
                    React.createElement(Form.Item, { labelAlign: 'left', ...formItemLayout, ...field, name: [field.name, 'package_height'], rules: [
                            {
                                required: true,
                                message: 'Height is required',
                            },
                            {
                                type: 'number',
                                max: selectedPackageType
                                    ? Number(selectedPackageType.height)
                                    : undefined,
                                message: `Height cannot exceed ${selectedPackageType?.height} IN`,
                            },
                        ], style: {
                            marginBottom: 0,
                        } },
                        React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Height (IN)', tabIndex: parseInt(`${field.key}` + '3') })),
                    React.createElement(Form.Item, { labelAlign: 'left', ...formItemLayout, ...field, name: [field.name, 'package_weight'], rules: [
                            {
                                required: true,
                                message: 'Weight is required',
                            },
                        ], style: {
                            marginBottom: 0,
                        } },
                        React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'Weight (LB)', tabIndex: parseInt(`${field.key}` + '4') })),
                    React.createElement(Form.Item, { labelAlign: 'left', ...formItemLayout, ...field, name: [field.name, 'id'], style: {
                            marginBottom: 0,
                        }, hidden: true },
                        React.createElement(InputNumber, { style: { width: '90%' }, placeholder: 'ID', tabIndex: -1 })),
                    React.createElement(Form.Item
                    // label={'Actions'}
                    , { 
                        // label={'Actions'}
                        labelAlign: 'left', ...formItemLayout, style: {
                            marginBottom: 0,
                        } }, box.status != 'Shipped' && (React.createElement("div", { className: 'text-center' },
                        React.createElement(MinusCircleOutlined, { style: {
                                color: 'red',
                            }, className: "dynamic-delete-button", onClick: () => remove(field.name), tabIndex: -1 }))))))))),
        React.createElement(Row, null,
            React.createElement(Col, { span: 24, style: {
                // backgroundColor: '#f0f2f5',
                } },
                React.createElement(Form.Item, null,
                    React.createElement(Button, { tabIndex: -1, type: "dashed", style: {
                            width: '100%',
                            marginTop: '20px',
                            color: 'green',
                            borderColor: 'green',
                        }, onClick: () => {
                            add({
                                package_length: undefined,
                                package_width: undefined,
                                package_height: undefined,
                                package_weight: undefined,
                            });
                        }, icon: React.createElement(PlusCircleOutlined, null) }, "Add Box"),
                    React.createElement(Form.ErrorList, { errors: errors }))))))));
    const ShippingRates = () => (React.createElement(Form.Item, { shouldUpdate: true }, () => (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { span: 24 },
                React.createElement("div", { style: {
                        width: '100%',
                    } },
                    !isGettingRates && shippingRates.messages.length > 0 && (React.createElement("div", { className: 'flex flex-col items-start justify-center w-full my-5' }, shippingRates.messages.map((message, index) => {
                        return (React.createElement("div", { key: message.code, className: 'mt-2' },
                            React.createElement(Alert, { key: index, message: message.text })));
                    }))),
                    !isGettingRates && hasApiIntegration == 'no' && (React.createElement("div", { className: 'flex flex-col items-start justify-center w-full my-5' },
                        React.createElement(Row, { className: 'flex flex-row items-center justify-between border  py-2 px-1 w-full' },
                            React.createElement(Col, null, box?.carrier),
                            React.createElement(Col, null,
                                React.createElement("div", { style: {
                                        textAlign: 'left',
                                    } }, "\u00A0")),
                            React.createElement(Col, null,
                                React.createElement("div", null, box?.shipping_cost)),
                            React.createElement(Col, null,
                                React.createElement(Button, { onClick: () => {
                                        createManualShipment();
                                    }, loading: isCreatingShipment }, "Submit"))))),
                    shippingRates.rates.map(rate => {
                        const isCustomerPreferred = rate.servicelevel.name == box.shipping_method;
                        return (React.createElement("div", { key: rate.object_id },
                            React.createElement(Row, { className: "flex flex-row items-center justify-between border py-2 px-1", style: { backgroundColor: isCustomerPreferred ? '#e6f7ff' : 'initial' } },
                                React.createElement("div", { className: "flex-shrink-0 w-1/5" },
                                    React.createElement("img", { src: rate.provider_image_75, alt: rate.provider })),
                                React.createElement("div", { className: "w-1/5" }, rate.servicelevel.name),
                                React.createElement("div", { className: "w-1/5" }, rate.servicelevel.terms),
                                React.createElement("div", { className: "w-1/5" }, rate.amount),
                                React.createElement("div", { className: "w-1/5 flex justify-center" },
                                    React.createElement(Button, { type: isCustomerPreferred ? 'primary' : 'default', onClick: () => createShipment(rate.object_id), loading: isCreatingShipment }, "Submit")))));
                    }))))))));
    return (React.createElement(Card, { title: React.createElement("div", { className: 'flex flex-row' },
            "Shipment:",
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(Tag, { color: box.status == 'Shipped' ? 'green' : 'yellow' }, box.status),
                box.status == 'Shipped' && React.createElement("a", null, box.tracking_number))), extra: React.createElement("div", { className: 'flex flex-row items-center justify-between' },
            box.status === 'Shipped' && (React.createElement(Button.Group, null,
                React.createElement(Popconfirm, { placement: "bottomLeft", title: 'Are you sure you want to cancel this shipment?', onConfirm: cancelShipment, okText: "Yes, Cancel Shipment", cancelText: "No", disabled: box.status !== 'Shipped' },
                    React.createElement(Button, { style: { float: 'right' } }, "Cancel Shipment")),
                React.createElement(Popconfirm, { placement: "bottomLeft", title: 'Are you sure you want to send a notification to the customer?', onConfirm: sendCustomerNotification, okText: "Yes, resend notification", cancelText: "No", disabled: box.status !== 'Shipped' },
                    React.createElement(Button, { key: 'send-customer-notification', type: 'default', style: { float: 'right' }, loading: isPrinting, icon: React.createElement(MailOutlined, null) }, "Send Customer Notification")),
                React.createElement(Button, { key: 'print', type: 'default', style: { float: 'right' }, onClick: downloadLabels, loading: isPrinting, icon: React.createElement(PrinterOutlined, null), disabled: box.status !== 'Shipped' }, "Download Labels"),
                React.createElement(Button, { key: 'print', type: 'primary', style: { float: 'right' }, onClick: printLabel, loading: isPrinting, icon: React.createElement(PrinterOutlined, null) }, "Print Label"))),
            box.status !== 'Shipped' && (React.createElement(React.Fragment, null,
                React.createElement(Popconfirm, { placement: "bottomLeft", title: 'Are you sure you want to delete this shipment?', onConfirm: deleteShipment, okText: "Yes, Delete Shipment", cancelText: "No" },
                    React.createElement(Button, { danger: true, style: { float: 'right' } }, "Delete Shipment?"))))) },
        React.createElement(Form, { title: box.status, disabled: box.status === 'Shipped', form: form, ...formItemLayout, initialValues: {
                carrier: box.carrier,
                shipping_method: box.shipping_method,
                shipping_account: box.shipping_account,
                notes: box.notes,
                internal_notes: box.internal_notes,
                shipping_cost: box.shipping_cost,
                late_shipping_cost: box.late_shipping_cost,
                is_late_shipment: box.is_late_shipment,
                account_type: box.account_type,
                tracking_number: box.tracking_number,
                bill_to_customer: box.bill_to_customer,
                boxes_included: box.boxes_included,
                notification_opt_out: box.notification_opt_out,
                template: box.template || null,
                carrier_one_rate: !!box.template,
                packages: box.packages.map(p => {
                    return {
                        id: p.id,
                        package_height: p.package_height,
                        package_length: p.package_length,
                        package_weight: p.package_weight,
                        package_width: p.package_width,
                        label: p.label?.file,
                    };
                }),
            }, onFinish: values => {
                const payload = {
                    carrier: values.carrier,
                    shipping_method: values.shipping_method,
                    shipping_account: values.shipping_account,
                    notes: values.notes,
                    internal_notes: values.internal_notes,
                    is_late_shipment: values.is_late_shipment,
                    bill_to_customer: values.bill_to_customer,
                    opt_out_packing_slip: values.opt_out_packing_slip,
                    template: values.template || '',
                    packages: values.packages,
                    notification_opt_out: values.notification_opt_out,
                    account_type: values.account_type,
                };
                if (values.boxes_included) {
                    payload.boxes_included = values.boxes_included;
                }
                if (values.shipping_cost) {
                    payload.shipping_cost = values.shipping_cost;
                }
                if (values.tracking_number) {
                    payload.tracking_number = values.tracking_number;
                }
                setIsLoading(true);
                updateShipment(payload).then(() => {
                    setIsLoading(false);
                });
            }, onFinishFailed: errorInfo => {
                // Filter errors to exclude those containing “cannot exceed”.
                const filteredErrors = errorInfo.errorFields.filter(errorField => {
                    return !errorField.errors.some(error => error.toLowerCase().includes('cannot exceed'));
                });
                // Show notification only if there are filtered errors
                if (filteredErrors.length > 0) {
                    notification.error({
                        key: 'form-errors',
                        duration: 8,
                        message: 'Please input all required fields',
                        description: React.createElement(FormErrorParser, { isAntd4: true, errors: filteredErrors }),
                        placement: 'top',
                        maxCount: 1,
                    });
                }
            }, onValuesChange: () => {
                // cleanRates();
            } },
            React.createElement(Row, null,
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: 'Carrier', name: 'carrier' },
                        React.createElement(ShippingCarriersPurchasesOrders, { tabIndex: -1, onChange: handleChangeCarrier }))),
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: "Shipping Method", name: 'shipping_method' },
                        React.createElement(ShippingMethodsPurchasesOrders, { tabIndex: -1 }))),
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement("div", { style: {
                            maxWidth: '80%',
                            margin: '0 auto',
                        } },
                        React.createElement(Form.Item, { labelAlign: 'left', label: "Shipping Account", name: 'account_type' },
                            React.createElement(Radio.Group, null,
                                React.createElement(Radio, { value: 'customer', tabIndex: -1 }, "Customer"),
                                React.createElement(Radio, { value: 'pb', tabIndex: -1 }, "Parts Badger"))))),
                React.createElement(Form.Item, { dependencies: ['account_type'], noStyle: true }, ({ getFieldValue }) => {
                    return (getFieldValue('account_type') === 'customer' && (React.createElement(Col, { md: 24, lg: 6 },
                        React.createElement(Form.Item, { labelAlign: 'left', label: "Account Number", name: 'shipping_account', initialValue: box.shipping_account, ...formItemLayout },
                            React.createElement(Input, { style: {
                                    width: '90%',
                                }, tabIndex: -1 })))));
                }),
                React.createElement(Form.Item, { dependencies: ['account_type'], noStyle: true }, ({ getFieldValue }) => {
                    return (getFieldValue('account_type') === 'pb' && (React.createElement(Col, { md: 24, lg: 6 },
                        React.createElement(Form.Item, { labelAlign: 'left', label: "Billing To", name: 'bill_to_customer', style: {
                                paddingLeft: '10px',
                            }, ...formItemLayout },
                            React.createElement(Radio.Group, null,
                                React.createElement(Radio, { value: true, tabIndex: -1 }, "Customer"),
                                React.createElement(Radio, { value: false, tabIndex: -1 }, "Parts Badger"))))));
                })),
            React.createElement(Row, { className: 'shadow p-2' },
                React.createElement(Form.Item, { dependencies: ['carrier', 'carrier_one_rate', 'template'], noStyle: true }, ({ getFieldValue }) => {
                    return CARRIER_WITH_API_INTEGRATION.includes(getFieldValue('carrier')) ? (React.createElement(React.Fragment, null,
                        packageTypeByCarrier.length > 0 && (React.createElement(React.Fragment, null,
                            React.createElement(Form.Item, { dependencies: ['carrier'], noStyle: true }, ({ getFieldValue }) => {
                                return (CARRIER_WITH_API_INTEGRATION.includes(getFieldValue('carrier')) && (React.createElement(Form.Item, { shouldUpdate: true }, () => (React.createElement(Form.Item, { name: 'carrier_one_rate', valuePropName: 'checked' },
                                    React.createElement(Checkbox, { style: { width: 300 }, onChange: e => {
                                            if (!e.target.checked) {
                                                form.setFieldsValue({
                                                    template: null,
                                                });
                                                setSelectedPackageType(null);
                                                cleanRates();
                                            }
                                        } },
                                        getFieldValue('carrier'),
                                        " One Rate"))))));
                            }),
                            React.createElement(Form.Item, { dependencies: ['carrier_one_rate', 'carrier'], noStyle: true }, ({ getFieldValue }) => {
                                return (CARRIER_WITH_API_INTEGRATION.includes(getFieldValue('carrier')) &&
                                    getFieldValue('carrier_one_rate') && (React.createElement(Form.Item, { shouldUpdate: true }, () => (React.createElement(Form.Item, { labelAlign: "left", name: 'template', rules: [
                                        {
                                            required: true,
                                            message: 'Select One Rate',
                                        },
                                    ], style: { marginBottom: 0 } },
                                    React.createElement(Select, { defaultValue: "" // Default Value
                                        , style: { width: 400 }, placeholder: 'One Rate', onChange: handleChange, loading: isLoadingTemplates, disabled: isLoadingTemplates }, packageTypeByCarrier.map(e => (React.createElement(Select.Option, { key: e.token, value: e.token }, `${e.name} (${e.length}"L x ${e.width}"W x ${e.height}"H)`)))))))));
                            }))),
                        React.createElement(PackageDetails, null))) : (React.createElement(React.Fragment, null,
                        React.createElement(Col, { md: 24, lg: 8 },
                            React.createElement(Form.Item, { labelAlign: 'left', label: "Tracking Number", name: 'tracking_number' },
                                React.createElement(Input, null))),
                        React.createElement(Col, { md: 24, lg: 8 },
                            React.createElement(Form.Item, { labelAlign: 'left', label: "Shipping Cost", name: 'shipping_cost', rules: [
                                    {
                                        required: true,
                                        message: 'Please input the shipping cost',
                                    },
                                ] },
                                React.createElement(InputNumber, { style: {
                                        width: '100%',
                                    } }))),
                        React.createElement(Col, { md: 24, lg: 8 },
                            React.createElement(Form.Item, { labelAlign: 'left', label: "Boxes Included", name: 'boxes_included', rules: [
                                    {
                                        required: true,
                                        message: 'Please input the number of boxes included',
                                    },
                                ] },
                                React.createElement(InputNumber, { style: {
                                        width: '100%',
                                    } })))));
                })),
            React.createElement(Row, null,
                React.createElement(Col, { md: 24, lg: 12 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: "Customer Shipping Notes", name: 'notes' },
                        React.createElement(Input.TextArea, { tabIndex: 100 }))),
                React.createElement(Col, { md: 24, lg: 12 },
                    React.createElement(Form.Item, { labelAlign: 'left', label: "Internal Shipping Notes", name: 'internal_notes' },
                        React.createElement(Input.TextArea, { tabIndex: 101 })))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 24, lg: 6 },
                    React.createElement(Form.Item, { name: 'notification_opt_out', valuePropName: "checked" },
                        React.createElement(Checkbox, { tabIndex: -1 }, "Opt Out of Packing Slip?"))),
                React.createElement(Form.Item, { dependencies: ['account_type', 'bill_to_customer'], noStyle: true }, ({ getFieldValue }) => {
                    return (getFieldValue('bill_to_customer') === false && (React.createElement(Col, { md: 24, lg: 6 },
                        React.createElement(Form.Item, { shouldUpdate: true }, () => (React.createElement(Form.Item, { name: 'is_late_shipment', valuePropName: 'checked' },
                            React.createElement(Checkbox, { tabIndex: -1 }, " Is this a late shipment? ")))))));
                })),
            box.status != 'Shipped' && (React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Form.Item, { shouldUpdate: true }, ({ getFieldValue }) => (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10" },
                        React.createElement(Button, { type: "primary", icon: React.createElement(SaveOutlined, null), loading: isLoading, onClick: () => {
                                form.submit();
                            } }, CARRIER_WITH_API_INTEGRATION.includes(getFieldValue('carrier'))
                            ? 'Get Rates'
                            : 'Save Changes'))))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(ShippingRates, null))),
            box.status == 'Shipped' && parseFloat(box.shipping_cost_carrier) > 0 && (React.createElement(Row, null,
                React.createElement(Col, { span: 24 },
                    React.createElement(Alert, { type: 'success', message: React.createElement("div", null,
                            React.createElement("div", null,
                                " Total Shipping Cost: $",
                                box.shipping_cost_carrier),
                            React.createElement("div", null,
                                " Customer Billed Shipping Cost: $",
                                box.shipping_cost)) })))))));
});
export default Shipment;
