import React, { useState } from 'react';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Select } from 'antd';
import { default as AddressItem } from './AddressItem';
const { Option } = Select;
const Addresses = React.forwardRef((props, ref) => {
    const { addresses = [], value, onChange, onAddNewAddress, loading = false } = props;
    const [open, setOpen] = useState(false);
    if (open) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Select, { loading: loading, allowClear: true, showSearch: true, style: { width: 250 }, placeholder: "Select an Address", dropdownMatchSelectWidth: false, open: open, value: value?.id, notFoundContent: null, optionFilterProp: "children", filterOption: (input, option) => {
                    return (option?.props?.children?.props?.address?.name?.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                        option?.props?.children?.props?.address?.street
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0);
                }, dropdownRender: menu => (React.createElement("div", { className: 'shadow p-2' },
                    menu,
                    React.createElement(Divider, { style: { margin: '4px 0' } }),
                    React.createElement("div", { style: { padding: '6px 8px', cursor: 'pointer' }, onMouseDown: e => e.preventDefault(), onClick: () => {
                            if (onAddNewAddress) {
                                onAddNewAddress();
                                setOpen(false);
                            }
                        } },
                        React.createElement("span", { className: "link" },
                            React.createElement(PlusOutlined, null),
                            " New Address")))), onSelect: (key) => {
                    const address = addresses?.find(address => address.id === parseInt(key));
                    if (onChange && address) {
                        onChange(address);
                    }
                    setOpen(false);
                } }, addresses?.map(address => (React.createElement(Option, { key: address.id },
                React.createElement(AddressItem, { address: address }))))),
            React.createElement(Button, { type: 'primary', style: { marginLeft: '2px' }, onMouseDown: e => e.preventDefault(), onClick: () => {
                    setOpen(false);
                } },
                React.createElement(CloseOutlined, null),
                " Cancel")));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { "data-testid": `${value?.type?.toLowerCase() ?? 'billing-and-shipping'}-address` }, value && React.createElement(AddressItem, { address: value })),
            React.createElement("span", { className: 'link', style: { marginLeft: '2px' }, onMouseDown: e => e.preventDefault(), onClick: () => {
                    if (addresses?.length) {
                        setOpen(true);
                    }
                    else {
                        onAddNewAddress && onAddNewAddress();
                    }
                } },
                React.createElement(EditOutlined, null),
                " ",
                addresses?.length ? 'Change' : 'Add New Address')));
    }
});
Addresses.displayName = 'Addresses';
export default Addresses;
