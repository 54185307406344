import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Badge, Button, Card, Col, Row, Typography } from 'antd';
import Stages from './Stages';
import { API_ROOT as BASE_URL, useTitle, ZOHO_BASE_URL } from '@partsbadger/utils';
import QuoteToolVersionSwitcher from './QuoteToolVersionSwitcher';
import QuoteSocket from '../Services/QuoteSocket';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, DownloadOutlined, HistoryOutlined } from '@ant-design/icons';
import { ReQuoteChanges, WholeQuoteAdjustmentForm } from '../Forms';
import { hasGroup } from 'quotes-frontend/src/components/User';
import QuoteModalHistory from './QuoteModalHistory';
import QuoteModalHistoryRequote from './QuoteModalHistoryRequote';
const { Paragraph } = Typography;
export const QuoteHeader = (props) => {
    const { quote, handleShowQuoteEdit } = props;
    const [showQuoteModalHistory, setShowQuoteModalHistory] = useState(false);
    const [showQuoteModalHistoryRequote, setShowQuoteModalHistoryRequote] = useState(false);
    useTitle(`${quote?.rfq?.id ?? ''} - ${quote.account?.name ?? quote.name}`);
    const history = useHistory();
    const goBack = () => {
        history.push('/quotes/');
    };
    const ExpiredContent = () => (React.createElement(Badge, { title: 'This quote has new prices that are not being reflected yet, please recalculate all prices', count: 'Expired', style: { backgroundColor: 'red' } }));
    return (React.createElement(Card, { style: {
            padding: 0,
            border: 'none',
            borderBottom: '2px solid #D9D8D8',
        }, bodyStyle: {
            padding: 0,
        } },
        React.createElement(Row, { className: 'shadow p-2' },
            React.createElement(Col, { xs: 24, sm: 24, md: 24, lg: 5, xl: 4, xxl: 3 },
                React.createElement("div", { className: 'flex flex-row item-center justify-left' },
                    React.createElement(ArrowLeftOutlined, { style: {
                            fontSize: 22,
                        }, onClick: goBack }),
                    React.createElement("div", { className: 'flex flex-col ml-2' },
                        React.createElement(Paragraph, { copyable: true, className: 'mb-1 font-bold' }, quote.name),
                        hasGroup('Developer') && (React.createElement("a", { onClick: () => setShowQuoteModalHistory(true) },
                            "History ",
                            React.createElement(HistoryOutlined, null))),
                        quote.changed_fields && (React.createElement("a", { onClick: () => setShowQuoteModalHistoryRequote(true) },
                            "Changes in Requote ",
                            React.createElement(HistoryOutlined, null))),
                        quote.changed_fields && showQuoteModalHistoryRequote && (React.createElement(QuoteModalHistoryRequote, { changed_fields: quote.changed_fields, handleClose: () => setShowQuoteModalHistoryRequote(false) })),
                        quote?.id && showQuoteModalHistory && (React.createElement(QuoteModalHistory, { id: quote?.id, handleClose: () => setShowQuoteModalHistory(false) })),
                        quote?.duplicated_from?.id && quote.type === 'Requote' && quote.duplicated_from?.id && (React.createElement("a", { target: "_blank", href: `/quotes/${quote.duplicated_from.id}`, rel: "noreferrer", style: { textDecoration: 'underline' } },
                            "Requote from: ",
                            quote.duplicated_from?.name)))),
                React.createElement("div", null,
                    React.createElement(Stages, { stage: quote.stage }),
                    quote.stage == 'Completed' && props.isExpired && React.createElement(ExpiredContent, null),
                    quote.safe_to_autocomplete && (React.createElement(Badge, { count: 'Auto Green', style: { backgroundColor: '#27AE60' } })),
                    quote.is_dynamic && (React.createElement(React.Fragment, null,
                        React.createElement(Badge, { count: 'Dynamic', style: { backgroundColor: '#7576c4' } }))),
                    quote.zoho_id && (React.createElement("a", { style: {
                            fontSize: 12,
                            marginLeft: 4,
                        }, target: '_blank', rel: "noreferrer", href: `${ZOHO_BASE_URL}/Quotes/${quote.zoho_id}` }, "View In Zoho")),
                    quote.editable && (React.createElement(Button, { disabled: !quote.editable, type: "link", icon: React.createElement(LegacyIcon, { type: 'edit' }), onClick: () => {
                            handleShowQuoteEdit();
                        } }, "Edit")),
                    React.createElement("div", { className: 'flex flex-row', style: {
                            paddingLeft: '10px',
                            marginLeft: '9px',
                            maxWidth: 100,
                            marginTop: 10,
                        } },
                        React.createElement("div", { className: 'font-bold' }, "Layout: "),
                        React.createElement(QuoteToolVersionSwitcher, { version: '1', handleChange: version => {
                                props.handleQuoteToolLayoutVersionChange(version);
                            } })))),
            React.createElement(Col, { xs: 24, sm: 24, md: 24, lg: 19, xl: 20, xxl: 21 },
                quote.type === 'Requote' && (React.createElement("div", { className: "flex w-full justify-end" },
                    React.createElement(ReQuoteChanges, { quote_id: quote.id, values: quote.requote_changes }))),
                React.createElement("div", { className: 'flex flex-row justify-end' },
                    props.account?.auto_adjust && (React.createElement("div", { className: 'mx-2 my-2' },
                        React.createElement("div", { className: 'account-auto-adjust' },
                            React.createElement("strong", null, "Account Auto Adjustment:"),
                            " ",
                            props.account.auto_adjust))),
                    React.createElement("div", { className: 'mx-2' },
                        React.createElement(WholeQuoteAdjustmentForm, { quote: quote })),
                    React.createElement(Button, { type: 'link', className: "mx-2", onClick: () => {
                            window.open(`${BASE_URL}/redirect?to=${BASE_URL}/staff/quotes/${quote.id}/download-all/`);
                        } },
                        React.createElement(DownloadOutlined, null),
                        " Download All Files")))),
        React.createElement(QuoteSocket, { quote_id: props.quote.id, user: {
                id: props.user.id,
                email: props.user.email,
                display_name: props.user.display_name,
            } })));
};
