import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message } from 'antd';
import { Price } from '@partsbadger/library';
const QuotedQuantity = (props) => {
    const [addingItem, setAddingItem] = useState(false);
    const { quantity, unit_price, total_price } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement("td", { className: "ant-table-cell" }, quantity),
        React.createElement("td", { className: "ant-table-cell" },
            React.createElement(Price, { value: unit_price })),
        React.createElement("td", { className: "ant-table-cell" },
            React.createElement(Price, { value: total_price })),
        React.createElement("td", { className: "ant-table-cell" },
            React.createElement(Button, { size: 'small', style: {
                    color: 'green',
                }, loading: addingItem, onClick: () => {
                    setAddingItem(true);
                    props
                        .onClick()
                        .then(() => {
                        message.success('Item Added!');
                    })
                        .finally(() => setAddingItem(false))
                        .catch(() => {
                        message.error('Something went wrong!');
                    });
                } },
                React.createElement(LegacyIcon, { type: 'plus' }),
                " Add To Order"))));
};
export default QuotedQuantity;
