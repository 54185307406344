import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
const ReQuoteChanges = observer(({ quote_id, values = [] }) => {
    const [changes, setChanges] = useState(values);
    return (React.createElement("div", { className: 'p-4' },
        React.createElement(Checkbox.Group, { disabled: !QuoteStore.quote?.editable, onChange: values => {
                setChanges(values);
            }, defaultValue: values, options: [
                { label: 'Quantity', value: 'Quantity' },
                { label: 'Lead Time', value: 'Lead Time' },
                { label: 'Material', value: 'Material' },
                { label: 'Coating or Finish', value: 'Coating or Finish' },
                { label: 'New File Revision', value: 'New File Revision' },
                { label: 'Add/Remove Lines', value: 'Add/Remove Lines' },
                { label: 'Pricing', value: 'Pricing' },
                { label: 'Additional Requirements', value: 'Additional Requirements' },
                { label: 'Refresh', value: 'Refresh' },
            ] }),
        React.createElement(Button, { disabled: !QuoteStore.quote?.editable, onClick: async () => {
                const payload = {
                    requote_changes: changes,
                };
                await QuoteStore.updateQuote(quote_id, payload);
            } }, "Save")));
});
export default ReQuoteChanges;
