import React from 'react';
import { Button, Modal, Table, message } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { post } from '@partsbadger/utils';
import { SortAscendingOutlined } from '@ant-design/icons';
import { QuoteStore } from '../Stores';
let dragingIndex = -1;
class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };
        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }
        return connectDragSource(connectDropTarget(React.createElement("tr", { ...restProps, className: className, style: style })));
    }
}
const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};
const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        props.moveRow(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
}))(BodyRow));
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
];
class ApplicationCostItemsReorder extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            data: QuoteStore.quote?.additional_requirements.map((item) => {
                return {
                    id: item.id,
                    name: item.additional_requirement,
                    position: item.position,
                };
            }),
        };
        this.components = {
            body: {
                row: DragableBodyRow,
            },
        };
        this.moveRow = (dragIndex, hoverIndex) => {
            const { data } = this.state;
            let dragRow;
            if (data) {
                dragRow = data[dragIndex];
            }
            this.setState(update(this.state, {
                data: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            }));
        };
        this.sortRows = () => {
            const { data } = this.state;
            if (data) {
                data.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({ data: data });
            }
        };
    }
    render() {
        const quote_id = this.props.quote_id;
        return (React.createElement(Modal, { title: 'Sort Application Costs', visible: true, onCancel: () => {
                this.props.handleClose();
            }, footer: [
                React.createElement(Button, { key: "close", type: "default", onClick: this.props.handleClose }, "Cancel"),
                React.createElement(Button, { key: "save", type: "primary", onClick: () => {
                        if (this.state.data) {
                            const payload = this.state.data.map((item, index) => {
                                return {
                                    id: item.id,
                                    position: index,
                                };
                            });
                            post(`/staff/quotes/${quote_id}/additional-requirements/`, payload)
                                .then(() => {
                                QuoteStore.getQuote(quote_id);
                                this.props.handleClose();
                            })
                                .catch(() => {
                                message.error('Error, please try again');
                            });
                        }
                    } }, "Apply"),
            ] },
            React.createElement("div", { className: "text-right" },
                React.createElement(Button, { onClick: () => {
                        this.sortRows();
                    } },
                    React.createElement(SortAscendingOutlined, { className: "text-2xl" }))),
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(Table, { className: 'components-table-drag-sorting', rowKey: 'id', size: 'small', pagination: false, columns: columns, dataSource: this.state.data, components: this.components, 
                    // @ts-ignore
                    onRow: (record, index) => ({
                        index,
                        moveRow: this.moveRow,
                    }) }))));
    }
}
export default ApplicationCostItemsReorder;
