import React from 'react';
import { Badge } from 'antd';
class Stages extends React.Component {
    render() {
        if (this.props.stage === 'Needs Review') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'red' } });
        }
        if (this.props.stage === 'Pending' || this.props.stage === 'Quoting' || this.props.stage === 'In Queue') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'grey' } });
        }
        if (this.props.stage === 'In Progress') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'blue' } });
        }
        if (this.props.stage === 'Cancelled') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: '#000' } });
        }
        if (this.props.stage === 'Unquoted') {
            return React.createElement(Badge, { count: 'No Quoted', style: { backgroundColor: '#000' } });
        }
        if (this.props.stage === 'Reviewed') {
            return (React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'yellow', color: 'rgba(0, 0, 0, 0.45)' } }));
        }
        if (this.props.stage === 'Completed') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'green' } });
        }
        if (this.props.stage === 'Closed Won') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'green' } });
        }
        if (this.props.stage === 'Closed Lost') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'black' } });
        }
        if (this.props.stage === 'Vendor Quoting') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'rgb(220, 108, 126)' } });
        }
        if (this.props.stage === 'Vendor Quote Received') {
            return (React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'yellow', color: 'rgba(0, 0, 0, 0.45)' } }));
        }
        return this.props.stage;
    }
}
export default Stages;
