import { Switch } from 'antd';
import React, { useState } from 'react';
const BatchReleasesSwitcher = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    return (React.createElement("div", null,
        React.createElement("label", { className: 'font-bold' }, "Batch Releases"),
        React.createElement(Switch, { className: 'ml-2', defaultChecked: props.initialValue, size: "small", loading: isLoading, onChange: checked => {
                setIsLoading(true);
                props.handleUpdate(checked).finally(() => {
                    setIsLoading(false);
                });
            } })));
};
export default BatchReleasesSwitcher;
