import * as React from 'react';
import * as THREE from 'three';
export function Edges({ userData, children, geometry, threshold = 15, color = 'black', ...props }) {
    const ref = React.useRef(null);
    React.useLayoutEffect(() => {
        const parent = ref.current.parent;
        if (parent) {
            const geom = geometry || parent.geometry;
            if (geom !== ref.current.userData.currentGeom || threshold !== ref.current.userData.currentThreshold) {
                ref.current.userData.currentGeom = geom;
                ref.current.userData.currentThreshold = threshold;
                ref.current.geometry = new THREE.EdgesGeometry(geom, threshold);
            }
        }
    });
    return (React.createElement("lineSegments", { ref: ref, raycast: () => null, ...props }, children ? children : React.createElement("lineBasicMaterial", { color: color })));
}
