import React from 'react';
import { Button, Divider, Row, Col } from 'antd';
import { QuoteStore } from '../Stores';
import { RichTextEditor } from '@partsbadger/library';
class QuoteNotesComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            notes: '',
            showNotes: false,
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', event => {
            if (event.code === 'Escape') {
                this.setState({
                    showNotes: false,
                });
                event.preventDefault();
            }
        });
    }
    render() {
        const quote = QuoteStore.quote;
        return (React.createElement("div", null,
            React.createElement(Divider, null,
                React.createElement("div", { className: 'title' }, this.state.showNotes ? (React.createElement(Button, { type: 'link', onClick: () => {
                        const payload = {
                            notes: this.state.notes,
                        };
                        if (quote?.id) {
                            QuoteStore.updateQuote(quote.id, payload);
                        }
                        this.setState({
                            showNotes: false,
                        });
                    } }, "Save Notes")) : (React.createElement("div", null, "Notes")))),
            this.state.showNotes ? (React.createElement(Row, null,
                React.createElement(Col, { xs: 24 },
                    React.createElement(RichTextEditor, { value: this.state.notes, placeholder: 'Add Quote Notes', onChange: html => {
                            this.setState({
                                notes: html,
                            });
                        } })))) : quote?.completed ? (React.createElement(Row, { style: { width: '100%', minHeight: '40px', textAlign: 'left', border: '1px solid #d4d4d4' }, className: 'editable-row bordered' },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: quote?.notes || 'No notes' } }))) : (React.createElement(Row, { style: { width: '100%', minHeight: '40px', textAlign: 'left', border: '1px solid #d4d4d4' }, className: 'editable-row bordered', onClick: () => {
                    this.setState({
                        showNotes: true,
                        notes: quote?.notes || '',
                    });
                } },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: quote?.notes || 'Click here to add notes..' } })))));
    }
}
export const QuoteNotes = QuoteNotesComponent;
