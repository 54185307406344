import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { message, Spin } from 'antd';
import { get } from '@partsbadger/utils';
//@ts-ignore
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
const EMPTY_DATA_VALUE = ' - ';
export const RFQView = observer(({ rfq_id }) => {
    const [rfq, setRFQ] = useState();
    useEffect(() => {
        get(`/staff/rfq/${rfq_id}/`)
            .then(response => {
            setRFQ(response);
        })
            .catch(error => message.error(error));
    }, [rfq_id]);
    if (!rfq?.id) {
        return React.createElement(Spin, { spinning: true, tip: 'Loading RFQ' });
    }
    return (React.createElement("div", { className: 'w-full' },
        React.createElement("div", { className: 'py-4' },
            React.createElement("h1", { className: 'font-bold' }, "Products")),
        rfq.rfq_items.map((r, index) => (React.createElement("div", { key: `p-${index + 1}`, className: "product-container", style: {
                border: '1px solid #dee2e6',
                borderRadius: '10px',
                margin: '0px 4px 5px',
                padding: '5px',
                display: 'flex',
                flexWrap: 'wrap',
            } }, [
            { label: '3D File:', value: r.file3d?.filename || r.file3d_name || EMPTY_DATA_VALUE },
            { label: '2D File:', value: r.file2d?.filename || r.file2d_name || EMPTY_DATA_VALUE },
            { label: 'Other Files:', value: r.other_files_name || EMPTY_DATA_VALUE },
            { label: 'Quantities:', value: r.quantities?.toString() || EMPTY_DATA_VALUE },
            { label: 'Materials:', value: r.materials || EMPTY_DATA_VALUE },
            { label: 'Coating:', value: r.coating || EMPTY_DATA_VALUE },
            { label: 'Finish:', value: r.finish || EMPTY_DATA_VALUE },
            { label: 'Notes:', value: r.notes || EMPTY_DATA_VALUE },
            { label: 'Lead Time:', value: r.lead_time || EMPTY_DATA_VALUE },
            { label: 'Target Price:', value: r.target_price || EMPTY_DATA_VALUE },
            { label: 'Unit Price:', value: r.unit_price || EMPTY_DATA_VALUE },
        ].map(({ label, value }, index) => (React.createElement("div", { key: `pi-${index + 1}`, className: "product-item", style: {
                margin: 2,
                flex: '1 1 140px',
                fontSize: '12px',
            } },
            React.createElement("strong", { className: "product-item-label", style: {
                    marginRight: 2,
                } }, label),
            React.createElement("span", null, value))))))),
        React.createElement("div", { className: 'py-4' },
            React.createElement("h1", { className: 'font-bold' }, "Additional Files")),
        React.createElement("ul", null,
            React.createElement("li", null, rfq.quote.additional_files?.length ? (rfq.quote.additional_files?.map(f => (React.createElement("div", { key: f.id },
                React.createElement("a", { target: "_blank", rel: "noreferrer", href: f.file }, f.filename))))) : (React.createElement("div", null, "None"))))));
});
