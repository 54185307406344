import { CaretRightOutlined, CloseOutlined, CloudDownloadOutlined, EditOutlined, FileOutlined, FilePdfOutlined, PlayCircleOutlined, SearchOutlined, SortAscendingOutlined, } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Card, notification, Popconfirm, Row, Spin, Tabs, Tooltip } from 'antd';
import { AddFrom3dFile } from './AddFromStepFile';
import { AddFrom2dFile } from './AddFromDrawingFile';
import AddExistingQuotedProduct from './AddExistingQuotedProduct';
import { MasterProductForm } from '../Forms/index';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { QuoteStore } from '../Stores';
import { DownloadFile, get } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { AddLeadTime } from './AddLeadTime';
export const QuoteToolBar = observer((props) => {
    const { quote, selectedLineItems, handleSelectAll, handleSelectNone, handleShowBulkEdit, handleShowPreview, handleShowReorder, handleShowEdit, handleShowReArrangeFiles, handleShowMessages, } = props;
    const [visible, setVisible] = useState(false);
    const [addLeadTine, setAddedLeadTime] = useState(false);
    const [loading, setLoading] = useState(false);
    const showError = error => {
        notification.error({
            message: 'Error',
            description: React.createElement(ErrorRender, { error: error }),
        });
    };
    return (React.createElement(React.Fragment, null, visible ? (React.createElement(Card, { className: 'actions', style: {
            top: '10%',
            right: 0,
            zIndex: 99,
            position: 'fixed',
        } },
        React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
            React.createElement(Row, { className: 'remove' },
                React.createElement(CloseOutlined, { style: {
                        fontSize: '30px',
                        color: '#08c',
                        float: 'left',
                        padding: '5px',
                    }, onClick: () => setVisible(false) })),
            React.createElement(Tabs, { defaultActiveKey: "1", className: 'mx-4' },
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(FileOutlined, null),
                        "With Step File"), key: "1" },
                    React.createElement(AddFrom3dFile, null)),
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(FilePdfOutlined, null),
                        "With Drawing File"), key: "2" },
                    React.createElement(AddFrom2dFile, null)),
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(SearchOutlined, null),
                        "Existing Product"), key: "3" },
                    React.createElement("div", { style: { maxWidth: '90%', margin: 'auto' } },
                        React.createElement(AddExistingQuotedProduct, null))),
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(EditOutlined, null),
                        "Manual Input"), key: "4" },
                    React.createElement(MasterProductForm, { handleCreate: async (payload) => {
                            try {
                                await QuoteStore.createLineItemWithDimensions(payload);
                            }
                            catch (e) {
                                notification.error({
                                    message: 'Error',
                                    description: React.createElement(ErrorRender, { error: e }),
                                });
                            }
                        } })))))) : addLeadTine ? (React.createElement(Card, { className: 'actions', style: {
            top: '10%',
            right: 0,
            zIndex: 99,
            position: 'fixed',
        } },
        React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
            React.createElement(Row, { className: 'remove' },
                React.createElement(CloseOutlined, { style: {
                        fontSize: '30px',
                        color: '#08c',
                        float: 'left',
                        padding: '5px',
                    }, onClick: () => setAddedLeadTime(false) })),
            React.createElement("div", null,
                React.createElement("div", { className: 'flex justify-center font-bold text-lg' }, "Bulk Lead Time Adjustment'"),
                React.createElement(AddLeadTime, { setAddLeadTime: setAddedLeadTime }))))) : (React.createElement("div", { className: 'quote-floating-bar' },
        React.createElement("div", { style: { backgroundColor: '#cc0000' }, className: 'tool-bar' },
            React.createElement(Tooltip, { placement: 'left', title: 'Add Product' },
                React.createElement("button", { style: { backgroundColor: '#CC0000' }, disabled: !quote.editable, className: "tool-button", onClick: () => setVisible(true) },
                    React.createElement(LegacyIcon, { type: 'plus' }))),
            !selectedLineItems.length ? (React.createElement(Tooltip, { placement: 'left', title: 'Select All' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleSelectAll },
                    React.createElement(LegacyIcon, { type: 'select' })))) : (React.createElement(Tooltip, { placement: 'left', title: 'Clear Selection' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleSelectNone },
                    React.createElement(LegacyIcon, { type: 'minus-square' })))),
            React.createElement(Tooltip, { placement: 'left', title: 'Bulk Edit' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleShowBulkEdit },
                    React.createElement(LegacyIcon, { type: 'form' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Recalculate Prices' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: () => {
                        const payload = {
                            quoted_products: QuoteStore.lineItems.map(p => p.id),
                        };
                        QuoteStore.recalculatePrices(payload).catch(err => {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'One of the line items has an error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        });
                    } },
                    React.createElement(LegacyIcon, { type: 'file-sync' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Preview' },
                React.createElement("button", { className: "tool-button", onClick: handleShowPreview },
                    React.createElement(LegacyIcon, { type: 'file-pdf' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Edit Quote' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleShowEdit },
                    React.createElement(LegacyIcon, { type: 'edit' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Sort Quote Items' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleShowReorder },
                    React.createElement(LegacyIcon, { type: 'unordered-list' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Re-arrange files' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: () => handleShowReArrangeFiles(true) },
                    React.createElement(SortAscendingOutlined, null))),
            React.createElement(Tooltip, { placement: 'left', title: 'Messages' },
                React.createElement("button", { className: "tool-button", onClick: handleShowMessages },
                    React.createElement(LegacyIcon, { type: 'message' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Download all Files' },
                React.createElement("button", { className: "tool-button", onClick: () => {
                        get(`/staff/quotes/${quote.id}/download-all/`, { responseType: 'blob' }).then((response) => {
                            DownloadFile(response, `${quote?.name}-files.zip`);
                        });
                    } },
                    React.createElement(CloudDownloadOutlined, null))),
            React.createElement(Spin, { spinning: loading },
                React.createElement(Tooltip, { placement: 'left', title: 'Calculate lead time for all items.' },
                    React.createElement(Popconfirm, { title: "Make sure that all products have been stored?", onConfirm: async () => {
                            setLoading(true);
                            if (QuoteStore.quote?.id) {
                                try {
                                    await QuoteStore.updateAllLeadTime();
                                    await QuoteStore.getLineItems(QuoteStore.quote.id);
                                    notification.success({
                                        message: 'Files are being analyzed, please wait!',
                                    });
                                    setLoading(false);
                                }
                                catch (err) {
                                    notification.error({
                                        duration: 10,
                                        message: 'Error',
                                        description: React.createElement(ErrorRender, { error: err }),
                                    });
                                    setLoading(false);
                                }
                            }
                        }, className: "tool-button", okText: "Yes", cancelText: "No" },
                        React.createElement("button", { className: "tool-button", disabled: !quote.editable },
                            React.createElement(CaretRightOutlined, { type: 'vertical-align-bottom' }))))),
            React.createElement(Tooltip, { placement: 'left', title: 'Bulk Lead Time Adjustment' },
                React.createElement("button", { className: "tool-button", onClick: () => setAddedLeadTime(true), disabled: !quote.editable },
                    React.createElement(PlayCircleOutlined, { type: 'vertical-align-bottom' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Analyze all 3D and 2D files.' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button step-reanalyze-all-files", onClick: async () => {
                        if (QuoteStore.quote?.id && QuoteStore.masterProductsByPosition) {
                            QuoteStore.masterProductsByPosition.map(async (r) => {
                                await QuoteStore.analyzeAllItemsFiles(r.master_product.id);
                                if (QuoteStore.error) {
                                    showError(QuoteStore.error);
                                }
                            });
                        }
                    } },
                    React.createElement(LegacyIcon, { type: 'sync' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'To Top' },
                React.createElement("button", { className: "tool-button", onClick: () => {
                        window.scrollTo(0, 0);
                    } },
                    React.createElement(LegacyIcon, { type: 'vertical-align-top' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'To Bottom' },
                React.createElement("button", { className: "tool-button", onClick: () => {
                        window.scrollTo(0, document.body.scrollHeight);
                    } },
                    React.createElement(LegacyIcon, { type: 'vertical-align-bottom' }))),
            React.createElement("div", { style: { height: '30px' } },
                React.createElement(Spin, { spinning: QuoteStore.loading })))))));
});
