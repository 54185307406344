import React from 'react';
import { Select } from 'antd';
import { QuoteStore } from '../../Stores';
import { SAFE_MATERIALS } from '../Select/Materials';
import { observer } from 'mobx-react';
import { get } from '@partsbadger/utils';
const { Option } = Select;
const Formulas = (props) => {
    const user = QuoteStore.currentUser;
    const [formulas, setFormulas] = React.useState([]);
    const isATesterUser = user?.is_tester;
    let formula26Safe = false;
    if (props.item.totalVol && props.item.partVol) {
        formula26Safe =
            props.item.totalVol < 90 &&
                props.item.partVol < 30 &&
                !!props.selectedMaterialName &&
                SAFE_MATERIALS.includes(props.selectedMaterialName);
    }
    React.useEffect(() => {
        get('/formulas/').then(data => {
            setFormulas(data);
        });
    }, []);
    return (React.createElement(Select, { allowClear: true, optionFilterProp: "children", dropdownMatchSelectWidth: false, style: { width: '100%' }, size: "small", ...props }, formulas
        .filter(f => {
        //  Check if is safe to use formula 2.6
        if (f.version == '2.6') {
            if (!formula26Safe) {
                return false;
            }
        }
        // Exclude formula 4 if analysis_status_v2 is not 'completed'
        if (parseFloat(f.version) >= 4.0 && (props.item.analysis_status_v2 ?? '') !== 'completed') {
            return false;
        }
        return true;
    })
        .filter(f => {
        // Remove testing formulas if the current user is not a tester
        if (!isATesterUser) {
            return !f.is_testing;
        }
        return true;
    })
        .map(f => {
        return (React.createElement(Option, { key: f.id, value: f.version },
            f.version,
            f.version == '2.6' && formula26Safe && props.item.has_file_3d && (React.createElement("span", { className: 'dropdown-item-helper', style: { color: 'green' } }, "\u00A0\u2713"))));
    })));
};
export default observer(Formulas);
