import * as React from 'react';
import { useState, useEffect } from 'react';
import { Select, Spin, Input } from 'antd';
import { get } from '@partsbadger/utils';
let busy = null;
export const Accounts = (props) => {
    const [loading, setLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    const { account } = props;
    const fetchRecords = (params) => {
        get(`/staff/accounts/`, {
            params: params,
        })
            .then(data => {
            setAccounts(data.results);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    if (props.zoho_id) {
        useEffect(() => {
            get(`/staff/accounts/`, {
                params: {
                    zoho_id: props.zoho_id,
                },
            })
                .then(data => {
                setTimeout(() => {
                    if (props.setAccount) {
                        props.setAccount(data.results[0]);
                    }
                    setSelectValue(data.results[0].name);
                }, 100);
            })
                .finally(() => {
                setLoading(false);
            });
        }, [props.zoho_id]);
    }
    return props.zoho_id && selectValue ? (React.createElement(Input, { disabled: true, size: 'small', style: { width: '100%' }, defaultValue: selectValue })) : (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search account by  name or email", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, onSearch: name => {
            if (busy) {
                clearTimeout(busy);
            }
            const params = {
                search: name,
            };
            if (account) {
                params['account'] = props.account;
            }
            setLoading(true);
            busy = setTimeout(() => fetchRecords(params), 400);
        }, ...props }, accounts.map(entity => (React.createElement(Select.Option, { key: entity.id, value: entity.id }, entity.name)))));
};
