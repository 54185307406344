import React, { useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, InputNumber, Row, Select, Tag, Tooltip } from 'antd';
import { Coatings, Finishes, Formulas, SelectReinforcement } from '../../components/Select';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { Materials } from '../../components/Select/Materials';
import { TooltipFlag } from '../../components';
const { Option } = Select;
const smallLayout = {
    xs: 8,
    sm: 2,
    md: 2,
    style: {
        textAlign: 'center',
    },
};
const largeLayout = {
    xs: 8,
    sm: 2,
    md: 2,
    style: {
        textAlign: 'center',
    },
};
const QuoteLineItemForm = ({ item, handleSubmit, isReadOnly, form, addPartsbadgerProductionQuotingNotes }) => {
    const { getFieldDecorator, getFieldValue } = form;
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = () => {
        form.validateFields((err, values) => {
            if (!err) {
                setIsLoading(true);
                handleSubmit(values)
                    .then(() => {
                    form.resetFields();
                })
                    .finally(() => setIsLoading(false));
            }
        });
    };
    const manufacturing_type = getFieldValue('manufacturing_type');
    const save_changes = form.isFieldsTouched() ? 'Save Changes' : 'No Changes';
    const selectedMaterialName = getFieldValue('product__material')?.label;
    const materials = QuoteStore.materials.map(m => {
        return {
            id: m.id,
            name: m.name,
        };
    });
    const coatings = QuoteStore.coatings.map(m => {
        return {
            id: m.id,
            name: m.name,
            needs_manual_adjust: m.needs_manual_adjust,
        };
    });
    const finishes = QuoteStore.finishes.map(m => {
        return {
            id: m.id,
            name: m.name,
            metric_name: m.metric_name,
            needs_manual_adjust: m.needs_manual_adjust,
        };
    });
    return (React.createElement(Form, { layout: 'inline' },
        React.createElement(Row, null,
            React.createElement(Col, { ...smallLayout },
                "Manufacturing ",
                React.createElement(TooltipFlag, { field_name: 'manufacturing_type', item_id: item.id })),
            React.createElement(Col, { ...smallLayout }, "Formula"),
            React.createElement(Col, { ...largeLayout },
                React.createElement("div", null,
                    React.createElement("span", null,
                        "Material ",
                        React.createElement(TooltipFlag, { field_name: 'material', item_id: item.id })),
                    item.product__material && item.product__material.label
                        ? item.product__material.label.toString().toLowerCase().includes('custom') && (React.createElement("span", { style: { color: 'red' } },
                            " \"",
                            item.custom_material,
                            "\""))
                        : null)),
            React.createElement(Col, { ...smallLayout }, "FAI Required"),
            manufacturing_type === '3D PRINTING' && (React.createElement(React.Fragment, null,
                React.createElement(Col, { ...largeLayout }, "Reinforcement"),
                React.createElement(Col, { ...largeLayout }, "Reinforcement %"),
                React.createElement(Col, { ...smallLayout }, "Design Time"))),
            React.createElement(Col, { ...smallLayout },
                React.createElement(Tooltip, { title: "Parts Badger Production Candidate" }, "PB Prod. Candidate")),
            React.createElement(Col, { ...smallLayout }, "PartsBadger Production"),
            React.createElement(Col, { ...smallLayout }, "Sides"),
            React.createElement(Col, { ...largeLayout },
                React.createElement("div", null,
                    React.createElement("span", null,
                        "Finish ",
                        React.createElement(TooltipFlag, { field_name: 'finish', item_id: item.id }),
                        ' '),
                    item.product__finish && item.product__finish.label
                        ? item.product__finish.label.toString().toLowerCase().includes('custom') && (React.createElement("span", { style: { color: 'red' } },
                            " \"",
                            item.custom_finish,
                            "\""))
                        : null)),
            React.createElement(Col, { ...largeLayout },
                React.createElement("div", null,
                    React.createElement("span", null,
                        "Coating ",
                        React.createElement(TooltipFlag, { field_name: 'coating', item_id: item.id })),
                    item.product__coating && item.product__coating.label
                        ? item.product__coating.label.toString().toLowerCase().includes('custom') && (React.createElement("span", { style: { color: 'red' } },
                            " \"",
                            item.custom_coating,
                            "\""))
                        : null)),
            React.createElement(Col, { ...smallLayout }, "Adjust"),
            React.createElement(Col, { ...smallLayout }, "Vendor Adjust")),
        React.createElement(Row, null,
            React.createElement(Col, { ...smallLayout }, getFieldDecorator('manufacturing_type', {
                initialValue: item.manufacturing_type,
            })(React.createElement(Select, { disabled: isReadOnly, size: "small" },
                React.createElement(Option, { value: "CNC" }, "CNC"),
                React.createElement(Option, { value: "3D PRINTING" }, "3D")))),
            React.createElement(Col, { ...smallLayout }, getFieldDecorator('formula', {
                initialValue: item.formula,
            })(React.createElement(Formulas, { disabled: isReadOnly, selectedMaterialName: selectedMaterialName, item: item }))),
            React.createElement(Col, { ...largeLayout }, getFieldDecorator('product__material', {
                ...(item.product__material && { initialValue: item.product__material }),
            })(React.createElement(Materials, { materials: materials, handleSearch: params => QuoteStore.getMaterials(params), disabled: isReadOnly, suggestions: item.drawing_file?.suggestions
                    ? item.drawing_file.suggestions
                        .filter(s => s.suggested_material?.id)
                        .map(s => {
                        return {
                            id: s.suggested_material?.id ?? 0,
                            name: s.suggested_material?.name ?? '',
                            thumbnail: s.cropped_image?.file ?? '',
                        };
                    })
                    : [] }))),
            React.createElement(Col, { ...smallLayout }, getFieldDecorator('is_fai_required', {
                initialValue: item.is_fai_required,
                valuePropName: 'checked',
            })(React.createElement(Checkbox, { disabled: isReadOnly }))),
            React.createElement(Col, { ...smallLayout },
                React.createElement("span", null, item.is_prod_candidate ? React.createElement(Tag, { color: 'blue' }, "Yes") : React.createElement(Tag, { color: 'red' }, "No"))),
            React.createElement(Col, { ...smallLayout },
                getFieldDecorator('partsbadger_production_method', {
                    initialValue: item.partsbadger_production_method,
                    // valuePropName: 'checked',
                })(React.createElement(Select, { size: 'small' },
                    React.createElement(Select.Option, { value: 'Fastlane' }, "Fastlane"),
                    React.createElement(Select.Option, { value: 'QuickTurn' }, "QuickTurn"),
                    React.createElement(Select.Option, { value: 'Swiss' }, "Swiss"),
                    React.createElement(Select.Option, { value: 'Traditional' }, "Traditional"),
                    React.createElement(Select.Option, { value: 'No' }, "No"))),
                React.createElement("div", { style: { display: 'flex' } },
                    React.createElement("div", null, item.partsbadger_production_method != null &&
                        item.partsbadger_production_method != '' &&
                        item.partsbadger_production_method != 'No' && (React.createElement(Tooltip, { title: "Add a note with information that may be helpful for PartsBadger\n                                        Production. This can include quoted materials, custom tooling, etc. Notes will be\n                                        visible in the ERP." },
                        React.createElement(Button, { size: 'small', type: 'link', disabled: QuoteStore.quote?.completed, onClick: event => {
                                event.stopPropagation(),
                                    addPartsbadgerProductionQuotingNotes &&
                                        addPartsbadgerProductionQuotingNotes(item.id, item.partsbadger_production_quoting_notes);
                            } }, item.partsbadger_production_quoting_notes != null &&
                            item.partsbadger_production_quoting_notes !== '' ? (React.createElement("span", null, "Edit Note")) : (React.createElement("span", null, "Add Note")))))))),
            manufacturing_type === '3D PRINTING' && (React.createElement(React.Fragment, null,
                React.createElement(Col, { ...largeLayout }, getFieldDecorator('reinforcement', {
                    initialValue: item.reinforcement ? item.reinforcement : 'carbon_fiber',
                })(React.createElement(SelectReinforcement, { disabled: isReadOnly }))),
                React.createElement(Col, { ...largeLayout }, getFieldDecorator('reinforcement_percent', {
                    initialValue: item.reinforcement_percent ? item.reinforcement_percent : '',
                })(React.createElement(Input, { size: "small", style: { width: '100%' }, disabled: isReadOnly }))),
                React.createElement(Col, { ...smallLayout }, getFieldDecorator('design_time', {
                    initialValue: item.design_time,
                })(React.createElement(InputNumber, { size: "small", disabled: isReadOnly }))))),
            React.createElement(Col, { ...smallLayout }, getFieldDecorator('product__sides', {
                initialValue: item.product__sides,
            })(React.createElement(Input, { size: "small", style: { width: '100%' }, disabled: isReadOnly }))),
            React.createElement(Col, { ...largeLayout }, getFieldDecorator('product__finishes', {
                ...(item.product__finish && { initialValue: item.product__finish }),
            })(React.createElement(Finishes, { finishes: finishes, handleSearch: params => QuoteStore.getFinishes(params), disabled: isReadOnly, suggestions: item.drawing_file?.suggestions
                    ? item.drawing_file.suggestions
                        .filter(s => s.suggested_finish?.id)
                        .map(s => {
                        return {
                            id: s.suggested_finish?.id ?? 0,
                            name: s.suggested_finish?.name ?? '',
                            thumbnail: s.cropped_image?.file ?? '',
                        };
                    })
                    : [] }))),
            React.createElement(Col, { ...largeLayout }, getFieldDecorator('product__coatings', {
                ...(item.product__coating && { initialValue: item.product__coating }),
            })(React.createElement(Coatings, { coatings: coatings, handleSearch: params => QuoteStore.getCoatings(params), disabled: isReadOnly, suggestions: item.drawing_file?.suggestions
                    ? item.drawing_file.suggestions
                        .filter(s => s.suggested_coating?.id)
                        .map(s => {
                        return {
                            id: s.suggested_coating?.id ?? 0,
                            name: s.suggested_coating?.name ?? '',
                            thumbnail: s.cropped_image?.file ?? '',
                        };
                    })
                    : [] }))),
            React.createElement(Col, { ...smallLayout }, getFieldDecorator('manual_adjustment', {
                initialValue: item.manual_adjustment,
            })(React.createElement(InputNumber, { disabled: isReadOnly, size: "small" }))),
            React.createElement(Col, { ...smallLayout }, getFieldDecorator('vendor_price_adjust', {
                initialValue: item.vendor_price_adjust,
            })(React.createElement(InputNumber, { disabled: isReadOnly, size: "small" }))),
            !isReadOnly && (React.createElement(Col, { ...smallLayout, className: "ml-2" },
                React.createElement(Button, { disabled: !form.isFieldsTouched(), size: "small", loading: isLoading, type: 'primary', htmlType: 'submit', onClick: onSubmit },
                    React.createElement(SaveOutlined, null),
                    " ",
                    isLoading ? 'Saving' : save_changes))))));
};
export default Form.create()(observer(QuoteLineItemForm));
