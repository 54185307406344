import React, { useState } from 'react';
import { message, Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { QuoteStore } from '../../Stores';
import { post } from '@partsbadger/utils';
const loadingIcon = React.createElement(SyncOutlined, { style: { fontSize: 16 }, spin: true });
const ConvertPngToPdf = (props) => {
    const [stateLoading, setStateLoading] = useState(false);
    const checkImagePng = () => {
        const filename = props.file.filename;
        const array = filename.split('.');
        let extension = '';
        const size = array.length;
        if (size > 1) {
            extension = array[size - 1].trim().toLowerCase();
            if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
                return true;
            }
        }
        return false;
    };
    return (React.createElement("div", { className: "flex flex-row " }, checkImagePng() && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: stateLoading ? 'link text-red-600 text-xs pointer-events-none' : 'link text-red-600 text-xs', onClick: e => {
                const payload = {
                    master_product_id: props.master_product.id,
                    file_id: props.file.id,
                };
                e.stopPropagation();
                setStateLoading(true);
                post(`/staff/quotes/${props.quote_id}/set-2d-file/`, payload)
                    .then((response) => {
                    setStateLoading(false);
                    const master_product = response.master_product;
                    if (master_product) {
                        const id = master_product.id;
                        const index = QuoteStore.masterProductsByPosition.findIndex(product => product.master_product.id === id);
                        if (index > -1) {
                            QuoteStore.masterProductsByPosition[index].master_product = master_product;
                        }
                    }
                })
                    .catch(error => {
                    setStateLoading(false);
                    message.error(error.message || 'Internal server error.');
                });
            } }, "Set as 2D file"),
        React.createElement(Spin, { spinning: stateLoading, size: "small", indicator: loadingIcon }, ' ')))));
};
export default ConvertPngToPdf;
