import { Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
export const Users = observer((props) => {
    return (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, size: 'small', placeholder: "Search user", dropdownMatchSelectWidth: false, style: { width: '100%' }, filterOption: (input, option) => {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }, ...props },
        props.group === 'Design Engineer' && React.createElement(Select.Option, { key: '' }, "None"),
        props.users.map(d => (React.createElement(Select.Option, { key: d.id }, d.fullname)))));
});
