import React from 'react';
import { Row, Col } from 'antd';
import ChecklistQuestion from './ChecklistQuestion';
import TopicChecklist from './TopicChecklist';
const SalesRepChecklistQuestion = ({ getFieldDecorator, cols, question, value, onChange, checklistEdit, form, checklistQuestions, setChecklistQuestions, }) => {
    return (React.createElement(Row, { gutter: 4 },
        React.createElement(Col, { ...cols },
            React.createElement(TopicChecklist, { value: question.question })),
        React.createElement(Col, { ...cols },
            React.createElement(ChecklistQuestion, { question: question, value: value, onChange: onChange, form: form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions, checklistEdit: checklistEdit, getFieldDecorator: getFieldDecorator }))));
};
export default SalesRepChecklistQuestion;
