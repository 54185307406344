import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { message } from 'antd';
import { get, post, requests, patch } from '@partsbadger/utils';
import { LeadStore } from './LeadStore';
function normalize_requirements(r) {
    return {
        key: r.requirement.id,
        label: r.requirement.name,
        value: r.value,
        files: r.files,
    };
}
class QuoteStore {
    constructor() {
        this.signed_uid = null;
        this.loading = false;
        this.loading_accounts = false;
        this.quote = null;
        this.is_new_contact = false;
        // All information of contact related to the current quote
        this.contact = null;
        this.partTolerances = [];
        this.holeTolerances = [];
        this.finishes = [];
        this.coatings = [];
        this.materials = [];
        this.accounts = [];
        this.selectedLineItems = [];
        this.collapsedLineItems = [];
        this.default_requirements = [];
        this.users = [];
        this.token = '';
        this.for_staff = false;
        this.current_user = {};
        this.isConnectedToSocket = false;
        this.previous_quoted_parts = [];
        this.loading_quote_items = false;
        this.additional_requirements = [];
        this.default_documents = [];
        this.acquisition_source = [];
        this.acquisition_source_contact = [];
        this.getAdditionalRequirements = () => {
            requests.get(`/customer/additional-requirements/`).then(data => {
                runInAction(() => {
                    this.additional_requirements = data.results;
                });
            });
        };
        this.createLineItemFromExistingLineItems = async (line_items) => {
            return await post(`/customer/quote/${this.quote_uid}/add-existing-quoted-product/`, {
                quoted_products: line_items,
            }).finally(() => this.getQuote());
        };
        this.searchPreviousParts = async (payload) => {
            this.loading_quote_items = true;
            await post(`/staff/master-products/parts-by-account/`, payload)
                .then(response => {
                this.previous_quoted_parts = response;
            })
                .finally(() => (this.loading_quote_items = false));
        };
        makeObservable(this, {
            // Observables
            signed_uid: observable,
            loading: observable,
            loading_accounts: observable,
            quote: observable,
            is_new_contact: observable,
            contact: observable,
            partTolerances: observable,
            holeTolerances: observable,
            finishes: observable,
            coatings: observable,
            materials: observable,
            accounts: observable,
            selectedLineItems: observable,
            collapsedLineItems: observable,
            default_requirements: observable,
            users: observable,
            token: observable,
            for_staff: observable,
            current_user: observable,
            isConnectedToSocket: observable,
            previous_quoted_parts: observable,
            loading_quote_items: observable,
            additional_requirements: observable,
            default_documents: observable,
            acquisition_source: observable,
            acquisition_source_contact: observable,
            // Actions
            setQuote: action,
            setLineItem: action,
            setCollapsed: action,
            collapseAllLineItems: action,
            expandAllLineItems: action,
            setConnectedSocket: action,
            setSignedUID: action,
            setToken: action,
            setLoading: action,
            newQuote: action,
            getQuote: action,
            updateQuote: action,
            getRequote: action,
            newLineItemWithFiles: action,
            duplicateItem: action,
            updateLineItem: action,
            updateMultipleLineItems: action,
            getBulkPrices: action,
            deleteLineItem: action,
            deleteMasterProduct: action,
            deleteAll: action,
            newLineItem: action,
            exportToPdf: action,
            sendToReview: action,
            getTolerances: action,
            getFinishes: action,
            getCoatings: action,
            getMaterials: action,
            getAdditionalRequirements: action,
            getAccounts: action,
            getUsers: action,
            lineItems: action,
            safeLineItems: action,
            setProduct: action,
            hasErrorFiles: action,
            needsRFQ: action,
            needs3DFiles: action,
            needs2DFiles: action,
            setForStaff: action,
            uploadAdditionalFile: action,
            setIsNewContact: action,
            setCurrentUser: action,
            updateContactInfo: action.bound,
            getContactDetails: action,
            getAdditionalRequirementsContactAccount: action,
            updateRequiredQuantities: action,
            getQuoteLineItems: action,
            createLineItemFromExistingLineItems: action,
            searchPreviousParts: action,
            clean_previous_items: action,
            update_quote_field: action,
            setAcquisitionSource: action,
            // Computed
            quote_uid: computed,
            needsToUsePolling: computed,
            canBeOrdered: computed,
            errorFields: computed,
            isRequote: computed,
            isNewContact: computed,
            getFirstName: computed,
            getLastName: computed,
            getEmail: computed,
            getCompany: computed,
            getPhone: computed,
            getZip: computed,
            getTitle: computed,
            getRelatedContact: computed,
            getAdditionalFiles: computed,
            isAllFileAnalyzingCompleted: computed,
        });
    }
    setQuote(data) {
        this.quote = data;
        this.loading = false;
    }
    setLineItem(data) {
        runInAction(() => {
            if (this.quote) {
                this.quote.line_items = this.quote.line_items.map(item => {
                    if (item.id == data.id) {
                        return data;
                    }
                    return item;
                });
            }
        });
    }
    setProduct(data) {
        runInAction(() => {
            if (this.quote) {
                this.quote.line_items = this.quote.line_items.map(item => {
                    if (item.product.id == data.id) {
                        item = {
                            ...item,
                            product: data,
                        };
                    }
                    return item;
                });
            }
        });
    }
    setCollapsed(id) {
        runInAction(() => {
            if (this.collapsedLineItems.includes(Number(id))) {
                this.collapsedLineItems = this.collapsedLineItems.filter(x => Number(x) != Number(id));
            }
            else {
                this.collapsedLineItems.push(Number(id));
            }
        });
    }
    collapseAllLineItems() {
        this.collapsedLineItems = this?.quote?.line_items?.map(x => Number(x.id)) || [];
    }
    expandAllLineItems() {
        this.collapsedLineItems = [];
    }
    setConnectedSocket(connected) {
        this.isConnectedToSocket = connected;
    }
    setSignedUID(signed_id) {
        this.signed_uid = signed_id;
    }
    async fetchAndSetAcquisitionSource(source) {
        try {
            const response = await requests.get(`/staff/acquisition-source/?source=${source}`);
            const valuesArray = response.results.map(item => item.value);
            runInAction(() => {
                if (source === 'LC') {
                    this.acquisition_source_contact = valuesArray;
                }
                else {
                    this.acquisition_source = valuesArray;
                }
            });
        }
        catch (error) {
            console.error('Error fetching acquisition source:', error);
        }
    }
    setAcquisitionSource(quote_with) {
        if (quote_with === 'NC') {
            this.fetchAndSetAcquisitionSource(quote_with);
            this.fetchAndSetAcquisitionSource('LC');
        }
        else {
            // Default Value EC
            this.fetchAndSetAcquisitionSource('EC');
        }
    }
    get quote_uid() {
        if (!this.token && this.signed_uid) {
            return this.signed_uid;
        }
        return this.quote?.uid;
    }
    /**
     * If is not authenticated (no token) and has pending files to analyze use polling because web sockets is only for
     * authenticated users
     * @returns {boolean}
     */
    get needsToUsePolling() {
        if (!this.token && this.signed_uid) {
            return true;
        }
        else {
            return false;
        }
    }
    /**
     * Items safe to quote can be ordered
     * */
    get canBeOrdered() {
        return (this.quote &&
            this.quote.line_items.length > 0 &&
            this.quote.line_items.filter(item => item.needs_manual_quote).length === 0);
    }
    setToken(token) {
        runInAction(() => {
            this.token = token;
        });
    }
    setLoading(loading = false) {
        runInAction(() => {
            this.loading = loading;
        });
    }
    newQuote(payload = {}) {
        return requests
            .post(`/customer/quote/new/`, payload)
            .then(data => {
            this.setQuote(data);
        })
            .catch(err => {
            console.log(err);
            message.error('Error, please try again');
        });
    }
    get isAllFileAnalyzingCompleted() {
        // Return false if there are no line items
        if (!this.quote?.line_items || this.quote?.line_items?.length === 0) {
            return false;
        }
        const isAllCompleted = this.quote.line_items.every(item => item.product?.analysis_status?.file_2d?.completed && item.product?.analysis_status?.file_3d?.completed);
        // Return true if all files have completed analysis
        return isAllCompleted;
    }
    getQuote() {
        if (this.quote_uid) {
            this.loading = true;
            requests
                .get(`/customer/quote/${this.quote_uid}/`)
                .then(data => {
                this.setQuote(data);
            })
                .catch(error => {
                if (error?.data?.status === 404) {
                    this.setQuote(null);
                }
            })
                .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
        }
        else {
            this.loading = false;
        }
    }
    updateQuote(payload = {}) {
        return requests
            .patch(`/customer/quote/${this.quote_uid}/`, payload)
            .then(() => {
            this.getQuote();
        })
            .catch(err => {
            console.log(err);
            message.error('Error, please try again');
        });
    }
    getRequote(id) {
        return requests.post(`/staff/quotes/${id}/requote/`);
    }
    newLineItemWithFiles(payload = {}, onUploadProgress) {
        const authorization = this.token ? { authorization: `Token ${this.token}` } : {};
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authorization,
            },
            onUploadProgress: onUploadProgress && onUploadProgress,
        };
        return requests
            .post(`/customer/quote/${this.quote_uid}/new-item/`, payload, options)
            .then(data => {
            runInAction(() => {
                this.quote?.line_items?.push(data);
            });
        })
            .catch(err => {
            console.log(err);
            message.error('Error, please try again');
        });
    }
    duplicateItem(master_product) {
        return requests
            .post(`/customer/quote/${this.quote_uid}/duplicate-item/${master_product}/`)
            .then(data => {
            runInAction(() => {
                if (this.quote) {
                    this.quote.line_items.push(data);
                }
                this.getQuote();
            });
        })
            .catch(err => {
            console.log(err);
            message.error('Error, please try again');
        });
    }
    async duplicateMasterProduct(master_product_id, duplicated_reason) {
        try {
            const response = await post(`/customer/quote/${this.quote_uid}/duplicate-master-product/`, {
                master_product: master_product_id,
                duplicated_reason: duplicated_reason,
            });
            this.getQuote();
        }
        catch (error) {
            console.error('Error duplicating master product:', error);
        }
    }
    async updateLineItem(payload = {}) {
        this.loading = true;
        return await requests
            .patch(`/customer/quote/${this.quote_uid}/update-item/`, payload)
            .then(data => {
            runInAction(() => {
                if (this.quote) {
                    this.quote.line_items = this.quote.line_items.map(item => {
                        if (item.uid === data.uid) {
                            return data;
                        }
                        return item;
                    });
                }
                // this.getQuote();
            });
        })
            .catch(err => {
            message.error('Error, please try again');
        })
            .finally(() => {
            this.setLoading(false);
        });
    }
    async updateMultipleLineItems(payload = {}) {
        this.loading = true;
        return await requests
            .patch(`/customer/quote/${this.quote_uid}/update-multiple-items/`, payload)
            .then(data => {
            this.setQuote(data);
        })
            .catch(err => {
            message.error('Error, please try again');
        })
            .finally(() => {
            this.setLoading(false);
        });
    }
    getBulkPrices(line_item, quantities) {
        const options = {
            params: {
                quantities: quantities.join(','),
            },
        };
        return requests.get(`/customer/quote/${this.quote_uid}/bulk-prices/${line_item}/`, options);
    }
    deleteLineItem(item_uid) {
        this.loading = true;
        if (this.quote) {
            this.quote.line_items = this.quote.line_items.filter(item => item.uid !== item_uid);
        }
        requests
            .delete(`/customer/quote/${this.quote_uid}/remove-item/${item_uid}/`)
            .then(data => {
            this.setQuote(data);
        })
            .catch(err => {
            message.error('Error, please try again');
        });
    }
    deleteMasterProduct(master_product_id) {
        requests
            .delete(`/customer/quote/${this.quote_uid}/remove-part/${master_product_id}/`)
            .then(data => {
            this.setQuote(data);
        })
            .catch(err => {
            message.error('Error, please try again');
        });
    }
    deleteAll() {
        this.loading = true;
        requests
            .delete(`/customer/quote/${this.quote_uid}/remove-all/`)
            .then(data => {
            this.setQuote(data);
        })
            .catch(err => {
            message.error('Error, please try again');
        });
    }
    newLineItem() {
        this.loading = true;
        const id = this.quote_uid;
        requests
            .post(`/customer/quote/${id}/new-item/`)
            .then(data => {
            runInAction(() => {
                if (this.quote) {
                    this.quote.line_items.push(data);
                }
                this.getQuote();
            });
        })
            .catch(err => {
            message.error('Error, please try again');
        });
    }
    exportToPdf() {
        return requests.get(`/customer/quote/${this.quote_uid}/export/`, {
            responseType: 'blob',
        });
    }
    sendToReview(payload = {}) {
        return requests.post(`/customer/quote/${this.quote_uid}/send-to-review/`, payload);
    }
    getTolerances() {
        requests
            .get(`/customer/part-tolerances`)
            .then(data => {
            runInAction(() => {
                this.partTolerances = data.results;
            });
        })
            .catch(error => {
            console.error(error);
        });
        requests
            .get(`/customer/hole-tolerances`)
            .then(data => {
            runInAction(() => {
                this.holeTolerances = data.results;
            });
        })
            .catch(error => {
            console.error(error);
        });
    }
    getFinishes() {
        requests
            .get(`/customer/finishes/`)
            .then(data => {
            runInAction(() => {
                this.finishes = data.results;
            });
        })
            .catch(error => {
            console.error(error);
        });
    }
    getCoatings() {
        requests
            .get(`/customer/coatings/`)
            .then(data => {
            runInAction(() => {
                this.coatings = data.results;
            });
        })
            .catch(error => {
            console.error(error);
        });
    }
    getMaterials() {
        requests
            .get(`/customer/materials/`)
            .then(data => {
            runInAction(() => {
                this.materials = data.results;
            });
        })
            .catch(error => {
            console.error(error);
        });
    }
    getAccounts(params = {}) {
        this.loading_accounts = true;
        return requests
            .get(`/staff/accounts/`, {
            params: params,
        })
            .then(data => {
            runInAction(() => {
                this.accounts = data.results;
            });
        })
            .finally(() => (this.loading_accounts = false));
    }
    getUsers(params = {}) {
        requests
            .get(`/staff/users/`, {
            params: params,
        })
            .then(data => {
            runInAction(() => {
                this.users = data.results;
            });
        });
    }
    lineItems() {
        return (this.quote?.line_items?.filter(item => {
            const { completed, status } = item.product.analysis_status.file_3d;
            return status == 'SUCCESS' && completed;
        }) || []);
    }
    safeLineItems() {
        return (this.quote?.line_items?.filter(item => {
            const { completed, status } = item.product.analysis_status.file_3d;
            return status == 'SUCCESS' && completed && !item.needs_manual_quote;
        }) || []);
    }
    hasErrorFiles() {
        return (this.quote &&
            this.quote.line_items?.filter(item => {
                return ((!item?.product?.material && !item?.product?.has_file_2d) ||
                    !item?.product?.finishes?.length ||
                    !item?.product?.coatings ||
                    (!item?.quantities && !this.isRequote));
            })?.length > 0);
    }
    needsRFQ() {
        return this.quote && this.quote.line_items?.filter(item => item?.needs_manual_quote)?.length > 0;
    }
    needs3DFiles() {
        return (this.quote &&
            this.quote.line_items?.filter(item => {
                return !item.product.has_file_3d;
            })?.length > 0);
    }
    needs2DFiles() {
        return (this.quote &&
            this.quote?.line_items?.filter(item => {
                return !item.product.has_file_2d;
            })?.length > 0);
    }
    setForStaff(for_staff) {
        this.for_staff = for_staff;
    }
    uploadAdditionalFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        this.loading = true;
        return requests.post(`/customer/quote/${this.quote_uid}/upload-additional-file/`, formData).finally(() => {
            runInAction(() => {
                this.loading = false;
            });
        });
    }
    get errorFields() {
        if (this.quote && this.quote.line_items) {
            return this.quote.line_items?.reduce((acc, item) => {
                const fieldName = (name) => {
                    return name + '__' + item.id;
                };
                if (!item?.product?.material) {
                    acc.push({
                        fieldName: fieldName('material'),
                        lineItem: String(item.id),
                    });
                }
                else {
                    if (item?.product?.material === 'Custom' && !item?.product?.custom_material) {
                        acc.push({
                            fieldName: fieldName('custom_material'),
                            lineItem: String(item.id),
                        });
                    }
                }
                if (item?.product?.finishes?.length === 0) {
                    acc.push({
                        fieldName: fieldName('finishes'),
                        lineItem: String(item.id),
                    });
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (item?.product?.finishes[0] === 'Custom' && !item?.product?.custom_finish) {
                        acc.push({
                            fieldName: fieldName('custom_finish'),
                            lineItem: String(item.id),
                        });
                    }
                }
                if (item?.product?.coatings?.length === 0) {
                    acc.push({
                        fieldName: fieldName('coatings'),
                        lineItem: String(item.id),
                    });
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (item?.product?.coatings[0] === 'Custom' && !item?.product?.custom_coating) {
                        acc.push({
                            fieldName: fieldName('custom_coating'),
                            lineItem: String(item.id),
                        });
                    }
                }
                return acc;
            }, []);
        }
        else {
            return [];
        }
    }
    get isRequote() {
        return this.quote?.type === 'Requote';
    }
    get isNewContact() {
        return this.is_new_contact;
    }
    setIsNewContact(is_new) {
        return (this.is_new_contact = is_new);
    }
    setCurrentUser(user) {
        return (this.current_user = user);
    }
    get getFirstName() {
        const firsName = ((this.quote && this.quote.contact?.first_name) || this.quote?.first_name) ??
            (LeadStore.lead?.first_name || '');
        return firsName;
    }
    get getLastName() {
        const lastName = ((this.quote && this.quote.contact?.last_name) || this.quote?.last_name) ??
            (LeadStore.lead?.last_name || '');
        return lastName;
    }
    get getEmail() {
        return (this.quote && this.quote.contact?.email) || this.quote?.email || '';
    }
    get getCompany() {
        const company = ((this.quote && this.quote.contact?.account) || this.quote?.company) ?? (LeadStore.lead?.company || '');
        return company;
    }
    get getPhone() {
        const phone = ((this.quote && this.quote.contact?.phone) || this.quote?.phone) ?? (LeadStore.lead?.phone || '');
        return phone;
    }
    get getZip() {
        return (this.quote && this.quote.contact?.zip_code) || this.quote?.shipping_zip_code || '';
    }
    get getTitle() {
        const title = (this.quote && this.quote.contact?.title) ?? (LeadStore.lead?.title || '');
        return title;
    }
    get getRelatedContact() {
        return (this.quote && this.quote.contact?.related_contact) || this.quote?.related_contact || '';
    }
    get getAdditionalFiles() {
        if (this.quote && this.quote.additional_files.length > 0) {
            const additional_files = [];
            this.quote.additional_files.map((file) => {
                additional_files.push({
                    uid: file.id.toString(),
                    name: file.filename,
                    size: 0,
                    type: '',
                });
            });
            return additional_files;
        }
        return [];
    }
    updateContactInfo(value) {
        if (this.quote && this.quote.contact) {
            this.quote.contact = { ...this.quote.contact, sms_notifications: value };
        }
    }
    getContactDetails(contact_id) {
        requests.get(`/customer/contacts/${contact_id}/`).then(data => {
            runInAction(() => {
                this.contact = data;
            });
        });
    }
    getAdditionalRequirementsContactAccount(contact_id) {
        requests
            .get(`/customer/contacts/${contact_id}/additional-requirements-documents/`)
            .then((data) => {
            runInAction(() => {
                this.default_documents = data.documents;
                const default_requirements = data.requirements
                    ? data.requirements.map(r => {
                        return {
                            key: r.requirement.id,
                            label: r.requirement.name,
                            value: r.value,
                            files: r.files,
                        };
                    })
                    : [];
                if (this.quote?.custom_requirements) {
                    if (typeof this.quote.custom_requirements !== 'string') {
                        const custom_requirements_saved = this.quote.custom_requirements
                            .map(r => {
                            return {
                                key: r.key,
                                label: r.label,
                                value: r.label,
                                files: null,
                            };
                        })
                            .filter(item => !default_requirements.some(dcr => dcr.key === item.key));
                        this.default_requirements = [...default_requirements, ...custom_requirements_saved];
                    }
                }
                else {
                    this.default_requirements = [...default_requirements];
                }
            });
        });
    }
    async updateRequiredQuantities(id, payload) {
        const response = await post(`/staff/quoted-products/${id}/get_dynamic_quantities/`, payload);
        if (response) {
            runInAction(() => {
                this.getQuote();
            });
        }
    }
    /**
     * Add Quoted line items of a specific quote
     */
    async getQuoteLineItems(quote_id) {
        this.loading_quote_items = true;
        await get(`/customer/quotes/${quote_id}/line-items/`)
            .then(response => {
            this.previous_quoted_parts = response.results;
        })
            .finally(() => (this.loading_quote_items = false));
    }
    clean_previous_items() {
        this.loading_quote_items = false;
        this.previous_quoted_parts = [];
    }
    /***
     * Use this option to update a field in the quote
     * @param key
     * @param value
     */
    async update_quote_field(key, value) {
        if (this.quote != undefined) {
            this.quote = {
                ...this.quote,
                [key]: value,
            };
            const payload = {
                [key]: value,
            };
            this.loading = true;
            await patch(`/customer/quote/${this.quote_uid}/`, payload).finally(() => {
                this.loading = false;
            });
            await this.getQuote();
        }
    }
    async update_account_field(account_id, payload = {}) {
        // This is only for staff users
        await patch(`/staff/accounts/${account_id}/`, payload);
    }
}
const quoteStore = new QuoteStore();
export default quoteStore;
