import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { QuoteStore } from '../../stores';
import { IQuoteListStaff } from '@partsbadger/types';
import { get, useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import { ColumnProps } from 'antd/es/table';
import { DateRender, Price } from '@partsbadger/library';

import { EditOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import ActionMenu from '../TableActions/TableActions';
import FileDownload from 'js-file-download';
import { BadgerTable, BadgerSearch } from '../badger-ui';

const AccountQuotes = (props: { account_id: number }) => {
    const { account_id } = props;
    const history = useHistory();
    const searchParams = useQueryParameters();

    const page = parseInt(searchParams.get('page') ?? '1');
    const search = searchParams.get('search') ?? '';

    useEffect(() => {
        QuoteStore.getByAccount(account_id, {
            limit: 10,
            search: search,
            page: page,
        });
        return () => {};
    }, [account_id, search, page]);

    const downloadQuote = (name: string, id: number) => {
        const url = `/staff/quotes/${id}/export/`;
        get(url, { responseType: 'blob' })
            .then(data => {
                FileDownload(data, name + '.pdf');
            })
            .catch(error => {});
    };
    const isDisabled = (stage: string) => {
        return stage == 'Draft';
    };

    const columns: ColumnProps<IQuoteListStaff>[] = [
        {
            title: 'Quote Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            disabled={isDisabled(record.stage)}
                            onClick={() => history.push(`/quotes/${record.id}/details/`)}
                        >
                            {record.name}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'RFQ Number',
            dataIndex: 'rfq',
            render: (text, record) => {
                return <div>{record.rfq}</div>;
            },
        },
        {
            title: 'Stage',
            dataIndex: 'Stage',
            render: (text, record) => {
                return <div>{record.stage}</div>;
            },
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            render: (text, record) => {
                return <div>{record.created_by.fullname}</div>;
            },
        },

        {
            title: 'Completed Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.completed_at ? <DateRender value={record.completed_at} /> : '-'}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                const actions =
                    record.stage === 'Draft'
                        ? [
                              {
                                  key: 'continue-submitting',
                                  icon: <EditOutlined />,
                                  text: 'Continue Submitting',
                                  // link: `/get-quote/?uid/${record.uid}/`,
                              },
                          ]
                        : [
                              {
                                  key: 'download-pdf',
                                  icon: <FilePdfOutlined />,
                                  text: 'Download',
                                  onClick: () => downloadQuote(record.name, record.id),
                              },
                              {
                                  key: 'view-in-zoho',
                                  icon: <FilePdfOutlined />,
                                  text: 'View in Zoho',
                                  link: record.zoho_id ? `${ZOHO_BASE_URL}/Quotes/${record.zoho_id}` : undefined,
                              },
                          ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    const { pagination, loading, entities } = QuoteStore;

    return (
        <div>
            <Row>
                <BadgerSearch />
            </Row>

            <br />

            <Row>
                <BadgerTable<IQuoteListStaff>
                    rowKey={'id'}
                    dataSource={entities}
                    loading={loading}
                    columns={columns}
                    pagination={{
                        defaultPageSize: pagination.defaultPageSize,
                        pageSize: pagination.defaultPageSize,
                        totalRecords: pagination.total,
                    }}
                ></BadgerTable>
            </Row>
        </div>
    );
};

export default observer(AccountQuotes);
