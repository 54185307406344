import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { notification, Spin } from 'antd';
import { QuoteStore } from '../../Stores';
import { default as InputType } from './InputTypes';
import { ErrorRender } from '@partsbadger/library';
const FormItem = Form.Item;
const loadIcon = React.createElement(LoadingOutlined, { style: { fontSize: 10 }, spin: true });
let timeout = null;
export const Cell = (props) => {
    const [errors, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { dataIndex, record, width, editable, editing, handleEdit, lineItems, ...restProps } = props;
    const value = dataIndex ? record[dataIndex] : null;
    if (!editable) {
        return (React.createElement("td", { style: { width: width }, className: `class-${dataIndex}` },
            React.createElement("div", { style: { maxWidth: '100%' }, className: 'editable-cell-value-wrap' }, restProps.children)));
    }
    if (editable && dataIndex && editing) {
        const properties = {
            size: 'small',
            showSearch: true,
            dataIndex: dataIndex,
            className: dataIndex,
            defaultValue: value,
            record: record,
            suffixIcon: loading ? (React.createElement(Spin, { indicator: loadIcon, style: { fontSize: '2px' }, spinning: true, size: 'small' })) : null,
            style: { width: '100%' },
            manufacturing_type: record.manufacturing_type,
            onChange: (new_value) => {
                if (timeout) {
                    clearTimeout(timeout);
                }
                let previous_value = record[dataIndex];
                record[dataIndex] = new_value;
                if (!new_value == undefined) {
                    setError(true);
                    return;
                }
                else {
                    setError(false);
                }
                // Calculate the manual adjustment given the unite price
                if (dataIndex === 'unit_price' && new_value && record.manual_adjustment) {
                    const price = QuoteStore.unitPricesOriginals.find(item => item.line_item_id == record.id);
                    let manual_adjustment = record.manual_adjustment;
                    if (price) {
                        previous_value = Number(price.unit_price);
                        manual_adjustment = price.manual_adjustment;
                    }
                    record.manual_adjustment = ((new_value * manual_adjustment) / previous_value)?.toFixed(3);
                }
                // This allows you to keep the partsbadger_production field synchronized to true or false, according to the method
                record['partsbadger_production'] =
                    record['partsbadger_production_method'] === 'Fastlane' ||
                        record['partsbadger_production_method'] === 'QuickTurn' ||
                        record['partsbadger_production_method'] === 'Traditional' ||
                        record['partsbadger_production_method'] === 'Swiss';
                timeout = setTimeout(async () => {
                    setLoading(true);
                    await QuoteStore.saveItem(record)
                        .then(() => {
                        setError(false);
                        setLoading(false);
                    })
                        .catch(error => {
                        notification.error({
                            key: 'line-item-error',
                            message: 'Error',
                            description: React.createElement(ErrorRender, { error: error }),
                            placement: 'topRight',
                            duration: 5,
                            maxCount: 1,
                        });
                        setError(true);
                        setLoading(false);
                    });
                }, 1);
            },
        };
        if (dataIndex === 'has_material_cert') {
            properties['defaultChecked'] = value;
        }
        if (dataIndex === 'is_fai_required') {
            properties['defaultChecked'] = value;
        }
        // Controlled inputs
        if (dataIndex === 'unit_price' ||
            dataIndex === 'manual_adjustment' ||
            dataIndex === 'product__material' ||
            dataIndex === 'product__coatings' ||
            dataIndex === 'product__finishes') {
            properties['value'] = value;
        }
        return (React.createElement("td", { style: { minWidth: width } },
            React.createElement(FormItem, { style: { margin: 0, textAlign: 'center' }, validateStatus: errors ? 'error' : 'success' },
                React.createElement(InputType, { ...properties }))));
    }
    else {
        return (React.createElement("td", { style: { minWidth: width }, onClick: () => {
                if (handleEdit && !editing) {
                    handleEdit(record?.id);
                }
            } }, restProps.children));
    }
};
