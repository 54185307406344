import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import { AccountDetail } from '../../stores/types';
import { TaxExemptForm, TaxExemptList } from '../Contact/taxes';

interface Props {
    account: AccountDetail;
}

interface ITaxExemptForm {
    id: 0;
    account: 0;
    tax_form: '';
    tax_id: '';
    tax_expiration: '';
    tax_exemption: '';
    tax_exemption_expiration: '';
}

const AccountTaxExemptions: React.FC<Props> = ({ account }) => {
    const [addNew, setAddNew] = useState<boolean>();
    const [taxForm, setTaxForm] = useState<ITaxExemptForm | null>(null);

    const editAddress = (record: ITaxExemptForm) => {
        setTaxForm(record);
        setAddNew(false);
    };

    return (
        <div>
            <>
                {addNew || taxForm?.id ? (
                    <div style={{ marginTop: 10 }}>
                        <TaxExemptForm
                            // @ts-ignore
                            tax_form={taxForm}
                            account={account.id}
                            callback={(): any => {
                                setAddNew(false);
                                setTaxForm(null);
                            }}
                        />
                    </div>
                ) : (
                    <div style={{ marginTop: 10 }}>
                        <TaxExemptList account_id={account.id} handleEdit={editAddress} />

                        <div className={'flex flex-row-reverse pt-4'}>
                            <Button type={'default'} onClick={() => setAddNew(true)}>
                                Add New{' '}
                            </Button>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default AccountTaxExemptions;
