import { PlusOutlined, EditOutlined, LoadingOutlined, MinusOutlined, CloseOutlined, PaperClipOutlined, UnlockOutlined, } from '@ant-design/icons';
import { Alert, Badge, Button, Checkbox, Divider, Form, Input, Spin, Typography, Modal, List, Tag, Tooltip, Image, notification, } from 'antd';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';
import { UploadDrawing, UploadOtherFiles } from './UploadParts';
import { PartDimensions } from './PartDimensions';
import { PartPreview } from './PartPreview';
import { Price, FormErrorParser } from '@partsbadger/library';
import QuoteStore from '../stores/QuoteStore';
import QuoteLineQuantityItem from './QuoteLineQuantityItem';
import QuoteLineItem from './QuoteLineItem';
import { QuestionMark } from './QuestionMark';
import DuplicationReasonPopup from './DuplicationReasonPopup';
import dayjs from 'dayjs';
let busy = null;
const { Paragraph, Text, Title, Link } = Typography;
const LineItemSummary = ({ index, item }) => {
    const { product, needs_manual_quote } = item || {};
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "product-summary" },
            React.createElement("div", null,
                React.createElement(Text, { strong: true, className: "mr-2" },
                    "Varient ",
                    index + 1,
                    ":"),
                item.manufacturing_type ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "text-muted" }, "Process:"),
                    " ",
                    React.createElement("span", null, item.manufacturing_type),
                    React.createElement(Divider, { type: 'vertical' }))) : null,
                React.createElement("span", { className: "text-muted" }, "Material:"),
                " ",
                React.createElement("span", null, product.material),
                React.createElement(Divider, { type: 'vertical' }),
                React.createElement("span", { className: "text-muted" }, "Surface Finish:"),
                " ",
                React.createElement("span", null, product.finishes),
                React.createElement(Divider, { type: 'vertical' }),
                React.createElement("span", { className: "text-muted" }, "Coating:"),
                " ",
                React.createElement("span", null, product.coatings)),
            React.createElement("div", { className: "d-inline-block" },
                React.createElement("span", { className: "text-muted" }, "Qty:"),
                " ",
                React.createElement("span", null, item.quantity || item.quantities),
                React.createElement(Divider, { type: 'vertical' }),
                needs_manual_quote ? (React.createElement(Text, null, "Manual Quote Required")) : (React.createElement(Text, null,
                    React.createElement(Price, { value: item.total_price })))))));
};
const HeaderTitle = observer(({ setLoading, item, collapsed, analyzer_all_finished }) => {
    const { product } = item;
    const [modalVisible, setModalVisible] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "product-name-wrapper", id: String(item.id) }, !QuoteStore.isRequote ? (React.createElement(React.Fragment, null,
            React.createElement(Modal, { open: modalVisible, onCancel: () => setModalVisible(false), title: 'Edit Product Name', footer: null, centered: true },
                React.createElement(Form, { onFinish: values => {
                        const payload = {
                            id: item.id,
                            product: {
                                name: values.name,
                            },
                        };
                        setLoading(true);
                        QuoteStore.updateLineItem(payload)
                            .then(() => {
                            setLoading(false);
                            setModalVisible(false);
                        })
                            .catch(err => console.log(err));
                    } },
                    React.createElement("div", { className: "d-flex flex-row align-items-center justify-content-between" },
                        React.createElement(Form.Item, { name: "name", noStyle: true, className: "flex-grow-1" },
                            React.createElement(Input, { defaultValue: product?.name?.toUpperCase() })),
                        React.createElement(Button, { htmlType: "submit", type: "primary", className: "ml-2" }, "Save")))),
            React.createElement(Typography.Title, { level: 4, className: 'product-name m-0' },
                product?.name?.toUpperCase(),
                !collapsed && analyzer_all_finished ? (React.createElement(Tooltip, { placement: "top", title: "Edit Name" },
                    React.createElement(EditOutlined, { onClick: () => setModalVisible(true), className: "ml-2" }))) : null))) : (React.createElement(Paragraph, { className: 'product-name', style: {
                display: 'inline',
                fontSize: 18,
                margin: 0,
                fontWeight: 'bold',
            } }, product?.name?.toUpperCase()))),
        item?.product?.dimensions_dict?.volume ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'product-dimensions' },
                React.createElement(Tag, { className: "mb-0", icon: React.createElement(PaperClipOutlined, null) }, item?.product?.attachments?.file3d?.filename),
                React.createElement(PartDimensions, { ...item?.product?.dimensions_dict })))) : null));
});
export const MasterProduct = observer(({ for_staff, item, line_items, isSelected, handleSelect, ...props }) => {
    const product = item?.product;
    const [loading, setLoading] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [form] = Form.useForm();
    const { analysis_status } = item?.product || {};
    const analyzer_2d_finished = analysis_status.file_2d?.completed;
    const analyzer_3d_finished = analysis_status.file_3d?.completed;
    const analyzer_2d_success = analysis_status?.file_2d?.status === 'SUCCESS';
    const analyzer_3d_success = analysis_status?.file_3d?.status === 'SUCCESS';
    const analyzer_all_finished = analysis_status.file_2d?.completed && analysis_status.file_3d?.completed;
    const isCollapsed = QuoteStore.collapsedLineItems.includes(item.id);
    const [showDuplicatonReasonPopup, setShowDuplicatonReasonPopup] = useState(false);
    const [filesLoading, setFilesLoading] = useState(false);
    const [duplicatingReasonType, setDuplicatingReasonType] = useState('request_change_files');
    const [analyzerTimeout, setAnalyzerTimeout] = useState(false); // State to manage the timeout
    const ANALYSIS_TIMEOUT = 60000; // 60 seconds
    // Get remote update on tighter tolerance
    React.useEffect(() => {
        if (item.product.tighter_tolerances) {
            form.setFieldsValue({
                tighter_tolerances: item.product.tighter_tolerances,
            });
        }
    }, [item.product?.tighter_tolerances]);
    React.useEffect(() => {
        // This effect sets a timeout to trigger analyzerTimeout if the analysis takes too long (ANALYSIS_TIMEOUT).
        // If the analysis finishes before the timeout, the timeout is cleared.
        let timeoutId;
        if (!analyzer_all_finished) {
            timeoutId = setTimeout(() => {
                setAnalyzerTimeout(true);
            }, ANALYSIS_TIMEOUT);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [analyzer_all_finished]);
    const handleSubmit = async () => {
        form.validateFields()
            .then(async (values) => {
            const payload = {
                id: item.id,
                product: {
                    tighter_tolerances: values[fieldName('tighter_tolerances')],
                    customer_notes: values[fieldName('customer_notes')],
                },
                master_product: {
                    tighter_tolerances: values[fieldName('tighter_tolerances')],
                },
                batch_shipment: values[fieldName('batch_shipment')],
            };
            setLoading(true);
            QuoteStore.updateLineItem(payload).then(() => {
                setLoading(false);
            });
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const fieldName = (name) => {
        return name + '__' + item.id;
    };
    const handleChange = () => {
        if (busy) {
            clearTimeout(busy);
        }
        busy = setTimeout(handleSubmit, 400);
    };
    const handleReasonSelectConfirm = (reason) => {
        setFilesLoading(true);
        QuoteStore.duplicateMasterProduct(item.master_product_details.id, reason).finally(() => {
            setFilesLoading(false);
        });
        setShowDuplicatonReasonPopup(false);
    };
    const handleReasonSelectCancel = () => {
        setShowDuplicatonReasonPopup(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: confirmDeleteModalVisible, onCancel: () => setConfirmDeleteModalVisible(false), okText: "Remove", centered: true, onOk: () => {
                QuoteStore.deleteMasterProduct(item.product.master_product);
                setConfirmDeleteModalVisible(false);
            }, closable: false, width: 400 },
            React.createElement(Title, { level: 4 }, `Remove Item From Quote?`)),
        React.createElement(DuplicationReasonPopup, { isOpen: showDuplicatonReasonPopup, partName: item.name, type: duplicatingReasonType, onConfirm: handleReasonSelectConfirm, onCancel: handleReasonSelectCancel }),
        React.createElement(Form, { form: form, colon: false },
            React.createElement("div", { onClick: () => {
                    if (isCollapsed) {
                        QuoteStore.setCollapsed(item.id);
                    }
                }, className: `${isCollapsed ? 'quote-product-card cursor-pointer' : 'quote-product-card'}` },
                for_staff && analyzer_all_finished && product?.quickturn_summary?.is_quickturn_part ? (React.createElement(Alert, { type: "success", className: "quickturn-notification", showIcon: true, description: React.createElement(React.Fragment, null,
                        React.createElement("span", null,
                            "This part may be designed and purchased from our",
                            ' ',
                            React.createElement("a", { href: "https://portal.parts-badger.com/quick-turn/design", rel: "noreferrer", target: "_blank" }, "QuickTurn Portal"))) })) : null,
                React.createElement("div", { className: "quote-product-header" },
                    React.createElement("div", { className: "d-flex align-items-center" },
                        React.createElement("div", { className: "mr-4", onClick: e => e.stopPropagation() },
                            React.createElement(Checkbox, { checked: isSelected, onChange: e => {
                                    handleSelect(e.target.checked);
                                } })),
                        isCollapsed && product.attachments?.file3d?.filename && product.thumbnail ? (React.createElement(Image, { className: "thumbnail-3d-file mr-1", style: { maxWidth: '75px' }, alt: product.attachments?.file3d?.filename, src: product.thumbnail, preview: false })) : null,
                        React.createElement("div", { className: "flex-grow-1" },
                            React.createElement(HeaderTitle, { item: item, setLoading: setLoading, collapsed: isCollapsed, analyzer_all_finished: analyzer_all_finished }))),
                    React.createElement("div", { className: "product-card-actions" },
                        (product.has_file_2d && analyzer_2d_success) ||
                            (product.has_file_3d && analyzer_3d_success) ? (React.createElement(AnimatePresence, null,
                            React.createElement(motion.span, { initial: {
                                    scaleY: 0,
                                    scaleX: 0,
                                }, animate: {
                                    scaleX: 1,
                                    scaleY: 1,
                                    opacity: 1,
                                }, exit: {
                                    scaleX: 0,
                                    scaleY: 0,
                                } },
                                React.createElement(Tooltip, { placement: "top", title: isCollapsed ? 'Expand' : 'Collapse' },
                                    React.createElement(Button, { type: "link", className: "text-primary", icon: isCollapsed ? React.createElement(PlusOutlined, null) : React.createElement(MinusOutlined, null), onClick: e => {
                                            e.stopPropagation();
                                            QuoteStore.setCollapsed(item.id);
                                        } }))))) : null,
                        React.createElement("span", { className: 'link' },
                            React.createElement(Tooltip, { placement: "top", title: "Delete Part" },
                                React.createElement(Button, { type: "link", icon: React.createElement(CloseOutlined, null), onClick: () => setConfirmDeleteModalVisible(true) }))))),
                analyzer_all_finished ? (React.createElement("div", { className: "w-100" },
                    React.createElement(AnimatePresence, null, isCollapsed ? (React.createElement(motion.div, { initial: {
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                        }, animate: {
                            zIndex: 1,
                            height: 'auto',
                            scaleY: 1,
                            opacity: 1,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                    delay: 0,
                                },
                            },
                        }, exit: {
                            zIndex: 0,
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                },
                            },
                        } },
                        React.createElement("div", { className: "product-collapsed-container" },
                            React.createElement(List, { itemLayout: "horizontal", dataSource: line_items, renderItem: (line_item, index) => (React.createElement(List.Item, { className: "p-0 py-2" },
                                    React.createElement(LineItemSummary, { key: index, item: line_item, index: index }))) })))) : null))) : null,
                product.has_file_2d && !analyzer_2d_finished && !analyzerTimeout ? (React.createElement("div", { className: 'loader pb-4' },
                    React.createElement(Spin, { indicator: React.createElement(React.Fragment, null,
                            React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true }),
                            React.createElement(Text, { className: "d-block text-primary" }, "Analyzing 2D File...")), style: { display: 'block' } }))) : product.has_file_3d && !analyzer_3d_finished && !analyzerTimeout ? (React.createElement("div", { className: 'loader pb-4' },
                    React.createElement(Spin, { indicator: React.createElement(React.Fragment, null,
                            React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true }),
                            React.createElement(Text, { className: "d-block text-primary" }, "Analyzing Geometry...")), style: { display: 'block' } }))) : analyzer_all_finished || analyzerTimeout ? (React.createElement(React.Fragment, null,
                    React.createElement(AnimatePresence, null, !isCollapsed ? (React.createElement(motion.div, { initial: {
                            zIndex: 1,
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                        }, animate: {
                            height: 'auto',
                            opacity: 1,
                            scaleY: 1,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                    delay: 0,
                                },
                            },
                        }, exit: {
                            zIndex: 0,
                            height: 0,
                            opacity: 0,
                            scaleY: 0,
                            transition: {
                                height: {
                                    duration: 0.25,
                                },
                                opacity: {
                                    duration: 0.25,
                                },
                            },
                        } },
                        React.createElement("div", { className: 'quote-product-body' },
                            React.createElement("div", { className: "quote-product-preview" },
                                React.createElement(Spin, { spinning: filesLoading },
                                    React.createElement(Form, { layout: 'vertical', className: "form-master-product" },
                                        React.createElement(PartPreview, { item: item }),
                                        React.createElement("div", { className: "d-flex flex-column justify-content-center text-center p-3" },
                                            React.createElement(UploadDrawing, { item: item, readOnly: !item.master_product_details.editable }),
                                            React.createElement(UploadOtherFiles, { item: item, readOnly: !item.master_product_details.editable }),
                                            !for_staff &&
                                                product.attachments?.file3d?.filename &&
                                                item.master_product_details.editable ? (React.createElement(Badge.Ribbon, { text: "NEW", color: "#cd2027", style: {
                                                    top: '4px',
                                                    height: '1rem',
                                                    fontSize: '10px',
                                                    fontWeight: 600,
                                                    lineHeight: '1rem',
                                                } },
                                                React.createElement(Tooltip, { title: `Coming Soon in ${dayjs().year()}!`, trigger: ['click'], color: "rgba(0,0,0,0.8)" },
                                                    React.createElement(Button, { className: "master-product-dfm-analysis-btn ant-btn-xs", block: true }, "DFM Analysis")))) : null,
                                            !item.master_product_details.editable ? (React.createElement(Link, { style: { color: '#1890ff' }, onClick: () => {
                                                    setDuplicatingReasonType('request_change_files');
                                                    setShowDuplicatonReasonPopup(true);
                                                } },
                                                React.createElement(UnlockOutlined, null),
                                                " ",
                                                "Would you like to update the files? Click here.")) : null)))),
                            React.createElement("div", { className: "quote-product-details" },
                                React.createElement(React.Fragment, null,
                                    line_items?.map((item, index) => {
                                        if (item.has_dynamic_quantities) {
                                            const rendered_items = item.quote_item_quantities
                                                .filter(i => i.displayed)
                                                .map((quantity_item, i) => {
                                                return (React.createElement(QuoteLineQuantityItem, { key: i, item: {
                                                        quantity: quantity_item.quantity,
                                                        lead_time: quantity_item.lead_time,
                                                        unit_price: Number(quantity_item.unit_price),
                                                        total_price: Number(quantity_item.total_price),
                                                        safe_auto_quote: quantity_item.safe_auto_quote,
                                                    } }));
                                            });
                                            return (React.createElement("div", { className: 'quantity-items' },
                                                React.createElement(Spin, { spinning: loading },
                                                    React.createElement("div", { className: 'flex flex-row' },
                                                        React.createElement("div", { className: 'w-full' },
                                                            React.createElement("div", { className: 'flex flex-row' },
                                                                React.createElement("div", { className: 'flex flex-row w-full' },
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Quantity")),
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Lead Time")),
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Unit Price")),
                                                                    React.createElement("div", { className: 'w-1/4 mr-4' },
                                                                        React.createElement("div", { className: 'flex justify-center font-semibold' }, "Total Price")))),
                                                            rendered_items))),
                                                React.createElement("hr", null)));
                                        }
                                        else {
                                            if (item.master_product_details.quotable) {
                                                return React.createElement(QuoteLineItem, { index: index, item: item });
                                            }
                                        }
                                    }),
                                    item.master_product_details.quotable && (React.createElement("div", { className: "quote-product-requirements" },
                                        for_staff &&
                                            item.already_quote_product &&
                                            item.already_quote && (React.createElement("div", { className: "text-center mt-6" },
                                            React.createElement(Spin, { spinning: loading },
                                                React.createElement(Alert, { message: [
                                                        React.createElement("strong", { key: "m-1" }, "It seems that this product has already been quoted."),
                                                        React.createElement(Divider, { key: "d-1", type: "vertical" }),
                                                        React.createElement("span", { key: "m-2", className: "link", onClick: () => {
                                                                setLoading(true);
                                                                QuoteStore.getRequote(item.already_quote).then((r) => {
                                                                    location.replace('/get-quote/?uid=' +
                                                                        r.uid);
                                                                    setLoading(false);
                                                                });
                                                            } }, "Click here to re quote!"),
                                                    ], type: "info", description: "You can request a re quote or continue to add items and submit your request.", showIcon: true })))),
                                        React.createElement("div", { className: "d-flex flex-column align-items-start" },
                                            React.createElement("div", { className: "d-flex flex-row align-items-baseline" },
                                                React.createElement(Button, { className: "ant-btn-xs mb-2", size: "small", icon: React.createElement(PlusOutlined, { style: { fontSize: '16px' } }), loading: duplicating, onClick: () => {
                                                        setDuplicating(true);
                                                        QuoteStore.duplicateItem(item.product.master_product).then(r => {
                                                            setDuplicating(false);
                                                        });
                                                    } }, "Add New Variation"),
                                                React.createElement(QuestionMark, { placement: "right" }, "Add a part variation to see price differences")),
                                            React.createElement(React.Fragment, null, !QuoteStore.isRequote && (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: "d-flex flex-column" },
                                                    React.createElement(Form.Item, { name: fieldName('batch_shipment'), rules: [
                                                            {
                                                                required: false,
                                                                message: 'Please insert a value',
                                                            },
                                                        ], noStyle: true, valuePropName: 'checked', initialValue: item.batch_shipment },
                                                        React.createElement(Checkbox, { onChange: handleChange, className: "text-base", style: { margin: '6px 0' } },
                                                            React.createElement(Tooltip, { title: "Check this box if you require the parts to be delivered in batches" }, "Batch Releases"))),
                                                    React.createElement(Form.Item, { name: fieldName('tighter_tolerances'), rules: [
                                                            {
                                                                required: false,
                                                                message: 'Please insert a value',
                                                            },
                                                        ], noStyle: true, valuePropName: 'checked', initialValue: item.product
                                                            ?.tighter_tolerances },
                                                        React.createElement(Checkbox, { onChange: handleChange, className: "text-base", style: { margin: 0 } }, "This part has feature tolerance of +/- 0.001\" or less"))))))))),
                                    QuoteStore.isRequote && !item.master_product_details.quotable && (React.createElement("div", { className: "quote-no-quotable-message" },
                                        React.createElement(Alert, { message: 'No Quoted Part', description: React.createElement("span", null,
                                                `
                                                                                ${item.master_product_details.notes}`,
                                                React.createElement("br", null),
                                                'If you believe that modifying certain details could make the part quotable, please ',
                                                React.createElement(Typography.Link, { style: { color: '#ff7875' }, onClick: () => {
                                                        setDuplicatingReasonType('request_requote');
                                                        setShowDuplicatonReasonPopup(true);
                                                    } }, "click here"),
                                                ' to request a new requote.'), type: "warning", showIcon: true })))))))) : null))) : null))));
});
