import React from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
const styles = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 5,
    alignItems: 'center',
};
export const Edit3DFilesApproval = observer(({ record, renderStepFile, setShowAddStepFile }) => {
    return (React.createElement("div", { className: 'flex flex-col w-full' },
        React.createElement("div", { className: 'file-wrapper', style: styles }, record?.name_3d_file ? (React.createElement(React.Fragment, null, record?.name_3d_file && (React.createElement(React.Fragment, null,
            React.createElement("span", { title: 'Preview 3D file', className: 'link', onClick: () => {
                    if (record.production_3d_file_id) {
                        renderStepFile(record.production_3d_file_id);
                    }
                } },
                React.createElement("a", null, record.name_3d_file)),
            ' ',
            "\u00A0",
            React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: () => setShowAddStepFile(true) }))))) : (React.createElement(React.Fragment, null, record.production_3d_file ? (React.createElement(React.Fragment, null,
            React.createElement("span", { title: 'Preview 3d file', className: 'link', onClick: () => {
                    if (record?.production_3d_file?.id) {
                        renderStepFile(record.production_3d_file.id);
                    }
                } },
                React.createElement("a", null,
                    " ",
                    record.production_3d_file.filename)),
            ' ',
            "\u00A0",
            React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: () => setShowAddStepFile(true) }))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { style: { color: 'red' } }, " Pending"),
            " \u00A0",
            React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: () => setShowAddStepFile(true) }))))))));
});
