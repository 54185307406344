import { get } from '@partsbadger/utils';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
const StepFile = ({ master_product, showAddStepFile, renderFile, removeFile, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("strong", null, "3D File:"),
        " \u00A0",
        master_product?.step_file ? (React.createElement(React.Fragment, null,
            React.createElement("span", { title: 'Preview 3d file', className: 'link', onClick: renderFile }, master_product.step_file.filename),
            ' ',
            master_product.analysis_status.file_3d.status == 'FAILURE' && (React.createElement(React.Fragment, null,
                React.createElement(WarningOutlined, { style: {
                        color: 'red',
                    }, title: master_product.analysis_status.file_3d.message != null &&
                        typeof master_product.analysis_status.file_3d.message === 'string'
                        ? master_product.analysis_status.file_3d.message
                        : 'Error analyzing 3d file' }))),
            "\u00A0",
            React.createElement("span", { className: 'link', onClick: e => {
                    e.stopPropagation();
                    get(`/staff/step-files/${master_product.step_file.id}/signed-url/`).then((response) => {
                        window.open(response.url, '_blank');
                    });
                } },
                React.createElement(LegacyIcon, { title: 'Download 3D file', type: 'download' }),
                ' '),
            ' ',
            "\u00A0",
            master_product.editable && (React.createElement(React.Fragment, null,
                React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: showAddStepFile }),
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Remove 3D file', className: 'link', type: 'close', style: { color: 'red' }, onClick: removeFile }),
                "\u00A0")))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { style: { color: 'red' } }, " Pending"),
            " \u00A0",
            master_product.editable && (React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: showAddStepFile }))))));
};
export default StepFile;
