import React from 'react';
import { observer } from 'mobx-react';
import { Modal, notification } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import ShipmentForm from '../Form/ShipmentForm';
import { ErrorRender } from '@partsbadger/library';
export const CreateBox = observer((props) => {
    const { carrier, shipping_method, shipping_account, bill_to_customer } = IntakeStore.boxInfo;
    const outtake = IntakeStore.previousOuttakes?.find(obj => obj.id === IntakeStore.boxInfo['outtakeId']);
    return (React.createElement(React.Fragment, null, IntakeStore.createBoxVisible && (React.createElement(Modal, { title: "Create Shipment", visible: true, width: 900, onCancel: () => IntakeStore.setCreateBoxVisible(false, IntakeStore.boxInfo['outtakeId']), cancelText: "Close", footer: [] },
        React.createElement(ShipmentForm, { isTester: props.isTester, box: {
                carrier: carrier ?? 'FedEX',
                shipping_method: shipping_method,
                shipping_account: shipping_account,
                bill_to_customer: bill_to_customer,
                // If customer pays for the shipment, default shipping cost to 0
                shipping_cost: shipping_account.toLowerCase() != 'pb' ? 0 : null,
                opt_out_packing_slip: outtake?.sales_order.notification_opt_out ?? false,
            }, shipping_notes: outtake?.sales_order?.shipping_notes, customer_shipping_notes: outtake?.sales_order?.customer_shipping_notes, outbound_destination: outtake?.outbound_destination ? outtake.outbound_destination : '', handleSave: async (payload) => {
                await IntakeStore.createBox(payload)
                    .then(() => {
                    IntakeStore.setCreateBoxVisible(false, IntakeStore.boxInfo['outtakeId']);
                })
                    .catch(e => {
                    notification.error({
                        message: 'Error creating shipment',
                        description: React.createElement(ErrorRender, { error: e }),
                    });
                });
            }, handleClose: () => {
                IntakeStore.setCreateBoxVisible(false, IntakeStore.boxInfo['outtakeId']);
            } })))));
});
