/**
 *
 */
const precision = 12;
function count_sig_figs() {
    return 12;
}
export const in3_to_mm3 = (value_inches) => {
    const precision = count_sig_figs();
    const result = (value_inches * 16387.064).toPrecision(precision);
    return normalize_number(result);
};
export const mm3_to_in3 = (value_mm) => {
    const precision = count_sig_figs();
    const result = (value_mm / 16387.064).toPrecision(precision);
    return normalize_number(result);
};
export const in_to_mm = (value_inches) => {
    const result = (value_inches * 25.4).toPrecision(precision);
    return normalize_number(result);
};
export const mm_to_in = (value_mm) => {
    const result = (value_mm * 0.0393701).toPrecision(precision);
    return normalize_number(result);
};
export const normalize_number = (value) => {
    return parseFloat(value).toFixed(3);
};
