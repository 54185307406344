import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { get } from '../utils/api';
import IntakeStore from '../Stores/IntakeStore';
import SalesOrderForm from './SalesOrderForm';
import RMA from './RMA';
import { useQueryParameters } from '@partsbadger/utils';
import { Button, DatePicker, message } from 'antd';
import InventoryStore from '../Stores/InventoryStore';
import PurchaseOrderStore from '../Stores/PurchaseOrderStore';
import PurchaseOrderLineItems from './PurchaseOrders/PurchaseOrderLineItems';
import { hasPermission } from 'quotes-frontend/src/components/User';
import { useHistory, useParams } from 'react-router-dom';
const { RangePicker } = DatePicker;
import HeadFilters from '../Componets/IntakeOuttake/HeadFilters';
import BarcodeScanner from './BarcodeScanner';
import SalesOrderStore from '../Stores/SalesOrderStore';
import VendorStore from '../Stores/VendorStore';
import fileDownload from 'js-file-download';
function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
}
export const IntakeOuttake = observer((props) => {
    const [ButtonKPI, setButtonKPI] = useState(false);
    const [isGettingKPI, setIsGettingKPI] = useState(false);
    const [ButtonBox, setButtonBox] = useState(false);
    const [KPIRange, setKPIRange] = useState(null);
    const [KPIs, setKPIs] = useState(null);
    const [rmaData, setRmaData] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const history = useHistory();
    const params = useParams();
    const query_params = useQueryParameters();
    const { vendorType } = IntakeStore;
    const getParamId = query_params.get('id');
    const getParamType = query_params.get('type');
    const switchVendorType = async (getVendorType) => {
        if (getParamId) {
            switch (getVendorType) {
                case 'barcode-vendor': {
                    history.push(`?id=${getParamId}&type=${getVendorType}`);
                    await SalesOrderStore.getSalesOrder(getParamId);
                    SalesOrderStore.salesOrder?.line_items.forEach(item => {
                        const data = {
                            ...SalesOrderStore.salesOrder,
                            id: item.id,
                            vendorProductId: item.product_zid,
                            line_items: [item],
                        };
                        VendorStore.setProductData(data);
                    });
                    break;
                }
                case 'rma':
                    try {
                        const res = await InventoryStore.get(getParamId, 'RMA');
                        setRmaData(res);
                        history.push(`?id=${getParamId}&type=${getVendorType}`);
                    }
                    catch (err) {
                        message.error(`Error; ${err}`);
                    }
                    break;
                case 'purchase-orders':
                    try {
                        PurchaseOrderStore.clearData();
                        await PurchaseOrderStore.get(getParamId);
                        history.push(`?id=${getParamId}&type=${getVendorType}`);
                    }
                    catch (err) {
                        message.error(`Error; ${err}`);
                    }
                    break;
                default:
                    history.push(`?id=${getParamId}&type=${getVendorType}`);
                    IntakeStore.getSalesOrder(getParamId, IntakeStore.receiveOrShip);
                    IntakeStore.getPurchaseOrderForSalesOrder(getParamId);
                    break;
            }
        }
    };
    useEffect(() => {
        const getVendorType = getParamType ? getParamType : vendorType;
        IntakeStore.setVendorType(getVendorType);
        switchVendorType(getVendorType);
    }, [getParamId]);
    return (React.createElement("div", { className: "container" },
        React.createElement(HeadFilters, null),
        React.createElement("div", { className: 'intake-outtake-filters flex flex-row items-center justify-center' }),
        React.createElement("div", { className: "forms" },
            hasPermission('intake.view_salesorderintake') && (React.createElement(React.Fragment, null,
                vendorType === 'barcode-vendor' && React.createElement(BarcodeScanner, { id: getParamId }),
                vendorType === 'sales-order' && (React.createElement(SalesOrderForm, { isTester: props.isTester, vendorType: vendorType, handleRefresh: () => {
                        if (getParamId) {
                            IntakeStore.getSalesOrder(getParamId, IntakeStore.receiveOrShip);
                        }
                    } })),
                vendorType === 'rma' && rmaData && (React.createElement(RMA, { isTester: props.isTester, rmaItem: rmaData, onUpdate: async () => {
                        if (rmaData.id) {
                            const res = await InventoryStore.get(rmaData.id, 'RMA');
                            setRmaData(res);
                        }
                    } })))),
            hasPermission('finance.add_purchaseorderintake') &&
                vendorType === 'purchase-orders' &&
                PurchaseOrderStore.PurchaseOrder?.id && (React.createElement(PurchaseOrderLineItems, { vendorType: vendorType, onUpdate: async (po_id) => {
                    await PurchaseOrderStore.get(po_id);
                } }))),
        React.createElement("div", { className: 'kpis' },
            React.createElement("div", { className: "mt-4" },
                React.createElement(RangePicker, { onChange: (dates, dateStrings) => setKPIRange(dateStrings) }),
                React.createElement(Button, { disabled: KPIRange === null, loading: isGettingKPI, onClick: () => {
                        setIsGettingKPI(true);
                        get(`/staff/reports/sr_kpi/?from=${KPIRange[0]}&to=${KPIRange[1]}`)
                            .then(res => setKPIs(res))
                            .finally(() => setIsGettingKPI(false));
                    } }, "Get KPIs"),
                ' ',
                React.createElement(Button, { disabled: KPIRange === null, loading: isGettingKPI, onClick: () => {
                        setDownloading(true);
                        setIsGettingKPI(true);
                        get(`/staff/reports/sr_kpi_detail/?from=${KPIRange[0]}&to=${KPIRange[1]}`)
                            .then(response => {
                            const blob = dataURItoBlob(response.data);
                            fileDownload(blob, `sr_kpi_details.xlsx`);
                            setDownloading(false);
                        })
                            .catch(err => {
                            setIsGettingKPI(false);
                        })
                            .finally(() => setIsGettingKPI(false));
                    } }, "Get KPI Detail"),
                KPIs && (React.createElement("div", null,
                    React.createElement("p", null,
                        "Items In: ",
                        KPIs.data.items_in),
                    React.createElement("p", null,
                        "Items Out: ",
                        KPIs.data.items_out),
                    React.createElement("p", null,
                        "Average Out to RSD: ",
                        KPIs.data.average_out_to_rsd),
                    React.createElement("p", null,
                        "Median Out: ",
                        KPIs.data.median_out),
                    React.createElement("p", null,
                        "Late Shipping Cost Sum: ",
                        KPIs.data.late_shipping_cost_sum.toLocaleString())))))));
});
