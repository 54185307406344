import { message, Upload, Tabs, Button, Divider, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import QuoteStore from '../../stores/QuoteStore';
import PartStore from '../../stores/PartStore';
import { AssociateParts } from './AssociateParts';
import SplitPDF from '../SplitPDF';
import { observer } from 'mobx-react';
//Copied from zip.js
import { allowed_2d, allowed_3d, getMimeType } from '@partsbadger/utils';
import { FolderOutlined, LoadingOutlined, FilePdfOutlined, } from '@ant-design/icons';
import JSZip from 'jszip';
import ITARNotice from '../ITARNotice';
const LIMIT_LINE_ITEMS = 100;
export const UploadPartFilesMultiple = observer((props) => {
    const [uploadType, setUploadType] = useState('file');
    const [PDFFile, setPDFFile] = useState();
    const UnZipFiles = async (zip_file) => {
        const new_zip = new JSZip();
        const zip = await new_zip.loadAsync(zip_file);
        zip.forEach(async (relativePath) => {
            const blob = await zip.files[relativePath].async('blob');
            const name = relativePath.split('/').pop();
            if (!name) {
                return;
            }
            const fileType = getMimeType(name);
            const file = new File([blob], name, {
                type: fileType,
            });
            const is_a_hidden_document = name.indexOf('._') !== -1 ? true : false;
            if (PartStore.count() < LIMIT_LINE_ITEMS && !is_a_hidden_document) {
                PartStore.addPart(file);
            }
        });
    };
    const uploadProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 100;
            if (!isLt2M) {
                message.error('File must smaller than 100MB!', 10);
                return false;
            }
            if (PartStore.count() > LIMIT_LINE_ITEMS) {
                message.error('Limit is 200');
                return false;
            }
            if (PartStore.isZipFile(file.name)) {
                UnZipFiles(file);
            }
            else if (uploadType !== 'split') {
                PartStore.addPart(file);
            }
            if (uploadType === 'split') {
                setPDFFile(file);
                handleClickScroll('splitPDF');
            }
            return false;
        },
    };
    const tabs = [
        {
            label: 'Upload Files',
            key: 'file',
        },
        {
            label: (React.createElement(React.Fragment, null,
                React.createElement(FolderOutlined, null),
                "Upload Folder")),
            key: 'directory',
        },
    ];
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (PartStore.fileParts.length == 1) {
                PartStore.fileParts.forEach(item => {
                    if ((item['3D File'] || item['2D File'] || item['Additional File']?.length) && !item.uploading) {
                        PartStore.uploadPart(item);
                    }
                });
            }
        }, 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [PartStore.fileParts]);
    function resetSplitFunction() {
        setUploadType('file');
        setPDFFile(undefined);
    }
    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    const splitPDF = (React.createElement("div", { id: "splitPDF" }, PDFFile && props.is_staff ? React.createElement(SplitPDF, { handleReset: () => resetSplitFunction(), pdfFile: PDFFile }) : null));
    const splitPDFButton = (React.createElement(React.Fragment, null, props.is_staff ? (React.createElement(Button, { type: 'ghost', className: "d-flex flex-row align-items-center mx-auto mb-2 shadow-light", icon: React.createElement(FilePdfOutlined, null), onClick: e => setUploadType('split') }, 'Split PDF File')) : null));
    return (React.createElement("div", { className: "mb-2" },
        props.showITARNotice ? React.createElement(ITARNotice, null) : null,
        props.showUploadTypeTabs ? (React.createElement("div", { className: "mb-2" },
            React.createElement(Tabs, { centered: true, defaultActiveKey: uploadType, onChange: e => setUploadType(e), items: tabs }))) : null,
        props.showExtraUploadInfo ? (React.createElement("div", { className: "d-flex align-items-center text-muted" },
            React.createElement("div", { className: "d-flex align-items-center" },
                React.createElement(FeatherIcon, { icon: "lock", size: 14, className: "mr-1" }),
                React.createElement("span", null, "File uploads are secure and confidential")),
            React.createElement("div", { className: "d-flex align-items-center ml-auto" },
                React.createElement(FeatherIcon, { icon: "award", size: 14, className: "mr-1" }),
                React.createElement("span", null, "ISO 9001:2015 and AS 9100 Certified")))) : null,
        React.createElement(Upload.Dragger, { ...uploadProps, directory: uploadType === 'directory', className: "w-100", disabled: !!PartStore.fileParts.find(filePart => filePart.uploading), style: PDFFile ? { display: 'none' } : {} },
            React.createElement(React.Fragment, null, PartStore.fileParts.find(filePart => filePart.uploading) ? (React.createElement(Spin, { spinning: true, tip: PartStore.fileParts.find(filePart => filePart.uploading)
                    ? 'Uploading Files...'
                    : 'Uploading File...', indicator: React.createElement(LoadingOutlined, null), className: "my-4" })) : (React.createElement(React.Fragment, null, QuoteStore.quote?.line_items.length === 0 ? (React.createElement("div", { className: "py-4" },
                React.createElement("p", { className: "ant-upload-text pb-2", style: { fontSize: '1.6rem', fontWeight: 600 } }, "Select your part files"),
                React.createElement("p", { className: "ant-upload-hint w-full m-auto" }, uploadType === 'directory' ? (React.createElement("span", null, "You can select and upload a whole folder.")) : (React.createElement("span", null, "Drag and drop your 2D and 3D files or zip files."))),
                React.createElement("div", { style: { width: '6rem', margin: '0 auto' } },
                    React.createElement(Divider, null, "or")),
                React.createElement(Button, { type: 'primary', className: "d-flex flex-row align-items-center mx-auto mb-2 shadow-light", icon: React.createElement(FeatherIcon, { icon: "upload-cloud", size: "22", className: "mr-2" }), onClick: () => setUploadType('file') }, uploadType === 'directory' ? 'Upload Folder' : 'Upload Files'),
                splitPDFButton,
                React.createElement("p", { className: "pt-3" },
                    React.createElement("span", { className: "text-uppercase" },
                        allowed_3d.replace(/,/g, ',  '),
                        ',  ',
                        allowed_2d.replace(/,/g, ',  ')),
                    React.createElement("br", null),
                    React.createElement("span", null, "100 MB Max File Size")))) : (React.createElement("div", { className: "d-flex flex-column mb-3 align-items-center justify-content-center ant-upload-text" },
                React.createElement("br", null),
                React.createElement(FeatherIcon, { icon: "upload-cloud", size: "24", className: "mr-2 text-primary" }),
                React.createElement("span", { className: "text-primary mr-1" }, "Click to add another part"),
                React.createElement("span", null, "or drag a file here"),
                props.is_staff ? (React.createElement("div", { style: { width: '6rem', margin: '0 auto' } },
                    React.createElement(Divider, null, "or"))) : null,
                splitPDFButton,
                React.createElement("p", { className: "pt-3" },
                    React.createElement("span", { className: "text-uppercase" },
                        allowed_3d.replace(/,/g, ',  '),
                        ',  ',
                        allowed_2d.replace(/,/g, ',  ')),
                    React.createElement("br", null),
                    React.createElement("span", null, "100 MB Max File Size")))))))),
        React.createElement(AssociateParts, { is_staff: props.is_staff }),
        splitPDF));
});
