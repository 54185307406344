import React, { useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
const InputCalculator = props => {
    const [value, setValue] = useState(props.value ?? '');
    return (React.createElement(Input, { ...props, size: "small", className: "w-16", value: value, onChange: e => {
            setValue(e.target.value);
            if (props.onChange) {
                props.onChange(parseFloat(e.target.value));
            }
        }, onKeyPress: event => {
            if (event.key === 'Enter' && value) {
                const total = eval(value).toFixed(3);
                event.preventDefault();
                setValue(total);
                if (props.onChange) {
                    props.onChange(total);
                }
            }
        } }));
};
export default InputCalculator;
