import { action, observable, makeObservable } from 'mobx';
import { get } from '@partsbadger/utils';
class QuickbooksStore {
    constructor() {
        this.quickbooks_account = [];
        this.journalEntries = [];
        makeObservable(this, {
            // Observables
            quickbooks_account: observable,
            journalEntries: observable,
            // Actions
            list: action,
            journalEntriesList: action,
        });
    }
    async list(params) {
        const response = await get(`/staff/quickbooks-accounts/`, { params: params });
        this.quickbooks_account = response.results;
        return response.results;
    }
    async journalEntriesList() {
        const response = await get(`/staff/quickbooks-journal-entries/`);
        this.journalEntries = response.results;
    }
}
export default new QuickbooksStore();
