import { action, observable, makeObservable } from 'mobx';
import { get, post } from '../utils/api';
class InventoryStore {
    constructor() {
        this.loading = false;
        this.storages = [];
        this.get = async (id, type) => {
            const res = await get(`/staff/inventory/${id}/`, {
                type: type,
            });
            return res.data;
        };
        this.create = async (payload) => {
            return await post(`/staff/inventory/`, payload);
        };
        this.intake = async (id, payload) => {
            await post(`/staff/inventory/${id}/intake/`, payload);
        };
        makeObservable(this, {
            // Observables
            loading: observable,
            storages: observable,
            // Actions
            get: action,
            create: action,
            intake: action,
            getStorageLocations: action,
        });
    }
    async getStorageLocations() {
        const response = await get(`/staff/storage-location/`);
        this.storages = response.data.results;
    }
}
export default new InventoryStore();
