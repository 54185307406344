import { QuoteStore } from '../../Stores';
import { SAFE_MATERIALS } from '../Select/Materials';
let formula = false;
const Formula26Safe = (formula_val, master_product_id, product_material_label, totalVol, partVol) => {
    if (formula_val == '2.6' && product_material_label) {
        if (totalVol && partVol) {
            formula = totalVol < 90 && partVol < 30 && SAFE_MATERIALS.includes(product_material_label);
        }
        else if (master_product_id) {
            const mp = QuoteStore.masterProductsByPosition.find(mp => mp.master_product.id === master_product_id);
            if (mp && mp.master_product) {
                const totalVol = mp.master_product.total_volume_inches;
                const partVol = mp.master_product.part_volume_inches;
                if (totalVol && partVol) {
                    formula = totalVol < 90 && partVol < 30 && SAFE_MATERIALS.includes(product_material_label);
                }
            }
        }
    }
    return formula_val == '2.6' && formula ? ' ✓' : '';
};
export default Formula26Safe;
