import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Form, Row, Upload, Input, Checkbox, notification, message, Table, Tag, } from 'antd';
import { Price } from '@partsbadger/library';
import SalesOrderStore from './Stores/SalesOrderStore';
import { UploadOutlined, NumberOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
const OrderShopToolForm = () => {
    const [form] = Form.useForm();
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orderItems, setOrderItems] = useState();
    const history = useHistory();
    const params = useParams();
    const [keyLineItems, setKeyLineItems] = useState([]);
    const [hasTravelerFile, setHasTravelerFile] = useState([]);
    async function getSalesOrderDetails(id) {
        setLoading(true);
        await SalesOrderStore.getOrderById(toNumber(id))
            .then(async () => {
            if (SalesOrderStore.order && SalesOrderStore.order?.line_items.length > 0) {
                setOrderItems(SalesOrderStore.order.line_items);
                SalesOrderStore.getHasTravelerFileSalesOrder(toNumber(id)).then(() => {
                    setHasTravelerFile(SalesOrderStore.has_traveler_file);
                });
                form.setFieldsValue({
                    customer_supplied_material: SalesOrderStore.order?.has_customer_supplied_material,
                });
            }
        })
            .catch(error => {
            message.error(`Error loading tracking codes: ${error}`);
        });
        setLoading(false);
    }
    useEffect(() => {
        if (params.id) {
            getSalesOrderDetails(params.id);
        }
    }, [params.id]);
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setKeyLineItems(selectedRowKeys);
            try {
                let sum = 0;
                selectedRows.forEach(value => {
                    sum += toNumber(value.total);
                });
                setTotal(sum);
            }
            catch (e) {
                setTotal(0);
            }
        },
    };
    const handleSubmit = (values) => {
        if (keyLineItems.length > 0) {
            const payload = {
                id: params.id,
                line_items: keyLineItems,
                customer_supplied_material: values.customer_supplied_material,
                notes: values.notes,
                files: values?.files ? values?.files?.fileList?.map(f => f.originFileObj) : [],
            };
            setLoading(true);
            SalesOrderStore.sendToShop(payload)
                .then((response) => {
                response;
                openNotificationWithIcon('success');
                setLoading(false);
            })
                .catch((error) => error);
            onReset();
            form.setFieldsValue({ customer_supplied_material: values.customer_supplied_material });
        }
        else {
            notification['error']({
                message: 'Order to Shop',
                description: 'Please select a line item.',
            });
        }
    };
    const onFinishFailed = (errorInfo) => {
        openNotificationWithIcon('error');
    };
    const onReset = () => {
        form.setFieldsValue({ files: [] });
        setKeyLineItems([]);
        // setHasTravelerFile([]);
        setTotal(0);
        form.resetFields();
    };
    const openNotificationWithIcon = (type) => {
        if (type === 'success') {
            notification[type]({
                message: 'The order was sent to the shop successfully.',
                description: 'SUCCESS.',
            });
        }
        else {
            notification[type]({
                message: 'Order to Shop.',
                description: 'Please review the red-colored field.',
            });
        }
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (t, r) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, t !== 'CNC Part' ? t : '')),
                    React.createElement("div", { className: 'text-md' }, r.product_description)));
            },
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Has traveler file?',
            dataIndex: 'has_traveler_file',
            key: 'has_traveler_file',
            align: 'center',
            render: (text, record) => hasTravelerFile.includes(record.id) ? React.createElement(Tag, { color: "green" }, "YES ") : React.createElement(Tag, { color: "red" }, "NO "),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },
        {
            title: 'Unit Price',
            dataIndex: 'list_price',
            key: 'list_price',
            align: 'center',
            render: (text, record) => React.createElement(Price, { value: record.list_price }),
        },
        {
            title: 'Total Price',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: (text, record) => React.createElement(Price, { value: record.total }),
        },
    ];
    return (React.createElement("div", { style: { maxWidth: '800px', margin: 'auto' } },
        React.createElement(Divider, null),
        React.createElement("div", { className: "flex flex-col justify-center" },
            React.createElement("p", { className: 'label flex items-center m-0 text-base font-serif' },
                React.createElement(NumberOutlined, { className: "mr-1" }),
                " Order Number:"),
            React.createElement("p", { className: 'font-bold text-base ml-1', style: { width: '7vw' } }, SalesOrderStore.order?.name ?? '--')),
        React.createElement(React.Fragment, null,
            React.createElement(Form, { form: form, name: "Order to the Shop", onFinish: handleSubmit, onFinishFailed: onFinishFailed },
                React.createElement(React.Fragment, null,
                    React.createElement(Row, { className: 'row-order mt-5 text-left' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { name: "line_items", className: "mb-0" },
                                React.createElement(Table, { rowKey: record => record.id.toString(), pagination: false, loading: loading, 
                                    // rowSelection={rowSelection}
                                    rowSelection: {
                                        type: 'checkbox',
                                        ...rowSelection,
                                    }, columns: columns, dataSource: orderItems, className: "border", footer: () => (React.createElement(Row, { style: { marginTop: 15 } },
                                        React.createElement(Col, { sm: 24, md: 12 }),
                                        React.createElement(Col, { sm: 24, md: 12 },
                                            React.createElement("div", { className: 'flex flex-col justify-between' },
                                                React.createElement("div", { className: "flex flex-row justify-between  mb-1" },
                                                    React.createElement("div", { className: "summary-label" }, "Subtotal"),
                                                    React.createElement("div", { className: "summary-value" },
                                                        React.createElement(Price, { value: total }))))))), scroll: window.innerWidth <= 600 ? { x: 600 } : undefined })))),
                    React.createElement(Row, { className: 'row-order mt-5 text-left' },
                        React.createElement(Col, { md: 24, span: 24 },
                            React.createElement(Form.Item, { label: 'Customer Supplied Material', name: "customer_supplied_material", className: "mb-0", valuePropName: "checked" },
                                React.createElement(Checkbox, null)))),
                    React.createElement(Row, { className: 'row-order mt-5 text-left' },
                        React.createElement(Col, { md: 24, span: 24 })),
                    React.createElement(Col, null,
                        React.createElement(Form.Item, { name: 'files', label: "Upload Files", rules: [{ required: false }] },
                            React.createElement(Upload, { multiple: true, accept: ".pdf", beforeUpload: () => false },
                                React.createElement(Button, { icon: React.createElement(UploadOutlined, null) }, "Click to Upload"))),
                        React.createElement(Form.Item, { name: 'notes', label: "Notes" },
                            React.createElement(Input.TextArea, null))),
                    React.createElement(Col, { sm: 24, style: { textAlign: 'center' } },
                        React.createElement(Form.Item, null,
                            React.createElement(Button, { type: "primary", htmlType: "submit", loading: loading }, "Submit"))))))));
};
export default observer(OrderShopToolForm);
