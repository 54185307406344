import React, { useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row, Spin, Upload } from 'antd';
import { observer } from 'mobx-react';
/**
 * Strip the name from url
 * @param filename
 */
const getFileName = (filename) => {
    const name = filename.split('?')[0].split('/');
    return name[name.length - 1];
};
const UploadRedactedFilesForm = ({ parts, onUploadRedactedFile }) => {
    const content = parts
        .filter(item => item.name !== 'CNC Part')
        .map((item, index) => {
        return (React.createElement(Row, { className: 'fex flex-row border my-4 p-2', key: index },
            React.createElement(Row, null,
                React.createElement("div", { className: 'text-center' },
                    React.createElement("span", { className: 'font-bold' }, item.name))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" },
                        React.createElement("div", { className: 'p-4' },
                            React.createElement(UploadDragger, { file_type: '3d', uploaded: !!item?.redacted_3d_file, handleUpload: async (file) => await onUploadRedactedFile(item.id, '3d', file) })),
                        item?.redacted_3d_file && (React.createElement("a", { target: '_blank', rel: "noopener noreferrer", href: item.redacted_3d_file },
                            React.createElement(LegacyIcon, { type: 'cloud' }),
                            " ",
                            getFileName(item?.redacted_3d_file))))),
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" },
                        React.createElement("div", { className: 'p-4' },
                            React.createElement(UploadDragger, { file_type: '2d', uploaded: !!item?.redacted_2d_file, handleUpload: async (file) => await onUploadRedactedFile(item.id, '2d', file) }),
                            item?.redacted_2d_file && (React.createElement("a", { target: '_blank', rel: "noopener noreferrer", href: item.redacted_2d_file },
                                React.createElement(LegacyIcon, { type: 'cloud' }),
                                " ",
                                getFileName(item?.redacted_2d_file)))))))));
    });
    return React.createElement("div", null, content);
};
const UploadDragger = (props) => {
    const [uploading, setUploading] = useState(false);
    const [isUploaded, setUploaded] = useState(false);
    useEffect(() => {
        setUploaded(props.uploaded);
    }, [props.uploaded]);
    return (React.createElement(Upload.Dragger, { style: {
            maxWidth: 300,
            margin: 'auto',
        }, accept: props.file_type === '3d' ? '.step, .STEP' : '.pdf', showUploadList: false, beforeUpload: file => {
            setUploading(true);
            props
                .handleUpload(file)
                .then(() => {
                setUploaded(true);
            })
                .finally(() => setUploading(false));
            return false;
        } },
        React.createElement("p", null, isUploaded ? (React.createElement("div", null,
            React.createElement(LegacyIcon, { type: 'check', style: { color: 'green' } }),
            "Uploaded")) : (React.createElement("p", { className: "ant-upload-text", style: { color: 'red' } },
            "Drag a ",
            props.file_type,
            " file"))),
        React.createElement("div", null, uploading && React.createElement(Spin, { spinning: true }))));
};
export default observer(UploadRedactedFilesForm);
