import * as React from 'react';
import { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';
import { API_ROOT } from '@partsbadger/utils';
let busy = null;
export const Industries = (props) => {
    const [loading, setLoading] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [industry_use, setIndustryUse] = useState([]);
    const [endUses, setEndUses] = useState([]);
    const [selected_industry, setSelectedIndustry] = useState(null);
    const [selected_industry_use, setSelectedIndustryUse] = useState(null);
    const [selected_end_use, setSelectedEndUse] = useState(null);
    /**
     * Get all industries
     * @param search
     */
    const getIndustries = (search) => {
        setLoading(true);
        if (busy) {
            clearTimeout(busy);
        }
        busy = setTimeout(() => {
            axios.get(`${API_ROOT}/staff/industries/?search=${search}`).then((response) => {
                setIndustries(response.data.results);
                setLoading(false);
            });
        }, 400);
    };
    /**
     * Get End Use for a specif industry
     * @param industry_id
     */
    const getEndUseByIndustry = (industry_id) => {
        setLoading(true);
        busy = setTimeout(() => {
            axios.get(`${API_ROOT}/staff/industries/${industry_id}/`).then((response) => {
                setEndUses(response.data.end_uses);
                setLoading(false);
            });
        }, 400);
    };
    /**
     * Get uses by specific industry
     * @param industry_id
     */
    const getUses = (industry_id) => {
        setLoading(true);
        busy = setTimeout(() => {
            axios.get(`${API_ROOT}/staff/industries/${industry_id}/uses/`).then((response) => {
                setIndustryUse(response.data);
                setLoading(false);
            });
        }, 400);
    };
    /**
     * On load get industries
     */
    useEffect(() => {
        getIndustries('');
    }, []);
    /**
     * Preloading industry and use if is available
     */
    useEffect(() => {
        if (props.value?.industry?.key && props.value.industry.key !== selected_industry?.key) {
            setSelectedIndustry(props.value.industry);
        }
        if (props.value?.industry_use && props.value.industry_use.key !== selected_industry_use?.key) {
            setSelectedIndustryUse(props.value.industry_use);
        }
        // Usually end use dont comes with unique key
        if (props.value?.end_use?.label && props.value.end_use.label !== selected_end_use?.label) {
            setSelectedEndUse(props.value.end_use);
        }
    }, [props.value]);
    useEffect(() => {
        if (selected_industry?.key) {
            getUses(selected_industry.key);
            getEndUseByIndustry(selected_industry.key);
            if (props.onChange) {
                // only send values if all three are selected
                if (selected_industry.key && selected_industry_use?.key && selected_end_use?.key) {
                    props.onChange({
                        industry: selected_industry,
                        industry_use: selected_industry_use,
                        end_use: selected_end_use,
                    });
                }
                else {
                    props.onChange(null);
                }
            }
        }
    }, [selected_industry, selected_industry_use, selected_end_use]);
    return (React.createElement("div", null,
        React.createElement(Select, { showSearch: true, labelInValue: true, filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, size: 'small', style: { width: 300 }, dropdownMatchSelectWidth: false, placeholder: "Industry", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, 
            //@ts-ignore
            value: selected_industry?.key
                ? selected_industry
                : {
                    key: null,
                    label: null,
                }, onChange: (item) => {
                if (item?.key) {
                    setSelectedIndustry(item);
                    //Clear the uses and use
                    setSelectedIndustryUse(null);
                    setSelectedEndUse(null);
                }
                else {
                    // setUses([]);
                    setIndustryUse([]);
                    setEndUses([]);
                }
            } }, industries?.map(row => {
            return (React.createElement(Select.Option, { key: row.id, value: row.id }, row.name));
        })),
        React.createElement(Select, { showSearch: true, labelInValue: true, size: 'small', style: { width: 300 }, dropdownMatchSelectWidth: false, placeholder: "Use", filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, 
            //@ts-ignore
            value: selected_industry_use?.key
                ? selected_industry_use
                : {
                    key: null,
                    label: null,
                }, onChange: (use) => {
                setSelectedIndustryUse(use);
            } }, industry_use?.map(item => {
            return React.createElement(Select.Option, { key: item.id }, item.use.name);
        })),
        React.createElement(Select, { showSearch: true, labelInValue: true, size: 'small', style: { width: 300 }, dropdownMatchSelectWidth: false, placeholder: "End Use", filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()), 
            //@ts-ignore
            value: selected_end_use?.key
                ? selected_end_use
                : {
                    key: null,
                    label: null,
                }, onChange: (item) => {
                setSelectedEndUse(item);
            } }, endUses?.map(item => {
            return React.createElement(Select.Option, { key: item.id }, item.description);
        }))));
};
