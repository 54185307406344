import React, { useState } from 'react';
import { Checkbox, Col, Row, Spin } from 'antd';
import { Price, Percentage } from '@partsbadger/library';
import { observer } from 'mobx-react';
export const styleBody = {
    padding: '3px 0',
    textAlign: 'center',
};
const QuoteLineItemQuantityForm = ({ variation, isReadOnly, handleUpdate, uniPriceAdjustmentType }) => {
    const [loading, setLoading] = useState(false);
    return (React.createElement(Col, { span: 24 },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Row, null,
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Checkbox, { disabled: isReadOnly, checked: variation.displayed, onClick: e => {
                                //@ts-ignore
                                setLoading(true);
                                handleUpdate({
                                    //@ts-ignore
                                    displayed: e.target?.checked,
                                }).finally(() => setLoading(false));
                            } }),
                        "\u2003",
                        React.createElement("label", { style: { fontWeight: 'bold' } }, variation.quantity))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody }, variation.lead_time)),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.vendor_price }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.material_price }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.shipping_cost }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        uniPriceAdjustmentType == '$' && React.createElement(Price, { value: variation.unit_price_adjustment }),
                        uniPriceAdjustmentType == '%' && React.createElement(Percentage, { value: variation.unit_price_adjustment }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.unit_price }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement("label", { style: { fontWeight: 'bold' } },
                            React.createElement("span", null,
                                React.createElement(Price, { value: variation.total_price })))))))));
};
export default observer(QuoteLineItemQuantityForm);
