import React from 'react';
import { Button, Modal, Table, message } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { post } from '@partsbadger/utils';
import { QuoteStore } from '../../Stores';
import { SortAscendingOutlined } from '@ant-design/icons';
let dragingIndex = -1;
class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };
        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }
        return connectDragSource(connectDropTarget(React.createElement("tr", { ...restProps, className: className, style: style })));
    }
}
const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};
const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
}))(BodyRow));
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
];
class ReorderMasterProducts extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            data: QuoteStore.masterProductsByPosition.map(item => {
                return {
                    id: item.master_product.id,
                    name: item.master_product.name,
                    position: item.position,
                };
            }),
        };
        this.components = {
            body: {
                row: DragableBodyRow,
            },
        };
        this.moveRow = (dragIndex, hoverIndex) => {
            const { data } = this.state;
            const dragRow = data[dragIndex];
            this.setState(update(this.state, {
                data: {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                },
            }));
        };
        this.sortRows = () => {
            const { data } = this.state;
            data.sort((a, b) => a.name.localeCompare(b.name));
            this.setState({ data: data });
        };
    }
    render() {
        const quote_id = this.props.quote_id;
        return (React.createElement(Modal, { title: 'Sort Products', visible: true, onCancel: () => {
                this.props.handleClose();
            }, footer: [
                React.createElement(Button, { key: "close", type: "default", onClick: this.props.handleClose }, "Cancel"),
                React.createElement(Button, { key: "save", type: "primary", onClick: () => {
                        const payload = this.state.data.map((item, index) => {
                            return {
                                master_product: item.id,
                                position: index,
                            };
                        });
                        post(`/staff/quotes/${quote_id}/master-products/`, payload)
                            .then(() => {
                            QuoteStore.getMasterProducts(quote_id);
                            this.props.handleClose();
                        })
                            .catch(() => {
                            message.error('Error, please try again');
                        });
                    } }, "Apply"),
            ] },
            React.createElement("div", { className: "text-right" },
                React.createElement(Button, { onClick: () => {
                        this.sortRows();
                    } },
                    React.createElement(SortAscendingOutlined, { className: "text-2xl" }))),
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement(Table, { className: 'components-table-drag-sorting', rowKey: 'id', size: 'small', pagination: false, columns: columns, dataSource: this.state.data, components: this.components, 
                    //@ts-ignore
                    onRow: (record, index) => ({
                        index,
                        moveRow: this.moveRow,
                    }) }))));
    }
}
export default ReorderMasterProducts;
