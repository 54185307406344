import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, InputNumber, Row } from 'antd';
export const ApplicationCostForm = (props) => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0.0);
    const [saving, setSaving] = useState(false);
    const handleSubmitEvent = () => {
        if (name && price >= 0) {
            setSaving(true);
            props
                .onSubmit({
                name: name,
                price: price,
            })
                .then(() => {
                setName('');
                setPrice(0);
            })
                .finally(() => setSaving(false));
        }
        else {
            alert('Insert Name');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { md: 24, lg: 12 },
                React.createElement(Form.Item, null,
                    React.createElement(Input, { size: 'small', placeholder: 'Application', value: name, onChange: e => setName(e.currentTarget.value) }))),
            React.createElement(Col, { md: 24, lg: 8 },
                React.createElement(Form.Item, null,
                    React.createElement(InputNumber, { value: price, size: 'small', placeholder: '0.00', onPressEnter: handleSubmitEvent, onChange: v => v && setPrice(v) }))),
            React.createElement(Col, { md: 24, lg: 4 },
                React.createElement(Form.Item, null,
                    React.createElement(Button, { size: 'small', type: "primary", loading: saving, onClick: handleSubmitEvent }, "Add"))))));
};
