import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Typography } from 'antd';
import { AccountDetail } from '../../stores/types';
import { ShippingCarriers, ShippingMethods } from '@partsbadger/library';
import { Rule } from 'antd/es/form';

interface Field {
    label: string;
    name: string;
    initialValue?: any;
    type: 'text' | 'email' | 'textarea' | 'select';
    rules?: Rule[];
    component?: React.ReactNode;
}

interface FieldCategory {
    title: string;
    fields: Field[];
}

interface Props {
    account?: AccountDetail;
}

const AccountEditForm: React.FC<Props> = ({ account }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();

    const onEdit = () => setIsEditing(true);
    const onSave = async () => {
        try {
            const values = await form.validateFields();
            message.success('Account details saved successfully.');
            setIsEditing(false);
        } catch (error) {
            message.error('Please correct the errors in the form.');
        }
    };

    const onCancel = () => {
        setIsEditing(false);
        form.resetFields();
    };

    const fieldCategories: FieldCategory[] = [
        {
            title: 'Basic Information',
            fields: [
                {
                    label: 'Name',
                    name: 'name',
                    initialValue: account?.name,
                    type: 'text',
                    rules: [{ required: true, message: 'Please enter the name' }],
                },
                {
                    label: 'Invoice Email Address',
                    name: 'invoice_email_address',
                    initialValue: account?.invoice_email_address,
                    type: 'email',
                    rules: [{ type: 'email', required: true, message: 'Please enter a valid email address' }],
                },
            ],
        },
        {
            title: 'Shipping Requirements',
            fields: [
                {
                    label: 'Shipping Account Number',
                    name: 'shipping_account_number',
                    initialValue: account?.shipping_account_number,
                    type: 'text',
                    rules: [{ required: true, message: 'Shipping account number is required' }],
                },
                {
                    label: 'Shipping Carrier',
                    name: 'carrier',
                    initialValue: account?.carrier,
                    type: 'select',
                    rules: [{ required: true, message: 'Please select a carrier' }],
                    component: <ShippingCarriers disabled={!isEditing} />,
                },
                {
                    label: 'Shipping Method',
                    name: 'shipping_method',
                    initialValue: account?.shipping_method,
                    type: 'select',
                    rules: [{ required: false }],
                    component: <ShippingMethods disabled={!isEditing} />,
                },
            ],
        },
        {
            title: 'Additional Information',
            fields: [
                {
                    label: 'Internal Notes',
                    name: 'internal_notes',
                    initialValue: account?.internal_notes,
                    type: 'textarea',
                    rules: [],
                },
            ],
        },
    ];

    const renderInput = (type: Field['type'], value: any, component?: React.ReactNode) => {
        if (!isEditing) {
            // Show fixed text when not editing
            return <Typography.Text>{value || '-'}</Typography.Text>;
        }

        // Show input components when editing
        if (component) return component;

        switch (type) {
            case 'text':
                return <Input />;
            case 'email':
                return <Input type="email" />;
            case 'textarea':
                return <Input.TextArea rows={4} />;
            default:
                return <Input />;
        }
    };

    return (
        <Form form={form} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {/*<div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>*/}
            {/*    <Button key="edit" onClick={onEdit} disabled={isEditing}>*/}
            {/*        Edit*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*        key="save-changes"*/}
            {/*        type="primary"*/}
            {/*        onClick={onSave}*/}
            {/*        disabled={!isEditing}*/}
            {/*        style={{ marginLeft: 8 }}*/}
            {/*    >*/}
            {/*        Save Changes*/}
            {/*    </Button>*/}
            {/*    <Button key="cancel" onClick={onCancel} disabled={!isEditing} style={{ marginLeft: 8 }}>*/}
            {/*        Cancel*/}
            {/*    </Button>*/}
            {/*</div>*/}

            {fieldCategories.map((category, catIndex) => (
                <Card key={catIndex} title={category.title} style={{ marginTop: 16 }}>
                    <Row gutter={16}>
                        {category.fields.map((field, index) => (
                            <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label={field.label}
                                    name={field.name}
                                    initialValue={field.initialValue}
                                    rules={field.rules}
                                >
                                    {renderInput(field.type, field.initialValue, field.component)}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </Card>
            ))}
        </Form>
    );
};

export default AccountEditForm;
