import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
const { Paragraph } = Typography;
export const PartName = ({ name, editable, handleUpdate }) => {
    const [partName, setPartName] = useState(name);
    useEffect(() => {
        setPartName(name);
    }, [name]);
    const rest = editable
        ? {
            editable: {
                onChange: value => {
                    setPartName(value);
                    handleUpdate(value);
                },
            },
        }
        : {};
    return (React.createElement(Paragraph, { style: {
            fontWeight: 'bold',
            fontSize: 20,
            margin: 0,
        }, ...rest }, partName.toUpperCase()));
};
