import React, { useEffect, useRef, useState } from 'react';
import { InputCalculator, PartDimensionsNewLayout } from '../index';
import { normalize_number, patch } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Divider, Input, message, Modal, notification, Select, Tooltip, } from 'antd';
import { QuoteStore } from '../../../Stores';
import { hasGroup } from 'quotes-frontend/src/components/User';
import MasterProductModalHistory from '../../MasterProductModalHistory';
import { HistoryOutlined } from '@ant-design/icons';
const PartDetails = ({ row, form, editable, handleChange, handleCreate, editNotes, closedNotes }) => {
    const [edit, setEdit] = useState(false);
    const [showPartModalHistory, setShowPartModalHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const { Option } = Select;
    const outsideClickRef = useRef(null);
    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.code === 'Escape')
                setEdit(false), editable(false);
        });
    }, []);
    useEffect(() => {
        function handleClick(event) {
            if (outsideClickRef?.current) {
                try {
                    // ANTD Select Option are render outside the component
                    if (event.target?.className && event.target.className.includes('ant-select-dropdown-menu-item')) {
                        return;
                    }
                    //for antd4
                    if (event.target?.className?.includes('ant-select-item-option-content')) {
                        return;
                    }
                    if (!outsideClickRef.current.contains(event.target)) {
                        if (edit && row.editable) {
                            handleSubmit();
                        }
                        else {
                            setEdit(false);
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        }
        if (edit) {
            // Bind the event listener
            document.addEventListener('mousedown', handleClick);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClick);
            };
        }
    }, [edit]);
    const validateNumberGreaterThan0 = (rule, value, callback) => {
        if (getFieldValue('quotable') && (isNaN(value) || value == 0 || !value)) {
            callback('Required');
        }
        callback();
    };
    const handleSubmit = () => {
        form.validateFieldsAndScroll(async (err, values) => {
            if (values.notes !== null && values.no_quote_category !== null && row.id && showNotes) {
                setLoading(true);
                const payload = {
                    quotable: values.quotable,
                    no_quote_category: values.no_quote_category,
                    notes: values.notes,
                };
                await submitPatch(payload, row.id);
            }
            else if (!err) {
                setLoading(true);
                if (row.id) {
                    const payload = {
                        ...values,
                        volume: values.volume >= 0 ? values.volume : 0,
                        part_volume_percentage: values.part_volume_percentage ? values.part_volume_percentage : 0,
                    };
                    await submitPatch(payload, row.id);
                }
                else {
                    const payload = {
                        master_product: {
                            ...values,
                            volume: values.volume ? values.volume : 0,
                            part_volume_percentage: values.part_volume_percentage ? values.part_volume_percentage : 0,
                        },
                        sides: values.sides,
                        taps: values.taps,
                    };
                    setLoading(false);
                    if (handleCreate) {
                        handleCreate(payload);
                        setShowNotes(false);
                        closedNotes(false);
                    }
                }
            }
            else {
                message.error('Please fill all required values');
            }
        });
    };
    const submitPatch = async (payload, id) => {
        await patch('/staff/master-products/' + id + '/', payload)
            .then(async (response) => {
            setLoading(false);
            if (handleChange) {
                handleChange(response.id);
            }
            setEdit(false);
            setShowNotes(false);
            closedNotes(false);
            await QuoteStore.getQuote(undefined);
            if (QuoteStore.quote)
                await QuoteStore.getLineItems(QuoteStore.quote.id);
        })
            .catch(error => {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
            setLoading(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { onClick: () => (setEdit(true), editable(true)), ref: outsideClickRef },
            React.createElement(Tooltip, { title: !edit && 'Click to edit' },
                React.createElement("div", { className: "flex flex-col lg:flex-row" },
                    !row.editable && !edit && (React.createElement(LegacyIcon, { title: 'This master product is used in other quotes', type: 'lock', style: { fontSize: '10px' }, className: "link mr-2 mt-1" })),
                    !edit ? (React.createElement("div", { className: "flex flex-col xl:flex-row" },
                        React.createElement("div", { className: "flex flex-col xl:flex-row" },
                            React.createElement("div", { className: "align-middle font-bold" },
                                row.name,
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement(PartDimensionsNewLayout, { length: parseFloat(row.length), width: parseFloat(row.width), height: parseFloat(row.height), unit: row.unit, edit: edit, 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                form: form })),
                        React.createElement("div", { className: "flex flex-col xl:flex-row" },
                            React.createElement("div", { className: "align-middle xl:m-0" },
                                React.createElement("span", { title: row.total_volume.toString() },
                                    React.createElement("strong", null, "Total Vol."),
                                    " ",
                                    row.total_volume_inches.toFixed(3),
                                    " IN"),
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement("div", { className: "align-middle xl:ml-2 xl:m-0" },
                                React.createElement("span", { title: row.part_volume.toString() },
                                    React.createElement("strong", null, "Part Vol. "),
                                    row.part_volume_inches.toFixed(3),
                                    " IN"),
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement("div", { className: "align-middle xl:ml-2 xl:m-0" },
                                React.createElement("span", { title: row.removed_volume.toFixed() },
                                    React.createElement("strong", null, "Removed Vol."),
                                    ' ',
                                    React.createElement("span", { style: {
                                            color: row.removed_volume_inches == 0 ? 'red' : 'inherit',
                                        } },
                                        row.removed_volume_inches.toFixed(3),
                                        " IN")),
                                React.createElement(Divider, { type: 'vertical' })),
                            React.createElement("div", { className: "align-middle xl:ml-2 xl:m-0" },
                                React.createElement("span", { className: "xl:ml-2 xl:m-0" },
                                    React.createElement("strong", null, "3d Print Vol. "),
                                    row.volume_3d_printing
                                        ? parseFloat(row.volume_3d_printing).toFixed(3)
                                        : 'Analyzing...',
                                    ' ',
                                    "MM",
                                    React.createElement(Divider, { type: 'vertical' })))))) : (React.createElement("div", { className: 'flex flex-row' },
                        React.createElement(Form.Item, { label: 'Name:', className: "font-bold ml-2 m-0" }, getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Please input a value!' }],
                            initialValue: row.name,
                        })(React.createElement(Input, { size: "small", style: { width: '15vw' } }))),
                        React.createElement(PartDimensionsNewLayout, { length: parseFloat(row.length), width: parseFloat(row.width), height: parseFloat(row.height), unit: row.unit, edit: edit, 
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            form: form }),
                        React.createElement(Form.Item, { label: 'Shape', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('shape', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: row.shape,
                        })(React.createElement(Select, { showSearch: true, size: "small" },
                            React.createElement(Option, { value: "REC" }, "REC"),
                            React.createElement(Option, { value: "ROD" }, "ROD"),
                            React.createElement(Option, { value: "TUBE" }, "TUBE")))),
                        getFieldValue('shape') === 'TUBE' && (React.createElement(Form.Item, { label: 'Inner Diam.', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('inner_diameter', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ],
                            initialValue: normalize_number(row.inner_diameter),
                        })(React.createElement(InputCalculator, null)))),
                        React.createElement(Form.Item, { label: 'Part Vol.', className: "flex flex-row font-bold ml-3 m-0" }, getFieldDecorator('volume', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: normalize_number(row.volume),
                        })(React.createElement(InputCalculator, null))),
                        getFieldValue('volume') == 0 && (React.createElement(Tooltip, { title: "% of Bounding Box volume. Use this field only when part volume is unknown" },
                            React.createElement(Form.Item, { label: '(%) Vol.', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('part_volume_percentage', {
                                rules: [
                                    {
                                        required: getFieldValue('volume') == 0,
                                        message: 'Required!',
                                    },
                                    getFieldValue('volume') == 0
                                        ? {
                                            validator: validateNumberGreaterThan0,
                                        }
                                        : {},
                                ],
                                initialValue: row.part_volume_percentage,
                            })(React.createElement(InputCalculator, null))))),
                        React.createElement(Form.Item, { label: 'Itar', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('itar', {
                            initialValue: row.itar,
                            valuePropName: 'checked',
                        })(React.createElement(Checkbox, null))))),
                    edit &&
                        (row?.id ? (React.createElement("div", { className: 'flex flex-row mx-2' },
                            React.createElement(Form.Item, { label: 'Quotable', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('quotable', {
                                valuePropName: 'checked',
                                initialValue: row.quotable,
                            })(React.createElement(Checkbox, { onChange: e => setShowNotes(!e.target.checked) }))))) : (React.createElement("div", { className: "flex flex-row justify-center align-middle", style: { width: '370px' } },
                            React.createElement(Form.Item, { label: 'Taps', className: "flex flex-row font-bold" }, getFieldDecorator('taps', {
                                rules: [{ required: true, message: 'Required!' }],
                                initialValue: 0,
                            })(React.createElement(Input, { placeholder: "", type: 'number', size: "small", className: "w-16" }))),
                            React.createElement(Form.Item, { label: 'Sides', className: "flex flex-row font-bold" }, getFieldDecorator('sides', {
                                rules: [{ required: true, message: 'Required!' }],
                            })(React.createElement(Input, { placeholder: "", type: 'number', size: "small", className: "w-16" }))),
                            React.createElement("div", { style: { textAlign: 'center' } },
                                React.createElement(Button, { type: "primary", htmlType: "submit", size: "small", loading: loading, className: "mt-2" }, "Save as New")))))),
                edit && !row.editable && QuoteStore.quote?.editable && (React.createElement("div", { className: "flex flex-row justify-center m-auto mb-2" },
                    React.createElement("div", { className: "flex flex-col justify-center align-center" },
                        React.createElement(Alert, { style: { margin: 5, width: '350px' }, type: 'warning', className: "text-center", message: 'This master product is used in other quotes.' }),
                        React.createElement("div", { className: "flex flex-row justify-center align-center w-48 m-auto" },
                            React.createElement(Button, { danger: true, type: 'primary', className: "m-auto", icon: React.createElement(LegacyIcon, { type: 'check' }), onClick: () => {
                                    QuoteStore.duplicateMasterProduct(row.id);
                                    setEdit(false);
                                    editable(false);
                                }, loading: loading, style: { width: '150px' } }, "Duplicate"),
                            React.createElement("div", null,
                                React.createElement(Button, { className: "ml-2", style: { width: '100px' } }, "Cancel"))))))),
            hasGroup('Developer') && (React.createElement("div", null,
                React.createElement("a", { onClick: e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowPartModalHistory(true);
                    } },
                    "History ",
                    React.createElement(HistoryOutlined, null))))),
        row?.id && showPartModalHistory && (React.createElement(MasterProductModalHistory, { id: row?.id, handleClose: () => setShowPartModalHistory(false) })),
        !getFieldValue('quotable') && (React.createElement(Modal, { visible: showNotes || editNotes, centered: true, title: "Why this product is not quotable?", onCancel: () => {
                setShowNotes(false);
                closedNotes(false);
            }, footer: [
                React.createElement(Button, { loading: loading, key: "ButtonAccept", type: "primary", htmlType: "submit", onClick: () => {
                        handleSubmit();
                    } }, "Accept"),
            ], width: 230 },
            React.createElement(Form.Item, { className: "font-bold", label: 'Reason' }, getFieldDecorator('notes', {
                initialValue: row.notes,
                rules: [
                    {
                        required: true,
                    },
                ],
            })(React.createElement(Input.TextArea, { rows: 2, className: "w-48", placeholder: "Why this product is not quotable?" }))),
            React.createElement(Form.Item, { className: "font-bold", label: 'Category' }, getFieldDecorator('no_quote_category', {
                initialValue: row.no_quote_category,
                rules: [
                    {
                        required: true,
                        message: 'Select a Category',
                    },
                ],
            })(React.createElement(Select, { showSearch: true, placeholder: "Select a Category", className: "w-48", dropdownMatchSelectWidth: false, style: {
                    width: '100%',
                } },
                React.createElement(Select.Option, { value: "Assembly" }, "Assembly"),
                React.createElement(Select.Option, { value: "Bending - Vendor" }, "Bending - Vendor"),
                React.createElement(Select.Option, { value: "Capabilities" }, "Capabilities"),
                React.createElement(Select.Option, { value: "Certifications" }, "Certifications"),
                React.createElement(Select.Option, { value: "Coatings" }, "Coatings"),
                React.createElement(Select.Option, { value: "Company Culture" }, "Company Culture"),
                React.createElement(Select.Option, { value: "Features" }, "Features"),
                React.createElement(Select.Option, { value: "Files" }, "Files"),
                React.createElement(Select.Option, { value: "Finished Size" }, "Finished Size"),
                React.createElement(Select.Option, { value: "Installation" }, "Installation"),
                React.createElement(Select.Option, { value: "Lead Time" }, "Lead Time"),
                React.createElement(Select.Option, { value: "Machinability" }, "Machinability"),
                React.createElement(Select.Option, { value: "Machining Size" }, "Machining Size"),
                React.createElement(Select.Option, { value: "Material" }, "Material"),
                React.createElement(Select.Option, { value: "Measurement" }, "Measurement"),
                React.createElement(Select.Option, { value: "RFQ" }, "RFQ"),
                React.createElement(Select.Option, { value: "Secondary Process" }, "Secondary Process"),
                React.createElement(Select.Option, { value: "Tolerance" }, "Tolerance"),
                React.createElement(Select.Option, { value: "Wall Thickness" }, "Wall Thickness"),
                React.createElement(Select.Option, { value: "New Category" }, "New Category"))))))));
};
export default Form.create()(PartDetails);
