import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Collapse, Row, Col, Card, notification, Typography, Spin } from 'antd';
import { QuoteStore } from '../Stores';
import { ApplicationCostItems, QuoteActions, QuoteNotes, QuoteNotifications, QuoteStatus, QuoteTotals, RFQView, TooltipFlag, } from '../components';
import { MasterProductDetail } from '../components/MasterProduct/index';
import { AdditionalRequirements, ErrorRender } from '@partsbadger/library';
import dayjs from 'dayjs';
import { AccountOverview, RfqInformation, RfqOverview } from '../components/Collapses';
import DynamicQuantitiesSwitcher from '../components/DynamicQuantitiesSwitcher';
import { patch } from '@partsbadger/utils';
import { useLocation } from 'react-router-dom';
const { Paragraph } = Typography;
const ButtonGroup = Button.Group;
const LayoutV1 = (props) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [loadingAllDynamicQuatities, setLoadingAllDynamicQuatities] = useState(false);
    const { quote, selectedLineItems, setSelectedLineItems, setShowBulkEdit, line_items, account_detail, setPreviewQuote, additional_requirements, setAdditionalRequirements, setReorderingAppCost, } = props;
    const line_items_dynamic_quote = QuoteStore.lineItems.filter(item => item.has_dynamic_quantities);
    const line_items_no_dynamic_quote = QuoteStore.lineItems.filter(item => !item.has_dynamic_quantities);
    const onChangeDynamicQuantities = async (enabled, items) => {
        setLoadingAllDynamicQuatities(true);
        try {
            await patch(`/staff/quotes/${quote.id}/update-multiple/`, {
                quoted_products: items,
                data: { has_dynamic_quantities: enabled },
            });
            await QuoteStore.recalculatePrices({ quoted_products: items });
            await QuoteStore.getLineItems(quote.id);
            setLoadingAllDynamicQuatities(false);
        }
        catch (error) {
            QuoteStore.error = error;
            setLoadingAllDynamicQuatities(false);
        }
        QuoteStore.getQuote(quote.id);
    };
    useEffect(() => {
        if (location.hash) {
            const targetElement = document.getElementById(location.hash.substring(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, sm: 8 },
                React.createElement(RfqOverview, { quote_type: quote.type, rfq_submission_method: QuoteStore.rfq?.submission_method || '', rfq_submission_date: QuoteStore.rfq ? dayjs(QuoteStore.rfq.created_time).format('LLL') : '', quote_number: quote.id, rfq_number: QuoteStore.rfq ? QuoteStore.rfq?.id : null, rfq_status: QuoteStore.rfq?.status || '', quote_design_engineer: quote.design_engineer?.fullname || '', quote_custom_rfq_reference: QuoteStore.rfq?.quote?.customer_rfq_reference || '', rfq_shipping_name: QuoteStore.rfq?.shipping_name || '', rfq_shipping_street: QuoteStore.rfq?.shipping_street || '', rfq_shipping_state: QuoteStore.rfq?.shipping_state || '', rfq_shipping_country: QuoteStore.rfq?.shipping_country || '', rfq_shipping_zip_code: QuoteStore.rfq?.shipping_zip_code || '' })),
            React.createElement(Col, { xs: 24, sm: 8 },
                React.createElement(AccountOverview, { flags: QuoteStore.flagsAccountOverview, account_name: account_detail?.name || '', sales_rep: quote.owner?.fullname || '', contact_name: quote.contact?.fullname || '', contact_email: quote.contact?.email || '', close_rate: QuoteStore.quoteAccountOverview?.close_rate || 0, average_margin: QuoteStore.quoteAccountOverview?.average_margin || 0, margin_stddev: QuoteStore.quoteAccountOverview?.margin_stddev || 0, lifetime_value: QuoteStore.quoteAccountOverview?.lifetime_value?.toLocaleString() || '', most_recent_deal_stage: QuoteStore.quote?.deal?.stage || '', lost_reason: quote.deal?.lost_reason || '', deal_stage: QuoteStore.quote?.deal?.stage || null })),
            React.createElement(Col, { xs: 24, sm: 8 },
                React.createElement(RfqInformation, { flags: QuoteStore.flagsRfqInformation, rfq_account_name: QuoteStore.rfq?.account?.name || '', quote_has_batch_shipments: quote.has_batch_shipments, quote_whole_quote_adjust: quote.whole_quote_adjust, rfq_other_notes: QuoteStore.rfq?.other_notes || '', quote_customer_statement: QuoteStore.rfq?.quote?.customer_statement || '', rfq_itar: QuoteStore.rfq?.itar || false, rfq_needs_to_create_2d_files: QuoteStore.rfq?.needs_to_create_2d_files || false, rfq_needs_to_create_3d_files: QuoteStore.rfq?.needs_to_create_3d_files || false, quote_custom_requirements: QuoteStore.rfq?.quote?.custom_requirements || [], quote_customer_supplied_material: QuoteStore.rfq?.quote?.customer_supplied_material || false, quote_domestic_quoting: QuoteStore.rfq?.quote?.domestic_quoting || false, quote_requote_changes: QuoteStore.rfq?.quote?.requote_changes || [], rfq_custom_additional_requirements: QuoteStore.rfq?.custom_additional_requirements || '', rfq_target_lead_time: QuoteStore.rfq?.target_lead_time || '' }))),
        quote.rfq?.id && (React.createElement("div", { className: 'm-4' },
            React.createElement(Collapse, { accordion: true },
                React.createElement(Collapse.Panel, { header: React.createElement("div", { className: 'font-bold' }, "RFQ Products"), key: "1" },
                    React.createElement("div", null,
                        React.createElement(RFQView, { rfq_id: quote.rfq?.id })))))),
        React.createElement("div", { className: 'quote-actions flex justify-end' },
            React.createElement("div", { className: "flex items-center mr-6" }, line_items.length > 0 && (React.createElement(React.Fragment, null,
                loadingAllDynamicQuatities && (React.createElement(Spin, { tip: React.createElement("span", { className: "text-xs" }, "Dynamic Quantities For All..."), size: "small" })),
                React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                    //if all line items have dynamic quantities, show the switcher enabled
                    line_items_dynamic_quote.length === line_items.length && quote?.editable && (React.createElement(DynamicQuantitiesSwitcher, { initialValue: true, label: 'Dynamic Quantities For All Products', handleUpdate: async (enabled) => {
                            const items = line_items_dynamic_quote.map(item => item.id);
                            await onChangeDynamicQuantities(enabled, items);
                        } })),
                    // if any line item have not dynamic quantities, show the switcher disabled
                    line_items_dynamic_quote.length < line_items.length && quote?.editable && (React.createElement(DynamicQuantitiesSwitcher, { initialValue: false, label: 'Dynamic Quantities For All Products', handleUpdate: async (enabled) => {
                            const items = line_items_no_dynamic_quote.map(item => item.id);
                            await onChangeDynamicQuantities(enabled, items);
                        } })))))),
            quote.editable && QuoteStore.inProgress && (React.createElement(ButtonGroup, { className: 'add-button' },
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'check-square' }), title: 'Shortcut + A', style: { display: selectedLineItems.length === 0 ? 'inline' : 'none' }, onClick: () => {
                        setSelectedLineItems(QuoteStore.lineItems.map(p => p.id));
                    } }, "Select All"),
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'form' }), title: 'Shortcut + E', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, onClick: setShowBulkEdit }, "Bulk Edit"),
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'paper-clip' }), title: 'Shift + R', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, loading: QuoteStore.loading, onClick: () => {
                        const payload = {
                            quoted_products: selectedLineItems,
                        };
                        QuoteStore.recalculatePrices(payload).catch(err => {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'One of the line items has an error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        });
                    } }, "Recalculate Prices"),
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'minus-square' }), title: 'Escape', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, onClick: () => setSelectedLineItems([]) }, "Clear Selection")))),
        React.createElement("div", { className: 'quote-line-items' }, line_items?.map((item, index) => {
            return (React.createElement(Card, { id: `div-mp-${item.master_product.id}`, key: `mp-${item.master_product.name}-${index}`, style: {
                    padding: 0,
                }, bodyStyle: {
                    padding: 0,
                } },
                React.createElement(MasterProductDetail, { count: index, position: item.position, key: item.master_product.id, master_product: item.master_product, setSelectedLineItems: items => setSelectedLineItems(items), selectedLineItems: selectedLineItems })));
        })),
        React.createElement(Row, { className: 'quote-additional-cost' },
            React.createElement(Col, { xs: 24 },
                React.createElement("div", { className: 'my-2' }, account_detail && (React.createElement(ApplicationCostItems, { quote: quote, account: account_detail, setReorderingAppCost: setReorderingAppCost }))))),
        React.createElement(Row, { className: 'quote-additional-cost' },
            React.createElement(Col, { xs: 12 },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'font-bold' },
                        React.createElement("span", null, "Additional Requirements"),
                        React.createElement(TooltipFlag, { field_name: 'custom_requirements' })),
                    React.createElement("div", { className: 'mt-4', style: {
                            maxWidth: 800,
                        } },
                        React.createElement(AdditionalRequirements
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            requirements: QuoteStore.additional_requirements, onChange: values => {
                                if (quote.editable) {
                                    //@ts-ignore
                                    setAdditionalRequirements(values);
                                    QuoteStore.updateQuote(quote.id, {
                                        custom_requirements: values,
                                    });
                                }
                            }, 
                            //@ts-ignore
                            value: additional_requirements, hide_extras: false, disabled: !quote.editable })))),
            React.createElement(Col, { xs: 12 }, props.user?.group && props.user?.group.includes('Design Engineers') && (React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'font-bold' }, "Internal Quoting Notes"),
                React.createElement("div", { className: 'mt-4', style: {
                        maxWidth: 800,
                    } },
                    React.createElement(Paragraph, { className: 'text-left', style: {
                            margin: 0,
                        }, editable: {
                            onChange: notes => {
                                const payload = {
                                    internal_quoting_notes: notes,
                                };
                                setLoading(true);
                                QuoteStore.updateQuote(quote.id, payload)
                                    .then(() => {
                                    setLoading(false);
                                })
                                    .catch(() => {
                                    setLoading(false);
                                });
                            },
                        } }, quote?.internal_quoting_notes)))))),
        React.createElement(Row, { className: 'quote-notes p-4' },
            React.createElement(Col, { xs: 24 },
                React.createElement(QuoteNotes, null))),
        React.createElement(Row, { className: 'flex space-between flex-wrap quote-totals' },
            React.createElement("div", { className: "md:w-3/4" },
                React.createElement(QuoteNotifications, { height: 600 })),
            React.createElement("div", { className: "md:w-1/4" },
                React.createElement(QuoteTotals, { quote: quote }))),
        React.createElement(QuoteStatus, { quote: quote, user: props.user, isQuoteLoading: QuoteStore.loading }),
        React.createElement(QuoteActions, { loading: QuoteStore.loading, quote: quote, handlePreviewQuote: setPreviewQuote })));
};
export default observer(LayoutV1);
