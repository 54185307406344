import React, { useState } from 'react';
import { Popover, InputNumber, Button, Radio } from 'antd';
import { EditOutlined } from '@ant-design/icons';
const EditDiscountPopover = ({ record, onUpdateItem, loading }) => {
    const [discountOption, setDiscountOption] = useState('percentage');
    const [open, setOpen] = useState(false);
    const [discountValue, setDiscountValue] = useState(0);
    const hide = () => {
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const onChange = (e) => {
        setDiscountOption(e.target.value);
    };
    const discountContent = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Radio.Group, { defaultValue: "percentage", onChange: onChange },
                    React.createElement(Radio, { value: "percentage" },
                        "Percentage",
                        discountOption === 'percentage' && (React.createElement(InputNumber, { size: "small", style: { width: '60px' }, className: "ml-1", defaultValue: 0, min: 0, max: 100, formatter: value => `${value}%`, onChange: value => {
                                if (value !== null) {
                                    const calculateDiscount = (value / 100) * record.total;
                                    if (calculateDiscount > 0) {
                                        setDiscountValue(parseFloat(calculateDiscount.toFixed(2)));
                                    }
                                }
                            } }))),
                    React.createElement(Radio, { value: "direct" },
                        "Direct Price Reduction",
                        discountOption === 'direct' && (React.createElement(InputNumber, { size: "small", min: 0, max: record.total, className: "ml-1", onChange: value => {
                                if (value !== null) {
                                    setDiscountValue(parseFloat(value.toFixed(2)));
                                }
                            } }))))),
            React.createElement("div", { className: "mt-3" },
                React.createElement(Button, { type: "primary", size: "small", className: "mr-2", loading: loading, onClick: () => {
                        hide();
                        onUpdateItem(discountValue);
                    } }, "Done"),
                React.createElement(Button, { size: "small", onClick: hide, loading: loading }, "Cancel"))));
    };
    return (React.createElement(Popover, { content: discountContent(), title: React.createElement("span", { className: "bold" }, "Set Discount"), trigger: "click", open: open, onOpenChange: handleOpenChange },
        React.createElement(Button, { type: "text", icon: React.createElement(EditOutlined, null) })));
};
export default EditDiscountPopover;
