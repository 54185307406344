import React from 'react';
import { Button, Upload } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { API_ROOT } from '@partsbadger/utils';
import QuoteStore from '../../stores/QuoteStore';
import { allowed3d } from '@partsbadger/library/src/UploadFile/FileExtensions';
export const Upload3dFile = ({ item }) => {
    const props = {
        name: 'file',
        multiple: false,
        accept: allowed3d.map(r => `.${r.toLowerCase()}`).toString(),
        action: `${API_ROOT}/customer/quote/${QuoteStore.signed_uid}/3d-file/`,
        data: { part_name: item?.name },
        onChange: (info) => {
            const { status } = info.file;
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_3d: info.file.response.id, name: info.file.name },
                });
            }
            if (status === 'removed') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_3d: null },
                });
            }
        },
    };
    if (!item?.uid) {
        return null;
    }
    return (
    // @ts-ignore
    React.createElement(Upload, { ...props },
        React.createElement(Button, { className: "d-flex align-items-center", size: "small", style: {
                fontSize: '13px',
                lineHeight: '2rem',
                height: '2rem',
            }, type: 'primary', icon: React.createElement(FeatherIcon, { icon: "upload-cloud", size: '16' }) }, 'Upload 3D File')));
};
