import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PartItem } from '../components/Parts/PartItem';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, notification, Row, Tooltip, Typography } from 'antd';
import { QuoteStore } from '../Stores';
import { ThreeDViewer } from '@partsbadger/threedviewer';
import { MasterProductDetail } from '../components/MasterProduct';
import { ApplicationCostItems, QuoteNotes, QuoteTotals, RFQView, TooltipFlag } from '../components';
import { AdditionalRequirements, ErrorRender } from '@partsbadger/library';
import { useHotkeys } from 'react-hotkeys-hook';
import { patch } from '@partsbadger/utils';
import { AccountOverview, RfqInformation, RfqOverview } from '../components/Collapses';
import dayjs from 'dayjs';
const { Text, Paragraph } = Typography;
function getNext(current, list) {
    let next = list[0];
    let index = list.findIndex(id => id === current);
    if (index > -1) {
        index = index + 1;
        if (index < list.length) {
            next = list[index];
        }
    }
    return next;
}
function getPrev(current, list) {
    let next = list[list.length - 1];
    let index = list.findIndex(id => id === current);
    if (index > -1) {
        index = index - 1;
        if (index > -1) {
            next = list[index];
        }
    }
    return next;
}
const LayoutV2 = ({ quote, user, account, master_products, rfq_id, selectedLineItems, handleSelectLineItems, additional_requirements, setAdditionalRequirements, setShowQuoteNotificationDrawer, setReorderingAppCost, }) => {
    const [loading, setLoading] = useState(false);
    const [new_index, setNewIndex] = useState(0);
    const [masterProducts, setMasterProducts] = useState([]);
    const master_product = QuoteStore.selected_master_product;
    const master_products_ids = master_products.map(item => item.master_product.id);
    const quote_line_items = QuoteStore.lineItems;
    const quote_id = QuoteStore?.quote?.id || 0;
    useEffect(() => {
        setMasterProducts(master_products);
        // Set the first line item as selected only if the user hasn't selected anything.
        if (!master_product) {
            QuoteStore.setSelectedMasterProduct(master_products[0]?.master_product);
        }
    }, [master_products]);
    const handlePrevOrNext = (type) => {
        let next_master_product_id = -1;
        if (type === 'next') {
            next_master_product_id = getNext(master_product?.id, master_products_ids);
        }
        else {
            next_master_product_id = getPrev(master_product?.id, master_products_ids);
        }
        const selected = master_products.find((item, index) => {
            setNewIndex(index);
            return item.master_product.id === next_master_product_id;
        });
        QuoteStore.setSelectedMasterProduct(selected.master_product);
        location.href = `#${selected.master_product.name}${selected.master_product.id}`;
    };
    //hotkeys
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    useEffect(() => {
        if (next) {
            handlePrevOrNext('next');
            setNext(false);
        }
        if (prev) {
            handlePrevOrNext('prev');
            setPrev(false);
        }
    }, [next, prev]);
    useHotkeys('shift + n', () => setNext(true));
    useHotkeys('shift + p', () => setPrev(true));
    return (React.createElement("div", { className: 'quote-tool flex', style: {
            height: '100%',
        } },
        React.createElement("div", { className: 'aside', style: {
                width: '12%',
                height: '100%',
            } },
            React.createElement("div", { className: 'content-parts p-4 border', style: {
                    height: '60vh',
                    overflowY: 'auto',
                } }, masterProducts.map((row, index) => {
                const quote_line_items_by_master_product = quote_line_items.filter(item => item.product.master_product === row.master_product.id);
                return (React.createElement(PartItem, { id: row.master_product.name, key: row.master_product.id, selected: master_product?.id === row.master_product.id, 
                    //@ts-ignore
                    line_items: quote_line_items_by_master_product, master_product: row.master_product, handleClick: () => {
                        //@ts-ignore
                        QuoteStore.setSelectedMasterProduct(row.master_product);
                        setNewIndex(index);
                    }, handleUpdateName: name => {
                        patch('/staff/master-products/' + row.master_product.id + '/', { name: name })
                            .then(() => {
                            QuoteStore.getMasterProducts(quote_id);
                        })
                            .catch(error => {
                            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
                        });
                    } }));
            })),
            React.createElement("div", { className: 'part-actions w-full flex flex-row items-center shadow', style: {
                    height: '5%',
                } },
                React.createElement("div", { className: 'w-full flex flex-row items-center m-auto justify-between max-w-xs shadow p-4' },
                    React.createElement(Tooltip, { title: "Shift + p" },
                        React.createElement(Button, { size: 'large', type: 'primary', icon: React.createElement(StepBackwardOutlined, null), onClick: () => handlePrevOrNext('prev') }, "Prev Part")),
                    React.createElement(Tooltip, { title: "Shift + n" },
                        React.createElement(Button, { size: 'large', type: 'primary', icon: React.createElement(StepForwardOutlined, null), onClick: () => handlePrevOrNext('next') }, "Next Part")))),
            React.createElement("div", { className: 'rfq-view shadow', style: {
                    // width: '20%',
                    height: '35%',
                    overflowY: 'auto',
                } },
                React.createElement(RfqOverview, { quote_type: quote.type, rfq_submission_method: QuoteStore.rfq?.submission_method || '', rfq_submission_date: QuoteStore.rfq ? dayjs(QuoteStore.rfq.created_time).format('LLL') : '', quote_number: quote.id, rfq_number: QuoteStore.rfq ? QuoteStore.rfq?.id : null, rfq_status: QuoteStore.rfq?.status || '', quote_design_engineer: quote.design_engineer?.fullname || '', quote_custom_rfq_reference: QuoteStore.rfq?.quote?.customer_rfq_reference || '', rfq_shipping_name: QuoteStore.rfq?.shipping_name || '', rfq_shipping_street: QuoteStore.rfq?.shipping_street || '', rfq_shipping_state: QuoteStore.rfq?.shipping_state || '', rfq_shipping_country: QuoteStore.rfq?.shipping_country || '', rfq_shipping_zip_code: QuoteStore.rfq?.shipping_zip_code || '' }),
                React.createElement("div", { className: 'p-4' },
                    React.createElement(Collapse, null,
                        React.createElement(Collapse.Panel, { header: React.createElement("div", { className: 'font-bold' }, "RFQ Products"), key: "1" }, rfq_id && React.createElement(RFQView, { rfq_id: rfq_id })))))),
        React.createElement("div", { className: 'content-right', style: {
                width: '88%',
                height: '100%',
            } },
            React.createElement(Row, { justify: "center" },
                React.createElement(Col, { xs: 24, sm: 12 },
                    React.createElement(AccountOverview, { flags: QuoteStore.flagsAccountOverview, account_name: quote.account?.name || '', sales_rep: quote.owner?.fullname || '', contact_name: quote.contact?.fullname || '', contact_email: quote.contact?.email || '', close_rate: QuoteStore.quoteAccountOverview?.close_rate || 0, average_margin: QuoteStore.quoteAccountOverview?.average_margin || 0, margin_stddev: QuoteStore.quoteAccountOverview?.margin_stddev || 0, lifetime_value: QuoteStore.quoteAccountOverview?.lifetime_value?.toLocaleString() || '', most_recent_deal_stage: QuoteStore.quote?.deal?.stage || '', lost_reason: quote.deal?.lost_reason || '', deal_stage: QuoteStore.quote?.deal?.stage || null })),
                React.createElement(Col, { xs: 24, sm: 12 },
                    React.createElement(RfqInformation, { flags: QuoteStore.flagsRfqInformation, rfq_account_name: QuoteStore.rfq?.account?.name || '', quote_has_batch_shipments: quote.has_batch_shipments, quote_whole_quote_adjust: quote.whole_quote_adjust, rfq_other_notes: QuoteStore.rfq?.other_notes || '', quote_customer_statement: QuoteStore.rfq?.quote?.customer_statement || '', rfq_itar: QuoteStore.rfq?.itar || false, rfq_needs_to_create_2d_files: QuoteStore.rfq?.needs_to_create_2d_files || false, rfq_needs_to_create_3d_files: QuoteStore.rfq?.needs_to_create_3d_files || false, quote_custom_requirements: QuoteStore.rfq?.quote?.custom_requirements || [], quote_customer_supplied_material: QuoteStore.rfq?.quote?.customer_supplied_material || false, quote_domestic_quoting: QuoteStore.rfq?.quote?.domestic_quoting || false, quote_requote_changes: QuoteStore.rfq?.quote?.requote_changes || [], rfq_custom_additional_requirements: QuoteStore.rfq?.custom_additional_requirements || '', rfq_target_lead_time: QuoteStore.rfq?.target_lead_time || '' }))),
            React.createElement("div", { className: 'part-viewers flex justify-content', style: {
                    height: '75vh',
                } }, master_product?.id ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'file-3d shadow', style: {
                        width: `${master_product?.step_file?.id && master_product?.drawing_file?.file
                            ? '40%'
                            : master_product?.step_file?.id && !master_product?.drawing_file?.file
                                ? '100% '
                                : !master_product?.step_file?.id && '0%'}`,
                    } }, master_product?.step_file?.file_3djs && (React.createElement(ThreeDViewer, { url: master_product?.step_file?.file_3djs, canvasStyle: {
                        height: '100%',
                    } }))),
                React.createElement("div", { className: 'file-2d shadow', style: {
                        width: `${master_product?.step_file?.id && master_product?.drawing_file?.file
                            ? '60%'
                            : master_product?.drawing_file?.file && !master_product?.step_file?.id
                                ? '100% '
                                : !master_product?.drawing_file?.file && '0%'}`,
                    } }, master_product?.drawing_file?.file && (React.createElement(React.Fragment, null, master_product.drawing_file?.file
                    ?.split('.')
                    ?.pop()
                    ?.split('?')[0]
                    .toLowerCase() == 'pdf' ? (React.createElement("embed", { key: master_product?.drawing_file?.id, src: master_product?.drawing_file?.file, type: "application/pdf", width: "100%", height: `100%` })) : (React.createElement("div", { className: 'flex items-center justify-center h-full' },
                    "This file is not a pdf",
                    React.createElement("a", { className: 'ml-2', href: master_product.drawing_file.file }, master_product.drawing_file.filename)))))))) : (React.createElement("div", { className: 'flex flex-col items-center text-xl justify-center w-full' }, "Please select a part on the left!"))),
            React.createElement("div", { className: 'quote-details flex flex-row', style: {
                    height: '45%',
                } },
                React.createElement("div", { className: 'quote-inputs p-2', style: {
                        width: '100%',
                        height: '100%',
                    } },
                    React.createElement("div", { className: 'line-items', style: {
                            height: '65%',
                            overflowY: 'auto',
                        } },
                        master_products
                            ?.filter(item => item.master_product?.id === master_product?.id)
                            ?.map((item, index) => {
                            return (React.createElement(MasterProductDetail, { key: item.master_product.id, count: new_index, position: item.position, master_product: item.master_product, setSelectedLineItems: (keys) => {
                                    handleSelectLineItems(keys);
                                }, selectedLineItems: selectedLineItems, item: dataMasterProduct => {
                                    setMasterProducts([]);
                                    //@ts-ignore
                                    setMasterProducts(dataMasterProduct);
                                } }));
                        }),
                        !master_product?.id && (React.createElement("div", { className: 'flex flex-col items-center text-xl justify-center w-full h-full' }, "Please select a part on the left!"))),
                    React.createElement("div", { className: 'quote-inputs flex flex-row w-full', style: {
                            height: '35%',
                        } },
                        React.createElement("div", { className: 'w-1/3', style: { height: '100%' } },
                            React.createElement(QuoteNotes, null)),
                        React.createElement("div", { className: 'w-1/2', style: {
                                height: '100%',
                                overflowY: 'auto',
                            } }, account && (React.createElement(ApplicationCostItems, { quote: quote, account: account, setReorderingAppCost: setReorderingAppCost }))),
                        React.createElement("div", { className: 'w-1/3 flex flex-row' },
                            React.createElement("div", { className: 'w-1/3 p-2' },
                                React.createElement("div", null,
                                    React.createElement("span", null, "Additional Requirements"),
                                    React.createElement(TooltipFlag, { field_name: 'custom_requirements' })),
                                React.createElement("div", { style: {} },
                                    React.createElement(AdditionalRequirements
                                    //@ts-ignore
                                    , { 
                                        //@ts-ignore
                                        requirements: QuoteStore.additional_requirements, onChange: values => {
                                            setAdditionalRequirements(values);
                                            QuoteStore.updateQuote(quote.id, {
                                                custom_requirements: values,
                                            });
                                        }, 
                                        //@ts-ignore
                                        value: additional_requirements, hide_extras: false, disabled: !quote.editable })),
                                React.createElement("div", null, "Internal Quoting Notes"),
                                React.createElement("div", null, user?.group && user?.group.includes('Design Engineers') && (React.createElement("div", { className: 'flex flex-col' },
                                    React.createElement("div", { className: 'mt-4', style: {
                                            maxWidth: 800,
                                        } },
                                        React.createElement(Paragraph, { className: 'text-left', style: {
                                                margin: 0,
                                            }, editable: {
                                                onChange: notes => {
                                                    const payload = {
                                                        internal_quoting_notes: notes,
                                                    };
                                                    setLoading(true);
                                                    QuoteStore.updateQuote(quote.id, payload)
                                                        .then(() => {
                                                        setLoading(false);
                                                    })
                                                        .catch(() => {
                                                        setLoading(false);
                                                    });
                                                },
                                            } }, quote?.internal_quoting_notes)))))),
                            React.createElement("div", { className: 'w-2/3 p-2 text-xl' },
                                React.createElement("div", { className: 'w-full flex flex-row items-end m-auto justify-end p-4' },
                                    React.createElement(Tooltip, { title: "Shift + p" },
                                        React.createElement(Button, { size: 'small', type: 'primary', icon: React.createElement(StepBackwardOutlined, null), onClick: () => handlePrevOrNext('prev') }, "Prev Part")),
                                    "\u00A0",
                                    React.createElement(Tooltip, { title: "Shift + n" },
                                        React.createElement(Button, { size: 'small', type: 'primary', icon: React.createElement(StepForwardOutlined, null), onClick: () => handlePrevOrNext('next') }, "Next Part"))),
                                React.createElement(QuoteTotals, { quote: quote })))))))));
};
export default observer(LayoutV2);
