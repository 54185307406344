import { Collapse } from 'antd';
import React from 'react';
import PillSelect from '../../common/PillSelect';
const { Panel } = Collapse;
export const VendorQualifications = (props) => {
    const groupingQualifications = Object.values(props.data.reduce((acc, current) => {
        acc[current.category_name] = acc[current.category_name] ?? [];
        acc[current.category_name].push(current);
        return acc;
    }, {}));
    return (React.createElement(Collapse, null, groupingQualifications.map((group, index) => (React.createElement(Panel, { key: index, header: group[0].category_name },
        React.createElement(PillSelect, { ...props, data: group }))))));
};
