import React from 'react';
import { Form, Select, Input, Button, Row, message } from 'antd';
import { SaveFilled } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
export const NotesForm = (props) => {
    const [form] = Form.useForm();
    const submitNote = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                title: values.title,
                content: values.content,
            };
            props.handleSubmit(payload);
        }
        catch (errorInfo) {
            message.error('Please input all required fields');
        }
    };
    return (React.createElement(Form, { form: form, layout: "horizontal", className: 'sales-order-form', ...formItemLayout, onFinish: submitNote },
        React.createElement(Form.Item, { label: "Title", name: "title", rules: [{ required: true, message: 'Please select a title' }] },
            React.createElement(Select, { showSearch: true, placeholder: "Select a title", optionFilterProp: "children", style: { width: '100%' } },
                React.createElement(Option, { value: "Production Update" }, "Production Update"),
                React.createElement(Option, { value: "Rep Note" }, "Rep Note"),
                React.createElement(Option, { value: "General" }, "General"))),
        React.createElement(Form.Item, { label: "Content", name: "content", rules: [{ required: true, message: 'Please insert content' }] },
            React.createElement(TextArea, { rows: 4 })),
        React.createElement(Row, { className: 'row-order mt-5' },
            React.createElement("div", { style: { textAlign: 'center' } },
                React.createElement(Button, { type: "primary", htmlType: "submit" },
                    React.createElement(SaveFilled, null),
                    " Save Note")))));
};
export default NotesForm;
