import React, { useState } from 'react';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import Suggestion from '../Suggestion';
export const Coatings = observer((props) => {
    const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);
    const items = props.coatings;
    // Get Custom Option
    const custom = items.find(item => item.name === 'Custom');
    const menu = items
        .slice()
        .sort(a => (a.name === 'None' ? -1 : 1))
        .map(item => {
        return (React.createElement(Select.Option, { key: item.id, value: item.id }, item.name));
    });
    const EmptyTag = (React.createElement("div", { className: "flex flex-no-wrap p-2 cursor-pointer", onClick: () => {
            if (custom && props.onChange) {
                props.onChange({
                    key: custom.id.toString(),
                    label: custom.name,
                }, []);
                setIsDropDownMenuOpen(false);
            }
        } },
        React.createElement("span", { className: "flex flex-row items-center cursor-pointer justify-center" },
            "Can't find your Coating? please select ",
            React.createElement("div", { className: "ml-2 link" }, "Custom"))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { showSearch: true, labelInValue: true, bordered: true, size: props?.for_staff ? 'middle' : 'small', dropdownMatchSelectWidth: false, placeholder: "Select Coating", open: isDropDownMenuOpen, onDropdownVisibleChange: visible => setIsDropDownMenuOpen(visible), ...props, notFoundContent: EmptyTag, allowClear: false, "data-testid": "coatings-test", filterOption: (input, option) => {
                try {
                    if (!option.key) {
                        // Filter by group label
                        return option.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                    }
                    return option.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                }
                catch (e) {
                    return false;
                }
            }, dropdownRender: menu => React.createElement("div", null, menu) },
            custom && custom?.id > 0 && (React.createElement(Select.OptGroup, { label: 'Custom' },
                React.createElement(Select.Option, { key: custom.id }, custom.name))),
            React.createElement(Select.OptGroup, { label: 'Options' }, menu)),
        !props.value?.label && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, props.suggestions?.map(suggestion => (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: () => {
                const val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    props.onSelect(val, val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })))))));
});
