import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tooltip } from 'antd';
import { DroppableFileZone } from '@partsbadger/library';
import { allowed_2d, allowed_3d } from '@partsbadger/utils';
import { EyeOutlined } from '@ant-design/icons';
const styles = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 10,
    alignItems: 'center',
};
export const ItemsCreateForm = ({ product, record, setShowAddStepFile, setShowAddDrawingFile, renderDrawingFile, onDropFile, }) => {
    return (React.createElement("div", { className: 'flex flex-row w-full' },
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "3D File:"),
            " \u00A0",
            record?.name_3d_file ? (React.createElement(DroppableFileZone, { onDrop: file => {
                    return onDropFile(file, '3D');
                } },
                React.createElement(Tooltip, { title: `Accepted files: ${allowed_3d.replace(/,/g, ' ')}` },
                    React.createElement("a", null, record.name_3d_file),
                    React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: () => setShowAddStepFile(true) })))) : (React.createElement(DroppableFileZone, { onDrop: files => {
                    return onDropFile(files, '3D');
                } }, record.production_3d_file ? (React.createElement(Tooltip, { title: `Accepted files: ${allowed_3d.replace(/,/g, ' ')}` },
                record.production_3d_file.filename,
                React.createElement(LegacyIcon, { title: 'Change 3D file s', className: 'link', type: 'edit', onClick: () => setShowAddStepFile(true) }))) : (React.createElement(Tooltip, { title: `Accepted files: ${allowed_3d.replace(/,/g, ' ')}` },
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: () => setShowAddStepFile(true) })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "2D File:"),
            " \u00A0",
            record?.name_2d_file ? (React.createElement(DroppableFileZone, { onDrop: files => {
                    return onDropFile(files, '2D');
                } }, record?.name_2d_file && (React.createElement(Tooltip, { title: `Accepted files: ${allowed_2d.replace(/,/g, ' ')}` },
                React.createElement("span", { title: 'Preview 2D file', className: 'link', onClick: () => {
                        if (record.production_2d_file_id) {
                            renderDrawingFile(record.production_2d_file_id);
                        }
                    } },
                    React.createElement("a", null,
                        " ",
                        record.name_2d_file)),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: () => setShowAddDrawingFile(true) }))))) : (React.createElement(DroppableFileZone, { onDrop: file => {
                    return onDropFile(file, '2D');
                } }, record.production_2d_file ? (React.createElement(Tooltip, { title: `Accepted files: ${allowed_2d.replace(/,/g, ' ')}` },
                React.createElement("span", { title: 'Preview 2D file', className: 'link', onClick: () => record.production_2d_file && renderDrawingFile(record.production_2d_file.id) }, record.production_2d_file.filename),
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: () => setShowAddDrawingFile(true) }))) : (React.createElement(Tooltip, { title: `Accepted files: ${allowed_2d.replace(/,/g, ' ')}` },
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 2D file', className: 'link', type: 'plus', onClick: () => setShowAddDrawingFile(true) })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "Other Files:"),
            " \u00A0",
            React.createElement("div", { className: 'flex flex-col' }, record.production_other_files?.map(f => (React.createElement("div", { key: f.id, className: 'flex flex-row', title: 'Preview 2D file' },
                f?.filename,
                React.createElement("div", { className: 'ml-2 link', onClick: () => window.open(f.file, '', `width=960,height=1200,left=0`) },
                    React.createElement(EyeOutlined, null)))))))));
};
