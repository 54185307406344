import * as React from 'react';
import { useCallback, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import _ from 'lodash';
const AutoCompletedRecord = (props) => {
    const [loading, setLoading] = useState(false);
    const debouncedSearch = useCallback(_.debounce(search => fetchData(search), 400), []);
    const fetchData = async (search) => {
        setLoading(true);
        await props.handleFetch(search);
        setLoading(false);
    };
    return (React.createElement(Select, { showSearch: true, defaultActiveFirstOption: true, filterOption: false, style: { width: '100%' }, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, showArrow: false, placeholder: React.createElement("span", null,
            React.createElement(SearchOutlined, null),
            "Search"), allowClear: true, labelInValue: true, dropdownMatchSelectWidth: false, ...props, onSearch: search => {
            debouncedSearch(search);
        }, onSelect: (value, element) => {
            if (value.key && props.handleSelect) {
                props.handleSelect({
                    key: value.key,
                    label: value.label,
                });
            }
            if (props?.onSelect) {
                props.onSelect(value, element);
            }
        } }, props.records.map(record => (React.createElement(Select.Option, { key: record.id }, record.label)))));
};
export default AutoCompletedRecord;
