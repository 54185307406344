import { Button, Input, Modal } from 'antd';
import React, { useState } from 'react';
const ModalNotesForm = (props) => {
    const { modalTitle, buttonLabel, inputPlaceHolder, defaultValue, handleSave, handleCancel } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState(defaultValue);
    return (React.createElement(Modal, { visible: true, centered: true, title: modalTitle, onCancel: handleCancel, footer: [
            React.createElement(Button, { key: "Add", type: "primary", loading: isLoading, onClick: () => {
                    setIsLoading(true);
                    handleSave(notes).finally(() => setIsLoading(false));
                } }, buttonLabel),
        ] },
        React.createElement(Input.TextArea, { placeholder: inputPlaceHolder, value: notes, rows: 4, onChange: event => {
                setNotes(event.target.value);
            } })));
};
export default ModalNotesForm;
