import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
const RadioChecklist = (props) => {
    const renderComponent = () => {
        if (props.question.visible) {
            return (React.createElement(Form.Item, null, props.getFieldDecorator(`confirm-${props.question.question}`, {
                rules: [{ required: props.question.required, message: 'Required' }],
                initialValue: props.question?.answer?.confirm ?? '',
            })(React.createElement(Radio.Group, { className: "flex mt-2", onChange: (e) => {
                    const value = e.target.value;
                    const question_id = props.question.question_id;
                    const arrayQuestion = props.checklistQuestions.filter(q => q.depends_on === question_id && q.question_id !== null);
                    if (arrayQuestion.length > 0) {
                        const questionF = arrayQuestion[0];
                        value === 'Yes' ? (questionF.visible = true) : (questionF.visible = false);
                        props.setChecklistQuestions([...props.checklistQuestions]);
                    }
                    const key = `confirm-${props.question.question}`;
                    props.form.setFieldsValue({
                        [key]: value,
                    });
                }, defaultValue: props.form.getFieldValue(`confirm-${props.question.question}`) },
                React.createElement(Radio, { value: 'Yes' }, "Yes"),
                React.createElement(Radio, { value: 'No' }, "No")))));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return renderComponent();
};
export default RadioChecklist;
