import { useEffect, useState } from 'react';
import { BASE_URL_WS } from '@partsbadger/utils';
import ReconnectingWebSocket from 'reconnecting-websocket';
const useSocketQuoteConnection = (quote_id, token) => {
    const [connectedSocket, setConnectedSocket] = useState(false);
    const [new_notification, setNewNotification] = useState();
    useEffect(() => {
        const url = `${BASE_URL_WS}/ws/quotes/${quote_id}/?token=${token}`;
        const chatSocket = new ReconnectingWebSocket(url, [], {
            maxRetries: 50,
        });
        chatSocket.onopen = m => {
            setConnectedSocket(true);
        };
        chatSocket.onmessage = message => {
            setNewNotification(JSON.parse(message.data));
        };
        chatSocket.onclose = e => {
            setConnectedSocket(false);
        };
        chatSocket.onerror = e => {
            console.log('Error');
        };
        return () => {
            if (chatSocket) {
                chatSocket.close();
            }
        };
    }, []);
    return { new_notification, connectedSocket };
};
export default useSocketQuoteConnection;
