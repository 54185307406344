import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Card, Col, Divider, Modal, notification, PageHeader, Row, Select, Switch, Tag, Typography } from 'antd';
import DatePicker from '../DatePicker/datepicker';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ShippingCarriers, ShippingMethods } from '../Select';
import NotesForm from '../Form/NotesForm';
import { NotesListCard } from './NotesListCard';
import { Price } from '../Price';
import { Addresses, AddressForm } from '../Addresses';
const { Text, Paragraph } = Typography;
export const SalesOrderCard = ({ sales_order, sales_order_notes, createNote, editOrder, permission, addresses, handleSaveAddress, }) => {
    const history = useHistory();
    const [editableStatus, setEditableStatus] = useState(false);
    const [editableRepShipDate, setEditableReShipDate] = useState(false);
    const [editableHotShipDate, setEditableHotShipDate] = useState(false);
    const [editableCarrier, setEditableCarrier] = useState(false);
    const [editableShippingMethod, setEditableShippingMethod] = useState(false);
    const [editableAddRequirements, setEditableAddRequirements] = useState(false);
    const [showAddNewAddress, setShowAddNewAddress] = useState(false);
    const [additional_requirements, setAdditionalRequirements] = useState();
    const handleSubmit = (values) => {
        createNote(values);
    };
    const handlePatch = (payload) => {
        if (permission) {
            editOrder(payload);
        }
        else {
            notification['error']({
                message: 'Not authorized',
                description: 'You do not have permissions to edit this order.',
            });
        }
    };
    const editInHouse = (str) => {
        const payload = {
            in_house_requirements: str,
        };
        handlePatch(payload);
    };
    const editShipAccountNumber = (str) => {
        const payload = {
            shipping_account_number: str,
        };
        handlePatch(payload);
    };
    const children = [];
    sales_order.additional_requirements?.map((requirement) => {
        // @ts-ignore
        children.push(React.createElement(Select.Option, { key: requirement }, requirement));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { style: {
                border: '1px solid rgb(235, 237, 240)',
            }, onBack: () => history.goBack(), title: "Sales Order", subTitle: sales_order.name }),
        React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: 'w-1/3' },
                React.createElement("div", { className: 'flex justify-center p-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Account:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.account.name)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Account number:"),
                            " \u00A0",
                            React.createElement(Text, null, "---")),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Recovered Account:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.recovered_account ? 'Yes' : 'No')),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Sales Order Owner:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.owner.fullname)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Contact Name:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.contact.fullname)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Status:"),
                            " \u00A0",
                            editableStatus ? (React.createElement(React.Fragment, null,
                                React.createElement(Select, { style: { width: '200px' }, defaultValue: sales_order.status, onSelect: status => {
                                        const payload = {
                                            status: status,
                                        };
                                        handlePatch(payload);
                                        setEditableStatus(false);
                                    } },
                                    React.createElement(Select.Option, { value: "Pending" }, "Pending"),
                                    React.createElement(Select.Option, { value: "Ready for Production" }, "Ready for Production"),
                                    React.createElement(Select.Option, { value: "Hold" }, "Hold")),
                                "\u00A0",
                                React.createElement("a", { onClick: () => setEditableStatus(!editableStatus) },
                                    React.createElement(CloseCircleOutlined, null)))) : (React.createElement(React.Fragment, null,
                                React.createElement(Text, null, sales_order.status),
                                " \u00A0",
                                React.createElement("a", { onClick: () => setEditableStatus(!editableStatus) },
                                    React.createElement(EditOutlined, null))))),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "High Risk:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.high_risk ? 'Yes' : 'No')),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Hot Order:"),
                            " \u00A0",
                            React.createElement(React.Fragment, null,
                                "No",
                                ' ',
                                React.createElement(Switch, { checked: sales_order.hot_order, size: 'small', onChange: value => {
                                        const payload = {
                                            hot_order: value,
                                        };
                                        handlePatch(payload);
                                    } }),
                                ' ',
                                "Yes")),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Hot Ship Date: "),
                            " \u00A0",
                            editableHotShipDate ? (React.createElement(React.Fragment, null,
                                React.createElement(DatePicker, { defaultValue: dayjs(sales_order.hot_ship_date, 'YYYY-MM-DD'), 
                                    // defaultValue={moment(sales_order.hot_ship_date, 'YYYY-MM-DD')}
                                    onChange: (date) => {
                                        const payload = {
                                            hot_ship_date: dayjs(date).format('YYYY-MM-DD'),
                                        };
                                        handlePatch(payload);
                                        setEditableHotShipDate(false);
                                    } }))) : (React.createElement(React.Fragment, null,
                                React.createElement(Text, null, dayjs(sales_order.hot_ship_date).format('LLLL')),
                                " \u00A0",
                                React.createElement("a", { onClick: () => setEditableHotShipDate(true) },
                                    React.createElement(EditOutlined, null))))),
                        React.createElement("div", { className: 'flex flex-row p-1' },
                            React.createElement(Text, { strong: true }, "In House Requirements:"),
                            " \u00A0",
                            React.createElement(Paragraph, { editable: { onChange: editInHouse } }, sales_order.in_house_requirements)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Additional Requirements:"),
                            " ",
                            React.createElement("br", null),
                            editableAddRequirements ? (React.createElement(React.Fragment, null,
                                React.createElement(Select, { mode: "tags", style: { width: '350px' }, defaultValue: sales_order.additional_requirements, onChange: values => {
                                        setAdditionalRequirements(values);
                                    }, tokenSeparators: [','] }, children),
                                ' ',
                                "\u00A0",
                                React.createElement("a", { onClick: () => {
                                        const payload = {
                                            additional_requirements: additional_requirements,
                                        };
                                        handlePatch(payload);
                                    } },
                                    React.createElement(CheckCircleOutlined, null)),
                                ' ',
                                "\u2003",
                                React.createElement("a", { onClick: () => setEditableAddRequirements(false) },
                                    React.createElement(CloseCircleOutlined, null)))) : (React.createElement(React.Fragment, null,
                                sales_order.additional_requirements?.map((requirement) => {
                                    return (React.createElement(React.Fragment, null,
                                        React.createElement(Tag, null, requirement),
                                        React.createElement("br", null)));
                                }),
                                React.createElement("a", { onClick: () => setEditableAddRequirements(true) },
                                    React.createElement(EditOutlined, null)))))))),
            React.createElement("div", { className: 'w-1/3' },
                React.createElement("div", { className: 'flex justify-center p-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Required Ship Date:"),
                            " \u00A0",
                            editableRepShipDate ? (React.createElement(React.Fragment, null,
                                React.createElement(DatePicker, { defaultValue: dayjs(sales_order.required_ship_date, 'YYYY-MM-DD'), onChange: (date) => {
                                        const payload = {
                                            required_ship_date: dayjs(date).format('YYYY-MM-DD'),
                                        };
                                        handlePatch(payload);
                                        setEditableReShipDate(false);
                                    } }))) : (React.createElement(React.Fragment, null,
                                React.createElement(Text, null, dayjs(sales_order.required_ship_date).format('LLLL')),
                                " \u00A0",
                                React.createElement("a", { onClick: () => setEditableReShipDate(true) },
                                    React.createElement(EditOutlined, null))))),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Closing Date:"),
                            " \u00A0",
                            React.createElement(Text, null, dayjs(sales_order.closing_date).format('LLLL'))),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Req. Partner Ship Date:"),
                            " \u00A0",
                            React.createElement(Text, null, dayjs(sales_order.partner_ship_date).format('LLLL'))),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Intake Status:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.intake_status)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Shipping Status:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.shipping_status)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Total Cost:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.total_cost)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Domestic Process Needed:"),
                            " \u00A0",
                            React.createElement(React.Fragment, null,
                                "No",
                                ' ',
                                React.createElement(Switch, { checked: sales_order.domestic_process_needed, size: 'small', onChange: value => {
                                        const payload = {
                                            domestic_process_needed: value,
                                        };
                                        handlePatch(payload);
                                    } }),
                                ' ',
                                "Yes")),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Hardware Required:"),
                            " \u00A0",
                            React.createElement(React.Fragment, null,
                                "No",
                                ' ',
                                React.createElement(Switch, { checked: sales_order.hardware_required, size: 'small', onChange: value => {
                                        const payload = {
                                            hardware_required: value,
                                        };
                                        handlePatch(payload);
                                    } }),
                                ' ',
                                "Yes")),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Internal Notes:"),
                            " \u00A0",
                            React.createElement(Text, null, sales_order.internal_notes)),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Carrier:"),
                            " \u00A0",
                            editableCarrier ? (React.createElement(React.Fragment, null,
                                React.createElement(ShippingCarriers, { style: { width: '300px' }, defaultValue: sales_order.carrier, onChange: carrier => {
                                        const payload = {
                                            carrier: carrier,
                                        };
                                        handlePatch(payload);
                                        setEditableCarrier(false);
                                    } }),
                                "\u00A0",
                                React.createElement("a", { onClick: () => setEditableCarrier(false) },
                                    React.createElement(CloseCircleOutlined, null)))) : (React.createElement(React.Fragment, null,
                                React.createElement(Text, null, sales_order.carrier),
                                " \u00A0",
                                React.createElement("a", { onClick: () => setEditableCarrier(true) },
                                    React.createElement(EditOutlined, null))))),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Shipping Method:"),
                            " \u00A0",
                            editableShippingMethod ? (React.createElement(React.Fragment, null,
                                React.createElement(ShippingMethods, { style: { width: '300px' }, defaultValue: sales_order.shipping_method, onChange: method => {
                                        const payload = {
                                            shipping_method: method,
                                        };
                                        handlePatch(payload);
                                        setEditableShippingMethod(false);
                                    } }),
                                "\u00A0",
                                React.createElement("a", { onClick: () => setEditableShippingMethod(false) },
                                    React.createElement(CloseCircleOutlined, null)))) : (React.createElement(React.Fragment, null,
                                React.createElement(Text, null, sales_order.shipping_method),
                                " \u00A0",
                                React.createElement("a", { onClick: () => setEditableShippingMethod(true) },
                                    React.createElement(EditOutlined, null))))),
                        React.createElement("div", { className: 'flex flex-row p-1' },
                            React.createElement(Text, { strong: true }, "Shipping Account Number: "),
                            " \u00A0",
                            React.createElement(Paragraph, { editable: { onChange: editShipAccountNumber } }, sales_order.shipping_account_number))))),
            React.createElement("div", { className: 'w-1/3' },
                React.createElement("div", { className: 'flex justify-center p-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Grand Total:"),
                            " \u00A0 $",
                            ' ',
                            React.createElement(Price, { value: sales_order.grand_total ? sales_order.grand_total : 0 })),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Needs Jason Review:"),
                            " \u00A0",
                            React.createElement(React.Fragment, null,
                                "No",
                                ' ',
                                React.createElement(Switch, { checked: sales_order.needs_jason_review, size: 'small', onChange: value => {
                                        const payload = {
                                            needs_jason_review: value,
                                        };
                                        handlePatch(payload);
                                    } }),
                                ' ',
                                "Yes")),
                        React.createElement("div", { className: 'p-1' },
                            React.createElement(Text, { strong: true }, "Needs Production Review:"),
                            " \u00A0",
                            React.createElement(React.Fragment, null,
                                "No",
                                ' ',
                                React.createElement(Switch, { checked: sales_order.needs_production_review, size: 'small', onChange: value => {
                                        const payload = {
                                            needs_production_review: value,
                                        };
                                        handlePatch(payload);
                                    } }),
                                ' ',
                                "Yes")),
                        React.createElement(Divider, null, "Shipping Address"),
                        React.createElement("div", null,
                            React.createElement("div", { className: 'p-1' },
                                React.createElement(Text, { strong: true, className: 'mr-2' }, "Shipping Name:"),
                                React.createElement(Text, null, sales_order.shipping_name)),
                            React.createElement("div", { className: 'p-1' },
                                React.createElement(Text, { strong: true, className: 'mr-2' }, "Shipping Street:"),
                                React.createElement(Text, null, sales_order.shipping_street)),
                            React.createElement("div", { className: 'p-1' },
                                React.createElement(Text, { strong: true, className: 'mr-2' }, "Shipping Location:"),
                                React.createElement(Text, null, sales_order.shipping_city +
                                    ', ' +
                                    sales_order.shipping_state +
                                    ' ' +
                                    sales_order.shipping_zip_code +
                                    ', ' +
                                    sales_order.shipping_country)),
                            React.createElement(Addresses, { addresses: addresses, onAddNewAddress: () => setShowAddNewAddress(true), onChange: (address) => {
                                    const payload = {
                                        shipping_name: address.name,
                                        shipping_street: address.street,
                                        shipping_city: address.city,
                                        shipping_state: address.state,
                                        shipping_zip_code: address.zip_code,
                                        shipping_country: address.country?.code,
                                    };
                                    handlePatch(payload);
                                } })))))),
        React.createElement(Row, null,
            React.createElement(Col, { span: 12, className: 'p-2' },
                React.createElement(Card, { bordered: false, title: 'Add New Note', size: 'small' },
                    React.createElement(NotesForm, { handleSubmit: handleSubmit }))),
            React.createElement(Col, { span: 12, className: 'p-2' },
                React.createElement(Card, { bordered: false, title: 'Existing Notes', size: 'small' },
                    React.createElement(NotesListCard, { sales_order_notes: sales_order_notes })))),
        sales_order.contact.id && (React.createElement(Modal, { visible: showAddNewAddress, onCancel: () => {
                setShowAddNewAddress(false);
            }, footer: [] },
            React.createElement(AddressForm, { handleSave: payload => {
                    return handleSaveAddress(payload);
                }, handleClose: () => {
                    setShowAddNewAddress(false);
                } })))));
};
