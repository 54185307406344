import React from 'react';
const precision = 12;
export const PartDimensions = ({ length = 0, width = 0, height = 0, unit = 'MM' }) => {
    if (!length || !width || !height) {
        return null;
    }
    const original = `${length}${unit} x ${width}${unit} x ${height}${unit}`;
    let converted = '';
    if (unit === 'MM') {
        converted = `${mm_to_in(length)}IN x ${mm_to_in(width)}IN x ${mm_to_in(height)}IN`;
    }
    else {
        converted = `${in_to_mm(length)}MM x ${in_to_mm(width)}MM x ${in_to_mm(height)}MM`;
    }
    return (React.createElement("div", { style: {
            display: 'flex',
            fontSize: '12px',
            textTransform: 'lowercase',
            fontWeight: 100,
            flexWrap: 'wrap',
            overflowWrap: 'break-word',
        } }, original + ' | ' + converted));
};
const in_to_mm = (value_inches) => {
    if (value_inches) {
        const result = (value_inches * 25.4).toPrecision(precision);
        return normalize_number(result);
    }
    return value_inches;
};
const mm_to_in = (value_mm) => {
    if (value_mm) {
        const result = (value_mm * 0.0393701).toPrecision(precision);
        return normalize_number(result);
    }
    return value_mm;
};
const normalize_number = (value) => {
    if (value) {
        return parseFloat(value).toFixed(3);
    }
    return value;
};
