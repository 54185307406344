import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Button,
    notification,
    Popconfirm,
    Card,
    Row,
    Col,
    Form,
    InputNumber,
    Input,
    Select,
    Tag,
    Typography,
    Skeleton,
} from 'antd';

//Stores
import TemplatesStore from '../../components/Templates/Stores/TemplatesStore';

//Components
import TemplateForm from '../../components/Templates/TemplateForm';
import { BadgerPage, BadgerSearch } from '../../components/badger-ui';
import ContentType from '../../stores/ContentType';
import { GenericSelect } from '@partsbadger/library';
import { ITemplate } from '../../components/Templates/types';
import TemplateVariablesView from '../../components/Templates/VariablesView';

const RecordSelect = (props: {
    template: ITemplate;
    initialValue: {
        key: number;
        label: string;
    } | null;
    handleSelectInstance: (entity: { key: number; label: string }) => void;
}) => {
    const { template, handleSelectInstance } = props;

    return (
        <div>
            <div className={'flex flex-row justify-between'}>
                <Form.Item label="Preview" name="preview">
                    {template.model_name == 'salesorder' && (
                        <GenericSelect
                            style={{
                                width: 300,
                            }}
                            defaultValue={props.initialValue}
                            onChange={v => {
                                handleSelectInstance({
                                    key: v.key,
                                    label: v.label,
                                });
                            }}
                            recordType={'SalesOrders'}
                        />
                    )}
                </Form.Item>
            </div>
        </div>
    );
};

const PreviewTemplate = (props: { template: ITemplate; html: string }) => {
    return (
        <div>
            <Card
                title={'Preview Template'}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: props.html }} />
            </Card>
        </div>
    );
};

const TemplatesEditPage = () => {
    const { id } = useParams<{ id: string }>();

    const [islLoading, setIsLoading] = React.useState<boolean>(false);

    const [previewInstancePK, setPreviewInstancePK] = React.useState<{
        key: number;
        label: string;
    } | null>(null);

    const [previewHTML, setPreviewHTML] = React.useState('');

    const history = useHistory();

    const template = TemplatesStore.template;
    const model_variables = TemplatesStore.model_variables;
    const blocks = TemplatesStore.blocks;

    useEffect(() => {
        setIsLoading(true);
        TemplatesStore.template = null;
        TemplatesStore.getById(id).finally(() => {
            setIsLoading(false);
        });
        TemplatesStore.getBlockByTemplate(id);
        ContentType.getAll();
        return () => {};
    }, [id]);

    const handleClickDelete = () => {
        TemplatesStore.delete(id)
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Template deleted successfully',
                });
                history.push('/templates/');
            })
            .catch(e => {
                notification.error({
                    message: 'Error',
                    description: e.message ?? 'Error deleting template',
                });
            });
    };

    useEffect(() => {
        handlePreviewTemplate();
    }, [previewInstancePK]);

    const handlePreviewTemplate = async () => {
        try {
            const data = await TemplatesStore.preview(id, previewInstancePK?.key ?? null);
            setPreviewHTML(data);
        } catch (e: any) {
            notification.error({
                message: 'Error previewing template',
                description: e?.message ?? 'Error previewing template',
            });
        }
    };

    if (!template) {
        return <div>Loading...</div>;
    }

    return (
        <BadgerPage
            title={'Edit Template'}
            handleBack={() => {
                history.push('/templates/');
            }}
            breadcrumbs={[
                {
                    title: 'Home',
                    link: '/',
                },
                {
                    title: 'Templates',
                },
            ]}
            topActions={[
                <Popconfirm
                    key="delete"
                    title={'Are you sure you want to delete this template?'}
                    onConfirm={handleClickDelete}
                >
                    <Button>Delete</Button>
                </Popconfirm>,
            ]}
        >
            <Card>
                <Row>
                    <Col md={12}>
                        <TemplateForm
                            record={template}
                            handleSave={async values => {
                                const data = await TemplatesStore.update(id, values);
                                handlePreviewTemplate();
                                return data;
                            }}
                        />
                    </Col>
                    <Col md={12}>
                        <div className={'px-5'}>
                            <RecordSelect
                                template={template}
                                initialValue={previewInstancePK}
                                handleSelectInstance={value => {
                                    setPreviewInstancePK(value);
                                }}
                            />
                            <PreviewTemplate template={template} html={previewHTML} />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={14}>
                        {template?.model_name && (
                            <>
                                <Typography.Title level={2}>Variables</Typography.Title>
                                <TemplateVariablesView model_name={template?.model_name} />
                            </>
                        )}
                    </Col>
                    <Col md={8} offset={2}>
                        <Typography.Title level={2}>Blocks</Typography.Title>
                        <div className={'flex flex-col flex-wrap'}>
                            {blocks.map((block, index) => {
                                return (
                                    <div key={index}>
                                        <div className={'flex flex-row items-center'}>
                                            <Typography.Text strong>{block.name}</Typography.Text>
                                            <Typography.Text code copyable={{ text: `[block_name ${block.slug}]` }}>
                                                {`[block_name ${block.slug}]`}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            </Card>
        </BadgerPage>
    );
};
export default observer(TemplatesEditPage);
