import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Col, Input, message, notification, Row, Select } from 'antd';
import React, { useState } from 'react';
import { normalize_number, patch } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import ThumbnailFile from '../../components/MasterProduct/ThumbnailFile';
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
    },
};
const cols = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
};
const InputCalculator = (props) => {
    const [value, setValue] = useState(props.value ?? '');
    const calculate = (a, b, operation) => {
        if (a && b) {
            if (operation === '+') {
                return parseFloat(a) + parseFloat(b);
            }
            if (operation === '-') {
                return parseFloat(a) - parseFloat(b);
            }
            if (operation === '*') {
                return parseFloat(a) * parseFloat(b);
            }
        }
        message.error('Wrong input');
        return 0;
    };
    return (React.createElement(Input, { ...props, value: value, onChange: e => {
            setValue(e.target.value);
            if (props.onChange) {
                props.onChange(parseFloat(e.target.value));
            }
        }, onKeyPress: event => {
            if (event.key === 'Enter' && value) {
                let total = value;
                if (value?.includes('+')) {
                    const a = value.split('+');
                    total = calculate(a[0], a[1], '+');
                    event.preventDefault();
                }
                else if (value?.includes('-')) {
                    const a = value.split('-');
                    total = calculate(a[0], a[1], '-');
                    event.preventDefault();
                }
                else if (value?.includes('*')) {
                    const a = value.split('*');
                    total = calculate(a[0], a[1], '*');
                    event.preventDefault();
                }
                if (total > 0) {
                    setValue(total.toString());
                    if (props.onChange) {
                        props.onChange(total);
                    }
                }
                else if (isNaN(total)) {
                    setValue('0');
                    if (props.onChange) {
                        props.onChange(0);
                    }
                }
            }
        } }));
};
const MasterProductForm = (props) => {
    const { form, record = {}, handleChange, handleCreate } = props;
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const renderFile = () => {
        const url = `/3d-viewer/${record.id}/`;
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);
                if (record?.id) {
                    const payload = {
                        ...values,
                    };
                    patch('/staff/master-products/' + record.id + '/', payload)
                        .then(response => {
                        setLoading(false);
                        if (handleChange) {
                            handleChange(response.id);
                        }
                    })
                        .catch(error => {
                        notification.error({ message: React.createElement(ErrorRender, { error: error }) });
                        setLoading(false);
                    });
                }
                else {
                    const payload = {
                        master_product: {
                            ...values,
                            volume: values.volume ? values.volume : 0,
                            part_volume_percentage: values.part_volume_percentage ? values.part_volume_percentage : 0,
                        },
                        sides: values.sides,
                        taps: values.taps,
                    };
                    setLoading(false);
                    if (handleCreate) {
                        handleCreate(payload);
                    }
                }
            }
        });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit, style: {
            padding: '20px',
            borderRadius: '6px',
        } },
        React.createElement(Row, null,
            record.id && React.createElement(ThumbnailFile, { master_product: record, handleClick: renderFile }),
            React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: 'Name', ...formItemLayout }, getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input a value!' }],
                    initialValue: record.name,
                })(React.createElement(Input, null)))),
            React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: 'shape', ...formItemLayout }, getFieldDecorator('shape', {
                    rules: [{ required: true, message: 'Please select a value!' }],
                    initialValue: record.shape,
                })(React.createElement(Select, { showSearch: true },
                    React.createElement(Option, { value: "REC" }, "REC"),
                    React.createElement(Option, { value: "ROD" }, "ROD"),
                    React.createElement(Option, { value: "TUBE" }, "TUBE"))))),
            React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: 'Unit', ...formItemLayout }, getFieldDecorator('unit', {
                    rules: [{ required: true, message: 'Please select a value!' }],
                    initialValue: record.unit || 'IN',
                })(React.createElement(Select, { showSearch: true },
                    React.createElement(Option, { value: "IN" }, "IN"),
                    React.createElement(Option, { value: "MM" }, "MM"))))),
            React.createElement(React.Fragment, null,
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'Length', ...formItemLayout }, getFieldDecorator('length', {
                        rules: [{ required: true, message: 'Required!' }],
                        initialValue: normalize_number(record.length),
                    })(React.createElement(InputCalculator, null)))),
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'Width', ...formItemLayout }, getFieldDecorator('width', {
                        rules: [
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ],
                        initialValue: normalize_number(record.width),
                    })(React.createElement(InputCalculator, null)))),
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'Height', ...formItemLayout }, getFieldDecorator('height', {
                        rules: [
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ],
                        initialValue: normalize_number(record.height),
                    })(React.createElement(InputCalculator, null))))),
            getFieldValue('shape') === 'TUBE' && (React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: 'Inner Diam.', ...formItemLayout }, getFieldDecorator('inner_diameter', {
                    rules: [
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ],
                    initialValue: normalize_number(record.inner_diameter),
                })(React.createElement(InputCalculator, null))))),
            React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: 'Vol.', ...formItemLayout }, getFieldDecorator('volume', {
                    rules: [
                        {
                            required: false,
                        },
                    ],
                    initialValue: normalize_number(record.volume),
                })(React.createElement(InputCalculator, null)))),
            React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: '(%) Vol.', ...formItemLayout, help: '% of Bounding Box volume' }, getFieldDecorator('part_volume_percentage', {
                    rules: [
                        {
                            required: !getFieldValue('volume'),
                            message: 'Required!',
                        },
                    ],
                    initialValue: record.part_volume_percentage,
                })(React.createElement(InputCalculator, null)))),
            React.createElement(Col, { ...cols },
                React.createElement(Form.Item, { label: 'Itar', ...formItemLayout }, getFieldDecorator('itar', {
                    initialValue: record.itar,
                    valuePropName: 'checked',
                })(React.createElement(Checkbox, null)))),
            record?.id ? (React.createElement(React.Fragment, null,
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'Quotable', ...formItemLayout }, getFieldDecorator('quotable', {
                        valuePropName: 'checked',
                        initialValue: record.quotable,
                    })(React.createElement(Checkbox, null)))),
                !getFieldValue('quotable') && (React.createElement(Col, { ...cols, lg: 8 },
                    React.createElement(Form.Item, { label: 'Notes', ...formItemLayout, help: 'Why this product is not quotable?' }, getFieldDecorator('notes', {
                        initialValue: record.notes,
                    })(React.createElement(Input.TextArea, { rows: 2 }))))),
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'MC Master SKU', ...formItemLayout }, getFieldDecorator('mcmaster_sku', {
                        rules: [{ required: false, message: 'Required!' }],
                        initialValue: record.mcmaster_sku,
                    })(React.createElement(Input, { placeholder: "" })))),
                React.createElement(Col, { md: 24, style: { textAlign: 'center', padding: 10 } },
                    record.editable && (React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'check' }), loading: loading }, "Update")),
                    !record.editable && QuoteStore.quote?.editable && (React.createElement(React.Fragment, null,
                        React.createElement(Alert, { style: { margin: 5 }, type: 'warning', message: 'This master product is used in other quotes' }),
                        React.createElement(Button, { danger: true, type: 'primary', icon: React.createElement(LegacyIcon, { type: 'check' }), onClick: () => {
                                QuoteStore.duplicateMasterProduct(record.id);
                            }, loading: loading }, "Duplicate")))))) : (React.createElement(React.Fragment, null,
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'Taps', ...formItemLayout }, getFieldDecorator('taps', {
                        rules: [{ required: true, message: 'Required!' }],
                        initialValue: 0,
                    })(React.createElement(Input, { placeholder: "", type: 'number' })))),
                React.createElement(Col, { ...cols },
                    React.createElement(Form.Item, { label: 'Sides', ...formItemLayout }, getFieldDecorator('sides', {
                        rules: [{ required: true, message: 'Required!' }],
                    })(React.createElement(Input, { placeholder: "", type: 'number' })))),
                React.createElement(Col, { md: 24, style: { textAlign: 'center', padding: 10 } },
                    React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'check' }), loading: loading }, "Save as New")))))));
};
export default Form.create()(MasterProductForm);
