import React from 'react';
import { Table } from 'antd';
import { Price } from '@partsbadger/library';
import dayjs from 'dayjs';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
export const TableCreditMemos = (props) => {
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
        },
        {
            title: 'Date created',
            dataIndex: 'created_time',
            render: (date) => {
                return React.createElement("span", null, dayjs(date).format('LLLL'));
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type, record) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", null, type),
                    type === 'Vendor' && record.vendor && (React.createElement(React.Fragment, null,
                        React.createElement("br", null),
                        React.createElement("span", null,
                            React.createElement("b", null, "Awarded Vendor:"),
                            " ",
                            record.vendor.name)))));
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount) => {
                return (React.createElement(React.Fragment, null,
                    "$ ",
                    React.createElement(Price, { value: amount })));
            },
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
        },
        {
            title: 'View in Zoho',
            dataIndex: 'zoho_id',
            render: (_, record) => {
                return (React.createElement(React.Fragment, null, record.zoho_id !== null && (React.createElement("a", { style: {
                        fontSize: 12,
                        marginLeft: 4,
                    }, target: '_blank', rel: "noreferrer", href: `${ZOHO_BASE_URL}/CustomModule14/${record.zoho_id}` }, "View In Zoho"))));
            },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { rowKey: 'id', pagination: false, dataSource: props.credit_memos, columns: columns })));
};
