import { ConfigProvider, DatePicker as ANTDatePicket } from 'antd';
import React from 'react';
import moment from 'moment';
const DateRangePicker = (props) => {
    return (React.createElement(ConfigProvider, null,
        React.createElement(ANTDatePicket.RangePicker, { ranges: {
                Today: [moment(), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                    moment().startOf('week').subtract(1, 'week'),
                    moment().endOf('week').subtract(1, 'week'),
                ],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                    moment().startOf('month').subtract(1, 'month'),
                    moment().endOf('month').subtract(1, 'month'),
                ],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Last Year': [
                    moment().startOf('year').subtract(1, 'year'),
                    moment().endOf('year').subtract(1, 'year'),
                ],
            }, ...props })));
};
export default DateRangePicker;
