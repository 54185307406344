/**
 * Format Line Item to local
 * @param record from APi
 * @returns {{[p: string]: *}}
 */
export const normalize_data = function (record) {
    return {
        product__material: record.product?.material?.id
            ? {
                key: record.product.material.id,
                label: record.product.material.name,
                manufacturing_types: record.product.material.manufacturing_types,
            }
            : {},
        // 'product__taps': record.product.taps,
        product__sides: record.product.sides,
        product__design_time: record.product.design_time,
        product__in_house_manufacturing: record.product.product__in_house_manufacturing,
        product__coatings: record.product?.coatings?.map((r) => {
            return {
                key: r.id,
                label: r.name,
            };
        })?.[0],
        product__finishes: record.product?.finishes?.map((r) => {
            const finish_name = r?.metric_name ? `${r.name} / ${r.metric_name}` : r.name;
            return {
                key: r.id,
                label: finish_name,
            };
        })?.[0],
        product__part_tolerance: {
            key: record.product?.part_tolerance?.id,
            label: `${record.product?.part_tolerance?.name} (${record.product?.part_tolerance?.value_in_mm}mm)`,
        },
        product__hole_tolerance: {
            key: record.product?.hole_tolerance?.id,
            label: `${record.product?.hole_tolerance?.name} (${record.product?.hole_tolerance?.value_in_mm}mm)`,
        },
        ...record,
    };
};
const get_data_product = function (record) {
    return {
        sides: record.product__sides,
        design_time: record.product__design_time,
        in_house_manufacturing: record.product__in_house_manufacturing === true,
        material: record.product__material?.key,
        part_tolerance: record.product__part_tolerance?.key,
        hole_tolerance: record.product__hole_tolerance?.key,
        coatings: record.product__coatings?.key ? [record.product__coatings?.key] : [],
        finishes: record.product__finishes?.key ? [record.product__finishes?.key] : [],
    };
};
export const denormalize_data = function (record) {
    const data = {
        ...record,
    };
    if (record.product) {
        data.product = get_data_product(record);
    }
    return data;
};
export const denormalize_data_product = function (record) {
    return {
        ...record,
        product: get_data_product(record),
    };
};
