import React from 'react';

interface TrackingLinkProps {
    carrier: string;
    trackingCode: string | number;
}

const TrackingLink: React.FC<TrackingLinkProps> = ({ carrier, trackingCode }) => {
    const getTrackingUrl = (carrier: string, trackingCode: string | number): string | null => {
        switch (carrier.toLowerCase()) {
            case 'dhl':
                return `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingCode}`;
            case 'fedex':
                return `https://www.fedex.com/fedextrack/?trknbr=${trackingCode}`;
            case 'ups':
                return `https://www.ups.com/track?loc=en_US&tracknum=${trackingCode}`;
            case 'usps':
                return `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingCode}`;
            default:
                return null; // Add more carriers here as needed
        }
    };

    const trackingUrl = getTrackingUrl(carrier, trackingCode);

    if (!trackingUrl) {
        return (
            <span>
                Unsupported carrier {carrier} with tracking code {trackingCode}
            </span>
        );
    }

    return (
        <div style={{ marginBottom: '10px' }}>
            <span style={{ marginRight: '5px' }}>Tracking Code:</span>
            <strong>{trackingCode}</strong>
            <br />
            <a
                title={`Track with ${carrier.toUpperCase()}`}
                target="_blank"
                rel="noreferrer"
                href={trackingUrl}
                style={{ marginTop: '5px', display: 'inline-block' }}
            >
                {carrier.toUpperCase()} Tracking Link
            </a>
        </div>
    );
};

export default TrackingLink;
