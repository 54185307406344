import React, { useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button, Table } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { OrderPreview } from './IntakeOuttake';
import PurchaseOrderStore from '../Stores/PurchaseOrderStore';
export const PreviousIntakePurchaseOrder = observer(({ dataSource }) => {
    const [ButtonPrintOut, setButtonPrintOut] = useState(false);
    const templateName = 'reports/purchase_order_intake_printout.html';
    const columns = [
        {
            title: 'Created',
            dataIndex: 'created_time',
            render: (text, record) => (React.createElement("span", null, moment(record.created_time).format('MMM Do hh:mm a'))),
        },
        {
            title: '',
            render: (_, record) => (React.createElement(Button, { onClick: () => {
                    typeof record.purchase_order_item === 'object' &&
                        PurchaseOrderStore.deleteIntake(record.id, record.purchase_order_item.purchase_order);
                }, danger: true, type: "primary" }, "Delete")),
        },
        {
            title: '',
            render: (text, intake) => (React.createElement(Button, { type: "primary", onClick: () => {
                    PurchaseOrderStore.printPurchaseOrderIntake(intake.id);
                } }, "Print")),
        },
        {
            title: '',
            render: (text, intake) => (React.createElement(Button, { onClick: () => PurchaseOrderStore.setActivePreview(`/staff/purchase-orders-intakes/${intake.id}/html/?template=${templateName}`) }, "View Printout")),
        },
        {
            title: '',
            render: (text, intake) => (React.createElement(Button, { loading: ButtonPrintOut, onClick: async () => {
                    setButtonPrintOut(true);
                    await IntakeStore.downloadPdf(`/staff/purchase-orders-intakes/${intake.id}/pdf/?template=${templateName}`, 'PrintOut.pdf').finally(() => setButtonPrintOut(false));
                } }, "Download PDF")),
        },
        {
            title: 'Z Process?',
            render: (text, intake) => React.createElement("div", null, intake.z_process ? 'Yes' : 'No'),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-around" },
            React.createElement("div", { className: "bg-white" }, dataSource && React.createElement(Table, { rowKey: "id", columns: columns, dataSource: dataSource, pagination: false })),
            PurchaseOrderStore.activePreview && React.createElement(OrderPreview, { html: PurchaseOrderStore.activePreview }))));
});
