import * as React from 'react';
import { Select } from 'antd';
export const InputQuantities = (props) => {
    return (React.createElement(Select, { mode: "tags", placeholder: '1,10,100,1000', tokenSeparators: [','], onInputKeyDown: evt => {
            const charCode = evt.keyCode;
            if (charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                (charCode < 96 || charCode > 105) &&
                charCode !== 188) {
                evt.preventDefault();
            }
        }, notFoundContent: 'Type a number', ...props }));
};
