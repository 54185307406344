import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import QuoteStore from '../stores/QuoteStore';
import { Price } from '@partsbadger/library';
export const BulkPrices = ({ item, handleSelect }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const columns = [
        {
            title: 'Lead Time',
            dataIndex: 'lead_time',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            align: 'center',
            className: 'ten-units',
            render: (t, r) => {
                return React.createElement(Price, { value: r.unit_price });
            },
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            align: 'center',
            className: 'one-unit',
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            align: 'center',
            className: 'twentyfive-units',
            render: (t, r) => {
                return React.createElement(Price, { value: r.total_price });
            },
        },
        {
            title: 'Actions',
            align: 'center',
            render: (t, r) => {
                return (React.createElement("span", { className: 'link', onClick: () => {
                        handleSelect(r.quantity);
                    } }, "Select"));
            },
        },
    ];
    useEffect(() => {
        setLoading(true);
        QuoteStore.getBulkPrices(item.id, [1, 25, 50, 100])
            .then(data => setData(data))
            .finally(() => setLoading(false));
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Your per unit price:"),
        React.createElement(Spin, { spinning: loading },
            React.createElement(Table, { rowKey: 'quantity', bordered: true, columns: columns, dataSource: data, size: "middle", pagination: { position: undefined } }))));
};
