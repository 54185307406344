import React, { Fragment, useEffect, useState } from 'react';
import { BgColorsOutlined, DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Input, Layout, Menu } from 'antd';
import MenuBar from './Menu';
import { useHistory } from 'react-router';

import { INotification } from '../../utils/types';
import { hasPermission } from './User';
import Notifications from './Notifications';
import { NotificationStore } from '../stores';

import { version } from '../../package.json';

import Logo from './Layouts/Logo';

interface Props {
    user: any;
    handleLogout: () => void;
}

const DARK_MODE = 'dark-mode';

const Header: React.FC<Props> = ({ user, handleLogout }) => {
    const [data, setData] = useState<INotification[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    const username = user.fullname;
    const auth = !!user?.id;
    const darkMode = window.localStorage.getItem(DARK_MODE) == 'yes';

    const handleSwitchTheme = () => {
        window.localStorage.setItem(DARK_MODE, darkMode ? 'no' : 'yes');
        window.location.reload();
    };

    const menu = (
        <Menu style={{ width: '200px' }}>
            <Menu.Item key="theme-switcher" onClick={handleSwitchTheme}>
                <BgColorsOutlined /> Switch to {darkMode ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item>
            <Menu.Divider />
            {auth && (
                <Menu.Item key="logout" onClick={handleLogout}>
                    <LogoutOutlined /> <span>Log Out</span>
                </Menu.Item>
            )}

            <div className="text-center mt-4">
                <small className="text-muted">v{version}</small>
            </div>
        </Menu>
    );

    useEffect(() => {
        async function getNotifications() {
            setLoading(true);

            try {
                const response = await NotificationStore.getNotifications();
                setData(response);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }

        getNotifications();
    }, []);

    async function makeRead(id: number) {
        const response = await NotificationStore.makeNotificationRead(id);

        setData(
            data.map(item => {
                if (item.id === response.id) {
                    return { ...item, read: true };
                }
                return item;
            })
        );
    }

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxHeight: '65px',
                    // background: 'red',
                }}
                className="top-menu flex flow align-items-center justify-between w-full"
            >
                <div
                    className={'w-2/3 hidden md:flex'}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Logo
                        className="row-logo menu-header w-1/6"
                        style={{
                            maxWidth: '150px',
                        }}
                    />
                    <div
                        className={'menu-header w-5/6'}
                        style={{
                            width: '100%',
                        }}
                    >
                        <MenuBar user={user} mode={'horizontal'} />
                    </div>
                </div>

                <div
                    className="flex flex-row items-center  w-full lg:w-1/3 ml-2"
                    style={
                        {
                            // background: 'beige',
                        }
                    }
                >
                    {/*{hasPermission('quotes.add_quote') && (*/}
                    {/*    <div className={'hidden xxl:inline'}>*/}
                    {/*        <Button*/}
                    {/*            className={'step-one-true-form'}*/}
                    {/*            onClick={() => {*/}
                    {/*                history.push('/get-quote');*/}
                    {/*            }}*/}
                    {/*            style={{*/}
                    {/*                color: '#fff',*/}
                    {/*                backgroundColor: '#d92923',*/}
                    {/*                borderColor: '#d92923',*/}
                    {/*                marginLeft: 'auto',*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            One True Form*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <Input.Search
                        placeholder={'Search'}
                        style={{ width: '100%' }}
                        onSearch={v => {
                            history.push(`/search?query=${v}`);
                        }}
                    />

                    <div className={'flex flex-row items-center'}>
                        <Notifications data={data} loading={loading} makeRead={makeRead} />
                    </div>

                    <div style={{ padding: ' 0 10px' }}>
                        <Avatar icon={<UserOutlined />} />
                    </div>

                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button type="link" className="ml-0 pl-0" style={{ color: '#737373' }}>
                            {username} <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
