import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { get } from '../utils/api';
import _ from 'lodash';
export const AutoCompletedRecord = ({ handleSelect, isVendorNumber }) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [collapsed, setCollapsed] = useState(true);
    const debouncedSearch = useCallback(_.debounce(search => {
        getOrders({
            search: search,
        });
    }, 400), [isVendorNumber]);
    useEffect(() => {
        setOrders([]);
    }, [isVendorNumber]);
    const getEndpoint = () => {
        switch (isVendorNumber) {
            case 'barcode-vendor':
            case 'Vendor box':
                return '/staff/sales-orders/';
            case 'rma':
            case 'RMA':
                return '/staff/inventory/';
            case 'purchase-orders':
            case 'Purchase Orders':
                return '/staff/purchase-orders/';
            default:
                return '/staff/sales-orders/';
        }
    };
    const getOrders = (params = {}) => {
        setLoading(true);
        const endpoint = getEndpoint();
        get(`${endpoint}?limit=5&offset=0`, params)
            .then(response => {
            setOrders(response.data.results);
            setLoading(false);
        })
            .catch(err => {
            console.error(err);
            setLoading(false);
        });
    };
    return (React.createElement(Select, { showSearch: true, defaultActiveFirstOption: true, onFocus: () => setCollapsed(false), onBlur: () => setCollapsed(true), filterOption: false, style: { width: '100%', margin: '1rem auto' }, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, showArrow: false, placeholder: React.createElement("span", null,
            React.createElement(SearchOutlined, null),
            "Search record"), allowClear: true, labelInValue: true, onSearch: name => {
            if (name.length > 2) {
                setLoading(true);
                setOrders([]);
                debouncedSearch(name);
            }
        }, onSelect: (pk, option) => {
            setOrders([]);
            handleSelect(pk);
        } }, orders.map(sales_order => {
        return React.createElement(Select.Option, { key: sales_order.id }, sales_order.name);
    })));
};
