import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Affix, Alert, Button, Drawer, Modal, notification, Row, Spin } from 'antd';
import { AccountInfo, ApplicationCostItemsReorder, QuoteActions, QuoteHeader, QuoteNotifications, QuotePreview, QuoteStatus, QuoteToolBar, } from './components';
import { ReorderMasterProducts } from './components/MasterProduct/index';
import { AccountStore, QuoteStore } from './Stores';
import { ErrorRender, GenericSelect, DeletionAlert } from '@partsbadger/library';
import { LineItemsBulkEditForm, QuoteEditForm } from './Forms/index';
import { ReArrangeFiles } from './components/LineItems/ReArrangeFiles';
import { useQueryParameters } from '@partsbadger/utils';
import { observer } from 'mobx-react';
import LayoutV2 from './Layouts/LayoutV2';
import LayoutV1 from './Layouts/LayoutV1';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
function get_shortcut(e) {
    return e.ctrlKey && e.shiftKey && e.altKey;
}
const QuoteTool = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isExpired, setIsExpired] = useState(false);
    const [reArrangeFiles, setReArrangeFiles] = useState(false);
    const [previewQuote, setPreviewQuote] = useState(false);
    const [showBulkEdit, setShowBulkEdit] = useState(false);
    const [selectedLineItems, setSelectedLineItems] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showQuoteNotificationsDrawer, SetShowQuoteNotificationsDrawer] = useState(false);
    const [reordering, setReordering] = useState(false);
    const [reordering_app_cost, setReorderingAppCost] = useState(false);
    const [account, setAccount] = useState();
    const searchParams = useQueryParameters();
    const v = searchParams.get('version') === '2' ? 2 : 1;
    const [version, setVersion] = useState(v);
    const [additional_requirements, setAdditionalRequirements] = useState([]);
    const params = useParams();
    const history = useHistory();
    const { id } = params;
    const quote = QuoteStore.quote;
    const loadAllData = async (id) => {
        await QuoteStore.getQuote(id);
        await QuoteStore.getMasterProducts(id);
        await QuoteStore.getLineItems(id);
        setIsLoading(false);
        await QuoteStore.getQuoteAccountOverview(id);
    };
    const getStaffUsers = async () => {
        await QuoteStore.getUsers({
            is_staff: true,
        });
    };
    useEffect(() => {
        getStaffUsers();
    }, []);
    useEffect(() => {
        document.addEventListener('dragover', function (e) {
            e.preventDefault();
        }, false);
        document.addEventListener('drop', function (e) {
            e.preventDefault();
        }, false);
        document.addEventListener('keydown', event => {
            const shortcut = get_shortcut(event);
            if (shortcut && event.code === 'KeyA') {
                setSelectedLineItems(QuoteStore.lineItems.map(p => p.id));
                event.preventDefault();
            }
            if (shortcut && event.code === 'KeyR') {
                if (selectedLineItems.length) {
                    const payload = {
                        quoted_products: selectedLineItems,
                    };
                    QuoteStore.recalculatePrices(payload).catch(err => {
                        notification.error({
                            key: 'form-errors',
                            duration: 8,
                            message: 'One of the line items has an error',
                            description: React.createElement(ErrorRender, { error: err }),
                            placement: 'top',
                            maxCount: 1,
                        });
                    });
                }
            }
            if (shortcut && event.code === 'KeyE') {
                if (selectedLineItems.length) {
                    setShowBulkEdit(true);
                }
                event.preventDefault();
            }
            if (event.code === 'Escape') {
                setSelectedLineItems([]);
                event.preventDefault();
            }
        });
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        // Clean up QuoteStore and AccountStore
        const cleanup = () => {
            QuoteStore.clean();
            AccountStore.clean();
        };
        // Load data from QuoteStore
        const loadData = async () => {
            try {
                const [finishes, coatings, additionalRequirements, materials] = await Promise.all([
                    QuoteStore.getFinishes(),
                    QuoteStore.getCoatings(),
                    QuoteStore.getAdditionalRequirements(),
                    QuoteStore.getMaterials(),
                ]);
            }
            catch (err) {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Failed to Retrieve Data',
                    description: React.createElement(ErrorRender, { error: err }),
                    placement: 'top',
                    maxCount: 1,
                });
            }
        };
        // Trigger cleanup and data loading
        cleanup();
        loadData();
        // Return the cleanup function for the dependency array
        return cleanup;
    }, []);
    useEffect(() => {
        QuoteStore.setQuote();
        QuoteStore.setCurrentUser({
            id: props.user.id,
            email: props.user.email,
            is_tester: props.user.is_tester,
            group: props.user.group,
        });
        if (id) {
            loadAllData(id);
        }
    }, [id]);
    /**
     * Save in local storage submitted requirements
     */
    useEffect(() => {
        if (quote?.custom_requirements) {
            //@ts-ignore
            setAdditionalRequirements(quote.custom_requirements);
        }
    }, [QuoteStore?.quote?.custom_requirements]);
    /**
     * Check if quote has a Design Engineer assigned
     */
    useEffect(() => {
        if (!quote?.id) {
            return; // Exit when useEffect if quote?.id es undefined
        }
        if (quote?.id) {
            QuoteStore.getNotifications();
            QuoteStore.getRFQ(quote?.rfq?.id);
        }
        if (quote?.id && !quote?.design_engineer?.id && !quote.completed) {
            Modal.confirm({
                title: 'Confirm',
                content: `Set ${props.user.email} as the Design Engineer? `,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    QuoteStore.updateQuote(quote?.id, {
                        design_engineer: props.user.id,
                    });
                },
            });
        }
    }, [quote?.id]);
    /**
     * Load Related Account
     */
    useEffect(() => {
        if (!quote?.account?.id) {
            return; // Exit when useEffect if quote?.account?.id is undefined
        }
        if (quote?.account?.id) {
            AccountStore.getAccount(quote?.account.id);
        }
    }, [quote?.account?.id]);
    useEffect(() => {
        if (quote && quote.stage == 'Completed') {
            QuoteStore.checkIfExpiredQuote(quote?.id).then(data => {
                setIsExpired(data.is_expired_price);
            });
        }
    }, [quote?.stage]);
    if (isLoading) {
        return React.createElement(Spin, { spinning: true });
    }
    if (!quote?.id) {
        return React.createElement("div", null, "Not found");
    }
    const line_items = QuoteStore.masterProductsByPosition;
    const account_detail = AccountStore.account;
    return (React.createElement(React.Fragment, null,
        React.createElement(Affix, { className: '', offsetTop: 0 },
            React.createElement(QuoteHeader, { quote: quote, user: {
                    id: props.user.id,
                    email: props.user.email,
                    display_name: props.user.display_name,
                }, isExpired: isExpired, handleShowQuoteEdit: () => setShowEditForm(true), handleQuoteToolLayoutVersionChange: version => {
                    if (version == 1) {
                        QuoteStore.setSelectedMasterProduct(null);
                    }
                    if (version == 2 && quote.stage === 'In Progress') {
                        SetShowQuoteNotificationsDrawer(true);
                    }
                    setVersion(version);
                }, account: account_detail })),
        React.createElement("div", { className: 'quote-information flex flex-col' },
            account_detail && (React.createElement(React.Fragment, null,
                React.createElement(AccountInfo, { account: account_detail }))),
            !QuoteStore.inProgress && quote.editable && (React.createElement("div", { className: 'p-5' },
                React.createElement(Alert, { message: React.createElement("div", null,
                        "You need to switch to ",
                        React.createElement("span", { className: 'font-bold' }, "In Progress"),
                        " to modify this quote",
                        React.createElement(Button, { onClick: () => {
                                const payload = {
                                    stage: 'In Progress',
                                };
                                QuoteStore.updateQuote(quote.id, payload);
                            }, className: 'ml-4', type: 'default' }, "Switch to In Progress")), type: "warning" }))),
            !quote?.design_engineer && (React.createElement(Form.Item, { label: "Quote Author", className: "text-center" },
                React.createElement(Button, { type: "link", onClick: () => {
                        QuoteStore.updateQuote(quote?.id, {
                            design_engineer: props.user.id,
                        });
                    } },
                    "Set ",
                    props.user.email,
                    " as the Design Engineer?",
                    ' '))),
            React.createElement(DeletionAlert, { accountDeleted: !quote?.account || quote?.account?.deleted, contactDeleted: !quote?.contact || quote?.contact?.deleted }),
            (!quote?.account || quote.account?.deleted) && (React.createElement("div", { className: 'flex justify-center' },
                React.createElement(Alert, { message: React.createElement("div", null,
                        ' ',
                        React.createElement(Form.Item, { label: 'Select a new Account', style: {
                                width: 200,
                            }, help: React.createElement("div", null,
                                "If you don't see the account?\u00A0",
                                React.createElement("a", { target: "_blank", href: `${ZOHO_BASE_URL}/Accounts/create`, rel: "noreferrer" }, "Create here")) },
                            React.createElement(GenericSelect, { recordType: "Account", key: 'Account', 
                                //@ts-ignore
                                onSelect: (value) => {
                                    if (value?.key) {
                                        setAccount(value);
                                        QuoteStore.updateAccountsInQuoteAndRelatedItems(quote?.id, {
                                            account: value?.key,
                                        });
                                    }
                                }, value: account }))), type: "error" }))),
            (!quote?.contact || quote.contact?.deleted) && quote.account && (React.createElement("div", { className: "text-center  flex align-center" },
                React.createElement(Form.Item, { label: `Select a new Contact of ${quote.account.name}`, key: 'Contact', style: {
                        width: 200,
                    } },
                    React.createElement(GenericSelect, { key: 'contact', filters: {
                            account: quote.account.id,
                        }, recordType: "Contact", 
                        //@ts-ignore
                        onSelect: (value) => {
                            if (value?.key) {
                                QuoteStore.updateAccountsInQuoteAndRelatedItems(quote?.id, {
                                    contact: value?.key,
                                });
                            }
                        }, value: account })))),
            reordering && React.createElement(ReorderMasterProducts, { quote_id: quote.id, handleClose: () => setReordering(false) }),
            reordering_app_cost && (React.createElement(ApplicationCostItemsReorder, { quote_id: quote.id, handleClose: () => setReorderingAppCost(false) }))),
        React.createElement(QuoteToolBar, { quote: quote, selectedLineItems: selectedLineItems, handleSelectAll: () => setSelectedLineItems(QuoteStore.lineItems.map(item => item.id)), handleSelectNone: () => setSelectedLineItems([]), handleShowBulkEdit: () => setShowBulkEdit(true), handleShowPreview: () => setPreviewQuote(true), handleShowEdit: () => setShowEditForm(true), handleShowReorder: () => setReordering(true), handleShowReArrangeFiles: setReArrangeFiles, handleShowMessages: () => {
                SetShowQuoteNotificationsDrawer(true);
            } }),
        version === 1 ? (React.createElement("div", { className: 'quote-classic-view flex flex-col  shadow' },
            React.createElement(LayoutV1, { quote: quote, line_items: line_items, account_detail: account_detail, user: {
                    id: props.user.id,
                    email: props.user.email,
                    group: props.user.group,
                }, setPreviewQuote: () => setPreviewQuote(true), setShowBulkEdit: () => setShowBulkEdit(true), selectedLineItems: selectedLineItems, setSelectedLineItems: setSelectedLineItems, additional_requirements: additional_requirements, setAdditionalRequirements: setAdditionalRequirements, setReorderingAppCost: setReorderingAppCost }))) : (React.createElement("div", { className: 'quote-one-view flex flex-col  shadow', style: {
                minHeight: '89vh',
            } },
            React.createElement("div", { className: "quote-top shadow", style: {
                    height: '97%',
                } },
                React.createElement(LayoutV2, { master_products: line_items, user: {
                        id: props.user.id,
                        email: props.user.email,
                        group: props.user.group,
                    }, rfq_id: quote?.rfq?.id, selectedLineItems: selectedLineItems, setPreviewQuote: setPreviewQuote, quote: quote, additional_requirements: additional_requirements, handleSelectLineItems: setSelectedLineItems, account: account_detail, setAdditionalRequirements: setAdditionalRequirements, setShowQuoteNotificationDrawer: () => SetShowQuoteNotificationsDrawer(true), setReorderingAppCost: setReorderingAppCost })),
            React.createElement("div", { className: "quote-bottom  flex flex-row items-center justify-center", style: {
                    height: '3%',
                } },
                React.createElement("div", { className: 'quote-status m-auto', style: {
                        width: '70%',
                        textAlign: 'center',
                    } },
                    React.createElement(QuoteStatus, { quote: quote, user: props.user, isQuoteLoading: QuoteStore.loading })),
                React.createElement("div", { className: 'quote-actions m-auto w-1/2', style: {
                        width: '30%',
                    } },
                    React.createElement(QuoteActions, { loading: QuoteStore.loading, quote: quote, handlePreviewQuote: () => setPreviewQuote(true) }))))),
        previewQuote && React.createElement(QuotePreview, { quote_id: quote.id, handleClose: () => setPreviewQuote(false) }),
        showEditForm && (React.createElement(Modal, { visible: true, title: "Edit Quote", onCancel: () => setShowEditForm(false), footer: null },
            React.createElement("div", { className: "mb-10" },
                React.createElement(QuoteEditForm, { quote: quote, handleSave: async (payload) => {
                        await QuoteStore.updateQuote(quote.id, payload);
                        await QuoteStore.getQuote(quote.id);
                        setShowEditForm(false);
                    } })))),
        showBulkEdit && (React.createElement(Modal, { visible: true, title: React.createElement("div", null,
                "Bulk Edit: Editing ",
                React.createElement("span", { className: 'font-bold text-xl' },
                    " ",
                    selectedLineItems.length,
                    " "),
                ' ',
                "lines in the quote"), onCancel: () => setShowBulkEdit(false), width: '800px', centered: true, footer: [] },
            React.createElement("div", { className: "mb-10" },
                React.createElement(LineItemsBulkEditForm, { handleSave: async (payload) => {
                        await QuoteStore.updateMultipleLineItems({
                            quoted_products: selectedLineItems,
                            data: payload,
                        });
                        setShowBulkEdit(false);
                    } })))),
        React.createElement(Drawer, { title: "Notifications", placement: "right", closable: true, width: 1200, height: 500, onClose: () => SetShowQuoteNotificationsDrawer(false), visible: showQuoteNotificationsDrawer }, showQuoteNotificationsDrawer && React.createElement(QuoteNotifications, { height: 600 })),
        React.createElement(Drawer, { title: React.createElement("b", null, "Re-arrange files, from line items"), placement: "left", closable: true, width: 1200, height: 500, onClose: () => setReArrangeFiles(false), visible: reArrangeFiles },
            React.createElement(ReArrangeFiles, { quote_id: quote.id })),
        React.createElement(Row, { className: 'errors' },
            React.createElement(ErrorRender, { error: QuoteStore.error }))));
};
export default observer(QuoteTool);
