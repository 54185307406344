import { makeAutoObservable } from 'mobx';
import { get } from '@partsbadger/utils';
class SalesOrderStore {
    constructor() {
        this.allSalesOrders = [];
        this.salesOrder = null;
        makeAutoObservable(this);
    }
    async checkIfThereIsSalesOrder(salesOrderId) {
        const getSalesOrder = this.allSalesOrders.find(item => item.zoho_id === salesOrderId);
        if (getSalesOrder) {
            return getSalesOrder;
        }
        const response = await this.getSalesOrder(salesOrderId);
        return response;
    }
    cleanSalesOrderList() {
        this.allSalesOrders = [];
    }
    async getSalesOrder(salesOrderId) {
        const response = await get(`/staff/sales-orders/${salesOrderId}`);
        this.salesOrder = response;
        this.allSalesOrders = [...this.allSalesOrders, response];
        return response;
    }
}
export default new SalesOrderStore();
