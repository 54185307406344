import React, { useCallback, useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Divider, Input, InputNumber, message, Select, Spin } from 'antd';
import { Price } from '@partsbadger/library';
import { DeleteOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import { post } from '@partsbadger/utils';
import _, { isNumber } from 'lodash';
import QuickbooksStore from '../stores/QuickbooksStore';
export const ItemsTable = observer((props) => {
    const { getFieldDecorator } = props.form;
    const [error_item, setErrorItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendingToQuickbooks, setSendingToQuickbooks] = useState(false);
    const [calculateTax, setCalculateTax] = useState(false);
    useEffect(() => {
        if (props.line_items)
            PurchaseOrderStore.sync_line_items(props.line_items);
    }, []);
    const handleAddItem = () => {
        const amount = props.form.getFieldValue('item_list_price') * props.form.getFieldValue('item_quantity');
        const total = amount + PurchaseOrderStore.input_tax;
        const name = props.form.getFieldValue('item_description');
        const quickbooks_account = props.form.getFieldValue('item_quickbook_account');
        let filteredItems = [];
        if (props.form.getFieldValue('sales_order_line_items')?.length >= 1) {
            filteredItems = PurchaseOrderStore.products_by_item
                ?.filter(filterItem => {
                return props.form.getFieldValue('sales_order_line_items').includes(filterItem.id);
            })
                ?.map(item => {
                if (props.form.getFieldValue('sales_order_line_items').includes(item.id)) {
                    return { id: item.id, name: item.name, sales_order: item.sales_order };
                }
            });
        }
        let sales_order_text = null;
        if (props.form.getFieldValue('sales_order_text')) {
            sales_order_text = props.form.getFieldValue('sales_order_text');
        }
        let product_text = null;
        if (props.form.getFieldValue('product_text')) {
            product_text = props.form.getFieldValue('product_text');
        }
        if (name && quickbooks_account) {
            setErrorItem(false);
            const new_line_item = {
                id: 0,
                sales_order_text: sales_order_text,
                sales_order_line_items: filteredItems,
                product_text: product_text,
                name: name,
                description: props.form.getFieldValue('item_description'),
                quickbooks_account: quickbooks_account.key,
                quickbooks_account_related: { id: quickbooks_account.key, name: quickbooks_account.label },
                status: props.form.getFieldValue('item_status'),
                list_price: props.form.getFieldValue('item_list_price'),
                quantity: props.form.getFieldValue('item_quantity'),
                amount: parseFloat(amount.toFixed(2)),
                discount: 0,
                tax: PurchaseOrderStore.input_tax,
                total: parseFloat(total.toFixed(2)),
            };
            PurchaseOrderStore.add_line_item(new_line_item);
            setCalculateTax(false);
            props.form.resetFields([
                'sales_order_text',
                'sales_order_by_line_item',
                'product_text',
                'sales_order_line_items',
                'item_name',
                'item_description',
                'item_quantity',
                'item_quickbook_account',
                'item_status',
                'item_list_price',
            ]);
        }
        else {
            setErrorItem(true);
            message.error('You must add a name and account');
        }
    };
    const debounceSearchAccount = useCallback(_.debounce(search => searchAccountQB(search), 600), []);
    const debounceSalesOrderItem = useCallback(_.debounce((search) => searchOrderLineItem(search), 600), []);
    const searchOrderLineItem = async (search) => {
        setLoading(true);
        await PurchaseOrderStore.getSalesOrderItems({
            search: search,
        }).finally(() => setLoading(false));
    };
    const searchAccountQB = async (value) => {
        setLoading(true);
        await QuickbooksStore.list({ search: value }).finally(() => setLoading(false));
    };
    const calculateTaxValue = (amount) => {
        const _tax = amount * 0.056;
        PurchaseOrderStore.update_input_tax(Number(_tax.toFixed(2)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null, "Line Items"),
        React.createElement("div", { className: "table w-full p-2" },
            error_item && (React.createElement("div", { className: "flex justify-center font-bold text-red-500 pb-2" },
                React.createElement("span", null, "To add an item be sure to enter the name and select an account."))),
            React.createElement("table", { className: "w-full border" },
                React.createElement("thead", null,
                    React.createElement("tr", { className: "border-b" },
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "#")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Sales Order Line Item")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Name / Description")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "List Price")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Quantity")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Amount")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Tax")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Total")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Status")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "In QB")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Account")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Actions")))),
                React.createElement("tbody", null,
                    props.create && (React.createElement("tr", { className: "text-center" },
                        React.createElement("td", { className: "p-2 border" }),
                        React.createElement("td", { className: "p-2 border", style: { maxWidth: '440px' } },
                            React.createElement(Form.Item, null, getFieldDecorator('sales_order_line_items')(React.createElement(Select, { mode: "multiple", showSearch: true, allowClear: true, 
                                // labelInValue
                                filterOption: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSalesOrderItem, onSelect: e => {
                                    const filteredProducts = PurchaseOrderStore.products_by_item.find(item => item.id == e);
                                    if (filteredProducts?.sales_order) {
                                        PurchaseOrderStore.add_sales_order_and_line_item(filteredProducts?.sales_order?.id, e, filteredProducts?.sales_order?.name);
                                    }
                                }, onDeselect: e => {
                                    PurchaseOrderStore.remove_sales_order_and_line_item(e);
                                } }, PurchaseOrderStore.products_by_item.map(item => {
                                return (React.createElement(Select.Option, { key: item.id, value: item.id },
                                    item?.sales_order?.name,
                                    " - ",
                                    item.name));
                            }))))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_description')(React.createElement(Input.TextArea, { autoSize: true, placeholder: "Description" })))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_list_price', {
                                initialValue: 0.0,
                            })(React.createElement(InputNumber, { onChange: value => {
                                    if (value != undefined && isNumber(value))
                                        PurchaseOrderStore.update_input_list_price(value);
                                } })))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_quantity', {
                                initialValue: 0.0,
                            })(React.createElement(InputNumber, { onChange: value => {
                                    if (value != undefined && isNumber(value))
                                        PurchaseOrderStore.update_input_quantity(value);
                                } })))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Price, { value: PurchaseOrderStore.input_amount })),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Price, { value: PurchaseOrderStore.input_tax }),
                            React.createElement(Checkbox, { className: 'mx-4', checked: calculateTax, onChange: event => {
                                    setCalculateTax(event.target.checked);
                                    if (event.target.checked) {
                                        calculateTaxValue(PurchaseOrderStore.input_amount);
                                    }
                                } })),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Price, { value: PurchaseOrderStore.input_total })),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_status', { initialValue: 'Open' })(React.createElement(Select, { style: { minWidth: '50px' } },
                                React.createElement(Select.Option, { value: 'Open' }, "Open"),
                                React.createElement(Select.Option, { value: 'Received in Full' }, "Received in Full"),
                                React.createElement(Select.Option, { value: 'Received Partial' }, "Received Partial"),
                                React.createElement(Select.Option, { value: 'Canceled' }, "Canceled"),
                                React.createElement(Select.Option, { value: 'Paid' }, "Paid"))))),
                        React.createElement("td", { className: "p-2 border" }),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_quickbook_account')(React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, filterOption: false, style: { width: '100%' }, dropdownMatchSelectWidth: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSearchAccount }, QuickbooksStore.quickbooks_account.map((quickbooks) => {
                                return (React.createElement(Select.Option, { key: quickbooks.id, value: quickbooks.id }, quickbooks.name));
                            }))))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Button, { type: "primary", onClick: handleAddItem }, "Add")))),
                    React.createElement(React.Fragment, null, PurchaseOrderStore.line_items.map((item, index) => {
                        return (React.createElement("tr", { className: "text-center border-b text-sm", key: index },
                            React.createElement("td", { className: "p-2 border-r" }, item.line_number),
                            React.createElement("td", { className: "p-2 border", style: { maxWidth: '440px' } },
                                React.createElement(Select, { mode: "multiple", showSearch: true, allowClear: true, labelInValue: true, filterOption: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSalesOrderItem, defaultValue: item.sales_order_line_items?.map(item => {
                                        return {
                                            key: item?.id,
                                            value: item?.id,
                                            label: `${item?.sales_order?.name} - ${item?.name}`,
                                        };
                                    }), onSelect: e => {
                                        const filteredProducts = PurchaseOrderStore.products_by_item.find(item => item.id == e.value);
                                        PurchaseOrderStore.add_sales_order_line_item(item.id, filteredProducts);
                                        if (filteredProducts?.sales_order) {
                                            PurchaseOrderStore.add_sales_order_and_line_item(filteredProducts?.sales_order?.id, e.value, filteredProducts?.sales_order?.name);
                                        }
                                    }, onDeselect: e => {
                                        PurchaseOrderStore.remove_sales_order_and_line_item(e.value);
                                        PurchaseOrderStore.remove_sales_order_line_item(item.id, e.value);
                                    } }, PurchaseOrderStore.products_by_item.map(item => {
                                    return (React.createElement(Select.Option, { key: item.id, value: item.id },
                                        item?.sales_order?.name,
                                        " - ",
                                        item.name));
                                }))),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Input, { className: 'my-1', placeholder: 'Description', value: item.description, onChange: e => {
                                        const new_item = { ...item, description: e.target.value };
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    } })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(InputNumber, { value: item.list_price, onChange: value => {
                                        if (value) {
                                            const new_item = { ...item, list_price: value };
                                            PurchaseOrderStore.update_line_item(index, new_item);
                                        }
                                    } })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(InputNumber, { value: item.quantity, onChange: value => {
                                        if (value) {
                                            const new_item = { ...item, quantity: value };
                                            PurchaseOrderStore.update_line_item(index, new_item);
                                        }
                                    } })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Price, { value: item.amount })),
                            React.createElement("td", { className: "p-2 border" },
                                React.createElement(InputNumber, { value: item.tax, onChange: value => {
                                        const new_item = { ...item, tax: value ?? 0 };
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    } })),
                            React.createElement("td", { className: "p-2 border" },
                                React.createElement(Price, { value: item.total })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Select, { value: item.status, onSelect: value => {
                                        const new_item = { ...item, status: value };
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    }, style: { minWidth: '50px' } },
                                    React.createElement(Select.Option, { value: 'Open' }, "Open"),
                                    React.createElement(Select.Option, { value: 'Received in Full' }, "Received in Full"),
                                    React.createElement(Select.Option, { value: 'Received Partial' }, "Received Partial"),
                                    React.createElement(Select.Option, { value: 'Canceled' }, "Canceled"),
                                    React.createElement(Select.Option, { value: 'Paid' }, "Paid"))),
                            React.createElement("td", { className: "p-2 border-r", style: { width: '100px' } }, item.status === 'Received in Full' && item.journal_entry?.in_quickbooks ? (React.createElement("a", { target: "_blank", href: `https://app.qbo.intuit.com/app/journal?txnId=${item.journal_entry.qb_id}`, rel: "noreferrer" }, "In QB")) : (item.status === 'Received in Full' &&
                                item.journal_entry === null && (React.createElement(Button, { className: "mx-2 link", loading: sendingToQuickbooks, onClick: () => {
                                    setSendingToQuickbooks(true);
                                    post(`/staff/purchase-orders/${item.purchase_order}/send-to-quickbooks/${item.id}/`)
                                        .then((response) => {
                                        if (typeof item.purchase_order === 'number') {
                                            PurchaseOrderStore.sync_line_items(response);
                                        }
                                        setSendingToQuickbooks(false);
                                    })
                                        .catch((error) => {
                                        console.log(error);
                                        setSendingToQuickbooks(false);
                                    });
                                } }, "Send to QB")))),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Select, { showSearch: true, allowClear: true, filterOption: false, style: { width: '100%' }, dropdownMatchSelectWidth: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSearchAccount, onSelect: value => {
                                        const new_item = { ...item, quickbooks_account: value };
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    }, defaultValue: item.quickbooks_account_related &&
                                        item.quickbooks_account_related.id },
                                    item.quickbooks_account_related && (React.createElement(Select.Option, { key: item.quickbooks_account_related.id, value: item.quickbooks_account_related.id }, item.quickbooks_account_related.name)),
                                    QuickbooksStore.quickbooks_account.map((quickbooks) => {
                                        return (React.createElement(Select.Option, { key: quickbooks.id, value: quickbooks.id }, quickbooks.name));
                                    }))),
                            React.createElement("td", null,
                                React.createElement(Button, { type: "link", onClick: () => PurchaseOrderStore.remove_line_item(index) },
                                    React.createElement(DeleteOutlined, null)))));
                    }))),
                React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 13 },
                            React.createElement(Button, { type: 'primary', style: { float: 'right', marginTop: '5px', marginBottom: '5px' }, onClick: async () => {
                                    const new_line_item = {
                                        id: 0,
                                        line_number: PurchaseOrderStore.line_items.length + 1,
                                        name: 'Shipping Cost',
                                        description: 'Shipping Cost',
                                        quickbooks_account: 192,
                                        quickbooks_account_related: {
                                            id: 192,
                                            name: 'Shipping and delivery expense',
                                        },
                                        status: 'Open',
                                        list_price: 0,
                                        quantity: 1,
                                        amount: 0,
                                        discount: 0,
                                        tax: 0,
                                        total: 0,
                                        sales_order_line_items: [], // TODO
                                    };
                                    if (props.po_id) {
                                        await PurchaseOrderStore.add_item(new_line_item, props.po_id);
                                    }
                                    else {
                                        await PurchaseOrderStore.add_line_item(new_line_item);
                                    }
                                } }, "Add Shipping Cost"))))))));
});
