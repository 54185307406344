import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { InputNumber, message, Table, Checkbox, Tag, Spin, Empty, Typography, Select } from 'antd';
import { DatePicker } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { get } from '@partsbadger/utils';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import { PartFiles } from '../../PartFiles';
import { ButtonApplyToAll, Price, VendorQualifications } from '@partsbadger/library';
import DomesticHardwareModal from './DomesticHardwareModal';
import Part from '../../Part';
import disabledDate, { subtractBusinessDays } from '../../utils/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import EditDiscountPopover from '../../components/SalesOrderEditForm/EditDiscountPopover';
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
const { Paragraph } = Typography;
const OrderLineItems = (props) => {
    const [dataVendorQualifications, setDataVendorQualifications] = useState([]);
    const [dataProductTickets, setDataProductTickets] = useState([]);
    const [loadingQualifications, setLoadingQualifications] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        vendorQualifications();
        vendorProductTickets();
    }, []);
    const vendorQualifications = () => {
        const sales_order_id = SalesOrderStore.order ? SalesOrderStore.order.id : 0;
        setLoadingQualifications(true);
        SalesOrderStore.getVendorQualificationsFromVendorApp(sales_order_id)
            .then(qualifications => {
            setDataVendorQualifications(qualifications);
        })
            .finally(() => {
            setLoadingQualifications(false);
        });
    };
    // get product tickets from vendor app
    const vendorProductTickets = () => {
        const sales_order_id = SalesOrderStore.order?.zoho_id ?? '0';
        SalesOrderStore.getProductTicketsFromVendorApp(sales_order_id).then(products => {
            // filter tickets production-support only
            const filter = products.map(p => {
                const resultado = {
                    ...p,
                    ticket_products: p.ticket_products.filter(element => element.message_type === 'production-support'),
                };
                return resultado;
            });
            setDataProductTickets(filter);
        });
    };
    const order_items = SalesOrderStore.order?.line_items || [];
    const [lineItem, setLineItem] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalType, setModalType] = useState('domestic');
    const ZProcessValues = lineItem => {
        if (lineItem === null)
            return [];
        if (modalType === 'domestic') {
            return lineItem.domestic_processes !== null ? lineItem.domestic_processes : [];
        }
        return lineItem.hardwares_required !== null ? lineItem.hardwares_required : [];
    };
    if (!order_items.length) {
        return null;
    }
    const onUpdateItem = _.debounce(async (payload) => {
        setLoading(true);
        await SalesOrderStore.updateRemoteOrderItem(payload)
            .then(() => {
            setLoading(false);
        })
            .catch(err => {
            setLoading(false);
        });
    }, 500);
    const onUpdateMultipleItem = async (data) => {
        const _order_items = order_items.map(item => {
            return {
                ...item,
                ...data,
            };
        });
        await SalesOrderStore.updateRemoteMultipleItems(_order_items);
    };
    const columns = [
        {
            title: 'Preview',
            dataIndex: 'image',
            width: 200,
            render: (t, record) => {
                const image = record.production_3d_file?.image;
                return React.createElement("img", { src: image });
            },
        },
        {
            title: 'Product Details',
            dataIndex: 'name',
            render: (t, record) => {
                if (record.name === 'CNC Part') {
                    return `${record.name} ${record.product_description ?? ''}`;
                }
                const product = record.quote_has_product?.product;
                return (React.createElement("div", { className: 'flex lg:flex-row md:flex-col sm:flex-col' },
                    React.createElement(Part, { part: {
                            name: record.name,
                            dimensions: product?.dimensions,
                            material: product?.material?.name,
                            coating: product?.coating?.name,
                            finish: product?.finish?.name,
                            first_article_approval: record.first_article_approval,
                            is_batch_shipment: !!record.is_batch_shipment,
                            previously_exists: dataProductTickets.find(item => item.product_id === record.product_zid),
                            domestic_quoting: record.domestic_quoting,
                        } })));
            },
        },
        {
            title: 'Part Files',
            width: 400,
            render: (t, record) => {
                if (!record.quote_has_product) {
                    return null;
                }
                return (React.createElement("div", { className: "flex flex-col" },
                    React.createElement(PartFiles, { show_pending_quoted_files: props.show_pending_files, product: record.quote_has_product.product, record: record, action: 'edit' }),
                    React.createElement("strong", { className: "ml-1" }, "Internal Files:"),
                    React.createElement(Paragraph, { ellipsis: { rows: 1, expandable: true }, style: { display: 'inline' }, className: "m-0 ml-1" }, record.quote_has_product.master_product?.internal_files?.map(file => {
                        return (React.createElement("div", { key: 'main' },
                            React.createElement("div", { key: file.id, className: "link inline-block", 
                                // style={{ display: 'inline' }}
                                onClick: e => {
                                    e.stopPropagation();
                                    get(`/staff/files/${file.id}/internal-file-url/`).then((response) => {
                                        window.open(response.url, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800');
                                    });
                                } },
                                file.filename,
                                "\u00A0")));
                    }))));
            },
        },
        {
            title: 'PartsBadger Production',
            dataIndex: 'partsbadger_production_method',
            width: 150,
            render: (t, record) => {
                if (!record.quote_has_product) {
                    return null;
                }
                if (SalesOrderStore.order?.status !== 'Ready for Production') {
                    return (React.createElement(Select, { value: record.partsbadger_production_method || 'No', className: 'mb-10', onSelect: (value) => {
                            const payload = {
                                partsbadger_production_method: value,
                                partsbadger_production: true,
                            };
                            if (value === 'No') {
                                payload.partsbadger_production = false;
                            }
                            onUpdateItem({
                                ...record,
                                ...payload,
                            });
                        } },
                        React.createElement(Select.Option, { value: 'Fastlane' }, "Fastlane"),
                        React.createElement(Select.Option, { value: 'QuickTurn' }, "QuickTurn"),
                        React.createElement(Select.Option, { value: 'Swiss' }, "Swiss"),
                        React.createElement(Select.Option, { value: 'Traditional' }, "Traditional"),
                        React.createElement(Select.Option, { value: 'No' }, "No")));
                }
                else {
                    return record.partsbadger_production ? (React.createElement("div", null,
                        React.createElement(Tag, { className: 'mx-2', color: "blue" }, record.partsbadger_production_method))) : (React.createElement("div", null,
                        React.createElement("span", { className: 'mx-2' }, record.partsbadger_production_method)));
                }
            },
        },
        {
            title: 'Required Part Ship Date',
            dataIndex: 'required_part_ship_date',
            render: (text, record) => {
                if (record.name === 'CNC Part') {
                    return null;
                }
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(DatePicker
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    , { 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        disabledDate: disabledDate, value: record.required_part_ship_date ? dayjs(record.required_part_ship_date) : null, onChange: date => {
                            if (date) {
                                const required_part_ship_date = dayjs(date).toDate();
                                let partner_ship_date = null;
                                if (required_part_ship_date) {
                                    partner_ship_date = subtractBusinessDays(required_part_ship_date, 8);
                                }
                                onUpdateItem({
                                    ...record,
                                    required_part_ship_date: date.format('YYYY-MM-DD'),
                                    required_partner_ship_date: dayjs(partner_ship_date).format('YYYY-MM-DD'),
                                });
                            }
                        } }),
                    record.required_part_ship_date && (React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: () => {
                            let partner_ship_date = null;
                            if (record.required_part_ship_date) {
                                partner_ship_date = subtractBusinessDays(dayjs(record.required_part_ship_date).toDate(), 8);
                            }
                            return onUpdateMultipleItem({
                                required_part_ship_date: record.required_part_ship_date,
                                required_partner_ship_date: dayjs(partner_ship_date).format('YYYY-MM-DD'),
                            });
                        } }))));
            },
        },
        {
            title: 'Required Partner Ship Date',
            dataIndex: 'required_partner_ship_date',
            render: (text, record) => {
                if (record.name === 'CNC Part') {
                    return null;
                }
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(DatePicker, { value: record.required_partner_ship_date ? dayjs(record.required_partner_ship_date) : null, onChange: v => {
                            onUpdateItem({
                                ...record,
                                required_partner_ship_date: v?.format('YYYY-MM-DD'),
                            });
                        } }),
                    record.required_partner_ship_date && (React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: () => {
                            return onUpdateMultipleItem({
                                required_partner_ship_date: record.required_partner_ship_date,
                            });
                        } }))));
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 100,
            render: (t, record) => {
                return (React.createElement(InputNumber, { defaultValue: record.quantity || 0, min: 0, parser: value => (value ? parseFloat(value) : record.quantity), onChange: (v) => {
                        if (v !== undefined && v >= 0) {
                            const _total = v * record.unit_price;
                            onUpdateItem({
                                ...record,
                                quantity: v,
                                total: parseFloat(_total.toFixed(2)),
                            });
                        }
                    } }));
            },
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            width: 200,
            render: (t, record) => {
                return (React.createElement(InputNumber, { defaultValue: record.unit_price || 0, min: 0, parser: value => (value ? parseFloat(value) : record.unit_price), onChange: (v) => {
                        if (v !== undefined && v >= 0) {
                            const _total = record.quantity * v;
                            onUpdateItem({
                                ...record,
                                unit_price: v,
                                total: parseFloat(_total.toFixed(2)),
                            });
                        }
                    } }));
            },
        },
        {
            title: 'Discount Item',
            dataIndex: 'discount',
            width: 200,
            render: (t, record) => (React.createElement(Fragment, null,
                React.createElement("span", null,
                    React.createElement(Price, { value: record.discount }),
                    React.createElement(EditDiscountPopover, { loading: loading, record: record, onUpdateItem: discountValue => {
                            onUpdateItem({
                                ...record,
                                discount: discountValue,
                            });
                        } })))),
        },
        {
            title: 'Hardware required',
            dataIndex: 'hardware_required',
            render: (t, record) => {
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(Checkbox, { checked: record.hardware_required, onClick: v => {
                            setModalType('hardware');
                            const target = v.target;
                            SalesOrderStore.updateLineItemHardwareDomesticLocal(record, 'hardware', target.checked);
                            setLineItem(record);
                            onUpdateItem({
                                ...record,
                            });
                            if (record.hardware_required) {
                                setIsModalVisible(true);
                            }
                        } }),
                    React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: () => {
                            return onUpdateMultipleItem({
                                hardware_required: record.hardware_required,
                                hardwares_required: record.hardwares_required,
                            });
                        } })));
            },
        },
        {
            title: 'Domestic process needed',
            dataIndex: 'domestic_process_needed',
            render: (t, record) => {
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(Checkbox, { checked: record.domestic_process_needed, onClick: v => {
                            setModalType('domestic');
                            const target = v.target;
                            SalesOrderStore.updateLineItemHardwareDomesticLocal(record, 'domestic', target.checked);
                            setLineItem(record);
                            onUpdateItem({
                                ...record,
                            });
                            if (record.domestic_process_needed) {
                                setIsModalVisible(true);
                            }
                        } }),
                    React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: () => {
                            return onUpdateMultipleItem({
                                domestic_process_needed: record.domestic_process_needed,
                                domestic_processes: record.domestic_processes,
                            });
                        } })));
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'total',
            width: 200,
            render: (t, record) => {
                return (React.createElement(Fragment, null,
                    React.createElement("span", null,
                        React.createElement(Price, { value: record.total_after_discount }))));
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (t, record) => {
                return (React.createElement("div", { className: 'cursor-pointer', style: {
                        color: 'red',
                    }, onClick: () => {
                        SalesOrderStore.removeRemoteOrderItem(record).finally(() => {
                            message.success('Removed!');
                        });
                    } },
                    React.createElement(LegacyIcon, { type: 'close' })));
            },
        },
    ];
    return (React.createElement(Fragment, null,
        React.createElement(Table, { rowKey: 'id', className: 'variation', pagination: false, dataSource: order_items, columns: columns, defaultExpandAllRows: true, expandedRowRender: (order_item) => order_item.quote_has_product !== null && (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement("span", { className: 'text-red-600' }, "*"),
                    " Vendor Qualifications:"),
                React.createElement(Spin, { spinning: loadingQualifications }, dataVendorQualifications.length > 0 ? (React.createElement(VendorQualifications, { data: dataVendorQualifications, value: order_item.vendor_qualifications ? order_item.vendor_qualifications : [], onChange: qualifications => {
                        onUpdateItem({ ...order_item, vendor_qualifications: qualifications });
                    } })) : (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "Vendor Qualifications could not be obtained." }))),
                order_item.vendor_qualifications && order_item.vendor_qualifications.length > 0 && (React.createElement(ButtonApplyToAll, { className: 'mt-2 m-auto', handleClick: () => {
                        return onUpdateMultipleItem({
                            vendor_qualifications: order_item.vendor_qualifications,
                        });
                    } })))), scroll: { x: 1200 } }),
        React.createElement(DomesticHardwareModal, { tags: ZProcessValues(lineItem), isVisible: isModalVisible, type: modalType, handleOk: values => {
                setIsModalVisible(false);
                if (modalType === 'domestic') {
                    if (lineItem !== null) {
                        SalesOrderStore.updateRemoteOrderItemWithOutPartnerShipDate({
                            ...lineItem,
                            domestic_processes: values,
                        });
                    }
                }
                else {
                    if (lineItem !== null) {
                        SalesOrderStore.updateRemoteOrderItemWithOutPartnerShipDate({
                            ...lineItem,
                            hardwares_required: values,
                        });
                    }
                }
            }, handleCancel: () => {
                setIsModalVisible(false);
            } })));
};
export default observer(OrderLineItems);
