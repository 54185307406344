import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Table } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
import { Price } from '@partsbadger/library';
const ApplicationCostItems = (props) => {
    const { available_application_costs_items } = SalesOrderStore;
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: 100,
            render: (t, record) => {
                return (React.createElement(Fragment, null,
                    React.createElement("span", null,
                        React.createElement(Price, { value: record.price }))));
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (t, record) => {
                return (React.createElement(Button, { style: {
                        color: 'green',
                    }, onClick: () => props.handleAdd(record).then(() => {
                        message.success('Item Added!');
                    }) },
                    React.createElement(LegacyIcon, { type: 'plus' }),
                    "Add To Order"));
            },
            align: 'right',
        },
    ];
    return (React.createElement(Fragment, null,
        React.createElement(Table, { rowKey: 'id', className: 'variation', pagination: false, dataSource: available_application_costs_items, columns: columns })));
};
export default observer(ApplicationCostItems);
