import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Modal, notification, Radio, Row, Space, Table } from 'antd';
import { ReworkHeader } from './components/ReworkHeader';
import { useHistory, useParams } from 'react-router-dom';
import { get, post } from '@partsbadger/utils';
import { ICreateSalesOrderRework, IPartQualityIssueDetail, IPartQualityItem } from '@partsbadger/types';
import { ReworkTable } from './components/ReworkTable';
import { QualityIssueStore } from '../../../stores/QualityIssuesStore';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { OrderLineItemProps } from '../../../../../types';

type Params = {
    quality_issue_zoho_id: string;
};

type InvoiceStatus = 'Paid' | 'Unpaid';

type IReworkItems = {
    id: number;
    name: string;
    quantity: number;
    original_quantity?: number;
    quantity_ordered: number;
    reason: string[];
};

export const QualityIssueReworkPage = () => {
    const params = useParams<Params>();
    const { quality_issue_zoho_id } = params;

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [type, setType] = useState<string | null>(null);
    const [qualityIssue, setQualityIssue] = useState<IPartQualityIssueDetail>();

    const [showModalQualityIssueItems, setShowModalQualityIssueItems] = useState<boolean>(false);
    const [selectedQualityIssueItemsRowKeys, setSelectedQualityIssueItemsRowKeys] = useState<React.Key[]>([]);
    const [tableKey, setTableKey] = useState(0);
    const [reworkItems, setReworkItems] = useState<IReworkItems[]>([]);
    const [deletedItems, setDeletedItems] = useState<IReworkItems[]>([]);
    const [itemsWithZeroQuantity, setItemsWithZeroQuantity] = useState<number[]>([]);

    useEffect(() => {
        if (quality_issue_zoho_id) {
            getQualityIssue();
        }
    }, [quality_issue_zoho_id]);

    const handleCreateSalesOrderRework = () => {
        setShowErrors(true);
        if (!type) {
            notification.error({ message: 'Please select a type.', duration: 5 });
            return;
        }

        if (reworkItems.length <= 0) {
            notification.error({ message: 'Please add at least one item to sales order rework.', duration: 5 });
            return;
        }

        const itemsWithZeroQuantityIds = reworkItems
            .filter(item => item.quantity === null || item.quantity === 0)
            .map(item => item.id);

        if (itemsWithZeroQuantityIds.length > 0) {
            notification.error({
                message: 'One or more items have a quantity of 0.',
                duration: 5,
            });
            setItemsWithZeroQuantity(itemsWithZeroQuantityIds);
            return;
        }

        if (qualityIssue && reworkItems.length > 0) {
            const payload: ICreateSalesOrderRework = {
                type_sales_order: type === 'REDO' ? 'Redo' : 'Fixing',
                line_items: reworkItems.map((item: IReworkItems) => {
                    return {
                        id: item.id,
                        quantity: item.quantity,
                    };
                }),
            };

            const url = `/staff/quality-issues/${quality_issue_zoho_id}/create-sales-order-rework/`;
            setLoading(true);
            post(url, payload)
                .then(response => {
                    notification.success({ message: 'Sales order rework created successfully.', duration: 5 });
                    history.push(`/sales-order/${response['sales_order_id']}/`);
                })
                .catch(() => {
                    notification.error({ message: 'Error creating sales order rework.', duration: 5 });
                })
                .finally(() => setLoading(false));
        } else {
            notification.error({ message: 'Please add at least one item to sales order rework.', duration: 5 });
        }
        setShowErrors(false);
    };

    const invoiceStatusMap: Record<InvoiceStatus, boolean> = {
        Paid: false,
        Unpaid: true,
    };

    const getQualityIssue = async () => {
        const url = `/staff/quality-issues/${quality_issue_zoho_id}/`;
        setLoading(true);
        await get(url)
            .then((response: any) => {
                const part_quality_items = response['part_quality_items'];
                const sales_order_id = response['sales_order']['id'];

                setQualityIssue(response);
                const { redo_required, rma_required } = response;
                const type = determineType(redo_required, rma_required);
                setType(type);

                SalesOrderStore.getById(sales_order_id).then(sales_order => {
                    const sales_order_items = sales_order.line_items;

                    const reworkItems = part_quality_items.map((item: IPartQualityItem) => ({
                        id: item.sales_order_item.id,
                        name: item.sales_order_item.name,
                        reason: item.reason,
                        quantity: item.quantity,
                        original_quantity: item.quantity,
                        quantity_ordered: item.sales_order_item.quantity,
                        credit_memo_needed: item.credit_memo_needed,
                    }));

                    // merge rework items with sales order items, if the item is not in rework items
                    // add it to rework items
                    sales_order_items.forEach((item: IReworkItems) => {
                        const reworkItem = reworkItems.find((rework: { id: number }) => rework.id === item.id);
                        if (reworkItem) {
                            setReworkItems(prevReworkItems => {
                                const itemExists = prevReworkItems.some(rework => rework.id === reworkItem.id);
                                if (!itemExists) {
                                    return [...prevReworkItems, reworkItem];
                                }
                                return prevReworkItems;
                            });
                        } else {
                            const newItem = {
                                id: item.id,
                                name: item.name,
                                reason: [],
                                quantity: 0,
                                quantity_ordered: item.quantity,
                                original_quantity: item.original_quantity,
                            };
                            setDeletedItems(prevReworkItems => {
                                const itemExists = prevReworkItems.some(rework => rework.id === newItem.id);
                                if (!itemExists) {
                                    return [...prevReworkItems, newItem];
                                }
                                return prevReworkItems;
                            });
                        }
                    });
                });
            })
            .catch(() => {
                notification.error({ message: 'Error getting data.', duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleUpdateItem = (item_id: number, key: string, value: any) => {
        if (qualityIssue) {
            const items = reworkItems;
            const item: IReworkItems | undefined = items.find(i => i.id === item_id);
            if (item) {
                // @ts-ignore
                item[key] = value;
                setReworkItems([...items]);
            }
        }
    };

    const handleRemoveItem = (item_id: number) => {
        if (qualityIssue) {
            const items = reworkItems;
            setReworkItems(prevReworkItems => {
                const itemExists = prevReworkItems.some(rework => rework.id === item_id);
                if (itemExists) {
                    return prevReworkItems.filter(rework => rework.id !== item_id);
                }
                return prevReworkItems;
            });
            const item_deleted = items.find(i => i.id === item_id);
            if (item_deleted) {
                setDeletedItems(prevReworkItems => {
                    const itemExists = prevReworkItems.some(rework => rework.id === item_deleted.id);
                    if (!itemExists) {
                        return [
                            ...prevReworkItems,
                            {
                                id: item_deleted.id,
                                name: item_deleted.name,
                                reason: item_deleted.reason,
                                quantity_ordered: item_deleted.quantity_ordered,
                                quantity:
                                    item_deleted && item_deleted.original_quantity
                                        ? item_deleted.original_quantity
                                        : item_deleted.quantity,
                                original_quantity: item_deleted.original_quantity,
                            },
                        ];
                    }
                    return prevReworkItems;
                });
            }

            notification.success({ message: 'Item removed successfully.', duration: 2 });
        }
    };

    const determineType = (redoRequired: boolean, rmaRequired: boolean): 'REDO' | 'FIXING' | null => {
        if (redoRequired && !rmaRequired) {
            return 'REDO';
        } else if (rmaRequired && !redoRequired) {
            return 'FIXING';
        }
        return null;
    };

    const handleGoBack = () => {
        if (qualityIssue) {
            history.push(`/new-sales-order-detail/${qualityIssue.sales_order.id}`);
        }
    };

    const downloadAllFiles = async (quality_issue_id: number, sales_order_name: string) => {
        setDownloading(true);
        await QualityIssueStore.download_all_files(quality_issue_id, sales_order_name);
        setDownloading(false);
    };

    const handleAddItemsRework = () => {
        if (qualityIssue) {
            selectedQualityIssueItemsRowKeys.forEach((key: React.Key) => {
                const item = reworkItems.find(i => i.id === Number(key));
                if (item) {
                    const reworkItem: IReworkItems = {
                        id: item.id,
                        name: item.name,
                        reason: item.reason,
                        quantity: item.quantity,
                        original_quantity: item.original_quantity,
                        quantity_ordered: item.quantity,
                    };

                    setReworkItems(prevReworkItems => {
                        const itemExists = prevReworkItems.some(rework => rework.id === reworkItem.id);
                        if (!itemExists) {
                            return [...prevReworkItems, reworkItem];
                        }
                        return prevReworkItems;
                    });
                } else {
                    const item_deleted = deletedItems.find(i => i.id === Number(key));
                    if (item_deleted) {
                        setReworkItems(prevReworkItems => {
                            const itemExists = prevReworkItems.some(rework => rework.id === item_deleted.id);
                            if (!itemExists) {
                                return [
                                    ...prevReworkItems,
                                    {
                                        id: item_deleted.id,
                                        name: item_deleted.name,
                                        reason: item_deleted.reason,
                                        quantity_ordered: item_deleted.quantity_ordered,
                                        quantity: item_deleted.quantity,
                                        original_quantity: item_deleted.original_quantity,
                                    },
                                ];
                            }
                            return prevReworkItems;
                        });

                        setDeletedItems(prevReworkItems => {
                            return prevReworkItems.filter(rework => rework.id !== item_deleted.id);
                        });
                    }
                }
            });

            setDeletedItems(deletedItems.filter(item => !selectedQualityIssueItemsRowKeys.includes(String(item.id))));

            handleCloseModal();
        }
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedQualityIssueItemsRowKeys(newSelectedRowKeys);
    };

    const handleSelectQualityIssueItems = {
        selectedQualityIssueItemsRowKeys,
        onChange: onSelectChange,
    };

    const handleCloseModal = () => {
        setSelectedQualityIssueItemsRowKeys([]);
        setShowModalQualityIssueItems(false);
        setTableKey(prevKey => prevKey + 1);
    };

    return (
        <>
            <Card
                style={{
                    padding: 0,
                    border: 'none',
                }}
                bodyStyle={{
                    padding: 0,
                }}
            >
                {qualityIssue && (
                    <>
                        <ReworkHeader
                            handleGoBack={handleGoBack}
                            sales_order_name={qualityIssue.sales_order.name}
                            created_time={qualityIssue.created_time}
                            downloading={downloading}
                            full_name={qualityIssue.owner.fullname}
                            downloadAllFiles={downloadAllFiles}
                            quality_issue_id={qualityIssue.id}
                        />

                        <Row className={'p-4'} gutter={16}>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Sales Order:</div>
                                    <div>{qualityIssue.sales_order.name}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Rework Type:</div>
                                    <Radio.Group
                                        style={{ marginTop: -5 }}
                                        onChange={e => setType(e.target.value)}
                                        value={type}
                                        buttonStyle={'solid'}
                                    >
                                        <Space direction="horizontal">
                                            <Radio value="REDO">REDO</Radio>
                                            <Radio value="FIXING">FIXING</Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                                {type === null && showErrors && (
                                    <div className={'text-red-500 ml-2'}>Please select a type.</div>
                                )}
                            </Col>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Quality Issue:</div>
                                    <div>{qualityIssue.name}</div>
                                </div>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Reporting Contact Information:</div>
                                    <div>{qualityIssue.reporting_contact_information}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Fault:</div>
                                    <div>{qualityIssue.fault}</div>
                                </div>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Reason Fault:</div>
                                    <div>{qualityIssue.reason_fault}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Issues:</div>
                                    <div>
                                        {qualityIssue.issues} {qualityIssue.reason}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}

                {qualityIssue && (
                    <Row className={'p-2'}>
                        <Col span={24} className={'flex flex-row justify-end mt-4 mb-4'}>
                            <Button type={'primary'} onClick={() => setShowModalQualityIssueItems(true)}>
                                Add Sales Order Items
                            </Button>
                        </Col>
                        <Col span={24}>
                            <ReworkTable
                                handleUpdateItem={handleUpdateItem}
                                handleRemove={handleRemoveItem}
                                items={reworkItems || []}
                                loading={loading}
                                itemsWithZeroQuantity={itemsWithZeroQuantity}
                            />
                        </Col>
                        <Col span={24} className={'flex flex-row justify-end mt-4'}>
                            <Button type={'primary'} onClick={handleCreateSalesOrderRework}>
                                Submit to Review
                            </Button>
                        </Col>
                    </Row>
                )}
            </Card>

            <Modal
                width={800}
                open={showModalQualityIssueItems}
                title="Please select the sales order items to add to rework"
                onOk={handleAddItemsRework}
                onCancel={handleCloseModal}
                footer={[
                    <>
                        <Button key="back" onClick={handleCloseModal}>
                            Close
                        </Button>

                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            onClick={handleAddItemsRework}
                            disabled={selectedQualityIssueItemsRowKeys.length <= 0}
                        >
                            Add to Rework
                        </Button>
                    </>,
                ]}
            >
                {qualityIssue && (
                    <Table
                        key={tableKey}
                        columns={[
                            {
                                key: 'name',
                                title: 'Part Name',
                                align: 'center',
                                render: (r: IReworkItems) => {
                                    return r.name;
                                },
                            },
                            {
                                key: 'quantity',
                                title: 'Quality Issue Quantity',
                                align: 'center',
                                render: (r: IReworkItems) => {
                                    return r.quantity === 0 ? '' : r.quantity;
                                },
                            },
                        ]}
                        dataSource={deletedItems}
                        loading={loading}
                        pagination={false}
                        rowSelection={handleSelectQualityIssueItems}
                        rowKey={(record: IReworkItems) => String(record.id)}
                    />
                )}
            </Modal>
        </>
    );
};
