import React, { useEffect, useState } from 'react';
import { Table, Empty, InputNumber, Button, Descriptions, notification, message } from 'antd';
const W_SETTINGS = 'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes';
import IntakeStore from '../../Stores/IntakeStore';
import VendorStore from '../../Stores/VendorStore';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import { observer } from 'mobx-react';
import { ErrorRender } from '@partsbadger/library';
const BarcodeScanner = ({ id }) => {
    const [reading, setReading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 40 });
    const [loading, setLoading] = useState(false);
    const handleChangeInput = (vendorProductId, quantity) => {
        VendorStore.updateTheProductFromTheList(vendorProductId, Number(quantity));
    };
    const columns = [
        {
            title: 'Box Name',
            // width: 220,
            key: 'box_name',
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.name))),
        },
        {
            title: 'Purchase Order',
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.purchase_order))),
        },
        {
            title: 'Required Ship Date',
            // width: 240,
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("span", { className: "font-bold" }, "Partner Ship Date:"),
                    React.createElement("span", null, record.partner_ship_date)),
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("span", { className: "font-bold" }, "Req. Ship Date:"),
                    React.createElement("span", null, record.required_ship_date)))),
        },
        {
            title: 'Line Item',
            // width: 220,
            // dataIndex: 'line_items__name',
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.line_items[0]?.name ?? '-'))),
        },
        {
            title: 'Awarded Vendor',
            // dataIndex: 'awardedVendor',
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.line_items[0]?.awarded_vendor ?? '-'))),
        },
        {
            title: 'Quantity Ordered',
            // dataIndex: 'salesOrderLineItemQuantity',
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.line_items[0]?.quantity ?? '-'))),
        },
        {
            title: 'Quantity Remaining',
            // dataIndex: 'quantityRemainingToReceive',
            render: record => (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.line_items[0]?.quantity_remaining_to_receive ?? '-'))),
        },
        {
            title: 'Quantity Received',
            fixed: 'right',
            render: (record) => {
                return (React.createElement(InputNumber, { min: 1, max: 9999, value: record.quantity_shipped, onChange: value => {
                        if (value) {
                            handleChangeInput(record.vendorProductZId, value);
                        }
                    } }));
            },
        },
        {
            title: 'Action',
            render: record => {
                return React.createElement("a", { onClick: () => VendorStore.RemoveProduct(record.vendorProductZId) }, "Remove");
            },
        },
    ];
    async function getTheProduct(product, salesOrderData) {
        const filteredLineItems = await salesOrderData.line_items.filter(item => item.product_zid == product.product);
        const filteredSalesOrderData = {
            ...salesOrderData,
            vendorProductId: product.id,
            vendorProductZId: product.product,
            line_items: filteredLineItems,
            quantity_shipped: product.quantity_shipped,
        };
        VendorStore.setProductData(filteredSalesOrderData);
    }
    async function checkIfThisProductExists(productData) {
        const productDuplicated = VendorStore.scannedProducts.find(item => item.product === productData.product && item.id === productData.id);
        if (productDuplicated) {
            return { status: 'Duplicated', product: productDuplicated };
        }
        const productExistsButWithDifferentProductId = VendorStore.scannedProducts.find(item => item.product === productData.product && item.id !== productData.id);
        if (productExistsButWithDifferentProductId) {
            const quantity = productData.quantity_shipped + productExistsButWithDifferentProductId.quantity_shipped;
            VendorStore.updateTheProductFromTheList(productData.product, quantity);
            VendorStore.setScannedProducts(productData);
            return { status: 'ProductToSum' };
        }
        VendorStore.setScannedProducts(productData);
        return { status: 'Not Found' };
    }
    const processBarcodeStartingWith1 = async (barcode) => {
        try {
            const removeFirstDigit = barcode.slice(1);
            const shipmentItem = await VendorStore.getShipmentItemInfoFromVendorApp(removeFirstDigit)
                .then(res => {
                return res;
            })
                .catch(err => {
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: React.createElement(ErrorRender, { error: err }),
                    placement: 'topRight',
                });
            });
            const doesThisProductExists = await checkIfThisProductExists(shipmentItem);
            if (doesThisProductExists?.status === 'Not Found') {
                const salesOrderData = await SalesOrderStore.checkIfThereIsSalesOrder(shipmentItem.sales_order);
                return getTheProduct(shipmentItem, salesOrderData);
            }
            if (doesThisProductExists?.status === 'Duplicated') {
                throw new Error('This product has already been added to your list.');
            }
        }
        catch (error) {
            notification.error({
                duration: 10,
                message: 'Error',
                description: React.createElement(ErrorRender, { error: error }),
                placement: 'topRight',
            });
        }
    };
    const processBarcodeStartingWith0 = async (barcode) => {
        try {
            const intakeRep = await VendorStore.getShipmentInfoFromVendorApp(barcode)
                .then(res => {
                return res;
            })
                .catch(err => {
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: React.createElement(ErrorRender, { error: err }),
                    placement: 'topRight',
                });
            });
            const groupedSalesOrderItems = intakeRep?.product_outtakes.reduce((result, item) => {
                if (!result[item.sales_order]) {
                    result[item.sales_order] = [];
                }
                result[item.sales_order].push(item);
                return result;
            }, {});
            if (groupedSalesOrderItems) {
                const salesOrders = Object.keys(groupedSalesOrderItems);
                for (const salesOrder of salesOrders) {
                    const salesOrderData = await SalesOrderStore.checkIfThereIsSalesOrder(salesOrder);
                    const products = groupedSalesOrderItems[salesOrder];
                    let showNotificationError = false;
                    for (const product_item of products) {
                        const doesThisProductExists = await checkIfThisProductExists(product_item);
                        if (doesThisProductExists?.status === 'Not Found') {
                            await getTheProduct(product_item, salesOrderData);
                        }
                        if (doesThisProductExists?.status === 'Duplicated') {
                            showNotificationError = true;
                        }
                    }
                    if (showNotificationError) {
                        throw new Error('Some products were not added because they are already in your list.');
                    }
                }
            }
        }
        catch (error) {
            notification.error({
                duration: 10,
                message: 'Error',
                description: React.createElement(ErrorRender, { error: error }),
                placement: 'topRight',
            });
        }
    };
    const fetchBarcode = async (fullBarcode) => {
        const firstDigit = fullBarcode.charAt(0);
        setLoadingData(true);
        if (fullBarcode.length < 12) {
            notification.error({
                duration: 10,
                message: 'Error',
                description: 'Barcode does not have 12 digits.',
                placement: 'topRight',
            });
            return;
        }
        if (firstDigit === '1') {
            await processBarcodeStartingWith1(fullBarcode);
        }
        else {
            await processBarcodeStartingWith0(fullBarcode);
        }
        setLoadingData(false);
    };
    let barcode = '';
    function handleBarcodeScanner(e) {
        if (e.key == 'Enter') {
            fetchBarcode(barcode);
            barcode = '';
        }
        else if (e.key !== 'Shift') {
            barcode += e.key;
        }
        if (!reading) {
            setReading(true);
            setTimeout(() => {
                barcode = '';
                setReading(false);
            }, 200);
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', handleBarcodeScanner, false);
        return () => {
            VendorStore.cleanProductData();
            VendorStore.cleanScannedProducts();
            SalesOrderStore.cleanSalesOrderList();
            document.removeEventListener('keydown', handleBarcodeScanner, false);
        };
    }, []);
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems(selectedRows);
        },
        getCheckboxProps: record => ({
            // id: record.vendorProductId,
            disabled: record.line_items[0]?.quantity_remaining_to_receive === 0,
        }),
    };
    const handleSubmit = async () => {
        setLoading(true);
        const payload = selectedItems.map(item => {
            return {
                id: item.id,
                // vendorProductId: item.vendorProductId,
                line_items: [
                    {
                        id: item.line_items[0]?.id,
                        quantity: item.quantity_shipped,
                    },
                ],
            };
        });
        await IntakeStore.multipleOrdersIntake(payload, true)
            .then(res => {
            selectedItems.forEach(item => {
                VendorStore.RemoveProduct(item.vendorProductZId);
            });
            SalesOrderStore.cleanSalesOrderList();
            message.success(`This product has been sucessfully intaked`);
            setLoading(false);
        })
            .catch(err => {
            notification.error({
                duration: 10,
                message: 'Error',
                description: React.createElement(ErrorRender, { error: err }),
                placement: 'topRight',
            });
            setLoading(false);
        });
        setSelectedItems([]);
    };
    const emptyTable = () => {
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement("span", { style: { color: '#000' } }, "The table is currently empty. Please, scan the barcode or manually enter the sales order name.") }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-end mt-5 mb-5" },
            React.createElement(Button, { className: "font-bold", type: "primary", disabled: selectedItems.length === 0 ? true : false, onClick: () => handleSubmit(), loading: loading }, "Submit and Print")),
        React.createElement(Table, { rowSelection: {
                type: 'checkbox',
                ...rowSelection,
            }, 
            // className="records-list"
            columns: columns, rowKey: (record) => (record.vendorProductZId ? record.vendorProductZId : record.id), loading: loadingData, dataSource: VendorStore.productsData, locale: { emptyText: emptyTable }, pagination: pagination, scroll: window.innerWidth <= 1160 ? { x: 1160 } : { x: true }, 
            // tableLayout="fixed"
            expandable: {
                expandedRowRender: record => (React.createElement("div", { className: "flex flex-row justify-around" },
                    React.createElement("div", null,
                        React.createElement(Descriptions, { title: "Order Info:", column: 2, className: "descriptions-antd" },
                            React.createElement(Descriptions.Item, { label: "Status" }, record.status),
                            React.createElement(Descriptions.Item, { label: "Shipping Status" }, record.shipping_status),
                            React.createElement(Descriptions.Item, { label: "Terms", span: 2 }, record.payment_terms ? record.payment_terms : '-'),
                            React.createElement(Descriptions.Item, { label: "Customer Po Files", contentStyle: { display: 'flex', flexDirection: 'column' }, span: 2 }, record.po_files.map(({ id, filename, file }) => (React.createElement("a", { key: `pf-${id}`, onClick: () => window.open(file, filename, W_SETTINGS) }, filename)))))),
                    React.createElement("div", null,
                        React.createElement(Descriptions, { title: "Order Requirements:", column: 1, className: "descriptions-antd" },
                            React.createElement(Descriptions.Item, { label: "In House Requirements" }, record.in_house_requirements ?? '-'),
                            React.createElement(Descriptions.Item, { label: "Additional Requirements" }, record.additional_requirements?.join(', ') ?? '-'),
                            React.createElement(Descriptions.Item, { label: "Shipping Options" }, record.shipping_options ?? '-'),
                            React.createElement(Descriptions.Item, { label: "Shipping Requirements" }, record.shipping_requirements?.join(', ') ?? '-'))),
                    React.createElement("div", null,
                        React.createElement(Descriptions, { title: "Shipping Information:", column: 1, className: "descriptions-antd " },
                            React.createElement(Descriptions.Item, { label: "Shipping Info" }, record.shipping_name ?? '-'),
                            React.createElement(Descriptions.Item, { label: "Carrier" }, record.account.carrier ?? '-'),
                            React.createElement(Descriptions.Item, { label: "Shipping Method" }, record.account?.shipping_method ?? '-'),
                            React.createElement(Descriptions.Item, { label: "Shipping Account Number" }, record.account?.shipping_account_number ?? '-'))),
                    React.createElement("div", null,
                        React.createElement(Descriptions, { title: "Contact:", column: 1, className: "descriptions-antd" },
                            React.createElement(Descriptions.Item, { label: "Name" }, record.contact?.fullname),
                            React.createElement(Descriptions.Item, { label: "Email" }, record.contact?.email ?? '-'))))),
                // rowExpandable: record => record.title,
            } })));
};
export default observer(BarcodeScanner);
