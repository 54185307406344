import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Col, Row, Skeleton, Statistic } from 'antd';
import { observer } from 'mobx-react';
import { AccountStore } from '../../stores/AccountStore';
import { BadgerPage, BadgerSearch } from '../../components/badger-ui';

import { useParams } from 'react-router-dom';
import PartsByAccount from '../../components/Account/PartsByAccount';
import {
    FileTextOutlined,
    ToolOutlined,
    UserOutlined,
    PaperClipOutlined,
    DollarOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import AccountEditForm from '../../components/Account/AccountEditForm';
import AccountSalesOrders from '../../components/Account/AcccountSalesOrders';
import AccountQuotes from '../../components/Account/AcccountQuotes';
import AccountTaxExemptions from '../../components/Account/AccountTaxExemptions';
import AccountRequirements from '../../components/Account/AccountRequirements';
import ContactsByAccount from '../../components/Account/ContactsByAccount';

const AccountListPage = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [selectedSection, setSelectedSection] = useState('basicInfo');
    useEffect(() => {
        if (id) {
            AccountStore.getById(Number(id));
        }
    }, [id]);

    const { account, isLoading } = AccountStore;

    const renderSectionContent = () => {
        if (!account) {
            return <Skeleton active />;
        }
        switch (selectedSection) {
            case 'basicInfo':
                return (
                    <>
                        <Card className="site-statistic-demo-card">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Card>
                                        <Statistic
                                            title="Close Rate"
                                            value={account.close_rate ?? 0}
                                            precision={2}
                                            valueStyle={{ color: '#3f8600' }}
                                            // prefix={<ArrowUpOutlined />}
                                            prefix="%"
                                        />
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card>
                                        <Statistic
                                            title="Sales Orders"
                                            value={account.total_so ?? 0}
                                            precision={2}
                                            valueStyle={{ color: '#3f8600' }}
                                            // prefix={<ArrowUpOutlined />}
                                            prefix="$"
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Card>

                        <div
                            style={{
                                marginTop: 16,
                            }}
                        >
                            {account && <AccountEditForm account={account} />}
                        </div>
                    </>
                );
            case 'Contacts':
                return (
                    <Card>
                        <ContactsByAccount account_id={Number(id)} />
                    </Card>
                );

            case 'Parts':
                return (
                    <Card>
                        <PartsByAccount account_id={Number(id)} />
                    </Card>
                );

            case 'TaxExempt':
                return (
                    <Card>
                        <AccountTaxExemptions account={account} />
                    </Card>
                );

            case 'Requirements':
                return (
                    <Card>
                        <AccountRequirements account={account} />
                    </Card>
                );

            case 'SalesOrders':
                return (
                    <Card>
                        <AccountSalesOrders account_id={Number(id)} />
                    </Card>
                );

            case 'Quotes':
                return (
                    <Card>
                        <AccountQuotes account_id={Number(id)} />
                    </Card>
                );

            default:
                return null;
        }
    };

    return (
        <BadgerPage
            title={account?.name ?? 'Account Details'}
            isLoading={isLoading}
            handleBack={() => {
                history.push('/accounts');
            }}
            sidebarLinks={
                account
                    ? [
                          { title: 'Basic Information', link: 'basicInfo', icon: <InfoCircleOutlined /> },
                          { title: 'Contacts', link: 'Contacts', icon: <UserOutlined /> },
                          { title: 'Quotes', link: 'Quotes', icon: <PaperClipOutlined /> },
                          { title: 'Sales Orders', link: 'SalesOrders', icon: <FileTextOutlined /> },
                          { title: 'Parts', link: 'Parts', icon: <ToolOutlined /> },
                          { title: 'Requirements', link: 'Requirements', icon: <FileTextOutlined /> },
                          { title: 'Tax Exemptions', link: 'TaxExempt', icon: <DollarOutlined /> },
                      ]
                    : []
            }
            handleSidebarClick={key => setSelectedSection(key)}
            topActions={[
                <Button
                    key={'view-in-zoho'}
                    type={'primary'}
                    onClick={() => window.open(`${ZOHO_BASE_URL}/Accounts/${account?.zoho_id}`)}
                >
                    View in Zoho
                </Button>,
            ]}
            historyChanges={{ model_name: 'accounts', pk: Number(id) }}
        >
            {renderSectionContent()}
        </BadgerPage>
    );
};

export default observer(AccountListPage);
