import React, { useState } from 'react';
import { Button } from 'antd';
const ButtonApplyToAll = ({ handleClick, className = '' }) => {
    const [isUpdatingLineItem, setIsUpdatingLineItem] = useState(false);
    return (React.createElement(Button, { loading: isUpdatingLineItem, type: 'link', className: className, onClick: () => {
            setIsUpdatingLineItem(true);
            handleClick().finally(() => {
                setIsUpdatingLineItem(false);
            });
        } }, "Apply to All"));
};
export default ButtonApplyToAll;
