import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, message, Modal, Table } from 'antd';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { post } from '@partsbadger/utils';
import { QuoteStore } from '../../Stores/QuoteStore';
import SortAscendingOutlined from '@ant-design/icons/lib/icons/SortAscendingOutlined';
const type = 'DraggableBodyRow';
const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            // @ts-ignore:next-line
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (React.createElement("tr", { ref: ref, className: `${className}${isOver ? dropClassName : ''}`, style: { cursor: 'move', ...style }, ...restProps }));
};
const columns = [
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity'
    },
    // {
    //     title: 'Position',
    //     dataIndex: 'position'
    // }
];
const SortDrag = (props) => {
    useEffect(() => {
        setData(props.lineItems.sort((a, b) => a.position - b.position));
    }, [props.open]);
    const [data, setData] = useState([]);
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback((dragIndex, hoverIndex) => {
        const dragRow = data[dragIndex];
        setData(update(data, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        }));
    }, [data]);
    const sortRows = () => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.sort((a, b) => a.quantity - b.quantity);
        setData(newData);
    };
    return (React.createElement(Modal, { title: "Sort Line Items", open: props.open, onOk: () => {
            if (data) {
                const payload = data.map((item, index) => {
                    return {
                        id: item.id,
                        position: index,
                    };
                });
                post(`/staff/quotes/${props.quoteId}/quote-has-product-position/`, payload)
                    .then(() => {
                    QuoteStore.getLineItems(props.quoteId);
                    props.onCancel();
                })
                    .catch(() => {
                    message.error('Error, please try again');
                });
            }
        }, onCancel: () => {
            props.onCancel();
        } },
        React.createElement("div", { className: "text-right", style: { paddingBottom: '10px' } },
            React.createElement(Button, { onClick: () => {
                    sortRows();
                } },
                React.createElement(SortAscendingOutlined, { className: "" }))),
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Table, { columns: columns, rowKey: 'id', dataSource: data, components: components, onRow: (_, index) => {
                    const attr = {
                        index,
                        moveRow,
                    };
                    return attr;
                }, pagination: false }))));
};
export default SortDrag;
