import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { TooltipFlag } from '../TooltipFlag';
const ChangeRequiredQuantitiesForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { getFieldDecorator } = props?.form;
    const onSubmit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                setIsLoading(true);
                props.handleChangeQuantities(values.quantities).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    };
    return (React.createElement(Form, { layout: 'inline' },
        React.createElement(Form.Item, { label: 'Customer Requested Quantities' }, getFieldDecorator('quantities', {
            rules: [{ required: true, message: 'Please input one or more quantities' }],
            initialValue: props.quantities || [],
        })(React.createElement(Select, { mode: "tags", disabled: props.isReadOnly, placeholder: '1,10,100,1000', tokenSeparators: [','], onInputKeyDown: evt => {
                const charCode = evt.keyCode;
                if (charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    (charCode < 96 || charCode > 105) &&
                    charCode !== 188) {
                    evt.preventDefault();
                }
            }, notFoundContent: 'Type a number', style: { minWidth: 200 } }))),
        React.createElement(Form.Item, null,
            React.createElement(Button, { disabled: props.isReadOnly, title: "Update Quantities and generate range", loading: isLoading, type: 'link', htmlType: 'submit', onClick: onSubmit },
                "Update Quantities",
                React.createElement(SaveOutlined, null),
                React.createElement(TooltipFlag, { field_name: 'quantity', item_id: props.item_id })))));
};
export default Form.create()(ChangeRequiredQuantitiesForm);
