// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const moment = require('moment-business-days');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const Holidays = require('date-holidays');
const hd = new Holidays();
hd.init('US');
moment.updateLocale('us', {
    holidays: hd
        .getHolidays(new Date())
        .filter((h) => h.type === 'public')
        .map((h) => {
        return h.date.split(' ')[0];
    }),
    holidayFormat: 'YY-MM-DD',
    workingWeekdays: [1, 2, 3, 4, 5],
});
export const TOTAL_PRICE_SHOW_CHECKLIST = 10000;
/**
 * Add business days to existing days
 * @param start_date : Date
 * @param lead_time : number
 */
export const addBusinessDays = (start_date, lead_time) => {
    return moment(start_date).businessAdd(lead_time);
};
/**
 * Subtract business days to existing days
 * @param start_date : Date
 * @param lead_time : number
 */
export const subtractBusinessDays = (start_date, lead_time) => {
    return moment(start_date).businessSubtract(lead_time);
};
export const buildJsonBigOrderChecklist = (values, checklistQuestions) => {
    const checklist = [...checklistQuestions];
    const array = Object.entries(values);
    if (array.length > 0) {
        const filter = array.filter(vec => vec[0].startsWith('confirm-'));
        filter.map(item => {
            const question = item[0].split('confirm-')[1];
            const find = checklist.find(element => element.question === question);
            if (find) {
                find['answer'] = {
                    confirm: String(item[1]),
                    mark: find.answer?.mark,
                };
            }
        });
        const filterMark = array.filter(vec => vec[0].startsWith('mark-'));
        filterMark.map(item => {
            const question = item[0].split('mark-')[1];
            const find = checklist.find(element => element.question === question);
            if (find) {
                find.answer = {
                    confirm: find.answer?.confirm,
                    mark: !!item[1],
                };
            }
        });
        return checklist;
    }
    return [];
};
const disabledDates = [
    '2024-05-27',
    '2024-07-04',
    '2024-09-02',
    '2024-11-28',
    '2024-11-29',
    '2024-12-24',
    '2024-12-25',
    '2025-01-01',
];
export default function disabledDate(current) {
    return disabledDates.includes(current.format('YYYY-MM-DD'));
}
