import React from 'react';
import { Collapse, notification } from 'antd';
import { get } from '@partsbadger/utils';
export const RfqOverview = (props) => {
    return (React.createElement("div", { className: "p-4" },
        React.createElement(Collapse, null,
            React.createElement(Collapse.Panel, { header: React.createElement("div", null,
                    React.createElement("div", { className: "flex justify-between" },
                        React.createElement("div", { className: "font-bold" }, "RFQ Overview"))), key: "1" },
                React.createElement("table", { className: "border-collapse border border-gray-500 w-full" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Type"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.quote_type)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Submission Method"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_submission_method)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Submission Date"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_submission_date)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Quote Number"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.quote_number)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "RFQ Number"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement("div", { title: 'View RFQ', className: 'link', onClick: () => {
                                        get(`/staff/quotes/${props.quote_number}/preview-rfq/?type=html`)
                                            .then(data => {
                                            const file = new Blob([data], {
                                                type: 'text/html; charset=utf-8',
                                            });
                                            const fileURL = URL.createObjectURL(file);
                                            window.open(fileURL, '', 'width=800,height=800,left=200,top=200');
                                        })
                                            .catch((error) => notification.error(error));
                                    } }, props.rfq_number))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "RFQ Status"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_status)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Design Engineer"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.quote_design_engineer)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Custom RFQ Reference"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.quote_custom_rfq_reference)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Shipping Address"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement("address", null,
                                    props.rfq_shipping_name,
                                    " \u00A0",
                                    props.rfq_shipping_street,
                                    " \u00A0",
                                    props.rfq_shipping_state,
                                    " \u00A0",
                                    props.rfq_shipping_country,
                                    " \u00A0",
                                    props.rfq_shipping_zip_code,
                                    " \u00A0")))))))));
};
