import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, Drawer, notification, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { IPartList, PartsStore } from '../../stores/PartsStore';
import { DateRender, ErrorRender } from '@partsbadger/library';
import { post, useQueryParameters } from '@partsbadger/utils';
import {
    CalendarOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    FileOutlined,
    FilePdfOutlined,
} from '@ant-design/icons';
import { QuotePreview } from '@partsbadger/quote-tool';
import ActionMenu from '../TableActions/TableActions';
import { BadgerTable } from '../badger-ui';
import BadgerSearch from '../badger-ui/badger-search/BadgerSearch';

interface Props {
    account_id: number;
}

const Empty = () => {
    return <Typography.Text type="secondary">-</Typography.Text>;
};

const PartsByAccount = (props: Props) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedPartsMap, setSelectedPartsMap] = useState<Map<React.Key, IPartList>>(new Map());
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const searchParams = useQueryParameters();
    const [previewQuote, setPreviewQuote] = useState<number>(0);
    const [isCreatingARequote, setIsCreatingARequote] = useState<boolean>(false);

    const page = parseInt(searchParams.get('page') ?? '1');
    const search = searchParams.get('search') ?? '';

    useEffect(() => {
        PartsStore.getByAccountId(props.account_id, {
            page: page,
            search: search,
        });
    }, [id, search, page]);

    useEffect(() => {
        setSelectedRowKeys(Array.from(selectedPartsMap.keys()));
    }, [selectedPartsMap]);

    const columns: ColumnProps<IPartList>[] = [
        {
            title: 'Name',
            dataIndex: 'step_file.image',
            width: 100,
            render: (text, record) => {
                return (
                    <div>
                        <div>
                            <Avatar
                                src={record.master_product?.step_file?.image || null}
                                icon={!record.master_product.step_file?.image && <FileOutlined />}
                                size={60}
                                style={{ marginRight: '16px' }}
                            />
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return <div>{record.master_product.name}</div>;
            },
        },
        {
            title: 'Material',
            dataIndex: 'material.name',
            render: (text, record) => {
                if (!record.material) {
                    return <Empty />;
                }
                return <div>{record.material.name}</div>;
            },
        },
        {
            title: 'Finish',
            dataIndex: 'finish.name',
            render: (text, record) => {
                if (!record.finish) {
                    return <Empty />;
                }
                return <div>{record.finish.name}</div>;
            },
        },
        {
            title: 'Coating',
            dataIndex: 'coating.name',
            render: (text, record) => {
                if (!record.coating) {
                    return <Empty />;
                }
                return <div>{record.coating.name}</div>;
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            render: (text, record) => {
                return <div>{record.quantity}</div>;
            },
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            render: (text, record) => {
                return <div>{record.unit_price}</div>;
            },
        },
        {
            title: 'Quoted On',
            dataIndex: 'quote',
            render: (text, record) => (
                <div style={{ padding: '4px 0' }}>
                    <Space direction="vertical" size="small">
                        {/* Quote Name and Stage */}
                        <div>
                            <Typography.Link
                                strong
                                onClick={() => {
                                    setPreviewQuote(record.quote.id);
                                }}
                            >
                                {record.quote.name}
                            </Typography.Link>
                        </div>

                        {/* Completion Status */}
                        <div>
                            {record.quote.completed_at ? (
                                <Tooltip title="Completed Date">
                                    <CalendarOutlined style={{ marginRight: 4 }} />
                                    <DateRender value={record.quote.completed_at} />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Not Completed">
                                    <CloseCircleOutlined style={{ marginRight: 4 }} />
                                    <Typography.Text type="secondary">{record.quote.stage}</Typography.Text>
                                </Tooltip>
                            )}
                        </div>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (text, record) => {
                const actions = [
                    {
                        key: 'download-pdf',
                        icon: <FilePdfOutlined />,
                        text: 'Download 2D File',
                        link: record.master_product?.drawing_file?.file || '#',
                    },
                    {
                        key: 'download-step',
                        icon: <FileOutlined />,
                        text: 'Download 3D File',
                        link: record.master_product?.step_file?.file || '#',
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    const handleSelectChange = (newSelectedRowKeys: React.Key[], newSelectedRows: IPartList[]) => {
        const updatedPartsMap = new Map(selectedPartsMap);

        // Add newly selected parts to the map
        newSelectedRows.forEach(part => updatedPartsMap.set(part.id, part));

        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedPartsMap(updatedPartsMap);
    };

    const handleRemovePart = (partId: React.Key) => {
        const updatedPartsMap = new Map(selectedPartsMap);
        updatedPartsMap.delete(partId); // Remove part from map

        const updatedSelectedRowKeys = selectedRowKeys.filter(key => key !== partId); // Update selected keys
        setSelectedPartsMap(updatedPartsMap);
        setSelectedRowKeys(updatedSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys, // controlled selection keys
        onChange: handleSelectChange,
    };

    const { entities, pagination, isLoading } = PartsStore;

    return (
        <div>
            <Row>
                <Col>
                    <BadgerSearch />
                </Col>
                <Col>
                    <Button
                        key={'view-selected-parts'}
                        type="primary"
                        onClick={() => setIsDrawerVisible(true)}
                        disabled={selectedRowKeys.length === 0}
                    >
                        View Selected {selectedRowKeys.length} Parts
                    </Button>
                </Col>
            </Row>

            <br />

            <Row>
                <Col span={24}>
                    <BadgerTable<IPartList>
                        columns={columns}
                        rowKey="id"
                        loading={isLoading}
                        dataSource={entities}
                        rowSelection={rowSelection}
                        pagination={{
                            defaultPageSize: pagination.defaultPageSize,
                            pageSize: pagination.defaultPageSize,
                            totalRecords: pagination.total,
                        }}
                    />
                </Col>
            </Row>

            {previewQuote > 0 && (
                <QuotePreview
                    quote_id={previewQuote}
                    handleClose={() => {
                        setPreviewQuote(0);
                    }}
                />
            )}
            <Drawer
                title="Selected Parts"
                placement="right"
                onClose={() => setIsDrawerVisible(false)}
                open={isDrawerVisible}
                width={600}
            >
                {Array.from(selectedPartsMap.values()).length > 0 ? (
                    Array.from(selectedPartsMap.values()).map(part => (
                        <Card
                            key={part.id}
                            style={{ marginBottom: '16px', borderRadius: '8px', overflow: 'hidden' }}
                            actions={[
                                <Button
                                    key={'remove-part'}
                                    type="link"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleRemovePart(part.id)}
                                >
                                    Remove
                                </Button>,
                            ]}
                        >
                            <Space align="start" style={{ width: '100%' }}>
                                <Avatar
                                    src={part.master_product.step_file?.image || null}
                                    icon={!part.master_product.step_file?.image && <FileOutlined />}
                                    size={64}
                                    style={{ marginRight: '16px' }}
                                />
                                <div style={{ flex: 1 }}>
                                    <Typography.Title level={5} style={{ marginBottom: '4px' }}>
                                        {part.master_product.name}
                                    </Typography.Title>

                                    {/* Row grouping for Material, Finish, and Coating */}
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <Typography.Text>
                                                <strong>Material:</strong> {part.material?.name || 'N/A'}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text>
                                                <strong>Finish:</strong> {part.finish?.name || 'N/A'}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text>
                                                <strong>Coating:</strong> {part.coating?.name || 'N/A'}
                                            </Typography.Text>
                                        </Col>
                                    </Row>

                                    <Divider style={{ margin: '8px 0' }} />

                                    {/* Row grouping for Quantity, Unit Price, and Total Price */}
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <Typography.Text>
                                                <strong>Quantity:</strong> {part.quantity}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text>
                                                <strong>Unit Price:</strong> ${part.unit_price}
                                            </Typography.Text>
                                        </Col>
                                    </Row>

                                    <Divider style={{ margin: '8px 0' }} />

                                    {/* Quote Information */}
                                    <Row>
                                        <Col span={24}>
                                            <Typography.Text type="secondary">
                                                <FilePdfOutlined style={{ marginRight: '8px' }} />
                                                {part.quote.name}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Text type="secondary">
                                                <CalendarOutlined style={{ marginRight: '8px' }} />
                                                <DateRender value={part.quote?.completed_at} />
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                            </Space>
                        </Card>
                    ))
                ) : (
                    <Empty />
                )}
                <div>
                    <Divider />
                    <Button
                        type="primary"
                        style={{
                            width: '100%',
                        }}
                        loading={isCreatingARequote}
                        onClick={() => {
                            setIsCreatingARequote(true);
                            post('/staff/v2/quotes/requote/', {
                                account: id,
                                quote_line_items: Array.from(selectedPartsMap.values()).map(part => part.id),
                            })
                                .then(data => {
                                    setIsDrawerVisible(false);
                                    setIsCreatingARequote(false);
                                    history.push(`/get-quote/?uid=${data.uid}`);
                                })
                                .catch(error => {
                                    setIsCreatingARequote(false);
                                    notification.error({
                                        duration: 10,
                                        message: 'Error',
                                        description: <ErrorRender error={error} />,
                                        placement: 'bottomRight',
                                    });
                                });
                        }}
                        disabled={selectedRowKeys.length === 0}
                    >
                        Requote Selected Parts
                    </Button>
                </div>
            </Drawer>
        </div>
    );
};

export default observer(PartsByAccount);
