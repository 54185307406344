import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox } from 'antd';
import TopicChecklist from './TopicChecklist';
import ChecklistQuestion from './ChecklistQuestion';
const DesignEngineerChecklistQuestion = ({ getFieldDecorator, question, value, onChange, cols, form, salesRepChecklist, checklistEdit, checklistQuestions, setChecklistQuestions, }) => {
    const answerSalesRepChecklist = () => {
        if (Array.isArray(salesRepChecklist)) {
            const questionFind = salesRepChecklist.find(el => el.question === question.question);
            if (questionFind) {
                const value = questionFind?.answer?.confirm;
                return value === true ? 'Yes' : value === false ? 'No' : value;
            }
        }
        return '';
    };
    return (React.createElement(Row, { gutter: 4 },
        React.createElement(Col, { ...cols },
            React.createElement(TopicChecklist, { value: question.question })),
        React.createElement(Col, { ...cols },
            React.createElement("h1", { className: "ml-4 mt-3" }, answerSalesRepChecklist())),
        React.createElement(Col, { ...cols },
            React.createElement(ChecklistQuestion, { question: question, value: value, onChange: onChange, form: form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions, checklistEdit: checklistEdit, getFieldDecorator: getFieldDecorator })),
        React.createElement(Col, { ...cols },
            React.createElement(Form.Item, null, getFieldDecorator(`mark-${question.question}`, {
                initialValue: question?.answer?.mark ?? false,
            })(React.createElement(Checkbox, { checked: form.getFieldValue(`mark-${question.question}`) }))))));
};
export default DesignEngineerChecklistQuestion;
