import React from 'react';
import { Table, TableProps } from 'antd';
import { PaginationComponent } from '../../Inputs/Pagination';
import { ColumnsType } from 'antd/es/table/interface';

interface BadgerTableProps<T> extends TableProps<T> {
    dataSource: T[];
    rowKey?: string | ((record: T) => string);
    columns: ColumnsType<T>; // Make columns required
    pagination: {
        defaultPageSize?: number;
        pageSize?: number; // if this value is not included, no pagination will be displayed
        totalRecords: number;
    };
}

const BadgerTable = <T extends object>({
    dataSource,
    columns,
    pagination,
    scroll = { x: true },
    rowKey = 'id',
    footer,
    ...rest
}: BadgerTableProps<T>): JSX.Element => {
    const tableFooter =
        pagination?.pageSize && pagination?.defaultPageSize
            ? {
                  footer: () => (
                      <PaginationComponent
                          defaultPageSize={pagination.defaultPageSize ?? 30}
                          pageSize={pagination.pageSize}
                          total={pagination.totalRecords}
                      />
                  ),
              }
            : {};

    return (
        <div style={{ width: '100%' }}>
            {pagination?.totalRecords ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '8px',
                        fontSize: '14px',
                        fontWeight: 500,
                    }}
                >
                    <span>{`Total Records: ${pagination.totalRecords}`}</span>
                </div>
            ) : null}

            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={scroll}
                rowKey={rowKey}
                {...rest}
                style={{ width: '100%' }}
                {...tableFooter}
            />
        </div>
    );
};

export default BadgerTable;
