import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, InputNumber, message, Radio } from 'antd';
import { observer } from 'mobx-react';
import { GenericSelect } from '@partsbadger/library';
const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const CreditMemoForm = observer((props) => {
    const { getFieldDecorator } = props.form;
    const form = props.form;
    const [credit_memo_type, set_credit_memo_type] = useState('Customer');
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err && props.selectedItems.length > 0) {
                const payload = {
                    name: values.name,
                    type: values.type,
                    amount: values.amount,
                    description: values.description,
                    reason: values.reason,
                    line_items: props.selectedItems,
                    vendor: values.vendor ? Number(values.vendor.key) : null,
                };
                props.onSubmitted(payload).then(() => {
                    form.resetFields();
                    set_credit_memo_type('Customer');
                });
            }
            else {
                message.error('Make sure you have selected 1 or more items from the order.');
            }
        });
    };
    return (React.createElement("div", { className: 'p-4 mt-4' },
        React.createElement(Form, { className: "ant-advanced-search-form", onSubmit: handleSubmit },
            React.createElement(Form.Item, { label: "Type", ...formItemLayout }, getFieldDecorator('type', {
                rules: [
                    {
                        required: true,
                    },
                ],
                initialValue: 'Customer',
            })(React.createElement(Radio.Group, { onChange: e => set_credit_memo_type(e.target.value) },
                React.createElement(Radio, { value: 'Customer' }, "Customer"),
                React.createElement(Radio, { value: 'Vendor' }, "Vendor")))),
            credit_memo_type === 'Vendor' && (React.createElement(React.Fragment, null,
                React.createElement(Form.Item, { label: "Select Vendor", ...formItemLayout }, getFieldDecorator('vendor', {
                    rules: [
                        {
                            required: true,
                            message: 'Select a Vendor!',
                        },
                    ],
                })(React.createElement(GenericSelect, { recordType: "Vendors" }))))),
            React.createElement(Form.Item, { label: "Name", ...formItemLayout }, getFieldDecorator('name', {
                rules: [
                    {
                        required: true,
                        message: 'Enter a name!',
                    },
                ],
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: "Amount", ...formItemLayout }, getFieldDecorator('amount', {
                rules: [
                    {
                        required: true,
                        message: 'Enter a amount!',
                    },
                ],
            })(React.createElement(InputNumber, { style: { width: '100%' } }))),
            React.createElement(Form.Item, { label: "Reason", ...formItemLayout }, getFieldDecorator('reason', {
                rules: [
                    {
                        required: true,
                        message: 'Enter a reason!',
                    },
                ],
            })(React.createElement(TextArea, { autoSize: true }))),
            React.createElement(Form.Item, { label: "Description", ...formItemLayout }, getFieldDecorator('description')(React.createElement(TextArea, { autoSize: true }))),
            React.createElement("div", { style: { textAlign: 'center' } },
                React.createElement(Button, { type: "primary", htmlType: "submit" }, "Create Credit Memo"),
                React.createElement(Button, { style: { marginLeft: 8 }, onClick: () => form.resetFields() }, "Clear")))));
});
export default Form.create()(CreditMemoForm);
