import * as React from 'react';
import { Select } from 'antd';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
const PartTolerances = props => {
    const tolerances = QuoteStore.partTolerances;
    return (React.createElement(Select, { showSearch: true, labelInValue: true, optionKeyProp: 'value', size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search tolerance", filterOption: (input, option) => {
            //@ts-ignore
            return option.props.children?.toLowerCase()?.slice(3)?.indexOf(input.toLowerCase()) >= 0;
        }, ...props }, tolerances.map(entity => (React.createElement(Select.Option, { key: entity.id }, `${entity.name} (${entity.value_in_mm} mm)`)))));
};
export default observer(PartTolerances);
