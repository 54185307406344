import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Drawer, Input, message, notification, Row, Select, Spin, Tabs, Tag, Tooltip, } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import LineItems from './LineItems';
import { QuotedLineItems } from '../../QuotedLineItems';
import { AdditionalRequirements, AddressEdit, Contacts, ErrorRender, FormErrorParser, Industries, QuestionYesOrNo, Quotes, ShippingCarriers, ShippingMethods, ShippingRequirements, UploadFile, Users, } from '@partsbadger/library';
import ApplicationCostItems from '../../ApplicationCostItems';
import AddItemsButton from './AddItemsButton';
import { SalesOrderModalBatches } from '../../components/SalesOrderModalBatches';
import { SalesOrderListBatches } from '../../components/SalesOrderListBatches';
import { buildJsonBigOrderChecklist, TOTAL_PRICE_SHOW_CHECKLIST } from '../../utils/utils';
import moment from 'moment';
import SalesOrderBatchStore from '../../Stores/SalesOrderBatchStore';
import { ALLOWED_FILES } from '@partsbadger/utils';
import SalesRepChecklist from '../SalesOrderChecklist/SalesRepChecklist';
import { QuoteStore } from 'quotes-frontend/src/stores';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { parseInt } from 'lodash';
import { OrderTotals } from '../../OrderTotals';
import NewSampleRequest from '../../components/SalesOrderCreateForm/NewSampleRequest';
import _ from 'lodash';
const { TabPane } = Tabs;
const formItemLayout = {
    labelCol: { md: 4, xs: 24 },
    wrapperCol: { md: 20, xs: 24 },
};
const formItemCheckBoxLayout = {
    labelCol: { md: 4, xs: 6 },
    wrapperCol: { md: 8, xs: 8 },
};
export const styleBody = {
    padding: '3px 0',
    textAlign: 'center',
    color: 'black',
};
const SalesOrderCreateForm = observer((props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const contactUrl = queryParams.get('contact');
    const quoteUrl = queryParams.get('quote');
    //Contact Email
    const param_email = queryParams.get('email');
    const param_id = queryParams.get('DraftOrderID');
    const [orderStatus, setOrderStatus] = useState('Needs Review');
    const [draftOrder, setDraftOrder] = useState(false);
    const [showAddSpecificItems, setShowAddSpecificItems] = useState(false);
    const [showAddNewAddress, setShowAddNewAddress] = useState(false);
    const [customRequirements, setCustomRequirements] = useState({});
    const [orderItem, setOrderItem] = useState(null);
    const [showModalBatches, setShowModalBatches] = useState(false);
    const [isCreatingARequote, setIsCreatingARequote] = useState(false);
    const [salesRepChecklistQuestions, setSalesRepChecklistQuestions] = useState([]);
    const [aditionalFilesFromQuote, setAditionalFilesFromquote] = useState([]);
    const [openQuotes, setOpenQuotes] = useState(false);
    const [sampleRequestList, setSampleRequestList] = useState([]);
    const history = useHistory();
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const { loading, account, contact, order_items, addresses, industry_use, users, default_shipping_address, default_billing_address, } = SalesOrderStore;
    const { current_user } = props;
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
    // Sections answer/reveal
    const [pricingCorrect, setPricingCorrect] = useState(true);
    const [dueDateMatch, setDueDateMatch] = useState(true);
    let order = SalesOrderStore.order;
    const mergeAditionalFiles = (first, second) => {
        return [...(first !== undefined ? first : []), ...second];
    };
    const getPayload = (values) => {
        const shipping = values.shipping_address;
        const billing = values.billing_address;
        let shipping_requirements = null;
        if (order?.split_into_batch_shipments && orderStatus === 'Needs Review') {
            if (!values.shipping_requirements.includes('Batch/Blanket Releases')) {
                shipping_requirements = ['Batch/Blanket Releases'];
            }
        }
        const partial_shipments = values.shipping_requirements ? values.shipping_requirements : [];
        if (shipping_requirements != null && Array.isArray(shipping_requirements)) {
            shipping_requirements = shipping_requirements.concat(partial_shipments);
        }
        else {
            shipping_requirements = partial_shipments.length > 0 ? partial_shipments : null;
        }
        const payload = {
            industry_use: null,
            end_use: null,
            owner: null,
            contact: values.contact.id,
            billing_name: billing && billing.name ? billing.name : order?.billing_name,
            billing_street: billing && billing.street ? billing.street : order?.billing_street,
            billing_city: billing && billing.city ? billing.city : order?.billing_city,
            billing_state: billing && billing.state ? billing.state : order?.billing_state,
            billing_zip_code: billing && billing.zip_code ? billing.zip_code : order?.billing_zip_code,
            billing_country: billing && billing.country?.code ? billing.country.code : order?.billing_country,
            shipping_name: shipping && shipping.name ? shipping.name : order?.shipping_name,
            shipping_street: shipping && shipping.street ? shipping.street : order?.shipping_street,
            shipping_city: shipping && shipping.city ? shipping.city : order?.shipping_city,
            shipping_state: shipping && shipping.state ? shipping.state : order?.shipping_state,
            shipping_zip_code: shipping && shipping.state ? shipping.zip_code : order?.shipping_zip_code,
            shipping_country: shipping && shipping.country?.code ? shipping.country.code : order?.shipping_country,
            shipping_requirements: shipping_requirements,
            create_2d_files: values.create_2d_files,
            create_3d_files: values.create_3d_files,
            additional_files: mergeAditionalFiles(values.additional_files?.map(po => {
                return po.response.id;
            }), aditionalFilesFromQuote.map((f) => f.id)),
            po_files: values.po_files?.map(po => {
                return po.response.id;
            }),
            additional_requirements: values.additional_requirements?.map(r => {
                return r.value ? `${r.label} : ${r.value}` : r.label;
            }),
            add_application_cost: values.add_application_cost,
            recovered_account: values.recovered_account,
            source: 'Sales Order Tool - Staff',
            notes: values.notes,
            add_shipping_notification_address: values.add_shipping_notification_address,
            hot_order: values.hot_order,
            hot_order_notes: values.hot_order_notes,
            shipping_account_number: values.shipping_account_number,
            shipping_method: values.shipping_method,
            carrier: values.carrier,
            export_control: values.export_control,
            status: orderStatus,
            purchase_order: values.purchase_order,
            payment_terms: values.payment_terms,
            samples_our_quality: order?.samples_our_quality,
            samples_design_customer: order?.samples_design_customer,
        };
        if (values.industry_use?.industry_use?.key) {
            payload['industry_use'] = values.industry_use?.industry_use?.key;
            payload['end_use'] = values.industry_use.end_use.label;
        }
        if (values.owner?.key) {
            payload['owner'] = values.owner.key;
        }
        if (values.invoice_email_address) {
            payload['invoice_email_address'] = values.invoice_email_address;
        }
        if (values.in_house_requirements) {
            payload['in_house_requirements'] = values.in_house_requirements;
        }
        if (values.hot_ship_date) {
            const hsd = values.hot_ship_date;
            if (hsd) {
                payload['hot_ship_date'] = hsd.format('YYYY-MM-DD');
            }
        }
        if (pricingCorrect !== undefined) {
            payload['pricing_correct'] = pricingCorrect;
            if (values.reason_pricing_correct) {
                payload['reason_pricing_correct'] = values.reason_pricing_correct;
            }
        }
        if (dueDateMatch !== undefined) {
            payload['due_date_match'] = dueDateMatch;
            if (values.reason_due_date_match) {
                payload['reason_due_date_match'] = values.reason_due_date_match;
            }
        }
        // build json Checklist Sales Rep
        if (SalesOrderStore.getTotalPriceOrder() >= TOTAL_PRICE_SHOW_CHECKLIST) {
            payload['sales_rep_checklist'] = buildJsonBigOrderChecklist(values, salesRepChecklistQuestions);
        }
        return payload;
    };
    const handleSubmit = (action) => {
        if (checkFilesItemQuotes()) {
            message.error('One or more order items have file types that are not allowed to send the sales order. Please check again.', 5);
        }
        else {
            props.form.validateFields(async (err, values) => {
                if (action == 'Send to Review') {
                    if (!err) {
                        try {
                            if (!contact) {
                                message.error('No Contact Selected');
                                return;
                            }
                            if (!account) {
                                message.error('No Account Selected');
                                return;
                            }
                            if (!order_items.length) {
                                message.error('Line Items are required!');
                                return;
                            }
                            if (doItNeedARequote) {
                                message.error('Please Validate Big Order CheckList!', 3);
                                return;
                            }
                            if (ffaBeforeProduction) {
                                if (partsSelectedForFAA.length === 0) {
                                    message.error('The Parts for FAA is required!');
                                    return;
                                }
                                if (sampleQuantity === null || sampleQuantity === 0) {
                                    message.error('Sample Quantities is required!');
                                    return;
                                }
                            }
                            const payload = getPayload(values);
                            await SalesOrderStore.sendToReviewStaff(SalesOrderStore.order_id, {
                                ...payload,
                                account: account.id,
                                contact: SalesOrderStore.contact?.id,
                                status: 'Needs Review',
                            });
                            notification.success({
                                message: 'The order was sent for review',
                            });
                            SalesOrderStore.loading = false;
                            SalesOrderStore.order_items = [];
                            SalesOrderStore.quoted_line_items = [];
                            SalesOrderStore.addresses = [];
                            SalesOrderStore.contact = undefined;
                            cleanStore();
                            props.onSubmitted();
                        }
                        catch (error) {
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: React.createElement(ErrorRender, { error: error }),
                                placement: 'bottomRight',
                            });
                        }
                    }
                    else {
                        notification.error({
                            key: 'form-errors',
                            duration: 20,
                            message: 'Please input all required fields',
                            description: React.createElement(FormErrorParser, { errors: err }),
                            placement: 'bottomRight',
                            maxCount: 1,
                        });
                    }
                }
                else {
                    try {
                        const payload = getPayload(values);
                        await SalesOrderStore.saveOrCompleteSalesOrder(SalesOrderStore.order_id, {
                            ...payload,
                            status: 'Draft',
                            more_line_items: moreLineItems,
                            split_into_batch_shipments: splitIntoBatchShipments,
                            ffa_before_production: ffaBeforeProduction,
                        });
                        notification.info({
                            message: 'The order was saved as draft',
                        });
                        SalesOrderStore.loading = false;
                        SalesOrderStore.order_items = [];
                        SalesOrderStore.quoted_line_items = [];
                        SalesOrderStore.addresses = [];
                        SalesOrderStore.contact = undefined;
                        cleanStore();
                        props.onSubmitted();
                    }
                    catch (error) {
                        notification.error({
                            duration: 10,
                            message: 'Error',
                            description: React.createElement(ErrorRender, { error: error }),
                            placement: 'bottomRight',
                        });
                    }
                }
                SalesOrderStore.loading = false;
            });
        }
    };
    const checkFilesItemQuotes = () => {
        // Verify that the files uploaded by quotes comply with the allowed extensions.
        const allowed_files_3d = ALLOWED_FILES.Files3D;
        const allowed_files_2d = ALLOWED_FILES.Files2D;
        const files_not_allowed = [];
        order_items.map(item => {
            if (item.production_3d_file_id === undefined && item.production_3d_file) {
                const t = item.production_3d_file.filename.split('.').pop()?.toLowerCase();
                const file_type = allowed_files_3d.filter(f => f === t);
                if (t !== file_type[0]) {
                    files_not_allowed.push(item.id);
                }
            }
            if (item.production_2d_file_id === undefined && item.production_2d_file) {
                const t = item.production_2d_file.filename.split('.').pop()?.toLowerCase();
                const file_type = allowed_files_2d.filter(f => f === t);
                if (t !== file_type[0]) {
                    files_not_allowed.push(item.id);
                }
            }
        });
        return files_not_allowed.length > 0;
    };
    const cleanStore = () => {
        // Cleaning selected quotes
        order = null;
        SalesOrderStore.setOrder(undefined);
        setFieldsValue({
            quotes: [],
            shipping_address: null,
            billing_address: null,
        });
        SalesOrderStore.contact = null;
        SalesOrderStore.account = null;
        SalesOrderStore.setQuotedLineItems([]);
        SalesOrderStore.setApplicationCostItems([]);
        SalesOrderStore.setOrderItems([]);
        SalesOrderStore.setQuote();
        SalesOrderBatchStore.resetBatches();
        SalesOrderStore.poUnacceptableKeywords = [];
        SalesOrderStore.afUnacceptableKeywords = [];
        setOpenQuotes(false);
        setPricingCorrect(true);
        setDueDateMatch(true);
    };
    const loadLocalStateData = () => {
        if (order) {
            order?.line_items?.map(batch => SalesOrderBatchStore.setBatches(batch.batch_shipments ?? []));
            if (order.due_date_match !== undefined) {
                setDueDateMatch(order.due_date_match);
            }
            if (order.pricing_correct !== undefined) {
                setPricingCorrect(order.pricing_correct);
            }
            setDraftOrder(true);
        }
    };
    const parseRequirements = () => {
        const v = _.values(customRequirements);
        let req = [];
        _.forEach(v).map(i => {
            req = [...req, ...i];
        });
        // Remove duplicates
        return _.uniqBy(req, function (e) {
            return e.key;
        });
    };
    /**
     * Get and order by ID and intialize the form with the data
     * @param id
     */
    const getOrderByID = async (id) => {
        await SalesOrderStore.getOrderById(id);
        loadLocalStateData();
        return true;
    };
    /**
     * Get a Draft order using the Contact ID, it will return an updated order with the default values
     * like the default shipping and billing address, the additional requirements, etc.
     * @param contact_id: number
     */
    const getDraftOrderByContactID = async (contact_id) => {
        await SalesOrderStore.getContactById(contact_id);
        await SalesOrderStore.getOrCreateSalesOrder(contact_id);
        loadLocalStateData();
        return true;
    };
    /**
     * Get an contact with the email provided, if exists, it will get a draft order for that contact
     * @param contact_email
     */
    const create_order_by_email = async (contact_email) => {
        await SalesOrderStore.getContactByEmail(contact_email);
        if (SalesOrderStore.contact) {
            await getDraftOrderByContactID(SalesOrderStore.contact.id);
        }
    };
    const getInitialRemoteData = async () => {
        // if there is an order id in the params, get the order
        if (param_id) {
            return await getOrderByID(parseInt(param_id));
        }
        // If there is a provided contact email in the url, it will try to get the contact by email
        if (param_email) {
            return await create_order_by_email(param_email);
        }
        // If there is a provided contact id in the url, it will try to get the order by contact id
        if (contactUrl) {
            await getDraftOrderByContactID(parseInt(contactUrl));
        }
        if (quoteUrl) {
            await SalesOrderStore.getQuotes('account=' + SalesOrderStore.contact?.account + '&is_purchasable=true');
            await SalesOrderStore.getQuoteLineItems(quoteUrl);
            await SalesOrderStore.getQuote(parseInt(quoteUrl));
        }
    };
    /**
     * Clean Store and load remote data based on the url params
     */
    useEffect(() => {
        cleanStore();
        setIsLoadingInitialData(true);
        getInitialRemoteData().finally(() => {
            setIsLoadingInitialData(false);
            getSalesRepChecklist();
        });
    }, []);
    /**
     * Local Contact Info
     */
    useEffect(() => {
        if (order?.contact?.id) {
            SalesOrderStore.quoted_line_items = [];
            SalesOrderStore.applicationCostItems = [];
            if (order)
                SalesOrderStore.getContactById(order.contact.id);
        }
    }, [order?.contact?.id]);
    // useEffect(() => {
    //     if (!completeAllSteps) {
    //         openNotification();
    //     }
    // }, []);
    /**
     * Set additional requirements when custom requirements change
     */
    useEffect(() => {
        setFieldsValue({
            additional_requirements: parseRequirements(),
        });
    }, [customRequirements]);
    const [sales_order_requirements, setSalesOrderRequirements] = useState();
    useMemo(() => {
        if (Object.keys(customRequirements).length > 0) {
            setSalesOrderRequirements(parseRequirements());
        }
    }, [customRequirements]);
    const getQuoteInformation = async (quote_id) => {
        await SalesOrderStore.getQuote(quote_id).then(r => {
            let newAdditionaFiles = [...aditionalFilesFromQuote];
            newAdditionaFiles = newAdditionaFiles.concat(r.additional_files);
            setAditionalFilesFromquote(newAdditionaFiles.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i));
            customRequirements[quote_id] = r.custom_requirements;
            setCustomRequirements({
                ...customRequirements,
            });
        });
        SalesOrderStore.getQuoteLineItems(quote_id);
    };
    /**
     * Get all quote information of all selected quotes in the sales order on the start page
     */
    useEffect(() => {
        if (order && order.quotes) {
            order.quotes.map(async (quote) => {
                getQuoteInformation(quote.id);
            });
        }
    }, [order?.id]);
    const doItNeedARequote = getFieldValue('confirm-Does pricing match?') == 'No' || getFieldValue('confirm-Do the revs match?') == 'No';
    const getSalesRepChecklist = () => {
        // sales_order_id = 0 when create new sales order
        SalesOrderStore.getChecklistType(0, 'Sales Representatives Checklist')
            .then(response => {
            setSalesRepChecklistQuestions(response.checklist);
        })
            .catch(error => {
            message.error(error.message);
        });
    };
    if (isLoadingInitialData) {
        return (React.createElement("div", { style: {
                maxWidth: 1500,
                margin: '0 auto',
            } },
            React.createElement("div", { style: {
                    display: 'flex',
                } },
                React.createElement(Spin, { size: "large" }))));
    }
    const parts = order_items.filter(i => i.name !== 'CNC Part');
    // const application_cost =  order_items.filter(i => i.name != 'CNC Part');
    const partsSelectedForFAA = parts.filter(i => i.quantity_sample_required).map(i => i.id.toString());
    const all_sample_quantities = parts.filter(i => i.quantity_sample_required != null).map(i => i.quantity_sample_required) ?? [];
    let sampleQuantity = null;
    if (all_sample_quantities.length > 0) {
        sampleQuantity = all_sample_quantities[0] ?? null;
    }
    const moreLineItems = order?.more_line_items;
    const splitIntoBatchShipments = order?.split_into_batch_shipments;
    const ffaBeforeProduction = order?.ffa_before_production;
    const needsToCompleteFAA = () => {
        return !!(order?.ffa_before_production && (!sampleQuantity || all_sample_quantities.length == 0));
    };
    const section1st = parts.length > 0;
    const section2nd = section1st && order?.more_line_items == false;
    const section3rd = section2nd && order?.split_into_batch_shipments != undefined;
    const section4th = section2nd && section3rd && order?.ffa_before_production != undefined && !needsToCompleteFAA();
    const completeAllSteps = section1st && section2nd && section2nd && section3rd && section4th;
    return (React.createElement("div", { style: {
            maxWidth: 1500,
            margin: '0 auto',
        } },
        React.createElement(Form, { className: 'sales-order-form' },
            React.createElement("div", { className: 'row-order mt-5 m-auto' },
                React.createElement(Col, { sm: 24 }, !param_email ? (React.createElement(Form.Item, { label: 'Contact', ...formItemLayout }, getFieldDecorator('contact', {
                    initialValue: SalesOrderStore?.contact
                        ? SalesOrderStore.contact?.fullname + ' || ' + SalesOrderStore.contact?.email
                        : null,
                    rules: [
                        {
                            required: true,
                            message: 'Please select a contact to start',
                        },
                    ],
                })(React.createElement(Contacts, { account: SalesOrderStore?.account?.id, onSelect: async (item) => {
                        getDraftOrderByContactID(item.key);
                        cleanStore();
                    } })))) : (React.createElement(Form.Item, { label: 'Contact', ...formItemLayout }, getFieldDecorator('contact', {
                    initialValue: contact?.fullname + ' || ' + param_email,
                })(React.createElement(Input, { disabled: true })))))),
            contact?.id && !account?.id && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                React.createElement(Alert, { type: 'error', showIcon: true, message: "This contact doesn't have an account related, please fix this before to continue!" }))),
            draftOrder && (React.createElement("div", { className: 'flex justify-center' },
                React.createElement(Alert, { message: React.createElement("div", null,
                        "A draft order already exists for the contact:",
                        ' ',
                        React.createElement("b", null,
                            SalesOrderStore.contact?.fullname + ' | ' + SalesOrderStore.contact?.email,
                            " "),
                        "You can continue editing the draft order and then submit it for review."), type: "info", showIcon: true, closable: true, onClose: () => setDraftOrder(false) }))),
            param_email && !contact?.id && !account?.id && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                React.createElement(Alert, { type: 'error', showIcon: true, message: `This email contact is payable ${param_email}; try another contact from zoho.` }))),
            contact?.id && account?.id && (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: 'row-order mt-5 text-center m-auto', style: {
                        maxWidth: '57%',
                    } },
                    React.createElement(Col, { sm: 12 },
                        React.createElement(Form.Item, { label: 'Billing' }, getFieldDecorator('billing_address', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Billing Address is required',
                                },
                            ],
                            validateTrigger: 'onSelectAddress',
                            ...(order?.billing_street
                                ? { initialValue: order?.billing_street }
                                : { initialValue: default_billing_address?.street }),
                        })(React.createElement(AddressEdit, { account_name: account?.name
                                ? account?.name
                                : `${contact.first_name} ${contact.last_name}`, contact_id: contact.id, addresses: SalesOrderStore.contact?.addresses
                                ? SalesOrderStore.contact?.addresses?.filter(function (billing) {
                                    return (billing.type === 'Billing' ||
                                        billing.type === 'Billing and Shipping');
                                })
                                : [], type: 'Billing', currentAddress: order?.billing_street
                                ? {
                                    name: order?.billing_name,
                                    type: 'Billing',
                                    street: order?.billing_street,
                                    city: order?.billing_city,
                                    state: order?.billing_state,
                                    country: {
                                        alpha3: order?.billing_country,
                                        code: order?.billing_country,
                                        name: order?.billing_country,
                                    },
                                    zip_code: order?.billing_zip_code,
                                }
                                : default_billing_address
                                    ? {
                                        name: default_billing_address?.name,
                                        type: 'Billing',
                                        street: default_billing_address?.street,
                                        city: default_billing_address?.city,
                                        state: default_billing_address?.state,
                                        country: {
                                            alpha3: '',
                                            code: default_billing_address?.country?.code,
                                            name: default_billing_address?.country?.name,
                                        },
                                        zip_code: default_billing_address?.zip_code,
                                    }
                                    : null, onSelectAddress: _address => {
                                const { name, street, city, state, country, zip_code, type } = _address;
                                let payload = {};
                                if (type == 'Billing and Shipping') {
                                    payload = {
                                        billing_name: name,
                                        billing_street: street,
                                        billing_city: city,
                                        billing_state: state,
                                        billing_country: country,
                                        billing_zip_code: zip_code,
                                        shipping_name: getFieldValue('shipping_address')?.name,
                                        shipping_street: getFieldValue('shipping_address')?.street,
                                        shipping_city: getFieldValue('shipping_address')?.city,
                                        shipping_state: getFieldValue('shipping_address')?.state,
                                        shipping_country: getFieldValue('shipping_address')?.country,
                                        shipping_zip_code: getFieldValue('shipping_address')?.zip_code,
                                    };
                                }
                                else {
                                    payload = {
                                        billing_name: name,
                                        billing_street: street,
                                        billing_city: city,
                                        billing_state: state,
                                        billing_country: country,
                                        billing_zip_code: zip_code,
                                    };
                                }
                                if (order?.id) {
                                    SalesOrderStore.patchOrderDraft(order?.id, payload).finally(() => {
                                        if (order)
                                            SalesOrderStore.getOrderById(order?.id);
                                    });
                                }
                            } })))),
                    React.createElement(Col, { sm: 12 },
                        React.createElement(Form.Item, { label: 'Shipping' }, getFieldDecorator('shipping_address', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Shipping Address is required',
                                },
                            ],
                            validateTrigger: 'onSelectAddress',
                            ...(order?.shipping_street
                                ? { initialValue: order?.shipping_street }
                                : { initialValue: default_shipping_address?.street }),
                        })(React.createElement(AddressEdit, { account_name: account?.name
                                ? account?.name
                                : `${contact.first_name} ${contact.last_name}`, contact_id: contact.id, addresses: SalesOrderStore.contact?.addresses
                                ? SalesOrderStore.contact?.addresses?.filter(function (shipping) {
                                    return (shipping.type === 'Shipping' ||
                                        shipping.type === 'Billing and Shipping');
                                })
                                : [], type: 'Shipping', currentAddress: order?.shipping_street
                                ? {
                                    name: order?.shipping_name,
                                    type: 'Shipping',
                                    street: order?.shipping_street,
                                    city: order?.shipping_city,
                                    state: order?.shipping_state,
                                    country: {
                                        alpha3: order?.shipping_country,
                                        code: order?.shipping_country,
                                        name: order?.shipping_country,
                                    },
                                    zip_code: order?.shipping_zip_code,
                                }
                                : default_shipping_address
                                    ? {
                                        name: default_shipping_address?.name,
                                        type: 'Shipping',
                                        street: default_shipping_address?.street,
                                        city: default_shipping_address?.city,
                                        state: default_shipping_address?.state,
                                        country: {
                                            alpha3: '',
                                            code: default_shipping_address?.country?.code,
                                            name: default_shipping_address?.country?.name,
                                        },
                                        zip_code: default_shipping_address?.zip_code,
                                    }
                                    : null, onSelectAddress: _address => {
                                const { name, street, city, state, country, zip_code, type } = _address;
                                let payload = {};
                                if (type == 'Billing and Shipping') {
                                    payload = {
                                        billing_name: getFieldValue('billing_address')?.name,
                                        billing_street: getFieldValue('billing_address')?.street,
                                        billing_city: getFieldValue('billing_address')?.city,
                                        billing_state: getFieldValue('billing_address')?.state,
                                        billing_country: getFieldValue('billing_address')?.country,
                                        billing_zip_code: getFieldValue('billing_address')?.zip_code,
                                        shipping_name: name,
                                        shipping_street: street,
                                        shipping_city: city,
                                        shipping_state: state,
                                        shipping_country: country,
                                        shipping_zip_code: zip_code,
                                    };
                                }
                                else {
                                    payload = {
                                        shipping_name: name,
                                        shipping_street: street,
                                        shipping_city: city,
                                        shipping_state: state,
                                        shipping_country: country,
                                        shipping_zip_code: zip_code,
                                    };
                                }
                                if (order?.id) {
                                    SalesOrderStore.patchOrderDraft(order?.id, payload).finally(() => {
                                        if (order)
                                            SalesOrderStore.getOrderById(order?.id);
                                    });
                                }
                            } }))))),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, { label: 'Quotes', ...formItemLayout }, getFieldDecorator('quotes', {
                            initialValue: order?.quotes?.map(q => {
                                return {
                                    key: q.id,
                                    label: q.name,
                                };
                            }),
                            rules: [{ required: false, message: 'Please select a Quote!' }],
                        })(React.createElement(Quotes, { mode: 'multiple', account: account?.id, filters: {
                                is_purchasable: true,
                            }, onSelect: (item) => {
                                getQuoteInformation(parseInt(item.key));
                                // SalesOrderStore.getQuoteLineItems(item.key);
                                // SalesOrderStore.getQuote(parseInt(item.key))
                                //     .then((response: QuoteProps) => {
                                //         if (
                                //             response.custom_requirements !== null &&
                                //             response.custom_requirements.length > 0
                                //         ) {
                                //             setCustomRequirements([
                                //                 ...customRequirements,
                                //                 response.custom_requirements,
                                //             ]);
                                //         } else {
                                //             setCustomRequirements([
                                //                 ...customRequirements,
                                //                 SalesOrderStore.default_requirements,
                                //             ]);
                                //         }
                                //     })
                                //     .then(() => {
                                //         if (SalesOrderStore.quote) {
                                //             for (
                                //                 let i = 0;
                                //                 i < SalesOrderStore.quote.additional_files.length;
                                //                 i++
                                //             ) {
                                //                 const obj = {
                                //                     ...SalesOrderStore.quote.additional_files[i],
                                //                     quoteId: SalesOrderStore.quote.id.toString(),
                                //                 };
                                //
                                //                 setAditionalFilesFromquote(aditionalFilesFromQuote => [
                                //                     ...aditionalFilesFromQuote,
                                //                     obj,
                                //                 ]);
                                //             }
                                //         }
                                //     });
                            }, onDeselect: (item) => {
                                delete customRequirements[item.key];
                                setCustomRequirements(customRequirements);
                                SalesOrderStore.RemoveQuoteLineItemsFromSpecificQuote(parseInt(item.key));
                                setAditionalFilesFromquote(aditionalFilesFromQuote.filter(obj => obj.quoteId !== item.key));
                            }, onChange: async (quotes) => {
                                const payload = {
                                    quotes: quotes.map(quote => Number(quote.key)),
                                };
                                await SalesOrderStore.saveOrCompleteSalesOrder(SalesOrderStore.order_id, payload).finally(() => {
                                    SalesOrderStore.loading = false;
                                });
                                if (order?.id) {
                                    SalesOrderStore.getOrderById(order?.id);
                                }
                            }, open: openQuotes, onClick: () => setOpenQuotes(undefined) })))),
                    order && SalesOrderStore.availableItemsCount > 0 && (React.createElement(Col, { sm: 24 },
                        React.createElement("div", { style: {
                                margin: 'auto',
                                marginTop: 10,
                            } },
                            React.createElement(AddItemsButton, { setShowAddSpecificItems: setShowAddSpecificItems }))))),
                React.createElement(Divider, null,
                    React.createElement("div", { className: 'title' }, " Line Items")),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(LineItems, null)),
                    order && (React.createElement(Col, { md: 24 },
                        React.createElement(OrderTotals, { subtotal: parseFloat(order.subtotal), discount: parseFloat(order.discount), taxes_rate: parseFloat(order.taxes_rate), adjustment: parseFloat(order.adjustment) })))),
                React.createElement("div", { className: 'border border-grey-600' },
                    React.createElement(Form.Item, null, getFieldDecorator('moreLineItems', {
                        validateTrigger: 'setAnswer',
                        initialValue: order?.more_line_items,
                        // rules: [{ required: true, message: 'Please select a Quote!' }],
                    })(React.createElement(QuestionYesOrNo, { default: order?.more_line_items, setAnswer: (answer) => {
                            SalesOrderStore.update_sales_order_field('more_line_items', answer);
                            setOpenQuotes(answer);
                            if (!answer && SalesOrderStore.order_items.length == 0) {
                                notification.error({
                                    duration: 10,
                                    message: 'Error',
                                    description: 'You must first add products to the order.',
                                    placement: 'bottomRight',
                                });
                            }
                        }, question: 'Do you have add more line items..?' })))),
                section2nd && (React.createElement("div", { className: 'border border-grey-600' },
                    React.createElement(Form.Item, null, getFieldDecorator('splitIntoBatchShipments', {
                        validateTrigger: 'setAnswer',
                        initialValue: order?.split_into_batch_shipments,
                        // rules: [{ required: true, message: 'Please select a Quote!' }],
                    })(React.createElement(QuestionYesOrNo, { default: order?.split_into_batch_shipments, setAnswer: (answer) => {
                            SalesOrderStore.update_sales_order_field('split_into_batch_shipments', answer);
                        }, question: 'Do the quantities above have to be split into batch shipments?' }))),
                    React.createElement("div", { className: 'row-order' }, order?.split_into_batch_shipments && (React.createElement("div", { className: 'pb-4' },
                        React.createElement(SalesOrderListBatches, { setOrderItem: setOrderItem, setShowModalBatches: setShowModalBatches, showModalBatches: showModalBatches })))))),
                section3rd && (React.createElement(React.Fragment, null,
                    React.createElement(QuestionYesOrNo, { default: ffaBeforeProduction !== null ? ffaBeforeProduction : undefined, setAnswer: answer => {
                            SalesOrderStore.update_sales_order_field('ffa_before_production', answer);
                        }, question: 'Will we need to complete an FAA (First Article Approval) or receive sample approval from the customer before continuing production?' }),
                    order_items?.map(item => {
                        if (item.quantity_sample_required) {
                            return (React.createElement("div", { key: item.id },
                                React.createElement(NewSampleRequest, { form: props.form, orderLineItem: item })));
                        }
                    }),
                    order?.ffa_before_production && (React.createElement(React.Fragment, null,
                        React.createElement(NewSampleRequest, { form: props.form }),
                        React.createElement(Row, { className: 'row-order mt-5 text-center' },
                            React.createElement(Col, { sm: 12 },
                                React.createElement(QuestionYesOrNo, { default: order.samples_our_quality, question: 'Is the customer requiring samples to verify our quality?', setAnswer: () => {
                                        SalesOrderStore.update_sales_order_field('samples_our_quality', !order?.samples_our_quality);
                                    } })),
                            React.createElement(Col, { sm: 12 },
                                React.createElement(QuestionYesOrNo, { default: order.samples_design_customer, question: 'Is the customer requiring samples to verify their design', setAnswer: () => {
                                        SalesOrderStore.update_sales_order_field('samples_design_customer', !order?.samples_design_customer);
                                    } }))))))),
                section4th && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, null,
                        React.createElement("div", { className: 'title' }, " Files")),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        SalesOrderStore.doWeNeed2DFiles && (React.createElement(Col, { sm: 12 },
                            React.createElement(Form.Item, { label: 'Creating 2D Files?', ...formItemLayout, help: 'PartsBadger creating 2D Files?' }, getFieldDecorator('create_2d_files', {
                                valuePropName: 'checked',
                                initialValue: SalesOrderStore.order?.create_2d_files || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please upload a 2d files or select the option to create 2d files',
                                    },
                                ],
                            })(React.createElement(Checkbox, { onChange: () => {
                                    SalesOrderStore.update_sales_order_field('create_2d_files', !SalesOrderStore.order?.create_2d_files);
                                } }))))),
                        SalesOrderStore.doWeNeed3DFiles && (React.createElement(Col, { sm: 12 },
                            React.createElement(Form.Item, { label: 'Creating 3D Files?', ...formItemLayout, help: 'PartsBadger creating 3D Files?' }, getFieldDecorator('create_3d_files', {
                                valuePropName: 'checked',
                                initialValue: SalesOrderStore.order?.create_3d_files || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please upload a 3d files or select the option to create 3d files',
                                    },
                                ],
                            })(React.createElement(Checkbox, null)))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Purchase Order Files', ...formItemLayout },
                                getFieldDecorator('po_files', {
                                    initialValue: order?.po_files?.map(r => {
                                        return {
                                            uid: r.id,
                                            name: r.filename,
                                            status: 'done',
                                            response: {
                                                id: r.id,
                                            },
                                            url: r.file,
                                        };
                                    }),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please upload a Purchase Order File',
                                        },
                                    ],
                                })(React.createElement(UploadFile, { multiple: true, onUpload: data => {
                                        SalesOrderStore.getUnacceptableKeywords(data.response.id, SalesOrderStore.poUnacceptableKeywords).catch(e => {
                                            console.error(e);
                                        });
                                    } })),
                                SalesOrderStore.poUnacceptableKeywords?.map((item, index) => {
                                    const key = `${index}`;
                                    return (React.createElement(Tooltip, { key: key, placement: "topLeft", title: 'Non-accepted keywords found in the document' },
                                        React.createElement(Tag, { color: "red" }, item)));
                                })))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 12 },
                            React.createElement(QuestionYesOrNo, { default: pricingCorrect, question: 'Is the pricing correct?', setAnswer: setPricingCorrect }),
                            !pricingCorrect && (React.createElement(Form.Item, { label: 'Are we accepting the PO? Why?', className: 'pl-8' }, getFieldDecorator('reason_pricing_correct', {
                                initialValue: SalesOrderStore.order?.reason_pricing_correct,
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field reason of pricing incorrect is required',
                                    },
                                ],
                            })(React.createElement(Input, null))))),
                        React.createElement(Col, { sm: 12 },
                            React.createElement(QuestionYesOrNo, { default: dueDateMatch, question: 'If applicable, does the due date match?', setAnswer: setDueDateMatch }),
                            !dueDateMatch && (React.createElement(Form.Item, { label: 'Are we accepting the PO? Why?', className: 'pl-8' }, getFieldDecorator('reason_due_date_match', {
                                initialValue: SalesOrderStore.order?.reason_due_date_match,
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field of reason due date match is required',
                                    },
                                ],
                            })(React.createElement(Input, null)))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Purchase Order Number', ...formItemLayout }, getFieldDecorator('purchase_order', {
                                initialValue: SalesOrderStore.order?.purchase_order,
                                rules: [{ required: true }],
                            })(React.createElement(Input, { placeholder: 'Enter the PO Number or enter “PrePaid” if you’re uploading a credit card invoice' })))),
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Payment Terms', ...formItemLayout }, getFieldDecorator('payment_terms', {
                                rules: [{ required: true, message: 'Please select a value!' }],
                                initialValue: SalesOrderStore.order?.payment_terms
                                    ? SalesOrderStore.order.payment_terms
                                    : !SalesOrderStore.order?.payment_terms && account?.payment_terms
                                        ? account?.payment_terms
                                        : 'NET30',
                            })(React.createElement(Select, { showSearch: true },
                                React.createElement(Select.Option, { value: "PrePaid" }, "PrePaid"),
                                React.createElement(Select.Option, { value: "NET15" }, "NET15"),
                                React.createElement(Select.Option, { value: "NET30" }, "NET30"),
                                React.createElement(Select.Option, { value: "NET45" }, "NET45"),
                                React.createElement(Select.Option, { value: "NET60" }, "NET60"),
                                React.createElement(Select.Option, { value: "NET75" }, "NET75"),
                                React.createElement(Select.Option, { value: "NET90" }, "NET90"),
                                React.createElement(Select.Option, { value: "CC Upon Shipment" }, "CC Upon Shipment"),
                                React.createElement(Select.Option, { value: "Cash on Delivery - NET00" }, "Cash on Delivery - NET00")))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Additional Files', ...formItemLayout },
                                getFieldDecorator('additional_files', {
                                    initialValue: order?.additional_files?.map(r => {
                                        return {
                                            uid: r.id,
                                            name: r.filename,
                                            status: 'done',
                                            response: {
                                                id: r.id,
                                            },
                                            url: r.file,
                                        };
                                    }),
                                })(React.createElement(UploadFile, { multiple: true, onUpload: data => {
                                        SalesOrderStore.getUnacceptableKeywords(data.response.id, SalesOrderStore.afUnacceptableKeywords);
                                    } })),
                                SalesOrderStore.afUnacceptableKeywords?.map((item, index) => {
                                    const key = `${index}`;
                                    return (React.createElement(Tooltip, { key: key, placement: "topLeft", title: 'Non-accepted keywords found in the document' },
                                        React.createElement(Tag, { color: "red" }, item)));
                                }))),
                        aditionalFilesFromQuote.length > 0 && (React.createElement(Col, { sm: 24 },
                            React.createElement("div", { className: 'mt-4' },
                                React.createElement("div", null,
                                    React.createElement("h2", null, "Additional files from RFQ:")),
                                aditionalFilesFromQuote.map(item => {
                                    return (React.createElement("div", { key: item.id },
                                        React.createElement("a", { onClick: () => {
                                                window.open(item.file, '__blank', 'width=800,height=800,left=200,top=200');
                                            } }, item.filename),
                                        React.createElement(DeleteOutlined, { onClick: () => {
                                                setAditionalFilesFromquote(aditionalFilesFromQuote.filter(obj => obj.id !== item.id));
                                            } })));
                                }))))),
                    React.createElement(Divider, null,
                        React.createElement("div", { className: 'title' }, " Additional Information")),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Add Application Cost?', ...formItemCheckBoxLayout }, getFieldDecorator('add_application_cost', {
                                valuePropName: 'checked',
                                initialValue: SalesOrderStore.order?.add_application_cost || false,
                            })(React.createElement(Checkbox, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Recovered Account?', ...formItemCheckBoxLayout }, getFieldDecorator('recovered_account', {
                                valuePropName: 'checked',
                                initialValue: SalesOrderStore.order?.recovered_account || false,
                            })(React.createElement(Checkbox, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Export Control?', ...formItemCheckBoxLayout }, getFieldDecorator('export_control', {
                                valuePropName: 'checked',
                                initialValue: SalesOrderStore.order?.export_control || false,
                            })(React.createElement(Checkbox, null)))),
                        !getFieldValue('export_control') && (React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Industry', ...formItemLayout }, getFieldDecorator('industry_use', {
                                initialValue: industry_use,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Industry, Use and End Use are required',
                                    },
                                ],
                            })(React.createElement(Industries, null)))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Hot Order?', ...formItemCheckBoxLayout }, getFieldDecorator('hot_order', {
                                valuePropName: 'checked',
                                initialValue: SalesOrderStore.order?.hot_order || false,
                            })(React.createElement(Checkbox, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Hot Ship Date', ...formItemLayout }, getFieldDecorator('hot_ship_date', {
                                initialValue: SalesOrderStore.order && SalesOrderStore.order.hot_ship_date
                                    ? moment(SalesOrderStore.order.hot_ship_date)
                                    : null,
                            })(React.createElement(DatePicker, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Hot Order Notes', ...formItemLayout }, getFieldDecorator('hot_order_notes', {
                                initialValue: SalesOrderStore.order?.hot_order_notes,
                            })(React.createElement(Input.TextArea, { autoComplete: 'off', maxLength: 3000 }))))),
                    React.createElement(Row, { className: 'text-center' },
                        React.createElement(Col, { sm: 24, className: 'row-order mt-5' },
                            React.createElement(Form.Item, { label: 'Shipping Requirements?', ...formItemLayout }, getFieldDecorator('shipping_requirements', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: SalesOrderStore.order?.shipping_requirements
                                    ? SalesOrderStore.order?.shipping_requirements
                                    : SalesOrderStore.default_requirements
                                        ? SalesOrderStore.default_requirements
                                            ?.filter(r => r?.type === 'Shipping Requirements')
                                            .map(r => r.label)
                                        : [],
                            })(React.createElement(ShippingRequirements, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Invoice Email Address', ...formItemLayout }, getFieldDecorator('invoice_email_address', {
                                initialValue: account?.invoice_email_address,
                                rules: [
                                    { type: 'email' },
                                    { required: !account?.invoice_email_address },
                                ],
                            })(React.createElement(Input, { placeholder: account?.invoice_email_address || 'Insert an email' }))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'In House Requirements', ...formItemLayout }, getFieldDecorator('in_house_requirements', {
                                initialValue: order?.in_house_requirements,
                            })(React.createElement(Input.TextArea, { placeholder: account?.in_house_requirements ||
                                    'Insert In House Requirements.' }))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Shipping Account', ...formItemLayout }, getFieldDecorator('shipping_account_number', {
                                initialValue: SalesOrderStore.order?.shipping_account_number
                                    ? SalesOrderStore.order.shipping_account_number
                                    : account?.shipping_account_number
                                        ? account.shipping_account_number
                                        : '555692285',
                            })(React.createElement(Input, { placeholder: account?.shipping_account_number ||
                                    'Insert Shipping Account Number, Please.' }))))),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Shipping Method', ...formItemLayout }, getFieldDecorator('shipping_method', {
                                initialValue: SalesOrderStore.order?.shipping_method
                                    ? SalesOrderStore.order.shipping_method
                                    : account?.shipping_method
                                        ? account.shipping_method
                                        : 'Ground',
                            })(React.createElement(ShippingMethods, { size: 'middle' }))))),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Carrier', ...formItemLayout }, getFieldDecorator('carrier', {
                                initialValue: SalesOrderStore.order?.carrier
                                    ? SalesOrderStore.order.carrier
                                    : account.carrier
                                        ? account.carrier
                                        : 'FedEX',
                            })(React.createElement(ShippingCarriers, { size: "middle" }))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Additional Shipping Notification', ...formItemLayout }, getFieldDecorator('add_shipping_notification_address', {
                                rules: [{ type: 'email' }],
                                initialValue: SalesOrderStore.order?.add_shipping_notification_address
                                    ? SalesOrderStore.order.add_shipping_notification_address
                                    : SalesOrderStore.quote?.related_contact,
                            })(React.createElement(Input, { placeholder: SalesOrderStore.quote?.related_contact || 'Insert an email' }))))),
                    sales_order_requirements !== undefined && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Sales Order Requirements', ...formItemLayout }, getFieldDecorator('additional_requirements', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: sales_order_requirements !== undefined
                                    ? sales_order_requirements
                                    : [],
                            })(React.createElement(AdditionalRequirements, { requirements: SalesOrderStore.requirements_list })))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Notes', ...formItemLayout }, getFieldDecorator('notes', {
                                initialValue: SalesOrderStore.order?.notes && SalesOrderStore.order.notes,
                            })(React.createElement(Input.TextArea, null))))),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { label: 'Owner', help: 'If empty the account owner will be used', ...formItemLayout }, getFieldDecorator('owner', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please select a user!',
                                    },
                                ],
                                initialValue: current_user?.id
                                    ? {
                                        key: current_user.id,
                                        label: current_user.fullname,
                                    }
                                    : {},
                            })(React.createElement(Users, { users: users }))))),
                    SalesOrderStore.getTotalPriceOrder() >= TOTAL_PRICE_SHOW_CHECKLIST && (React.createElement(SalesRepChecklist, { getFieldDecorator: getFieldDecorator, checklistEdit: {}, form: props.form, checklistQuestions: salesRepChecklistQuestions, setChecklistQuestions: setSalesRepChecklistQuestions })))),
                doItNeedARequote && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Alert, { type: 'error', message: React.createElement("div", null,
                            "Looks like something is not matching, please submit a Requote to match the customer requirements",
                            getFieldValue('quotes').map(q => (React.createElement("div", { key: q.key },
                                React.createElement(Button, { type: 'link', loading: isCreatingARequote, key: q.key, onClick: () => {
                                        setIsCreatingARequote(true);
                                        QuoteStore.getRequote(q.key)
                                            .then((r) => {
                                            history.push('/get-quote/?uid=' + r.data.uid);
                                        })
                                            .catch(() => setIsCreatingARequote(false));
                                    } },
                                    "Click here to send (",
                                    q.label,
                                    ")"))))) }))),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24, style: { textAlign: 'center' } },
                        React.createElement(Button, { className: 'mr-4', type: "default", onClick: () => {
                                setOrderStatus('Draft');
                                handleSubmit('Save to Later');
                            }, loading: loading }, "Save for later"),
                        !completeAllSteps && (React.createElement("span", { style: { color: 'red', fontWeight: 900 } }, "Answer the question above to continue")),
                        completeAllSteps && (React.createElement(Button, { type: "primary", loading: loading, className: 'mr-4', onClick: () => {
                                handleSubmit('Send to Review');
                            } }, "Submit to Review"))))))),
        orderItem !== null && showModalBatches && (React.createElement(SalesOrderModalBatches, { batches: SalesOrderBatchStore.batches, order_item: orderItem, setShowModalBatches: setShowModalBatches, showModalBatches: showModalBatches })),
        React.createElement(Drawer, { closable: true, title: "Quoted Parts and Additional Requirements", placement: "left", onClose: () => setShowAddSpecificItems(false), visible: showAddSpecificItems, width: 800 },
            React.createElement(Tabs, { defaultActiveKey: "1" },
                React.createElement(TabPane, { tab: `Parts (${SalesOrderStore.quoted_line_items.length})`, key: "1" },
                    React.createElement(QuotedLineItems, { handleAddItem: async (item) => {
                            const order_item = {
                                id: item.id,
                                name: item.quote_has_product.name,
                                product_description: '',
                                quote_has_product: item.quote_has_product,
                                quantity: item.quantity,
                                list_price: item.unit_price,
                                unit_price: item.unit_price,
                                total: item.total_price,
                                discount: 0,
                                total_after_discount: item.total_price,
                                taxes: 0,
                            };
                            await SalesOrderStore.addOrderItem(order_item);
                        } })),
                React.createElement(TabPane, { tab: `Additional Requirements (${SalesOrderStore.applicationCostItems.length})`, key: "2" },
                    React.createElement(ApplicationCostItems, { handleAdd: async (item) => {
                            const order_item = {
                                id: item.id,
                                name: 'CNC Part',
                                product_description: item.name,
                                quote_has_product: undefined,
                                quantity: 1,
                                list_price: parseFloat(item.price),
                                unit_price: parseFloat(item.price),
                                total: parseFloat(item.price),
                                discount: 0,
                                total_after_discount: parseFloat(item.price),
                                taxes: 0,
                            };
                            await SalesOrderStore.addOrderItem(order_item);
                        } }))))));
});
export default Form.create()(SalesOrderCreateForm);
