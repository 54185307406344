import React from 'react';
import { HistoryChanges } from '@partsbadger/library';
import { Modal } from 'antd';
export const OrderModalHistory = ({ id, handleClose }) => {
    return (React.createElement(Modal, { open: true, title: "Part History", width: 1200, onCancel: handleClose, footer: [], bodyStyle: {
            height: '80vh',
            overflowY: 'scroll',
        } },
        React.createElement(HistoryChanges, { model_name: "master-products", pk: id })));
};
export default OrderModalHistory;
