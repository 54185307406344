import React, { useEffect } from 'react';
import Pill from './pill';
const PillSelect = ({ data, value, onChange }) => {
    const [selectedPills, setSelectedPills] = React.useState(value ?? []);
    const handlePillSelect = (item, exists) => {
        const pills = [...selectedPills];
        if (exists) {
            pills.splice(pills.indexOf(item), 1);
        }
        else {
            pills.push(item);
        }
        setSelectedPills(pills);
        if (onChange) {
            onChange(pills);
        }
    };
    const renderPill = (item, index) => {
        const { label, value } = item;
        const isChecked = selectedPills.includes(value);
        return (React.createElement(Pill, { key: `pill-${index}`, label: label, checked: isChecked, onClick: () => handlePillSelect(value, isChecked) }));
    };
    useEffect(() => {
        if (value && value.length > 0)
            setSelectedPills(value);
    }, [value]);
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: '5px',
        } }, data.map((item, index) => renderPill(item, index))));
};
export default PillSelect;
