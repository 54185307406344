import { makeAutoObservable } from 'mobx';
import { get } from '@partsbadger/utils';
import { message } from 'antd';
class VendorStore {
    constructor() {
        this.productsData = [];
        this.scannedProducts = [];
        makeAutoObservable(this);
    }
    setScannedProducts(data) {
        this.scannedProducts = [...this.scannedProducts, data];
    }
    async setProductData(data) {
        this.productsData = [...this.productsData, data];
    }
    async cleanProductData() {
        this.productsData = [];
    }
    async cleanScannedProducts() {
        this.scannedProducts = [];
    }
    async RemoveProduct(id) {
        this.productsData = this.productsData.filter(item => item.vendorProductZId !== id);
    }
    async updateTheProductFromTheList(id, quantity) {
        const productIndex = this.productsData.findIndex(item => Number(item.vendorProductZId) === Number(id));
        if (productIndex !== undefined && productIndex !== -1) {
            const getQuantityRemaining = this.productsData[productIndex]?.line_items[0]?.quantity_remaining_to_receive;
            if (getQuantityRemaining && quantity <= getQuantityRemaining) {
                const updatedProduct = { ...this.productsData[productIndex], quantity_shipped: quantity };
                this.productsData.splice(productIndex, 1);
                this.productsData = [...this.productsData, updatedProduct];
            }
            else {
                message.error(`You don't have quantity remaining`);
            }
        }
    }
    async getShipmentInfoFromVendorApp(shipmentId) {
        const response = await get(`/vendor/v1/shipments/${shipmentId}/`);
        return response;
    }
    async getShipmentItemInfoFromVendorApp(shipmentId) {
        const response = await get(`/vendor/v1/shipment-items/${shipmentId}/`);
        return response;
    }
}
export default new VendorStore();
