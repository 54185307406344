import React, { useState } from 'react';
import { get } from '@partsbadger/utils';
import AutoCompletedRecord from '../../AutoCompleteRecord/AutoCompleteRecord';
/**
 * Generate url based on the record type, by default pluralize
 * @param record_type
 */
const getEndpointByRecordName = (record_type) => {
    switch (record_type) {
        case 'HoleTolerance':
            return '/staff/hole-tolerances/';
        case 'PartTolerances':
            return '/staff/part-tolerances/';
        case 'SalesOrders':
            return '/staff/sales-orders/';
        case 'Vendors':
            return '/staff/vendors/';
        case 'Finish':
            return '/staff/finishes/';
        case 'RFQ':
            return '/staff/rfq/';
        default:
            return `/staff/${record_type.toLowerCase()}s/`;
    }
};
const GenericSelect = (props) => {
    const [records, setRecords] = useState([]);
    const url = getEndpointByRecordName(props.recordType);
    const getRecords = async (search) => {
        const response = await get(url, {
            params: {
                ...props.filters,
                search: search,
            },
        });
        const results = response.results.map((item) => {
            return { id: item.id, label: item.display_name ?? item?.name ?? item?.id };
        });
        setRecords(results);
    };
    return React.createElement(AutoCompletedRecord, { handleFetch: getRecords, records: records, ...props });
};
export default GenericSelect;
