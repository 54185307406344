import React from 'react';
import { Card, Col, Row } from 'antd';
import SalesRepChecklistQuestion from './SalesRepChecklistQuestion';
const cols = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
};
const SalesRepChecklist = ({ getFieldDecorator, checklistEdit, form, checklistQuestions, setChecklistQuestions, }) => {
    return (React.createElement(Card, { title: 'Big Order Checklist', size: "small", className: "mt-2" },
        React.createElement(Row, { gutter: 4 },
            React.createElement(Col, { ...cols },
                React.createElement("h1", { className: "font-bold" }, "Topic")),
            React.createElement(Col, { ...cols },
                React.createElement("h1", { className: "font-bold" }, "Answer"))),
        checklistQuestions.map((question) => {
            const key = `${question.question_id}`;
            return (React.createElement(SalesRepChecklistQuestion, { key: key, getFieldDecorator: getFieldDecorator, cols: cols, question: question, value: '', onChange: (e) => { }, checklistEdit: checklistEdit, form: form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions }));
        })));
};
export default SalesRepChecklist;
