import { Form } from 'antd';
import { Button, Row, Col, Input, InputNumber, Modal, Tooltip, notification, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { Coatings, Finishes, InputQuantities, Materials, Processes } from '../DropDowns';
import { BulkPrices } from '../BulkPrices';
import QuoteStore from '../../stores/QuoteStore';
import { observer } from 'mobx-react';
import SuggestionField from '../SuggestionField';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import PriceRender from '../PriceRender';
import { QuestionMark } from '../QuestionMark';
import { ErrorRender, FormErrorParser } from '@partsbadger/library';
const min = 1;
const QuoteLineItem = observer(({ index, item, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [bulkPrices, showBulkPrices] = useState(false);
    // const [fieldErrors, setFieldErrors] = useState([]);
    let busy = null;
    const product = item?.product;
    const [form] = Form.useForm();
    const { getFieldValue, setFieldsValue } = form;
    const { needs_manual_quote } = item;
    const { analysis_status } = item?.product;
    const analyzer_finished = analysis_status?.file_2d?.completed && analysis_status?.file_3d?.completed;
    const has_suggestions = product?.attachments?.files_2d?.suggestions?.length > 0;
    // useEffect(() => {
    //     //The initial value displays only the value passed on the first time and to solve this we are using reset fields to display a new value when the props are changed.
    //     form.resetFields();
    // }, [item]);
    const fileType = item.product.has_file_3d && item.product.attachments.file3d.filename
        ? item.product.attachments.file3d.filename.split('.').pop()
        : null;
    useEffect(() => {
        setFieldsValue({
            [fieldName('manufacturing_type')]: fileType?.toLowerCase() === 'stl' ? '3D PRINTING' : item.manufacturing_type,
            [fieldName('material')]: {
                key: product.material_related?.id,
                value: product.material_related?.id,
                label: product.material_related?.name,
            },
            [fieldName('custom_material')]: product.custom_material,
            [fieldName('finishes')]: {
                key: product.finish_related?.id,
                value: product.material_related.id,
                label: product.finish_related?.name,
            },
            [fieldName('custom_finish')]: product?.custom_finish,
            [fieldName('coatings')]: {
                key: product?.coating_related?.id,
                value: product.material_related?.id,
                label: product?.coating_related?.name,
            },
            [fieldName('custom_coating')]: product?.custom_coating,
            [fieldName('quantity')]: item.quantity,
            [fieldName('quantities')]: item.quantities,
        });
    }, [item]);
    const handleSubmit = async () => {
        form.validateFields()
            .then(async (values) => {
            const payload = {
                id: item.id,
                product: {
                    customs: { ...item.product.customs },
                },
                // needs_more_quantities: values[fieldName('needs_more_quantities')] || false,
                needs_more_quantities: QuoteStore.for_staff,
                quantities: values[fieldName('quantities')] || [],
                quantity: values[fieldName('quantity')],
                delivery: values[fieldName('delivery')],
                manufacturing_type: values[fieldName('manufacturing_type')],
                manual_adjustment: values[fieldName('manual_adjustment')],
            };
            payload['target_price'] = values[fieldName('target_price')]
                ? parseFloat(values[fieldName('target_price')])
                : 0;
            payload['product']['material'] = values[fieldName('material')]?.key;
            payload['product']['finishes'] = values[fieldName('finishes')]?.key
                ? [values[fieldName('finishes')]?.key]
                : [];
            payload['product']['coatings'] = values[fieldName('coatings')]?.key
                ? [values[fieldName('coatings')]?.key]
                : [];
            if (values[fieldName('custom_material')] || values[fieldName('custom_material')] === '') {
                payload['product']['custom_material'] = values[fieldName('custom_material')];
            }
            if (values[fieldName('custom_finish')] !== null) {
                payload['product']['custom_finish'] = values[fieldName('custom_finish')];
            }
            if (values[fieldName('custom_coating')] !== null) {
                payload['product']['custom_coating'] = values[fieldName('custom_coating')];
            }
            setLoading(true);
            await QuoteStore.updateLineItem(payload)
                .then(() => {
                setLoading(false);
            })
                .catch(error => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: React.createElement(ErrorRender, { error: error }),
                });
            });
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const fieldName = (name) => {
        return name + '__' + item.id;
    };
    const handleChange = async () => {
        if (busy) {
            clearTimeout(busy);
        }
        busy = setTimeout(handleSubmit, 400);
    };
    const needs_more_quantities = QuoteStore.for_staff && !QuoteStore.isRequote;
    const manufacturing_type = getFieldValue(fieldName('manufacturing_type')) || item?.manufacturing_type || 'CNC';
    return (React.createElement("div", { className: "quote-product-variant" },
        React.createElement(Form, { form: form, layout: "horizontal" },
            React.createElement("div", { className: "quote-product-variant-header" },
                React.createElement("div", { className: "variant-name" }, `Variant #${index + 1}`),
                React.createElement(Tooltip, { placement: "top", title: "Remove Variation" },
                    React.createElement(Button, { className: "remove-variant-btn", title: "Remove Variation", icon: React.createElement(CloseOutlined, null), size: "small", type: "link", onClick: () => {
                            QuoteStore.deleteLineItem(item.uid);
                        } }))),
            fileType?.toLowerCase() === 'stl' && (React.createElement("div", { className: "m-3 md:m-5" },
                React.createElement(Alert
                // type="info"
                , { 
                    // type="info"
                    showIcon: true, message: "File Compatibility Message", description: "STL files (.stl or .STL) can only be used for quoting 3D-Printed parts. If you part requires a different production method, such as CNC or Sheet Metal, please upload a compatible file type.", className: "manual-review-required-message" }))),
            React.createElement("div", { className: "quote-product-variant-details" },
                React.createElement("div", { className: "quote-product-variant-spec" },
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { name: fieldName('manufacturing_type'), label: "Process", rules: [{ required: false, message: 'Required' }], initialValue: item.manufacturing_type },
                            React.createElement(Processes, { onSelect: handleChange, for_staff: QuoteStore?.for_staff, disabled: fileType?.toLowerCase() === 'stl' ? true : false }))),
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { label: "Material" },
                            React.createElement(Form.Item, { name: fieldName('material'), initialValue: {
                                    key: product.material_related.id,
                                    label: product.material_related.name,
                                }, rules: [{ required: false, message: 'Required' }], hasFeedback: !!QuoteStore.errorFields.find(x => x.fieldName === fieldName('material')), validateStatus: QuoteStore.errorFields.find(x => x.fieldName === fieldName('material'))
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(x => x.fieldName === fieldName('material'))
                                    ? 'Material Required'
                                    : null },
                                React.createElement(Materials, { onSelect: handleChange, manufacturing_type: manufacturing_type, materials: QuoteStore.materials, for_staff: QuoteStore?.for_staff })),
                            product.material_related?.name === 'Custom' ? (React.createElement(Form.Item, { name: fieldName('custom_material'), rules: [{ required: false, message: 'Required' }], initialValue: product.custom_material, hasFeedback: true, validateStatus: QuoteStore.errorFields.find(x => x.fieldName === fieldName('custom_material'))
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(x => x.fieldName === fieldName('custom_material'))
                                    ? 'Custom Material Name Required'
                                    : null },
                                React.createElement(Input, { size: "small", onBlur: handleChange, placeholder: "Enter Custom Material" }))) : null)),
                    !product.material && has_suggestions ? (React.createElement(SuggestionField, { suggestions: product.attachments.files_2d?.suggestions
                            ? product.attachments.files_2d.suggestions
                                .filter(s => s.suggested_material?.id)
                                .map(s => {
                                return {
                                    id: s.suggested_material?.id ?? 0,
                                    name: s.suggested_material?.name ?? '',
                                    thumbnail: s.cropped_image?.file ?? '',
                                    version: s.version ?? '1.0',
                                };
                            })
                            : [], suggested_text: product.attachments.files_2d?.suggested_materials_text, suggestionType: "Material", for_staff: QuoteStore?.for_staff, handleClick: async (key, label) => {
                            const getValue = {
                                key: key,
                                label: label,
                            };
                            setFieldsValue({
                                [fieldName('material')]: getValue,
                            });
                            handleChange();
                        } })) : null,
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { label: "Surface Finish" },
                            React.createElement(Form.Item, { name: fieldName('finishes'), initialValue: {
                                    key: product.finish_related?.id,
                                    label: product.finish_related?.name,
                                }, rules: [{ required: false, message: 'Please select a value' }], hasFeedback: !!QuoteStore.errorFields.find(x => x.fieldName === fieldName('finishes')), validateStatus: QuoteStore.errorFields.find(x => x.fieldName === fieldName('finishes'))
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(x => x.fieldName === fieldName('finishes'))
                                    ? 'Surface Finish Required'
                                    : null },
                                React.createElement(Finishes, { finishes: QuoteStore.finishes, onSelect: handleChange, for_staff: QuoteStore?.for_staff })),
                            product.finish_related?.name === 'Custom' ? (React.createElement(Form.Item, { name: fieldName('custom_finish'), rules: [{ required: false, message: 'Please insert a value' }], initialValue: product?.custom_finish, hasFeedback: true, validateStatus: QuoteStore.errorFields.find(x => x.fieldName === fieldName('custom_finish'))
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(x => x.fieldName === fieldName('custom_finish'))
                                    ? 'Custom Surface Finish Name Required'
                                    : null },
                                React.createElement(Input, { size: "small", onBlur: handleChange, placeholder: "Enter Custom Surface Finish" }))) : null)),
                    product.finishes.length < 1 && has_suggestions ? (React.createElement(SuggestionField, { suggestions: product.attachments.files_2d?.suggestions
                            ? product.attachments.files_2d.suggestions
                                .slice()
                                .sort(s => (s.suggested_finish?.name === 'Deburr' ? -1 : 1))
                                .sort(s => (s.suggested_finish?.name === 'None' ? -1 : 1))
                                .filter(s => s.suggested_finish?.id)
                                .map(s => {
                                return {
                                    id: s.suggested_finish?.id ?? 0,
                                    name: s.suggested_finish?.name ?? '',
                                    thumbnail: s.cropped_image?.file ?? '',
                                    version: s.version ?? '1',
                                };
                            })
                            : [], suggested_text: product.attachments.files_2d?.suggested_finishes_text, suggestionType: "Finish", for_staff: QuoteStore?.for_staff, handleClick: async (key, label) => {
                            const getValue = {
                                key: key,
                                label: label,
                            };
                            setFieldsValue({
                                [fieldName('finishes')]: getValue,
                            });
                            handleChange();
                        } })) : null,
                    React.createElement("div", { className: "variant-spec-dropdown-wrapper" },
                        React.createElement(Form.Item, { label: "Coating" },
                            React.createElement(Form.Item, { name: fieldName('coatings'), initialValue: {
                                    key: product?.coating_related?.id,
                                    label: product?.coating_related?.name,
                                }, rules: [{ required: false, message: 'Please select a value' }], hasFeedback: !!QuoteStore.errorFields.find(x => x.fieldName === fieldName('coatings')), validateStatus: QuoteStore.errorFields.find(x => x.fieldName === fieldName('coatings'))
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(x => x.fieldName === fieldName('coatings'))
                                    ? 'Coating Required'
                                    : null },
                                React.createElement(Coatings, { coatings: QuoteStore.coatings, onSelect: handleChange, for_staff: QuoteStore?.for_staff })),
                            product.coating_related?.name === 'Custom' ? (React.createElement(Form.Item, { name: fieldName('custom_coating'), initialValue: product?.custom_coating, rules: [{ required: false, message: 'Please insert a value' }], hasFeedback: true, validateStatus: QuoteStore.errorFields.find(x => x.fieldName === fieldName('custom_coating'))
                                    ? 'error'
                                    : undefined, help: QuoteStore.errorFields.find(x => x.fieldName === fieldName('custom_coating'))
                                    ? 'Custom Coating Name Required'
                                    : null },
                                React.createElement(Input, { size: "small", onBlur: handleChange, placeholder: "Enter Custom Coating" }))) : null)),
                    React.createElement("div", { className: "d-block" }, product.coatings?.length < 1 && has_suggestions ? (React.createElement(SuggestionField, { suggestions: product.attachments.files_2d?.suggestions
                            ? product.attachments.files_2d.suggestions
                                .slice()
                                .sort(s => (s.suggested_coating?.name === 'Deburr' ? -1 : 1))
                                .sort(s => (s.suggested_coating?.name === 'None' ? -1 : 1))
                                .filter(s => s.suggested_coating?.id)
                                .map(s => {
                                return {
                                    id: s.suggested_coating?.id ?? 0,
                                    name: s.suggested_coating?.name ?? '',
                                    thumbnail: s.cropped_image?.file ?? '',
                                    version: s.version ?? '1.0',
                                };
                            })
                            : [], suggested_text: product.attachments.files_2d?.suggested_coatings_text, suggestionType: "Coating", for_staff: QuoteStore?.for_staff, handleClick: async (key, label) => {
                            const getValue = {
                                key: key,
                                label: label,
                            };
                            setFieldsValue({
                                [fieldName('coatings')]: getValue,
                            });
                            handleChange();
                        } })) : null)),
                React.createElement("div", { className: "quote-product-variant-qty-price" },
                    React.createElement("div", null,
                        React.createElement(PriceRender, { unit_price: item.unit_price, total_price: item.total_price, needs_manual_quote: needs_manual_quote, is_analyzing: !analyzer_finished, loading: loading })),
                    QuoteStore.for_staff ? (React.createElement(Row, { gutter: [8, 0], style: { maxWidth: '480px' } },
                        React.createElement(Col, { span: 12 }, QuoteStore.isRequote ? (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Single Quantity' },
                            React.createElement(Form.Item, { name: fieldName('quantity') },
                                React.createElement(InputNumber, { min: min, step: 1, controls: false, onBlur: handleChange, defaultValue: item.quantity })))) : (React.createElement(React.Fragment, null,
                            React.createElement(Form.Item, { noStyle: true, shouldUpdate: (prevValues, curValues) => prevValues.quantities !== curValues.quantities }, ({ getFieldValue }) => getFieldValue(fieldName('quantities'))?.length >= 1 ? (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Multiple Quantities' },
                                React.createElement(Form.Item, { name: fieldName('quantities'), rules: [
                                        {
                                            required: false,
                                            message: 'Please insert a value',
                                        },
                                    ], initialValue: item.quantities || [], help: React.createElement("div", null, !needs_manual_quote &&
                                        QuoteStore.quote?.email &&
                                        !QuoteStore.isRequote && (React.createElement("span", { className: 'link', onClick: () => showBulkPrices(true) }, "Show list prices"))), noStyle: true },
                                    React.createElement(InputQuantities, { onBlur: handleChange, style: { width: '100%' } })),
                                React.createElement(Button, { type: 'link', onClick: () => {
                                        setFieldsValue({
                                            [fieldName('quantities')]: [],
                                        });
                                        handleChange();
                                    } },
                                    "or Quote Single Quantity?",
                                    React.createElement(QuestionMark, { placement: "right" }, "Quote a single quantity and get an instant price")))) : (React.createElement(Form.Item, { labelCol: { span: 24 }, label: 'Quote Single Quantity' },
                                React.createElement(Form.Item, { name: fieldName('quantity'), noStyle: true },
                                    React.createElement(InputNumber, { min: min, step: 1, controls: false, onBlur: handleChange, defaultValue: item.quantity, style: { width: '100%' } })),
                                React.createElement(Button, { type: 'link', onClick: () => {
                                        setFieldsValue({
                                            [fieldName('quantities')]: [1],
                                        });
                                        handleChange();
                                    } },
                                    "or Quote Multiple Quantities?",
                                    ' ',
                                    React.createElement(QuestionMark, { placement: "right" }, "Use this option to request prices of different quantity")))))))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(Form.Item, { name: fieldName('target_price'), label: index === 0 ? 'Target Price' : null, initialValue: item.target_price, labelCol: { span: 24 } },
                                React.createElement(InputNumber, { onBlur: handleChange, precision: 2, style: { width: '100%' } }))))) : (React.createElement("div", { className: "quantity-selector d-flex flex-row align-items-center" },
                        React.createElement("label", { htmlFor: "quantity", className: "mr-2" }, "Quantity"),
                        React.createElement("div", { className: "button-group" },
                            React.createElement(Button, { size: "small", className: "minus-btn", icon: React.createElement(MinusOutlined, null), onClick: () => {
                                    setFieldsValue({
                                        [fieldName('quantity')]: Number(item.quantity) - 1,
                                    });
                                    handleSubmit();
                                }, disabled: loading || Number(getFieldValue(fieldName('quantity'))) === min }),
                            React.createElement(Form.Item, { name: fieldName('quantity'), initialValue: item.quantity, noStyle: true },
                                React.createElement(InputNumber, { min: min, step: 1, controls: false, size: "small", onChange: value => {
                                        setFieldsValue({
                                            [fieldName('quantity')]: value,
                                        });
                                    }, onBlur: handleChange, disabled: loading })),
                            React.createElement(Button, { size: "small", className: "plus-btn", icon: React.createElement(PlusOutlined, null), onClick: () => {
                                    setFieldsValue({
                                        [fieldName('quantity')]: Number(item.quantity) + 1,
                                    });
                                    handleSubmit();
                                }, disabled: loading }))))),
                bulkPrices ? (React.createElement(Modal, { open: true, onCancel: () => {
                        showBulkPrices(false);
                    }, footer: [] },
                    React.createElement(BulkPrices, { item: item, handleSelect: quantity => {
                            setFieldsValue({
                                [fieldName('quantity')]: quantity,
                            });
                            showBulkPrices(false);
                            handleChange();
                        } }))) : null))));
});
export default QuoteLineItem;
