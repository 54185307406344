import React, { useState } from 'react';
import { Spin, Tag, Typography, Image } from 'antd';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import QuoteStore from '../stores/QuoteStore';
import * as api from '@partsbadger/utils';
import { Upload3dFile } from './UploadParts';
import { observer } from 'mobx-react';
import ThreeDViewerModal from './ThreeDViewerModal';
import FeatherIcon from 'feather-icons-react';
const { Text } = Typography;
const IconPreview = () => {
    return (React.createElement("div", { className: "d-flex flex-column align-items-center text-center w-100" },
        React.createElement(FeatherIcon, { icon: "file", size: "72", strokeWidth: "0.5" }),
        React.createElement("div", null, "2D Drawing")));
};
export const PartPreview = observer(({ item }) => {
    const product = item.product;
    const [url, setUrl] = useState(null);
    const [modelLoading, setModelLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const open3dViewer = async () => {
        setModalVisible(true);
        setModelLoading(true);
        try {
            const data = await api.get(`/customer/quote/${QuoteStore.quote_uid}/threed-signed-url/${item.uid}`);
            setUrl(data.url);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setModelLoading(false);
        }
    };
    const open2dViewer = async (url) => {
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    const { analysis_status, has_file_3d, has_file_2d, thumbnail, attachments } = product;
    const analyzer_3d_finished = analysis_status.file_3d.completed;
    const analyzer_3d_success = analysis_status?.file_3d?.status === 'SUCCESS';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "part-preview-box" }, !analyzer_3d_finished ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, null), spinning: !analyzer_3d_finished, tip: `Analyzing Geometry...` })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "d-flex flex-column align-items-center justify-content-center text-center" }, thumbnail ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "p-2 w-100" }, item.product.has_file_3d ? (React.createElement(Image, { preview: false, className: "thumbnail-3d-file", alt: attachments?.file3d?.filename, src: thumbnail, onClick: () => {
                        open3dViewer();
                    } })) : item.product.has_file_2d ? (React.createElement(Image, { preview: false, className: "thumbnail-2d-file", src: thumbnail, onClick: () => {
                        open2dViewer(attachments.files_2d.file);
                    } })) : null))) : null),
            !thumbnail && item.product.has_file_3d && (React.createElement("div", null,
                React.createElement(Tag, { color: "blue", className: "my-4 mr-auto ml-auto", icon: React.createElement(WarningOutlined, null) }, "3D Preview Unavailable"))),
            !thumbnail && item.product.has_file_3d && analyzer_3d_success && (React.createElement("div", null,
                React.createElement(Spin, { size: "small", style: { color: 'black' }, indicator: React.createElement(LoadingOutlined, null), spinning: item.product.has_file_3d, tip: `Generating Preview...` }))),
            analyzer_3d_finished && !analyzer_3d_success && product.has_file_3d ? (React.createElement("div", { className: "d-flex flex-column align-items-center justify-content-around" },
                React.createElement(Text, { type: "danger", strong: true, className: "mr-auto ml-auto mt-0 font-size-sm" },
                    React.createElement(WarningOutlined, null),
                    " 3D file could not be analyzed"),
                React.createElement("div", { className: "mb-3" },
                    React.createElement(Upload3dFile, { item: item })))) : null,
            !has_file_3d && !has_file_2d && (React.createElement(Text, { type: "danger", strong: true, className: "mr-auto ml-auto mt-3 font-size-sm" },
                React.createElement(WarningOutlined, null),
                " No Part Files"))))),
        !has_file_3d && !QuoteStore.isRequote && (React.createElement("div", { className: "d-flex justify-content-around py-3 text-center" },
            React.createElement(Upload3dFile, { item: item }))),
        QuoteStore.isRequote && item.master_product_details.editable && (React.createElement("div", { className: "d-flex justify-content-around py-3 text-center" },
            React.createElement(Upload3dFile, { item: item }))),
        React.createElement(ThreeDViewerModal, { url: url, visible: modalVisible, onClose: () => setModalVisible(false), loading: modelLoading })));
});
