import React, { useState } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import Suggestion from '../Suggestion';
export const Materials = observer((props) => {
    const manufacturing_type = props?.manufacturing_type;
    const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);
    let data = props.materials;
    if (manufacturing_type) {
        data = data.filter(item => {
            return item.manufacturing_types.includes(manufacturing_type);
        });
    }
    const custom = data.find(item => item.name === 'Custom');
    // Exclude custom from list
    const items = data.filter(item => item.id !== custom?.id);
    const materials_grouped = _.groupBy(items, (item) => item.material_type);
    const menu = _.map(materials_grouped, (items, key) => {
        const label = key !== 'null' ? key : 'Uncategorized';
        return (React.createElement(Select.OptGroup, { key: key, label: label }, items.map(material => (React.createElement(Select.Option, { key: material.id }, material.name)))));
    });
    const EmptyTag = (React.createElement("div", { className: "flex flex-no-wrap p-2 cursor-pointer", onClick: () => {
            if (custom && props.onChange) {
                props.onChange({
                    key: custom.id.toString(),
                    label: custom.name,
                }, []);
                setIsDropDownMenuOpen(false);
            }
        } },
        React.createElement("span", { className: "flex flex-row items-center cursor-pointer justify-center" },
            "Can't find your material? please select ",
            React.createElement("div", { className: "ml-2 link" }, "Custom"))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { "data-testid": "material-test", showSearch: true, labelInValue: true, size: props?.for_staff ? 'middle' : 'small', bordered: true, placeholder: "Select", allowClear: false, filterOption: (input, option) => {
                try {
                    if (!option.key) {
                        // Filter by group label
                        return option.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                    }
                    return option.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                }
                catch (e) {
                    return false;
                }
            }, notFoundContent: EmptyTag, dropdownMatchSelectWidth: false, style: { width: '100%' }, ...props, open: isDropDownMenuOpen, onDropdownVisibleChange: visible => setIsDropDownMenuOpen(visible), dropdownRender: menu => menu },
            custom && custom?.id > 0 && (React.createElement(Select.OptGroup, { label: 'Custom' },
                React.createElement(Select.Option, { key: custom.id }, custom.name))),
            menu),
        !props.value?.label && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, props.suggestions?.map(suggestion => (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: () => {
                const val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    props.onSelect(val, val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })))))));
});
