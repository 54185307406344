import React from 'react';
import { Select } from 'antd';
const ShippingDestination = props => {
    return (React.createElement(Select, { showSearch: true, style: {
            width: '100%',
        }, size: 'small', ...props },
        React.createElement(Select.Option, { value: "E" }, "Express (E)"),
        React.createElement(Select.Option, { value: "G" }, "Ground (G)"),
        React.createElement(Select.Option, { value: "U" }, "UPS (U)")));
};
export default ShippingDestination;
