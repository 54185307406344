const SAFE_MATERIALS = [
    '6061',
    '6061-T6',
    '304',
    '1018',
    '17-4 PH',
    '303',
    '4140',
    '316',
    'A2',
    'Black Delrin',
    'C360 Brass',
    'White Delrin',
    '6061-T651',
    'A36',
    'O1',
    '1045',
    'D2',
    'C172 Copper',
    '440C',
    'PC - Clear',
    'Black ABS',
    '7075-T6',
    'PTFE - Teflon',
    '7075',
    'Black Acetal',
    'MIC-6',
    '416',
    '4130',
    'Titanium Grade 2',
    'Nylon 101',
    '1020',
    'UHMW-White',
    '2024-T351',
    'Acetal',
    '7075-T7351',
    'C101 Copper',
    '316L',
    'Radel PPSU',
    'HDPE',
    'C110 Copper',
    'Nylon 6',
    'UHMW-Black',
    'PVC',
    'C630 Bronze',
    '6060',
];
export { SAFE_MATERIALS };
