import React, { useEffect, useState } from 'react';
import { Drawer, notification, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import { DownloadFile, get } from '@partsbadger/utils';
export const QuotePreview = ({ quote_id, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const [fileURL, setFileURL] = useState('');
    useEffect(() => {
        setLoading(true);
        get(`/staff/quotes/${quote_id}/export/`, { responseType: 'blob' }).then(async (response) => {
            const data = response;
            if (isMobile) {
                DownloadFile(data, `quote-${quote_id}.pdf`);
                handleClose();
                notification.success({ message: 'Document downloaded' });
            }
            else {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                setLoading(false);
                setFileURL(fileURL);
            }
        });
    }, [quote_id]);
    if (isMobile) {
        return null;
    }
    return (React.createElement(Drawer, { title: "Preview", width: '80%', closable: true, visible: true, placement: 'left', onClose: handleClose },
        React.createElement(Spin, { spinning: loading },
            React.createElement("iframe", { title: 'Quote Preview', src: fileURL, frameBorder: "0", height: window.innerHeight, width: "100%" }))));
};
