import { Button, Popover, Image } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import * as React from 'react';
const Suggestion = (props) => {
    return (React.createElement("div", { className: 'suggestion flex flex-row justify-start items-center' },
        React.createElement(Button, { className: " space-x-0.5 text-xs mt-0 mr-2", type: "dashed", size: "small", onClick: props.handleClick },
            React.createElement("div", { className: 'font-normal', title: `${props.title}` },
                props.title.substring(0, 8),
                "...")),
        props.thumbnail && (React.createElement(Popover, { title: React.createElement("div", { className: 'flex flex-row justify-start' },
                React.createElement("div", { className: 'font-bold' }, "Suggested Option: "),
                React.createElement("div", { className: 'font-normal ml-2' }, props.title)), placement: "right", content: React.createElement("div", { className: 'border', style: {
                    maxWidth: 500,
                    background: '#d4d4d4',
                    textAlign: 'center',
                } },
                React.createElement(Image, { preview: false, style: {
                        maxWidth: '90%',
                        margin: 'auto',
                    }, src: props.thumbnail })) },
            React.createElement(ZoomInOutlined, null)))));
};
export default Suggestion;
