import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber } from 'antd';
import { QuoteStore } from '../../Stores';
const WholeQuoteAdjustmentForm = ({ quote }) => {
    const [value, setValue] = useState(100);
    const [loading, setLoading] = useState(false);
    const handleUpdate = () => {
        setLoading(true);
        //@ts-ignore
        QuoteStore.updateQuote(QuoteStore.quote?.id, {
            whole_quote_adjust: value,
        })
            .then(() => {
            QuoteStore.getLineItems(quote?.id);
        })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (parseFloat(quote.whole_quote_adjust) > 0) {
            setValue(parseFloat(quote.whole_quote_adjust));
        }
    }, [QuoteStore.quote?.whole_quote_adjust]);
    return (React.createElement(Form, null,
        React.createElement(Form.Item, { label: 'Whole Quote Adjustment' },
            React.createElement(InputNumber, { placeholder: '100', value: value, onChange: v => v && setValue(v), onPressEnter: handleUpdate, disabled: !quote.editable }),
            React.createElement(Button, { loading: loading, onClick: handleUpdate, disabled: !quote.editable }, "Update"))));
};
export default WholeQuoteAdjustmentForm;
