import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, DatePicker, TimePicker, Button, message } from 'antd';
import moment from 'moment';
import SalesOrderStore from '../../Stores/SalesOrderStore';
const ModalMeeting = (props) => {
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const date = moment(values.date).format('YYYY-MM-DD');
                const time = moment(values.time).format('HH:mm');
                const datetime = `${date} ${time}`;
                const params = {
                    datetime,
                    type: props.type_review,
                };
                setLoading(true);
                SalesOrderStore.updateMeetingTime(props.sales_order_id, params)
                    .then(response => {
                    message.success('Meeting notification sent', 3);
                    props.handleClose();
                    props.handleSubmit();
                })
                    .catch(error => {
                    message.error(error.message);
                })
                    .finally(() => {
                    setLoading(false);
                });
            }
        });
    };
    const disabledDate = (current) => {
        // Can not select days before today
        return current && current < moment().add('-1', 'days').endOf('day');
    };
    return (React.createElement(Modal, { title: "Set Meeting Datetime", visible: true, onCancel: props.handleClose, footer: null },
        React.createElement(Form, { className: "text-center", layout: "inline", onSubmit: handleSubmit },
            React.createElement(Form.Item, { label: "Date" }, getFieldDecorator('date', {
                rules: [{ required: true, message: 'Please select a date!' }],
                initialValue: '',
            })(React.createElement(DatePicker, { disabledDate: disabledDate }))),
            React.createElement(Form.Item, { label: "Time" }, getFieldDecorator('time', {
                rules: [{ required: true, message: 'Please select a hour!' }],
                initialValue: '',
            })(React.createElement(TimePicker, { format: "HH:mm" }))),
            React.createElement("div", { className: "mt-4" },
                React.createElement(Button, { type: "primary", htmlType: "submit", loading: loading }, "Send Notification")))));
};
export default Form.create()(ModalMeeting);
