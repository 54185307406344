import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider, Radio, Select } from 'antd';
import { in_to_mm, mm_to_in, normalize_number } from '@partsbadger/utils';
import { InputCalculator } from './index';
const PartDimensions = (props) => {
    const [converted, setConverted] = useState('');
    const [original, setOriginal] = useState('');
    const { getFieldDecorator, getFieldValue } = props.form;
    const { edit } = props;
    const { Option } = Select;
    useEffect(() => {
        setOriginal(`${props.length}${props.unit} x ${props.width}${props.unit} x ${props.height}${props.unit}`);
        if (props.unit === 'MM') {
            setConverted(`${mm_to_in(props.length)}in x ${mm_to_in(props.width)}in x ${mm_to_in(props.height)}in`);
        }
        else {
            setConverted(`${in_to_mm(props.length)}mm x ${in_to_mm(props.width)}mm x ${in_to_mm(props.height)}mm`);
        }
    }, [props.unit, props.length, props.width, props.height]);
    const validateNumberGreaterThan0 = (rule, value, callback) => {
        if (getFieldValue('quotable') && (isNaN(value) || value == 0 || !value)) {
            callback('Required');
        }
        callback();
    };
    return (React.createElement("div", { className: "flex flex-row justify-center" }, !edit ? (React.createElement("div", { style: {
            textTransform: 'lowercase',
        }, className: "m-0 xl:ml-2 xl:m-0" },
        original,
        React.createElement(Divider, { type: 'vertical' }),
        converted,
        React.createElement(Divider, { type: 'vertical' }))) : (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { label: 'Length:', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('length', {
            rules: [
                { required: true, message: 'Please input a value!' },
                { validator: validateNumberGreaterThan0 },
            ],
            initialValue: normalize_number(props.length),
        })(React.createElement(InputCalculator, null))),
        React.createElement(Form.Item, { label: 'Width: ', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('width', {
            rules: [
                {
                    required: true,
                    message: 'Required!',
                },
                { validator: validateNumberGreaterThan0 },
            ],
            initialValue: normalize_number(props.width),
        })(React.createElement(InputCalculator, null))),
        React.createElement(Form.Item, { label: 'Height', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('height', {
            rules: [
                {
                    required: true,
                    message: 'Required!',
                },
                { validator: validateNumberGreaterThan0 },
            ],
            initialValue: normalize_number(props.height),
        })(React.createElement(InputCalculator, null))),
        React.createElement(Form.Item, { label: 'Unit', className: "flex flex-row font-bold ml-2 m-0" }, getFieldDecorator('unit', {
            rules: [{ required: true, message: 'Please select a value!' }],
            initialValue: props.unit || 'IN',
        })(React.createElement(Radio.Group, { size: 'small' },
            React.createElement(Radio, { value: 'IN' }, "IN"),
            React.createElement(Radio, { value: 'MM' }, "MM"))))))));
};
export default Form.create()(PartDimensions);
