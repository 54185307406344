import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Spin } from 'antd';
import React from 'react';
import QuoteStore from '../stores/QuoteStore';
import { Price } from '@partsbadger/library';
const PriceRender = ({ unit_price, total_price, needs_manual_quote, is_analyzing, loading, }) => {
    if (unit_price && total_price && !needs_manual_quote && !QuoteStore.quote?.email) {
        return null;
    }
    return (React.createElement("div", { className: "price-render" },
        React.createElement(Spin, { spinning: loading, indicator: React.createElement(LoadingOutlined, null) }, (unit_price && total_price && !needs_manual_quote) || QuoteStore.isRequote ? (React.createElement("div", { className: "d-flex flex-column text-right" },
            React.createElement("span", { className: "product-price-unit" },
                React.createElement(Price, { value: unit_price, loading: loading }),
                " ea."),
            React.createElement("span", { className: "product-price-total" },
                React.createElement(Price, { value: total_price, loading: loading })))) : is_analyzing ? (React.createElement(React.Fragment, null,
            React.createElement(LoadingOutlined, { className: "text-primary" }),
            " Analyzing Files")) : (React.createElement(Alert, { showIcon: false, message: "Manual Quote Required", description: 'This part has unusual features or specifications that require a manual review by one of our engineers.', className: "manual-review-required-message" })))));
};
export default PriceRender;
