import React, { useState } from 'react';
import { Button, Card, Dropdown, Empty, InputNumber, List, Menu, notification, Tag } from 'antd';
import IntakeStore from '../../Stores/IntakeStore';
import PurchaseOrderStore from '../../Stores/PurchaseOrderStore';
import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { PreviousIntakePurchaseOrder } from '../../Componets';
import { observer } from 'mobx-react';
export const PurchaseOrderLineItems = observer(({ onUpdate }) => {
    const history = useHistory();
    const [selectedValue, setSelectedValue] = useState({});
    const [loading, setIsLoading] = useState(false);
    function handleChange(id, value) {
        const _selected = selectedValue;
        _selected[id] = value;
        setSelectedValue({
            ..._selected,
        });
    }
    function handleSubmit(value) {
        setIsLoading(true);
        const payload = Object.keys(selectedValue)
            .filter(key => selectedValue[key] != undefined)
            .map(key => {
            return {
                purchase_order_item: key,
                quantity: selectedValue[key] ?? 0,
            };
        });
        if (PurchaseOrderStore.PurchaseOrder?.id) {
            PurchaseOrderStore.intake(PurchaseOrderStore.PurchaseOrder.id, payload)
                .then(() => {
                notification.success({
                    message: 'OK',
                    description: 'Intake successfully',
                });
                if (PurchaseOrderStore.PurchaseOrder?.id)
                    onUpdate(PurchaseOrderStore.PurchaseOrder.id);
                setSelectedValue({});
            })
                .finally(() => setIsLoading(false));
        }
    }
    function handleInTakeOrOutTakeAll(value) {
        const _intakeAll = {};
        PurchaseOrderStore.PurchaseOrder.line_items.map(i => {
            if (i.id) {
                _intakeAll[i.id] = i.remaining_quantity;
            }
        });
        setSelectedValue({
            ..._intakeAll,
        });
    }
    const cancelLineItem = async (po_item_id, po_id) => {
        await PurchaseOrderStore.cancel_line_item(po_id, { purchase_order_item: po_item_id });
        onUpdate(po_id);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { title: "Purchase Order:", className: "flex flex-col w-full justify-center", bordered: false, style: { overflow: 'auto' } },
            React.createElement("div", { className: 'm-4 text-center' },
                React.createElement(Button, { onClick: handleInTakeOrOutTakeAll },
                    IntakeStore.receiveOrShip === 'receive' ? 'Intake' : 'Outtake',
                    " All Remaining"),
                React.createElement(Button, { className: 'ml-2', type: 'primary', onClick: handleSubmit, loading: loading }, "Submit")),
            React.createElement("div", { key: PurchaseOrderStore.PurchaseOrder.id, className: "flex flex-col xl:flex-row" },
                React.createElement(Card, { title: "PO Information:", className: "mr-5 mb-5", style: { width: '25vw' } },
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Name"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.name)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Vendor"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder &&
                            typeof PurchaseOrderStore.PurchaseOrder.vendor === 'object'
                            ? PurchaseOrderStore.PurchaseOrder.vendor?.name
                            : '')),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Requisition number"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.requisition_number)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Carrier"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.carrier)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Ship method"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.ship_method)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Due date"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.due_date)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "PB point of contact"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.pb_point_of_contact)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Sales order"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder &&
                            typeof PurchaseOrderStore.PurchaseOrder?.sales_order === 'object'
                            ? PurchaseOrderStore.PurchaseOrder?.sales_order?.name
                            : '')),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Category"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.category)),
                    React.createElement("div", null,
                        React.createElement("span", { className: "font-bold" }, "Payment terms"),
                        React.createElement("span", { className: "ml-1" }, PurchaseOrderStore.PurchaseOrder.payment_terms)),
                    React.createElement("div", { className: "mt-2" },
                        React.createElement("span", { className: "font-bold" },
                            React.createElement(Tag, { color: 'green', style: { minWidth: '100px', maxWidth: '100%', whiteSpace: 'normal' } }, "Form 33 Receiving Record Book. Authorized by Brandon Spenneberg. Ops Manager 11/01/2020")))),
                React.createElement(List, { header: React.createElement("div", { className: "flex flex-row justify-between font-extrabold text-base" },
                        React.createElement("div", { className: "w-40 text-center" }, "Name"),
                        React.createElement("div", { className: "w-40 text-center" }, "Status"),
                        React.createElement("div", { className: "w-48 text-center" }, "Quantity"),
                        React.createElement("div", { className: "w-48 text-center" }, "Quantity Remaining"),
                        React.createElement("div", { className: "w-40 text-center" }, "Quantity Received"),
                        React.createElement("div", { className: "w-40 text-center" }, "Actions")), style: { width: '80vw' }, bordered: true, dataSource: PurchaseOrderStore.PurchaseOrder.line_items, itemLayout: "vertical", className: "mb-auto", locale: {
                        emptyText: (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement("span", { style: { color: '#000' } }, "Sorry... This purchase order doesn't have line items.") })),
                    }, renderItem: (item) => (React.createElement(List.Item, { key: item?.id, className: "flex flex-row justify-between" },
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "w-40 text-left inline-grid" },
                                React.createElement("div", null,
                                    React.createElement("strong", null, item.name)),
                                React.createElement("div", null, item.description)),
                            React.createElement("div", { className: "w-40 text-center" }, item.status),
                            React.createElement("div", { className: "w-48 text-center" }, item.quantity),
                            React.createElement("div", { className: "w-48 text-center" }, item.remaining_quantity),
                            React.createElement("div", { className: "w-48 text-center" }, item.id && item.status !== 'Canceled' && (React.createElement(React.Fragment, null,
                                React.createElement(InputNumber, { value: selectedValue[item.id] ?? undefined, onChange: value => {
                                        if (item.id && value) {
                                            handleChange(item.id, value);
                                        }
                                    } })))),
                            React.createElement("div", { className: "w-48 text-center" },
                                React.createElement("div", { className: 'flex flex-col' },
                                    React.createElement(Dropdown, { overlay: React.createElement(Menu, null,
                                            React.createElement(Menu.Item, { onClick: () => {
                                                    history.push(`/purchase-orders/${PurchaseOrderStore.PurchaseOrder.id}/`);
                                                } },
                                                React.createElement(EditOutlined, null),
                                                " Edit Item"),
                                            React.createElement(Menu.Item, { onClick: () => {
                                                    if (item.id && PurchaseOrderStore.PurchaseOrder.id)
                                                        cancelLineItem(item.id, PurchaseOrderStore.PurchaseOrder.id);
                                                } },
                                                React.createElement(CloseOutlined, null),
                                                " Cancel Item")) },
                                        React.createElement("span", { className: 'link' },
                                            "Actions ",
                                            React.createElement(DownOutlined, null)))))))) })),
            React.createElement("div", { className: "previous-history" },
                React.createElement(PreviousIntakePurchaseOrder, { dataSource: PurchaseOrderStore.previousIntakes })))));
});
export default PurchaseOrderLineItems;
