import { action, observable, computed, makeObservable } from 'mobx';
import { post } from '@partsbadger/utils';
class Lead {
    constructor() {
        this.isLoading = true;
        this.lead = null;
        this.account = null;
        this.zoholead = [];
        makeObservable(this, {
            // Observables
            lead: observable,
            account: observable,
            zoholead: observable,
            // Actions
            searchLeads: action,
            setLead: action,
            setAccount: action,
            //Computed
            IsLead: computed,
        });
    }
    async searchLeads(params) {
        // Search for leads by email
        this.isLoading = true;
        const data = await post(`/staff/leads/search/`, {
            email: params.search,
        });
        this.isLoading = false;
        this.zoholead = data;
    }
    get IsLead() {
        return !!this.lead?.lead_zoho_id;
    }
    async setLead(data) {
        data && (this.lead = data);
    }
    async setAccount(data) {
        data && (this.account = data);
    }
    clearLead() {
        this.lead = null;
    }
    async convertLeadToContact(payload = {}) {
        return await post(`/staff/leads/convert-to-contact/`, payload);
    }
}
export const LeadStore = new Lead();
