import * as React from 'react';
import PartStore from '../stores/PartStore';
import { PDFDocument } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import { Row, Avatar, Card, Col, Button } from 'antd';
import { DeleteOutlined, BorderHorizontalOutlined, ScissorOutlined, ClearOutlined, CloudDownloadOutlined, CloseOutlined, } from '@ant-design/icons';
const SplitPDF = (props) => {
    const [totalPages, setTotalPages] = React.useState(Number);
    const [urlFile, setUrlFile] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [allPages, setAllPages] = React.useState([]);
    const pageRef = React.useRef();
    React.useEffect(() => {
        loadPDF();
    }, [props.pdfFile]);
    React.useEffect(() => {
        loadStructure();
    }, [totalPages]);
    async function loadPDF() {
        const buffer = props.pdfFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(await buffer);
        const pages_count = pdfDoc.getPageCount();
        setUrlFile(URL.createObjectURL(props.pdfFile));
        setTotalPages(pages_count);
        setFileName(props.pdfFile.name);
    }
    function loadStructure() {
        const initial_pages = [];
        [...Array(totalPages)].map((x, i) => initial_pages.push({
            pageNumber: i + 1,
            status: 'active',
        }));
        setAllPages(initial_pages);
    }
    function updatePageStatus(page_number, status) {
        const updated_pages = allPages.map(obj => {
            if (obj.pageNumber === page_number) {
                const newStatus = status === obj.status ? 'active' : status;
                return { ...obj, status: newStatus };
            }
            return obj;
        });
        setAllPages(updated_pages);
    }
    function splitAllPages() {
        const updated_pages = allPages.map(obj => {
            return { ...obj, status: 'splitted' };
        });
        setAllPages(updated_pages);
    }
    function resetAllPages() {
        const updated_pages = allPages.map(obj => {
            return { ...obj, status: 'active' };
        });
        setAllPages(updated_pages);
    }
    function generateStructure() {
        var splitPages = [];
        const documents = [];
        allPages.map(page => {
            if (page.status != 'deleted') {
                splitPages.push(page.pageNumber - 1);
                if (page.status === 'splitted' || page.pageNumber === totalPages) {
                    const document = {
                        idDocument: documents.length,
                        pages: splitPages,
                    };
                    documents.push(document);
                    splitPages = [];
                }
            }
        });
        return documents;
    }
    async function exportDocuments() {
        const documents = await generateStructure();
        const srcDoc = await PDFDocument.load(await props.pdfFile.arrayBuffer());
        let count = 0;
        documents.map(async (document) => {
            const PDF = await PDFDocument.create();
            const copiedPages = await PDF.copyPages(srcDoc, document.pages);
            copiedPages.map(async (page) => {
                PDF.addPage(page);
            });
            const PDFFile = await PDF.save();
            PartStore.addPart(new File([PDFFile], ++count + '-' + fileName, { type: 'application/pdf' }));
        });
        props.handleReset();
    }
    return (React.createElement("div", { className: "mb-2", style: {
            marginBottom: '5px',
            padding: '10px',
            border: '1px solid #e2e8f0',
        } },
        React.createElement("div", null,
            React.createElement(Button, { style: { float: 'right' }, onClick: () => props.handleReset(), icon: React.createElement(CloseOutlined, null), type: "link" }, ' '),
            React.createElement("p", { className: "pb-2", style: { textAlign: 'center', fontSize: '1.6rem', fontWeight: 600 } }, "Split PDF files"),
            React.createElement("p", { style: { textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)' } }, "Easy tool to split PDF files by pages.")),
        React.createElement("div", { className: "mb-2", style: {
                marginBottom: '5px',
                padding: '10px',
            } },
            React.createElement(Button, { onClick: () => resetAllPages(), icon: React.createElement(ClearOutlined, null), type: "link" }, "Reset All"),
            React.createElement(Button, { onClick: () => splitAllPages(), icon: React.createElement(ScissorOutlined, null), type: "link" }, "Split All"),
            React.createElement(Button, { onClick: () => exportDocuments(), icon: React.createElement(CloudDownloadOutlined, null), type: "primary", style: { float: 'right' } }, "Add Parts")),
        React.createElement("div", { className: "main", style: {
                marginBottom: '5px',
                padding: '10px',
                border: '1px solid #e2e8f0',
            } },
            React.createElement(Document, { file: urlFile },
                React.createElement(React.Fragment, null,
                    React.createElement(Row, { gutter: [16, 16] }, allPages.map(page => (React.createElement(React.Fragment, null,
                        React.createElement(Col, { className: "gutter-row", span: 5 },
                            React.createElement(Card, { ...(page.status === 'splitted'
                                    ? { width: 250, style: { borderRight: '5px dotted red' } }
                                    : { width: 250 }), cover: React.createElement("div", { ref: pageRef, ...(page.status === 'deleted' ? { style: { opacity: '.2' } } : {}) },
                                    React.createElement(Page, { width: pageRef.current ? pageRef.current.offsetWidth - 4 : 300, height: pageRef.current ? pageRef.current.offsetHeight : 300, pageNumber: page.pageNumber })), actions: [
                                    React.createElement(Avatar, { ...(page.status != 'deleted'
                                            ? { style: { backgroundColor: '#c00' } }
                                            : {}), gap: 1 }, page.pageNumber),
                                    React.createElement(DeleteOutlined, { key: "delete", onClick: () => updatePageStatus(page.pageNumber, 'deleted'), ...(page.status === 'deleted' ? { style: { color: '#c00' } } : {}) }),
                                    React.createElement(BorderHorizontalOutlined, { key: "split", onClick: () => updatePageStatus(page.pageNumber, 'splitted'), ...(page.status === 'splitted'
                                            ? { style: { color: '#c00' } }
                                            : {}) }),
                                ] })))))))))));
};
export default SplitPDF;
