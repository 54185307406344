import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
const { Option } = Select;
const DomesticHardwareModal = (props) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [mytags, setMyTags] = useState([]);
    useEffect(() => {
        setMyTags(props.tags);
    }, [props.tags]);
    return (React.createElement(Modal, { title: props.type === 'domestic' ? 'Domestic' : 'Hardware', visible: props.isVisible, onOk: () => {
            props.handleOk(mytags);
        }, onCancel: () => {
            props.handleCancel();
        } },
        React.createElement(Select, { mode: "tags", style: { width: '100%' }, placeholder: "Tags Mode", onChange: value => {
                setMyTags(value);
            }, value: mytags }, mytags.map(item => {
            React.createElement(Option, { key: item }, item);
        }))));
};
export default DomesticHardwareModal;
