import { v4 } from 'uuid';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { ALLOWED_FILES, stringSimilarity } from '@partsbadger/utils';
import QuoteStore from './QuoteStore';
const { allowed_3d, allowed_2d } = ALLOWED_FILES;
/**
 * Extract the name from file name
 * @param filename
 * @returns {null}
 */
const get_name = (filename) => {
    return filename
        ? filename
            .split('.')
            .slice(0, -1)
            .join('.')
            .replace(/[^0-9a-z]/gi, '')
        : '';
};
/**
 * Check if a file is 2d of 3d file based on list of extensions
 * @param filename
 * @returns {string}
 */
const is3dFile = (filename) => {
    const extension = filename.split('.').pop();
    return allowed_3d.find(ext => ext.toLowerCase() === extension?.toLowerCase());
};
const is2dFile = (filename) => {
    const extension = filename.split('.').pop();
    return allowed_2d.find(ext => ext.toLowerCase() === extension?.toLowerCase());
};
class PartFiles {
    constructor() {
        this.FILE_3D = '3D File';
        this.FILE_2D = '2D File';
        this.FILE_OTHERS = 'Additional File';
        /**
         * @type {Array} of objects :
         * @format [{
         *     id: unique_id
         *     file2d:
         *     file3:
         *     fileothers:
         *     similarity: 0 to 1, 1 is the exact name
         * }]
         */
        this.fileParts = [];
        this.sortedBy = {};
        this.isZipFile = (filename) => {
            return filename.split('.')?.pop()?.toLowerCase() === 'zip';
        };
        /**
         * Search a spot to a new file, search the spot by similarity name or add new line
         * @param file (object)
         */
        this.addPart = (file) => {
            const payload = {
                uid: v4(),
            };
            let type = 'Additional File'; // Current file type
            let searchFileType = 'all'; // Type of file where we have to find match
            if (is3dFile(file.name)) {
                type = '3D File';
                searchFileType = '2D File';
            }
            else if (is2dFile(file.name)) {
                type = '2D File';
                searchFileType = '3D File';
            }
            else {
                type = 'Additional File';
                searchFileType = 'all'; // For other files we should to search on the 3d or 2d matches
            }
            // Check if there is file with the same name
            let index = this.findExactName(file.name, searchFileType);
            let similarity = 1;
            //If there is not files with the same name try with fuzzy search
            if (index === -1) {
                const result = this.fuzzySearch(file.name, searchFileType);
                index = result.index;
                similarity = result.similarity;
            }
            if (type === 'Additional File') {
                if (index >= 0) {
                    this.fileParts[index][type] =
                        this.fileParts[index][type]?.length > 0 ? [...this.fileParts[index][type], file] : [file];
                }
                else {
                    payload[type] = [file];
                    this.setParts([...this.fileParts, payload]);
                }
                return;
            }
            // Replace the file with the files founded
            if (index >= 0 && similarity > this.fileParts[index]['similarity']) {
                const backup = this.fileParts[index][type];
                this.fileParts[index][type] = file;
                this.fileParts[index]['similarity'] = similarity;
                if (backup) {
                    // if there is a file search a spot for that file
                    this.addPart(backup);
                }
            }
            else {
                // Add a new line
                payload[type] = file;
                payload['similarity'] = similarity;
                payload['uploading'] = false;
                payload['progress'] = 0;
                this.setParts([...this.fileParts, payload]);
            }
        };
        makeObservable(this, {
            fileParts: observable,
            sortedBy: observable,
            // Actions
            setParts: action,
            isZipFile: action,
            addPart: action,
            removePart: action,
            addFile: action,
            removeFile: action,
            swapParts: action,
            swapParts2: action,
            uploadPart: action,
            hasParts: action,
            count: action,
            sortBy: action,
        });
    }
    setParts(parts) {
        runInAction(() => {
            this.fileParts = parts;
        });
    }
    /**
     * Find for a exact file name
     * @param filename
     * @param type {String}  type of file, '3D File' or '2D File' or "all"
     * @returns {number} Index founded else -1 if not found
     */
    findExactName(filename, type) {
        return this.fileParts.findIndex(item => {
            if (type === 'all') {
                const a = item['2D File'];
                const b = item['3D File'];
                return ((a && get_name(a?.name) === get_name(filename)) || (b && get_name(b?.name) === get_name(filename)));
            }
            const currentFile = item[type];
            return currentFile && get_name(currentFile?.name) === get_name(filename);
        });
    }
    /**
     * Find similar file  with fuzzy search
     * @param filename
     * @param type {String}  type of file to search in, '3D File' or '2D File' or "all" to compare with both
     * @return {index, similarity} Index founded else -1 if not found, similarity 0 - 1
     */
    fuzzySearch(filename, type) {
        // Exclude items with similarity 1, those are exact names
        // Exclude items if the similarity found is less than the existing ones
        const found = this.fileParts
            .filter(item => item.similarity < 1 && item[type])
            .map(item => {
            let similarity = 0;
            if (type === 'all') {
                const a = item['2D File'];
                const b = item['3D File'];
                const similarity_a = stringSimilarity(get_name(a?.name), get_name(filename));
                const similarity_b = stringSimilarity(get_name(b?.name), get_name(filename));
                similarity = similarity_a > similarity_b ? similarity_a : similarity_b;
            }
            else {
                similarity = stringSimilarity(get_name(item[type]?.name), get_name(filename));
            }
            return {
                item: item,
                similarity: similarity,
            };
        })
            .filter(f => f.similarity > 0 && f.similarity > f.item.similarity)
            .sort((a, b) => b.similarity - a.similarity);
        if (found.length > 0) {
            const index = this.fileParts.findIndex(item => {
                return item.uid === found[0].item.uid;
            });
            return {
                index: index,
                similarity: found[0].similarity,
            };
        }
        return {
            index: -1,
            similarity: 0,
        };
    }
    removePart(item) {
        this.setParts([...this.fileParts.filter(i => i.uid !== item.uid)]);
    }
    addFile(index, fileType, file) {
        if (fileType === 'Additional File') {
            const files = this.fileParts[index][fileType] || [];
            this.fileParts[index][fileType] = [...files, file];
        }
        else {
            this.fileParts[index][fileType] = file;
        }
    }
    removeFile(item, fileType, file) {
        this.setParts([
            ...this.fileParts.filter(i => {
                if (i.uid !== item.uid) {
                    return i;
                }
                if (fileType === 'Additional File') {
                    if (file) {
                        i[fileType] = i[fileType].filter((f) => f.name !== file.name);
                    }
                }
                else {
                    i[fileType] = null;
                }
                return i;
            }),
        ]);
    }
    /**
     * Swap a part file
     * @param index_from: index from the file was picked
     * @param index_to: index where the file was dropped
     * @param FileTypeFrom: Spot file type where the file was picker
     * @param FileTypeTo: Spot File type were the file is dropped
     * @param file : file object
     */
    swapParts(index_from, index_to, FileTypeFrom, FileTypeTo, file) {
        if (FileTypeTo === 'Additional File' && (FileTypeFrom === '2D File' || FileTypeFrom === '3D File')) {
            // Remove the file from the current position 2d or 3d
            this.fileParts[index_from][FileTypeFrom] = null;
            // Append file to the other files
            const current_other_files = this.fileParts[index_to]['Additional File'] || [];
            this.fileParts[index_to]['Additional File'] = [...current_other_files, file];
        }
        else if (FileTypeFrom === 'Additional File' && (FileTypeTo === '2D File' || FileTypeTo === '3D File')) {
            // Remove the file from file others on the previous position
            this.fileParts[index_from]['Additional File'] = this.fileParts[index_from]['Additional File'].filter((f) => {
                return f.name !== file.name;
            });
            // save if there is a file in the destiny spot
            const current = this.fileParts[index_to][FileTypeTo];
            // Move to the other files if there was a file
            if (current) {
                const current_other_files = this.fileParts[index_from]['Additional File'] || [];
                this.fileParts[index_from]['Additional File'] = [...current_other_files, current];
            }
            // Put the file on the 2d or 3d spot
            this.fileParts[index_to][FileTypeTo] = file;
        }
        else if (FileTypeFrom === 'Additional File' && FileTypeTo === 'Additional File') {
            // Remove the file from file others on the previous position, filtering by name
            this.fileParts[index_from]['Additional File'] = this.fileParts[index_from]['Additional File'].filter((f) => {
                return f.name !== file.name;
            });
            const current_other_files = this.fileParts[index_to]['Additional File'] || [];
            this.fileParts[index_to]['Additional File'] = [...current_other_files, file];
        }
        else {
            const file_from = this.fileParts[index_from][FileTypeFrom];
            const file_to = this.fileParts[index_to][FileTypeTo];
            this.fileParts[index_to][FileTypeTo] = file_from;
            this.fileParts[index_from][FileTypeFrom] = file_to;
        }
    }
    /**
     * Swap a part file
     * @param index_from
     * @param index_to
     * @param fileType type of file
     * @param file
     */
    swapParts2(index_from, index_to, fileType, file) {
        if (fileType === 'Additional File') {
            const destination_files = this.fileParts[index_to][fileType];
            const origin_files = this.fileParts[index_from][fileType];
            // Append to the destination files
            this.fileParts[index_to][fileType] = destination_files?.length ? [...destination_files, file] : [file];
            // Remove from the original position
            this.fileParts[index_from][fileType] = origin_files?.filter((f) => {
                return f.name !== file.name;
            });
        }
        else {
            const file_to = this.fileParts[index_to][fileType];
            this.fileParts[index_to][fileType] = this.fileParts[index_from][fileType];
            this.fileParts[index_from][fileType] = file_to;
        }
    }
    uploadPart(item) {
        const index = this.fileParts.findIndex(i => i.uid === item.uid);
        this.fileParts[index]['uploading'] = true;
        const formData = new FormData();
        if (item['3D File']) {
            formData.append('file_3d', item['3D File']);
        }
        if (item['2D File']) {
            formData.append('file_2d', item['2D File']);
        }
        if (item['Additional File']?.length) {
            item['Additional File'].map((f) => formData.append('file_others', f));
        }
        // Send the exact row position of the file in the drag and drop
        formData.append('position', (index + 1).toString());
        if (QuoteStore.quote?.email) {
            formData.append('email', QuoteStore.quote.email);
        }
        const onUploadProgress = (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            try {
                this.fileParts[index]['progress'] = Number(progress?.toFixed(0)) || 0;
            }
            catch (e) {
                console.error(e);
            }
        };
        QuoteStore.newLineItemWithFiles(formData, onUploadProgress).finally(() => {
            this.removePart(item);
        });
    }
    hasParts() {
        return this.fileParts.length > 0;
    }
    count() {
        return this.fileParts.length;
    }
    sortBy(fileType) {
        this.sortedBy = fileType;
        this.fileParts = this.fileParts.slice().sort((item1, item2) => {
            const a = item1[fileType]?.name?.toLowerCase();
            const b = item2[fileType]?.name?.toLowerCase();
            if (a < b) {
                return -1;
            }
            if (b > a) {
                return 1;
            }
            return 0; // Are equal
        });
    }
}
const partStore = new PartFiles();
export default partStore;
