import React, { useEffect, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Col, Divider, notification, Row, Typography } from 'antd';
import { patch, post, remove } from '@partsbadger/utils';
import { ContactStore, QuoteStore } from '../Stores';
import { Price } from '@partsbadger/library';
import { ApplicationCostForm } from '../Forms';
import { observer } from 'mobx-react';
import { requirements_for_app_costs } from 'quotes-frontend/src/shared';
const { Text, Paragraph } = Typography;
const ApplicationCostItems = observer(({ quote, account, setReorderingAppCost }) => {
    useEffect(() => {
        if (quote?.contact?.id) {
            ContactStore.getAdditionalRequirementsContactAccount(quote.contact.id);
        }
    }, [quote?.contact?.id]);
    const [editingKey, setEditingKey] = useState();
    const duplicateAdditionalCost = (item) => {
        const payload = {
            quote: item.quote,
            is_fai: false,
            additional_requirement: item.additional_requirement,
            price: item.price,
        };
        post('/staff/quote-additional-requirements/duplicate/', payload).then(() => {
            QuoteStore.getQuote(quote.id);
        });
    };
    const popup = (url) => {
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    const [loading, setLoading] = useState(false);
    const needAppCosts = useMemo(() => {
        if (quote.custom_requirements) {
            return quote.custom_requirements.filter(x => requirements_for_app_costs.includes(x.label));
        }
        return [];
    }, [quote.custom_requirements]);
    return (React.createElement("div", { style: { margin: '0px 0' } },
        React.createElement(Row, { className: 'center' },
            React.createElement(Col, { md: 12, sm: 24 },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 16 },
                        React.createElement("span", { className: 'font-bold' }, "Application")),
                    React.createElement(Col, { sm: 4 },
                        React.createElement("span", { className: 'font-bold' }, "Cost")),
                    React.createElement(Col, { sm: 4, className: 'font-bold' }, "Action")),
                React.createElement(Col, null,
                    React.createElement(Divider, { className: 'line', style: { margin: '2px' } })),
                quote?.additional_requirements?.map(item => {
                    if (editingKey === item.id) {
                        return (React.createElement(ApplicationCostForm, { item: {
                                id: item.id,
                                additional_requirement: item.additional_requirement,
                                price: item.price,
                            }, handleSave: async (payload) => {
                                await patch(`/staff/quote-additional-requirements/${item.id}/`, payload);
                                QuoteStore.getQuote(quote?.id);
                                setEditingKey(undefined);
                            } }));
                    }
                    return (React.createElement(React.Fragment, null,
                        React.createElement(React.Fragment, { key: item.id },
                            React.createElement(Row, null,
                                React.createElement(Col, { sm: 16 }, item.additional_requirement),
                                React.createElement(Col, { sm: 4 },
                                    React.createElement(Price, { value: item.price })),
                                React.createElement(Col, { sm: 4 },
                                    React.createElement(React.Fragment, null,
                                        React.createElement(Button, { disabled: !quote.editable, icon: React.createElement(DeleteOutlined, null), onClick: () => {
                                                remove(`/staff/quote-additional-requirements/${item.id}/`)
                                                    .then(() => {
                                                    QuoteStore.getQuote(quote.id);
                                                    QuoteStore.getMasterProducts(quote.id);
                                                })
                                                    .catch(error => {
                                                    notification.error({
                                                        message: 'Error',
                                                        description: error,
                                                    });
                                                });
                                            } }),
                                        React.createElement(Button, { disabled: !quote.editable, icon: React.createElement(EditOutlined, null), onClick: () => setEditingKey(item.id) }),
                                        React.createElement(Button, { disabled: !quote.editable, icon: React.createElement(LegacyIcon, { type: 'copy' }), onClick: () => duplicateAdditionalCost(item) })))),
                            React.createElement(Row, null,
                                React.createElement(Divider, { className: 'line', style: { margin: '2px' } })))));
                }),
                React.createElement("br", null),
                quote.editable && (React.createElement(React.Fragment, null,
                    needAppCosts.length > 0 && (React.createElement("div", { className: 'flex flex-col pb-2' },
                        React.createElement("div", { className: 'flex justify-start' },
                            React.createElement("span", null, "Additional requirements that should include application costs.")),
                        React.createElement("div", { className: 'flex flex-col text-red-700 px-2' }, needAppCosts.map((x, i) => {
                            return (React.createElement("div", { key: i, className: 'flex' },
                                React.createElement("span", { className: 'px-2' }, "*"),
                                React.createElement("span", null, x.label)));
                        })))),
                    React.createElement(ApplicationCostForm, { handleSave: async (payload) => {
                            await post('/staff/quote-additional-requirements/', payload);
                            QuoteStore.getQuote(quote?.id);
                        }, setReorderingAppCost: setReorderingAppCost })))),
            React.createElement(Col, { md: 12, sm: 24, style: { textAlign: 'center' } },
                ContactStore.requirements && (React.createElement(Row, null,
                    React.createElement(Col, { md: 12, xs: 24 },
                        React.createElement("strong", { style: { textAlign: 'right' } }, "Contact/Account Requirements:")),
                    React.createElement(Col, { md: 12, xs: 24 },
                        React.createElement("ul", { style: { textAlign: 'left' } }, ContactStore.requirements.map(r => (React.createElement("li", { key: r.id }, r.requirement.name))))))),
                ContactStore.documents.length && (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 12, xs: 24 },
                            React.createElement("strong", { style: { textAlign: 'right' } }, "Contact/Account Documents:")),
                        React.createElement(Col, { md: 12, xs: 24 },
                            React.createElement("ul", { style: { textAlign: 'left' } }, ContactStore.documents.map(d => (React.createElement("li", { key: d.id },
                                React.createElement("a", { onClick: () => popup(d.file), target: "_blank", rel: "noreferrer" }, d.filename)))))))))))));
});
export default ApplicationCostItems;
