import React, { useState } from 'react';
import { Table, Tag, Switch, notification } from 'antd';
import SalesOrderStore from '../Stores/SalesOrderStore';
import { Edit3DPartsFilesApproval } from './Edit3DPartFilesApproval';
import { ErrorRender } from '@partsbadger/library';
const TAG_STATUS = {
    Approved: '#87d068',
    Rejected: '#f50',
    'Need 3D Approval': 'orange',
};
export const Table3DFilesApproval = () => {
    const order_items = SalesOrderStore.order?.line_items?.filter(i => i.name != 'CNC Part') || [];
    const [switchLoading, setSwitchLoading] = useState(false);
    const handleSwitchStatus = async (id, status) => {
        setSwitchLoading(true);
        await SalesOrderStore.update3DApprovalStatus(id, status)
            .then(() => {
            setSwitchLoading(false);
        })
            .catch(error => {
            notification.error({
                duration: 10,
                message: 'Error',
                description: React.createElement(ErrorRender, { error: error }),
                placement: 'bottomRight',
            });
            setSwitchLoading(false);
        });
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'File',
            width: 250,
            render: (t, record) => {
                if (!record.quote_has_product) {
                    return null;
                }
                return (React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(Edit3DPartsFilesApproval, { record: record, product: record.quote_has_product.product, show_pending_quoted_files: true })));
            },
        },
        {
            title: 'Status',
            // dataIndex: 'production_3d_file_approval_status',
            render: (t, record) => {
                const color = TAG_STATUS[record.production_3d_file_approval_status];
                if (color) {
                    return React.createElement(Tag, { color: color }, record.production_3d_file_approval_status);
                }
                return '';
            },
        },
        {
            title: 'Feedback',
            dataIndex: 'production_3d_file_approval_feedback',
            width: 300,
        },
        {
            title: 'Action',
            render: (t, record) => (React.createElement(Switch, { defaultChecked: record.production_3d_file_approval_status === 'Approved' ? true : false, loading: switchLoading, onChange: () => handleSwitchStatus(record.id, record.production_3d_file_approval_status === 'Approved' ? 'Need 3D Approval' : 'Approved') })),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { rowKey: 'id', pagination: false, dataSource: order_items, columns: columns })));
};
