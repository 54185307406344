import React from 'react';
import { Button, Upload } from 'antd';
import { API_ROOT } from '@partsbadger/utils';
import QuoteStore from '../../stores/QuoteStore';
import { UploadOutlined } from '@ant-design/icons';
export const UploadOtherFiles = ({ item, readOnly = false }) => {
    const { other_files } = item.product?.attachments;
    const defaultFileList = other_files?.map(row => {
        return {
            uid: row.id,
            name: row.filename,
            status: 'done',
            response: {
                id: row.id,
                file: row.file,
                filename: row.filename,
            },
        };
    });
    const headers = QuoteStore.token ? { Authorization: `Token ${QuoteStore.token}` } : {};
    const props = {
        name: 'file',
        multiple: true,
        action: `${API_ROOT}/customer/files/`,
        headers: headers,
        defaultFileList: defaultFileList,
        onChange: (info) => {
            const { status } = info.file;
            if (status === 'done') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_others: info.fileList.map(f => f.response.id) },
                });
            }
            if (status === 'removed') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: {
                        file_others: info.fileList.filter(f => f.uid !== info.file.uid).map(f => f.response.id),
                    },
                });
            }
        },
    };
    if (!item?.uid) {
        return null;
    }
    return (
    // @ts-ignore
    React.createElement(Upload, { className: 'mb-3', ...props, showUploadList: {
            showRemoveIcon: !readOnly,
        } }, !readOnly ? (React.createElement(Button, { className: "ant-btn-xs", block: true, icon: React.createElement(UploadOutlined, null) }, "Add Additional File")) : (React.createElement("div", { onClick: e => e.stopPropagation() },
        React.createElement("span", { className: "font-weight-bold" }, "Additional Files:")))));
};
