import React from 'react';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import SalesOrderStore from './Stores/SalesOrderStore';
import { Chat } from '@partsbadger/library';
const SalesOrderNotifications = observer((props) => {
    return (React.createElement("div", null,
        React.createElement(Chat, { form: props.form, height: props.height, messages: SalesOrderStore?.sales_order_notifications, onSendMessage: values => {
                SalesOrderStore.sendNotification(props.sales_order_id, values);
            } })));
});
export default Form.create()(SalesOrderNotifications);
