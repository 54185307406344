import React, { useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Alert, Badge, Button, Card, Checkbox, Divider, Drawer, Form, message, Modal, notification, Popconfirm, Popover, Progress, Spin, Table, Tag, Tooltip, Typography, } from 'antd';
import { get, patch, post } from '@partsbadger/utils';
import DynamicQuantities from '../LineItems/DynamicQuantities';
import LineItems from '../LineItems/LineItems';
import SortDrag from '../LineItems/SortDrag';
import { ErrorRender, UploadFile } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { DrawingFiles, InternalFiles, OtherFiles, PartDetails, StepFile, ThumbnailFile, VendorQuote } from './index';
import ThumbnailFileMcMaster from './ThumbnailFileMcMaster';
import { LineItemInfo } from '../LineItems/LineItemInfo';
import DynamicQuantitiesSwitcher from '../DynamicQuantitiesSwitcher';
import _ from 'lodash';
import { HistoryOutlined, LoadingOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { BatchReleasesSwitcher } from '../index';
import { AddLeadTime } from '../AddLeadTime';
import RecuoteChanges from './RecuoteChanges';
import { ModalERPNotes } from '../ModalERPNotes';
const { Paragraph } = Typography;
const MasterProductDetail = (props) => {
    const { master_product } = props;
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2D, setLoading2D] = useState(false);
    const [isSwitchingDynamicQuantities, setIsSwitchingDynamicQuantities] = useState(false);
    const [showAddStepFile, setShowAddStepFile] = useState(false);
    const [showAddDrawingFile, setShowAddDrawingFile] = useState(false);
    const [showAddOtherFile, setShowAddOtherFile] = useState(false);
    const [status_analyzer, setStatusAnalyzer] = useState();
    const [error, setError] = useState();
    const [already_quote, setAlreadyQuote] = useState(false);
    const [item_already, setItemAlready] = useState();
    const [deviationNote, setDeviationNote] = useState('');
    const [editNotes, setEditNotes] = useState(false);
    const [adjustmentLeadTime, setAdjustmentLeadTime] = useState(false);
    const [sortModal, setSortModal] = useState(false);
    const [viewRecuoteChanges, setViewRecuoteChanges] = useState(false);
    const [showModalERPNote, setShowModalERPNote] = useState(false);
    const [dataERPNote, setDataERPNote] = useState();
    const [form] = Form.useForm();
    let pollingInterval = null;
    const quote_id = QuoteStore?.quote?.id || 0;
    const quote = QuoteStore.quote;
    const isAnEditableQuote = QuoteStore.inProgress;
    /**
     * Enable polling if the part is being analyzed
     */
    useEffect(() => {
        if (!master_product.analysis_status.file_3d.completed) {
            setLoading(true);
            startPolling(master_product.id);
        }
        else {
            setLoading(false);
        }
        return () => {
            clearInterval(pollingInterval);
        };
    }, [master_product.analysis_status.file_3d.completed]);
    const startPolling = (id) => {
        setLoading(true);
        const url = `/staff/master-products/${id}/status/`;
        pollingInterval = setInterval(() => {
            get(url)
                .then((data) => {
                if (data.file_3d.message?.msg) {
                    setStatusAnalyzer(data.file_3d.message);
                }
                if (data.file_3d.completed) {
                    loadMasterProduct(id);
                    clearInterval(pollingInterval);
                    setLoading(false);
                }
            })
                .catch(() => {
                setLoading(false);
                clearInterval(pollingInterval);
            });
        }, 5000);
    };
    const loadMasterProduct = (id) => {
        get(`/staff/master-products/${id}/`)
            .then((response) => {
            setLoading(!response.analysis_status.file_3d.completed);
            QuoteStore.getMasterProducts(quote_id).finally(() => {
                if (props.item) {
                    props.item(QuoteStore.masterProductsByPosition);
                }
            });
            if (!response.analysis_status.file_3d.completed) {
                startPolling(id);
            }
        })
            .catch((error) => {
            setLoading(false);
            setError(error);
        });
    };
    const reanalyzeMasterProduct = (id) => {
        post('/staff/master-products/' + id + '/analyze/')
            .then(() => {
            startPolling(id);
        })
            .catch(() => message.error('Error'));
    };
    const toggleEditable = () => {
        setEditing(!editing);
    };
    const renderFile = () => {
        const url = `/3d-viewer/${master_product.id}/`;
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    const updateStepFile = (file_id) => {
        post('/staff/master-products/' + master_product.id + '/upload-3d-file/', {
            step_file: file_id,
        })
            .then(() => {
            if (file_id) {
                startPolling(master_product.id);
            }
            else {
                loadMasterProduct(master_product.id);
            }
            setShowAddStepFile(false);
        })
            .catch(() => message.error('Error, try again'));
    };
    const updateDrawingFile = (file_id) => {
        post('/staff/master-products/' + master_product.id + '/upload-2d-file/', {
            drawing_file: file_id,
        })
            .then(() => {
            loadMasterProduct(master_product.id);
            setShowAddDrawingFile(false);
        })
            .catch(() => {
            message.error('Error, try again');
        });
    };
    const onChangeDynamicQuantities = async (enabled, items) => {
        setIsSwitchingDynamicQuantities(true);
        await patch(`/staff/quotes/${quote_id}/update-multiple/`, {
            quoted_products: items,
            data: { has_dynamic_quantities: enabled },
        });
        await QuoteStore.recalculatePrices({ quoted_products: items });
        await QuoteStore.getLineItems(quote_id);
        setIsSwitchingDynamicQuantities(false);
        QuoteStore.getQuote(quote_id);
    };
    const onChangeBatchReleases = async (enabled, items) => {
        await patch(`/staff/quotes/${quote_id}/update-multiple/`, {
            quoted_products: items,
            data: { batch_shipment: enabled },
        });
        await QuoteStore.getLineItems(quote_id);
        QuoteStore.getQuote(quote_id);
    };
    const loadProductAlreadyQuote = (row_id, already_quote_product) => {
        setLoading(true);
        setAlreadyQuote(true);
        get(`/staff/quoted-products/${already_quote_product}`)
            .then((response) => {
            const product = response;
            get(`/staff/master-products/${product.product.master_product}`).then((master_product) => {
                const data = {
                    product: product,
                    master_product: master_product,
                    mp_replace_id: row_id,
                };
                setItemAlready(data);
            });
        })
            .finally(() => setLoading(false));
    };
    const updateTighterTolerance = e => {
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { tighter_tolerances: e.target.checked })
            .then(response => {
            loadMasterProduct(response.id);
            setLoading(false);
        })
            .catch(error => {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    };
    const updateDeviation = e => {
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { deviation: e.target.checked })
            .then(response => {
            loadMasterProduct(response.id);
            setLoading(false);
        })
            .catch(error => {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    };
    const handleUpdateDeviationNote = _.debounce(async (value) => {
        setDeviationNote(value);
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { deviation_note: value })
            .then(response => {
            loadMasterProduct(response.id);
            setLoading(false);
        })
            .catch(error => {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    }, 500);
    const update3dFileCreation = e => {
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { creation_file_3d: e.target.checked })
            .then(async (response) => {
            loadMasterProduct(response.id);
            setLoading(false);
            await QuoteStore.getQuote(quote_id);
        })
            .catch(error => {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    };
    if (!master_product?.id) {
        return null;
    }
    const line_items_by_master_product = QuoteStore.lineItems.filter(entity => entity.product.master_product === master_product.id);
    const line_items_dynamic_quote = line_items_by_master_product.filter(item => item.has_dynamic_quantities);
    const line_items_no_dynamic_quote = line_items_by_master_product.filter(item => !item.has_dynamic_quantities);
    let initialQuantities = [];
    if (line_items_by_master_product.length > 0) {
        if (line_items_by_master_product[0].quantities !== null &&
            line_items_by_master_product[0].quantities !== undefined) {
            initialQuantities =
                line_items_by_master_product[0].quantities.length > 0
                    ? line_items_by_master_product[0].quantities
                    : [line_items_by_master_product[0].quantity];
        }
        else {
            initialQuantities = [line_items_by_master_product[0].quantity];
        }
    }
    const handleAddERPQuotingNotes = async (quote_line_item_id, notes) => {
        setDataERPNote({
            id: quote_line_item_id,
            partsbadger_production_quoting_notes: notes,
        });
        setShowModalERPNote(true);
    };
    return (React.createElement("div", { className: `master-product-${master_product.id}`, key: `master-product-${master_product.id}` },
        React.createElement(ErrorRender, { error: error }),
        React.createElement(Card, { className: "overflow-auto m-0", style: { padding: '10px', cursor: 'pointer' } },
            React.createElement(Spin, { spinning: loading, tip: (React.createElement(Tooltip, { title: status_analyzer?.msg },
                    React.createElement(Progress, { style: { maxWidth: 200, margin: 'auto' }, percent: status_analyzer?.current || 0 }))) },
                master_product?.quickturn_summary?.is_quickturn_part ? (React.createElement(Alert, { type: "success", showIcon: true, message: React.createElement("span", { style: { color: 'darkgreen', fontWeight: 'bold' } }, "QuickTurn Alert"), style: { marginBottom: '1rem' }, description: React.createElement(React.Fragment, null,
                        React.createElement("span", { style: { color: 'darkgreen' } },
                            "This product was automatically flagged as a potential QuickTurn part.",
                            ' ',
                            React.createElement("a", { href: "https://portal.parts-badger.com/quick-turn/design", rel: "noreferrer", target: "_blank", style: {
                                    color: 'darkgreen',
                                    textDecoration: 'underline',
                                    fontWeight: 500,
                                } }, "Check the QuickTurn Portal"))) })) : null,
                React.createElement("div", { className: "flex flex-col xl:flex-row justify-evenly" },
                    !editing && (React.createElement("div", { className: 'flex flex-row justify-start items-center mr-5' },
                        React.createElement("span", { className: "mr-4" },
                            "#",
                            props.count + 1),
                        React.createElement("div", null,
                            React.createElement(ThumbnailFile, { master_product: master_product, handleClick: renderFile })))),
                    React.createElement("div", { className: 'flex flex-row items-center flex-wrap m-0' },
                        React.createElement(PartDetails, { row: master_product, editable: setEditing, editNotes: editNotes, closedNotes: setEditNotes, handleChange: (id) => {
                                QuoteStore.getQuote(quote_id);
                                loadMasterProduct(id);
                                toggleEditable();
                            } }),
                        React.createElement("div", { className: "lg:w-full xl:w-auto" },
                            React.createElement(StepFile, { master_product: master_product, renderFile: renderFile, removeFile: () => {
                                    updateStepFile(null);
                                }, showAddStepFile: () => {
                                    setShowAddStepFile(true);
                                } })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "col-drawing-files" },
                            React.createElement(DrawingFiles, { master_product: master_product, removeFile: () => {
                                    updateDrawingFile(null);
                                }, showAddDrawingFile: () => {
                                    setShowAddDrawingFile(true);
                                } }),
                            loading2D && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement("div", { className: 'text-center' },
                                    React.createElement("span", { className: 'text-red-600' },
                                        ' ',
                                        React.createElement(LoadingOutlined, null),
                                        "\u2003 Analyzing..."))))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: 'col-other-files' },
                            React.createElement(OtherFiles, { master_product: master_product, showAddOtherFile: () => {
                                    setShowAddOtherFile(true);
                                }, quote_id: quote_id })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(InternalFiles, { master_product: master_product, quote_id: quote_id })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Checkbox, { disabled: !master_product.editable || !QuoteStore?.quote?.editable, onChange: updateTighterTolerance, defaultChecked: master_product.tighter_tolerances },
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "Tighter Tolerance"))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Checkbox, { disabled: !master_product.editable || !QuoteStore?.quote?.editable, onChange: updateDeviation, defaultChecked: master_product.deviation },
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "Deviation"))),
                        master_product.deviation && (React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "Note Deviation: "),
                                master_product.editable ? (React.createElement(Paragraph, { style: { width: '100%' }, disabled: !master_product.editable || !QuoteStore?.quote?.editable, editable: {
                                        onChange: handleUpdateDeviationNote,
                                    } }, master_product.deviation_note)) : (React.createElement("div", null, master_product.deviation_note))))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Checkbox, { disabled: !master_product.editable || !QuoteStore?.quote?.editable, onChange: update3dFileCreation, defaultChecked: master_product.creation_file_3d },
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "3D File Creation"))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !master_product.editable || !QuoteStore?.quote?.editable, title: 'Adjust lead time', className: 'analyze link', onClick: () => {
                                    setAdjustmentLeadTime(true);
                                } }, "Adjust lead time")),
                        React.createElement(Divider, { type: 'vertical' }),
                        master_product.itar && (React.createElement("div", { className: 'col-itar' },
                            React.createElement(Badge, { count: 'ITAR', style: { backgroundColor: '#4b5320' } }),
                            React.createElement(Divider, { type: 'vertical' }))),
                        React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !master_product.editable || !QuoteStore?.quote?.editable, title: 'Analyze again', className: 'analyze link', onClick: () => {
                                    setLoading(true);
                                    reanalyzeMasterProduct(master_product.id);
                                } },
                                "Analyze again ",
                                React.createElement(LegacyIcon, { type: 'sync' }))),
                        React.createElement(Divider, { type: 'vertical' }),
                        QuoteStore?.quote?.editable && master_product.already_quote_product && (React.createElement("div", null,
                            React.createElement(Tag, { style: { cursor: 'pointer' }, color: "magenta", onClick: () => loadProductAlreadyQuote(master_product.id, master_product.already_quote_product) }, "Product already quoted"),
                            React.createElement(Divider, { type: 'vertical' }))),
                        master_product?.previously_ordered && (React.createElement("div", null,
                            React.createElement(Tag, { style: { cursor: 'default' }, color: "green" }, "Previously ordered"),
                            React.createElement(Divider, { type: 'vertical' }))),
                        master_product.is_new && (React.createElement("div", null,
                            React.createElement(Tag, { color: "orange", style: { cursor: 'default' } }, "New item"))),
                        master_product.vendor_quote_information && (React.createElement("div", null,
                            React.createElement(Popover, { title: "Vendor Quote", content: React.createElement(VendorQuote, { quotes: master_product.vendor_quote_information }) },
                                React.createElement(Tag, { color: 'blue' },
                                    "Quoted by a Vendor: ",
                                    master_product.vendor_name)),
                            React.createElement(Divider, { type: 'vertical' }))),
                        master_product.mcmaster_product ? (React.createElement("div", { className: 'flex flex-row items-center' },
                            React.createElement(ThumbnailFileMcMaster, { mcmaster_product: master_product.mcmaster_product }),
                            React.createElement(Divider, { type: 'vertical' }))) : (React.createElement("div", null)),
                        React.createElement("div", { className: 'delete' },
                            React.createElement(Popconfirm, { disabled: !QuoteStore?.quote?.editable, okType: 'danger', className: 'link', title: `Do you want to delete this product?`, onConfirm: () => {
                                    QuoteStore.deleteMasterProduct(master_product.id);
                                } },
                                React.createElement(Button, { type: "link", disabled: !QuoteStore?.quote?.editable, title: 'Delete  Master Product from this quote', className: 'link' },
                                    "Delete ",
                                    React.createElement(LegacyIcon, { type: 'delete' })))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !QuoteStore?.quote?.editable, title: 'Sort', className: 'analyze link', onClick: () => {
                                    setSortModal(true);
                                } },
                                "Sort ",
                                React.createElement(SortAscendingOutlined, { className: "" }))),
                        QuoteStore.similarProducts &&
                            QuoteStore.similarProducts[master_product.id.toString()] &&
                            QuoteStore.similarProducts[master_product.id.toString()].length > 0 && (React.createElement(React.Fragment, null,
                            React.createElement(Divider, { type: 'vertical' }),
                            React.createElement(Popover, { title: "Similar Parts", content: React.createElement(React.Fragment, null,
                                    React.createElement(Table, { columns: [
                                            {
                                                dataIndex: 'product__master_product__name',
                                                title: 'Product',
                                            },
                                            {
                                                dataIndex: 'quantity',
                                                title: 'QTY',
                                            },
                                            {
                                                dataIndex: 'manual_adjustment',
                                                title: 'DE Adj',
                                            },
                                            {
                                                dataIndex: 'formula',
                                                title: 'Formula',
                                            },
                                            {
                                                dataIndex: 'product__material__name',
                                                title: 'Material',
                                            },
                                            {
                                                dataIndex: 'coating',
                                                title: 'Coating',
                                            },
                                            {
                                                dataIndex: 'finish',
                                                title: 'Finish',
                                            },
                                            {
                                                dataIndex: 'unit_price',
                                                title: 'Unit Price',
                                            },
                                        ], key: "pk", dataSource: QuoteStore.similarProducts[master_product.id.toString()] })) },
                                React.createElement(Tag, { color: 'blue' }, "Similar Parts")))),
                        !master_product.editable && quote?.editable && (React.createElement("div", { className: "col-duplicate" },
                            React.createElement("div", { title: 'This master product is related to other quotes, please duplicate to make changes', className: 'link', onClick: () => {
                                    setLoading(false);
                                    QuoteStore.duplicateMasterProduct(master_product.id);
                                } },
                                "Duplicate ",
                                React.createElement(LegacyIcon, { type: 'copy' })))),
                        master_product.changed_fields && (React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !QuoteStore?.quote?.editable, title: 'View Requote Changes', className: 'analyze link', onClick: () => {
                                    setViewRecuoteChanges(true);
                                } },
                                "Requote Changes ",
                                React.createElement(HistoryOutlined, null))))))),
            loading && (React.createElement("div", { className: 'link', style: { textAlign: 'center' }, onClick: () => {
                    setLoading(false);
                    const url = `/staff/master-products/${master_product.id}/cancel-analyzer/`;
                    post(url);
                } }, "Cancel"))),
        React.createElement("div", { className: 'quote-master-product-variations' }, master_product.quotable ? (React.createElement(React.Fragment, null,
            line_items_dynamic_quote.length > 0 && (React.createElement(Spin, { spinning: loading },
                React.createElement("div", { className: 'flex flex-row' },
                    quote?.editable && (React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                            React.createElement(DynamicQuantitiesSwitcher, { initialValue: true, handleUpdate: async (enabled) => {
                                    const items = line_items_dynamic_quote.map(item => item.id);
                                    await onChangeDynamicQuantities(enabled, items);
                                } })),
                        React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                            React.createElement(BatchReleasesSwitcher, { initialValue: line_items_dynamic_quote[0].batch_shipment, handleUpdate: async (enabled) => {
                                    const items = line_items_dynamic_quote.map(item => item.id);
                                    await onChangeBatchReleases(enabled, items);
                                } })))),
                    quote?.rfq !== null && (React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                        React.createElement("b", null, "Customer Notes: "),
                        " ",
                        master_product.customer_notes))),
                line_items_dynamic_quote.map(line_item => {
                    return (React.createElement(DynamicQuantities, { key: line_item.id, editable: isAnEditableQuote, item: line_item, partVol: master_product.part_volume_inches, totalVol: master_product.total_volume_inches }));
                }))),
            line_items_no_dynamic_quote.length > 0 && (React.createElement("div", { className: 'line-items-standard' },
                React.createElement("div", { className: 'flex flex-row' },
                    quote?.editable && (React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "col-dynamic-quantities my-1 ml-4" },
                            React.createElement(DynamicQuantitiesSwitcher, { initialValue: false, handleUpdate: async (enabled) => {
                                    const items = line_items_no_dynamic_quote.map(item => item.id);
                                    await onChangeDynamicQuantities(enabled, items);
                                    if (initialQuantities.length > 0 &&
                                        line_items_by_master_product[0].quote_item_quantities
                                            .length == 0) {
                                        try {
                                            await QuoteStore.updateRequiredQuantities(line_items_by_master_product[0].id, {
                                                quantities: initialQuantities,
                                            });
                                        }
                                        catch (e) {
                                            notification.error({
                                                key: 'form-',
                                                duration: 8,
                                                description: 'One of the line items has an error',
                                                message: 'Error',
                                                placement: 'top',
                                                maxCount: 1,
                                            });
                                        }
                                    }
                                } })),
                        React.createElement("div", { className: "col-dynamic-quantities my-1 ml-4" },
                            React.createElement(BatchReleasesSwitcher, { initialValue: line_items_no_dynamic_quote[0].batch_shipment, handleUpdate: async (enabled) => {
                                    const items = line_items_no_dynamic_quote.map(item => item.id);
                                    await onChangeBatchReleases(enabled, items);
                                } })))),
                    master_product.customer_notes && (React.createElement("div", { className: "col-dynamic-quantities my-1 ml-4" },
                        React.createElement("b", null, "Customer Notes: "),
                        " ",
                        master_product.customer_notes))),
                React.createElement(LineItems, { editable: true, handleRowSelections: props.setSelectedLineItems, selectedQuotedProducts: props.selectedLineItems, master_product_id: master_product.id, line_items: line_items_no_dynamic_quote, addPartsbadgerProductionQuotingNotes: handleAddERPQuotingNotes, ...props }))))) : (React.createElement("div", { style: { margin: 'auto', maxWidth: 400, padding: 20 } }, master_product.editable ? (React.createElement(Tooltip, { title: 'Click to edit.' },
            React.createElement("div", { onClick: () => {
                    setEditNotes(!editNotes);
                }, style: { cursor: 'pointer' } },
                React.createElement(Alert, { style: { padding: 20 }, message: 'No Quotable', description: React.createElement("div", null,
                        "Notes: ",
                        master_product.notes,
                        " ",
                        React.createElement("br", null),
                        "Category: ",
                        master_product.no_quote_category ?? '--'), type: "info", className: "mb-3" })))) : (React.createElement(Alert, { style: { padding: 20 }, message: 'No Quotable', description: React.createElement("div", null,
                "Notes: ",
                master_product.notes,
                " ",
                React.createElement("br", null),
                "Category: ",
                master_product.no_quote_category ?? '--'), type: "info", className: "mb-3" }))))),
        React.createElement(Modal, { visible: showAddStepFile, centered: true, title: "Add Step File", onCancel: () => setShowAddStepFile(false), footer: [] },
            React.createElement(UploadFile, { fileType: '3D', onUpload: (file) => {
                    updateStepFile(file.response.id);
                } })),
        React.createElement(Modal, { visible: showAddDrawingFile, centered: true, title: "Add Drawing File", onCancel: () => setShowAddDrawingFile(false), footer: [] },
            React.createElement(UploadFile, { fileType: '2D', onUpload: (file) => {
                    updateDrawingFile(file.response.id);
                } })),
        React.createElement(Modal, { visible: showAddOtherFile, centered: true, title: "Other Files", onCancel: () => setShowAddOtherFile(false), footer: [] },
            React.createElement(UploadFile, { onUpload: file => {
                    const current_files = master_product?.other_files.map(r => r.id);
                    post('/staff/master-products/' + master_product.id + '/upload-other-file/', {
                        other_files: [...current_files, file.response.id],
                    })
                        .then((response) => {
                        setShowAddDrawingFile(false);
                        QuoteStore.getMasterProducts(quote_id);
                    })
                        .catch(() => message.error('Error, try again'));
                } })),
        React.createElement(Modal, { visible: adjustmentLeadTime, centered: true, title: "", onCancel: () => setAdjustmentLeadTime(false), footer: false, style: { maxWidth: '700px', borderRadius: '10px' }, width: 700 },
            React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
                React.createElement("div", null,
                    React.createElement("div", { className: 'flex justify-center font-bold text-lg' },
                        "Add Lead Time Adjustment for ",
                        master_product.name),
                    React.createElement(AddLeadTime, { setAddLeadTime: setAdjustmentLeadTime, master_product: master_product.id })))),
        React.createElement(Modal, { visible: viewRecuoteChanges, centered: true, title: "", onCancel: () => setViewRecuoteChanges(false), footer: false, style: { maxWidth: '700px', borderRadius: '10px' }, width: 700 },
            React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
                React.createElement("div", null,
                    React.createElement(RecuoteChanges, { master_product: master_product })))),
        React.createElement(SortDrag, { quoteId: quote_id, open: sortModal, onCancel: () => {
                setSortModal(false);
            }, masterProductId: master_product.id, lineItems: line_items_by_master_product }),
        React.createElement(Drawer, { title: "Product already quoted", width: "80%", closable: true, visible: already_quote, placement: 'left', onClose: () => {
                QuoteStore.getLineItems(quote_id);
                setAlreadyQuote(false);
            } },
            React.createElement(Spin, { spinning: loading },
                React.createElement(LineItemInfo, { line_item: item_already }))),
        dataERPNote && (React.createElement(ModalERPNotes, { quote_id: quote_id, showModalERPNote: showModalERPNote, quote_line_item_id: dataERPNote.id, notes: dataERPNote.partsbadger_production_quoting_notes, setShowModalERPNote: setShowModalERPNote, setDataERPNote: setDataERPNote }))));
};
export default observer(MasterProductDetail);
