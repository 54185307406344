import { Popover } from 'antd';
import React from 'react';
const ThumbnailFileMcMaster = ({ mcmaster_product }) => {
    let quantity_per_package = null;
    const content = mcmaster_product?.Specifications?.map((item) => {
        if (item?.Attribute === 'Quantity per Package') {
            quantity_per_package = item.Values;
        }
        return (React.createElement("tr", { key: item },
            React.createElement("th", null, item.Attribute),
            React.createElement("td", { style: {
                    padding: '.75rem',
                    verticalAlign: 'top',
                    borderTop: '1px solid #dee2e6',
                    color: '#2a2a2a',
                } }, item.Values?.toString())));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { title: "Mc Master", content: React.createElement("div", { style: {
                    height: 400,
                    overflowY: 'auto',
                } },
                React.createElement("table", null, content)) },
            React.createElement("div", { className: 'flex flex-row items-center' },
                React.createElement("div", { className: 'ml-2' }, mcmaster_product?.Links?.filter((item) => item?.Key === 'Price')?.map((item) => {
                    return item.Value.map((p) => {
                        return (React.createElement("div", { key: p, className: 'flex flexRow' },
                            "$",
                            p?.Amount,
                            " per ",
                            p?.UnitOfMeasure,
                            ' ',
                            quantity_per_package && `of ${quantity_per_package}`,
                            " if ",
                            p?.MinimumQuantity,
                            ' ',
                            p?.UnitOfMeasure,
                            " is ordered"));
                    });
                }))))));
};
export default ThumbnailFileMcMaster;
