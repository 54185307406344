import React, { useState } from 'react';
import { CloseCircleOutlined, EditOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Spin, Form, Button, notification } from 'antd';
import { ErrorRender } from '../ErrorRender';
const EditableFieldWrapper = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const static_value = (React.createElement("a", { onClick: () => setIsEditing(true) }, props?.defaultValue?.label || React.createElement(PlusCircleOutlined, null)));
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields()
            .then(async (values) => {
            setIsEditing(false);
            setIsLoading(true);
            if (props.handleSave) {
                props.handleSave(values.field).finally(() => {
                    setIsLoading(false);
                });
            }
            else {
                setIsLoading(false);
            }
        })
            .catch(errorInfo => {
            notification.error({
                duration: 10,
                message: 'Error',
                description: React.createElement(ErrorRender, { error: errorInfo }),
                placement: 'topRight',
            });
        });
    };
    return (React.createElement("div", { className: 'flex flex-col items-center', title: 'Click to Edit' },
        React.createElement(Form, { form: form },
            React.createElement(Form.Item, { name: "field", noStyle: true }, isEditing ? props?.children : static_value),
            !isEditing && (React.createElement("a", { className: 'ml-2', onClick: () => setIsEditing(true) },
                React.createElement(EditOutlined, null))),
            isEditing && (React.createElement(Button, { onClick: handleSubmit, type: "link", icon: React.createElement(SaveOutlined, null) }, "Save!")),
            isEditing && props.cancellable && (React.createElement(Button, { onClick: () => {
                    setIsEditing(false);
                }, type: "link", style: { margin: 0 }, icon: React.createElement(CloseCircleOutlined, null) }, "Close")),
            React.createElement(Spin, { spinning: isLoading }))));
};
export default EditableFieldWrapper;
