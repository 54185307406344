import * as React from 'react';
import { useState } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
let busy = null;
export const Contacts = (props) => {
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const token = localStorage.getItem('token');
    const { account } = props;
    const fetchContacts = (params) => {
        axios
            .get(`${BASE_URL}/staff/contacts/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
            params: params,
        })
            .then(response => {
            setContacts(response.data.results);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    return (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search contact by  name or email", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, onSearch: name => {
            if (busy) {
                clearTimeout(busy);
            }
            const params = {
                search: name,
                exclude_account_payable: true,
            };
            if (account) {
                params['account'] = props.account;
            }
            if (props.exclude_account_payable) {
                params['exclude_account_payable'] = props.exclude_account_payable;
            }
            setLoading(true);
            busy = setTimeout(() => fetchContacts(params), 400);
        }, ...props }, contacts.map(entity => (React.createElement(Select.Option, { key: entity.id, 
        //@ts-ignore
        contact: entity, account: entity.account, first_name: entity.first_name, last_name: entity.last_name, company: entity?.account, phone: entity.phone, email: entity.email, zip_code: entity.zip_code },
        entity.fullname,
        " || ",
        entity.email)))));
};
