import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import { QuoteStore } from '../../Stores';
import { ErrorRender } from '@partsbadger/library';
import { SortAscendingOutlined } from '@ant-design/icons';
const ApplicationCostForm = (props) => {
    const { item, handleSave, form } = props;
    const { getFieldDecorator } = form;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const quote = QuoteStore.quote;
    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    quote: quote?.id,
                    additional_requirement: values.additional_requirement,
                    price: values.price,
                };
                setLoading(true);
                handleSave(payload)
                    .then(() => {
                    form.resetFields();
                })
                    .finally(() => {
                    setLoading(false);
                });
            }
        });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(ErrorRender, { error: error }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(Form.Item, null, getFieldDecorator('additional_requirement', {
                    rules: [{ required: true, message: 'Please select a value!' }],
                    initialValue: item?.additional_requirement,
                })(React.createElement(Input, { style: { width: '90%' }, placeholder: 'Application' })))),
            React.createElement(Col, { sm: 6 },
                React.createElement(Form.Item, null, getFieldDecorator('price', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: item?.price,
                })(React.createElement(Input, { style: { width: '90%' }, placeholder: '0.00' })))),
            React.createElement(Col, { sm: 6 },
                React.createElement(Form.Item, null,
                    React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }), loading: loading }, "Save"),
                    props.setReorderingAppCost && (React.createElement("span", { className: 'px-2' },
                        React.createElement(Tooltip, { title: 'Sort App. Costs' },
                            React.createElement(Button, { onClick: () => props.setReorderingAppCost && props.setReorderingAppCost(true) },
                                React.createElement(SortAscendingOutlined, { style: { fontSize: '20px' } }))))))))));
};
export default Form.create()(ApplicationCostForm);
