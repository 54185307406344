import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FileUnknownOutlined, LoadingOutlined, } from '@ant-design/icons';
import { Button, Checkbox, Modal, notification, Radio, Menu, Spin, Table, Alert, Dropdown, Divider, Typography, Badge, } from 'antd';
import { injectStores } from '@mobx-devtools/tools';
import _ from 'lodash';
import 'animate.css';
import { CheckoutForm } from './components/Checkout';
import BulkUpdateItems from './components/BulkUpdateItems';
import { UploadPartFilesMultiple } from './components/UploadParts';
import QuoteStore from './stores/QuoteStore';
import PartStore from './stores/PartStore';
import SocketConnection from './utils/socket_connection';
import { ErrorRender, Price, Quotes } from '@partsbadger/library';
import Search from 'antd/lib/input/Search';
import { MasterProduct } from './components/MasterProduct';
import '../style/styles.css';
if (process.env.NODE_ENV !== 'production') {
    // MobX devtools
    injectStores({ QuoteStore, PartStore });
}
const { Title } = Typography;
export const Loading = () => {
    const antIcon = React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true });
    return (React.createElement("div", { className: 'loader', style: { margin: '0 auto' } },
        React.createElement(Spin, { indicator: antIcon, style: { display: 'block' } })));
};
let pollingQuoteInterval = null;
const QuoteOneTrueForm = observer(({ quote, token = '', acquisition_source, signed_uid, is_new_contact = false, current_user, for_staff = false, onQuoteSubmitted, isReceiveSms, onRequestOrder, }) => {
    // const [previewQuote, setPreviewQuote] = useState(false);
    const [bulkUpdate, showBulkUpdate] = useState(false);
    // Save the selected items for bulk updates
    const [selectedItems, setSelectedItems] = useState([]);
    const [applyBatch, setApplyBatch] = useState(false);
    const [modalPreviousParts, setModalPreviousParts] = useState(false);
    const [deletePartsModalVisible, setDeletePartsModalVisible] = useState(false);
    const [typeSearch, setTypeSearch] = useState('quote');
    const [selectedRowKeys, setRowKeys] = useState([]);
    useEffect(() => {
        if (signed_uid) {
            QuoteStore.setSignedUID(signed_uid);
        }
        QuoteStore.getAdditionalRequirements();
        QuoteStore.setQuote(quote);
        QuoteStore.setForStaff(for_staff);
        if (current_user) {
            QuoteStore.setCurrentUser(current_user);
        }
        QuoteStore.setToken(token);
        QuoteStore.setIsNewContact(is_new_contact);
    }, []);
    useEffect(() => {
        if (pollingQuoteInterval) {
            clearInterval(pollingQuoteInterval);
        }
        // Check if there are pending files to be analyzed
        if (QuoteStore.needsToUsePolling && !QuoteStore.isAllFileAnalyzingCompleted) {
            pollingQuoteInterval = setInterval(() => {
                QuoteStore.getQuote();
                if (QuoteStore.isAllFileAnalyzingCompleted) {
                    clearInterval(pollingQuoteInterval);
                }
            }, 30000);
        }
        return () => {
            if (pollingQuoteInterval) {
                clearInterval(pollingQuoteInterval);
            }
        };
    }, [QuoteStore.needsToUsePolling, QuoteStore.isAllFileAnalyzingCompleted]);
    useEffect(() => {
        QuoteStore.getMaterials();
        QuoteStore.getFinishes();
        QuoteStore.getCoatings();
    }, []);
    useEffect(() => {
        // Only authenticated users can access to contact details
        if (quote.contact?.id && QuoteStore.token) {
            QuoteStore.getContactDetails(quote.contact.id);
            QuoteStore.getAdditionalRequirementsContactAccount(quote.contact.id);
        }
    }, [quote.contact?.id]);
    useEffect(() => {
        if (acquisition_source !== 'Portal') {
            QuoteStore.setAcquisitionSource(acquisition_source);
        }
    }, [acquisition_source]);
    /**
     * Add or remove items from the selected array list
     */
    const handleSelect = (key, selected) => {
        if (selected) {
            setSelectedItems([...selectedItems, key]);
        }
        else {
            setSelectedItems(selectedItems.filter(id => id !== key));
        }
    };
    /**
     * Select all for bulk update
     */
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            // Get all master products
            const selected = _.map(master_products).map(line_items => line_items[0].product.master_product);
            setSelectedItems(selected);
        }
        else {
            setSelectedItems([]);
        }
    };
    if (!QuoteStore.quote) {
        return null;
    }
    const master_products = _.groupBy(QuoteStore.quote.line_items, item => {
        return item.product.master_product;
    });
    const isIndeterminate = selectedItems.length > 0 && selectedItems.length < _.size(master_products);
    /**
     * Add existing products from other quotes to the current quote in process.
     */
    const add_existing_items = async () => {
        try {
            await QuoteStore.createLineItemFromExistingLineItems(selectedRowKeys);
            reloadTableRows();
            notification.success({ message: 'Success', description: `Items were added correctly.` });
        }
        catch (error) {
            notification.error({ message: 'Error', description: React.createElement(ErrorRender, { error: error }) });
        }
    };
    /**
     * Search for existing products in other quotes, related to the account of the quote in process in modal-previous-parts.
     * @param value
     */
    const onSearchPreviousPart = async (value) => {
        await QuoteStore.searchPreviousParts({
            name: value,
            account: QuoteStore.contact?.account.id,
        }).then(() => { });
    };
    /**
     * Function that runs when you stop typing in the search field of a part in modal-previous-parts.
     */
    const handleSearchPreviousPart = _.debounce(async (value) => {
        if (value) {
            await onSearchPreviousPart(value);
        }
        else {
            QuoteStore.clean_previous_items();
        }
    }, 500);
    /**
     * Reset the selectedRowKeys in table-previous-parts.
     */
    const reloadTableRows = () => {
        setRowKeys([]);
    };
    /**
     * Captures the keys of the selected rows in table-previous-parts.
     * @param selectedRowKeys
     */
    const onSelectChange = selectedRowKeys => {
        setRowKeys(selectedRowKeys);
    };
    /**
     * Renders the checkboxes in the table-previous-parts.
     */
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    if (QuoteStore.quote.line_items?.length === 0 && PartStore?.fileParts.length > 0) {
        React.createElement(LoadingOutlined, null);
    }
    return (React.createElement("div", null,
        QuoteStore.isRequote && (React.createElement(Alert, { className: "flex flex-row mb-4", type: "warning", showIcon: true, message: 'Please Note', description: React.createElement(React.Fragment, null, "This is a Requote") })),
        React.createElement(React.Fragment, null,
            QuoteStore.quote.line_items?.length > 0 ? (React.createElement(React.Fragment, null,
                bulkUpdate && (React.createElement(Modal, { title: 'Bulk Update', open: bulkUpdate, onCancel: () => showBulkUpdate(false), footer: null, centered: true },
                    React.createElement(BulkUpdateItems, { master_products: selectedItems, handleClose: () => showBulkUpdate(false), for_staff: for_staff }))),
                React.createElement("div", { className: "d-flex flex-row align-items-center w-100 justify-content-between" },
                    React.createElement("h2", { className: "my-2 font-bold", style: { fontSize: '1.5rem' } },
                        "Parts & Specifications",
                        React.createElement("div", { className: "d-block" },
                            React.createElement(Button, { type: "link", className: "m-0 p-0 text-muted", onClick: () => QuoteStore.collapseAllLineItems() }, "Collapse All"),
                            React.createElement(Divider, { type: "vertical", className: "mx-1" }),
                            React.createElement(Button, { type: "link", className: "m-0 p-0 text-muted", onClick: () => QuoteStore.expandAllLineItems() }, "Expand All"))),
                    React.createElement("div", { className: "ml-auto text-right flex-grow-1" },
                        QuoteStore.quote.line_items?.length > 0 && (React.createElement(Checkbox, { checked: selectedItems.length > 0, indeterminate: isIndeterminate, onChange: handleSelectAll }, "Select All")),
                        React.createElement(Dropdown, { disabled: selectedItems.length === 0 || QuoteStore.isRequote, trigger: ['click'], placement: "bottomRight", overlay: React.createElement(Menu, { items: [
                                    {
                                        label: 'Bulk Update',
                                        key: '1',
                                        onClick: () => showBulkUpdate(true),
                                    },
                                    {
                                        label: 'Delete Selected Parts',
                                        key: '2',
                                        onClick: () => setDeletePartsModalVisible(true),
                                    },
                                ] }) },
                            React.createElement(Button, { size: "small" }, "Actions"))),
                    React.createElement("div", { className: 'ml-auto' }, current_user?.is_staff && QuoteStore.contact?.account && (React.createElement("div", { style: {
                            textAlign: 'right',
                        } },
                        React.createElement(Button, { size: 'small', type: 'primary', onClick: async () => {
                                setModalPreviousParts(true);
                            } }, "Add a previously quoted product"))))),
                React.createElement("div", null, _.chain(master_products)
                    .map((line_items, key) => {
                    return { key, line_items };
                })
                    .sortBy(({ line_items }) => {
                    const line_item = line_items[0];
                    return line_item.position;
                })
                    .map(({ line_items, key }) => {
                    // Send one item to display the master product data
                    const line_item = line_items[0];
                    const isSelected = selectedItems.includes(line_item.product.master_product);
                    return (React.createElement("div", { key: key, className: "mb-3" },
                        React.createElement(MasterProduct, { for_staff: for_staff, item: line_item, line_items: line_items, isSelected: isSelected, handleSelect: selected => {
                                handleSelect(line_item.product.master_product, selected);
                            } })));
                })
                    .value()))) : null,
            React.createElement(UploadPartFilesMultiple, { is_staff: current_user?.is_staff, showUploadTypeTabs: false, showExtraUploadInfo: false, showITARNotice: QuoteStore.quote.line_items.length === 0 }),
            QuoteStore.quote.uid && (React.createElement("div", { style: { display: 'block' } },
                React.createElement(SocketConnection, null))),
            QuoteStore.quote.line_items?.length > 0 ? (React.createElement(React.Fragment, null,
                React.createElement(CheckoutForm, { onQuoteSubmitted: onQuoteSubmitted, isReceiveSms: isReceiveSms, onRequestOrder: onRequestOrder, is_tester: current_user?.is_tester }),
                modalPreviousParts && current_user?.is_staff && QuoteStore.contact?.account && (React.createElement(Modal, { className: 'modal-previous-parts', title: React.createElement(React.Fragment, null,
                        React.createElement("span", null, "Search by: "),
                        React.createElement(Radio.Group, { buttonStyle: "solid", onChange: checked => {
                                QuoteStore.clean_previous_items();
                                setTypeSearch(checked.target.value);
                            }, value: typeSearch },
                            React.createElement(Radio.Button, { value: 'quote' }, "Quote"),
                            React.createElement(Radio.Button, { value: 'parts' }, "Parts"))), open: modalPreviousParts, closable: true, width: 1250, onCancel: () => {
                        reloadTableRows();
                        QuoteStore.clean_previous_items();
                        setModalPreviousParts(false);
                    }, footer: false },
                    React.createElement("div", { className: 'flex flex-row pb-4' },
                        typeSearch === 'quote' ? (React.createElement("div", { className: 'w-1/2 mx-4' },
                            React.createElement(Quotes, { show_in_zoho_alert: false, className: 'input-field', onSelect: async (item) => {
                                    await QuoteStore.getQuoteLineItems(item.key);
                                }, filters: {
                                    stage: 'Completed',
                                }, account: QuoteStore.contact?.account?.id }))) : (React.createElement("div", { className: 'w-1/2 mx-4' },
                            React.createElement(Search, { allowClear: true, onSearch: onSearchPreviousPart, onChange: (data) => handleSearchPreviousPart(data.target.value), placeholder: "Search by part name...", size: "small" }))),
                        React.createElement("div", { className: 'w-1/2 mx-4' },
                            React.createElement(Button, { type: 'primary', size: 'small', disabled: selectedRowKeys.length <= 0, onClick: () => add_existing_items() },
                                "Add (",
                                selectedRowKeys.length,
                                ") Parts"),
                            React.createElement(Button, { className: 'mx-4', size: 'small', type: "default", onClick: reloadTableRows }, "Remove selected"))),
                    React.createElement("div", null,
                        React.createElement(Table, { className: 'table-previous-parts', rowKey: (record) => String(record.id), rowSelection: rowSelection, columns: [
                                {
                                    title: 'Preview',
                                    dataIndex: 'step_file',
                                    render: (t, record) => {
                                        if (record.product.attachments.file3d &&
                                            record.product.attachments.file3d.image) {
                                            return (React.createElement("div", null,
                                                React.createElement("img", { style: { width: 60, cursor: 'pointer' }, src: record.product.attachments.file3d?.image })));
                                        }
                                        return React.createElement(FileUnknownOutlined, { style: { fontSize: 40 } });
                                    },
                                },
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    render: (name, record) => (React.createElement(React.Fragment, null,
                                        React.createElement("div", null, name),
                                        React.createElement("div", { style: { display: 'flex' } },
                                            React.createElement("span", { style: { fontSize: 12 } },
                                                record.product.dimensions_dict.length.toFixed(3),
                                                record.product.dimensions_dict.unit,
                                                " x",
                                                ' ',
                                                record.product.dimensions_dict?.width.toFixed(3),
                                                record.product.dimensions_dict.unit,
                                                " x",
                                                ' ',
                                                record.product.dimensions_dict?.height.toFixed(3),
                                                record.product.dimensions_dict.unit)))),
                                },
                                {
                                    title: 'Source',
                                    dataIndex: 'source',
                                    render: (_, record) => (React.createElement("div", null,
                                        React.createElement("div", null, record.quote_related?.source),
                                        React.createElement("div", null,
                                            record.quote_related?.is_dynamic && (React.createElement(Badge, { count: 'Dynamic', style: {
                                                    fontSize: 10,
                                                    height: 20,
                                                    backgroundColor: '#7576c4',
                                                } })),
                                            record.quote_related?.type === 'Requote' && (React.createElement(Badge, { count: 'Requote', style: { backgroundColor: '#8E44AD' } }))))),
                                },
                                {
                                    title: 'Information',
                                    dataIndex: 'information',
                                    render: (_, record) => (React.createElement(React.Fragment, null,
                                        React.createElement("div", null,
                                            React.createElement("span", null, "Material: "),
                                            record.product.material
                                                ? record.product.material
                                                : record.product.custom_material),
                                        React.createElement("div", null,
                                            React.createElement("span", null, "Coating: "),
                                            record.product.coatings
                                                ? record.product.coatings
                                                : record.product.custom_coating),
                                        React.createElement("div", null,
                                            React.createElement("span", null, "Finish: "),
                                            record.product.finishes
                                                ? record.product.finishes
                                                : record.product.custom_finish))),
                                },
                                {
                                    title: 'Unit Price',
                                    dataIndex: 'unit_price',
                                    render: (unit_price) => React.createElement(Price, { value: unit_price }),
                                },
                                {
                                    title: 'Qty',
                                    dataIndex: 'quantity',
                                },
                                {
                                    title: 'Total Price',
                                    dataIndex: 'total_price',
                                    render: (total_price) => React.createElement(Price, { value: total_price }),
                                },
                            ], loading: QuoteStore.loading_quote_items, dataSource: QuoteStore.previous_quoted_parts })))))) : null),
        React.createElement(Modal, { open: deletePartsModalVisible, centered: true, onCancel: () => setDeletePartsModalVisible(false), onOk: async () => {
                if (QuoteStore.quote) {
                    for await (const line_item of selectedItems) {
                        QuoteStore.deleteMasterProduct(line_item);
                    }
                }
                setDeletePartsModalVisible(false);
            } },
            React.createElement(Title, { level: 3 }, "Delete selected parts from quote?"))));
});
export default QuoteOneTrueForm;
