import React, { useCallback, useState } from 'react';
import { Select, Spin } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import Suggestion from './Suggestions';
const Coatings = observer((props) => {
    const [isLoading, setIsLoading] = useState(false);
    const items = props.coatings;
    const debouncedSearch = useCallback(_.debounce(search => fetchData(search), 400), []);
    const fetchData = async (search) => {
        setIsLoading(true);
        await props.handleSearch({ search: search });
        setIsLoading(false);
    };
    const menu = items.map(item => {
        return React.createElement(Select.Option, { key: item.id }, item.needs_manual_adjust ? `* ${item.name}` : item.name);
    });
    return (React.createElement("div", null,
        React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, size: 'small', placeholder: "Search", filterOption: false, dropdownMatchSelectWidth: false, style: { width: '100%' }, notFoundContent: isLoading ? React.createElement(Spin, { size: "small", spinning: true }) : null, onSearch: debouncedSearch, ...props },
            React.createElement(Select.OptGroup, { label: 'Uncategorized' }, menu)),
        //@ts-ignore
        props.value?.label !== 'Custom' && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, props.suggestions?.map(suggestion => (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: () => {
                const val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    // @ts-ignore
                    props.onSelect(val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })))))));
});
export default Coatings;
