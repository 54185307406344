import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, List, Upload } from 'antd';
import { Users } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
export const Chat = observer(Form.create()(({ messages = [], onSendMessage, form, height }) => {
    const ref = useRef(null);
    const [visibleCarbonCopy, setVisibleCarbonCopy] = useState(true);
    const { getFieldDecorator, getFieldValue } = form;
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current?.scrollHeight;
        }
    }, [messages?.length]);
    const onSend = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    body: values.body,
                    attachments: values.attachment ? values.attachment.map(f => f.originFileObj) : [],
                    notify_owner: values.notify_owner,
                    carbon_copy: values.carbon_copy,
                };
                if (onSendMessage) {
                    onSendMessage(payload);
                    form.resetFields();
                }
            }
        });
    };
    // if (ChatStore.loading) {
    //     return <Spin spinning={true}/>
    // }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const ordered_messages = useMemo(() => {
        return messages.reverse();
    }, [messages]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, id: "messages", className: 'chat-list', style: {
                maxHeight: height || 600,
                height: height || 600,
                overflowY: 'auto',
                border: 'solid 1px #d4d4d4',
            } },
            React.createElement(List, { className: "comment-list px-4", itemLayout: "horizontal", dataSource: ordered_messages, renderItem: (item) => (React.createElement("li", { className: "m-4 shadow p-4" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "mb-2 text-lg" }, !!item?.body && (React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: item?.body,
                            } }))),
                        item.attachment && (React.createElement("div", { className: 'mt-2' },
                            React.createElement("a", { target: '_blank', onClick: () => {
                                    if (item.attachment) {
                                        window.open(item.attachment, '', 'width=800,height=800,left=200,top=200');
                                    }
                                } },
                                item.attachment?.split('?')[0]?.split('/')?.pop(),
                                React.createElement(LegacyIcon, { type: 'paper-clip' })))),
                        item.attachments.map(attachment => {
                            return (React.createElement("div", { key: attachment.filename, className: 'mt-2' },
                                React.createElement("a", { target: '_blank', onClick: () => {
                                        window.open(attachment.file, '', 'width=800,height=800,left=200,top=200');
                                    } },
                                    attachment.filename,
                                    React.createElement(LegacyIcon, { type: 'paper-clip' }))));
                        })),
                    React.createElement("div", null,
                        item.author?.fullname,
                        " at ",
                        dayjs(item.created_time).format('LLL'),
                        item.author && React.createElement(LegacyIcon, { style: { marginLeft: '1rem' }, type: 'mail' })),
                    item?.carbon_copy && item?.carbon_copy?.length > 0 && (React.createElement("div", null,
                        React.createElement("span", null, "Cc: "),
                        item?.carbon_copy?.map((a) => {
                            return React.createElement(React.Fragment, null,
                                a?.fullname,
                                " | ");
                        }))))) })),
        React.createElement("div", { className: 'chat-form  bottom-0 w-11/12 mb-2 mt-4', style: {
                left: 5,
            } },
            React.createElement(Form, { className: '' },
                React.createElement("div", { className: '' },
                    visibleCarbonCopy && (React.createElement(Form.Item, { label: 'Cc', className: 'm-0 text-center' }, getFieldDecorator('carbon_copy', {
                        rules: [
                            {
                                required: false,
                                message: 'Please input a value!',
                            },
                        ],
                    })(React.createElement(Users, { users: QuoteStore.users, mode: 'multiple' })))),
                    React.createElement(Form.Item, { className: 'm-0' }, getFieldDecorator('body', {
                        rules: [
                            {
                                required: !getFieldValue('attachment'),
                                message: 'Please input a value!',
                            },
                        ],
                    })(React.createElement(Input.TextArea, { onKeyPress: e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                onSend();
                            }
                        } }))),
                    React.createElement("div", { className: "flex items-center justify-around" },
                        React.createElement(Form.Item, { className: 'm-0 text-center' }, getFieldDecorator('attachment', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input a value!',
                                },
                            ],
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                        })(React.createElement(Upload, { multiple: true, name: "avatar", showUploadList: true, beforeUpload: () => false },
                            React.createElement(Button, null, "Add File")))),
                        React.createElement(Form.Item, { help: 'Notify Owner', className: 'm-0 text-center' }, getFieldDecorator('notify_owner', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input a value!',
                                },
                            ],
                            valuePropName: 'checked',
                            initialValue: visibleCarbonCopy,
                        })(React.createElement(Checkbox, { onChange: e => setVisibleCarbonCopy(e.target.checked) }))),
                        React.createElement(Button, { onClick: onSend, type: "primary", htmlType: "submit" }, "Send")))))));
}));
