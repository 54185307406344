import { notification } from 'antd';
export const addMessage = (mgsTitle, msg) => {
    notification.open({
        message: mgsTitle,
        description: msg,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
};
export const openNotificationWithIcon = (type, message, description, duration = 5) => {
    notification[type]({
        message: message,
        description: description,
        duration: duration,
    });
};
