import * as React from 'react';
import { Select } from 'antd';
import countries from 'country-region-data';
export const Countries = props => {
    return (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, optionKeyProp: 'value', 
        // size={'small'}
        // style={{ width: '100%' }}
        dropdownMatchSelectWidth: false, placeholder: "Select country", filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, ...props }, countries?.map(row => {
        return (React.createElement(Select.Option, { key: row.countryShortCode, value: row.countryShortCode }, row.countryName));
    })));
};
