import React, { useEffect } from 'react';
import { Card, notification } from 'antd';
import { observer } from 'mobx-react';
import { UploadDocument } from '../../OrderRequirements/UploadDocument';
import { AccountStore } from '../../../stores/AccountStore';
import { ErrorRender } from '@partsbadger/library';

interface Props {
    account_id: number;
}

export const DocumentsAdditional = observer(({ account_id }: Props) => {
    useEffect(() => {
        // AccountStore.getAccount(account_id);
    }, [account_id]);

    const handleChange = async (info: any) => {
        const { status } = info.file;
        if (status === 'done') {
            await AccountStore.getAccount(account_id);
        }
    };

    const remove_document = async (index: number, document_id: number) => {
        try {
            await AccountStore.remove_account_document(index, document_id);
        } catch (e: any) {
            notification.error({ message: 'Error', description: <ErrorRender error={e} /> });
        }
    };

    return (
        <div style={{ width: '100%', marginBottom: '20px' }}>
            <Card title="Additional Documents">
                <UploadDocument
                    multiple={true}
                    model_id={account_id}
                    documents={AccountStore.account_documents_related}
                    remove_document={remove_document}
                    handleChange={handleChange}
                    model={'accounts'}
                />
            </Card>
        </div>
    );
});
