import { Popover, Tag, Image } from 'antd';
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
const SuggestionField = props => {
    const maxVersion = Math.max(...props.suggestions.map(item => parseFloat(item.version)));
    const latestSuggestions = props.suggestions.filter(item => parseFloat(item.version) === maxVersion);
    // Dynamic label based on suggestion type
    const suggestionLabel = `Suggested ${props.suggestionType === 'Finish' ? 'Finishes' : `${props.suggestionType}s`}${maxVersion === 2 ? ' (AI)' : ''}:`;
    // Dynamic extracted text based on suggestion type
    const extractedTextPrefix = props.suggestionType === 'Material'
        ? 'Material Extracted:'
        : props.suggestionType === 'Finish'
            ? 'Finish Extracted:'
            : 'Coating Extracted:';
    const extractedText = props.suggested_text ? (React.createElement("span", null,
        React.createElement("strong", null, extractedTextPrefix),
        " ",
        props.suggested_text)) : ('');
    return (React.createElement("div", { className: "d-flex flex-column rounded-sm text-green mb-2", style: { padding: '4px', border: '3px solid #a3dab673' } },
        extractedText && props.for_staff && (React.createElement("div", { style: {
                padding: '8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                marginBottom: '8px',
                color: '#333',
            } }, extractedText)),
        React.createElement("div", { className: "d-flex flex-row align-items-start" },
            React.createElement("span", { style: { width: '150px', color: '#008041' }, className: "text-xs font-weight-bold" }, suggestionLabel),
            React.createElement(Scrollbars, { style: { width: '100%' }, autoHide: true, autoHeight: true, autoHeightMin: 50, autoHeightMax: 100 }, latestSuggestions.map((item, index) => (React.createElement("div", { key: index, className: 'suggestion', style: {
                    display: 'inline-block',
                    cursor: 'pointer',
                } },
                React.createElement(Popover, { title: React.createElement("div", { className: 'flex flex-row justify-start' },
                        React.createElement("div", { className: 'font-bold' }, "Suggested Option: "),
                        React.createElement("div", { className: 'font-normal ml-2' }, item.name)), placement: "top", content: item.thumbnail && (React.createElement("div", { className: 'border', style: {
                            maxWidth: 500,
                            background: '#d4d4d4',
                            textAlign: 'center',
                        } },
                        React.createElement(Image, { preview: false, style: {
                                maxWidth: '90%',
                                margin: 'auto',
                            }, src: item.thumbnail }))) },
                    React.createElement(Tag, { className: "border border-dashed border-green", style: { maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }, color: item.version === '1.0' ? 'green' : 'blue', onClick: () => {
                            props.handleClick(item.id.toString(), item.name);
                        } }, item.name)))))))));
};
export default SuggestionField;
