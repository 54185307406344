import { get, post } from '@partsbadger/utils';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography } from 'antd';
import React from 'react';
import ConvertPngToPdf from './ConvertPngToPdf';
import { QuoteStore } from '../../Stores/QuoteStore';
const { Paragraph } = Typography;
const OtherFiles = ({ master_product, showAddOtherFile, quote_id, }) => {
    const other_files = master_product?.other_files.map(file => {
        return (React.createElement("div", { className: "", key: file.id },
            React.createElement("div", { className: 'link inline-block', onClick: e => {
                    e.stopPropagation();
                    get(`/staff/files/${file.id}/signed-url/`).then((response) => {
                        window.open(response.url, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800');
                    });
                } },
                file.filename,
                "\u00A0"),
            React.createElement(LegacyIcon, { title: 'Remove file', type: 'cross', className: 'link text-red-600', onClick: e => {
                    e.stopPropagation();
                    post(`/staff/files/${file.id}/remove-internal-file/`).then(() => {
                        QuoteStore.getMasterProducts(quote_id);
                    });
                } }),
            React.createElement(ConvertPngToPdf, { quote_id: quote_id, master_product: master_product, file: file })));
    });
    const converted_2d_file = master_product.converted_drawing_file ?? master_product.drawing_file?.convertable_file ?? null;
    const converted_3d_file = master_product.converted_3d_file ?? master_product.step_file?.convertable_file ?? null;
    return (React.createElement("div", { style: {
            display: 'flex',
            justifyItems: 'flex-start',
            alignContent: 'center',
            alignItems: 'center',
        } },
        React.createElement("strong", null, "Other Files:"),
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'flex-start',
                alignContent: 'center',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            } },
            other_files,
            React.createElement(RenderConverted2dFile, { url: converted_2d_file }),
            React.createElement(RenderConverted2dFile, { url: converted_3d_file })),
        master_product.editable && (React.createElement(LegacyIcon, { className: 'link ml-1', title: 'Add or Remove files', type: 'plus', onClick: showAddOtherFile }))));
};
const RenderConverted2dFile = ({ url }) => {
    if (url) {
        const filename = url.split('/');
        return (React.createElement("span", { style: { display: 'inline' }, title: 'Original File' },
            React.createElement("a", { href: url, className: 'link' },
                React.createElement("span", null,
                    decodeURI(filename[filename.length - 1].split('?')[0]),
                    ", \u00A0"))));
    }
    return null;
};
export default OtherFiles;
