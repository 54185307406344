import React, { useState } from 'react';
import { CodeSandboxOutlined, DropboxOutlined } from '@ant-design/icons';
import { Tabs, Radio } from 'antd';
import { AutoCompletedRecord } from '../../AutoCompleteRecord';
import { hasPermission } from 'quotes-frontend/src/components/User';
import IntakeStore from '../../../Stores/IntakeStore';
import { useHistory } from 'react-router-dom';
const HeadFilters = () => {
    const [isIntakeOrOuttake, setIsIntakeOrOuttake] = useState('receive');
    const history = useHistory();
    const items = [
        {
            label: (React.createElement("div", { className: "flex items-center" },
                React.createElement(DropboxOutlined, null),
                "Intake")),
            key: 'receive',
            children: React.createElement("div", null),
        },
        {
            label: (React.createElement("div", { className: "flex items-center", "aria-disabled": true },
                React.createElement(CodeSandboxOutlined, null),
                "Outtake")),
            key: 'ship',
            children: React.createElement("div", null),
        },
    ];
    const onChange = (key) => {
        setIsIntakeOrOuttake(key);
        IntakeStore.setReceiveOrShip(key);
        if (key === 'ship' && IntakeStore.vendorType === 'barcode-vendor') {
            IntakeStore.setVendorType('sales-order');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mb-5" },
            React.createElement(Tabs, { items: items.filter(item => {
                    return IntakeStore.vendorType === 'purchase-orders' ? item.key === 'receive' : item;
                }), tabBarStyle: { backgroundColor: '#FFF', marginBottom: 0 }, onChange: onChange, defaultActiveKey: "receive" })),
        React.createElement("div", { className: "mx-4", style: { minWidth: 150 } },
            isIntakeOrOuttake === 'receive' && (React.createElement("div", { className: "flex flex-row" },
                React.createElement("strong", { className: "mx-4" }, "Filters:"),
                React.createElement(Radio.Group, { className: "flex flex-row", value: IntakeStore.vendorType, onChange: e => {
                        IntakeStore.setVendorType(e.target.value);
                        history.push(`?id=&type=${e.target.value}`);
                    } },
                    hasPermission('intake.view_salesorderintake') && (React.createElement(React.Fragment, null,
                        React.createElement(Radio, { value: "sales-order" }, "Sales order"),
                        React.createElement(Radio, { value: "barcode-vendor" }, "Barcode Scanner"),
                        React.createElement(Radio, { value: "rma" }, "RMA"))),
                    hasPermission('finance.add_purchaseorderintake') && (React.createElement(Radio, { value: "purchase-orders" }, "Purchase Orders"))))),
            React.createElement(AutoCompletedRecord, { isVendorNumber: IntakeStore.vendorType, handleSelect: sales => {
                    history.push(`?id=${sales.key}&type=${IntakeStore.vendorType}`);
                } }))));
};
export default HeadFilters;
