import React, { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
export const PreviewQuote = ({ id, name }) => {
    const [loadingPreview, setLoadingPreview] = useState(false);
    /**
     * Open quote pdf in new window
     */
    const previewQuote = async () => {
        const data = await SalesOrderStore.getQuotePDF(id);
        const file = new Blob([data], { type: 'application/pdf; charset=utf-8' });
        const fileURL = URL.createObjectURL(file);
        const left = window.screen.width - 960;
        window.open(fileURL, '', `width=960,height=1200,left=${left}`);
        setLoadingPreview(false);
    };
    return (React.createElement(Button, { type: 'link', loading: loadingPreview, onClick: () => {
            setLoadingPreview(true);
            previewQuote().finally(() => setLoadingPreview(false));
        } },
        React.createElement(FilePdfOutlined, null),
        " ",
        name));
};
