import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { observer } from 'mobx-react';
const styles = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 5,
    alignItems: 'center',
};
export const ItemsEditForm = observer(({ record, renderStepFile, renderDrawingFile, setShowAddStepFile, setShowAddDrawingFile }) => {
    return (React.createElement("div", { className: 'flex flex-col w-full' },
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "3D File:"),
            " \u00A0",
            record?.name_3d_file ? (React.createElement(React.Fragment, null, record?.name_3d_file && (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 3D file', className: 'link', onClick: () => {
                        if (record.production_3d_file_id) {
                            renderStepFile(record.production_3d_file_id);
                        }
                    } },
                    React.createElement("a", null, record.name_3d_file)),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: () => setShowAddStepFile(true) }))))) : (React.createElement(React.Fragment, null, record.production_3d_file ? (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 3d file', className: 'link', onClick: () => {
                        if (record?.production_3d_file?.id) {
                            renderStepFile(record.production_3d_file.id);
                        }
                    } },
                    React.createElement("a", null,
                        " ",
                        record.production_3d_file.filename)),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: () => setShowAddStepFile(true) }))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: () => setShowAddStepFile(true) })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "2D File:"),
            " \u00A0",
            record?.name_2d_file ? (React.createElement("div", null, record?.name_2d_file && (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 3D file', className: 'link', onClick: () => {
                        if (record.production_2d_file_id) {
                            renderDrawingFile(record.production_2d_file_id);
                        }
                    } }, record.name_2d_file),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: () => setShowAddDrawingFile(true) }))))) : (React.createElement("div", null, record.production_2d_file ? (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 2D file', className: 'link', onClick: () => {
                        if (record.production_2d_file?.id) {
                            renderDrawingFile(record.production_2d_file.id);
                        }
                    } }, record.production_2d_file.filename),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: () => setShowAddDrawingFile(true) }))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 2D file', className: 'link', type: 'plus', onClick: () => setShowAddDrawingFile(true) })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "Other Files:"),
            React.createElement("div", { className: 'flex flex-row' }, record?.production_other_files?.map(f => (React.createElement("div", { key: f.id, className: 'flex flex-row link', title: 'Preview file', onClick: () => window.open(f.file, '', `width=960,height=1200,left=0`) },
                f?.filename,
                ",")))))));
});
