import React from 'react';
import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';

const BadgerSearch = () => {
    const query = useQueryParameters();
    const history = useHistory();
    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params = Object.keys(values)
            .filter(key => values[key] !== undefined && values[key] !== '')
            .map(key => `${key}=${encodeURIComponent(values[key])}`)
            .join('&');

        history.replace({
            pathname: history.location.pathname,
            search: `?${params.toString()}`,
            hash: history.location.hash,
        });
    };

    return (
        <Form
            form={form}
            layout="inline"
            onFinish={handleSubmit}
            initialValues={{ search: query.get('search') || '' }}
            style={{ backgroundColor: 'white', height: '100%' }}
        >
            <Form.Item name="search">
                <Input placeholder="Search" allowClear />
            </Form.Item>

            <Form.Item>
                <Button
                    onClick={() => {
                        form.resetFields();
                        history.replace({
                            pathname: history.location.pathname,
                            search: '',
                            hash: history.location.hash,
                        });
                    }}
                    style={{ marginRight: 8 }}
                >
                    Reset
                </Button>
                <Button type="primary" htmlType="submit">
                    Search
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BadgerSearch;
