import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Checkbox, Modal, notification, Select } from 'antd';
import { LineItemQuantity, PreviousHistory } from '../Componets';
import SalesOrderInfo from '../Componets/SalesOrderInfo';
import SalesOrderData from './SalesOrderData';
import IntakeStore from '../Stores/IntakeStore';
import { QuadrantSelector } from '../Componets/IntakeOuttake';
import InventoryStore from '../Stores/InventoryStore';
import { CreateInventoryForm } from './Inventory';
import { ErrorRender } from '@partsbadger/library';
import CustomerOuttake from '../Componets/IntakeOuttake/CustomerOuttake';
const SalesOrderForm = observer(({ vendorType, handleRefresh, ...props }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleInTakeOrOutTakeAll = () => IntakeStore.IntakeOrOuttakeAllItems();
    const handleOuttakeAllIntakeItems = () => IntakeStore.OuttakeAllIntakeItems();
    const [domesticFinishModalVisible, setDomesticFinishVisible] = useState(false);
    const [extraOrderItem, setExtraOrderItem] = useState([]);
    const [createInventory, setCreateInventory] = useState(false);
    const [showCustomerOuttakeModal, setShowCustomerOuttakeModal] = useState(false);
    const [purchaseOrdersModal, setPurchaseOrdersModal] = useState(false);
    const [selectedPo, setSelectedPo] = useState(null);
    const { purchase_orders } = IntakeStore;
    const closeDomesticModal = () => {
        setDomesticFinishVisible(false);
    };
    const handleUpdateOrderItem = async (order_item) => {
        const refactor_line_items = extraOrderItem.map(line_item => {
            if (line_item.id === order_item.id) {
                return {
                    id: order_item.id,
                    name: order_item.name,
                    quantity_remaining: order_item.quantity_remaining,
                    quantity_received: order_item.quantity_received,
                    extra_quantity: order_item.extra_quantity,
                    storage: order_item.storage,
                    save_in_inventory: order_item.save_in_inventory,
                    reason: order_item.reason,
                };
            }
            else {
                return line_item;
            }
        });
        if (refactor_line_items.length > 0) {
            setCreateInventory(true);
            setExtraOrderItem(refactor_line_items.sort((x, y) => x.name.localeCompare(y.name)));
        }
    };
    const intakeNormal = async (zIntake) => {
        setLoading(true);
        if (IntakeStore.receiveOrShip === 'receive') {
            const refactor_line_items = await IntakeStore.refactorLineItems();
            const extra_quantities = refactor_line_items.filter((lineItem) => lineItem.extra_quantity > 0);
            if (extra_quantities.length > 0) {
                setCreateInventory(true);
                setExtraOrderItem(extra_quantities.sort((x, y) => x.name.localeCompare(y.name)));
            }
            else {
                await IntakeStore.intakeSalesOrder(true, zIntake);
            }
        }
        setLoading(false);
    };
    /**
     * Intake Sales Order normally (No Extra Domestic work needed)
     */
    const handleIntakeSalesOrder = () => {
        setIsSubmitting(true);
        intakeNormal();
        setIsSubmitting(false);
        // IntakeStore.intakeSalesOrder().finally(() => setIsSubmitting(false));
    };
    /**
     * Outtake Sales Order normally (No Extra Domestic work needed)
     */
    const handleOuttakeSalesOrder = (zOuttake, poId) => {
        setIsSubmitting(true);
        IntakeStore.outtakeSalesOrder(zOuttake, {}, poId).finally(() => setIsSubmitting(false));
    };
    const handleZIntakeDisable = () => {
        if (IntakeStore.salesOrder) {
            const z = IntakeStore.salesOrder.line_items.filter(obj => obj.quantity_in_z_outtakes > 0);
            if (z.length > 0) {
                return false;
            }
        }
        return true;
    };
    // const handleSubmitOrCustomerOuttake = () => {
    //     if (
    //         IntakeStore?.salesOrder?.in_house_requirements ||
    //         IntakeStore?.salesOrder?.additional_requirements?.length ||
    //         IntakeStore?.salesOrder?.shipping_requirements?.length
    //     ) {
    //         setShowCustomerOuttakeModal(true);
    //     } else {
    //         handleOuttakeSalesOrder();
    //     }
    // };
    useEffect(() => {
        InventoryStore.getStorageLocations();
    }, []);
    return (React.createElement("div", { className: "border border-gray-300 py-3 px-6 mb-3" },
        IntakeStore.salesOrder && IntakeStore.salesOrder.status.includes('Received - Under Review') && (React.createElement(Alert, { message: "Place parts on the appropriate shelf. Do not ship until review is completed.", type: "warning", className: "mb-2" })),
        React.createElement("div", { className: "flex justify-center" },
            IntakeStore.salesOrder && IntakeStore.receiveOrShip === 'receive' && (React.createElement(QuadrantSelector, { selectedQuadrant: IntakeStore.salesOrder.quadrant !== null ? IntakeStore.salesOrder.quadrant : 'A', onQuadrantChange: val => IntakeStore.setQuadrant(val) })),
            React.createElement("div", { className: "flex" },
                React.createElement("div", { className: "mx-4" },
                    React.createElement("span", { className: "mr-2" }, "Customer Return?"),
                    React.createElement(Checkbox, { checked: IntakeStore.location === 'PB-CUSTOMER-RETURN', onChange: e => {
                            if (e.target.checked === true) {
                                IntakeStore.setLocation('PB-CUSTOMER-RETURN');
                            }
                            else {
                                IntakeStore.setLocation('PB');
                            }
                        } })))),
        React.createElement("div", { className: "m-4 text-center" },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { onClick: handleInTakeOrOutTakeAll },
                        IntakeStore.receiveOrShip === 'receive' ? 'Intake' : 'Outtake',
                        " All Remaining")),
                IntakeStore.receiveOrShip === 'ship' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { onClick: handleOuttakeAllIntakeItems }, "Outtake All Intake Parts"))),
                IntakeStore.receiveOrShip === 'ship' && (React.createElement(Modal, { title: "Confirm the information below...", closable: false, open: showCustomerOuttakeModal, footer: [], width: 550 },
                    React.createElement(CustomerOuttake, { acknowledgeVisible: value => setShowCustomerOuttakeModal(value), isSubmitting: value => setIsSubmitting(value) }))),
                IntakeStore.receiveOrShip == 'ship' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { danger: true, loading: isSubmitting, disabled: Object.values(IntakeStore.lineItemQuantities).filter(amt => amt !== 0).length === 0, onClick: () => {
                            const items = IntakeStore.lineItemQuantities;
                            InventoryStore.create({
                                type: 'SCRAP',
                                sales_order: IntakeStore.salesOrder.id,
                                line_items: Object.keys(items).map(key => {
                                    return {
                                        sales_order_line_item: Number(key),
                                        quantity: items[key],
                                    };
                                }),
                            }).then(() => {
                                notification.success({
                                    message: 'OK',
                                    description: 'Parts scrapped!',
                                });
                            });
                        } }, "Scrap Outtake"))),
                !IntakeStore.salesOrder?.domestic_process_needed && !IntakeStore.salesOrder?.hardware_required && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { loading: isSubmitting, disabled: Object.values(IntakeStore.lineItemQuantities).filter(amt => amt !== 0).length === 0, onClick: () => {
                            // Update variable print
                            IntakeStore.setPrintIntakeOuttake(true);
                            if (IntakeStore.receiveOrShip === 'receive') {
                                handleIntakeSalesOrder();
                            }
                            else {
                                handleOuttakeSalesOrder();
                            }
                        } }, "Submit and Print"))),
                IntakeStore.receiveOrShip === 'ship' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { className: "font-bold", type: "primary", loading: isSubmitting, disabled: Object.values(IntakeStore.lineItemQuantities).filter(amt => amt !== 0).length === 0, onClick: () => {
                            if (purchase_orders?.length) {
                                setPurchaseOrdersModal(true);
                            }
                            else {
                                handleOuttakeSalesOrder(true);
                                // // Todo: what is this?
                                IntakeStore.setDefaultValue();
                            }
                        } }, "Z Outtake"))),
                IntakeStore.receiveOrShip === 'receive' && (React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { disabled: handleZIntakeDisable(), key: 'btn-m-2', className: "font-bold", type: "primary", onClick: () => {
                            intakeNormal(true);
                        } }, "Z Intake"))),
                React.createElement("div", { className: 'm-4 text-center' },
                    React.createElement(Button, { className: "font-bold", type: "primary", loading: loading, disabled: Object.values(IntakeStore.lineItemQuantities).filter(amt => amt !== 0).length === 0, onClick: async () => {
                            if (IntakeStore.receiveOrShip === 'receive') {
                                await intakeNormal(false);
                            }
                            else {
                                setShowCustomerOuttakeModal(true);
                            }
                            // TODO: what is this?
                            IntakeStore.setDefaultValue();
                        } }, IntakeStore.receiveOrShip == 'ship' ? 'Customer Outtake' : 'Intake Normal')))),
        IntakeStore.salesOrder !== null && (React.createElement("div", { className: "my-4 flex flex-col" },
            React.createElement("div", { className: "flex flex-row mb-4 justify-center" },
                React.createElement(SalesOrderData, null),
                React.createElement(LineItemQuantity, { isVendorNumber: vendorType })),
            React.createElement(SalesOrderInfo, { salesOrder: IntakeStore.salesOrder, contact: IntakeStore.salesOrder, pdSalesOrder: IntakeStore.pdSalesOrder }))),
        React.createElement("div", { className: "previous-history" },
            React.createElement(PreviousHistory, { isTester: props.isTester, 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                dataSource: IntakeStore.receiveOrShip === 'receive'
                    ? IntakeStore.previousIntakes
                    : IntakeStore.previousOuttakes, 
                // disabledBox={IntakeStore.salesOrder?.name?.includes('REDO')}
                historyType: IntakeStore.receiveOrShip === 'receive' ? 'intakes' : 'outtakes', handleRefresh: () => {
                    handleRefresh();
                } })),
        React.createElement(Modal, { title: "Domestic Process", visible: domesticFinishModalVisible, onOk: closeDomesticModal, onCancel: closeDomesticModal, footer: [
                React.createElement(Button, { key: 'btn-m-1', onClick: closeDomesticModal }, "Cancel"),
                React.createElement(Button, { key: 'btn-m-2', type: "primary", onClick: () => {
                        intakeNormal(true);
                        closeDomesticModal();
                    } }, "Z Intake"),
                React.createElement(Button, { key: 'btn-m-3', type: "primary", onClick: () => {
                        intakeNormal(false);
                        closeDomesticModal();
                    } }, "Intake Normal"),
            ] },
            React.createElement("p", null, "This order has domestic processes required. Click submit to intake this order as Received in Full - Domestic Finish. If Domestic processes have been completed, please click Intake Normal.")),
        React.createElement(Modal, { title: 'Select a purchase order for this z outtake', closable: true, visible: purchaseOrdersModal, footer: [
                React.createElement(Button, { key: 'btn-m-1', onClick: () => setPurchaseOrdersModal(false) }, "Cancel"),
                React.createElement(Button, { key: 'btn-m-2', type: "primary", onClick: () => {
                        if (selectedPo) {
                            handleOuttakeSalesOrder(true, selectedPo);
                            IntakeStore.setDefaultValue();
                            setPurchaseOrdersModal(false);
                        }
                    } }, "Z Outtake"),
            ] },
            React.createElement(Select, { allowClear: true, style: { width: '100%' }, placeholder: "Please select a part*", onChange: value => {
                    setSelectedPo(value);
                } }, purchase_orders?.map(entity => (React.createElement(Select.Option, { key: entity.id, name: entity.name }, entity.name))))),
        createInventory && (React.createElement(Modal, { title: 'The quantities entered for these items appear to be incorrect, please correct them or they were saved in inventory.', open: createInventory, closable: true, footer: false, onOk: () => {
                setExtraOrderItem([]);
                setCreateInventory(false);
            }, onCancel: () => {
                setExtraOrderItem([]);
                setCreateInventory(false);
            }, width: 850 }, createInventory && (React.createElement(React.Fragment, null,
            extraOrderItem.map((order_item, index) => {
                return (React.createElement("div", { key: index, className: 'pt-4 border-b-2 ' },
                    React.createElement("div", { className: 'pb-2 ' },
                        React.createElement("strong", null, "PRODUCT: "),
                        " ",
                        React.createElement("strong", null, order_item.name)),
                    React.createElement(CreateInventoryForm, { key: order_item.id, storage_locations: InventoryStore.storages, extra_order_item: order_item, handleUpdateItem: async (oi) => {
                            await handleUpdateOrderItem(oi);
                        } })));
            }),
            React.createElement("div", { className: 'flex justify-center mt-4' },
                React.createElement(Button, { loading: loading, type: "primary", onClick: async () => {
                        setLoading(true);
                        const error = [];
                        try {
                            extraOrderItem.map(item => {
                                if (item.save_in_inventory && item.storage === undefined) {
                                    error.push(item);
                                }
                            });
                            if (error.length > 0) {
                                notification.error({
                                    message: 'Make sure you have selected a storage location to create the inventory items.',
                                    placement: 'topRight',
                                });
                                setLoading(false);
                            }
                            else {
                                await IntakeStore.intakeSalesOrder(true, false, extraOrderItem).then(() => {
                                    setCreateInventory(false);
                                    setLoading(false);
                                });
                            }
                        }
                        catch (err) {
                            notification.error({
                                message: React.createElement(ErrorRender, { error: err }),
                                placement: 'topRight',
                            });
                            setLoading(false);
                        }
                    } }, "Proceed, create a separate Conforming Spare inventory record"))))))));
});
export default SalesOrderForm;
