import { Button, Popover } from 'antd';
import * as React from 'react';
const Suggestion = (props) => {
    return (React.createElement("div", { className: 'suggestion flex flex-row justify-start items-center' },
        React.createElement(Popover, { title: React.createElement("div", { className: 'flex flex-row justify-start' },
                React.createElement("div", { className: 'font-bold text-xs' }, "Suggested Option: "),
                React.createElement("div", { className: 'font-normal text-xs' }, props.title)), placement: "right", content: React.createElement("div", { className: 'border', style: {
                    width: 450,
                    maxWidth: 500,
                    background: '#d4d4d4',
                    textAlign: 'center',
                } },
                React.createElement("img", { style: {
                        maxWidth: '90%',
                        margin: 'auto',
                    }, src: props.thumbnail })) },
            React.createElement(Button, { className: "space-x-0.5 mt-0 mr-2", type: "dashed", size: "small", onClick: props.handleClick },
                React.createElement("div", { className: 'font-normal', style: { fontSize: 10 }, title: `${props.title}` },
                    props.title.substring(0, 15),
                    "...")))));
};
export default Suggestion;
