import React, { useState } from 'react';
import { Button, InputNumber, notification } from 'antd';
import { isNumber } from 'lodash';
import { QuoteStore } from '../Stores';
import { ErrorRender } from '@partsbadger/library';
export const AddLeadTime = (props) => {
    const [days, setDays] = useState(1);
    return (React.createElement("div", { style: { minWidth: '200px', padding: 20 } },
        React.createElement("div", { className: 'mb-2' },
            React.createElement("label", { className: 'mx-2' }, props.master_product !== undefined
                ? `Add lead time for the product.`
                : 'Number of days that are added or subtracted from all products:'),
            React.createElement(InputNumber, { precision: 0, className: 'mx-2', autoFocus: true, style: { width: '60px' }, size: "small", defaultValue: 1, onChange: number => {
                    if (isNumber(number)) {
                        setDays(number);
                    }
                    else {
                        setDays(1);
                    }
                } }),
            React.createElement(Button, { type: 'primary', size: 'small', htmlType: "submit", onClick: async () => {
                    const payload = {
                        days: days,
                    };
                    if (props.master_product !== undefined) {
                        payload.master_product_id = props.master_product;
                    }
                    try {
                        await QuoteStore.addLeadTine(payload);
                        notification.success({ message: 'Lead time updated' });
                        props.setAddLeadTime(false);
                    }
                    catch (e) {
                        notification.error({
                            message: 'Error',
                            description: React.createElement(ErrorRender, { error: e }),
                        });
                    }
                } }, "Save Days")),
        React.createElement("span", { className: 'text-sm mx-2' }, "Negative values are subtracted: -1, -5")));
};
