import React, { useEffect, useState } from 'react';
import { message, Modal, Spin } from 'antd';
import { UploadFileInfo } from './UploadFileInfo';
import { ItemsCreateForm } from './ItemsCreateForm';
import { ItemsEditForm } from './ItemsEditForm';
import api from './utils/api';
import SalesOrderStore from './Stores/SalesOrderStore';
import { ALLOWED_FILES } from '@partsbadger/utils';
export const PartFiles = (props) => {
    const { product } = props;
    const { record } = props;
    const { action } = props;
    let pollingInterval = null;
    const [showAddStepFile, setShowAddStepFile] = useState(false);
    const [showAddDrawingFile, setShowAddDrawingFile] = useState(false);
    const [loading, setLoading] = useState(false);
    // Determine if the Manufacturing Type is '3D PRINTING'
    const is3DPrinting = record.quote_has_product?.manufacturing_type === '3D PRINTING';
    // If it is '3D PRINTING', accept all files including 'stl'.
    // Otherwise, exclude 'stl' from the allowed files.
    const allowedFiles = is3DPrinting
        ? ALLOWED_FILES.Files3D // Accept '.stl' because it's 3D PRINTING
        : ALLOWED_FILES.Files3D.filter(file => file !== 'stl'); // Exclude '.stl' if not 3D PRINTING
    useEffect(() => {
        if (record.production_3d_file_id) {
            api.requests.get(`/staff/step-files/${record.production_3d_file_id}/`).then((response) => {
                if (response.analyzed_status !== 'completed') {
                    if (record.production_3d_file_id) {
                        startPolling(record.production_3d_file_id);
                    }
                }
            });
        }
    }, [record, record.production_3d_file_id]);
    const renderStepFile = (id) => {
        const url = `/staff-threedviewer/${id}/`;
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    const renderDrawingFile = (id) => {
        const width = 960;
        const height = 720;
        const left = window.screen.width - width;
        const external_window = window.open(`/drawing-viewer/${id}/`, '', `width=${width},height=${height},left=${left}`);
        external_window.document.title = 'Drawing File Viewer';
    };
    const startPolling = (id) => {
        setLoading(true);
        const url = `/staff/step-files/${id}/`;
        pollingInterval = setInterval(() => {
            api.requests
                .get(url)
                .then((response) => {
                if (response.analyzed_status === 'completed' && response.image !== null) {
                    SalesOrderStore.updateOrderItemStepFile(record.id, response);
                    clearInterval(pollingInterval);
                    setLoading(false);
                }
            })
                .catch(() => {
                setLoading(false);
                clearInterval(pollingInterval);
            });
        }, 5000);
    };
    return (React.createElement(Spin, { spinning: loading, tip: 'Analyzing file, please wait.' },
        React.createElement("div", { className: 'flex flex-col itemsCenter' },
            React.createElement("div", { className: 'flex flex lg:flex-row md:flex-col sm:flex-col itemsCenter' }, props?.show_pending_quoted_files && (React.createElement("div", { className: 'w-full' },
                action === 'create' ? (React.createElement(React.Fragment, null,
                    React.createElement(ItemsCreateForm, { record: record, product: product, setShowAddDrawingFile: setShowAddDrawingFile, setShowAddStepFile: setShowAddStepFile, renderStepFile: renderStepFile, renderDrawingFile: renderDrawingFile, onDropFile: async (file, type) => {
                            let allowed_files = ALLOWED_FILES.Files3D;
                            const t = file.name.split('.').pop()?.toLowerCase();
                            if (type === '2D')
                                allowed_files = ALLOWED_FILES.Files2D;
                            const file_type = allowed_files.filter(f => f === t);
                            if (t === file_type[0]) {
                                const data = await SalesOrderStore.upload2dOr3dFile(file, type);
                                if (type === '2D') {
                                    SalesOrderStore.updateOrderItem2D(record, data.id, file.name);
                                }
                                else {
                                    SalesOrderStore.analyze_3d_file(data.id);
                                    SalesOrderStore.updateOrderItem3D(record, data.id, file.name);
                                }
                            }
                            else {
                                message.error('The file type is not allowed, try again.', 3);
                            }
                        } }))) : (React.createElement(React.Fragment, null,
                    React.createElement(ItemsEditForm, { record: record, setShowAddDrawingFile: setShowAddDrawingFile, setShowAddStepFile: setShowAddStepFile, renderStepFile: renderStepFile, renderDrawingFile: renderDrawingFile }))),
                React.createElement(Modal, { visible: showAddStepFile, centered: true, title: "Add Step File", onCancel: () => setShowAddStepFile(false), footer: false },
                    React.createElement("div", { className: "flex justify-center" }, allowedFiles.map(file => file.toUpperCase()).join(' | ')),
                    React.createElement(UploadFileInfo, { typeFile: "3D", accept: allowedFiles, setShowDragger: setShowAddStepFile, record: record })),
                React.createElement(Modal, { visible: showAddDrawingFile, centered: true, title: "Add Drawing File", onCancel: () => setShowAddDrawingFile(false), footer: false },
                    React.createElement("div", { className: 'flex justify-center' }, ALLOWED_FILES.Files2D.map(file => {
                        return file.toUpperCase() + ' | ';
                    })),
                    React.createElement(UploadFileInfo, { typeFile: '2D', accept: ALLOWED_FILES.Files2D, setShowDragger: setShowAddDrawingFile, record: record }))))))));
};
