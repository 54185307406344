import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ThreeDViewer } from '@partsbadger/threedviewer';
// Custom hook to get the size of the container
const useContainerSize = (ref, modalVisible) => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    // Function to update the size of the container
    const updateSize = useCallback(() => {
        if (ref.current) {
            const width = ref.current.clientWidth;
            const height = ref.current.clientHeight;
            if (width !== size.width || height !== size.height) {
                setSize({ width, height });
            }
        }
    }, [ref, size]);
    // Update the size when the modal becomes visible
    useEffect(() => {
        if (modalVisible) {
            // Use requestAnimationFrame to ensure the size calculation happens after the render
            const handle = requestAnimationFrame(updateSize);
            return () => cancelAnimationFrame(handle);
        }
    }, [modalVisible, updateSize]);
    // Return the current size
    return size;
};
// Main component for the ThreeDViewerModal
const ThreeDViewerModal = ({ url, visible, onClose, loading }) => {
    const modalRef = useRef(null); // Ref for the modal container
    const size = useContainerSize(modalRef, visible); // Get the size using the custom hook
    return (React.createElement(Modal, { open: visible, footer: null, centered: true, width: "90vw", maskClosable: true, onCancel: onClose, bodyStyle: { height: '720px', margin: 0, padding: 0 }, style: { maxWidth: '960px' } },
        React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center", ref: modalRef, style: { minHeight: '720px', backgroundColor: '#f0f0f0' } }, loading ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, null), tip: "Loading 3D Model..." })) : (url &&
            size.width > 0 &&
            size.height > 0 && (React.createElement(ThreeDViewer, { url: url, canvasStyle: { width: `${size.width}px`, height: `${size.height}px` } }))))));
};
export default ThreeDViewerModal;
