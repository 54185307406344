import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal, notification, Spin, message, Tag } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { ErrorRender } from '@partsbadger/library';
import ShipmentEditForm from '../Form/ShipmentEditForm';
export const EditBox = observer(() => {
    const [isPrinting, setIsPrinting] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCancelling, setIsCancelling] = React.useState(false);
    useEffect(() => { }, []);
    if (isLoading) {
        return (React.createElement("div", null,
            React.createElement(Spin, { tip: 'Loading' })));
    }
    if (!IntakeStore.outtake?.box) {
        return React.createElement("div", null, "No Shipment created yet");
    }
    const { id, carrier, shipping_method, shipping_account, bill_to_customer, notes, internal_notes, tracking_number, boxes_included, carrier_shipping_cost, shipping_cost, late_shipping_cost, is_api_shipment, packages, } = IntakeStore.outtake?.box;
    const { outbound_destination } = IntakeStore.outtake;
    return (React.createElement(React.Fragment, null, IntakeStore.editBoxVisible && (React.createElement(Modal, { title: React.createElement(React.Fragment, null,
            "Edit Box",
            ' ',
            React.createElement(Tag, { color: IntakeStore.outtake?.box?.status == 'Shipped' ? 'green' : 'yellow' }, IntakeStore.outtake?.box?.status)), visible: true, width: 1200, onCancel: () => IntakeStore.setEditBoxVisible(false), footer: [] },
        React.createElement(ShipmentEditForm, { box: {
                id: id,
                carrier: carrier,
                shipping_method: shipping_method,
                shipping_account: shipping_account,
                bill_to_customer: bill_to_customer,
                notes: notes,
                internal_notes: internal_notes,
                tracking_number: tracking_number,
                boxes_included: boxes_included,
                shipping_cost: shipping_cost,
                carrier_shipping_cost: carrier_shipping_cost,
                late_shipping_cost: late_shipping_cost,
                is_api_shipment: is_api_shipment,
                packages: packages.map(p => {
                    return {
                        package_length: p.package_length,
                        package_width: p.package_width,
                        package_height: p.package_height,
                        package_weight: p.package_weight,
                        tracking_number: p.tracking_number,
                        shipping_cost: p.shipping_cost,
                        label: {
                            id: p.label?.id,
                            file: p.label?.file,
                        },
                    };
                }),
            }, handleSave: async (payload) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                await IntakeStore.editBox(IntakeStore.outtake.id, payload);
            }, handleClose: () => {
                IntakeStore.setEditBoxVisible(false);
            }, outbound_destination: outbound_destination ? outbound_destination : '' }),
        React.createElement("div", { className: "flex flex-row items-center justify-center mt-10" }, IntakeStore.outtake?.box?.status == 'Shipped' && (React.createElement(Button, { loading: isCancelling, type: "link", style: {
                color: 'red',
            }, onClick: () => {
                if (!IntakeStore.outtake?.box?.tracking_number) {
                    message.error('No tracking number provided');
                    return;
                }
                setIsCancelling(true);
                IntakeStore.cancelShipment(IntakeStore.outtake.id, IntakeStore.outtake.box.tracking_number)
                    .then(e => {
                    IntakeStore.setEditBoxVisible(false);
                })
                    .catch(e => {
                    notification.error({
                        message: 'Error cancelling the shipment',
                        description: React.createElement(ErrorRender, { error: e?.response?.data }),
                    });
                })
                    .finally(() => setIsCancelling(false));
            } }, "Cancel shipment")))))));
});
