import React from 'react';
import { Alert } from 'antd';
import FeatherIcon from 'feather-icons-react';
const ITARNotice = () => {
    return (React.createElement(Alert, { className: "flex flex-row mb-4", type: "warning", showIcon: true, message: 'ITAR Notice', description: React.createElement(React.Fragment, null,
            React.createElement("span", null,
                "If your request contains ITAR requirements, please use our ITAR portal.",
                React.createElement("a", { className: "link font-bold text-warning d-flex align-items-center", href: "https://parts-badger.com/itar-notice/" },
                    "Go to ITAR Portal ",
                    React.createElement(FeatherIcon, { icon: "arrow-up-right", size: "20", className: "ml-1" })))) }));
};
export default ITARNotice;
