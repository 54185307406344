import { action, observable, makeObservable } from 'mobx';
import { post, get } from '@partsbadger/utils';
class CreditMemoStore {
    constructor() {
        this.credit_memos = [];
        this.credit_memo = null;
        makeObservable(this, {
            // Observables
            credit_memos: observable,
            credit_memo: observable,
            // Actions
            list: action,
            create: action,
        });
    }
    async list(sales_order_id) {
        const response = await get(`/staff/sales-orders/${sales_order_id}/credit-memos/`);
        this.credit_memos = response.results;
    }
    async create(sales_order_id, payload) {
        const response = await post(`/staff/sales-orders/${sales_order_id}/create-credit-memo/`, payload);
        this.credit_memos.push(response.data);
        return response;
    }
}
export default new CreditMemoStore();
