import * as React from 'react';
import { Select } from 'antd';
import countries from 'country-region-data';
export const States = (props) => {
    let data;
    if (props?.countryShortCode) {
        data = countries.find(country => country.countryShortCode === props?.countryShortCode);
    }
    return (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, 
        // size={'small'}
        // style={{ width: '100%' }}
        dropdownMatchSelectWidth: false, placeholder: "Search", filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, ...props }, data?.regions.map(row => (React.createElement(Select.Option, { key: row.shortCode, value: row.shortCode }, row.name)))));
};
