import React, { useEffect } from 'react';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import { observer } from 'mobx-react';
import PurchaseOrderEditForm from '../Forms/PurchaseOrderEditForm';
import PurchaseOrderCreateForm from '../Forms/PurchaseOrderCreateForm';
export const PurchaseOrder = observer((props) => {
    const getStaffUsers = async () => {
        await PurchaseOrderStore.getUsers({
            is_staff: true,
        });
    };
    useEffect(() => {
        getStaffUsers();
    }, []);
    return (React.createElement(React.Fragment, null, props.purchase_order_id ? (React.createElement(PurchaseOrderEditForm, { users: PurchaseOrderStore.users, current_user: props.current_user, purchase_order_id: props.purchase_order_id, onSubmitted: () => {
            props.onSubmitted();
        } })) : (React.createElement(PurchaseOrderCreateForm, { users: PurchaseOrderStore.users, current_user: props.current_user, onSubmitted: () => {
            props.onSubmitted();
        }, onAccountError: () => {
            props?.onAccountError && props?.onAccountError();
        } }))));
});
