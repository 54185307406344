import React, { useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import { Progress } from 'antd';
const DroppableZone = props => {
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(TargetBox, { ...props })));
};
export const TargetBox = props => {
    const [isUploading, setIsUploading] = useState(false);
    const { onDrop } = props;
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: [NativeTypes.FILE],
        drop(item) {
            if (onDrop) {
                setIsUploading(true);
                item.files.forEach(f => {
                    onDrop(f).finally(() => setIsUploading(false));
                });
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [props]);
    const isActive = canDrop && isOver;
    const style = {
        border: '1px dashed gray',
        display: 'inline',
        padding: isActive ? 40 : 10,
        borderColor: isActive ? 'green' : '#d4d4d4',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: drop, style: style },
            isActive ? 'Release to drop' : props.children,
            isUploading === true && React.createElement(Progress, { style: { width: 100 }, percent: 30, size: "small" }))));
};
export default DroppableZone;
