import { Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import QuoteStore from '../../stores/QuoteStore';
let busy = null;
export const Users = observer((props) => {
    const searchOwners = (search = '') => {
        QuoteStore.getUsers({
            search: search,
            is_staff: true,
        });
    };
    useEffect(() => {
        searchOwners('');
    }, []);
    const users = QuoteStore.users;
    return (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, placeholder: "Search user by name or email", filterOption: false, optionKeyProp: 'value', dropdownMatchSelectWidth: false, style: { width: '100%' }, onSearch: name => {
            if (busy) {
                clearTimeout(busy);
            }
            busy = setTimeout(() => searchOwners(name), 500);
        }, ...props }, users.map(d => (React.createElement(Select.Option, { key: d.id }, d.fullname)))));
});
