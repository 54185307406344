import { BASE_URL_WS } from '@partsbadger/utils';
import React, { useEffect } from 'react';
import QuoteStore from '../stores/QuoteStore';
import { observer } from 'mobx-react';
import ReconnectingWebSocket from 'reconnecting-websocket';
const SocketConnection = () => {
    const { quote, token, isConnectedToSocket } = QuoteStore;
    useEffect(() => {
        // Send the token on the cookie hackie option
        document.cookie = `X-Authorization=${token}; path=/`;
        const url = `${BASE_URL_WS}/ws/instant-quote/${quote?.uid}/`;
        const ws = new ReconnectingWebSocket(url, [], {
            maxRetries: 50,
        });
        ws.onopen = () => {
            QuoteStore.setConnectedSocket(true);
        };
        ws.onmessage = message => {
            try {
                const data = JSON.parse(message.data);
                if (data.type === 'updated.quote_line_item') {
                    QuoteStore.setLineItem(JSON.parse(data.message));
                }
                if (data.type == 'updated_part') {
                    QuoteStore.setProduct(JSON.parse(data.message));
                }
            }
            catch (err) {
                console.error('SocketConnection Error - Message.data content:', message?.data, 'Origin Error:', err);
            }
        };
        ws.onclose = () => {
            QuoteStore.setConnectedSocket(false);
        };
        ws.onerror = function (error) {
            ws.close();
        };
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);
    return (React.createElement("div", { style: {
            fontSize: 18,
            marginRight: 10,
            color: isConnectedToSocket ? '#32CD32' : 'black',
        } }, "\u2022"));
};
export default observer(SocketConnection);
