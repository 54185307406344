import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const semver = require('semver');
const APP_VERSION_KEY = 'app_version';
/**
 * Only use for new Features
 * @param props
 * @constructor
 */
const Tour = (props) => {
    const [steps, setSteps] = useState([]);
    const [run, setRun] = useState(false);
    const TOUR_KEY = `tour-${props.tourKey.toLowerCase()}`;
    const tourDone = window.localStorage.getItem(TOUR_KEY) ?? '0.0.1';
    const app_version = window.localStorage.getItem(APP_VERSION_KEY) ?? '1.0';
    const setAsCompleted = () => {
        window.localStorage.setItem(TOUR_KEY, app_version);
        setRun(false);
    };
    useEffect(() => {
        //Check if the tour for the version was done
        try {
            if (semver.lt(tourDone, app_version)) {
                const _filtered_steps = props.steps
                    .filter(step => {
                    try {
                        return semver.lt(tourDone, step.version);
                    }
                    catch (e) {
                        console.error(e);
                        return false;
                    }
                })
                    .map(step => {
                    return {
                        ...step,
                        title: step.version,
                    };
                });
                setSteps(_filtered_steps);
                setRun(true);
            }
        }
        catch (e) {
            console.error(e);
        }
    }, [tourDone, app_version, TOUR_KEY]);
    const handleJoyrideCallback = ({ action }) => {
        if (action == 'close' || action == 'skip' || action == 'reset') {
            setAsCompleted();
        }
    };
    if (!steps.length) {
        return null;
    }
    return (React.createElement(Joyride, { callback: handleJoyrideCallback, run: run, debug: true, continuous: true, showSkipButton: true, steps: steps, showProgress: true, styles: {
            options: {
                primaryColor: 'green',
                zIndex: 1000,
            },
        } }));
};
export default Tour;
