import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
const InputChecklist = (props) => {
    const depends_on = props.question.depends_on;
    let selection = '';
    if (depends_on) {
        selection = props.form.getFieldValue(`confirm-${depends_on.toString()}`);
    }
    if (props.question.visible || selection === 'Yes') {
        return (React.createElement(Form.Item, null, props.getFieldDecorator(`confirm-${props.question.question}`, {
            rules: [{ required: props.question.required, message: 'Required' }],
            initialValue: props.question?.answer?.confirm ?? '',
        })(React.createElement(Input, { defaultValue: props.form.getFieldValue(`confirm-${props.question.question}`), onChange: props.onChange, maxLength: 300 }))));
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
export default InputChecklist;
