import { Select } from 'antd';
import React from 'react';
export const ManufacturingTypes = (props) => {
    return (React.createElement(Select, { showSearch: true, dropdownMatchSelectWidth: false, optionFilterProp: "children", size: 'small', filterOption: (input, option) => {
            //@ts-ignore
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }, ...props },
        React.createElement(Select.Option, { value: "CNC" }, " CNC "),
        React.createElement(Select.Option, { value: "3D PRINTING" }, " 3D "),
        React.createElement(Select.Option, { value: "Sheet Metal" }, " Sheet Metal ")));
};
