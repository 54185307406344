import React from 'react';
import { Divider } from 'antd';
import { AccountDetail } from '../../stores/types';
import RequirementForm from './OrderRequirements/AccountRequirementForm';

interface Props {
    account: AccountDetail;
}

const AccountRequirements: React.FC<Props> = ({ account }) => {
    return (
        <div>
            <div style={{ marginTop: 10 }}>
                <RequirementForm
                    //@ts-ignore
                    defaultValues={[]}
                    account_id={account.id}
                />
            </div>
        </div>
    );
};

export default AccountRequirements;
