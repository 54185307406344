import { action, observable, makeObservable } from 'mobx';
import { get } from '@partsbadger/utils';
class Account {
    constructor() {
        this.isLoading = true;
        this.account = null;
        makeObservable(this, {
            // Observables
            isLoading: observable,
            account: observable,
            // Actions
            getAccount: action,
        });
    }
    async getAccount(account_id) {
        this.isLoading = true;
        return await get(`/staff/accounts/${account_id}/`)
            .then(data => {
            this.account = data;
        })
            .finally(() => {
            this.isLoading = false;
        });
    }
    clean() {
        this.account = null;
    }
}
export const AccountStore = new Account();
