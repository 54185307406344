import React, { useEffect, useState } from 'react';
import { get, useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import { Col, Divider, Input, Row, Table, Tag, Typography } from 'antd';
import {
    IQuoteListStaff,
    ISalesOrderListStaff,
    IInvoiceListStaff,
    IPartListStaff,
    IContactListStaff,
    IOuttakeListStaff,
} from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { DateRender, Price } from '@partsbadger/library';
import { useHistory } from 'react-router';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';
import { IAccountList } from '../../stores/AccountStore';

const SearchSalesOrders = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<ISalesOrderListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/sales-orders/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<ISalesOrderListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.name}</div>
                        <Tag>{record.status}</Tag>
                        {record.partsbadger_production ? <Tag color="blue">PB Prod</Tag> : ''}
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.account?.name}</div>
                        <div className={'italic'}>{record.contact.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Quotes',
            dataIndex: 'quotes',
            render: (text, record) => {
                return (
                    <div>
                        <div>
                            {record.quotes.map((quote, index) => {
                                return (
                                    <div key={index}>
                                        <div>{quote.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a
                            className={'cursor-pointer'}
                            onClick={() => history.push('/new-sales-order-detail/' + record.id)}
                        >
                            View
                        </a>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Sales Orders</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<ISalesOrderListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchQuotes = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IQuoteListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/quotes/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IQuoteListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.name}</div>
                        <Tag>{record.stage}</Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.account?.name}</div>
                        <div className={'italic'}>{record.contact?.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/quotes/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Quotes</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IQuoteListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchInvoices = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IInvoiceListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/invoices/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IInvoiceListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.name}</div>
                        <Tag>{record.invoice_status}</Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div className={'italic'}>{record.account?.name}</div>
                    </div>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/invoices/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Invoices</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IInvoiceListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchParts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IPartListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/master-products/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IPartListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        {record.step_file && (
                            <img
                                className="thumbnail-3d-file"
                                style={{ width: 60, cursor: 'pointer' }}
                                src={record.step_file?.image}
                                onClick={() => {
                                    // setModalVisible(true);
                                    // setUrl(r?.step_file.file_3djs);
                                }}
                            />
                        )}

                        <div>{record.name}</div>
                        <Tag>
                            {!record.quotable && 'No Quotable'}
                            {record.notes}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div className={'italic'}>{record.account?.name}</div>
                    </div>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div className="flex flex-col">
                        {record.drawing_file && (
                            <a
                                className={'cursor-pointer'}
                                target={'_blank'}
                                href={record.drawing_file.file}
                                rel="noreferrer"
                            >
                                Download 2D File
                            </a>
                        )}

                        <br />

                        {record.step_file?.file && (
                            <a
                                className={'cursor-pointer'}
                                target={'_blank'}
                                href={record.step_file.file}
                                rel="noreferrer"
                            >
                                Download 3D File
                            </a>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Parts</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IPartListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchContacts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IContactListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/contacts/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IContactListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Account',
            dataIndex: 'account',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.account?.name}</div>
                    </div>
                );
            },
        },

        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.email}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/contacts/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];
    return (
        <Row>
            <Divider>
                <div className={'title'}> Contacts </div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IContactListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchAccounts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IAccountList[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/accounts/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IAccountList>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.name}</div>
                    </div>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/accounts/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];
    return (
        <Row>
            <Divider>
                <div className={'title'}> Accounts </div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IAccountList>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};
const CustomerShipments = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IOuttakeListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/outtakes/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IOuttakeListStaff>[] = [
        {
            title: 'id',
            dataIndex: 'id',
            render: (text, record) => {
                return <div>{record.id}</div>;
            },
        },

        {
            title: 'Sales Order',
            dataIndex: 'sales_order',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.sales_order?.name}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Destination',
            dataIndex: 'outbound_destination',
            key: 'outbound_destination',
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a
                            className={'cursor-pointer'}
                            onClick={() => history.push('/shipping-receiving/?sales-order=' + record.sales_order?.id)}
                        >
                            View
                        </a>
                    </div>
                );
            },
        },
    ];
    return (
        <Row>
            <Divider>
                <div className={'title'}> Shipments </div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IOuttakeListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchPage = () => {
    const history = useHistory();
    const queryParams = useQueryParameters();
    const query = queryParams.get('query') || '';

    return (
        <div
            style={{
                maxWidth: '1200px',
                margin: '0 auto',
            }}
        >
            <Row>
                <Col md={24}>
                    <Typography.Title level={2}>Search Page</Typography.Title>
                </Col>
            </Row>

            <Row>
                <Col md={24}>
                    <div className={'flex flex-row items-center'}>
                        Search:{' '}
                        <Input.Search
                            defaultValue={query}
                            placeholder={'Search'}
                            onSearch={v => {
                                history.push(`/search?query=${v}`);
                            }}
                        />
                    </div>
                </Col>
            </Row>

            {query ? (
                <>
                    <Row>
                        <Col md={24} lg={24}>
                            <SearchAccounts query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchContacts query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchSalesOrders query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <CustomerShipments query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchQuotes query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchInvoices query={query} />
                        </Col>

                        <Col md={24} lg={24}>
                            <SearchParts query={query} />
                        </Col>
                    </Row>
                </>
            ) : (
                <div className={'flex flex-col items-center justify-center mt-10'}>
                    Please enter search query to search
                </div>
            )}
        </div>
    );
};

export default SearchPage;
