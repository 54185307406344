import React, { useMemo } from 'react';
import { Button, Col, InputNumber, Row, Select, notification } from 'antd';
import { Form } from '@ant-design/compatible';
import SalesOrderStore from '../../../Stores/SalesOrderStore';
import { DeleteOutlined } from '@ant-design/icons';
import { ErrorRender } from '@partsbadger/library';
import SalesOrderBatchStore from '../../../Stores/SalesOrderBatchStore';
const NewSampleRequest = props => {
    const { orderLineItem } = props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const fieldName = (name, id) => {
        return name + '__' + id;
    };
    const { order_items } = SalesOrderStore;
    const order = SalesOrderStore.order;
    const updateSamplesOnLineItem = (id, qty, actionText) => {
        if (order && order?.line_items) {
            const payload = order.line_items
                .filter(i => i.name != 'CNC Part')
                .map(item => {
                if (item.id === id) {
                    return {
                        id: item.id,
                        quantity_sample_required: qty,
                    };
                }
                return {
                    id: item.id,
                    quantity_sample_required: item.quantity_sample_required,
                };
            });
            SalesOrderStore.update_multiple_items(order.id, payload)
                .then(res => {
                setFieldsValue({ partsSelectedForFAA: null, sampleQuantity: null });
                const hasSampleRequests = res?.line_items?.some(item => item.quantity_sample_required);
                if (!hasSampleRequests) {
                    SalesOrderStore.update_sales_order_field('ffa_before_production', false).catch(error => {
                        notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Unable to update the ffa_before_production field to False',
                            placement: 'bottomRight',
                        });
                    });
                }
                notification.success({
                    message: `Sample Request ${actionText}d`,
                    description: `The sample was ${actionText.toLowerCase()}d successfully.`,
                });
            })
                .catch(error => {
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: React.createElement(ErrorRender, { error: error }),
                    placement: 'bottomRight',
                });
            });
        }
    };
    const maxSampleQty = () => {
        const partId = orderLineItem?.id ? orderLineItem?.id : parseInt(getFieldValue('partsSelectedForFAA'));
        const total_batch_quantity = SalesOrderBatchStore.getQuantity(partId);
        if (orderLineItem?.quantity) {
            return orderLineItem?.quantity - total_batch_quantity;
        }
        const line_item = order?.line_items.find(item => item.id === partId);
        if (line_item) {
            return line_item?.quantity - total_batch_quantity;
        }
        return 1;
    };
    const cachedMaxSampleQty = useMemo(maxSampleQty, [order, orderLineItem, getFieldValue('partsSelectedForFAA')]);
    const hasLineItemsWithNullSampleRequired = () => {
        return order?.line_items?.some(item => item.quantity_sample_required === null);
    };
    return (React.createElement(Row, { className: 'row-order mt-5 text-center' },
        React.createElement(Col, { sm: 11 },
            React.createElement(Form.Item, { label: 'Select the Part(s) for FAA:', className: 'pl-8' }, getFieldDecorator(orderLineItem?.id ? fieldName('partsSelectedForFAA', orderLineItem.id) : 'partsSelectedForFAA', {
                initialValue: orderLineItem?.name,
                rules: [
                    {
                        required: false,
                        message: 'Please select at least one part for FAA',
                    },
                ],
            })(React.createElement(Select, { disabled: !!orderLineItem?.id, allowClear: true, style: { width: '100%' }, placeholder: "Please select a part*" }, order_items
                .filter(i => i.name !== 'CNC Part' && !i.quantity_sample_required)
                .map(entity => (React.createElement(Select.Option, { key: entity.id, name: entity.name, product_description: entity.product_description, quote_has_product: entity.quote_has_product?.id, quantity: entity.quantity, unit_price: entity.unit_price }, entity.name))))))),
        React.createElement(Col, { sm: 11 },
            React.createElement(Form.Item, { label: 'Sample Quantities for FAA*', className: 'pl-8', hasFeedback: true, validateStatus: cachedMaxSampleQty == 0 ? 'error' : '', help: cachedMaxSampleQty == 0 && 'No quantity remains' }, getFieldDecorator(orderLineItem?.id ? fieldName('sampleQuantity', orderLineItem.id) : 'sampleQuantity', {
                initialValue: orderLineItem?.quantity_sample_required,
                rules: [
                    {
                        required: getFieldValue('partsSelectedForFAA') && !orderLineItem?.id ? true : false,
                        message: 'Please select at a sample quantity',
                    },
                ],
            })(React.createElement(InputNumber, { min: 1, max: cachedMaxSampleQty, style: { width: '100%' }, onBlur: e => {
                    if (!orderLineItem?.id)
                        return;
                    const inputValue = e.target.value.trim();
                    const parsedQty = inputValue ? parseInt(inputValue, 10) : null;
                    if (parsedQty === null || isNaN(parsedQty)) {
                        // Handle invalid input
                        console.warn('Invalid quantity input');
                        return;
                    }
                    if (parsedQty === orderLineItem.quantity_sample_required)
                        return;
                    const qty = Math.min(parsedQty, cachedMaxSampleQty);
                    updateSamplesOnLineItem(orderLineItem.id, qty, 'Update');
                } })))),
        orderLineItem?.id && (React.createElement(Col, { sm: 2, className: "flex justify-center items-center" },
            React.createElement(Button, { type: "link", onClick: () => {
                    updateSamplesOnLineItem(orderLineItem?.id, null, 'Delete');
                }, icon: React.createElement(DeleteOutlined, { style: {
                        color: 'red',
                    } }) }))),
        !orderLineItem?.id && hasLineItemsWithNullSampleRequired() && (React.createElement("div", { className: "flex justify-center items-center w-full" },
            React.createElement(Button, { type: "link", onClick: () => {
                    const partId = parseInt(getFieldValue('partsSelectedForFAA'));
                    const qty = parseInt(getFieldValue('sampleQuantity'));
                    if (!partId) {
                        return notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Please select a part to proceed',
                            placement: 'topRight',
                        });
                    }
                    if (!qty) {
                        return notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Please enter a quantity to continue',
                            placement: 'topRight',
                        });
                    }
                    updateSamplesOnLineItem(partId, qty, 'Create');
                } }, "Add the Sample Request")))));
};
export default NewSampleRequest;
