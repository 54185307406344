import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Upload } from 'antd';
import _ from 'lodash';
import { API_ROOT } from '@partsbadger/utils';
const FILE_URL = API_ROOT + `/customer/files/`;
const { Option, OptGroup } = Select;
const { TextArea } = Input;
export const AdditionalRequirements = ({ value: originalValue, requirements, onChange, hide_extras, disabled, }) => {
    // Load default data
    const [selectedValue, setSelectedValue] = useState(originalValue?.map(v => {
        return {
            label: v.label,
            value: parseInt(v.key),
            extra: v.value ?? '',
        };
    }));
    const handleOnChange = (values) => {
        setSelectedValue(values);
        if (onChange) {
            const _translateToOriginal = values
                ? values.map(i => {
                    return {
                        key: i.value.toString(),
                        label: i.label,
                        value: i.extra,
                        files: i.files,
                    };
                })
                : [];
            onChange(_translateToOriginal);
        }
    };
    // Group by Type
    const options = _.groupBy(requirements, (item) => {
        return item.type;
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Select, { allowClear: true, mode: "multiple", placeholder: "Search", style: { width: '100%' }, labelInValue: true, optionFilterProp: "children", value: selectedValue ?? [], onChange: (items) => {
                    // Selected Item doesn't have the rest for fields (extra, files) so we need to append here
                    const _updated = items.map(i => {
                        const _found = selectedValue?.find(v => v.value == i.value);
                        return {
                            label: i.label,
                            value: i.value,
                            extra: _found ? _found.extra : null,
                            files: _found ? _found.files : [],
                        };
                    });
                    handleOnChange(_updated);
                }, disabled: disabled }, Object.keys(options).map(key => {
                return (React.createElement(OptGroup, { key: key, label: key }, options[key].map((item) => (React.createElement(Option, { key: item.id, value: item.id, extra: "test", files: [] }, item.name)))));
            }))),
        React.createElement("div", { style: { padding: '10px 21px' } }, selectedValue && !hide_extras && (React.createElement(ExtraInputs, { requirements: requirements, value: selectedValue, handleChange: handleOnChange })))));
};
const ExtraInputs = ({ value, requirements, handleChange }) => {
    if (!value) {
        return React.createElement("div", null);
    }
    return (React.createElement(React.Fragment, null, value.map(item => {
        const option = requirements.find(record => Number(record.id) === Number(item.value));
        if (!option) {
            return null;
        }
        const { field_type } = option;
        if (field_type === 'input') {
            return (React.createElement(Form.Item, { key: `input-${item.value}`, label: item.label },
                React.createElement(Input, { style: { maxWidth: 300 }, size: 'small', required: true, defaultValue: item.extra ?? undefined, onBlur: event => {
                        const new_data = value.map(old_record => {
                            return old_record.value === item.value
                                ? {
                                    ...old_record,
                                    extra: event.target.value,
                                }
                                : old_record;
                        });
                        handleChange(new_data);
                    } })));
        }
        if (field_type === 'inputtext') {
            return (React.createElement(Form.Item, { key: `input-${item.value}`, label: item.label },
                React.createElement(TextArea, { style: { maxWidth: 300 }, size: 'small', required: true, defaultValue: item.extra ?? '', onBlur: event => {
                        const new_data = value.map(old_record => {
                            return old_record.value === item.value
                                ? {
                                    ...old_record,
                                    extra: event.target.value,
                                }
                                : old_record;
                        });
                        handleChange(new_data);
                    } })));
        }
        if (field_type === 'file') {
            const defaultFileList = item?.files
                ?.filter(f => f)
                .map(file => {
                return {
                    uid: file.id,
                    name: file.filename,
                    status: 'done',
                    url: file.file,
                    response: {
                        id: file.id,
                    },
                };
            });
            return (React.createElement(Form.Item, { key: `upload-${item.value}`, label: item.label },
                React.createElement(Upload.Dragger, { name: "file", action: FILE_URL, headers: {
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    }, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    defaultFileList: defaultFileList, onChange: info => {
                        const fileList = info.fileList;
                        const files = fileList
                            ?.filter(f => f?.status === 'done')
                            .map(file => {
                            return {
                                id: file.response.id,
                                filename: file.response.filename,
                                file: file.response.file,
                            };
                        });
                        if (!files.length) {
                            return;
                        }
                        const items = value?.map(old_record => {
                            return old_record.value === item.value
                                ? {
                                    ...old_record,
                                    files: files,
                                }
                                : old_record;
                        });
                        handleChange(items);
                    }, multiple: true },
                    React.createElement(Button, null,
                        React.createElement(UploadOutlined, null),
                        " Click to upload"))));
        }
        return null;
    })));
};
