import * as React from 'react';
import { Select, Spin } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
import { Popover } from 'antd';
let busy = null;
export const Quotes = (props) => {
    const [loading, setLoading] = useState(false);
    const [quotes, setQuotes] = useState([]);
    const token = localStorage.getItem('token');
    const fetchQuotes = (params) => {
        if ('contact' in props) {
            params['contact'] = props.contact;
        }
        if ('account' in props) {
            params['account'] = props.account;
        }
        if ('stage' in props) {
            params['stage'] = props.stage;
        }
        if ('filters' in props) {
            params = { ...params, ...props.filters };
        }
        axios
            .get(`${BASE_URL}/staff/quotes/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
            params: params,
        })
            .then(response => {
            setQuotes(response.data.results);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        if (props.account) {
            fetchQuotes({
                account: props.account,
            });
        }
    }, [props.account]);
    return (React.createElement(Select, { showSearch: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search quotes", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : 'No results found', onSearch: name => {
            if (busy) {
                clearTimeout(busy);
            }
            const params = {
                search: name,
            };
            setLoading(true);
            busy = setTimeout(() => fetchQuotes(params), 400);
        }, ...props }, quotes.map(quote => (React.createElement(Select.Option, { key: quote.id, value: quote.name, account: quote.account, contact: quote.contact, entity: quote },
        quote.name,
        quote.notes && (React.createElement(Popover, { overlayStyle: { maxWidth: '500px' }, content: quote.notes },
            React.createElement("a", null, " (Notes) "))),
        ' ',
        props.show_in_zoho_alert && !quote?.zoho_id && React.createElement("span", { style: { color: 'red' } }, " Not In Zoho"))))));
};
