import React, { useState } from 'react';
import { get, post } from '@partsbadger/utils';
import { UploadFile } from '@partsbadger/library';
import { QuoteStore } from '../../../Stores';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Modal, message } from 'antd';
const { Paragraph } = Typography;
const InternalFiles = ({ master_product, quote_id }) => {
    const [showModal, setShowModal] = useState(false);
    return (React.createElement("div", { className: "flex items-center justify-center" },
        React.createElement("strong", null, "Internal Files:"),
        React.createElement(Paragraph, { ellipsis: { rows: 1, expandable: true }, style: { display: 'inline' }, className: "m-0 ml-1" }, master_product?.internal_files?.map(file => {
            return (React.createElement("div", { key: 'main' },
                React.createElement("div", { key: file.id, className: "link inline-block", 
                    // style={{ display: 'inline' }}
                    onClick: e => {
                        e.stopPropagation();
                        get(`/staff/files/${file.id}/internal-file-url/`).then((response) => {
                            window.open(response.url, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800');
                        });
                    } },
                    file.filename,
                    "\u00A0"),
                React.createElement(LegacyIcon, { title: 'Remove file', type: 'cross', className: 'link ml-1 text-red-600', onClick: e => {
                        e.stopPropagation();
                        post(`/staff/files/${file.id}/remove-internal-file/`).then(() => {
                            QuoteStore.getMasterProducts(quote_id);
                        });
                    } })));
        })),
        master_product.editable ? (React.createElement(React.Fragment, null,
            React.createElement(LegacyIcon, { title: 'Add files', type: 'plus', className: 'link ml-1', onClick: () => setShowModal(!showModal) }),
            showModal && (React.createElement(Modal, { visible: showModal, centered: true, title: "Internal Files", onCancel: () => setShowModal(false), footer: [] },
                React.createElement(UploadFile, { onUpload: file => {
                        const current_files = master_product?.internal_files
                            ? master_product?.internal_files?.map((r) => r.id)
                            : [{}];
                        post('/staff/master-products/' + master_product.id + '/upload-internal-file/', {
                            internal_files: [...current_files, file.response.id],
                        })
                            .then((response) => {
                            setShowModal(false);
                            QuoteStore.getMasterProducts(quote_id);
                        })
                            .catch(() => message.error('Error, try again'));
                    } }))))) : (React.createElement(LegacyIcon, { title: 'This master product is used in other quotes', type: 'lock', style: { fontSize: '10px' }, className: "link mr-2 mt-1" }))));
};
export default InternalFiles;
