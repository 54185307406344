import * as React from 'react';
import { Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import QuoteStore from '../../stores/QuoteStore';
let busy = null;
export const Accounts = observer((props) => {
    return (React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, filterOption: false, optionKeyProp: 'value', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search account by name", notFoundContent: QuoteStore.loading_accounts ? React.createElement(Spin, { size: "small" }) : null, onSearch: name => {
            if (busy) {
                clearTimeout(busy);
            }
            const params = {
                search: name,
            };
            busy = setTimeout(() => QuoteStore.getAccounts(params), 400);
        }, ...props }, QuoteStore.accounts.map(d => (React.createElement(Select.Option, { key: d.id }, d.name)))));
});
