import { action, observable, makeObservable } from 'mobx';
import { get, post, remove, printSalesOrder } from '../utils/api';
import { openNotificationWithIcon } from './UIStore';
class PurchaseOrderStore {
    constructor() {
        this.loading = false;
        this.PurchaseOrder = null;
        this.previousIntakes = [];
        this.activePreview = null;
        this.get = async (poID) => {
            const res = await get(`/staff/purchase-orders/${poID}/`);
            await get(`/staff/purchase-orders-intakes/?purchase_order_id=${poID}`).then(response => {
                this.previousIntakes = response.data.results;
                this.loading = false;
            });
            this.PurchaseOrder = res.data;
        };
        this.intake = async (poID, payload) => {
            await post(`/staff/purchase-orders/${poID}/intake/`, payload);
        };
        this.cancel_line_item = async (poID, payload) => {
            await post(`/staff/purchase-orders/${poID}/cancel-item/`, payload);
        };
        makeObservable(this, {
            // Observables
            loading: observable,
            PurchaseOrder: observable,
            previousIntakes: observable,
            activePreview: observable,
            // Actions
            get: action,
            intake: action,
            cancel_line_item: action,
            deleteIntake: action,
            setActivePreview: action,
            printPurchaseOrderIntake: action,
            clearData: action.bound,
        });
    }
    async deleteIntake(id, poID) {
        try {
            await remove(`/staff/purchase-orders-intakes/${id}/`);
            await this.get(poID);
        }
        catch (error) {
            console.log(error);
        }
    }
    async setActivePreview(url) {
        const response = await get(url);
        this.activePreview = response.data;
    }
    async printPurchaseOrderIntake(instanceID) {
        try {
            openNotificationWithIcon('info', 'Printing Meow', '');
            await printSalesOrder(`/staff/purchase-orders-intakes/${instanceID}/printout/`);
            openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
        }
        catch (err) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            openNotificationWithIcon('danger', 'Error', `${err.status} - ${err.statusText}`);
        }
    }
    clearData() {
        this.PurchaseOrder = null;
        this.activePreview = null;
        this.previousIntakes = null;
    }
}
export default new PurchaseOrderStore();
