import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import React from 'react';
import { GOOGLE_MAPS_API_KEY } from '@partsbadger/utils';
/**
 * Get the value for a given key in address_components
 *
 * @param {Array} components address_components returned from Google maps autocomplete
 * @param type key for desired address component
 * @returns {String} value, if found, for given type (key)
 */
function extractFromAddress(components, type) {
    return (components
        .filter(component => component.types.indexOf(type) === 0)
        .map(item => item)
        .pop() || null);
}
const AutoCompleteAddressInput = (props) => {
    const handleSelectAddress = async (selected) => {
        const value = selected.value;
        const results = await geocodeByPlaceId(value.place_id);
        if (results) {
            const postCode = extractFromAddress(results[0].address_components, 'postal_code');
            const street_number = extractFromAddress(results[0].address_components, 'street_number');
            const street = extractFromAddress(results[0].address_components, 'route');
            const full_street = `${street_number?.long_name ?? ''} ${street?.long_name ?? ''}`;
            const town = extractFromAddress(results[0].address_components, 'locality');
            const state = extractFromAddress(results[0].address_components, 'administrative_area_level_1');
            const country = extractFromAddress(results[0].address_components, 'country');
            if (props.onChange) {
                props.onChange(full_street);
            }
            if (props.onAutoFill) {
                props.onAutoFill({
                    street: full_street,
                    city: town?.long_name ?? '',
                    state: {
                        key: state?.short_name,
                        label: state?.long_name,
                        value: state?.short_name,
                    },
                    country: {
                        key: country?.short_name,
                        label: country?.long_name,
                        value: state?.short_name
                    },
                    zip_code: postCode?.long_name,
                });
            }
        }
    };
    return (React.createElement(GooglePlacesAutocomplete, { apiKey: GOOGLE_MAPS_API_KEY, selectProps: {
            placeholder: 'Type Street or P.O. Box',
            onChange: handleSelectAddress,
        } }));
};
export default AutoCompleteAddressInput;
