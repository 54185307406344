import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, List, Upload } from 'antd';
export const ChatLibrary = observer(({ messages, onSendMessage, form, height, onlyOneAttachmentPerMessage, chatIsReadOnly, useCheckboxNotifyOwner, }) => {
    const ref = useRef();
    const { getFieldDecorator, getFieldValue } = form;
    const onSend = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    body: values.body,
                    attachments: values.attachment ? values.attachment.map(f => f.originFileObj) : [],
                    notify_owner: values.notify_owner,
                };
                if (onSendMessage) {
                    onSendMessage(payload);
                    form.resetFields();
                }
            }
        });
    };
    useEffect(() => {
        // @ts-ignore
        ref.current.scrollTop = ref.current.scrollHeight;
    }, [messages.length]);
    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const renderUploadFile = () => {
        if (onlyOneAttachmentPerMessage === true) {
            return (React.createElement(Form.Item, { className: 'm-0 text-center' }, getFieldDecorator('attachment', {
                rules: [
                    {
                        required: false,
                        message: 'Please input a value!',
                    },
                ],
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
            })(React.createElement(Upload, { maxCount: 1, name: "avatar", showUploadList: true, beforeUpload: () => false },
                React.createElement(Button, null, "Add File")))));
        }
        else {
            return (React.createElement(Form.Item, { className: 'm-0 text-center' }, getFieldDecorator('attachment', {
                rules: [
                    {
                        required: false,
                        message: 'Please input a value!',
                    },
                ],
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
            })(React.createElement(Upload, { multiple: true, name: "avatar", showUploadList: true, beforeUpload: () => false },
                React.createElement(Button, null, "Add File")))));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { 
            //@ts-ignore
            ref: ref, id: "messages", className: 'chat-list', style: {
                maxHeight: height || 600,
                height: height || 600,
                overflowY: 'auto',
                border: 'solid 1px #d4d4d4',
            } },
            React.createElement(List, { className: "comment-list px-4", itemLayout: "horizontal", dataSource: messages.slice().reverse(), renderItem: item => (React.createElement("li", { className: "m-4 bg-gray-100 shadow p-4" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "mb-2 text-lg" }, !!item?.body && (React.createElement("div", { dangerouslySetInnerHTML: {
                                __html: item?.body,
                            } }))),
                        item.attachment && (React.createElement("div", { className: 'mt-2' },
                            React.createElement("a", { target: '_blank', onClick: () => {
                                    window.open(item.attachment, '', 'width=800,height=800,left=200,top=200');
                                } },
                                item.attachment.split('?')[0]?.split('/')?.pop(),
                                React.createElement(LegacyIcon, { type: 'paper-clip' })))),
                        item.attachments.map(attachment => {
                            return (React.createElement("div", { key: attachment.id, className: 'mt-2' },
                                React.createElement("a", { target: '_blank', onClick: () => {
                                        window.open(attachment.file, '', 'width=800,height=800,left=200,top=200');
                                    } },
                                    attachment.filename,
                                    React.createElement(LegacyIcon, { type: 'paper-clip' }))));
                        })),
                    React.createElement("div", null,
                        item.author?.fullname,
                        " at ",
                        dayjs(item.created_time).format('LLLL'),
                        item.notify_owner && React.createElement(LegacyIcon, { style: { marginLeft: '1rem' }, type: 'mail' })))) })),
        (chatIsReadOnly === undefined || chatIsReadOnly === false) && (React.createElement("div", { className: 'chat-form  bottom-0 w-11/12 mb-2 mt-4', style: {
                left: 5,
            } },
            React.createElement(Form, { className: '' },
                React.createElement("div", { className: '' },
                    React.createElement(Form.Item, { className: 'm-0' }, getFieldDecorator('body', {
                        rules: [
                            {
                                required: !getFieldValue('attachment'),
                                message: 'Please input a value!',
                            },
                        ],
                    })(React.createElement(Input.TextArea, { onKeyPress: e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                onSend();
                            }
                        } }))),
                    React.createElement("div", { className: "flex items-center justify-around" },
                        renderUploadFile(),
                        (useCheckboxNotifyOwner === undefined || useCheckboxNotifyOwner === true) && (React.createElement(Form.Item, { help: 'Notify Owner', className: 'm-0 text-center' }, getFieldDecorator('notify_owner', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input a value!',
                                },
                            ],
                            valuePropName: 'checked',
                            initialValue: true,
                        })(React.createElement(Checkbox, null)))),
                        React.createElement(Button, { onClick: onSend, type: "primary", htmlType: "submit" }, "Send"))))))));
});
