import * as React from 'react';
import { Select } from 'antd';
export const ShippingCarriers = (props) => {
    return (React.createElement(Select, { style: {
            minWidth: '100%',
        }, size: 'small', showSearch: true, ...props },
        React.createElement(Select.Option, { value: "FedEX" }, "FedEX"),
        React.createElement(Select.Option, { value: "UPS" }, "UPS"),
        React.createElement(Select.Option, { value: "USPS" }, "USPS"),
        React.createElement(Select.Option, { value: "DHL" }, "DHL"),
        React.createElement(Select.Option, { value: "BlueDart" }, "BlueDart"),
        React.createElement(Select.Option, { value: "Freight" }, "Freight"),
        React.createElement(Select.Option, { value: "Customer" }, "Customer"),
        React.createElement(Select.Option, { value: "PartsBadger" }, "PartsBadger")));
};
export const ShippingCarriersPurchasesOrders = (props) => {
    return (React.createElement(Select, { style: {
            minWidth: '100%',
        }, size: 'small', showSearch: true, ...props },
        React.createElement(Select.Option, { value: "FedEX" }, "FedEX"),
        React.createElement(Select.Option, { value: "UPS" }, "UPS"),
        React.createElement(Select.Option, { value: "USPS" }, "USPS"),
        React.createElement(Select.Option, { value: "DHL" }, "DHL"),
        React.createElement(Select.Option, { value: "Freight" }, "Freight"),
        React.createElement(Select.Option, { value: "Vendor" }, "Vendor"),
        React.createElement(Select.Option, { value: "PartsBadger" }, "PartsBadger")));
};
export const ShippingMethods = (props) => {
    return (React.createElement(Select, { showSearch: true, style: {
            width: '100%',
        }, size: 'small', ...props },
        React.createElement(Select.Option, { value: "AM Overnight" }, "AM Overnight"),
        React.createElement(Select.Option, { value: "PM Overnight" }, "PM Overnight"),
        React.createElement(Select.Option, { value: "2-Day" }, "2-Day"),
        React.createElement(Select.Option, { value: "UPS 3-Day" }, "UPS 3-Day"),
        React.createElement(Select.Option, { value: "Priority" }, "Priority"),
        React.createElement(Select.Option, { value: "Ground" }, "Ground"),
        React.createElement(Select.Option, { value: "International Priority" }, "International Priority"),
        React.createElement(Select.Option, { value: "International Economy" }, "International Economy"),
        React.createElement(Select.Option, { value: "As Needed Per Date" }, " As Needed Per Date "),
        React.createElement(Select.Option, { value: "Call Customer to Confirm" }, " Call Customer to Confirm "),
        React.createElement(Select.Option, { value: "TBD" }, "TBD "),
        React.createElement(Select.Option, { value: "Same Day" }, "Same Day "),
        React.createElement(Select.Option, { value: "PartsBadger Drop Off" }, "PartsBadger Drop Off "),
        React.createElement(Select.Option, { value: "Customer Pick Up" }, "Customer Pick Up ")));
};
export const ShippingMethodsPurchasesOrders = (props) => {
    return (React.createElement(Select, { showSearch: true, style: {
            width: '100%',
        }, size: 'small', ...props },
        React.createElement(Select.Option, { value: "AM Overnight" }, "AM Overnight"),
        React.createElement(Select.Option, { value: "PM Overnight" }, "PM Overnight"),
        React.createElement(Select.Option, { value: "2-Day" }, "2-Day"),
        React.createElement(Select.Option, { value: "UPS 3-Day" }, "UPS 3-Day"),
        React.createElement(Select.Option, { value: "Priority" }, "Priority"),
        React.createElement(Select.Option, { value: "Ground" }, "Ground"),
        React.createElement(Select.Option, { value: "Same Day" }, "Same Day"),
        React.createElement(Select.Option, { value: "As Needed Per Date" }, " As Needed Per Date "),
        React.createElement(Select.Option, { value: "PartsBadger \u2013 Pick-Up/Drop-Off" }, "PartsBadger \u2013 Pick-Up/Drop-Off"),
        React.createElement(Select.Option, { value: "Vendor \u2013 Pick-Up/Drop-Off" }, "Vendor \u2013 Pick-Up/Drop-Off"),
        React.createElement(Select.Option, { value: "Call PB for Preference" }, "Call PB for Preference")));
};
export const ShippingRequirements = (props) => {
    return (React.createElement(Select, { mode: 'multiple', style: {
            width: '100%',
        }, size: 'small', ...props },
        React.createElement(Select.Option, { value: "Batch/Blanket Releases" }, "Batch/Blanket Releases"),
        React.createElement(Select.Option, { value: "Ship with Supplied FAI" }, "Ship with Supplied FAI"),
        React.createElement(Select.Option, { value: "Ship with Supplied COC" }, "Ship with Supplied COC"),
        React.createElement(Select.Option, { value: "Ship with Supplied Packing List" }, "Ship with Supplied Packing List"),
        React.createElement(Select.Option, { value: "Ship in Full (No Partials)" }, "Ship in Full (No Partials)"),
        React.createElement(Select.Option, { value: "Ship Partials (Completed Line Items)" }, "Ship Partials (Completed Line Items)"),
        React.createElement(Select.Option, { value: "Ship Partials (Any)" }, "Ship Partials (Any)"),
        React.createElement(Select.Option, { value: "Allow Weekend Delivery" }, "Allow Weekend Delivery")));
};
export const ShippingOptions = (props) => {
    return (React.createElement(Select, { showSearch: true, style: {
            width: '100%',
        }, size: 'small', ...props },
        React.createElement(Select.Option, { value: "Hold until Received in Full" }, "Hold until Received in Full"),
        React.createElement(Select.Option, { value: "Hold until Documentation is Approved" }, "Hold until Documentation is Approved"),
        React.createElement(Select.Option, { value: "Allow Partial Shipments (Complete Line Items Only)" }, "Allow Partial Shipments (Complete Line Items Only)"),
        React.createElement(Select.Option, { value: "Allow Partial Shipments" }, "Allow Partial Shipments")));
};
