import React, { Suspense, useEffect, useState } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Center } from '@react-three/drei';
import { ContactShadows } from './ContactShadows';
import { Edges } from './Edges';
import { LoadingOutlined } from '@ant-design/icons';
import { get } from '@partsbadger/utils';
function Scene(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Center, null,
            React.createElement("group", null,
                React.createElement("mesh", { geometry: props?.geometry, castShadow: true },
                    React.createElement("meshPhysicalMaterial", { attach: "material", color: 0xffffff, metalness: 0.7, roughness: 0.5 }),
                    props?.edges ? (React.createElement(Edges, { scale: 1.002, threshold: 15, color: 0x444444 })) : null)))));
}
export const ThreeJSThumbnail = (props) => {
    const [geometry, setGeometry] = useState(null);
    const [part, setPart] = useState(null);
    const loader = new THREE.BufferGeometryLoader();
    const fetchPart = async () => {
        const data = await get(`/quickturn/api/parts/${props?.id}/`);
        setPart(data);
    };
    useEffect(() => {
        if (props?.id) {
            fetchPart();
        }
    }, [props?.id, props?.seed]);
    useEffect(() => {
        if (part?.get_buffer_geometry) {
            const bufferGeo = loader.parse(part?.get_buffer_geometry);
            setGeometry(bufferGeo);
        }
    }, [part]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Suspense, { fallback: null },
            React.createElement("div", { style: { width: props?.size, height: props?.size } }, !props?.id || !part || !geometry || !part.material ? (React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center" },
                React.createElement(LoadingOutlined, { style: { fontSize: '2rem', color: 'rgba(0,0,0,0.5)' } }))) : (React.createElement(Canvas, { dpr: window.devicePixelRatio, camera: {
                    fov: Math.hypot(part.overallLength, part.hasFlange ? part.flangeDiameter : part.outerDiameter) * 2,
                    near: 0.1,
                    far: 1000,
                    position: [-25, 10, 25],
                }, shadows: true },
                React.createElement("color", { attach: "background", args: [props?.background] }),
                React.createElement("hemisphereLight", { intensity: 0.3, position: [0, 100, 0] }),
                React.createElement("spotLight", { color: 0xebf7f7, intensity: 0.7, position: [-5, 10, -12] }),
                React.createElement("spotLight", { color: 0xebf7f7, intensity: 0.2, position: [-10, -10, -5] }),
                React.createElement("pointLight", { position: [10, 10, 10], intensity: 1, color: 0xc8dee0 }),
                React.createElement(Scene, { geometry: geometry, edges: props?.edges, part: part }),
                React.createElement(ContactShadows, { position: [
                        0,
                        part?.hasFlange ? -(part?.flangeDiameter / 2) : -(part?.outerDiameter / 2),
                        0,
                    ], opacity: 0.3, scale: 10, blur: 3, far: 0.8 })))))));
};
