import { Checkbox, Divider, message, PageHeader, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { TableSalesOrderItems } from './components/TableSalesOrderItems';
import CreditMemoForm from './Forms/CreditMemoForm';
import SalesOrderStore from './Stores/SalesOrderStore';
import CreditMemosStore from './Stores/CreditMemosStore';
import { useHistory } from 'react-router-dom';
import { TableCreditMemos } from './components/TablesCreditMenos';
import _ from 'lodash';
const { TabPane } = Tabs;
const CreditMemoTool = (props) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('1');
    const [selectedItems, setSelectedItems] = useState([]);
    let isIndeterminate = false;
    async function onSubmitted(data) {
        const payload = {
            sales_order: props.sales_order_id,
            account: SalesOrderStore.order?.account?.id,
            ...data,
        };
        const response = await CreditMemosStore.create(props.sales_order_id, payload);
        if (response) {
            await CreditMemosStore.list(props.sales_order_id);
            message.success('Credit memos created.');
            setActiveTab('1');
            setSelectedItems([]);
        }
        return response;
    }
    async function getOrder() {
        await SalesOrderStore.getOrderById(props.sales_order_id);
        if (SalesOrderStore.order) {
            isIndeterminate =
                selectedItems.length > 0 && selectedItems.length < _.size(SalesOrderStore.order.line_items);
        }
    }
    useEffect(() => {
        getOrder();
        CreditMemosStore.list(props.sales_order_id);
    }, []);
    /**
     * Add or remove items from the selected array list
     */
    const handleSelect = (order_item_id, quantity, selected) => {
        if (selected) {
            const selected_item = {
                sales_order_line_item: order_item_id,
                quantity: quantity,
            };
            setSelectedItems([...selectedItems, selected_item]);
        }
        else {
            setSelectedItems(selectedItems.filter(item => item.sales_order_line_item !== order_item_id));
        }
    };
    /**
     * Select all items
     */
    const handleSelectAll = e => {
        if (e.target.checked && SalesOrderStore.order) {
            const selected = _.map(SalesOrderStore.order.line_items).map(line_items => {
                return { sales_order_line_item: line_items.id, quantity: line_items.quantity, selected: true };
            });
            setSelectedItems(selected);
        }
        else {
            setSelectedItems([]);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { onBack: () => history.goBack(), title: React.createElement("div", { className: 'flex flex-row' },
                React.createElement("div", { className: 'font-bold mx-2' }, "Sales Order:"),
                React.createElement("div", { className: 'font-light' },
                    SalesOrderStore.order?.id,
                    " - ",
                    SalesOrderStore.order?.name)) }),
        React.createElement(Tabs, { defaultActiveKey: "1", activeKey: activeTab, onTabClick: (key) => setActiveTab(key) },
            React.createElement(TabPane, { tab: "List", key: "1" },
                React.createElement(TableCreditMemos, { credit_memos: CreditMemosStore.credit_memos })),
            React.createElement(TabPane, { tab: "New", key: "2" },
                React.createElement("div", { title: 'Enter the information for the new credit memo' },
                    React.createElement(CreditMemoForm, { selectedItems: selectedItems, onSubmitted: onSubmitted }),
                    React.createElement(Divider, { orientation: 'left' }, "Select Order Items"),
                    SalesOrderStore.order && (React.createElement(React.Fragment, null,
                        React.createElement(Checkbox, { checked: selectedItems.length === SalesOrderStore.order.line_items.length, className: 'p-2', indeterminate: isIndeterminate, onChange: handleSelectAll }, "Select All"),
                        React.createElement("div", { className: 'flex flex-row w-full p-4 font-bold', style: { border: '1px solid #d4d4d4' } },
                            React.createElement("div", { className: 'w-1/4' }),
                            React.createElement("div", { className: 'w-1/4' }, "Name"),
                            React.createElement("div", { className: 'w-1/4' }, "Awarded Vendor"),
                            React.createElement("div", { className: 'w-1/4' }, "Quantity")),
                        _.map(SalesOrderStore.order.line_items).map((order_line_item, key) => {
                            let isSelected = false;
                            selectedItems.map(item => {
                                if (item.sales_order_line_item === order_line_item.id) {
                                    isSelected = true;
                                }
                            });
                            return (React.createElement("div", { className: 'p-3', key: key, style: {
                                    backgroundColor: isSelected ? '#F1FAFC' : '',
                                    borderBottom: '1px solid #d4d4d4',
                                    borderLeft: '1px solid #d4d4d4',
                                    borderRight: '1px solid #d4d4d4',
                                } },
                                React.createElement(TableSalesOrderItems, { order_line_item: order_line_item, isSelected: isSelected, handleSelect: (order_item_id, quantity, selected) => {
                                        handleSelect(order_item_id, quantity, selected);
                                    } })));
                        }))))))));
};
export default observer(CreditMemoTool);
