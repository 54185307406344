import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_ROOT } from '@partsbadger/utils';
import QuoteStore from '../../stores/QuoteStore';
export const UploadDrawing = ({ item, readOnly = false }) => {
    const { files_2d } = item.product?.attachments || {};
    const defaultFileList = files_2d?.id
        ? [
            {
                uid: files_2d.id,
                name: files_2d.filename,
                status: 'done',
                response: {
                    id: files_2d.id,
                    file: files_2d.file,
                    filename: files_2d.filename,
                },
            },
        ]
        : [];
    const [fileList, setFileList] = useState(defaultFileList);
    const headers = QuoteStore.token ? { Authorization: `Token ${QuoteStore.token}` } : {};
    const props = {
        name: 'file',
        multiple: false,
        accept: '.pdf, .dxf',
        action: `${API_ROOT}/customer/drawing-files/`,
        headers: headers,
        defaultFileList: defaultFileList,
        fileList: fileList,
        onChange: (info) => {
            const { status } = info.file;
            let fileList = info.fileList;
            fileList = fileList.slice(-1);
            //@ts-ignore
            setFileList(fileList);
            if (status === 'done') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_2d: info.file.response.id },
                });
            }
            if (status === 'removed') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_2d: null },
                });
            }
        },
    };
    if (!item?.uid) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Upload, { className: 'mb-2', ...props, showUploadList: {
                showRemoveIcon: !readOnly,
            } }, !files_2d && !readOnly ? (React.createElement(Button, { className: "ant-btn-xs w-100", block: true, icon: React.createElement(UploadOutlined, null) }, "Add 2D Drawing File")) : (React.createElement("div", { onClick: e => e.stopPropagation() },
            React.createElement("span", { className: "font-weight-bold" }, "2D Drawing File:"))))));
};
