import React from 'react';
import { Col, Row, Spin } from 'antd';
import { Price } from '@partsbadger/library';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import { observer } from 'mobx-react';
export const InputsTotalsForm = observer(() => {
    if (!PurchaseOrderStore.purchase_order) {
        return React.createElement(Spin, { tip: 'Loading Purchase Order' });
    }
    const purchase_order = PurchaseOrderStore.purchase_order;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-row p-4" },
            React.createElement("div", { className: 'w-2/4' },
                React.createElement(Row, { key: 'tax' },
                    React.createElement(Col, { xl: 12, className: "flex flex-col my-4" }))),
            React.createElement("div", { className: 'w-2/4' },
                React.createElement("div", { className: 'flex flex-col w-full' },
                    React.createElement(Row, { key: 'subtotal' },
                        React.createElement(Col, { xl: 12, className: "flex flex-col my-4" }),
                        React.createElement(Col, { xl: 12 },
                            React.createElement("div", { className: 'flex flex-col justify-between ' },
                                React.createElement("div", { className: "flex flex-row justify-between   " },
                                    React.createElement("div", { className: "text-sm" }, "Subtotal"),
                                    React.createElement("div", { className: "text-sm" },
                                        React.createElement(Price, { value: purchase_order.subtotal_without_taxes }),
                                        " (+)"))))),
                    React.createElement(Row, { key: 'subtotal' },
                        React.createElement(Col, { xl: 12, className: "flex flex-col my-4" }),
                        React.createElement(Col, { xl: 12 },
                            React.createElement("div", { className: 'flex flex-col justify-between ' },
                                React.createElement("div", { className: "flex flex-row justify-between   " },
                                    React.createElement("div", { className: "text-sm" }, "Tax"),
                                    React.createElement("div", { className: "text-sm" },
                                        React.createElement(Price, { value: purchase_order.total_taxes }),
                                        " (+)"))))),
                    React.createElement(Row, { key: 'totals' },
                        React.createElement(Col, { xl: 12, className: "flex flex-col my-4" }),
                        React.createElement(Col, { xl: 12 },
                            React.createElement("div", { className: 'flex flex-col justify-between ' },
                                React.createElement("div", { className: "flex flex-row justify-between   pt-4", style: {
                                        borderTop: 'solid 1px #d9d9d9',
                                    } },
                                    React.createElement("div", { className: "font-bold text-xl" }, "Total"),
                                    React.createElement("div", { className: "font-bold text-xl" },
                                        React.createElement(Price, { value: purchase_order.grand_total_with_shipping })))))))))));
});
