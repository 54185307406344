import { Select } from 'antd';
import React from 'react';
const { Option } = Select;
export const SelectReinforcement = ({ ...props }) => {
    return (React.createElement(Select, { showSearch: true, filterOption: true, size: "small", ...props },
        React.createElement(Option, { value: "carbon_fiber" }, " Carbon Fiber "),
        React.createElement(Option, { value: "kevlar" }, " Kevlar"),
        React.createElement(Option, { value: "fiberglass" }, " Fiberglass "),
        React.createElement(Option, { value: "hsht_fiberglass" }, " HSHT fiberglass "),
        React.createElement(Option, { value: "none" }, " None ")));
};
