import React, { useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { SAFE_MATERIALS } from './utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import Suggestion from '../Suggestions';
const Materials = observer((props) => {
    const [isLoading, setIsLoading] = useState(false);
    const items = props.materials;
    useEffect(() => {
        // fetchData('');
    }, []);
    const debouncedSearch = useCallback(_.debounce(search => fetchData(search), 400), []);
    const fetchData = async (search) => {
        setIsLoading(true);
        const params = {
            search: search,
        };
        if (props.manufacturing_type) {
            params['manufacturing_type'] = props.manufacturing_type;
        }
        await props.handleSearch(params);
        setIsLoading(false);
    };
    // Group by Material Type
    const materials_grouped = _.groupBy(items, (item) => item.material_type);
    const menu = _.map(materials_grouped, (items, key) => {
        return (React.createElement(Select.OptGroup, { key: key, label: 'Uncategorized' }, items.map(material => (React.createElement(Select.Option, { style: {
                backgroundColor: SAFE_MATERIALS.includes(material.name)
                    ? 'rgba(169, 255, 169, 0.3)'
                    : 'inherit',
            }, key: material.id }, material.name)))));
    });
    return (React.createElement("div", null,
        React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, size: 'small', placeholder: "Search", filterOption: false, dropdownMatchSelectWidth: false, style: { width: '100%' }, notFoundContent: isLoading ? React.createElement(Spin, { size: "small", spinning: true }) : null, onSearch: debouncedSearch, ...props }, menu),
        //@ts-ignore
        props.value?.label !== 'Custom' && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, props.suggestions?.map(suggestion => (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: () => {
                const val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    // @ts-ignore
                    props.onSelect(val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })))))));
});
export default Materials;
