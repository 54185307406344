import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import moment from 'moment';
import { AdditionalRequirements, Addresses, AddressForm, Contacts, Deals, EditableFieldWrapper, Industries, is_valid_address, Quotes, ShippingCarriers, ShippingMethods, ShippingOptions, ShippingRequirements, Users, FormErrorParser, DeletionAlert, } from '@partsbadger/library';
import { EditOutlined, MessageOutlined, WarningTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Drawer, Input, message, Modal, notification, Row, Select, Spin, Tabs, Typography, Popover, Tag, List, } from 'antd';
import { useHistory } from 'react-router-dom';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import { QuotedLineItems } from '../../QuotedLineItems';
import OrderLineItems from './OrderLineItems';
import { ApplicationCostForm } from '../ApplicationCostForm';
import { OrderTotals } from '../../OrderTotals';
import { PreviewQuote } from '../../PreviewQuote';
import SalesOrderNotifications from '../../SalesOrderNotifications';
import ApplicationCostItems from '../../ApplicationCostItems';
import SalesOrderStatus from '../../components/SalesOrderStatus';
import DesignEngineerChecklist from '../SalesOrderChecklist/DesignEngineerChecklist';
import PointPersonChecklist from '../SalesOrderChecklist/PointPersonChecklist';
import ProductionChecklist from '../SalesOrderChecklist/ProductionChecklist';
import AccountManager from './AccountManager';
import { TOTAL_PRICE_SHOW_CHECKLIST } from '../../utils/utils';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { RichTextEditor } from '@partsbadger/library';
import { TaxExemptList } from '../../../../quotes_frontend/src/components/Contact/taxes/TaxExemptList';
import { TaxExemptForm } from '../../../../quotes_frontend/src/components/Contact/taxes/TaxExemptForm';
import { Table3DFilesApproval } from '../../components/Table3DFilesApproval';
const { TabPane } = Tabs;
const formItemLayout = {
    labelCol: { lg: 6, md: 12 },
    wrapperCol: { lg: 18, md: 12 },
};
const cols = {
    xs: 24,
    sm: 24,
    md: 12,
};
const cols2 = {
    xs: 24,
    sm: 24,
    md: 24,
};
const newCols = {
    xs: 24,
    sm: 24,
    md: 24,
};
const { Title } = Typography;
const SalesOrderEditForm = observer((props) => {
    const [loading, setLoading] = useState(false);
    const [is_first_load, setIsFirstLoad] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [showAddNewAddress, setShowAddNewAddress] = useState(false);
    const quoteURL = (id) => `https://quotes.parts-badger.com/quotes/${id}`;
    const [showAddSpecificItems, setShowAddSpecificItems] = useState(false);
    const [show_edit_form, showEditContactForm] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [isSendingToZoho, setSendingQuoteToZoho] = useState(false);
    const [averageMargin, setAverageMargin] = useState(0);
    const [lifetimeValue, setLifetimeValue] = useState(0);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const shipping_address = getFieldValue('shipping_address');
    const [handleCloseDesinEngineerCheklist, setHandleCloseDesinEngineerCheklist] = useState(false);
    const [handleCloseProductionCheklist, setHandleCloseProductionCheklist] = useState(false);
    const [handleClosePointPersonCheklist, setHandleClosePointPersonCheklist] = useState(false);
    const [isSalesRep, setIsSalesRep] = useState(false);
    const [isDesignEngineer, setIsDesignEngineer] = useState(false);
    const [isProductionReview, setIsProductionReview] = useState(false);
    const [isPointPersonReview, setIsPointPersonReview] = useState(false);
    const [shippingModalVisible, setShippingModalVisible] = useState(false);
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [sendSalesOrder, setSendSalesOrder] = useState(false);
    const [sending, setSending] = useState(false);
    const [salesOrderNotes, setSalesOrderNotes] = useState('');
    const [taxExemptForm, setTaxExemptForm] = useState(false);
    const [addNewTaxExemptForm, setAddNewTaxExemptForm] = useState(false);
    const [taxForm, setTaxForm] = useState({ id: null });
    const [upload3DFilesModal, setUpload3DFilesModal] = useState(false);
    const history = useHistory();
    const { loading_quote_items, order, quoted_line_items, addresses, getLeadItem, users } = SalesOrderStore;
    const editAddress = record => {
        setTaxForm(record);
        setAddNewTaxExemptForm(false);
    };
    const downloadAllFiles = () => {
        if (id) {
            setDownloading(true);
            SalesOrderStore.downloadAllFiles(id).finally(() => setDownloading(false));
        }
    };
    const handleSubmit = () => {
        const orderItemsDateErrors = [];
        const files3DApproval = [];
        SalesOrderStore.order?.line_items
            .filter(i => i.name != 'CNC Part')
            .map(order_item => {
            const lineItemDates = { required_part_ship_date: false, required_partner_ship_date: false, name: '' };
            if (order_item.required_part_ship_date === null) {
                lineItemDates.required_part_ship_date = true;
            }
            if (order_item.required_partner_ship_date === null) {
                lineItemDates.required_partner_ship_date = true;
            }
            lineItemDates.name = order_item.name;
            if (lineItemDates.required_part_ship_date || lineItemDates.required_partner_ship_date) {
                orderItemsDateErrors.push(order_item);
            }
            if (order_item.production_3d_file_approval_status === 'Need 3D Approval' ||
                order_item.production_3d_file_approval_status === 'Rejected') {
                files3DApproval.push(order_item.name);
            }
        });
        if (files3DApproval.length > 0) {
            notification.error({
                description: `Name: ${files3DApproval[0]}`,
                message: 'This item needs 3d file approval',
            });
            return;
        }
        if (orderItemsDateErrors.length > 0) {
            orderItemsDateErrors.forEach(item => {
                notification.error({
                    message: `
                        These fields are required: ${item.required_part_ship_date === null && 'Part ship date'}, ${item.required_partner_ship_date === null && 'Partner ship date'}
                    `,
                    description: `Product: ${item.name}`,
                });
            });
            return;
        }
        if (orderItemsDateErrors.length === 0) {
            props.form.validateFields((err, values) => {
                if (!err) {
                    setLoading(true);
                    if (!id) {
                        return;
                    }
                    if (!contact) {
                        message.info('Select a Contact');
                        return;
                    }
                    if (!account) {
                        message.info('Select a Account');
                        return;
                    }
                    const dates_ship = [];
                    SalesOrderStore.order?.line_items
                        .filter(i => i.name != 'CNC Part')
                        .map(order_item => {
                        if (order_item.required_part_ship_date !== null && order_item.quote_has_product) {
                            dates_ship.push(moment(order_item.required_part_ship_date).toDate());
                        }
                        if (order_item.vendor_qualifications === null) {
                            notification.error({
                                message: 'Order item not assigned a vendor qualification.',
                                description: `Name: ${order_item.name}`,
                            });
                        }
                    });
                    let maxShipDate = null;
                    if (dates_ship) {
                        maxShipDate = new Date(Math.min.apply(null, dates_ship));
                    }
                    // Extra max Partners ship date from all items
                    const max_partner_ship_date = SalesOrderStore.order?.line_items
                        ?.filter(item => item.required_partner_ship_date)
                        ?.map(order_item => moment(order_item.required_partner_ship_date).toDate())
                        ?.reduce(function (a, b) {
                        return b < a ? a : b;
                    });
                    delete values.quotes;
                    const payload = {
                        ...values,
                        purchase_order_number: values.purchase_order_number,
                        payments_received: values.payments_received || 0,
                        pb_shipping_cost: values.pb_shipping_cost || 0,
                        shipping_cost: values.shipping_cost || 0,
                        invoice_due_date: values?.invoice_due_date && dayjs(values?.invoice_due_date).format('YYYY-MM-DD'),
                        payment_date: values?.payment_date && dayjs(values?.payment_date).format('YYYY-MM-DD'),
                        required_ship_date: maxShipDate ? moment(maxShipDate).format('YYYY-MM-DD') : null,
                        partner_ship_date: max_partner_ship_date
                            ? moment(max_partner_ship_date).format('YYYY-MM-DD')
                            : null,
                        hot_ship_date: values?.hot_ship_date && dayjs(values.hot_ship_date).format('YYYY-MM-DD'),
                        status: 'Ready for Production',
                        additional_requirements: values?.additional_requirements?.map((r) => {
                            return r.label;
                        }),
                        internal_notes: values?.internal_notes,
                        owner: null,
                        taxes_rate: taxes_rate,
                    };
                    if (values.owner?.key) {
                        payload['owner'] = values.owner.key;
                    }
                    if (values.industry_use?.industry_use?.key) {
                        payload['industry_use'] = values.industry_use?.industry_use?.key;
                        payload['end_use'] = values.industry_use.end_use.label;
                    }
                    payload['average_margin'] = averageMargin.toFixed(2);
                    payload['lifetime_value'] = lifetimeValue.toFixed(2);
                    // payload['account_manager'] = values.account_manager;
                    SalesOrderStore.sendToProduction(id, payload)
                        .then(() => {
                        props.onSubmitted();
                    })
                        .catch(() => setLoading(false));
                }
                else {
                    notification.error({
                        key: 'form-errors',
                        duration: 8,
                        message: 'Please input all required fields',
                        description: React.createElement(FormErrorParser, { errors: err }),
                        placement: 'top',
                        maxCount: 1,
                    });
                }
            });
        }
    };
    const handleCloseOrderProcessingCheklist = () => {
        setHandleCloseDesinEngineerCheklist(false);
    };
    const handleCloseOrderProductionCheklist = () => {
        setHandleCloseProductionCheklist(false);
    };
    const handleCloseOrderPointPersonCheklist = () => {
        setHandleClosePointPersonCheklist(false);
    };
    useEffect(() => {
        setIsFirstLoad(true);
        SalesOrderStore.getAdditionalRequirements();
        SalesOrderStore.getNotifications(props.sales_order_id);
        getMarginLifetimeQuote();
        getUser();
    }, []);
    useEffect(() => {
        if (SalesOrderStore.sales_order_notifications.length > 0) {
            setShowNotifications(true);
        }
        else {
            setShowNotifications(false);
        }
    }, [SalesOrderStore.sales_order_notifications.length]);
    function dragElement(elmnt) {
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (elmnt && document.getElementById(elmnt.id + 'header')) {
            // if present, the header is where you move the DIV from:
            const el = document.getElementById(elmnt.id + 'header');
            if (el)
                el.onmousedown = dragMouseDown;
        }
        else if (elmnt) {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
        }
        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }
        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
            elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
        }
        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
    const getMarginLifetimeQuote = () => {
        SalesOrderStore.getMarginLifetimeQuote(props.sales_order_id)
            .then(response => {
            const average_margin = response.average_margin ?? 0;
            const lifetime_value = response.lifetime_value ?? 0;
            setAverageMargin(average_margin);
            setLifetimeValue(lifetime_value);
        })
            .catch(error => {
            message.error(error.message);
        });
    };
    const getUser = () => {
        SalesOrderStore.getSessionUser()
            .then(response => {
            const groups = response.group;
            setIsSalesRep(groups.includes('Sales Representatives'));
            setIsDesignEngineer(groups.includes('Design Engineers'));
            setIsProductionReview(groups.includes('Production Review'));
            setIsPointPersonReview(groups.includes('Point Person Review'));
        })
            .catch(error => {
            message.error(error.message);
        });
    };
    async function loadOrder() {
        await SalesOrderStore.getOrderById(props.sales_order_id).finally(() => {
            setIsFirstLoad(false);
        });
        if (SalesOrderStore.order?.design_engineer === null) {
            Modal.confirm({
                title: 'Confirm',
                content: `Set ${props.current_user.email} as the Design Engineer? `,
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    SalesOrderStore.update(props.sales_order_id, {
                        design_engineer: props.current_user.id,
                    });
                },
            });
        }
        if (SalesOrderStore.order?.design_engineer !== null && SalesOrderStore.order?.status === 'Waiting on 3d File') {
            setUpload3DFilesModal(true);
        }
    }
    /**
     * Load Order
     */
    useEffect(() => {
        loadOrder();
    }, [props.sales_order_id]);
    /**
     *Get Taxes with shipping zip code for the specific contact
     */
    useEffect(() => {
        if (contact && shipping_address?.zip_code) {
            SalesOrderStore.getTaxesByContactAndZipCode(contact.id, shipping_address?.zip_code);
        }
    }, [order?.contact, shipping_address?.zip_code]);
    /***
     * Get line items if a quote is selected or removed
     */
    useEffect(() => {
        if (order?.quotes?.length && order.quotes.length > 0) {
            order.quotes.forEach(q => {
                SalesOrderStore.getQuoteLineItems(q.id);
                SalesOrderStore.getQuote(q.id);
            });
        }
    }, [order?.quotes?.length]);
    // Don't load until the order is set to the global state
    if (is_first_load || !order?.id) {
        return React.createElement(Spin, { spinning: true });
    }
    const { id, name, created_time, quotes, deal_related, account, contact, shipping_name, shipping_street, shipping_city, shipping_country, shipping_state, shipping_zip_code, billing_name, billing_street, billing_city, billing_country, billing_state, billing_zip_code, create_2d_files, create_3d_files, notes, purchase_order, po_files, additional_files, additional_requirements, carrier, hot_ship_date, in_house_requirements, internal_notes, invoice_due_date, invoiced, paid_in_full, partner_ship_date, payment_date, payment_notes, payment_terms, payments_received, pb_shipping_cost, required_ship_date, hardware_required, domestic_process_needed, shipping_method, shipping_requirements, shipping_account_number, shipping_options, shipping_prepaid, shipping_cost, status, subtotal, discount, adjustment, taxes_rate, owner, design_engineer, industry_use, export_control, hot_order, hot_order_notes, sales_rep_checklist, order_processing_checklist, account_manager, has_customer_supplied_material, has_domestic_quoting, } = order;
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { className: 'sales-order-form' },
            React.createElement(Row, { className: 'row-order mt-5 text-left', style: {
                    padding: 20,
                } },
                React.createElement(Col, { md: 8 },
                    React.createElement(Title, { level: 4 },
                        "Request #",
                        id),
                    React.createElement("div", null, name),
                    React.createElement("div", null, dayjs(created_time).format('LLL')),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("span", { className: 'font-bold' }, "Source:\u00A0 "),
                        React.createElement("span", null, order.source)),
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'flex flex-row' },
                            React.createElement("div", { className: "font-bold" }, "Sales Representative:"),
                            React.createElement("div", null,
                                React.createElement(EditableFieldWrapper, { defaultValue: {
                                        key: owner.id,
                                        label: owner.fullname,
                                    }, handleSave: async (value) => {
                                        await SalesOrderStore.update(id, {
                                            owner: value?.key,
                                        });
                                        await SalesOrderStore.getOrderById(props.sales_order_id);
                                    } },
                                    React.createElement(Users, { users: users })))),
                        React.createElement("div", null,
                            React.createElement(Button, { type: 'link', onClick: () => {
                                    SalesOrderStore.getNotifications(order.id);
                                    setShowNotifications(!showNotifications);
                                } },
                                "Send/View Messages to: ",
                                owner.email,
                                " ",
                                React.createElement(MessageOutlined, null))),
                        isDesignEngineer && (React.createElement("div", null,
                            React.createElement("div", { className: "font-bold" }, "Account Manager:"),
                            React.createElement(AccountManager, { account_manager: account_manager, sales_order_id: props.sales_order_id }))),
                        React.createElement("div", { className: "mt-5" },
                            React.createElement(Col, { ...newCols },
                                React.createElement(Form.Item, { label: 'Is the pricing correct?' }, order.pricing_correct ? (React.createElement("b", null, "YES")) : (React.createElement(React.Fragment, null,
                                    React.createElement("b", null, "NO"),
                                    " - ",
                                    React.createElement("span", null, order.reason_pricing_correct))))),
                            React.createElement(Col, { ...newCols },
                                React.createElement(Form.Item, { label: 'If applicable, does the due date match?' }, order.due_date_match ? (React.createElement("b", null, "YES")) : (React.createElement(React.Fragment, null,
                                    React.createElement("b", null, "NO"),
                                    " - ",
                                    React.createElement("span", null, order.reason_due_date_match))))),
                            React.createElement(Col, { ...newCols },
                                React.createElement(Form.Item, { label: 'Is the customer requiring samples to verify our quality?' }, order.samples_our_quality ? (React.createElement("b", null, "YES")) : (React.createElement(React.Fragment, null,
                                    React.createElement("b", null, "NO"))))),
                            React.createElement(Col, { ...newCols },
                                React.createElement(Form.Item, { label: 'Is the customer requiring samples to verify their design?' }, order.samples_design_customer ? (React.createElement("b", null, "YES")) : (React.createElement(React.Fragment, null,
                                    React.createElement("b", null, "NO")))))))),
                React.createElement(Col, { md: 8 },
                    React.createElement("div", { style: {
                            display: 'flex',
                        } },
                        React.createElement(Col, { md: 12 },
                            React.createElement(Form.Item, { label: 'Shipping Address' }, getFieldDecorator('shipping_address', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Shipping Address is required',
                                        validator: is_valid_address,
                                    },
                                ],
                                initialValue: {
                                    name: shipping_name,
                                    street: shipping_street,
                                    city: shipping_city,
                                    state: shipping_state,
                                    country: {
                                        code: shipping_country,
                                        alpha3: shipping_country,
                                        name: shipping_country,
                                    },
                                    zip_code: shipping_zip_code,
                                },
                            })(React.createElement(Addresses, { addresses: addresses, onAddNewAddress: () => setShowAddNewAddress(true), onChange: (address) => {
                                    const payload = {
                                        shipping_name: address.name,
                                        shipping_street: address.street,
                                        shipping_city: address.city,
                                        shipping_state: address.state,
                                        shipping_zip_code: address.zip_code,
                                        shipping_country: address.country?.code,
                                    };
                                    SalesOrderStore.update(order.id, payload);
                                } })))),
                        React.createElement(Col, { md: 12 },
                            React.createElement(Form.Item, { label: 'Billing Address' }, getFieldDecorator('billing_address', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Billing Address is required',
                                        validator: is_valid_address,
                                    },
                                ],
                                initialValue: {
                                    name: billing_name,
                                    street: billing_street,
                                    city: billing_city,
                                    state: billing_state,
                                    country: {
                                        code: billing_country,
                                        alpha3: billing_country,
                                        name: billing_country,
                                    },
                                    zip_code: billing_zip_code,
                                },
                            })(React.createElement(Addresses, { addresses: addresses, onAddNewAddress: () => setShowAddNewAddress(true), onChange: (address) => {
                                    const payload = {
                                        billing_name: address.name,
                                        billing_street: address.street,
                                        billing_city: address.city,
                                        billing_state: address.state,
                                        billing_zip_code: address.zip_code,
                                        billing_country: address.country?.code,
                                    };
                                    SalesOrderStore.update(order.id, payload);
                                } }))))),
                    contact?.id && (React.createElement(Modal, { visible: showAddNewAddress, onCancel: () => {
                            setShowAddNewAddress(false);
                        }, footer: [] },
                        React.createElement(AddressForm, { handleSave: payload => {
                                return SalesOrderStore.saveAddress(contact.id, payload).then(() => {
                                    SalesOrderStore.getContactById(contact.id);
                                });
                            }, handleClose: () => {
                                setShowAddNewAddress(false);
                            } })))),
                React.createElement(Col, { md: 8 },
                    React.createElement(Title, { level: 4 }, "Details"),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "font-bold" }, "Design Engineer:"),
                        React.createElement("div", null,
                            React.createElement(EditableFieldWrapper, { defaultValue: {
                                    key: design_engineer?.id,
                                    label: design_engineer?.fullname,
                                }, handleSave: async (value) => {
                                    await SalesOrderStore.update(id, {
                                        design_engineer: value?.key,
                                    });
                                    await SalesOrderStore.getOrderById(props.sales_order_id);
                                } },
                                React.createElement(Users, { users: users, group: 'Design Engineer' })))),
                    React.createElement(DeletionAlert, { accountDeleted: !order?.account || order?.account?.deleted, contactDeleted: !order?.contact || order?.contact?.deleted }),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "font-bold" }, " Account / Contact:"),
                        React.createElement("div", { className: 'link', onClick: () => showEditContactForm(true) },
                            account && !account.deleted && account.name,
                            " /",
                            ' ',
                            contact && !contact.deleted && contact.fullname)),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "font-bold" }, " Deal:"),
                        React.createElement("div", { className: 'ml-4', style: {
                                minWidth: 200,
                            } },
                            React.createElement(EditableFieldWrapper, { defaultValue: {
                                    key: deal_related?.id,
                                    label: deal_related?.name ?? '',
                                }, handleSave: async (value) => {
                                    await SalesOrderStore.update(id, {
                                        deal: value?.key,
                                    });
                                    await SalesOrderStore.getOrderById(props.sales_order_id);
                                } },
                                React.createElement(Deals, null)))),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "font-bold" }, "Create 3d:"),
                        create_3d_files ? 'Yes' : 'No',
                        " ",
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "font-bold" }, "Create 2d:"),
                        create_2d_files ? 'Yes' : 'No'),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "font-bold" }, "Notes:"),
                        notes),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("span", { className: 'font-bold' }, "Purchase Order Files: "),
                        React.createElement("ul", { className: 'ml-1' }, po_files?.map(po => (React.createElement("li", { key: po.id },
                            React.createElement("a", { onClick: () => {
                                    window.open(po.file, '__blank', 'width=800,height=800,left=200,top=200');
                                } }, po.filename),
                            po.unacceptable_keywords && po.unacceptable_keywords[0] && (React.createElement(Popover, { content: po.unacceptable_keywords.map(uk => (
                                // eslint-disable-next-line react/jsx-key
                                React.createElement(Popover, { content: React.createElement("img", { src: uk.snapshot }) },
                                    React.createElement(Tag, { color: "red" }, uk.unacceptable_keyword)))) },
                                React.createElement(WarningTwoTone, { className: 'pl-2', twoToneColor: "#dc2626" })))))))),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("span", { className: 'font-bold' }, "Additional Files: "),
                        React.createElement("span", null,
                            React.createElement("ul", { className: 'ml-1' }, additional_files.map(po => (React.createElement("li", { key: po.id },
                                React.createElement("a", { onClick: () => {
                                        window.open(po.file, '__blank', 'width=800,height=800,left=200,top=200');
                                    } }, po.filename),
                                po.unacceptable_keywords && po.unacceptable_keywords[0] && (React.createElement(Popover, { content: po.unacceptable_keywords.map(uk => (
                                    // eslint-disable-next-line react/jsx-key
                                    React.createElement(Popover, { content: React.createElement("img", { src: uk.snapshot }) },
                                        React.createElement(Tag, { color: "red" }, uk.unacceptable_keyword)))) },
                                    React.createElement(WarningTwoTone, { className: 'pl-2', twoToneColor: "#dc2626" }))))))))),
                    React.createElement(Button, { type: "link", loading: downloading, onClick: downloadAllFiles }, "Download Files"),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("span", { className: 'font-bold' }, "Involved quotes: ")),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("br", null),
                        React.createElement("span", null, quotes?.map(q => {
                            return (React.createElement("li", { key: q.id, className: "cursor-pointer" },
                                React.createElement("u", { onClick: () => {
                                        window.open(quoteURL(q.id), '__blank');
                                    } }, q.name)));
                        }))),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("span", { className: 'font-bold' }, "Summary:")),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement(Button, { type: "link", onClick: () => {
                                setShippingModalVisible(true);
                            } }, "Shipping Requirements")),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement(Button, { type: "link", onClick: () => {
                                setPaymentModalVisible(true);
                            } }, "Payment Information")),
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement(Button, { onClick: () => {
                                setTaxExemptForm(true);
                            } }, "Tax-Exempt Form")))),
            React.createElement(Row, { className: 'row-order mt-5' },
                React.createElement(Col, { sm: 24 },
                    React.createElement("ul", { style: {
                            maxWidth: 600,
                            margin: 'auto',
                        } },
                        quotes?.map(q => {
                            if (!q?.zoho_id) {
                                return (React.createElement("li", { key: q.id },
                                    React.createElement(Alert, { showIcon: true, type: 'error', message: React.createElement("div", { className: 'flex flex-row justify-center items-center' },
                                            React.createElement("div", null,
                                                q.name,
                                                " is not in zoho"),
                                            React.createElement(Button, { type: 'link', loading: isSendingToZoho, className: 'ml-1', onClick: () => {
                                                    setSendingQuoteToZoho(true);
                                                    SalesOrderStore.sendQuoteToZoho(q.id)
                                                        .then(() => {
                                                        notification.success({
                                                            message: 'Quote  sent to zoho',
                                                        });
                                                        SalesOrderStore.getOrderById(props.sales_order_id);
                                                    })
                                                        .catch(() => {
                                                        notification.error({
                                                            message: 'Error sending to zoho, please contact to the devs',
                                                        });
                                                    })
                                                        .finally(() => {
                                                        setSendingQuoteToZoho(false);
                                                    });
                                                } },
                                                ' ',
                                                "Click Send To Zoho")) })));
                            }
                        }),
                        deal_related?.id && !deal_related?.zoho_id && (React.createElement(Alert, { showIcon: true, type: 'error', style: { color: 'red' }, message: "Deal Not In Zoho" }))))),
            quotes &&
                quotes.map((quote) => {
                    if (quote.fast_lane_items) {
                        return (React.createElement(Row, { className: 'text-blue-700 row-order mt-5' }, "PRODUCTS ON THIS ORDER WERE QUOTED THROUGH THE FAST LANE"));
                    }
                }),
            contact?.id && (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: 'row-order mt-5' },
                    order.type === 'Redo' || order.type === 'Fixing' ? null : (React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, { label: 'Quotes' }, getFieldDecorator('quotes', {
                            rules: [{ required: false, message: 'Please select a quote!' }],
                            ...(quotes?.length && {
                                initialValue: quotes.map(q => {
                                    return {
                                        key: q.id,
                                        label: (React.createElement(React.Fragment, null,
                                            ' ',
                                            q.name,
                                            q.notes && (React.createElement(Popover, { overlayStyle: { maxWidth: '500px' }, content: q.notes },
                                                React.createElement("a", null, " (Notes) "))),
                                            ' ')),
                                    };
                                }),
                            }),
                        })(React.createElement(Quotes, { show_in_zoho_alert: true, className: 'input-field', mode: 'multiple', onSelect: (item) => {
                                SalesOrderStore.getQuote(item.key);
                                SalesOrderStore.getQuoteLineItems(item.key);
                            }, onDeselect: (item) => {
                                SalesOrderStore.RemoveQuoteLineItemsFromSpecificQuote(item.key);
                            }, filters: {
                                is_purchasable: true,
                            }, account: account?.id }))))),
                    React.createElement(Col, { sm: 24, className: 'row-order mt-5' },
                        React.createElement("ul", null, getFieldValue('quotes')?.map(q => {
                            return (React.createElement("li", { key: q.key },
                                React.createElement(PreviewQuote, { id: q.key, name: q.label })));
                        }))),
                    order.type === 'Redo' || order.type === 'Fixing'
                        ? null
                        : quoted_line_items.length > 0 && (React.createElement(Col, { sm: 24 },
                            React.createElement("div", { style: {
                                    margin: 'auto',
                                    marginTop: 10,
                                } },
                                React.createElement(Button.Group, null,
                                    React.createElement(Button, { loading: loading_quote_items, type: "primary", onClick: () => setShowAddSpecificItems(true) },
                                        "Add specific items",
                                        ' ')))))),
                React.createElement(Divider, null,
                    React.createElement("div", { className: 'title' }, " Line Items")),
                React.createElement(Row, { className: 'row-order mt-5 text-left' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(OrderLineItems, { show_pending_files: true }))),
                React.createElement(Row, { className: 'row-order mt-5 text-left' },
                    React.createElement(Col, { md: 12, style: {
                            padding: 10,
                        } },
                        React.createElement(Form.Item, { label: 'Application Cost', help: 'Each item will be saved as CNC Part' },
                            order.add_application_cost && (React.createElement(Alert, { message: "Add Application Cost", type: "warning", showIcon: true })),
                            React.createElement(ApplicationCostForm, { onSubmit: values => {
                                    return SalesOrderStore.addRemoteOrderItemFormAdditionalRequirement({
                                        product_description: values.name,
                                        quantity: 1,
                                        unit_price: values.price,
                                        total_price: values.price,
                                    });
                                } }))),
                    React.createElement(Col, { md: 12 },
                        React.createElement(OrderTotals, { subtotal: parseFloat(subtotal), discount: parseFloat(discount), taxes_rate: parseFloat(taxes_rate), adjustment: parseFloat(adjustment), hasTaxExemptForm: contact?.tax_exempted_states && contact.tax_exempted_states.length > 0
                                ? true
                                : false, canEditTax: true, onUpdateTax: value => {
                                SalesOrderStore.update_sales_order_field('taxes_rate', value);
                            }, recalculateTax: () => SalesOrderStore.recalculateSalesOrderTax(order.id) }))),
                React.createElement(Divider, null,
                    React.createElement("div", { className: 'title' }, " Production")),
                React.createElement(Row, { className: 'row-order mt-5' },
                    React.createElement(Col, { ...cols2, style: { textAlign: 'center' } },
                        has_customer_supplied_material && (React.createElement("div", null,
                            React.createElement("span", null, "Customer Supplied Material"),
                            " ",
                            React.createElement(Tag, { color: "#87d068" }, "Yes"))),
                        has_domestic_quoting && (React.createElement("div", null,
                            React.createElement("span", null, "Domestic Quoting"),
                            " ",
                            React.createElement(Tag, { color: "#87d068" }, "Yes"))))),
                React.createElement(Row, { className: 'row-order mt-5' },
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'In house Requirements', ...formItemLayout }, getFieldDecorator('in_house_requirements', {
                            rules: [{ required: false, message: 'Please insert a value!' }],
                            initialValue: in_house_requirements,
                        })(React.createElement(Input.TextArea, { style: {
                                maxWidth: 600,
                            } })))),
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'Order Requirements', ...formItemLayout }, getFieldDecorator('additional_requirements', {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: additional_requirements?.map((name, index) => {
                                return {
                                    key: index + 100000,
                                    label: name,
                                };
                            }),
                        })(React.createElement(AdditionalRequirements, { requirements: SalesOrderStore.requirements_list }))))),
                React.createElement(Row, { className: 'row-order mt-5 text-left' },
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'Internal Notes (Production)', ...formItemLayout }, getFieldDecorator('internal_notes', {
                            rules: [{ required: false, message: 'Please insert a value!' }],
                            initialValue: internal_notes,
                        })(React.createElement(Input.TextArea, { style: {
                                maxWidth: 600,
                            } }))))),
                SalesOrderStore.order &&
                    SalesOrderStore.order?.quotes &&
                    SalesOrderStore.order?.quotes?.length > 0 &&
                    SalesOrderStore.order?.line_items?.filter(item => item?.quote_reference_id !== null)
                        .length > 0 && (React.createElement(Row, { className: 'row-order mt-5 text-left' },
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'Quote includes a Vendor RFQ', ...formItemLayout },
                            React.createElement(List, { dataSource: SalesOrderStore.order?.quotes, renderItem: item => (React.createElement(List.Item, { key: item.id },
                                    React.createElement(List.Item.Meta, { description: React.createElement("a", { href: `/quotes/${item.id}/ `, target: "_blank", rel: "noreferrer" }, item.name) }))) }))))),
                React.createElement(Row, { className: "row-order" },
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'Average Margin', ...formItemLayout }, getFieldDecorator('average_margin', {
                            initialValue: averageMargin.toLocaleString(),
                        })(React.createElement(Input, { readOnly: true, style: {
                                maxWidth: 400,
                            }, size: 'small' })))),
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'Lifetime Value', ...formItemLayout }, getFieldDecorator('lifetime_value', {
                            initialValue: lifetimeValue.toLocaleString(),
                        })(React.createElement(Input, { readOnly: true, style: {
                                maxWidth: 400,
                            }, size: 'small' }))))),
                React.createElement(Row, { className: 'row-order mt-5' },
                    React.createElement(Col, { ...cols }, !industry_use && !export_control && (React.createElement(Form.Item, { label: 'Industry', help: 'HTS code was not provided, please select one' }, getFieldDecorator('industry_use', {
                        rules: [
                            {
                                required: true,
                                message: 'Industry, Use and End Use are required',
                            },
                        ],
                    })(React.createElement(Industries, null))))),
                    React.createElement(Col, { ...cols },
                        React.createElement(Form.Item, { label: 'Owner', help: 'If empty the account owner will be used', ...formItemLayout }, getFieldDecorator('owner', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please select a user!',
                                },
                            ],
                            initialValue: owner?.id
                                ? {
                                    key: owner.id,
                                    label: owner.fullname,
                                }
                                : {},
                        })(React.createElement(Users, { users: users }))))),
                parseFloat(subtotal) >= TOTAL_PRICE_SHOW_CHECKLIST && isDesignEngineer && (React.createElement(Alert, { className: "mt-2", showIcon: true, type: SalesOrderStore.order?.order_processing_checklist ? 'success' : 'warning', description: " ", message: React.createElement("div", null,
                        React.createElement(Button, { type: "link", onClick: e => {
                                setHandleCloseDesinEngineerCheklist(true);
                                setTimeout(() => {
                                    dragElement(document.getElementById('02_div_design_engineer_checklist'));
                                    const box = document.getElementById('02_div_design_engineer_checklist');
                                    if (box) {
                                        box.style.top = (e.pageY - 400).toString() + 'px';
                                        box.style.left = (e.pageX + 100).toString() + 'px';
                                    }
                                }, 10);
                            } },
                            "Design Engineer Big Order Checklist Review",
                            ' ',
                            SalesOrderStore.order?.order_processing_checklist ? ('') : (React.createElement("span", { className: "text-red-600" }, "\u00A0\u00A0[ Pending Fill ]")))) })),
                parseFloat(subtotal) >= TOTAL_PRICE_SHOW_CHECKLIST && isPointPersonReview && (React.createElement(Alert, { showIcon: true, type: SalesOrderStore.order?.point_person_review_checklist ? 'success' : 'warning', description: " ", message: React.createElement("div", null,
                        React.createElement(Button, { type: "link", onClick: e => {
                                setHandleClosePointPersonCheklist(true);
                                setTimeout(() => {
                                    dragElement(document.getElementById('02_div_point_person_checklist'));
                                    const box = document.getElementById('02_div_point_person_checklist');
                                    if (box) {
                                        box.style.top = (e.pageY - 400).toString() + 'px';
                                        box.style.left = (e.pageX + 100).toString() + 'px';
                                    }
                                }, 10);
                            } },
                            "Point Person Big Order Checklist Review",
                            ' ',
                            SalesOrderStore.order?.point_person_review_checklist ? ('') : (React.createElement("span", { className: "text-red-600" }, "\u00A0\u00A0[ Pending Fill ]")))) })),
                SalesOrderStore.order?.status === 'Ready for Production' && SalesOrderStore.isBigOrder() && (React.createElement(Alert, { className: "mt-2", showIcon: true, type: "success", description: " ", message: React.createElement("div", null,
                        React.createElement(Button, { type: "link", onClick: e => {
                                history.push('checklists-view');
                            } }, "View All Big Orders CheckList")) })),
                SalesOrderStore.order?.create_3d_files && (React.createElement(Row, { className: 'row-order-status mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Button, { onClick: () => setUpload3DFilesModal(true) }, "3d files needed")))),
                React.createElement(Row, { className: 'row-order-status mt-5' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(SalesOrderStatus, { loading: loading, sales_order: order, user: {
                                id: props.current_user.id,
                                email: props.current_user.email,
                            }, handleSubmit: handleSubmit })))))),
        handleCloseDesinEngineerCheklist && (React.createElement(DesignEngineerChecklist
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        , { 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handleClose: handleCloseOrderProcessingCheklist, sales_order_id: props.sales_order_id, checklistName: 'Design Engineer Checklist', title: 'Design Engineer Big Order Checklist Review' })),
        handleCloseProductionCheklist && (React.createElement(ProductionChecklist
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        , { 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handleClose: handleCloseOrderProductionCheklist, sales_order_id: props.sales_order_id, checklistName: 'Production Review Checklist', title: 'Production Big Order Checklist Review', isPointPersonReview: isPointPersonReview })),
        handleClosePointPersonCheklist && (React.createElement(PointPersonChecklist
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        , { 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handleClose: handleCloseOrderPointPersonCheklist, sales_order_id: props.sales_order_id, checklistName: 'Point Person Review Checklist', title: 'Point Person Big Order Checklist Review' })),
        React.createElement(Row, { style: { textAlign: 'center', padding: 5 } },
            React.createElement(Col, { style: { margin: 'auto' } }, order.status === 'Ready for Production' && (React.createElement(React.Fragment, null,
                React.createElement(Button, { type: "primary", icon: React.createElement(LegacyIcon, { type: 'file-pdf' }), className: "mx-2", onClick: () => setSendSalesOrder(true) }, "Send Sales Order to Rep"),
                React.createElement(Button, { icon: React.createElement(EditOutlined, null), onClick: () => {
                        history.push(`/orders/redaction-tool/${order.id}`);
                    } }, "Open in Redaction Tool"))))),
        React.createElement(Drawer, { closable: true, title: "Add Line Items", placement: "left", onClose: () => setShowAddSpecificItems(false), visible: showAddSpecificItems, width: 800 },
            React.createElement(Tabs, { defaultActiveKey: "1" },
                React.createElement(TabPane, { tab: `Parts (${SalesOrderStore.quoted_line_items.length})`, key: "1" },
                    React.createElement(QuotedLineItems, { handleAddItem: async (item) => {
                            // Check if the item comes from a QuoteItemQuantity
                            const item_id = item.quote_has_product.has_dynamic_quantities
                                ? item.quote_has_product._parent_id
                                : item.quote_has_product.id;
                            await SalesOrderStore.addRemoteOrderItem({
                                quote_has_product: item_id,
                                name: item.quote_has_product.name,
                                quantity: item.quantity,
                                unit_price: item.unit_price,
                                total_price: item.total_price,
                            });
                        } })),
                React.createElement(TabPane, { tab: `Additional Requirements (${SalesOrderStore.applicationCostItems.length})`, key: "2" },
                    React.createElement(ApplicationCostItems, { handleAdd: async (item) => {
                            await SalesOrderStore.addRemoteOrderItem({
                                name: 'CNC Part',
                                product_description: item.name,
                                quantity: 1,
                                unit_price: parseFloat(item.price),
                                total_price: parseFloat(item.price),
                            });
                        } })))),
        React.createElement(Modal, { title: 'This order needs 3D files approval', width: 1000, visible: upload3DFilesModal, closable: false, footer: React.createElement(Button, { type: 'primary', onClick: () => setUpload3DFilesModal(false) }, "Ok") },
            React.createElement(Table3DFilesApproval, null)),
        React.createElement(Modal, { visible: show_edit_form, closable: false, footer: React.createElement(Button, { type: 'primary', onClick: () => showEditContactForm(false) }, "Ok") },
            React.createElement("div", { className: 'p-4' },
                React.createElement(Contacts, { onChange: (contact) => {
                        if (contact?.key) {
                            SalesOrderStore.update(order.id, { contact: contact.key });
                        }
                    } }))),
        React.createElement(Modal, { title: 'Shipping requirements', visible: shippingModalVisible, closable: false, footer: React.createElement(Button, { type: 'primary', onClick: () => setShippingModalVisible(false) }, "Ok") },
            React.createElement("div", { className: '' },
                React.createElement(Row, null,
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Hot Ship Date', ...formItemLayout }, getFieldDecorator('hot_ship_date', {
                            rules: [{ required: false, message: 'Please select a value!' }],
                            initialValue: hot_ship_date ? moment(hot_ship_date) : null,
                        })(React.createElement(DatePicker, { size: 'small' })))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Hot Order?', ...formItemLayout }, getFieldDecorator('hot_order', {
                            valuePropName: 'checked',
                            initialValue: hot_order,
                        })(React.createElement(Checkbox, null)))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Hot Order Notes', ...formItemLayout }, getFieldDecorator('hot_order_notes', {
                            initialValue: hot_order_notes,
                        })(React.createElement(Input.TextArea, { style: {
                                maxWidth: 600,
                            } })))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Shipping  Prepaid', ...formItemLayout, ...formItemLayout }, getFieldDecorator('shipping_prepaid', {
                            rules: [{ required: false, message: 'Please insert a value!' }],
                            valuePropName: 'checked',
                            initialValue: shipping_prepaid,
                        })(React.createElement(Checkbox, null)))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Carrier', ...formItemLayout, ...formItemLayout }, getFieldDecorator('carrier', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: carrier,
                        })(React.createElement(ShippingCarriers, null)))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Shipping Method', ...formItemLayout, ...formItemLayout }, getFieldDecorator('shipping_method', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: shipping_method,
                        })(React.createElement(ShippingMethods, null)))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Shipping Requirements', ...formItemLayout, ...formItemLayout }, getFieldDecorator('shipping_requirements', {
                            initialValue: shipping_requirements !== null ? shipping_requirements : [],
                        })(React.createElement(ShippingRequirements, null)))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Shipping Account', ...formItemLayout }, getFieldDecorator('shipping_account_number', {
                            rules: [{ required: false, message: 'Please insert a value!' }],
                            initialValue: shipping_account_number,
                        })(React.createElement(Input, { style: {
                                maxWidth: 400,
                            }, size: 'small' })))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Shipping Options', ...formItemLayout }, getFieldDecorator('shipping_options', {
                            rules: [{ required: false, message: 'Please insert a value!' }],
                            initialValue: shipping_options,
                        })(React.createElement(ShippingOptions, null))))))),
        React.createElement(Modal, { title: 'Payment information', visible: paymentModalVisible, closable: false, footer: React.createElement(Button, { type: 'primary', onClick: () => setPaymentModalVisible(false) }, "Ok") },
            React.createElement("div", { className: 'p-4' },
                React.createElement(Row, null,
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Purchase Order', ...formItemLayout }, getFieldDecorator('purchase_order', {
                            rules: [{ required: false, message: 'Please insert a value!' }],
                            initialValue: purchase_order,
                        })(React.createElement(Input, { size: "small", style: {
                                maxWidth: 400,
                            } })))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Paid In Full', ...formItemLayout }, getFieldDecorator('paid_in_full', {
                            rules: [{ required: false, message: 'Please select a value!' }],
                            valuePropName: 'checked',
                            initialValue: paid_in_full,
                        })(React.createElement(Checkbox, null)))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Payment Date', ...formItemLayout }, getFieldDecorator('payment_date', {
                            rules: [{ required: false, message: 'Please select a value!' }],
                            initialValue: payment_date ? moment(payment_date) : null,
                        })(React.createElement(DatePicker, { size: 'small' })))),
                    React.createElement(Col, { ...newCols },
                        React.createElement(Form.Item, { label: 'Payment Terms', ...formItemLayout }, getFieldDecorator('payment_terms', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: payment_terms,
                        })(React.createElement(Select, { showSearch: true, size: "small", style: {
                                maxWidth: 400,
                            } },
                            React.createElement(Select.Option, { value: "PrePaid" }, "PrePaid"),
                            React.createElement(Select.Option, { value: "NET10" }, "NET10"),
                            React.createElement(Select.Option, { value: "NET15" }, "NET15"),
                            React.createElement(Select.Option, { value: "NET30" }, "NET30"),
                            React.createElement(Select.Option, { value: "NET45" }, "NET45"),
                            React.createElement(Select.Option, { value: "NET60" }, "NET60"),
                            React.createElement(Select.Option, { value: "NET75" }, "NET75"),
                            React.createElement(Select.Option, { value: "NET90" }, "NET90"),
                            React.createElement(Select.Option, { value: "NETPARTIAL" }, "NETPARTIAL")))))))),
        sendSalesOrder && (React.createElement(Modal, { visible: true, centered: true, onCancel: () => setSendSalesOrder(false), footer: [
                React.createElement(Button, { key: "1", className: "mx-2", loading: sending, onClick: () => {
                        setSending(true);
                        SalesOrderStore.sendToSalesRepresentative(props.sales_order_id, {
                            notes: salesOrderNotes,
                        }).then(() => {
                            notification.success({
                                message: 'Sales order was sent to the sales representative',
                            });
                            setSendSalesOrder(false);
                            setSending(false);
                        });
                    } }, "Submit"),
            ] },
            React.createElement(RichTextEditor, { initialValue: `<p>
                            Hi ${owner?.fullname}, Review is Completed
                        </p>`, onChange: (html, text) => setSalesOrderNotes(html) }))),
        React.createElement(Modal, { title: 'Tax-Exempt Form', closable: true, visible: taxExemptForm, onCancel: () => {
                setTaxExemptForm(false);
            }, footer: null, width: 800 },
            React.createElement(React.Fragment, null, addNewTaxExemptForm || taxForm?.id ? (React.createElement("div", { style: { marginTop: 10 } },
                React.createElement(TaxExemptForm, { tax_form: taxForm, account: account?.id, callback: () => {
                        setAddNewTaxExemptForm(false);
                        setTaxForm({ id: null });
                    } }))) : (React.createElement("div", { style: { marginTop: 10 } },
                React.createElement(TaxExemptList, { account_id: account?.id, handleEdit: editAddress }),
                React.createElement("div", { className: 'flex flex-row-reverse pt-4' },
                    React.createElement(Button, { type: 'default', onClick: () => setAddNewTaxExemptForm(true) },
                        "Add New",
                        ' ')))))),
        React.createElement(Drawer, { title: "Notifications", placement: "left", closable: true, width: 1200, height: 500, onClose: () => setShowNotifications(!showNotifications), visible: showNotifications },
            React.createElement(SalesOrderNotifications, { height: 600, sales_order_id: props.sales_order_id }))));
});
export default Form.create()(SalesOrderEditForm);
