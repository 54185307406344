import React from 'react';
const AddressItem = React.forwardRef((props, ref) => {
    const { name, street, city, state, country, zip_code } = props.address;
    return (React.createElement("div", { style: { lineHeight: 'initial' } },
        React.createElement("div", null,
            name,
            " "),
        React.createElement("div", null,
            street,
            " "),
        React.createElement("div", null,
            city,
            ", ",
            state,
            " ",
            zip_code),
        React.createElement("div", null, country?.code)));
});
AddressItem.displayName = 'AddressItem';
export default AddressItem;
