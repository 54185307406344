var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable, runInAction } from 'mobx';
import { get, patch, post } from '@partsbadger/utils';
class ContactStore {
    constructor() {
        this.loading = false;
        this.addresses = [];
        this.requirements_list = [];
    }
    setLoading(loading) {
        runInAction(() => {
            this.loading = loading;
        });
    }
    async getContactById(contact_id) {
        await get(`/customer/contacts/${contact_id}/`).then(data => {
            runInAction(() => {
                this.contact = data;
                this.default_shipping_address = data.default_addresses.shipping_address;
                this.default_billing_address = data.default_addresses.billing_address;
                this.addresses = data.addresses;
            });
        });
    }
    saveAddress(contact_id, payload = {}) {
        return post(`/customer/contacts/${contact_id}/addresses/`, payload);
    }
    patchAddress(contact_id, address_id, payload = {}) {
        return patch(`/customer/contacts/${contact_id}/addresses/${address_id}/`, payload);
    }
    get billingAddresses() {
        return this.addresses.filter(address => address.type === 'Billing' || address.type === 'Billing and Shipping');
    }
    get shippingAddresses() {
        return this.addresses.filter(address => address.type === 'Shipping' || address.type === 'Billing and Shipping');
    }
}
__decorate([
    observable
], ContactStore.prototype, "loading", void 0);
__decorate([
    observable
], ContactStore.prototype, "contact", void 0);
__decorate([
    observable
], ContactStore.prototype, "addresses", void 0);
__decorate([
    observable
], ContactStore.prototype, "requirements_list", void 0);
__decorate([
    observable
], ContactStore.prototype, "default_shipping_address", void 0);
__decorate([
    observable
], ContactStore.prototype, "default_billing_address", void 0);
__decorate([
    observable
], ContactStore.prototype, "default_requirements", void 0);
__decorate([
    action
], ContactStore.prototype, "setLoading", null);
__decorate([
    action
], ContactStore.prototype, "getContactById", null);
__decorate([
    action
], ContactStore.prototype, "saveAddress", null);
__decorate([
    action
], ContactStore.prototype, "patchAddress", null);
__decorate([
    computed
], ContactStore.prototype, "billingAddresses", null);
__decorate([
    computed
], ContactStore.prototype, "shippingAddresses", null);
export default new ContactStore();
