import React, { useMemo, useState } from 'react';
import { message, Modal, notification, Spin, Steps } from 'antd';
import { QuoteStore } from '../Stores';
import { ModalNotesForm, VendorRFQSubmissionForm } from '../Forms';
import { observer } from 'mobx-react';
import { ErrorRender } from '@partsbadger/library';
import { WarningOutlined } from '@ant-design/icons';
import { requirements_for_app_costs } from 'quotes-frontend/src/shared';
const QuoteStatus = ({ quote, user, isQuoteLoading }) => {
    const [showVendorNotes, setShowVendorNotes] = useState(false);
    const [showReviewNotes, setShowReviewNotes] = useState(false);
    const [showReviewedNotes, setShowReviewedNotes] = useState(false);
    const [showCompletedNotes, setShowCompletedNotes] = useState(false);
    const [showContactFirstQuote, setShowContactFirstQuote] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showAppCostModal, setShowAppCostModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let needAppCosts = useMemo(() => {
        if (quote.custom_requirements) {
            return quote.custom_requirements.filter(x => requirements_for_app_costs.includes(x.label));
        }
        return [];
    }, [quote.custom_requirements]);
    const getMasterProductPosition = (id) => {
        return QuoteStore.masterProductsByPosition.find((obj) => obj.master_product.id === id);
    };
    const changeStage = async (stage, app_costs_required) => {
        const payload = {
            stage: stage,
        };
        // Verify if one or more quote products have the material in custom and is quotable.
        const hasCustomMaterial = QuoteStore.lineItems.some((item) => {
            const masterProductPosition = getMasterProductPosition(item.product.master_product);
            if (masterProductPosition && item.product.material && masterProductPosition.master_product.quotable) {
                return item.product.material.name.toLowerCase() === 'custom';
            }
        });
        // Verify if one or more quote products have the finish in custom.
        const hasCustomFinish = QuoteStore.lineItems.some((item) => {
            const masterProductPosition = getMasterProductPosition(item.product.master_product);
            if (masterProductPosition && item.product.finish && masterProductPosition.master_product.quotable) {
                return item.product.finish.name.toLowerCase() === '* custom';
            }
        });
        // Verify if one or more quote products have the coating in custom.
        const hasCustomCoating = QuoteStore.lineItems.some((item) => {
            const masterProductPosition = getMasterProductPosition(item.product.master_product);
            if (masterProductPosition && item.product.coating && masterProductPosition.master_product.quotable) {
                return item.product.coating.name.toLowerCase() === '* custom';
            }
        });
        if (app_costs_required) {
            payload['app_costs_required'] = app_costs_required;
        }
        if (stage === 'Completed' && !quote.design_engineer?.id) {
            // eslint-disable-next-line no-alert
            const res = window.confirm(`Set ${user.email} as the Design Engineer?`);
            if (res) {
                payload['design_engineer'] = user.id;
                await QuoteStore.updateQuote(quote.id, payload);
            }
            else {
                message.warning('Please set the design Engineer before complete the quote', 8);
            }
        }
        else if (stage === 'Completed') {
            if (needAppCosts &&
                quote.additional_requirements &&
                needAppCosts.length > quote.additional_requirements.length) {
                setShowAppCostModal(true);
                notification.open({
                    duration: 8,
                    message: 'Application Cost is required',
                    description: (React.createElement(React.Fragment, null,
                        React.createElement("p", null, "Application Cost is required for this quote, please add the application cost before complete the quote"),
                        React.createElement("p", null,
                            "Application Cost for:",
                            ' ',
                            React.createElement("b", null, needAppCosts.map(a => (React.createElement("span", { key: a.key },
                                a.label,
                                ", \u00A0"))))))),
                    icon: React.createElement(WarningOutlined, { style: { color: '#ff0000' } }),
                });
            }
            else if (hasCustomMaterial || hasCustomFinish || hasCustomCoating) {
                notification.open({
                    placement: 'bottomRight',
                    duration: 10,
                    message: 'Material, Finish or Coating have custom value',
                    description: (React.createElement(React.Fragment, null,
                        React.createElement("p", null, "Material, Finish or Coating of one or more products have custom value, please add the correct values before completing the quote."))),
                    icon: React.createElement(WarningOutlined, { style: { color: '#ff0000' } }),
                });
            }
            else {
                await QuoteStore.updateQuote(quote.id, payload);
            }
        }
        else {
            await QuoteStore.updateQuote(quote.id, payload);
            if (QuoteStore.error) {
                notification.error({
                    message: 'Error',
                    description: React.createElement(ErrorRender, { error: QuoteStore.error }),
                });
            }
        }
    };
    const getStage = () => {
        const stage = quote.stage;
        const stages = [
            'In Queue',
            'In Progress',
            'Waiting Customer',
            'Vendor Quoting',
            'Needs Review',
            'Reviewed',
            'Completed',
            'Cancelled',
            'Closed Won',
            'Closed Lost',
        ];
        return stages.indexOf(stage);
    };
    const modal_review_notes = (React.createElement(ModalNotesForm, { modalTitle: "Review Notes", buttonLabel: "Mark as Needs Review", inputPlaceHolder: "What we should review?", defaultValue: quote.review_notes, handleSave: async (notes) => {
            const payload = {
                review_notes: notes,
                stage: 'Needs Review',
            };
            setIsLoading(true);
            await QuoteStore.updateQuote(quote.id, payload);
            setShowReviewNotes(false);
        }, handleCancel: () => setShowReviewNotes(false) }));
    const modal_reviewed_notes = (React.createElement(ModalNotesForm, { modalTitle: "Reviewed Notes", buttonLabel: "Mark as Reviewed", inputPlaceHolder: ":Optional", defaultValue: quote.reviewed_notes, handleSave: async (notes) => {
            const payload = {
                reviewed_notes: notes,
                stage: 'Reviewed',
            };
            setIsLoading(true);
            await QuoteStore.updateQuote(quote.id, payload);
            setShowReviewedNotes(false);
            setIsLoading(false);
        }, handleCancel: () => setShowReviewedNotes(false) }));
    const modal_sales_rep_notes = (React.createElement(ModalNotesForm, { modalTitle: `Additional Notes for ${quote?.owner?.fullname} `, buttonLabel: "Mark as Completed", inputPlaceHolder: "Notes: Optional?", defaultValue: quote.reviewed_notes, handleSave: async (notes) => {
            const payload = {
                sales_rep_notes: notes,
                stage: 'Completed',
            };
            setIsLoading(true);
            await QuoteStore.updateQuote(quote.id, payload);
            setShowCompletedNotes(false);
            setIsLoading(false);
        }, handleCancel: () => setShowCompletedNotes(false) }));
    const modal_vendor_notes = (React.createElement(Modal, { width: 1200, visible: showVendorNotes, centered: true, title: "Vendor RFQ Submission Form", onCancel: () => setShowVendorNotes(false), footer: [] },
        React.createElement(VendorRFQSubmissionForm, { loading: isLoading, quote_id: quote.id, selected_items: selectedItems, onSubmitForm: (rfqType, notes, selectedItems) => {
                setIsLoading(true);
                if (selectedItems.length > 0) {
                    QuoteStore.sendToPartners(quote.id, {
                        notes_for_vendors: notes,
                        stage: 'Vendor Quoting',
                        vendor_rfq_type: rfqType,
                        selected_items: selectedItems,
                    }).finally(async () => {
                        await QuoteStore.getLineItems(quote.id);
                        setShowVendorNotes(false);
                        setIsLoading(false);
                    });
                }
                else {
                    setIsLoading(false);
                    notification.error({
                        message: 'Error',
                        description: 'Select the items to be sent to the vendor.',
                    });
                }
            } })));
    const modal_contact_first_quote = (React.createElement(Modal, { width: 600, visible: showContactFirstQuote, centered: true, title: "\uD83C\uDFC6 Contact's First Quote \uD83C\uDFC6", okText: 'Yes', onOk: () => {
            if (QuoteStore.quote?.id) {
                QuoteStore.updateQuote(QuoteStore.quote?.id, {
                    whole_quote_adjust: 90,
                })
                    .then(() => {
                    const payload = {
                        quoted_products: QuoteStore.lineItems.map(p => p.id),
                    };
                    QuoteStore.recalculatePrices(payload).catch(err => {
                        notification.error({
                            key: 'form-errors',
                            duration: 8,
                            message: 'One of the line items has an error',
                            description: React.createElement(ErrorRender, { error: err }),
                            placement: 'top',
                            maxCount: 1,
                        });
                    });
                    changeStage('Completed');
                })
                    .finally(() => setShowContactFirstQuote(false));
            }
            // QuoteStore.getQuote(QuoteStore.quote.id);
        }, cancelText: 'No', onCancel: () => {
            changeStage('Completed');
            setShowContactFirstQuote(false);
        } },
        "\uD83C\uDFC6\uD83C\uDFC6\uD83C\uDFC6 Wow, this quote is the first from this Contact \uD83C\uDFC6\uD83C\uDFC6\uD83C\uDFC6",
        React.createElement("br", null),
        "Would you like to discount by 10% before completing?"));
    const modal_app_costs_required = (React.createElement(Modal, { title: React.createElement("strong", null, "Application Cost is required"), width: 600, visible: showAppCostModal, onCancel: () => setShowAppCostModal(false), closable: true, okText: 'Application costs are not required', onOk: () => {
            needAppCosts = [];
            changeStage('Completed', true);
            setShowAppCostModal(false);
        } },
        React.createElement("p", null,
            "Application Cost is required for this quote, please select ",
            React.createElement("strong", null, "Cancel"),
            " to add the costs or confirm that costs are not required in order to complete the quote"),
        React.createElement("p", null,
            "Application Cost for:",
            ' ',
            React.createElement("b", null, needAppCosts.map(a => (React.createElement("span", { key: a.key },
                a.label,
                ", \u00A0")))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { tip: "Updating...", spinning: isQuoteLoading },
            React.createElement(Steps, { current: getStage() },
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "In Queue", description: "", onClick: () => {
                        changeStage('In Queue');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "In Progress", description: "", onClick: () => {
                        changeStage('In Progress');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Waiting Customer", description: "", onClick: () => {
                        changeStage('Waiting Customer');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Vendor Quoting", description: "", onClick: () => {
                        const mp_ids_selected = [];
                        QuoteStore.lineItems.map((line_item) => {
                            if (line_item &&
                                line_item.send_to_vendor &&
                                line_item.product &&
                                line_item.product.master_product) {
                                mp_ids_selected.push(Number(line_item.product.master_product));
                            }
                        });
                        if (mp_ids_selected.length > 0) {
                            setSelectedItems(mp_ids_selected);
                        }
                        else {
                            setSelectedItems(QuoteStore.lineItems
                                .filter((line_item) => line_item.product && line_item.product.master_product)
                                .map(line_item => line_item.product.master_product));
                        }
                        QuoteStore.quote?.design_engineer
                            ? setShowVendorNotes(true)
                            : notification.error({
                                message: 'Please assign a design engineer to the Quote',
                                placement: 'topRight',
                            });
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Needs Review", description: React.createElement("span", null, quote && quote.review_notes), onClick: () => {
                        setShowReviewNotes(true);
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Reviewed", description: React.createElement("span", null, quote && quote.reviewed_notes), onClick: () => {
                        setShowReviewedNotes(true);
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Completed", description: "", onClick: () => {
                        if (!QuoteStore.quote?.grand_total) {
                            Modal.confirm({
                                title: 'Warning! The total of your quote is 0',
                                centered: true,
                                okText: 'Continue',
                                icon: React.createElement(WarningOutlined, null),
                                cancelButtonProps: {
                                    danger: true,
                                },
                                content: React.createElement("div", null, "Do you want to complete a quote with amount 0?"),
                                onOk: () => {
                                    if (QuoteStore.quote?.stage !== 'Completed') {
                                        if (QuoteStore.quoteAccountOverview?.is_first_quote_for_contact) {
                                            setShowContactFirstQuote(true);
                                        }
                                        else {
                                            changeStage('Completed');
                                        }
                                    }
                                },
                            });
                        }
                        else {
                            if (QuoteStore.quote?.stage !== 'Completed') {
                                if (QuoteStore.quoteAccountOverview?.is_first_quote_for_contact) {
                                    setShowContactFirstQuote(true);
                                }
                                else {
                                    changeStage('Completed');
                                }
                            }
                        }
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Cancelled", description: "", onClick: () => {
                        changeStage('Cancelled');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Closed Won", description: "", onClick: () => {
                        changeStage('Closed Won');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Closed Lost", description: "", onClick: () => {
                        changeStage('Closed Lost');
                    } }))),
        showReviewNotes && React.createElement("div", null, modal_review_notes),
        showReviewedNotes && React.createElement("div", null, modal_reviewed_notes),
        showCompletedNotes && React.createElement("div", null, modal_sales_rep_notes),
        showContactFirstQuote && React.createElement("div", null, modal_contact_first_quote),
        showVendorNotes && React.createElement("div", null, modal_vendor_notes),
        showAppCostModal && React.createElement("div", null, modal_app_costs_required)));
};
export default observer(QuoteStatus);
