import { action, computed, observable, makeObservable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
class PurchaseOrderStore {
    constructor() {
        this.purchase_orders = [];
        this.purchase_order = null;
        this.line_items = [];
        this.users = [];
        this.temp_line_items = [];
        this.sales_order = null;
        this.input_amount = 0;
        this.input_quantity = 0;
        this.input_list_price = 0;
        this.input_discount = 0;
        this.input_tax = 0;
        this.input_total = 0;
        this.sale_order_by_item = 0;
        this.products_by_item = [];
        this.combined_sales_orders_and_line_item = [];
        makeObservable(this, {
            // Observables
            purchase_orders: observable,
            purchase_order: observable,
            line_items: observable,
            users: observable,
            temp_line_items: observable,
            sales_order: observable,
            input_amount: observable,
            input_quantity: observable,
            input_list_price: observable,
            input_discount: observable,
            input_tax: observable,
            input_total: observable,
            sale_order_by_item: observable,
            products_by_item: observable,
            combined_sales_orders_and_line_item: observable,
            // Actions
            setPurchaseOrder: action,
            get: action,
            list: action,
            create: action,
            patch: action,
            getUsers: action,
            remove: action,
            sync_line_items: action,
            remove_line_item: action,
            update_line_item: action,
            recalculate_totals: action,
            calculate_input_amount: action,
            calculate_input_total: action,
            update_input_quantity: action,
            update_input_list_price: action,
            update_input_discount: action,
            update_input_tax: action,
            update_amount_total: action,
            update_input_total: action,
            update_po_field: action,
            update_purchase_order: action,
            approve_purchase_order: action,
            add_item: action,
            send_to_vendor: action,
            get_sales_order: action,
            removeLineItems: action,
            removeSalesOrder: action,
            set_zx_line_items: action,
            getSalesOrderItems: action,
            add_sales_order_line_item: action,
            remove_sales_order_line_item: action,
            add_sales_order_and_line_item: action,
            hasUniqueSalesOrderId: action,
            remove_sales_order_and_line_item: action,
            // computed
            getTotalDiscount: computed,
            is_sales_order_line_items_empty: computed,
        });
    }
    setPurchaseOrder(po) {
        this.purchase_order = po;
    }
    async get(id) {
        const response = await get(`/staff/purchase-orders/${id}/`);
        // The server some values send as string, lets convert all to number to avoid Javascript
        // string concatenations in the totals
        this.purchase_order = {
            ...response,
            shipping_cost: Number(response.shipping_cost),
            discount: Number(response.discount),
            tax: Number(response.tax),
            sub_total: Number(response.sub_total),
            taxes_rate: Number(response.taxes_rate),
            adjustment: Number(response.adjustment),
            grand_total: Number(response.grand_total),
            grand_total_with_shipping: Number(response.grand_total_with_shipping),
        };
    }
    async list(params) {
        const response = await get(`/staff/purchase-orders/`, params);
        this.purchase_orders = response.results;
    }
    async create(payload) {
        const response = await post(`/staff/purchase-orders/`, payload);
        this.purchase_orders.push(response.data);
    }
    async patch(payload, id) {
        const response = await patch(`/staff/purchase-orders/${id}/`, payload);
        this.purchase_order = response.data;
    }
    getUsers(params) {
        get(`/staff/users/`, {
            params: params,
        }).then(data => {
            this.users = data.results;
        });
    }
    async remove(id) {
        return await remove(`/staff/purchase-orders/${id}`);
    }
    sync_line_items(line_items) {
        if (line_items && line_items.length > 0) {
            this.line_items = line_items;
            this.line_items.forEach(l_item => {
                l_item.sales_order_line_items?.forEach(s_item => {
                    if (s_item?.sales_order) {
                        this.add_sales_order_and_line_item(s_item?.sales_order?.id, s_item.id, s_item?.sales_order?.name);
                    }
                });
            });
        }
        else {
            this.line_items = [];
            this.update_input_quantity(0);
            this.update_input_list_price(0);
            this.update_input_discount(0);
            this.update_input_tax(0);
            this.update_amount_total(0);
            this.update_input_total(0);
        }
    }
    add_line_item(line_item) {
        if (line_item) {
            line_item.line_number = this.line_items.length + 1;
            // if (this.purchase_order) {
            //     line_item.purchase_order = this.purchase_order.id;
            // }
            this.line_items.push(line_item);
            this.update_input_quantity(0);
            this.update_input_list_price(0);
            this.update_input_discount(0);
            this.update_input_tax(0);
            this.update_amount_total(0);
            this.update_input_total(0);
            // this.products_by_item = [];
            this.recalculate_totals();
        }
    }
    remove_line_item(index) {
        const filter_line_items = this.line_items.filter((line_item, i) => i !== index);
        filter_line_items.map((line_item, index) => {
            line_item.line_number = index + 1;
        });
        this.line_items = filter_line_items;
        this.recalculate_totals();
    }
    add_sales_order_line_item(id, value) {
        this.line_items = this.line_items.map(item => {
            if (item.id == id) {
                return { ...item, sales_order_line_items: [...item?.sales_order_line_items, value] };
            }
            return item;
        });
    }
    add_sales_order_and_line_item(soId, lineItemId, label) {
        this.combined_sales_orders_and_line_item = [
            ...this.combined_sales_orders_and_line_item,
            { soId: soId, lineItemId: lineItemId, label: label },
        ];
    }
    remove_sales_order_and_line_item(lineItemId) {
        this.combined_sales_orders_and_line_item = this.combined_sales_orders_and_line_item.filter(item => item.lineItemId !== lineItemId);
    }
    get is_sales_order_line_items_empty() {
        // returns true if at least one l_item has an empty sales_order_line_items array and at least one has a non-empty sales_order_line_items array.
        const hasSomeEmpty = this.line_items.some(item => !item.sales_order_line_items?.length);
        const hasSomeNonEmpty = this.line_items.some(item => item.sales_order_line_items?.length > 0);
        return hasSomeEmpty && hasSomeNonEmpty;
    }
    hasUniqueSalesOrderId() {
        const soIdSet = new Set(this.combined_sales_orders_and_line_item.map(obj => obj.soId));
        if (this.is_sales_order_line_items_empty == false && soIdSet.size === 1) {
            return this.combined_sales_orders_and_line_item[0];
        }
        else {
            return false;
        }
    }
    remove_sales_order_line_item(POLineItemId, salesOrderItemId) {
        this.line_items = this.line_items.map(item => {
            if (item.id == POLineItemId) {
                return {
                    ...item,
                    sales_order_line_items: item.sales_order_line_items.filter(lineItem => lineItem.id !== salesOrderItemId),
                };
            }
            return item;
        });
    }
    update_line_item(index, new_item) {
        this.line_items.map((item, i) => {
            if (index === i) {
                const amount = Number(new_item.list_price) * Number(new_item.quantity);
                const total = Number(amount - Number(new_item.discount) + Number(new_item.tax));
                item.name = new_item.name;
                item.description = new_item.description;
                item.status = new_item.status;
                item.quickbooks_account = Number(new_item.quickbooks_account);
                item.quickbooks_account_related = new_item.quickbooks_account_related;
                item.list_price = Number(new_item.list_price);
                item.quantity = Number(new_item.quantity);
                item.amount = Number(amount.toFixed(2));
                item.discount = 0;
                item.tax = new_item.tax;
                item.sales_order = new_item.sales_order;
                item.sales_order_related = new_item.sales_order_related;
                item.sales_order_text = new_item.sales_order_text;
                item.product = new_item.product;
                item.product_related = new_item.product_related;
                item.product_text = new_item.product_text;
                item.sales_order_line_items = new_item.sales_order_line_items;
                item.total = Number(total.toFixed(2));
            }
        });
        this.products_by_item = [];
        this.recalculate_totals();
    }
    get getTotalDiscount() {
        if (!this.purchase_order) {
            return 0;
        }
        if (this.purchase_order.discount_type === '%') {
            return (this.purchase_order.sub_total * this.purchase_order.discount) / 100;
        }
        return this.purchase_order.discount;
    }
    recalculate_totals() {
        if (!this.purchase_order) {
            return;
        }
        let subtotal = 0;
        this.line_items
            .filter(l => l.status !== 'Canceled')
            .map(line => {
            subtotal = Number(subtotal) + Number(line.total);
            return subtotal;
        });
        this.purchase_order.sub_total = subtotal;
        this.purchase_order.subtotal_without_taxes = this.line_items.reduce((total, item) => total + Number(item.amount), 0);
        this.purchase_order.total_taxes = this.line_items.reduce((total, item) => total + Number(item.tax), 0);
        const total_discount = this.getTotalDiscount;
        if (this.purchase_order.taxes_rate) {
            this.purchase_order.tax = ((subtotal - total_discount) * this.purchase_order.taxes_rate) / 100;
        }
        else {
            this.purchase_order.tax = 0;
        }
        this.purchase_order.sub_total = Number(subtotal);
        this.purchase_order.grand_total =
            this.purchase_order.sub_total - total_discount + this.purchase_order.tax + this.purchase_order.adjustment;
        this.purchase_order.grand_total_with_shipping =
            this.purchase_order.grand_total + this.purchase_order.shipping_cost;
    }
    calculate_input_amount() {
        this.input_amount = this.input_quantity * this.input_list_price;
        this.calculate_input_total();
    }
    calculate_input_total() {
        this.input_total = this.input_amount - this.input_discount + this.input_tax;
    }
    update_input_quantity(value) {
        this.input_quantity = value;
        this.calculate_input_amount();
    }
    update_input_list_price(value) {
        this.input_list_price = value;
        this.calculate_input_amount();
    }
    update_input_discount(value) {
        this.input_discount = value;
        this.calculate_input_total();
    }
    update_input_tax(value) {
        this.input_tax = value;
        this.calculate_input_total();
    }
    update_amount_total(value) {
        this.input_amount = value;
    }
    update_input_total(value) {
        this.input_total = value;
    }
    update_po_field(key, value) {
        if (this.purchase_order != undefined) {
            this.purchase_order = {
                ...this.purchase_order,
                [key]: value,
            };
        }
    }
    update_purchase_order(purchase_order) {
        if (purchase_order === null) {
            this.purchase_order = undefined;
        }
        else {
            this.purchase_order = purchase_order;
        }
    }
    async approve_purchase_order(purchase_order_id) {
        return await post(`/staff/purchase-orders/${purchase_order_id}/approve/`);
    }
    async add_item(payload, po_id) {
        const response = await post(`/staff/purchase-orders/${po_id}/add-item/`, payload);
        this.get(po_id);
        this.sync_line_items(response);
    }
    async send_to_vendor(purchase_order_id) {
        this.purchase_orders.map(po => {
            if (po.id === purchase_order_id) {
                return {
                    ...po,
                    send_to_vendor: true,
                };
            }
            return {
                ...po,
            };
        });
        return await post(`/staff/purchase-orders/${purchase_order_id}/sent-to-vendor/`);
    }
    async get_sales_order(id, in_item) {
        const response = await get(`/staff/sales-orders/${id}`);
        if (in_item === true) {
            this.sale_order_by_item = response.id;
            this.products_by_item = [];
            this.combined_sales_orders_and_line_item = [{ soId: response.id, lineItemId: 0, label: response.name }];
        }
        else {
            this.products_by_item = [];
            this.temp_line_items = [];
            if (id) {
                this.combined_sales_orders_and_line_item = [{ soId: response.id, lineItemId: 0, label: response.name }];
            }
            this.sales_order = undefined;
            if (response.domestic_process_needed | response.hardware_required) {
                {
                    this.sales_order = response;
                }
            }
        }
        return response;
    }
    removeLineItems() {
        this.combined_sales_orders_and_line_item = [];
        this.line_items = [];
        this.recalculate_totals();
    }
    removeSalesOrder() {
        this.sales_order = undefined;
        this.recalculate_totals();
    }
    set_zx_line_items() {
        if (!this.purchase_order) {
            return;
        }
        if (!this.sales_order) {
            return;
        }
        let notes = '';
        this.sales_order?.line_items.map((li, index) => {
            if (li.hardware_required || li.domestic_process_needed) {
                const findIndex = this.line_items.findIndex(obj => {
                    return obj.name === li.name && obj.quantity === li.quantity;
                });
                if (findIndex === -1) {
                    // save notes
                    notes += li.hardwares_required?.join();
                    notes += li.domestic_processes?.join();
                    // @ts-expect-error lacks of account
                    this.add_line_item({
                        id: index,
                        description: li.name,
                        name: li.name,
                        list_price: 0,
                        quantity: li.quantity,
                        tax: Number(li.taxes),
                        total: 0,
                        amount: 0,
                        discount: Number(li.discount),
                    });
                }
            }
        });
        if (this.purchase_order) {
            this.purchase_order.notes = notes;
        }
    }
    async getSalesOrderItems(params) {
        const response = await get(`/staff/sales-order-line-items/`, { params: params });
        this.products_by_item = response.results;
    }
}
export default new PurchaseOrderStore();
