import React, { useCallback, useEffect, useState } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Alert, Spin, Tabs } from 'antd';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SortFiles } from '@partsbadger/one-true-form/lib/components/SortFiles';
import { PartSlot } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
const { TabPane } = Tabs;
const style = {
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
    height: 'auto',
    border: '1px #000 dashed',
};
const ItemInfo = ({ index, line_item, type, isDropped }) => {
    const [collected, drag] = useDrag(() => ({
        type,
        item: { index, line_item, type },
    }), [line_item]);
    return (React.createElement("div", { className: 'draggable-item', ref: drag, style: { ...style } }, isDropped ? (React.createElement("span", null)) : (React.createElement("div", { className: "flex flex-row" },
        React.createElement(DragOutlined, { className: "ml-auto" }),
        type === '3D File' && React.createElement("div", { className: "mx-2" }, line_item.filename),
        type === '2D File' && React.createElement("div", { className: "mx-2" }, line_item.filename),
        type === 'Additional File' && React.createElement("div", { className: "mx-2" }, line_item.filename)))));
};
export const ReArrangeFiles = observer(({ quote_id }) => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setRecords(QuoteStore.master_products_with_replace_files);
    }, [QuoteStore.masterProductsByPosition]);
    const moveStepFiles = useCallback((dragIndex, hoverIndex) => {
        setLoading(true);
        const clonedRecords = Array.from(records);
        const item_drag = clonedRecords[dragIndex].step_file;
        const item_hover = clonedRecords[hoverIndex].step_file;
        const data_drag = clonedRecords[dragIndex];
        const data_hover = clonedRecords[hoverIndex];
        clonedRecords[dragIndex].step_file = item_hover;
        clonedRecords[hoverIndex].step_file = item_drag;
        clonedRecords[dragIndex].replace_3d_data = {
            shape: data_hover.shape,
            unit: data_hover.unit,
            length: data_hover.length,
            width: data_hover.width,
            height: data_hover.height,
            inner_diameter: data_hover.inner_diameter,
            volume: data_hover.volume,
            volume_3d_printing: data_hover.volume_3d_printing,
            part_volume_percentage: data_hover.part_volume_percentage,
            itar: data_hover.itar,
        };
        clonedRecords[hoverIndex].replace_3d_data = {
            shape: data_drag.shape,
            unit: data_drag.unit,
            length: data_drag.length,
            width: data_drag.width,
            height: data_drag.height,
            inner_diameter: data_drag.inner_diameter,
            volume: data_drag.volume,
            volume_3d_printing: data_drag.volume_3d_printing,
            part_volume_percentage: data_drag.part_volume_percentage,
            itar: data_drag.itar,
        };
        // const drag = clonedRecords[dragIndex];
        // const hover = clonedRecords[hoverIndex];
        // setRecords(
        //     update(records, {
        //         $splice: [
        //             [hoverIndex, 1, hover],
        //             [dragIndex, 1, drag],
        //         ],
        //     })
        // );
        QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(() => QuoteStore.getMasterProducts(quote_id))
            .finally(() => setLoading(false));
    }, [records]);
    const moveDrawingFiles = useCallback((dragIndex, hoverIndex) => {
        setLoading(true);
        const clonedRecords = Array.from(records);
        const item_drag = clonedRecords[dragIndex].drawing_file;
        const item_hover = clonedRecords[hoverIndex].drawing_file;
        clonedRecords[dragIndex].drawing_file = item_hover;
        clonedRecords[hoverIndex].drawing_file = item_drag;
        QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(() => QuoteStore.getMasterProducts(quote_id))
            .finally(() => setLoading(false));
    }, [records]);
    const moveAdditionalFiles = async (dragIndex, hoverIndex, item_moved) => {
        setLoading(true);
        if (item_moved.type == '2D File') {
            const clonedRecords = Array.from(records);
            clonedRecords[hoverIndex].other_files.push({
                id: item_moved.line_item.id,
                filename: item_moved.line_item.filename,
                type: item_moved.type,
            });
            clonedRecords[dragIndex].drawing_file = null;
            QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
            // setRecords([]);
            await QuoteStore.replaceFilesMasterProduct(quote_id)
                .then(() => {
                QuoteStore.getMasterProducts(quote_id);
            })
                .finally(() => setLoading(false));
        }
        else {
            const clonedRecords = Array.from(records);
            const index_file = clonedRecords[dragIndex].other_files
                .map((file) => {
                return file.id;
            })
                .indexOf(item_moved.line_item.id);
            clonedRecords[dragIndex].other_files.splice(index_file, 1);
            const add_hover = clonedRecords[hoverIndex].other_files.findIndex((file) => file.id === item_moved.line_item.id);
            add_hover === -1 &&
                clonedRecords[hoverIndex].other_files.push({
                    id: item_moved.line_item.id,
                    filename: item_moved.line_item.filename,
                });
            // setRecords([]);
            QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
            QuoteStore.replaceFilesMasterProduct(quote_id)
                .then(() => QuoteStore.getMasterProducts(quote_id))
                .finally(() => setLoading(false));
        }
    };
    const moveAdditionalFilesToDrawingFile = async (dragIndex, hoverIndex, item_moved) => {
        setLoading(true);
        const clonedRecords = Array.from(records);
        const index_file = clonedRecords[dragIndex].other_files
            .map((file) => {
            return file.id;
        })
            .indexOf(item_moved.line_item.id);
        clonedRecords[hoverIndex].drawing_file = {
            ...clonedRecords[dragIndex].other_files.splice(index_file, 1)[0],
            type: 'Additional File',
        };
        QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
        // setRecords([]);
        await QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(async () => {
            await QuoteStore.getMasterProducts(quote_id);
        })
            .finally(() => setLoading(false));
    };
    const parts = records.map((part_item, index) => {
        const file3d = part_item.step_file ? (React.createElement(ItemInfo, { index: index, line_item: part_item.step_file, type: '3D File' })) : null;
        const file2d = part_item.drawing_file ? (React.createElement(ItemInfo, { index: index, line_item: part_item.drawing_file, type: '2D File' })) : null;
        const fileOthers = part_item.other_files.length > 0
            ? part_item.other_files.map((item) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(ItemInfo, { index: index, line_item: item, type: 'Additional File' }),
                    React.createElement("br", null)));
            })
            : null;
        return (React.createElement(React.Fragment, null,
            React.createElement(Tabs, null,
                React.createElement(TabPane, { tab: [React.createElement("span", { key: "1" },
                            "Line item: ",
                            part_item.name)], key: "1" }, part_item.editable ? (React.createElement("div", { className: 'row-associate-parts', key: index, style: {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(217, 217, 217, 0.19)',
                        marginBottom: '5px',
                        padding: '10px',
                        position: 'relative',
                    } },
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], newRecords: records, lastDroppedItem: file3d, onDrop: item => {
                            moveStepFiles(item.index, index);
                        }, onDropNew: () => {
                            console.log('on drop new');
                        } }),
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], newRecords: records, lastDroppedItem: file2d, onDrop: item => {
                            if (item.type === 'Additional File') {
                                moveAdditionalFilesToDrawingFile(item.index, index, item);
                            }
                            else {
                                moveDrawingFiles(item.index, index);
                            }
                        }, onDropNew: () => {
                            console.log('on drop new');
                        } }),
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], newRecords: records, lastDroppedItem: fileOthers, onDrop: item => {
                            moveAdditionalFiles(item.index, index, item);
                        }, onDropNew: () => {
                            console.log('todo');
                        } }))) : (React.createElement("div", { className: 'flex justify-center' },
                    React.createElement(Alert, { style: { margin: 5, width: '350px' }, type: 'warning', className: "text-center", message: 'This master product is used in other quotes.' })))))));
    });
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Spin, { spinning: loading, tip: 'Moving file...' },
            React.createElement("div", null,
                React.createElement("div", { className: 'text-sm' }, "1. It is allowed to move files between the same type 3D, 2D and Additional."),
                React.createElement("div", { className: 'text-sm' }, "2. You can move files between 2D and Additional types. "),
                React.createElement("div", { className: 'text-sm' }, "3. You cannot move files from other types to the 3D type.")),
            React.createElement("br", null),
            React.createElement("div", { className: "flex flex-row", style: {
                    background: '#fafafa',
                } },
                React.createElement("div", { className: "flex-1 mr-auto text-center" },
                    React.createElement(SortFiles, { fileType: '3D File' })),
                React.createElement("div", { className: "flex-1 mr-auto text-center" },
                    React.createElement(SortFiles, { fileType: '2D File' })),
                React.createElement("div", { className: "flex-1 mr-auto text-center" },
                    React.createElement(SortFiles, { fileType: 'Additional File' }))),
            parts)));
});
