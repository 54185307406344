import React from 'react';
import { observer } from 'mobx-react';
import { QuoteStore } from '../Stores/index';
import { Chat } from './Chat/chat';
export const QuoteNotifications = observer(({ height }) => {
    const messages = QuoteStore.quote_notifications.map(n => {
        return {
            body: n.body,
            created_time: n.created_time,
            attachment: n.attachment,
            attachments: n.attachments.map(a => {
                return {
                    filename: a.filename,
                    file: a.file,
                };
            }),
            author: {
                fullname: n.author.fullname,
            },
            notify_owner: n.notify_owner,
            carbon_copy: n.carbon_copy,
        };
    });
    return (React.createElement("div", null,
        React.createElement(Chat, { height: height, messages: messages, onSendMessage: values => {
                QuoteStore.sendNotification({
                    body: values.body,
                    attachments: values.attachments,
                    notify_owner: values.notify_owner,
                    carbon_copy: values.carbon_copy,
                });
            } })));
});
