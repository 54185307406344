import React from 'react';
import { List, Comment, Image } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
const isImage = file => {
    const types = ['jpg', 'jpeg', 'png', 'gif'];
    const attachmentName = file.attachment_name;
    const array = attachmentName.split('.');
    if (array.length > 0) {
        const type = array[array.length - 1];
        const exits = types.filter(extension => extension === type);
        return exits.length > 0 ? true : false;
    }
    return false;
};
export default function TicketView({ arrayMessages }) {
    return (React.createElement(List, { className: "comment-list shadow-2xl bg-gray-100 px-4 border-2 mb-4 rounded-md", itemLayout: "horizontal", dataSource: arrayMessages, locale: {
            emptyText: 'No messages',
        }, renderItem: item => (React.createElement("li", null,
            React.createElement(Comment, { author: item.author.username, content: React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'mt-2' }, !!item?.body && item?.body),
                    isImage(item) && (React.createElement(Image, { width: 400, preview: {
                            src: item.attachment,
                        }, src: item.attachment })),
                    !isImage(item) && item.attachment && (React.createElement("div", { className: 'mt-2' },
                        React.createElement("a", { target: '_blank', onClick: () => {
                                window.open(item.attachment, '', 'width=800,height=800,left=200,top=200');
                            } },
                            item.attachment?.split('?')[0]?.split('/')?.pop(),
                            React.createElement(PaperClipOutlined, null))))), datetime: dayjs(item.created).format('YYYY-MM-DD HH:mm:ss') }))) }));
}
