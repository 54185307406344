import { Col, Row, InputNumber, Button, Tooltip } from 'antd';
import { Price } from '@partsbadger/library';
import React, { useState } from 'react';
import { RedoOutlined } from '@ant-design/icons';
export const OrderTotals = ({ subtotal, discount, taxes_rate, adjustment, hasTaxExemptForm, canEditTax, onUpdateTax, recalculateTax, }) => {
    const total = subtotal - discount;
    const tax_value = (total * taxes_rate) / 100;
    let grand_total = total + tax_value;
    if (adjustment) {
        grand_total = grand_total + adjustment;
    }
    const [editingTax, setEditingTax] = useState(false);
    const [loading, setLoading] = useState(false);
    const editableTax = () => {
        if (editingTax) {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: 'title' }, "Sales Tax: "),
                React.createElement(InputNumber, { onPressEnter: event => {
                        const target = event.target;
                        setEditingTax(false);
                        onUpdateTax?.(target.value);
                    }, onBlur: () => setEditingTax(false), min: 0, defaultValue: taxes_rate })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Tooltip, { title: "Recalculate Tax" },
                React.createElement(Button, { type: "dashed", shape: "circle", size: "small", icon: React.createElement(RedoOutlined, null), loading: loading, onClick: () => {
                        setLoading(true);
                        recalculateTax?.();
                        setLoading(false);
                    } })),
            React.createElement("a", { style: { color: 'inherit' }, onClick: () => setEditingTax(true) },
                React.createElement("span", { className: 'title' }, "Sales Tax "),
                React.createElement("span", { style: { color: 'red' } },
                    "(",
                    taxes_rate,
                    "%)"))));
    };
    return (React.createElement(Row, { className: "record-footer", style: {
            textAlign: 'right',
        } },
        React.createElement(Col, { sm: 24 },
            React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Subtotal: ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: subtotal }))),
            discount > 0 && (React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Discount: ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: discount })))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    canEditTax ? editableTax() : React.createElement("span", { className: 'title' },
                        "Sales Tax (",
                        taxes_rate,
                        "%)"),
                    hasTaxExemptForm && React.createElement("span", { style: { color: 'red' } }, "(Tax-Exempt Form)"),
                    ":"),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: tax_value }))),
            adjustment > 0 && (React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Adjustment: ")),
                React.createElement(Col, { md: 6 },
                    React.createElement(Price, { value: adjustment })))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 18 },
                    React.createElement("span", { className: 'title' }, "Grand Total: ")),
                React.createElement(Col, { md: 6 }, grand_total && React.createElement(Price, { value: grand_total }))))));
};
