import React, { useState } from 'react';
import { Divider, Select } from 'antd';
import { observer } from 'mobx-react';
import Suggestion from '../Suggestion';
export const Finishes = observer((props) => {
    const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);
    const data = props.finishes;
    // Get Custom Option
    const custom = data.find(item => item.name === 'Custom');
    // Exclude custom from list
    const items = data.filter(item => item.id !== custom?.id);
    const menu = items.map(item => {
        return React.createElement(Select.Option, { key: item.id }, item.name);
    });
    const EmptyTag = (React.createElement("div", { className: "flex flex-no-wrap cursor-pointer", onClick: () => {
            if (custom && props.onChange) {
                const val = {
                    key: custom.id.toString(),
                    label: custom.name,
                    value: custom.id.toString(),
                };
                props.onChange(val, []);
                setIsDropDownMenuOpen(false);
            }
        } },
        React.createElement("span", { className: "flex flex-row items-center cursor-pointer text-light" },
            "Can't find your Surface Finish? Choose ",
            React.createElement("span", { className: "text-primary link" }, "Custom"))));
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { size: props?.for_staff ? 'middle' : 'small', showSearch: true, labelInValue: true, bordered: true, dropdownMatchSelectWidth: false, placeholder: "Select", ...props, open: isDropDownMenuOpen, onDropdownVisibleChange: visible => setIsDropDownMenuOpen(visible), notFoundContent: EmptyTag, allowClear: false, "data-testid": "finishes-test", filterOption: (input, option) => {
                try {
                    if (!option.key) {
                        // Filter by group label
                        return option.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                    }
                    return option.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                }
                catch (e) {
                    return false;
                }
            }, dropdownRender: menu => (React.createElement("div", null,
                menu,
                React.createElement(Divider, { style: { margin: '4px 0' } }))) },
            custom && custom?.id > 0 && (React.createElement(Select.OptGroup, { label: 'Custom' },
                React.createElement(Select.Option, { key: custom.id }, custom.name))),
            React.createElement(Select.OptGroup, { label: 'Options' }, menu)),
        !props.value?.label && (React.createElement("div", { className: "overflow-auto", style: { maxHeight: '75px' } }, props.suggestions?.map(suggestion => (React.createElement(Suggestion, { key: suggestion.id, title: suggestion.name, thumbnail: suggestion.thumbnail, handleClick: () => {
                const val = {
                    key: suggestion.id.toString(),
                    label: suggestion.name,
                    value: suggestion.id.toString(),
                };
                if (props.onSelect) {
                    props.onSelect(val, val);
                }
                if (props.onChange) {
                    props.onChange(val, []);
                }
            } })))))));
});
