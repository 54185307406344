import React, { useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button, Modal, notification, Table } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { EditBox } from './EditBox';
import { CreateBox } from './CreateBox';
import { IntakesList, OrderPreview } from './IntakeOuttake';
import { ErrorRender } from '@partsbadger/library';
import InventoryStore from '../Stores/InventoryStore';
import Shipment from '../Form/Shipment/';
export const PreviousIntakeOuttakeHistory = observer(({ dataSource, historyType, handleRefresh, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [ButtonPacket, setButtonPacket] = useState(false);
    const [ButtonPrintOut, setButtonPrintOut] = useState(false);
    const templateName = historyType === 'intakes' ? 'intake-printout.html' : 'packing-slip.html';
    // Create a shipment for a specific outtake
    const [showShipmentForm, setShowShipmentForm] = useState(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Created',
            dataIndex: 'created_time',
            render: (text, record) => React.createElement("span", null, moment(record.created_time).format('MMM Do hh:mm a')),
        },
        {
            title: '',
            render: record => (React.createElement(Button, { onClick: () => {
                    IntakeStore.receiveOrShip === 'receive'
                        ? IntakeStore.deleteIntakeSalesOrder(record.id)
                        : IntakeStore.deleteOuttakeSalesOrder(record.id);
                }, danger: true, type: "primary" }, "Delete")),
        },
        {
            title: '',
            render: (text, intake) => (React.createElement(Button, { type: "primary", onClick: () => {
                    IntakeStore.printSalesOrder(intake.id, historyType, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    intake?.box?.boxes_included > 0 ? 'packing-slip-boxes.html' : templateName);
                } }, "Print")),
        },
        {
            title: '',
            render: (text, intake) => (React.createElement(Button, { onClick: () => IntakeStore.setActivePreview(`/staff/${historyType}/${intake.id}/html/?template=${templateName}`) }, "View Printout")),
        },
        {
            title: '',
            render: (text, intake) => (React.createElement(Button, { loading: ButtonPrintOut, onClick: async () => {
                    setButtonPrintOut(true);
                    await IntakeStore.downloadPdf(`/staff/${historyType}/${intake.id}/pdf/?template=${
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    intake?.box?.boxes_included > 0 ? 'packing-slip-boxes.html' : templateName}`, 'PrintOut.pdf').finally(() => setButtonPrintOut(false));
                } }, "Download PDF")),
        },
        {
            title: 'Inbound Destination',
            dataIndex: 'inbound_destination',
            render: (text, intake) => {
                if (text == 'SZ') {
                    return React.createElement("div", null, "Foreign");
                }
                return React.createElement("div", null,
                    " ",
                    text);
            },
        },
    ];
    if (historyType !== 'outtakes') {
        columns.splice(3, 0, {
            title: '',
            render: (text, intake) => (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: async () => {
                        setLoading(true);
                        try {
                            await IntakeStore.setActiveViewLineItems(`/staff/intakes/${intake.id}`);
                            setLoading(false);
                        }
                        catch (err) {
                            notification.error({
                                message: React.createElement(ErrorRender, { error: err }),
                                placement: 'topRight',
                            });
                            setLoading(false);
                        }
                    } }, "View Line Items"))),
        });
    }
    // if (historyType === 'outtakes') {
    //     columns.push({
    //         title: 'Submit and Acknowledge',
    //         align: 'center' as const,
    //         dataIndex: '',
    //         render: (text, record) => (
    //             <Tooltip placement="right" title={record.created_by?.first_name}>
    //                 {record.acknowledge ? (
    //                     <CheckSquareOutlined style={{ color: 'green' }} />
    //                 ) : (
    //                     <CloseSquareOutlined style={{ color: 'red' }} />
    //                 )}
    //             </Tooltip>
    //         ),
    //     });
    // }
    if (historyType !== 'outtakes') {
        columns.push({
            title: 'Inbound Destination',
            dataIndex: 'inbound_destination',
            render: (text, intake) => React.createElement("div", null,
                " ",
                text),
        });
    }
    if (historyType === 'outtakes') {
        columns.push({
            title: 'Type',
            dataIndex: 'z_processes_name',
            render: (text, intake) => React.createElement("div", null,
                " ",
                text),
        }, {
            title: 'Outbound destination',
            dataIndex: 'outbound_destination',
            render: (text, intake) => React.createElement("div", null,
                " ",
                text),
        }, {
            title: '',
            render: record => (React.createElement(Button, { loading: ButtonPacket, type: "default", onClick: async () => {
                    setButtonPacket(true);
                    await IntakeStore.downloadPdf(`/staff/sales-orders/${IntakeStore.salesOrder.id}/download-document-pack/?outtake=${record.id}`, 'Document Packet.pdf').finally(() => setButtonPacket(false));
                } }, "Download Document Packet")),
        }, {
            title: '',
            render: (text, intakeOrOuttake) => {
                const actions = intakeOrOuttake?.box ? (React.createElement("div", { className: "flex flex-col" },
                    React.createElement(Button, { type: "primary", onClick: () => {
                            IntakeStore.setEditBoxVisible(true, intakeOrOuttake.id);
                        } }, "Edit Box"),
                    intakeOrOuttake.box?.packages.filter(p => p.label?.id).length > 0 && (React.createElement(Button, { className: 'mt-2', onClick: () => {
                            IntakeStore.printLabel(intakeOrOuttake.id)
                                .then(() => {
                                notification.success({
                                    message: 'Printing Label..',
                                    description: '',
                                });
                            })
                                .catch(err => {
                                notification.error({
                                    message: 'Error printing label',
                                    description: React.createElement(ErrorRender, { error: err }),
                                });
                            });
                        } }, "Print Label")))) : (React.createElement(Button, { type: "primary", 
                    // disabled={props.disabledBox}
                    onClick: () => IntakeStore.setCreateBoxVisible(true, intakeOrOuttake.id) }, "Create Box"));
                return (React.createElement("div", { className: 'flex flex-col' },
                    actions,
                    React.createElement("div", { className: 'mt-2 w-full', id: 'box-' + intakeOrOuttake.box?.id },
                        React.createElement(Button, { type: "default", onClick: () => setShowShipmentForm(intakeOrOuttake.id) }, intakeOrOuttake.box?.status == 'Shipped' ? 'Change Shipment' : 'Create Shipment'))));
            },
        });
    }
    return (React.createElement(React.Fragment, null,
        historyType === 'outtakes' ? (React.createElement("div", null,
            React.createElement(EditBox, null),
            React.createElement(CreateBox, { isTester: props.isTester }),
            showShipmentForm && (React.createElement(Modal, { open: true, width: 1200, footer: [], onCancel: () => {
                    setShowShipmentForm(null);
                    handleRefresh();
                } },
                React.createElement(Shipment, { isTester: props.isTester, outtakeId: showShipmentForm, handleClose: () => {
                        setShowShipmentForm(null);
                        handleRefresh();
                    } }))))) : null,
        React.createElement("div", { className: "flex justify-around" },
            React.createElement("div", { className: "bg-white" },
                React.createElement(Table, { rowKey: "id", columns: columns, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    dataSource: dataSource, pagination: false })),
            IntakeStore.activePreviewLineItems && IntakeStore.receiveOrShip === 'receive' && (React.createElement(IntakesList, { loading: loading, intake: IntakeStore.activePreviewLineItems, storage_locations: InventoryStore.storages, updateIntakeItem: IntakeStore.updateLineItemRemote, updateLocalQuantity: IntakeStore.setLocalNewQuantityIntakeLineItem })),
            IntakeStore.activePreview && React.createElement(OrderPreview, { html: IntakeStore.activePreview }))));
});
