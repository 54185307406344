import React, { useState, useEffect } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification, Popconfirm, Row, Col, message, Select, Card, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { QuoteStore } from '../Stores';
import { RichTextEditor } from '@partsbadger/library';
import { API_ROOT as BASE_URL, DownloadFile, get, post } from '@partsbadger/utils';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
export const QuoteActions = ({ loading, quote, handlePreviewQuote }) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [downloading, setDownloading] = useState(false);
    const [downloadingDynamic, setDownloadingDynamic] = useState(false);
    const [sending, setSending] = useState(false);
    const [send, sendQuote] = useState(false);
    const [notes, setNotes] = useState('');
    const [sendingToZoho, setSendingToZoho] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [showRichText, setShowRichText] = useState(false);
    const [partsWithReason, setPartsWithReason] = useState([]);
    const download = () => {
        const url = '/staff/quotes/' + quote?.id + '/export';
        setDownloading(true);
        get(url, { responseType: 'blob' }).then((response) => {
            DownloadFile(response, quote.name + '.pdf');
            setDownloading(false);
        });
    };
    const downloadDynamic = () => {
        const url = '/staff/quotes/' + quote?.id + '/export-dynamic';
        setDownloadingDynamic(true);
        get(url, { responseType: 'blob' }).then((response) => {
            DownloadFile(response, quote.name + '.pdf');
            setDownloadingDynamic(false);
        });
    };
    const templates = [
        {
            id: 1,
            title: 'Standard, attached is your quote',
            template: `<p> Hi ${quote.owner.first_name}, attached is your ${quote?.type === 'Requote' ? 'requote' : 'quote'}. ${QuoteStore?.quoteAccountOverview?.is_first_quote_for_contact
                ? ' 🏆<b>This is the customers first quote</b>🏆'
                : ''}</p>
            <p style="font-weight: bold;">Quote Notes:</->
            ${quote?.notes ? quote.notes : ''}
            <br />
            - ${quote.design_engineer?.first_name}
            </p>`,
        },
        {
            id: 2,
            title: 'No Quote - Domestic Acapbility (including PBP)',
            template: `<p>Hi ${quote.owner.first_name}, this is being no quoted due to the
            following:
            We do not offer (capability) domestically at this
            time. If you are open to us quoting within our
            international partner network, contact your
            rep.</p>`,
        },
        {
            id: 3,
            title: 'No Quote - ITAR',
            template: `<p>
            Hi ${quote.owner.first_name}, this is being no quoted due to the
            following:
            Due to unprecedented demand and capacity
            constraints within our ITAR facility
            </P`,
        },
        {
            id: 4,
            title: 'No Quote - Select by Product',
            template: `<p>
                Hi ${quote.owner.first_name}, attached is your quote.
                Please note the following product(s) is/are
                being no quoted due to the following: </br>                
                Parts: </br>
            </p>
            `,
        },
        {
            id: 5,
            title: 'No Quote - Whole Quote (Including lack of vendor quote)',
            template: `
            <p>
                Hi ${quote.owner.first_name}, attached is your quote.
                This is being no quoted due to the following:
                We do not offer (capability) at this time.
                OR
                Part is too short/long. Our
                (minimum/maximum) is (x)
                OR
                Walls are too thin. Our (minimum) is (x)
                OR
                Freefill
            </p>
            `,
        },
        {
            id: 6,
            title: 'Freefill',
            template: `
                <p>Custom message</P
            `,
        },
    ];
    useEffect(() => {
        setShowRichText(true);
    }, [selectedTemplate, notes]);
    return (React.createElement(Row, { className: 'quote-actions', style: { textAlign: 'center', padding: 5 } },
        React.createElement(Col, { style: { margin: 'auto' } },
            !quote.completed && (React.createElement(Popconfirm, { title: `Do you want to delete this quote?`, onConfirm: () => QuoteStore.deleteQuote(quote.id, history) },
                React.createElement(Button, { className: "mx-2", type: 'primary', danger: true },
                    "Delete ",
                    React.createElement(LegacyIcon, { type: 'delete' })))),
            React.createElement(Button, { className: "mx-2", onClick: () => {
                    handlePreviewQuote();
                }, loading: loading },
                React.createElement(FilePdfOutlined, null),
                " Preview"),
            React.createElement(Popconfirm, { title: `Do you want to duplicate this quote?`, onConfirm: () => {
                    QuoteStore.duplicateQuote(quote.id).then((response) => {
                        window.location.href = `/quotes/${response.id}`;
                    });
                } },
                React.createElement(Button, { className: "mx-2" },
                    "Duplicate ",
                    React.createElement(LegacyIcon, { type: 'file-pdf' }))),
            quote.completed && (React.createElement(Button, { className: "mx-2", onClick: download, loading: downloading },
                React.createElement(FilePdfOutlined, null),
                " Download")),
            quote.completed && (React.createElement(Button, { className: "mx-2", onClick: downloadDynamic, loading: downloadingDynamic },
                React.createElement(FilePdfOutlined, null),
                " Download Dynamic PDF")),
            React.createElement(Button, { className: "mx-2", onClick: () => {
                    setLoadingCsv(true);
                    QuoteStore.exportQuotedProductsAsCSV(quote.id)
                        .then(response => {
                        if (response.message) {
                            message.warning(response.message, 5);
                        }
                        else {
                            FileDownload(response, `products.csv`);
                        }
                    })
                        .catch(error => {
                        message.error('There was an error downloading the csv file.', 6);
                    })
                        .finally(() => {
                        setLoadingCsv(false);
                    });
                }, loading: loadingCsv },
                React.createElement(DownloadOutlined, null),
                " CSV Quoted Products"),
            React.createElement(Button, { className: "mx-2", onClick: () => {
                    window.open(`${BASE_URL}/redirect?to=${BASE_URL}/staff/quotes/${quote.id}/download-all/`);
                }, loading: loading },
                React.createElement(DownloadOutlined, null),
                " Download All Files"),
            quote.completed && (React.createElement(React.Fragment, null,
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'file-pdf' }), className: "mx-2", onClick: () => sendQuote(true) }, "Send Quote to Rep"),
                React.createElement(Popconfirm, { placement: "topLeft", title: "Do you want to convert this quote to an order?", onConfirm: async () => {
                        await SalesOrderStore.getOrCreateSalesOrder(quote.contact.id).then(async () => {
                            await SalesOrderStore.update(SalesOrderStore.order_id, {
                                quotes: [],
                            });
                            await SalesOrderStore.update(SalesOrderStore.order_id, {
                                contact: quote.contact.id,
                                quotes: [quote.id],
                            });
                            history.push('/sales-order/new/');
                        });
                        history.push('/sales-order/new');
                    }, okText: "Yes", cancelText: "No" },
                    React.createElement(Button, { className: "mx-2", type: "primary" }, "Convert to Sales Order")),
                !quote.zoho_id && quote.stage === 'Completed' && (React.createElement(Button, { className: "mx-2", title: 'Completed quotes are sending automatically to zoho', loading: sendingToZoho, onClick: () => {
                        setSendingToZoho(true);
                        post(`/staff/quotes/${quote.id}/send-to-zoho/`)
                            .then((response) => {
                            QuoteStore.setQuote(response);
                            setSendingToZoho(false);
                        })
                            .catch((error) => {
                            // console.log(error);
                            setSendingToZoho(false);
                        });
                    } }, "Send to Zoho")))),
            send && (React.createElement(Modal, { width: 800, visible: true, centered: true, onCancel: () => sendQuote(false), footer: [
                    React.createElement(Button, { key: "1", className: "mx-2", loading: sending, onClick: () => {
                            if ((selectedTemplate === 4 && partsWithReason === null) ||
                                (selectedTemplate === 4 && partsWithReason?.length === 0)) {
                                notification.error({
                                    message: 'Please select one or more Parts.',
                                });
                                return;
                            }
                            setSending(true);
                            QuoteStore.sendToSalesRepresentative(quote.id, {
                                notes: notes,
                            }).then(() => {
                                notification.success({
                                    message: 'Quote was sent to the sales representative',
                                });
                                sendQuote(false);
                                setSending(false);
                            });
                            QuoteStore.getNotifications();
                        } }, "Submit"),
                ] },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    "Select template",
                    React.createElement(Select, { value: selectedTemplate, options: templates.map(template => ({ label: template.title, value: template.id })), onChange: value => {
                            const item = templates.find(item => item.id === value);
                            if (item) {
                                setPartsWithReason([]);
                                setSelectedTemplate(value);
                                setNotes(item.template);
                                setShowRichText(false);
                            }
                        } })),
                selectedTemplate === 4 && QuoteStore.lineItems.length > 0 && (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(Card, { title: 'Select a part and enter a reason for not being quoted', size: 'small' },
                        React.createElement(Form, { form: form, name: "add-part-to-no-quote", onFinishFailed: errorInfo => {
                                console.log('Failed:', errorInfo);
                            }, layout: "vertical", onFinish: (values) => {
                                setPartsWithReason([
                                    { part_name: values.part, due_to: values.due_to },
                                    ...partsWithReason,
                                ]);
                                setShowRichText(false);
                                const renderedParts = partsWithReason.map(part => {
                                    return `<b>${part.part_name}</b> - ${part.due_to}`;
                                });
                                const result = renderedParts.join('<br>');
                                setNotes(` Hi ${quote.owner.first_name}, attached is your quote.
                                                        Please note the following product(s) is/are
                                                        being no quoted due to the following: </br>

                                                        Parts: </br>
                                                        ${result} <br>
                                                        <b>${values.part}</b> - ${values.due_to} </br>
                                                     `);
                                setShowRichText(true);
                                form.resetFields();
                            } },
                            React.createElement("div", { className: "flex" },
                                React.createElement("div", { className: "w-1/2 p-4" },
                                    React.createElement(Form.Item, { label: "Parts", name: 'part', rules: [{ required: true, message: 'Please select a part!' }] },
                                        React.createElement(Select, { onSelect: value => {
                                                const match = value.match(/#(\d+)-/);
                                                if (match) {
                                                    const part_id = parseInt(match[1], 10);
                                                    const selectedPart = QuoteStore.lineItems.find(part => part.id === part_id);
                                                    if (selectedPart) {
                                                        form.setFieldValue('due_to', selectedPart.product.master_product_detail
                                                            ?.notes);
                                                    }
                                                }
                                            }, options: QuoteStore.lineItems.map(part => ({
                                                label: '#' + part.id + '-' + part.name,
                                                value: '#' + part.id + '-' + part.name,
                                            })) }))),
                                React.createElement("div", { className: "w-1/2 p-4" },
                                    React.createElement(Form.Item, { label: "Due to", name: 'due_to', rules: [{ required: true, message: 'Please enter a reason!' }] },
                                        React.createElement(Input, null)))),
                            React.createElement("div", { className: "flex justify-end" },
                                React.createElement(Button, { type: "primary", htmlType: "submit" }, "Add")))))),
                showRichText && React.createElement(RichTextEditor, { value: notes, onChange: (html, text) => setNotes(html) })))),
        React.createElement("br", null)));
};
