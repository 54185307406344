import React, { useState } from 'react';
import { Modal, Select, Button } from 'antd';
const { Option } = Select;
const DuplicationReasonPopup = ({ isOpen, partName, type, onChangeReason, onConfirm, onCancel, }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const handleConfirm = () => {
        onConfirm(selectedReason);
    };
    const handleChange = (value) => {
        setSelectedReason(value);
        if (onChangeReason)
            onChangeReason(value);
    };
    const modalTitle = type === 'request_requote' ? 'Request for Requote' : 'Select a reason for the new file';
    const modalMessage = type === 'request_requote'
        ? `Please select a reason for the change related to the part ${partName} not being quoted and your request for a new quote.`
        : `It appears that you’re updating a file for ${partName}. Please select a reason for the change.`;
    return (React.createElement(Modal, { title: modalTitle, visible: isOpen, onOk: handleConfirm, onCancel: onCancel, footer: [
            React.createElement(Button, { key: "back", onClick: onCancel }, "Cancel"),
            React.createElement(Button, { key: "submit", type: "primary", onClick: handleConfirm, disabled: !selectedReason }, "Confirm"),
        ] },
        React.createElement("p", null, modalMessage),
        React.createElement(Select, { value: selectedReason, onChange: handleChange, style: { width: '100%' } },
            React.createElement(Option, { value: "" }, "Please select..."),
            React.createElement(Option, { value: "To Correct or Provide Additional Information" }, "To Correct or Provide Additional Information"),
            React.createElement(Option, { value: "To Update the Revision" }, "To Update the Revision"))));
};
export default DuplicationReasonPopup;
