import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
const ButtonGroup = Button.Group;
const VendorQuotedPrice = (props) => {
    const { form } = props;
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    ...values,
                };
                setLoading(true);
                props
                    .handleSave({
                    vendor_name: values.vendor_name,
                    vendor_quoted_unit_price: values.vendor_quoted_unit_price,
                    vendor_notes: values.vendor_notes,
                })
                    .then(() => {
                    setLoading(false);
                });
            }
        });
    };
    return (React.createElement("div", { className: 'vendor-form' },
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(Form.Item, { label: "Vendor Name" }, getFieldDecorator('vendor_name', {
                rules: [{ required: true, message: 'Please insert value!' }],
                initialValue: props.vendor_name,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: "Vendor Unit Price" }, getFieldDecorator('vendor_quoted_unit_price', {
                rules: [{ required: true, message: 'Please insert unit price!' }],
                initialValue: props.vendor_quoted_unit_price,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: "Vendor Notes" }, getFieldDecorator('vendor_notes', {
                rules: [{ required: false, message: 'Please insert unit price!' }],
                initialValue: props.vendor_notes,
            })(React.createElement(Input.TextArea, null))),
            React.createElement(ButtonGroup, { className: 'add-button' },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }), loading: loading }, "Save")))));
};
export default Form.create()(VendorQuotedPrice);
