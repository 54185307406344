import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Layout } from 'antd';

import HeaderComponent from './HeaderComponent';
import LoadingComponent from './LoadingComponent';
import { DealDetail, DealList } from '../pages/Deal';
import {
    PartsBadgerProductionList,
    QuoteDetailV2,
    QuotedProductDetail,
    QuotedProductList,
    QuoteList,
    QuoteNew,
    QuoteReport,
    RequoteLinkRedirector,
} from '../pages/Quote';

import NotFoundComponent from '../pages/NotFoundComponent';
import ForbiddenComponent from '../pages/ForbiddenComponent';
import DashboardPage from '../pages/Dashboard';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../pages/LoginComponent';
import Logout from './LogoutComponent';
import { RfqList, RfqToQuote } from '../pages/Rfq/';
import DrawingTextBox from '../pages/Orders/DrawingTextBox';
import { NewPartQualityIssueReport } from '../pages/Orders/NewPartQualityIssueReport';
import CreditCardSubmission from '../pages/Orders/CreditCardSubmission';
import OrderShop from '../pages/Orders/OrderShop';
import { PartViewer, ShopTimeTracker } from '../pages/Tools';
import {
    ManageAddresses,
    ManageRequirements,
    ManageTaxForms,
    MergeAccountsComponent,
    MergeContacts,
    NDAForms,
} from '../pages/Contact/';
import NewSalesOrder from '../pages/Orders/NewSalesOrder';
import { Compose } from './Rfq/message';
import {
    Details,
    LineItemsOnlyIntakeReportForm,
    OrderDetail,
    OrdersList,
    QualityKpisReport,
    UpdateHTSOnOrder,
} from '../pages/Orders';
import { OrderReportsForm } from '../pages/Orders/OrderReports';
import CancelledIntakeReport from '../pages/Orders/CancelledIntakeReport';
import { GetQuote } from '../pages/OneTrueForm';
import { ShippingReceiving2 } from '../pages/ShippingReceivingForm';
import { MergeAccounts } from '../pages/Accounts/merge_accounts';
import { RequoteReport } from '../pages/Quote/ReportRequotesByAuthor';
import ReportQuoteTimeMetrics from '../pages/Quote/ReportQuoteTimeMetrics';
import EditSalesOrder from '../pages/Orders/EditSalesOrder';
import { UserStore } from '../stores/UserStore';
import OrdersRedirect from '../pages/Orders/OrderRedirect';
import { InventoryDetail, InventoryList } from '../pages/Inventory';
import CreditMemoPage from '../pages/Orders/CreditMemoPage';
import PurchaseOrder from '../pages/PurchaseOrders/PurchaseOrder';
import PurchaseOrderCreate from '../pages/PurchaseOrders/PurchaseOrderCreate';
import Invoices from 'pages/Invoices/Invoices';
import InvoiceDetailPage from 'pages/Invoices/InvoiceDetailPage';
import PurchaseOrderEdit from '../pages/PurchaseOrders/PurchaseOrderEdit';
import LeadTimeTrackingReport from '../pages/Orders/LeadTimeTracking/LeadTimeTrackingReport';
import { OrderTimeTrackingReportForm } from '../pages/Orders/OrderTimeTracking/OrderTimeTrackingReport';
import OrderReportsToday from 'pages/Orders/OrderReportsToday';
import { AccountRequirements } from '../pages/Accounts';
import ChecklistReviewPage from '../pages/Orders/ChecklistReviewPage';
import { OuttakeAuditForm } from 'pages/Orders/OuttakeAuditReport';
import { ZOuttakeForm } from 'pages/Orders/ZOuttakeReport';
import RequestDesignChange from '../pages/Orders/RquestDesignChange';
import BusinessRuleIndexPage from '../pages/BusinessRules/IndexPage';
import BusinessRuleEditPage from '../pages/BusinessRules/EditPage';
import BusinessRuleNewPage from '../pages/BusinessRules/NewPage';
import RedactionTool from '../pages/Orders/RedactionTool';

import packages from '../../package.json';
import GuidedTour from '../components/GuidedTour';
import { RouteProps } from 'react-router';
import { StorageListPage } from '../pages/StorageLocation';
import OrderApprovedSample from '../pages/Orders/OrderApprovedSample';
import { QuickTurnOrdersList } from 'pages/Orders/QuickTurnOrdersList';
import { QuickTurnOrderDetail } from 'pages/Orders/QuickTurnOrderDetail';
import { FastLaneRerportForm } from 'pages/Orders/FastLaneProductsReport';
import { YesterdayTransactionsForm } from 'pages/Orders/YesterdayTransactionsReport';
import { OrdersReadyToBeProcessedForm } from 'pages/Orders/OrdersReadyToBeProcessedReport';
import SalesTicker from 'pages/SalesTicker';
import Search from '../pages/Search';
import ContactListPage from '../pages/Contact/ContactsList';
import ContactDetailPage from '../pages/Contact/ContactDetailPage';
import VendorsListPage from '../pages/Production/Vendors';
import TicketMainPage from '../pages/Orders/TicketSystem/TicketMainPage';

import { TemplateListPage, TemplatesCreatePage, TemplatesEditPage } from '../pages/Templates/index';
import { LeadConversionTool } from '../pages/Leads';
import MaterialVendorsPage from '../pages/Production/DomesticVendors';
import MenuBar from './Menu';
import Logo from './Layouts/Logo';
import CancelItemsPage from '../pages/Orders/Details/CancelItems/CancelItemsPage';
import AccountListPage from '../pages/Accounts/AccountsListPage';
import AccountsDetailPage from '../pages/Accounts/AccountsDetailPage';
import QuoteDetails from '../pages/Quote/QuoteDetails';
import { QualityIssueReworkPage } from '../pages/Orders/SalesOrderRework/QualityIssueReworkPage';
import { SalesOrderReworkPage } from '../pages/Orders/SalesOrderRework/SalesOrderReworkPage';

const { Header, Content, Sider } = Layout;

interface IRouteProp extends RouteProps {
    name: string;
}

const Main = () => {
    const { user, isLoading, isAuthenticated } = UserStore;

    useEffect(() => {
        UserStore.loadUser();
    }, []);

    useEffect(() => {
        window.localStorage.setItem('app_version', packages.version);
    }, [packages.version]);

    const DARK_MODE = 'dark-mode';

    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const mode = window.localStorage.getItem(DARK_MODE) === 'yes';
        setDarkMode(mode);
    }, []);

    const withLayout = (ChildComponent: any, props: any, pageName: string) => {
        return (
            <Layout>
                <Sider
                    collapsible
                    breakpoint="lg"
                    collapsedWidth="0"
                    defaultCollapsed={true}
                    className={'sider'}
                    theme={darkMode ? 'dark' : 'light'}
                >
                    <Logo
                        className="ml-1 pt-2"
                        style={{
                            width: '90%',
                            float: 'left',
                        }}
                    />
                    <MenuBar user={user} mode={'inline'} />
                </Sider>

                <Layout>
                    <Header style={{ zIndex: 2, padding: 0 }}>
                        <div className="flex items-center">
                            <HeaderComponent
                                user={user}
                                handleLogout={() => {
                                    UserStore.logout();
                                }}
                            />
                        </div>
                    </Header>
                    <Content style={{ margin: '5px 15px 0px' }}>
                        <Card
                            style={{ padding: 5, minHeight: '90vh' }}
                            bodyStyle={{
                                padding: 0,
                            }}
                        >
                            <GuidedTour pageName={pageName} />
                            <ChildComponent {...props} />
                        </Card>
                    </Content>
                    {/*<Layout.Footer>*/}
                    {/*    <div>*/}
                    {/*        Version:*/}
                    {/*        {window.localStorage.getItem('app_version')}*/}
                    {/*    </div>*/}
                    {/*</Layout.Footer>*/}
                </Layout>
            </Layout>
        );
    };

    const PrivateRoute = ({ component: ChildComponent, name, ...rest }: IRouteProp) => {
        return (
            <Route
                {...rest}
                render={props => {
                    if (isLoading) {
                        return (
                            <div style={{ paddingTop: 200, textAlign: 'center' }}>
                                <LoadingComponent visible={true} />
                            </div>
                        );
                    } else if (!isAuthenticated) {
                        const next = window.location.pathname;
                        return <Redirect to={'/login?next=' + next} />;
                    } else {
                        return withLayout(ChildComponent, props, name);
                    }
                }}
            />
        );
    };

    const PublicRoute = ({ component: ChildComponent, name, ...rest }: IRouteProp) => {
        return (
            <Route
                {...rest}
                render={props => {
                    return withLayout(ChildComponent, props, name);
                }}
            />
        );
    };

    if (isLoading) {
        return (
            <div style={{ paddingTop: 200, textAlign: 'center' }}>
                <LoadingComponent visible={true} />
            </div>
        );
    }

    return (
        <Switch>
            <PrivateRoute name="Dashboard" exact path="/" component={DashboardPage} />

            <PrivateRoute name="Part Viewers" exact path="/viewer" component={PartViewer} />

            <PrivateRoute name="Deal" exact path="/deals" component={DealList} />
            <PrivateRoute name="Deals Detail" exact path="/deals/:deal" component={DealDetail} />

            <PrivateRoute name="Shipping Receiving" exact path="/shipping-receiving/" component={ShippingReceiving2} />
            <PrivateRoute name="One True Form" exact path="/get-quote" component={GetQuote} />

            <PrivateRoute name="Quotes" exact path="/quotes" component={QuoteList} />
            <PrivateRoute name="Quotes" exact path={'/customer/quotes'} component={QuoteList} />
            <PrivateRoute name="Quote Details" exact path={'/quotes/:id/details'} component={QuoteDetails} />
            <PrivateRoute name="Quote Tool" exact path="/quotes/:id" component={QuoteDetailV2} />
            <PrivateRoute name="New Quote" exact path="/quote/new/" component={QuoteNew} />
            <PrivateRoute name="Get Requote" exact path="/requote/:id" component={RequoteLinkRedirector} />

            <PrivateRoute
                name={'PartsBadger Production'}
                exact
                path="/partsbadger-production"
                component={PartsBadgerProductionList}
            />

            <PrivateRoute name="Quoted Products" exact path="/quoted-products" component={QuotedProductList} />
            <PrivateRoute
                name="Quoted Products Detail"
                exact
                path="/quoted-product/:id"
                component={QuotedProductDetail}
            />

            <PrivateRoute name="DWG Text Box" exact path={'/orders/dwg'} component={DrawingTextBox} />
            <PrivateRoute name="DWG Text Box" exact path={'/tools/shop-time-tracker'} component={ShopTimeTracker} />

            <PrivateRoute name="RFQ" exact path="/rfq" component={RfqList} />
            <PrivateRoute name="New RFQ" exact path="/rfq/new" component={GetQuote} />
            <PrivateRoute name="View RFQ" exact path="/rfq/:id/create" component={RfqToQuote} />

            <PrivateRoute name="RFQ Module List" exact path="/module/quotes.rfq" component={RfqList} />
            <PrivateRoute name="RFQ Module Detail" exact path="/module/quotes.rfq/:id" component={Compose} />

            <PrivateRoute name="Request For Order List" exact path="/rfo/" component={OrdersList} />
            <PrivateRoute name="Request For Order New" exact path="/rfo/new/" component={NewSalesOrder} />

            <PrivateRoute
                name="List QuickTurn Orders"
                exact
                path="/quickturn/sales-orders/"
                component={QuickTurnOrdersList}
            />
            <PrivateRoute
                name="QuickTurn Order Details"
                exact
                path="/quickturn/sales-orders/:quickturn_sales_order_id"
                component={QuickTurnOrderDetail}
            />

            <PrivateRoute name="List Orders" exact path="/sales-orders/" component={OrdersList} />
            <PrivateRoute name="View Order" exact path="/sales-order-detail/:sales_order_id" component={OrderDetail} />
            <PrivateRoute
                name="Order Details"
                exact
                path="/new-sales-order-detail/:sales_order_id"
                component={Details}
            />
            <PrivateRoute
                name={'ticket-system'}
                exact
                path="/new-sales-order-detail/:zoho_id/ticket-system/"
                component={TicketMainPage}
            />
            <PrivateRoute name="New Order" exact path="/sales-order/new" component={NewSalesOrder} />
            <PrivateRoute name="Edit Order" exact path="/sales-order/:sales_order_id/" component={EditSalesOrder} />
            <PrivateRoute
                name="Edit Order"
                exact
                path="/sales-order/:sales_order_id/credit-memos"
                component={CreditMemoPage}
            />
            <PrivateRoute
                name={'order-approved-sample'}
                exact
                path="/sales-order/:id/approved-sample"
                component={OrderApprovedSample}
            />
            <PrivateRoute
                name={'checklist'}
                exact
                path="/sales-order/:id/checklists-view"
                component={ChecklistReviewPage}
            />
            <PrivateRoute
                name={'request-design-change'}
                exact
                path="/sales-order/:id/request-design-change"
                component={RequestDesignChange}
            />

            <PrivateRoute
                name="Create Sales Order Rework"
                exact
                path="/sales-order/:sales_order_zoho_id/rework"
                component={SalesOrderReworkPage}
            />

            <PrivateRoute name="DWG Text Box" exact path={'/sales-orders/:id/dwg'} component={DrawingTextBox} />

            <PrivateRoute name="Update HTS" exact path={'/sales-orders/hts/'} component={UpdateHTSOnOrder} />

            <PrivateRoute
                name="Credit Card Submission"
                exact
                path={'/sales-orders/credit-card-submission/'}
                component={CreditCardSubmission}
            />

            <PrivateRoute
                name={'sales-order-process'}
                exact
                path={'/sales-order/:process/:zoho_id/'}
                component={OrdersRedirect}
            />

            <PrivateRoute
                name="Part Quality Issue Report"
                exact
                path={'/sales-orders/quality-issue/'}
                component={NewPartQualityIssueReport}
            />

            <PrivateRoute
                name="Part Quality Issue Rework"
                exact
                path={'/quality-issues/:quality_issue_zoho_id/rework/'}
                component={QualityIssueReworkPage}
            />

            <PrivateRoute name="Order To The Shop" exact path={'/sales-orders/order-shop/:id'} component={OrderShop} />

            <PrivateRoute name="New Order" exact path="/inventory/" component={InventoryList} />
            <PrivateRoute name="Edit Order" exact path="/inventory/:id/" component={InventoryDetail} />
            <PrivateRoute name="Storage Location" exact path="/storage-location/" component={StorageListPage} />

            <PrivateRoute name="Leads" exact path="/leads/conversion-tool/" component={LeadConversionTool} />

            <PrivateRoute name="Accounts" exact path="/accounts/" component={AccountListPage} />
            <PrivateRoute name="Accounts" exact path="/accounts/:id/" component={AccountsDetailPage} />
            <PrivateRoute name="Accounts" exact path="/accounts/merge" component={MergeAccounts} />

            <PrivateRoute name="Contacts" exact path="/contacts" component={ContactListPage} />
            <PrivateRoute name="Contact Detail" exact path="/contacts/:id/" component={ContactDetailPage} />

            <PrivateRoute
                name="Requirements"
                exact
                path="/accounts-requirements/:zoho_id?/"
                component={AccountRequirements}
            />
            <PrivateRoute name="Requirements" exact path="/additional-requirements" component={ManageRequirements} />
            <PrivateRoute name="Tax Exempt Form" exact path="/contact/taxes-form" component={ManageTaxForms} />
            <PrivateRoute name="Addresses" exact path="/contact/addresses" component={ManageAddresses} />
            <PrivateRoute name="NDA Form" exact path="/contact/nda-form" component={NDAForms} />
            <PrivateRoute name="Merge Contacts" exact path="/contact/merge-contacts" component={MergeContacts} />
            <PrivateRoute
                name="Merge Accounts"
                exact
                path="/contact/merge-accounts"
                component={MergeAccountsComponent}
            />

            <PrivateRoute name="Report Quotes" exact path="/reports/quotes" component={QuoteReport} />
            <PrivateRoute name="Report Requotes" exact path="/reports/requotes" component={RequoteReport} />
            <PrivateRoute
                name={'rreport-avg-times'}
                exact
                path="/reports/report-avg-times/"
                component={ReportQuoteTimeMetrics}
            />
            <PrivateRoute name={'must-to-ship'} exact path="/reports/must-ship-today/" component={OrderReportsForm} />
            <PrivateRoute name="today-intakes" exact path="/reports/today-intakes/" component={OrderReportsToday} />
            <PrivateRoute
                name={'cancelled-intake'}
                exact
                path="/reports/cancelled-intake/"
                component={CancelledIntakeReport}
            />
            <PrivateRoute name={'quality-kpis'} exact path="/reports/quality-kpis/" component={QualityKpisReport} />
            <PrivateRoute
                name={'audit-report'}
                exact
                path="/reports/outtake-audit-report/"
                component={OuttakeAuditForm}
            />
            <PrivateRoute
                name={'z-outtake-report'}
                exact
                path="/reports/purchase-orders-z-outtakes/"
                component={ZOuttakeForm}
            />
            <PrivateRoute
                name={'fast-lane-products-report'}
                exact
                path="/reports/fast-lane-products-report/"
                component={FastLaneRerportForm}
            />
            <PrivateRoute
                name={'yesterday-transactions'}
                exact
                path="/reports/yesterday-transactions/"
                component={YesterdayTransactionsForm}
            />
            <PrivateRoute
                name={'orders-ready-to-be-processed'}
                exact
                path="/reports/orders-ready-to-be-processed/"
                component={OrdersReadyToBeProcessedForm}
            />
            <PrivateRoute name="Redaction Tool" exact path={'/orders/redaction-tool/:id'} component={RedactionTool} />

            <PrivateRoute name="Purchase Orders" exact path="/purchase-orders/" component={PurchaseOrder} />
            <PrivateRoute name="Purchase Order New" exact path="/purchase-orders/new" component={PurchaseOrderCreate} />
            <PrivateRoute name="Invoices" exact path="/invoices/" component={Invoices} />
            <PrivateRoute name="Invoices" exact path="/invoices/:id/" component={InvoiceDetailPage} />
            <PrivateRoute
                name="Edit Purchase Order"
                exact
                path="/purchase-orders/:purchase_order_id/"
                component={PurchaseOrderEdit}
            />

            <PrivateRoute name={'business-rules'} exact path="/business-rules/" component={BusinessRuleIndexPage} />
            <PrivateRoute name={'business-rules'} exact path="/business-rules/new/" component={BusinessRuleNewPage} />
            <PrivateRoute name={'business-rules'} exact path="/business-rules/:id/" component={BusinessRuleEditPage} />

            <PrivateRoute name={'Vendor Users'} exact path="/production/vendors/" component={VendorsListPage} />
            <PrivateRoute
                name={'Domestic Vendors'}
                exact
                path="/production/domestic-vendors/"
                component={MaterialVendorsPage}
            />

            <PrivateRoute
                name={'only-intake'}
                exact
                path="/reports/line-items-only-intake/"
                component={LineItemsOnlyIntakeReportForm}
            />

            <PrivateRoute
                name={'lead-time-tracking'}
                exact
                path="/reports/lead-time-tracking/"
                component={LeadTimeTrackingReport}
            />

            <PrivateRoute
                name={'order-time-tracking'}
                exact
                path="/reports/order-time-tracking/"
                component={OrderTimeTrackingReportForm}
            />

            <PrivateRoute name={'sales-ticker'} exact path="/reports/sales-ticker/" component={SalesTicker} />

            <PrivateRoute name={'Search'} exact path="/search/" component={Search} />
            <PrivateRoute name={'templates-list'} exact path="/templates/" component={TemplateListPage} />
            <PrivateRoute name={'template-new'} exact path="/templates/new/" component={TemplatesCreatePage} />
            <PrivateRoute name={'template-edit'} exact path="/templates/:id/" component={TemplatesEditPage} />

            <PrivateRoute
                name={'cancel-items'}
                exact
                path="/new-sales-order-detail/:sales_order_id/cancellation"
                component={CancelItemsPage}
            />

            <PrivateRoute name="Forbidden" exact path="/forbidden" component={ForbiddenComponent} />

            <PrivateRoute name="Logout" exact path="/logout" component={Logout} />
            <Route exact path="/login" component={Login} />
            <PublicRoute name="NotFound" component={NotFoundComponent} />
        </Switch>
    );
};

export default observer(Main);
