import React, { useState } from 'react';
import { Radio } from 'antd';
export const QuestionYesOrNo = (props) => {
    const [value, setValue] = useState(undefined);
    const onChange = (e) => {
        setValue(e.target.value);
        props.setAnswer(e.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex justify-center p-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", null,
                    React.createElement("span", { className: 'font-bold' }, props.question)),
                React.createElement("div", { className: 'flex justify-center' },
                    React.createElement(Radio.Group, { className: 'flex flex-row', value: props.default !== undefined ? props.default : value, ...props, onChange: onChange },
                        React.createElement(Radio, { value: true }, "Yes"),
                        React.createElement(Radio, { value: false }, "No")))))));
};
