import React from 'react';
import { UploadFile } from '@partsbadger/library';
import SalesOrderStore from './Stores/SalesOrderStore';
export const UploadFileInfo = ({ typeFile, record, setShowDragger, accept, file3DApproval }) => {
    const { order_items } = SalesOrderStore;
    const updateStepFile = (file_id, filename) => {
        SalesOrderStore.updateOrderItem3D(record, file_id, filename, file3DApproval);
        const payload = {
            ...record,
            name_3d_file: filename,
            production_3d_file_id: file_id,
        };
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
        setShowDragger(false);
    };
    const updateDrawingFile = (file_id, filename) => {
        SalesOrderStore.updateOrderItem2D(record, file_id, filename);
        const payload = {
            ...record,
            name_2d_file: filename,
            production_2d_file_id: file_id,
        };
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
        setShowDragger(false);
    };
    const onUpload = (file) => {
        if (typeFile === '3D') {
            updateStepFile(file.response.id, file.name);
            SalesOrderStore.analyze_3d_file(file.response.id);
        }
        else if (typeFile === '2D') {
            updateDrawingFile(file.response.id, file.response.filename);
        }
    };
    const updateStepFileAll = (record, file_id, filename) => {
        SalesOrderStore.updateOrderItem3D(record, file_id, filename);
        const payload = {
            ...record,
            name_3d_file: filename,
            production_3d_file_id: file_id,
        };
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
    };
    const updateDrawingFileAll = (record, file_id, filename) => {
        SalesOrderStore.updateOrderItem2D(record, file_id, filename);
        const payload = {
            ...record,
            name_2d_file: filename,
            production_2d_file_id: file_id,
        };
        SalesOrderStore.updateRemoteOrderItemLocal(payload);
    };
    return (React.createElement("div", { className: 'py-2' },
        React.createElement(UploadFile, { fileType: typeFile === '3D' ? '3D' : '2D', accept: accept, multiple: false, onUpload: (file) => onUpload(file) })));
};
