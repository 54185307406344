import React from 'react';
import { Tooltip } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { QuoteStore } from '../Stores';
export const TooltipFlag = observer((props) => {
    const value_original = () => {
        if (QuoteStore.quote && QuoteStore.quote.changed_fields) {
            if (props.item_id) {
                const changed_item = QuoteStore.quote.changed_fields[props.item_id];
                if (changed_item) {
                    const changed_field = changed_item[props.field_name];
                    if (changed_field) {
                        if (changed_field.previous_value === '' || changed_field.previous_value === null) {
                            return 'No previous value';
                        }
                        return changed_field.previous_value.toString();
                    }
                    else {
                        return undefined;
                    }
                }
                else {
                    return undefined;
                }
            }
            else {
                const changed_field = QuoteStore.quote.changed_fields[props.field_name];
                if (changed_field) {
                    if (Array.isArray(changed_field.previous_value) &&
                        changed_field.previous_value.every(element => true)) {
                        return changed_field.previous_value.join(', ');
                    }
                    if (changed_field.previous_value === '' || changed_field.previous_value === null) {
                        return 'No previous value';
                    }
                    return changed_field.previous_value.toString();
                }
                else {
                    return undefined;
                }
            }
        }
    };
    if (value_original()) {
        return (React.createElement(Tooltip, { className: 'mx-2 mb-2', title: React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("b", null, "Changed from: "),
                    value_original() == 'true' ? 'Yes' : value_original() == 'false' ? 'No' : value_original())) },
            React.createElement(HistoryOutlined, { style: { color: 'purple', cursor: 'pointer', fontSize: '14px' } })));
    }
    else {
        return null;
    }
});
