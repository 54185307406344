import { action, observable, makeObservable } from 'mobx';
class SalesOrderBatchStore {
    constructor() {
        this.batches = [];
        this.total_quantity = 0;
        this.missing_quantity = 0;
        this.original_quantity = 0;
        makeObservable(this, {
            // Observables
            batches: observable,
            total_quantity: observable,
            missing_quantity: observable,
            original_quantity: observable,
            // Actions
            setBatches: action,
            setBatch: action,
            removeBatch: action,
            resetBatches: action,
            resetTotalQuantity: action,
            getQuantity: action,
            getMissingQuantity: action,
            getOriginalQuantity: action,
        });
    }
    setBatches(batches) {
        this.batches = batches;
    }
    setBatch(order_item, batch) {
        this.batches.push(batch);
        order_item.batches = this.batches;
    }
    removeBatch(order_item, index_batch) {
        this.batches.splice(index_batch, 1);
        this.batches = this.batches.map(bt => {
            return bt;
        });
        const batches = this.batches.filter(batch => batch.order_item === order_item.id);
        if (batches.length > 0) {
            order_item.batches = this.batches;
        }
        else {
            order_item.batches = [];
        }
    }
    resetBatches() {
        this.batches = [];
    }
    resetTotalQuantity() {
        this.total_quantity = 0;
    }
    getQuantity(order_item_id) {
        let quantity = 0;
        this.batches.map(batch => {
            if (batch.order_item == order_item_id) {
                quantity = quantity + batch.quantity;
            }
        });
        this.total_quantity = quantity;
        return quantity;
    }
    getMissingQuantity(quantity_quote_item, total_quantity, quantity_sample = 0) {
        this.missing_quantity = quantity_quote_item - total_quantity - quantity_sample;
    }
    getOriginalQuantity(quantity) {
        this.original_quantity = quantity;
    }
}
export default new SalesOrderBatchStore();
