import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { get } from '@partsbadger/utils';
import { Skeleton, Timeline, Typography, Button } from 'antd';
import dayjs from 'dayjs';
function normalizeFieldName(fieldName) {
    const words = fieldName.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}
const HistoryChanges = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [history, setHistory] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true);
    const pageSize = 30;
    const loadHistory = async () => {
        setLoading(true);
        const data = await get(`/staff/${props.model_name}/${props.pk}/history/?page=${page}&page_size=${pageSize}`);
        setHistory(prevHistory => [...prevHistory, ...data.history]);
        setTotalPages(data.total_pages);
        setHasMore(page < data.total_pages);
        setLoading(false);
    };
    React.useEffect(() => {
        loadHistory();
    }, [page]);
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };
    if (loading && page === 1) {
        return React.createElement(Skeleton, { active: true });
    }
    if (history.length === 0) {
        return React.createElement("div", null, "No history changes found");
    }
    return (React.createElement("div", { style: { width: '100%' } },
        React.createElement(Timeline, { mode: "left", className: "mt-2" }, history.map(item => (React.createElement(Timeline.Item, { key: item.history_id, dot: React.createElement(ClockCircleOutlined, { style: { fontSize: '16px' } }), label: React.createElement("div", null,
                React.createElement("div", null, dayjs(item.date).format('MM/DD/YYYY hh:mm A')),
                React.createElement("div", { className: "text-primary" },
                    React.createElement(Typography.Text, null, item.changed_by)),
                React.createElement("div", null,
                    "Reason: ",
                    item.change_reason ?? '-')) },
            React.createElement("div", { style: { minHeight: '100px' } },
                React.createElement(Typography.Text, null,
                    React.createElement("div", { className: "my-2" }, item.changes.map(c => (React.createElement("div", { key: c.field },
                        React.createElement("span", { className: "mx-2" }, normalizeFieldName(c.field)),
                        "was updated from",
                        React.createElement("span", { className: "bold ml-2" }, c.old),
                        "\u00A0 to",
                        React.createElement("span", { className: "bold ml-2" }, c.new))))))))))),
        hasMore && (React.createElement("div", { style: { textAlign: 'center', marginTop: '20px' } },
            React.createElement(Button, { onClick: handleLoadMore, loading: loading }, "Load More")))));
};
export default HistoryChanges;
