import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, Radio } from 'antd';
import { Countries, States } from '../Select';
import { AutoCompleteAddressInput } from '../AutoCompleteAddressInput';
const formItemLayout = {
    labelCol: {
        span: 7,
    },
};
const formItem = {
    style: {
        marginBottom: 8,
        paddingBottom: 0,
    },
};
const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};
export const is_valid_address = (rule, value) => {
    return !(!value.name || !value.street || !value.city || !value.country || !value.zip_code);
};
const AddressFormComponent = (props) => {
    const [loading, setLoading] = useState(false);
    const { form } = props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const validateZipCode = (rule, value, callback) => {
        const commonRegex = /^[a-zA-Z0-9\s.,!?()-]+$/;
        const commonMessage = ' Special characters are not allowed.';
        let regex = commonRegex;
        let message = commonMessage;
        const countryFieldValue = form.getFieldValue('country')?.value ?? 'US';
        if (countryFieldValue === 'US') {
            regex = /^\d{5}(-\d{4})?$/;
            message = ' Please enter a valid US zip code.';
        }
        if (value && !regex.test(value)) {
            callback(message);
        }
        else {
            callback();
        }
    };
    const handleSubmit = () => {
        form.validateFields((err, values) => {
            if (!err) {
                if (!err) {
                    const payload = {
                        name: values.name,
                        type: props.addressType ? props.addressType : values.type,
                        street: values.street,
                        city: values.city,
                        state: values.state.key,
                        country: values.country.key,
                        zip_code: values.zip_code,
                        primary: values.primary,
                    };
                    if (values.primary) {
                        payload.type = 'Billing and Shipping';
                    }
                    setLoading(true);
                    props.handleSave(payload).finally(() => {
                        setLoading(false);
                        props.handleClose();
                    });
                }
            }
        });
    };
    const labelAddressName = props?.addressType
        ? `${props.addressType} To: (Full Name or Company)`
        : 'Full Name or Company';
    return (React.createElement(Form, { layout: "vertical" },
        React.createElement(Form.Item, { label: labelAddressName, ...formItem }, getFieldDecorator('name', {
            rules: [
                {
                    required: true,
                },
            ],
            initialValue: props.address?.name ?? props.defaultAddressName,
        })(React.createElement(Input, null))),
        React.createElement(Form.Item, { label: 'Search Address', ...formItem }, getFieldDecorator('full_address', {
            rules: [
                {
                    required: false,
                },
            ],
            initialValue: props.address?.street,
        })(React.createElement(AutoCompleteAddressInput, { onAutoFill: addressItem => {
                setFieldsValue({
                    street: addressItem.street,
                    city: addressItem.city,
                    state: addressItem.state,
                    country: addressItem.country,
                    zip_code: addressItem.zip_code,
                });
            } }))),
        React.createElement(Form.Item, { label: 'Street', ...formItem, className: 'mt-4' }, getFieldDecorator('street', {
            rules: [
                {
                    required: true,
                },
            ],
            initialValue: props.address?.street,
        })(React.createElement(Input, null))),
        React.createElement(Form.Item, { label: 'City', ...formItem }, getFieldDecorator('city', {
            rules: [
                {
                    required: true,
                },
            ],
            initialValue: props.address?.city,
        })(React.createElement(Input, null))),
        React.createElement("div", { className: "flex flex-row" },
            React.createElement("div", { className: 'w-full md:w-1/2' },
                React.createElement(Form.Item, { label: 'Country', ...formItem }, getFieldDecorator('country', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: props.address?.country
                        ? {
                            key: props.address?.country.code,
                            label: props.address?.country.name,
                        }
                        : {
                            key: 'US',
                            label: 'United States',
                        },
                })(React.createElement(Countries, null)))),
            React.createElement("div", { className: 'w-full md:w-1/2' },
                React.createElement(Form.Item, { label: 'State', ...formItem }, getFieldDecorator('state', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: props.address?.state
                        ? {
                            key: props?.address.state,
                            label: props?.address.state,
                        }
                        : {},
                })(React.createElement(States, { countryShortCode: getFieldValue('country')?.key }))))),
        React.createElement("div", { className: "grid grid-cols-2 gap-4" },
            React.createElement(Form.Item, { label: 'Zip Code', ...formItem }, getFieldDecorator('zip_code', {
                rules: [
                    {
                        required: true,
                        min: 5,
                    },
                    {
                        validator: validateZipCode,
                    },
                ],
                initialValue: props.address?.zip_code,
            })(React.createElement(Input, null))),
            !props.addressType && (React.createElement(Form.Item, { label: 'Type', ...formItem }, getFieldDecorator('type', {
                rules: [
                    {
                        required: true,
                    },
                ],
                initialValue: props.address?.type,
            })(React.createElement(Radio.Group, { size: 'small' },
                React.createElement(Radio, { style: radioStyle, value: 'Billing' }, "Billing"),
                React.createElement(Radio, { style: radioStyle, value: 'Shipping' }, "Shipping"),
                React.createElement(Radio, { style: radioStyle, value: 'Billing and Shipping' }, "Billing and Shipping")))))),
        React.createElement("div", { className: "grid grid-cols-1 gap-4" },
            React.createElement(Form.Item, null, getFieldDecorator('primary', {
                rules: [
                    {
                        required: false,
                    },
                ],
                valuePropName: 'checked',
                initialValue: props.address?.primary,
            })(React.createElement(Checkbox, null, "Make this my default address")))),
        React.createElement("div", { className: "flex flex-col p-4 space-y-2", style: {
                borderTop: 'solid 1px #d9d9d9',
            } },
            React.createElement(Button, { onClick: handleSubmit, type: "primary", loading: loading }, props.address?.id ? 'Update Address' : 'Create New Address'),
            React.createElement(Button, { type: "link", loading: loading, onClick: () => props.handleClose() }, "Cancel"))));
};
export const AddressForm = Form.create()(AddressFormComponent);
