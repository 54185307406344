import React, { useState } from 'react';
import { Col, Input, Row } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
import Part from './Part';
import _ from 'lodash';
import QuotedQuantity from './QuotedQuantity';
export const QuotedLineItems = (props) => {
    const [query, setQuery] = useState('');
    const { available_quoted_line_items } = SalesOrderStore;
    const filtered_quoted_line_items = query
        ? available_quoted_line_items.filter((item) => item.name.toLowerCase().includes(query.toString()))
        : available_quoted_line_items;
    const grouped_items_by_master_product = _.groupBy(filtered_quoted_line_items, item => {
        return item.product.master_product;
    });
    return (React.createElement("div", null,
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Input.Search, { placeholder: 'Search', onChange: e => {
                        setQuery(e.currentTarget.value);
                    } }))),
        Object.keys(grouped_items_by_master_product).map(key => {
            const quoted_line_items = grouped_items_by_master_product[key];
            // get the first element as header
            const fist_quoted_line_item = grouped_items_by_master_product[key][0];
            const formatted_quoted_items = quoted_line_items.map(quoted_item => {
                return {
                    id: quoted_item.id,
                    quote_has_product: quoted_item,
                    quantity: quoted_item.quantity,
                    unit_price: quoted_item.unit_price,
                    total_price: quoted_item.total_price,
                };
            });
            return (React.createElement("div", { className: 'mt-4', key: key },
                React.createElement("div", { className: "part" },
                    React.createElement(Part, { part: {
                            name: fist_quoted_line_item.name,
                            dimensions: fist_quoted_line_item.product?.dimensions,
                            coating: fist_quoted_line_item.product?.coating_related?.name,
                            finish: fist_quoted_line_item.product?.finish_related?.name,
                            material: fist_quoted_line_item.product?.material_related?.name,
                        } })),
                React.createElement("div", { className: 'quotes mt-2' },
                    React.createElement("table", { className: "table-layout: auto; w-full" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: "ant-table-cell" }, "Qty"),
                                React.createElement("th", { className: "ant-table-cell" }, "Unit Price"),
                                React.createElement("th", { className: "ant-table-cell" }, "Total Price"),
                                React.createElement("th", { className: "ant-table-cell" }, "Actions"))),
                        React.createElement("tbody", { className: "ant-table-tbody" }, formatted_quoted_items.map(formatted_item => {
                            return (React.createElement("tr", { key: formatted_item.quantity },
                                React.createElement(QuotedQuantity, { quantity: formatted_item.quantity, unit_price: formatted_item.unit_price, total_price: formatted_item.total_price, onClick: async () => {
                                        await props.handleAddItem({
                                            id: formatted_item.id,
                                            quote_has_product: formatted_item.quote_has_product,
                                            quantity: formatted_item.quantity,
                                            unit_price: formatted_item.unit_price,
                                            total_price: formatted_item.total_price,
                                        });
                                    } })));
                        }))))));
        })));
};
