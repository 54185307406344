import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, message, Spin, Card } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import PointPersonChecklistQuestion from './PointPersonChecklistQuestion';
import ModalMeeting from './ModalMeeting';
import { buildJsonBigOrderChecklist } from '../../utils/utils';
const cols = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
};
const PointPersonChecklist = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [checklistQuestions, setChecklistQuestions] = useState([]);
    const [checklistEdit, setChecklistEdit] = useState();
    const [handleCloseModalMeeting, setHandleCloseModalMeeting] = useState(false);
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    useEffect(() => {
        getPointPersonChecklist();
    }, []);
    const getPointPersonChecklist = () => {
        setLoading(true);
        SalesOrderStore.getChecklistType(props.sales_order_id, props.checklistName)
            .then(response => {
            setChecklistEdit(response.checklist_edit);
            if (Object.keys(response.checklist_edit).length > 0) {
                setChecklistQuestions(response.checklist_edit);
            }
            else {
                setChecklistQuestions(response.checklist);
            }
        })
            .catch(error => {
            message.error(error.message);
            console.error(error);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const handleCloseModal = () => {
        setHandleCloseModalMeeting(false);
    };
    const handleSubmit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                try {
                    setLoadingSubmit(true);
                    const params = {
                        checklist: buildJsonBigOrderChecklist(values, checklistQuestions),
                        type: 'point_person_review',
                    };
                    SalesOrderStore.updateSalesOrderChecklist(props.sales_order_id, params)
                        .then(response => {
                        message.success('Checklist saved ok', 3);
                        props.handleClose();
                        SalesOrderStore.getOrderById(props.sales_order_id);
                    })
                        .catch(error => {
                        message.error(error.message, 5);
                    })
                        .finally(() => {
                        setLoadingSubmit(false);
                    });
                }
                catch (error) {
                    console.error(error);
                    message.error(error.message, 5);
                }
            }
        });
    };
    return (React.createElement("div", { id: "02_div_point_person_checklist", className: "absolute z-10 h-96 border-solid border-2 overflow-y-auto w-1/3 pb-4" },
        React.createElement(Card, null,
            React.createElement("div", { id: "02_div_point_person_checklistheader", className: "flex justify-between cursor-move z-10 bg-blue-500 h-12 p-2 sticky top-0" },
                React.createElement("h1", { className: "text-left text-lg" }, props.title),
                React.createElement(Button, { type: "link", className: "text-white", onClick: () => {
                        props.handleClose();
                    } }, "X")),
            React.createElement(Spin, { spinning: loading },
                React.createElement(Form, { className: "lg:w-1/1 sm:1/1 mx-4 my-4" },
                    React.createElement(Row, { gutter: 4 },
                        React.createElement(Col, { ...cols },
                            React.createElement("h1", { className: "font-bold" }, "Topic")),
                        React.createElement(Col, { ...cols },
                            React.createElement("h1", { className: "font-bold" }, "Answer"))),
                    checklistEdit &&
                        Array.isArray(checklistQuestions) &&
                        checklistQuestions.map((question) => {
                            const key = `${question.question_id}`;
                            return (React.createElement(PointPersonChecklistQuestion, { key: key, getFieldDecorator: getFieldDecorator, question: question, value: '', onChange: (e) => { }, cols: cols, checklistEdit: checklistEdit, form: props.form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions }));
                        }),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement("div", { style: { textAlign: 'center' } },
                            React.createElement(Button, { type: "primary", htmlType: 'submit', loading: loadingSubmit, onClick: handleSubmit }, "Submit"),
                            React.createElement(Button, { type: "primary", loading: false, className: "ml-1", onClick: () => {
                                    setHandleCloseModalMeeting(true);
                                } }, "Trigger Meeting")))),
                handleCloseModalMeeting && (React.createElement(ModalMeeting
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                , { 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    handleClose: handleCloseModal, sales_order_id: props.sales_order_id, type_review: 'point_person_review', handleSubmit: handleSubmit }))))));
};
export default Form.create()(PointPersonChecklist);
