import React from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { useDrag } from 'react-dnd';
const style = {
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
    height: 'auto',
    border: '1px #000 dashed',
};
export const PartItem = ({ index, file, type, isDropped, handleRemove }) => {
    const [collected, drag] = useDrag(() => ({
        type,
        item: { index, file, type },
    }));
    return (React.createElement("div", { className: 'draggable-item', ref: drag, style: { ...style } }, isDropped ? (React.createElement("span", null)) : (React.createElement("div", { className: "flex flex-row" },
        React.createElement(DragOutlined, { className: "ml-auto" }),
        React.createElement("div", { className: "mx-2" }, file?.name),
        React.createElement(LegacyIcon, { title: 'Remove file', className: "mr-auto cursor-pointer", onClick: handleRemove && handleRemove, type: 'delete' })))));
};
