import React from 'react';
import { observer } from 'mobx-react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Input, message, Result, Checkbox, Form, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import api from '../Mobile/utils/api';
import { FormErrorParser } from '../ErrorRender';
export const Mobile = observer(({ ...props }) => {
    const [loading, setLoading] = React.useState(false);
    const [noficated, setNotificated] = React.useState(props.contact ? props.contact?.sms_notifications : false);
    const [verified, setVerified] = React.useState(props.contact ? props.contact?.sms_verified : false);
    const [verify, setVerify] = React.useState(false);
    const [resend, setResend] = React.useState(false);
    const [mobile, setMobile] = React.useState(props.contact?.mobile ? props.contact?.mobile : null);
    const [responsetype, setResponsetype] = React.useState(null);
    const [receiveSms, setReceiveSms] = React.useState(false);
    const isSmsNotifications = props.contact?.sms_notifications;
    const [form] = Form.useForm();
    const info = 'I would like to receive SMS text message notifications when a quote is completed';
    const handleSubmit = e => {
        form.validateFields()
            .then(async (values) => {
            setLoading(true);
            await api.requests
                .post(`/customer/contacts/${props.contact?.id}/send-sms/`, { mobile: values.mobile })
                .then(data => {
                setResponsetype('success');
                setMobile(data.mobile);
                setNotificated(data.sms_notifications);
                setVerified(data.sms_verified);
                setLoading(false);
                form.resetFields();
                props.updateData && props.updateData();
            })
                .catch(error => {
                setResponsetype('error');
                setMobile(values.mobile);
                setLoading(false);
                form.resetFields();
            });
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const handleSubmitVerified = async () => {
        form.validateFields()
            .then(async (values) => {
            setLoading(true);
            await api.requests
                .post(`/customer/contacts/${props.contact?.id}/verify-sms/`, {
                mobile: mobile,
                code: values.verifycode,
            })
                .then(data => {
                form.resetFields();
                props.updateData && props.updateData();
                setReceiveSms(true);
                setVerify(true);
                setLoading(false);
                setNotificated(data.sms_notifications);
                setVerified(data.sms_verified);
                props.onChangeSmsNotification(true);
                if (data.detail === 'approved') {
                    message.success('Your mobile was registered successfully', 3);
                }
                else {
                    message.warning('Incorrect code, try again', 3);
                }
            })
                .catch(error => {
                form.resetFields();
                message.error('Incorrect code, Please resend a new code', 3);
                setLoading(false);
            });
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const handleResend = async () => {
        setResend(false);
        await api.requests
            .post(`/customer/contacts/${props.contact?.id}/send-sms/`, { mobile: mobile?.replace('+', '') })
            .catch(() => {
            message.error('Error');
        });
    };
    const handleDisableSms = async () => {
        try {
            const smsNotification = !isSmsNotifications;
            await api.requests.post(`/customer/contacts/${props.contact?.id}/disable-mobile/`, {
                sms: smsNotification,
            });
            props.onChangeSmsNotification(smsNotification);
            props.updateData && props.updateData();
        }
        catch (_error) {
            message.error('Error');
        }
    };
    const handleReceiveSMSNotification = () => {
        setReceiveSms(!receiveSms);
    };
    let response = React.createElement(React.Fragment, null);
    if (responsetype === 'success') {
        response = (React.createElement("span", null,
            React.createElement(CheckCircleOutlined, { style: { color: '#52c41a' } }),
            " We have sent a sms to ",
            mobile,
            " for verification."));
    }
    else if (responsetype === 'error') {
        response = (React.createElement("span", null,
            React.createElement(CloseCircleOutlined, { style: { color: 'red' } }),
            " ",
            mobile,
            " is unavailable."));
    }
    const errorMessage = null;
    let text = (React.createElement("span", { className: 'link', onClick: handleResend }, "Resend Verification"));
    if (resend) {
        text = React.createElement("span", null, "Sent");
    }
    return (React.createElement(React.Fragment, null,
        !props.contact?.sms_notifications &&
            !props.contact?.sms_verified &&
            !props.contact?.mobile &&
            !verified &&
            !noficated && (React.createElement(Checkbox, { checked: receiveSms, onChange: handleReceiveSMSNotification, className: "text-base" }, info)),
        ((props.contact?.sms_notifications && props.contact?.sms_verified) ||
            (!props.contact?.sms_notifications && props.contact?.sms_verified) ||
            (verified && noficated)) && (React.createElement(Checkbox, { checked: isSmsNotifications, onChange: handleDisableSms, className: "text-base" }, info)),
        !verified && !noficated && (responsetype != 'success' || verify === true) && (React.createElement(Form, { form: form, onFinish: handleSubmit, className: "mt-5" },
            React.createElement("p", { className: "text-base text-black" }, "Mobile Number"),
            React.createElement(Form.Item, { name: "mobile", rules: [
                    {
                        required: true,
                        message: 'Please input a Mobile number',
                    },
                ] },
                React.createElement(PhoneInput, { country: 'us', onlyCountries: ['us', 'ca'], disabled: !receiveSms, buttonStyle: { border: '1px solid #595959' }, inputStyle: {
                        minWidth: 300,
                        width: '26.354vw',
                        height: 50,
                        border: '1px solid #595959',
                    } })),
            React.createElement(Form.Item, null,
                React.createElement(Button, { loading: loading, htmlType: "submit", disabled: !receiveSms, size: "large", type: "primary", className: "text-lg font-normal", style: { width: '263px' } }, "Add Mobile Number")))),
        !verified && noficated && (React.createElement(React.Fragment, null,
            errorMessage,
            React.createElement(Form, { onFinish: handleSubmitVerified, form: form },
                response,
                React.createElement(Form.Item, { name: "verifycode", style: { width: 300 }, rules: [
                        {
                            required: true,
                            message: 'Please input a code verification',
                        },
                    ] },
                    React.createElement(Input, { maxLength: 4, placeholder: "Code verification" })),
                React.createElement(Form.Item, null,
                    React.createElement(Button, { loading: loading, htmlType: "submit" }, "Verify"))),
            mobile && React.createElement(React.Fragment, null, text))),
        verified && noficated && (React.createElement(Result, { status: "success", subTitle: "Congrats! Your mobile is registered and verified" }))));
});
