import React from 'react';
import { Card, Modal } from 'antd';
import { QuoteStore } from '../Stores';
import { observer } from 'mobx-react';
export const QuoteModalHistoryRequote = observer((props) => {
    function normalizeFieldName(fieldName) {
        const words = fieldName.split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    }
    const get_global_fields = () => {
        const fields = [];
        for (const field in props.changed_fields) {
            if (isNaN(Number(field))) {
                const object = props.changed_fields[field];
                let previous_value = object['previous_value'];
                let current_value = object['current_value'];
                if (previous_value === null) {
                    previous_value = 'No previous value';
                }
                if (current_value === null) {
                    current_value = 'No current value';
                }
                if (Array.isArray(previous_value) && previous_value.every(element => true)) {
                    previous_value = object['previous_value'].join(', ');
                }
                if (Array.isArray(current_value) && current_value.every(element => true)) {
                    current_value = object['current_value'].join(', ');
                }
                if (previous_value === true) {
                    previous_value = 'Yes';
                }
                else if (previous_value === false) {
                    previous_value = 'No';
                }
                if (current_value === true) {
                    current_value = 'Yes';
                }
                else if (current_value === false) {
                    current_value = 'No';
                }
                fields.push(React.createElement("div", null,
                    React.createElement("span", { className: 'mr-2' }, normalizeFieldName(field)),
                    "was updated from",
                    React.createElement("span", { className: 'mx-2 bold' },
                        previous_value,
                        " "),
                    "\u00A0 to",
                    React.createElement("span", { className: 'mx-2 bold ' }, current_value)));
            }
        }
        return fields;
    };
    const get_specific_fields = () => {
        const items = [];
        for (const field in props.changed_fields) {
            if (!isNaN(Number(field))) {
                const object = props.changed_fields[field];
                object['part_id'] = Number(field);
                items.push(object);
            }
        }
        QuoteStore.lineItems.forEach((item) => {
            const part = items.find((part) => part.part_id === item.id);
            if (part) {
                part.part_name = item.name;
            }
        });
        return items;
    };
    return (React.createElement(Modal, { open: true, title: "Changes requested for the Requote", width: 1200, onCancel: props.handleClose, footer: [], bodyStyle: {
            height: '80vh',
            overflowY: 'scroll',
        } },
        React.createElement(Card, { title: 'Quote - Global fields.' }, get_global_fields()),
        React.createElement(Card, { className: 'mt-4', title: 'Line Items - Specific fields.' }, get_specific_fields().map((obj, index) => (React.createElement(Card, { title: obj.part_name, key: index, className: 'mt-4', size: 'small' }, Object.entries(obj).map(([field, value]) => (React.createElement("div", { key: field }, field !== 'part_id' && field !== 'part_name' && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: 'mr-2' }, normalizeFieldName(field)),
            "was updated from",
            React.createElement("span", { className: 'mx-2 bold' },
                value.previous_value,
                " "),
            "\u00A0 to",
            React.createElement("span", { className: 'mx-2 bold ' }, value.current_value))))))))))));
});
export default QuoteModalHistoryRequote;
