import React from 'react';
import { Timeline } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
const RecuoteChanges = ({ master_product }) => {
    function normalizeFieldName(fieldName) {
        return fieldName
            .split('_')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' ');
    }
    const renderTimelineItems = () => {
        if (!master_product.changed_fields || typeof master_product.changed_fields !== 'object') {
            return React.createElement(Timeline.Item, null, "No changes recorded");
        }
        return Object.entries(master_product.changed_fields).map(([field, { previous_value, current_value }]) => (React.createElement(Timeline.Item, { dot: React.createElement(RetweetOutlined, null) },
            React.createElement("strong", null, normalizeFieldName(field)),
            " was changed from",
            ' ',
            React.createElement("strong", null, previous_value !== null ? previous_value.toString() : 'N/A'),
            " to",
            ' ',
            React.createElement("strong", null, current_value !== null ? current_value.toString() : 'N/A'))));
    };
    return (React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
        React.createElement("div", null,
            React.createElement("div", { className: 'flex justify-center font-bold text-lg' },
                "Changes in Requote Process ",
                master_product.name),
            React.createElement(Timeline, { className: "mt-5" }, renderTimelineItems()))));
};
export default RecuoteChanges;
