import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Col, Divider, Form, PageHeader, Row, Skeleton, Table, Tabs, Tag } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ContactStore } from '../../stores';
import { IQuoteListStaff, ISalesOrderListStaff } from '@partsbadger/types';
import { get } from '@partsbadger/utils';
import { ColumnProps } from 'antd/es/table';
import { DateRender, Price } from '@partsbadger/library';
import { BadgerPage, BadgerTable } from '../../components/badger-ui';

const ContactSalesOrders = (props: { contact_id: number }) => {
    const { contact_id } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<ISalesOrderListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/sales-orders/', {
            params: {
                contact: contact_id,
                limit: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [contact_id]);

    const columns: ColumnProps<ISalesOrderListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.name}</div>
                        <Tag>{record.status}</Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.account?.name}</div>
                        <div className={'italic'}>{record.contact.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Quotes',
            dataIndex: 'quotes',
            render: (text, record) => {
                return (
                    <div>
                        <div>
                            {record.quotes.map((quote, index) => {
                                return (
                                    <div key={index}>
                                        <div>{quote.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a
                            className={'cursor-pointer'}
                            onClick={() => history.push('/new-sales-order-detail/' + record.id)}
                        >
                            View
                        </a>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <Table<ISalesOrderListStaff>
                    rowKey={'id'}
                    dataSource={data}
                    loading={loading}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 'auto' }}
                ></Table>
            </Col>
        </Row>
    );
};

const ContactQuotes = (props: { contact_id: number }) => {
    const { contact_id } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IQuoteListStaff[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        setLoading(true);
        get('/staff/quotes/', {
            params: {
                contact: contact_id,
            },
        })
            .then(data => {
                setData(data.results);
                setTotalRecords(data.count);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [contact_id]);

    const columns: ColumnProps<IQuoteListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.name}</div>
                        <Tag>{record.stage}</Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.account?.name}</div>
                        <div className={'italic'}>{record.contact?.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/quotes/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <BadgerTable<IQuoteListStaff>
                    rowKey={'id'}
                    dataSource={data}
                    loading={loading}
                    columns={columns}
                    pagination={{
                        totalRecords: totalRecords,
                    }}
                ></BadgerTable>
            </Col>
        </Row>
    );
};

const ContactDetailPage: React.FC = (props, l) => {
    const history = useHistory();

    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        if (id) {
            ContactStore.getById(parseInt(id));
        }
    }, [id]);

    const contact = ContactStore.contact;

    if (!contact) {
        return <Skeleton />;
    }

    return (
        <BadgerPage title={contact.fullname} handleBack={() => history.goBack()}>
            <Card>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'Email'}>
                            <div>{contact.email} </div>
                        </Form.Item>
                        <Form.Item label={'Account'}>
                            <div>{contact.account.name} </div>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Phone'}> {contact.phone}</Form.Item>
                        <Form.Item label={'Tax Exempt States'}> {contact.tax_exempted_states.toString()}</Form.Item>
                    </Col>
                </Row>
            </Card>

            <br />

            <Card>
                <Row>
                    <Col span={24}>
                        <Tabs>
                            <Tabs.TabPane tab={`Quotes(${contact.quotes_count})`} key="quotes">
                                <Col span={24}>
                                    <ContactQuotes contact_id={contact.id} />
                                </Col>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={`Orders(${contact.sales_orders_count})`} key="orders">
                                <Col span={24}>
                                    <ContactSalesOrders contact_id={contact.id} />
                                </Col>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Card>
        </BadgerPage>
    );
};

export default observer(ContactDetailPage);
