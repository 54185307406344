import React, { useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, DatePicker, Divider, Input, Select, Button, message, Tooltip } from 'antd';
import { GenericSelect, ShippingCarriersPurchasesOrders, ShippingMethodsPurchasesOrders } from '@partsbadger/library';
import moment from 'moment';
import { hasGroup } from 'quotes-frontend/src/components/User';
import { observer } from 'mobx-react';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import { QuestionCircleOutlined } from '@ant-design/icons';
export const InputsInformationForm = observer((props) => {
    const { getFieldDecorator, purchase_order, change_address, setChangeAddress, onSalesOrderChange, sales_order, setZXLineItems, isFieldTouched, } = props;
    const address = e => {
        setChangeAddress(e.target.checked);
    };
    useEffect(() => {
        const getUniqueSalesOrder = PurchaseOrderStore.hasUniqueSalesOrderId();
        if (getUniqueSalesOrder) {
            props.setFieldsValue({
                sales_order: {
                    key: getUniqueSalesOrder.soId,
                    label: getUniqueSalesOrder.label,
                },
            });
        }
        else if (!purchase_order.sales_order) {
            if (isFieldTouched('sales_order')) {
                message.error('Unable to select this order because some sales order line items either have different sales order IDs or are empty.', 10);
            }
            props.setFieldsValue({
                sales_order: null,
            });
        }
        const lineItems = PurchaseOrderStore.line_items;
        if (lineItems?.length > 0) {
            const salesOrders = [];
            lineItems.map(item => {
                if (item.sales_order_line_items.length > 0) {
                    item.sales_order_line_items.map(soli => {
                        if (soli.sales_order) {
                            salesOrders.push({
                                key: soli.sales_order.id,
                                label: soli.sales_order.name,
                            });
                        }
                    });
                }
            });
            const uniqueObjects = Array.from(salesOrders
                .reduce((acc, item) => {
                if (!acc.has(item.label)) {
                    acc.set(item.label, item);
                }
                return acc;
            }, new Map())
                .values());
            props.setFieldsValue({
                sales_orders: uniqueObjects,
            });
        }
    }, [PurchaseOrderStore.line_items?.length]);
    useEffect(() => {
        props.setFieldsValue({
            sales_orders: undefined,
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-center" },
            React.createElement("div", { className: 'w-1/4' },
                React.createElement(Divider, { className: 'font-bold' }, "Billing Addresses"),
                React.createElement("div", { className: 'p-4' },
                    React.createElement(Form.Item, { label: 'Billing Street' }, getFieldDecorator('billing_street', {
                        initialValue: purchase_order ? purchase_order.billing_street : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Billing City' }, getFieldDecorator('billing_city', {
                        initialValue: purchase_order ? purchase_order.billing_city : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Billing State' }, getFieldDecorator('billing_state', {
                        initialValue: purchase_order ? purchase_order.billing_state : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Billing Code' }, getFieldDecorator('billing_code', {
                        initialValue: purchase_order ? purchase_order.billing_zipcode : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Billing Country' }, getFieldDecorator('billing_country', {
                        initialValue: purchase_order ? purchase_order.billing_country : '',
                    })(React.createElement(Input, null))),
                    !purchase_order?.id && (React.createElement(Form.Item, null, getFieldDecorator('change_address', {
                        initialValue: purchase_order ? purchase_order.address : change_address,
                    })(React.createElement(Checkbox, { onClick: e => address(e), checked: change_address }, "Use the same address for shipping.")))))),
            !change_address && (React.createElement("div", { className: 'w-1/4' },
                React.createElement(Divider, { className: 'font-bold' }, "Shipping Addresses"),
                React.createElement("div", { className: 'p-4' },
                    React.createElement(Form.Item, { label: 'Shipping Street' }, getFieldDecorator('shipping_street', {
                        initialValue: purchase_order ? purchase_order.shipping_street : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Shipping City' }, getFieldDecorator('shipping_city', {
                        initialValue: purchase_order ? purchase_order.shipping_city : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Shipping State' }, getFieldDecorator('shipping_state', {
                        initialValue: purchase_order ? purchase_order.shipping_state : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Shipping Code' }, getFieldDecorator('shipping_code', {
                        initialValue: purchase_order ? purchase_order.shipping_zipcode : '',
                    })(React.createElement(Input, null))),
                    React.createElement(Form.Item, { label: 'Shipping Country' }, getFieldDecorator('shipping_country', {
                        initialValue: purchase_order ? purchase_order.shipping_country : '',
                    })(React.createElement(Input, null)))))),
            React.createElement("div", { className: 'w-2/4' },
                React.createElement(Divider, { className: 'font-bold' }, "Purchase Order Information"),
                React.createElement("div", { className: 'flex flex-row' },
                    React.createElement("div", { className: 'w-1/2 p-4' },
                        React.createElement(Form.Item, { label: React.createElement(React.Fragment, null,
                                React.createElement(Tooltip, { title: "This Sales Order field is set automatically based on the Sales Order Line Items selected in the Purchase Order Line Items below." },
                                    React.createElement("span", null, "Sales Order"),
                                    React.createElement("div", { className: "inline-block" },
                                        React.createElement(QuestionCircleOutlined, { className: "ml-1 align-middle", style: {
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                color: '#08c',
                                            } })))) },
                            getFieldDecorator('sales_orders', {
                                initialValue: purchase_order && purchase_order.sales_orders
                                    ? purchase_order.sales_orders.map(s => {
                                        return {
                                            key: s.id,
                                            label: s.name,
                                        };
                                    })
                                    : [],
                            })(React.createElement(GenericSelect, { disabled: true, mode: 'multiple', onChange: value => {
                                    onSalesOrderChange(value !== undefined ? value.key : '');
                                }, recordType: 'SalesOrders' })),
                            sales_order?.domestic_process_needed | sales_order?.hardware_required ? (React.createElement(Button, { onClick: () => {
                                    setZXLineItems();
                                }, type: "link" }, "Add the associated X or Z products")) : ('')),
                        React.createElement(Form.Item, { label: 'Internal Reference', className: "px-1" }, getFieldDecorator('internal_reference', {
                            initialValue: purchase_order ? purchase_order.internal_reference : '',
                        })(React.createElement(Input.TextArea, { autoSize: true }))),
                        React.createElement(Form.Item, { label: 'Requisition Number', help: 'This is the quote number from the vendor' }, getFieldDecorator('requisition_number', {
                            initialValue: purchase_order ? purchase_order.requisition_number : '',
                        })(React.createElement(Input, { style: { width: '100%' } }))),
                        React.createElement(Form.Item, { label: 'Carrier', className: "px-1" }, getFieldDecorator('carrier', {
                            initialValue: purchase_order ? purchase_order.carrier : 'FedEX',
                        })(React.createElement(ShippingCarriersPurchasesOrders, { size: "middle" }))),
                        React.createElement(Form.Item, { label: 'PB Point of Contact', className: "px-1" }, getFieldDecorator('pb_point_of_contact', {
                            initialValue: purchase_order ? purchase_order.pb_point_of_contact : '',
                        })(React.createElement(Input, null))),
                        React.createElement(Form.Item, { label: 'Notes', className: "px-1" }, getFieldDecorator('notes', {
                            initialValue: purchase_order ? purchase_order.notes : '',
                        })(React.createElement(Input.TextArea, { autoSize: true })))),
                    React.createElement("div", { className: 'w-1/2 p-4' },
                        React.createElement(Form.Item, { label: 'Vendor' }, getFieldDecorator('vendor', {
                            rules: [{ required: true, message: 'Vendor is required!' }],
                            initialValue: purchase_order && typeof purchase_order.vendor === 'object'
                                ? {
                                    key: purchase_order?.vendor?.id,
                                    label: purchase_order?.vendor?.name,
                                }
                                : '',
                        })(React.createElement(GenericSelect, { recordType: 'Vendors', filters: {
                                in_quickbooks: true,
                            } }))),
                        React.createElement(Form.Item, { label: 'Due Date' }, getFieldDecorator('due_date', {
                            rules: [{ required: true }],
                            initialValue: purchase_order ? moment(purchase_order.due_date) : moment(new Date()),
                        })(React.createElement(DatePicker, { style: { width: '100%' } }))),
                        React.createElement(Form.Item, { label: 'Shipping Method' }, getFieldDecorator('shipping_method', {
                            initialValue: purchase_order ? purchase_order.ship_method : 'Ground',
                        })(React.createElement(ShippingMethodsPurchasesOrders, { size: 'middle' }))),
                        React.createElement(Form.Item, { label: 'Payment Terms' }, getFieldDecorator('payment_terms', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: purchase_order ? purchase_order.payment_terms : 'NET30',
                        })(React.createElement(Select, { showSearch: true, style: {
                                width: '100%',
                            } },
                            React.createElement(Select.Option, { value: "NET 10" }, "NET 10"),
                            React.createElement(Select.Option, { value: "NET 15" }, "NET 15"),
                            React.createElement(Select.Option, { value: "NET 30" }, "NET 30"),
                            React.createElement(Select.Option, { value: "NET 45" }, "NET 45"),
                            React.createElement(Select.Option, { value: "NET 60" }, "NET 60"),
                            React.createElement(Select.Option, { value: "NET 75" }, "NET 75"),
                            React.createElement(Select.Option, { value: "None" }, "NONE"),
                            React.createElement(Select.Option, { value: "CC" }, "CC")))),
                        React.createElement(Form.Item, { label: 'Category' }, getFieldDecorator('category', {
                            rules: [{ required: true, message: 'Please select a value!' }],
                            initialValue: purchase_order && purchase_order.category,
                        })(React.createElement(Select, { showSearch: true, style: {
                                width: '100%',
                            }, onSelect: props.setCategory },
                            hasGroup('PartsBadger Production') === false && (React.createElement(Select.Option, { value: "PartsBadger, LLC" }, "PartsBadger, LLC")),
                            React.createElement(Select.Option, { value: "PartsBadger Production" }, "PartsBadger Production"),
                            React.createElement(Select.Option, { value: "Yeti" }, "Yeti"))))))))));
});
