//@ts-nocheck
import React from 'react';
import { Button, Col, Divider, Row, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { QuoteStore } from '../Stores';
import { get } from '@partsbadger/utils';
let busy = null;
class AddExistingQuotedProduct extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false,
            quoted_products: [],
            selectedItems: [],
        };
        this.getQuotedProducts = name => {
            this.setState({
                loading: true,
            });
            if (busy) {
                clearTimeout(busy);
            }
            const params = {
                search: name,
                quote__account: QuoteStore.quote.account.id,
                exclude_quote: QuoteStore.quote.id,
                product__master_product__quotable: true,
            };
            busy = setTimeout(() => {
                get('/staff/quoted-products/', {
                    params: params,
                })
                    .then(response => {
                    this.setState({
                        quoted_products: response.results,
                        loading: false,
                    });
                })
                    .catch(error => alert(error));
            }, 300);
        };
        this.handleChange = selectedItems => {
            this.setState({ selectedItems });
        };
    }
    componentDidMount() {
        this.getQuotedProducts('');
    }
    render() {
        const { selectedItems } = this.state;
        const filteredOptions = this.state.quoted_products.filter(o => !this.state.selectedItems.includes(o));
        return (React.createElement(Row, { style: { minWidth: '200px', padding: 20 } },
            React.createElement(Col, { md: 20 },
                React.createElement(Select, { allowClear: true, placeholder: "Search products by name", notFoundContent: this.state.loading ? React.createElement(Spin, { size: "small" }) : React.createElement("span", null, "No results found"), filterOption: false, value: selectedItems, mode: "multiple", onSearch: name => {
                        this.getQuotedProducts(name);
                    }, onChange: this.handleChange, style: { width: '100%' } }, filteredOptions.map(d => (React.createElement(Select.Option, { key: d.id, value: d.id },
                    React.createElement(Tooltip, { placement: "left", title: () => {
                            return (React.createElement("div", null,
                                "Sides: ",
                                d.product.sides,
                                React.createElement("br", null),
                                "Taps: ",
                                d.product.taps,
                                React.createElement("br", null),
                                "Finish: ",
                                d.product?.finishes?.map(r => r.name),
                                React.createElement("br", null),
                                "Coating: ",
                                d.product?.coatings?.map(r => r.name),
                                React.createElement("br", null),
                                "LT: ",
                                d.lead_time,
                                React.createElement("br", null)));
                        } },
                        React.createElement("div", { style: { fontSize: '12px' } },
                            React.createElement("strong", null,
                                d?.product?.master_product?.name,
                                " "),
                            React.createElement("b", null, d.name),
                            React.createElement(Divider, { type: 'vertical' }),
                            d.product.material?.name,
                            React.createElement(Divider, { type: 'vertical' }),
                            "Qty: ",
                            d.quantity,
                            React.createElement(Divider, { type: 'vertical' }),
                            "Adj: % ",
                            d.manual_adjustment,
                            React.createElement(Divider, { type: 'vertical' }),
                            moment([d.expiration_date]).fromNow()))))))),
            React.createElement(Col, { md: 4 },
                React.createElement(Button, { type: 'primary', onClick: () => {
                        QuoteStore.createLineItemFromExistingLineItems(selectedItems);
                        this.setState({
                            selectedItems: [],
                        });
                    } }, "Add"))));
    }
}
export default AddExistingQuotedProduct;
