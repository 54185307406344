import { message } from 'antd';
import axios from 'axios';
import { API_ROOT } from '@partsbadger/utils';
const handleErrors = error => {
    //need good error handling
    // Need throw the error to handle error events on the page were is called
    if (!error?.response) {
        message.error(error?.message ? error?.message : 'Error');
    }
    else if (error?.response?.status === 500) {
        message.error(error?.message ? error?.message : 'Error');
    }
    throw error;
};
const responseBody = resp => {
    // right now both successful/unsuccessful calls go to the caller
    // routing them to a single component would be ideal to show errors
    // to the user
    return resp.data;
};
function getHeader(has_files = false) {
    const token = localStorage.getItem('token');
    if (token) {
        return {
            'Content-Type': has_files ? 'multipart/form-data' : 'application/json',
            Authorization: `Token ${token}`,
        };
    }
    else {
        return {
            'Content-Type': has_files ? 'multipart/form-data' : 'application/json',
        };
    }
}
const requests = {
    get: (url, options = {}) => axios
        .get(`${API_ROOT}${url}`, {
        headers: getHeader(),
        ...options,
    })
        .then(responseBody)
        .catch(handleErrors),
    post: (url, data, options = {}, has_files = false) => axios
        .post(`${API_ROOT}${url}`, data, {
        headers: getHeader(has_files),
        ...options,
    })
        .then(responseBody)
        .catch(handleErrors),
    patch: (url, data, options = {}, has_files = false) => axios
        .patch(`${API_ROOT}${url}`, data, {
        headers: getHeader(has_files),
    })
        .then(responseBody)
        .catch(handleErrors),
    delete: url => axios
        .delete(`${API_ROOT}${url}`, {
        headers: getHeader(),
    })
        .then(responseBody)
        .catch(handleErrors),
};
export default {
    API_ROOT,
    requests,
};
