import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
const style = {
    minHeight: '4rem',
    width: '100%',
    marginRight: '1.5rem',
    padding: '1rem',
    fontSize: '1rem',
    lineHeight: 'normal',
    color: 'rgba(0, 0, 0, 0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
export const PartSlot = ({ accept, newRecords, lastDroppedItem, onDrop, onDropNew }) => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: [...accept, NativeTypes.FILE],
        drop: (item) => {
            if (item?.index >= 0) {
                onDrop(item);
            }
            else {
                if (item['files']?.length) {
                    onDropNew(item['files']);
                }
            }
            return null;
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [newRecords]);
    const isActive = isOver && canDrop;
    const activeStyles = isActive
        ? {
            border: '1px #d92923 dashed',
        }
        : {
            border: '1px #d9d9d9 solid',
        };
    return (React.createElement("div", { ref: drop, style: { ...style, ...activeStyles } }, lastDroppedItem ? (React.createElement("div", { title: 'Drag and Drop to reorder' }, lastDroppedItem)) : (React.createElement("div", { title: 'Drop a file here ', style: {
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.24)',
        } }, "No files"))));
};
