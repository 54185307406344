import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber, message, Select } from 'antd';
import { Coatings, Finishes, Formulas, HoleTolerances, ManufacturingTypes, PartTolerances, SelectReinforcement, } from '../Select';
import React, { useState } from 'react';
import { QuoteStore } from '../../Stores';
import { Materials } from '../Select/Materials';
import { observer } from 'mobx-react';
const { Option } = Select;
const InputType = (props) => {
    const { dataIndex } = props;
    const user = QuoteStore.currentUser;
    let part_vol = 0;
    let total_vol = 0;
    const isATesterUser = user?.is_tester;
    const [boolean, setBoolean] = useState(false);
    const payload = {
        product: {},
    };
    const updateLineItems = () => {
        const items = QuoteStore.lineItems.filter(item => item.product.master_product === props.record.product.master_product);
        QuoteStore.updateMultipleLineItems({
            quoted_products: items.map(item => item.id),
            data: payload,
        });
    };
    switch (dataIndex) {
        case 'manufacturing_type':
            return (React.createElement(React.Fragment, null,
                React.createElement(ManufacturingTypes, { ...props, onSelect: item => {
                        QuoteStore.getMaterials({
                            manufacturing_type: item,
                        });
                    } }),
                React.createElement("a", { onClick: () => {
                        payload['manufacturing_type'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'reinforcement':
            return React.createElement(SelectReinforcement, { ...props });
        case 'product__material':
            return (React.createElement(React.Fragment, null,
                React.createElement(Materials, { materials: QuoteStore.materials.map(m => {
                        return {
                            id: m.id,
                            name: m.name,
                        };
                    }), handleSearch: params => QuoteStore.getMaterials(params), style: { margin: '20px' }, suggestions: props.record.product.drawing_file?.suggestions
                        ? props.record.product.drawing_file.suggestions
                            .filter(s => s.suggested_material?.id)
                            .map(s => {
                            return {
                                id: s.suggested_material?.id,
                                name: s.suggested_material?.name,
                                thumbnail: s.cropped_image?.file,
                            };
                        })
                        : [], ...props }),
                props.record.product.material?.name.toLowerCase() === 'custom' ? (React.createElement("div", null, props.record.product.custom_material)) : (''),
                React.createElement("a", { onClick: () => {
                        payload['product']['material'] = props.defaultValue ? props.defaultValue.key : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'in_house_manufacturing':
            return React.createElement(Checkbox, { style: { margin: 'auto' }, ...props });
        case 'fixed_unit_price':
            return React.createElement(Checkbox, { ...props });
        case 'product__hole_tolerance':
            return (React.createElement(React.Fragment, null,
                React.createElement(HoleTolerances, { ...props }),
                React.createElement("a", { onClick: () => {
                        payload['product']['hole_tolerance'] = props.defaultValue ? props.defaultValue.key : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'product__part_tolerance':
            return (React.createElement(React.Fragment, null,
                React.createElement(PartTolerances, { ...props }),
                React.createElement("a", { onClick: () => {
                        payload['product']['part_tolerance'] = props.defaultValue ? props.defaultValue.key : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'product__coatings':
            return (React.createElement(React.Fragment, null,
                React.createElement(Coatings, { coatings: QuoteStore.coatings.map(m => {
                        return {
                            id: m.id,
                            name: m.name,
                            needs_manual_adjust: m.needs_manual_adjust,
                        };
                    }), handleSearch: params => QuoteStore.getCoatings(params), suggestions: props.record.product.drawing_file?.suggestions
                        ? props.record.product.drawing_file.suggestions
                            .filter(s => s.suggested_coating?.id)
                            .map(s => {
                            return {
                                id: s.suggested_coating?.id,
                                name: s.suggested_coating?.name,
                                thumbnail: s.cropped_image?.file,
                            };
                        })
                        : [], ...props }),
                props.record.product.coating?.name.toLowerCase() === '* custom' ? (React.createElement("div", null, props.record.product.custom_coating)) : (''),
                React.createElement("a", { onClick: () => {
                        payload['product']['coatings'] = props.defaultValue ? [props.defaultValue.key] : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'product__finishes':
            return (React.createElement(React.Fragment, null,
                React.createElement(Finishes, { finishes: QuoteStore.finishes.map(m => {
                        return {
                            id: m.id,
                            name: m.name,
                            metric_name: m.metric_name,
                            needs_manual_adjust: m.needs_manual_adjust,
                        };
                    }), handleSearch: params => QuoteStore.getFinishes(params), suggestions: props.record.product.drawing_file?.suggestions
                        ? props.record.product.drawing_file.suggestions
                            .filter(s => s.suggested_finish?.id)
                            .map(s => {
                            return {
                                id: s.suggested_finish?.id,
                                name: s.suggested_finish?.name,
                                thumbnail: s.cropped_image?.file,
                            };
                        })
                        : [], ...props }),
                props.record.product.finish?.name.toLowerCase() === '* custom' ? (React.createElement("div", null, props.record.product.custom_finish)) : (''),
                React.createElement("a", { onClick: () => {
                        payload['product']['finishes'] = props.defaultValue ? [props.defaultValue.key] : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'formula':
            if (props.record) {
                const mp = QuoteStore.masterProductsByPosition?.find(mp => mp?.master_product?.id === props.record?.product?.master_product);
                if (mp) {
                    total_vol = mp.master_product.total_volume_inches;
                    part_vol = mp.master_product.part_volume_inches;
                }
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(Formulas, { item: {
                        totalVol: total_vol,
                        partVol: part_vol,
                        has_file_3d: props.record.product.master_product.has_file_3d,
                        analysis_status_v2: props.record.product.step_file?.analysis_status_v2,
                    }, selectedMaterialName: props.record.product.material?.name, ...props }),
                React.createElement("a", { onClick: () => {
                        payload['formula'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'has_material_cert':
            return (React.createElement(React.Fragment, null,
                React.createElement(Checkbox, { style: { margin: 'auto' }, ...props, onChange: event => {
                        props?.onChange(event.target.checked);
                        setBoolean(event.target.checked);
                    } }),
                React.createElement("a", { onClick: () => {
                        payload['has_material_cert'] = boolean;
                        updateLineItems();
                    } }, "Save for all")));
        case 'is_fai_required':
            return (React.createElement(React.Fragment, null,
                React.createElement(Checkbox, { style: { margin: 'auto' }, ...props, onChange: event => {
                        props.onChange(event.target.checked);
                        setBoolean(event.target.checked);
                    } }),
                React.createElement("a", { onClick: () => {
                        payload['is_fai_required'] = boolean;
                        updateLineItems();
                    } }, "Save for all")));
        case 'fast_lane':
            return (React.createElement(React.Fragment, null,
                React.createElement(Select, { ...props },
                    React.createElement(Select.Option, { value: 'Yes' }, "Yes"),
                    React.createElement(Select.Option, { value: 'No' }, "No"),
                    React.createElement(Select.Option, { value: 'Considered' }, "Considered")),
                React.createElement("a", { onClick: () => {
                        payload['fast_lane'] = props.defaultValue;
                        updateLineItems();
                    } }, "Save for all")));
        case 'partsbadger_production_method':
            return (React.createElement(React.Fragment, null,
                React.createElement(Select, { ...props },
                    React.createElement(Select.Option, { value: 'Fastlane' }, "Fastlane"),
                    React.createElement(Select.Option, { value: 'QuickTurn' }, "QuickTurn"),
                    React.createElement(Select.Option, { value: 'Swiss' }, "Swiss"),
                    React.createElement(Select.Option, { value: 'Traditional' }, "Traditional"),
                    React.createElement(Select.Option, { value: 'No' }, "No")),
                React.createElement("a", { onClick: () => {
                        payload['partsbadger_production_method'] = props.defaultValue;
                        payload['partsbadger_production'] = false;
                        if (props.defaultValue === 'Fastlane') {
                            payload['partsbadger_production'] = true;
                            payload['partsbadger_production_method'] = props.defaultValue;
                        }
                        updateLineItems();
                    } }, "Save for all")));
        case 'lead_time':
            return (React.createElement("div", { className: "input-container", style: { display: 'flex', alignItems: 'center' } },
                React.createElement(InputNumber, { ...props, value: props.record.lead_time }),
                React.createElement(Button, { onClick: async () => {
                        try {
                            const lead_time = await QuoteStore.updateLT(props.record);
                            props.onChange(lead_time);
                        }
                        catch (e) {
                            message.error('Error updating lead time');
                            console.error(e);
                        }
                    }, size: "small" },
                    React.createElement(CaretRightOutlined, null))));
        default:
            return (React.createElement("div", { className: "input-container" },
                React.createElement(InputNumber, { ...props, onChange: () => { }, onBlur: e => {
                        props.onChange(e.target.value);
                    } }),
                props.className === 'product__sides' && (React.createElement("a", { onClick: () => {
                        payload['product']['sides'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'setup_hours' && (React.createElement("a", { onClick: () => {
                        payload['setup_hours'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'programming_hours' && (React.createElement("a", { onClick: () => {
                        payload['programming_hours'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'runtime' && (React.createElement("a", { onClick: () => {
                        payload['runtime'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'total_material' && (React.createElement("a", { onClick: () => {
                        payload['total_material'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'coating1' && (React.createElement("a", { onClick: () => {
                        payload['coating1'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'coating2' && (React.createElement("a", { onClick: () => {
                        payload['coating2'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'extra1' && (React.createElement("a", { onClick: () => {
                        payload['extra1'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'extra2' && (React.createElement("a", { onClick: () => {
                        payload['extra2'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all"))));
    }
};
export default observer(InputType);
