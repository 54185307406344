import { action, observable, runInAction, makeObservable } from 'mobx';
import { get as _get, remove, post } from '../shared';
import { AccountDetail } from './types';
import { get } from '@partsbadger/utils';

export interface IAccountListResponse {
    count: number;
    next: string;
    previous: null;
    results: IAccountList[];
}

export interface IAccountList {
    id: number;
    name: string;
    owner: Owner;
    quoting_notes: null;
    auto_adjust: null;
    custom_terms_and_conditions: null;
    carrier: null;
    shipping_account_number: null;
    shipping_method: null;
    account_executive: null | string;
    account_manager: string[];
    display_name: string;
    account_documents_related: any[];
    zoho_id: string;
    deleted: boolean;
    created_time: string;
}

export interface Owner {
    id: number;
    first_name: string;
    last_name: string;
    fullname: string;
    email: string;
    is_active: boolean;
    biography: null;
    picture: null | string;
    thumbnail: null | string;
}

class Account {
    isLoading = true;
    entities: IAccountList[] = [];
    account?: AccountDetail | null = null;
    account_documents_related = [];

    pagination: {
        current: number;
        defaultPageSize: number;
        pageSize: number;
        total: number;
    } = {
        current: 1,
        defaultPageSize: 30,
        pageSize: 30,
        total: 0,
    };

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            account: observable,
            account_documents_related: observable,

            // Actions
            getAccount: action,
            remove_account_document: action,
        });
    }

    async getById(account_id: number) {
        this.isLoading = true;
        return await get(`/staff/accounts/${account_id}/`)
            .then(data => {
                this.account = data;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async getAll(params: any) {
        this.isLoading = true;

        try {
            const data = await get(`/staff/accounts/`, {
                params: params,
            });
            runInAction(() => {
                this.entities = data.results;
                this.pagination.current = params.page;
                this.pagination.total = data.count;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    async getAccount(account_id: number) {
        this.isLoading = true;
        return await _get(`staff/accounts/${account_id}/`)
            .then((response: any) => {
                this.account = response.data;
                this.account_documents_related = response.data.account_documents_related;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    remove_account_document = async (index: number, document_id: number) => {
        this.account_documents_related = this.account_documents_related.filter((d, i: number) => i !== index);
        await remove(`staff/accounts/${this.account?.id}/remove-document/${document_id}`);
    };

    async existingAccount(account_name: string | null, email: string): Promise<void> {
        return await post(`staff/accounts/existing-account/`, { account_name, email });
    }

    async convertLeadToContact(payload = {}) {
        return await post(`staff/leads/convert-to-contact/`, payload);
    }
}

export const AccountStore = new Account();
