import React from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Modal, Typography } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PartItem, PartSlot } from '@partsbadger/library';
import PartStore from '../../stores/PartStore';
import { SortFiles } from '../SortFiles';
import { ALLOWED_FILES } from '@partsbadger/utils';
const { Title, Paragraph } = Typography;
export const AssociateParts = observer((props) => {
    const handleSubmit = () => {
        PartStore.fileParts.map(item => {
            if (item['3D File'] || item['2D File'] || item['Additional File']?.length) {
                PartStore.uploadPart(item);
            }
            else {
                PartStore.removePart(item); // Remove empty lines
            }
        });
    };
    const handleRemove = (item, fileType, file) => {
        PartStore.removeFile(item, fileType, file);
    };
    const check3DAllowedFiles = item_filename => {
        const allowed_files_3d = ALLOWED_FILES.Files3D;
        const t = item_filename.split('.').pop().toLowerCase();
        const file_type = allowed_files_3d.filter(f => f === t);
        return t === file_type[0];
    };
    const check2DAllowedFiles = item_filename => {
        const allowed_files_2d = ALLOWED_FILES.Files2D;
        const t = item_filename.split('.').pop().toLowerCase();
        const file_type = allowed_files_2d.filter(f => f === t);
        return t === file_type[0];
    };
    const parts = PartStore.fileParts.map((part_item, index) => {
        const file2d = part_item['2D File'] ? (React.createElement(PartItem, { index: index, file: part_item['2D File'], type: '2D File', handleRemove: () => handleRemove(part_item, '2D File') })) : null;
        const file3d = part_item['3D File'] ? (React.createElement(PartItem, { index: index, file: part_item['3D File'], type: '3D File', handleRemove: () => handleRemove(part_item, '3D File') })) : null;
        const fileOthers = part_item['Additional File']?.length > 0
            ? part_item['Additional File'].map((file) => {
                return (React.createElement(PartItem, { key: index + 1, index: index, file: file, type: 'Additional File', handleRemove: () => handleRemove(part_item, 'Additional File', file) }));
            })
            : null;
        return (React.createElement("div", { className: 'row-associate-parts', key: `${part_item.uid}-${index}`, style: {
                display: 'flex',
                justifyContent: 'center',
                // backgroundColor: 'rgba(217, 217, 217, 0.19)',
                marginBottom: '5px',
                padding: '10px',
                position: 'relative',
            } },
            React.createElement(LegacyIcon, { type: 'close', style: {
                    position: 'absolute',
                    right: '5px',
                    top: '5px',
                    cursor: 'pointer',
                    color: '#d92923',
                }, onClick: () => {
                    PartStore.removePart(part_item);
                } }),
            React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: file3d, onDrop: item => {
                    if (props.is_staff) {
                        if (check3DAllowedFiles(item.file.name)) {
                            PartStore.swapParts(item.index, index, item.type, '3D File', item.file);
                        }
                        else {
                            message.error('The file type is not allowed for the 3D category.', 4);
                        }
                    }
                    else {
                        PartStore.swapParts(item.index, index, item.type, '3D File', item.file);
                    }
                }, onDropNew: files => {
                    if (files.length) {
                        const f = files.pop();
                        if (f) {
                            PartStore.addFile(index, '3D File', f);
                        }
                    }
                } }),
            React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: file2d, onDrop: item => {
                    if (props.is_staff) {
                        if (check2DAllowedFiles(item.file.name)) {
                            PartStore.swapParts(item.index, index, item.type, '2D File', item.file);
                        }
                        else {
                            message.error('The file type is not allowed for the 2D category.', 4);
                        }
                    }
                    else {
                        PartStore.swapParts(item.index, index, item.type, '2D File', item.file);
                    }
                }, onDropNew: (files) => {
                    const f = files.pop();
                    if (f) {
                        PartStore.addFile(index, '2D File', f);
                    }
                } }),
            React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], lastDroppedItem: fileOthers, onDrop: item => {
                    PartStore.swapParts(item.index, index, item.type, 'Additional File', item.file);
                }, onDropNew: files => {
                    files.forEach(file => {
                        PartStore.addFile(index, 'Additional File', file);
                    });
                } })));
    });
    if (!PartStore.hasParts()) {
        return null;
    }
    const content = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "m-auto" },
            React.createElement(DndProvider, { backend: HTML5Backend },
                React.createElement("div", { className: "flex flex-row", style: {
                    // background: '#fafafa',
                    } },
                    React.createElement("div", { className: "flex-1 mr-auto text-center" },
                        React.createElement(SortFiles, { fileType: '3D File' })),
                    React.createElement("div", { className: "flex-1 mr-auto text-center" },
                        React.createElement(SortFiles, { fileType: '2D File' })),
                    React.createElement("div", { className: "flex-1 mr-auto text-center" },
                        React.createElement(SortFiles, { fileType: 'Additional File' }))),
                parts))));
    return (React.createElement(Modal, { open: PartStore.fileParts.length > 1, onCancel: () => {
            PartStore.fileParts.map(item => {
                PartStore.removePart(item);
            });
        }, centered: true, title: React.createElement(React.Fragment, null,
            React.createElement(Title, { level: 2, className: "text-center" }, "Match your 2D and 3D files"),
            React.createElement(Paragraph, { className: "text-center" }, "Drag and Drop the files between columns.")), width: '90vw', style: { maxWidth: '1350px' }, bodyStyle: { maxHeight: '50vh', overflowY: 'scroll' }, footer: React.createElement("div", { className: "text-center my-3" },
            React.createElement(Button, { id: "start-quoting-btn", className: "w-100", style: {
                    maxWidth: 300,
                }, size: 'large', type: "primary", loading: !!PartStore.fileParts.find(filePart => filePart.uploading), onClick: handleSubmit }, "Continue")) }, content));
});
