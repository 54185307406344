import React, { useState } from 'react';
import { Form, Button, Descriptions, notification } from 'antd';
import { ShippingCarriersPurchasesOrders, ShippingMethodsPurchasesOrders } from '@partsbadger/library';
import IntakeStore from '../../Stores/IntakeStore';
import { FormErrorParser } from '@partsbadger/library';
import dayjs from 'dayjs';
import ShippingDestination from './ShippingDestination';
const CustomerOuttake = props => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const onSubmit = async (values) => {
        setLoading(false);
        props.acknowledgeVisible(false);
        props.isSubmitting(true);
        try {
            const payload = {
                shipping_designation: values.shipping_designation,
            };
            await IntakeStore.outtakeSalesOrder(false, payload).finally(() => props.isSubmitting(false));
        }
        catch (err) {
            notification.error({
                message: 'Error',
                description: 'An error has occured!',
            });
        }
    };
    const handleCancel = (e) => {
        e.preventDefault();
        props.acknowledgeVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { onFinish: values => onSubmit(values), onFinishFailed: errorInfo => {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                    placement: 'top',
                    maxCount: 1,
                });
            }, layout: "horizontal" },
            React.createElement("p", null, "Confirm the shipment method for this outtake based on the Required Ship Date and Shipping information."),
            React.createElement(Descriptions, { bordered: true, size: "small", column: 1 },
                React.createElement(Descriptions.Item, { label: "* Shipping Designation" },
                    React.createElement(Form.Item, { name: "shipping_designation", noStyle: true, rules: [
                            {
                                required: true,
                                message: 'Please enter a shipping destination',
                            },
                        ] },
                        React.createElement(ShippingDestination, null))),
                React.createElement(Descriptions.Item, { label: "Shipping Address" },
                    IntakeStore.salesOrder.shipping_street,
                    ", ",
                    IntakeStore.salesOrder.shipping_city,
                    ",",
                    ' ',
                    IntakeStore.salesOrder.shipping_state,
                    ", ",
                    IntakeStore.salesOrder.shipping_code,
                    ",",
                    IntakeStore.salesOrder.shipping_country,
                    IntakeStore.salesOrder.shipping_zip_code),
                React.createElement(Descriptions.Item, { label: "Required Ship Date" }, dayjs(IntakeStore.salesOrder?.required_ship_date).format('YYYY-MM-DD')),
                React.createElement(Descriptions.Item, { label: "Carrier" },
                    React.createElement(ShippingCarriersPurchasesOrders, { disabled: true, defaultValue: IntakeStore.salesOrder?.carrier })),
                React.createElement(Descriptions.Item, { label: "Shipping Method" },
                    React.createElement(ShippingMethodsPurchasesOrders, { disabled: true, defaultValue: IntakeStore.salesOrder?.shipping_method })),
                React.createElement(Descriptions.Item, { label: "Shipping Account" }, IntakeStore.salesOrder?.shipping_account_number)),
            React.createElement(Form.Item, { noStyle: true },
                React.createElement("div", { className: "flex w-full justify-between mt-10" },
                    React.createElement(Button, { key: "back", onClick: handleCancel }, "Go Back to Outtake"),
                    React.createElement(Button, { type: "primary", htmlType: "submit" }, "Submit"))))));
};
export default CustomerOuttake;
