import { useEffect } from 'react';
import { QuoteStore } from '../Stores';
import useSocketQuoteConnection from './useSocketQuoteConnection';
import { notification } from 'antd';
const QuoteSocket = (props) => {
    const token = localStorage.getItem('token') || '';
    const { new_notification } = useSocketQuoteConnection(props.quote_id, token);
    useEffect(() => {
        if (new_notification) {
            const payload = new_notification.payload;
            if (new_notification.type === 'QUOTE') {
                QuoteStore.setQuote(payload);
                QuoteStore.setQuoteEditable(!payload.completed);
            }
            if (new_notification.type == 'MASTER_PRODUCT') {
                QuoteStore.setUpdatedMasterProduct(payload);
            }
            if (payload.modified_by) {
                if (payload?.modified_by.id !== props.user.id) {
                    notification.info({
                        key: 'quote-updated',
                        message: 'Updated quote by another user.',
                        description: `${payload.modified_by?.fullname} made a change to this quote`,
                        placement: 'bottomRight',
                        maxCount: 1,
                    });
                    QuoteStore.getMasterProducts(props.quote_id);
                    QuoteStore.getLineItems(props.quote_id);
                }
            }
        }
    }, [new_notification]);
    return null;
};
export default QuoteSocket;
