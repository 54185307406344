import React from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { QuoteStore } from '../Stores';
import { FormErrorParser } from '@partsbadger/library';
export const ModalERPNotes = (props) => {
    const [form] = Form.useForm();
    const onSubmitERPNotes = async () => {
        form.validateFields()
            .then(async (values) => {
            if (props.quote_line_item_id) {
                const payload = {
                    id: props.quote_line_item_id,
                    partsbadger_production_quoting_notes: values.partsbadger_production_quoting_notes,
                };
                await QuoteStore.saveItem(payload).then(() => {
                    QuoteStore.getLineItems(props.quote_id);
                    props.setShowModalERPNote(false);
                });
            }
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    return (React.createElement(Modal, { visible: props.showModalERPNote, centered: true, title: "Add Notes for PartsBadger Production", onCancel: () => {
            props.setShowModalERPNote(false);
            props.setDataERPNote(null);
        }, footer: false, style: { maxWidth: '700px', borderRadius: '10px' }, width: 700 },
        React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
            React.createElement(Form, { onFinish: onSubmitERPNotes, form: form, layout: 'vertical', initialValues: {
                    partsbadger_production_quoting_notes: props.notes,
                } },
                React.createElement(Form.Item, { name: "partsbadger_production_quoting_notes", label: "Type your notes here..." },
                    React.createElement(Input.TextArea, { placeholder: "Add a note with information that may be helpful for PartsBadger Production. This can include quoted materials, custom tooling, etc. Notes will be visible in the ERP.", rows: 4 })),
                React.createElement("div", { className: "d-flex justify-content-between" },
                    React.createElement(Button, { id: "contact-rep-send-msg", className: "w-100 mb-2 mr-2", htmlType: "submit", type: "primary" }, "Save"),
                    React.createElement(Button, { onClick: () => {
                            props.setShowModalERPNote(false);
                            props.setDataERPNote(null);
                        }, className: "w-100", htmlType: "button" }, "Cancel"))))));
};
