import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, Select } from 'antd';
import { GenericSelect } from '@partsbadger/library';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
const ButtonGroup = Button.Group;
const Option = Select.Option;
const QuoteEditForm = (props) => {
    const { form } = props;
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator } = form;
    const quote = props.quote;
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    ...values,
                    deal: values.deal.key,
                    contact: values.contact.key,
                    account: values.account.key,
                    owner: values.owner.key,
                    design_engineer: values?.design_engineer?.key || null,
                };
                setLoading(true);
                props.handleSave(payload).then(() => {
                    setLoading(false);
                });
            }
        });
    };
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    };
    return (React.createElement("div", { className: 'quote-form' },
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(Form.Item, { labelCol: { span: 6 }, wrapperCol: { span: 16 }, label: "Deal" }, getFieldDecorator('deal', {
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: quote?.deal
                    ? {
                        key: quote.deal.id,
                        label: quote.deal.name,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "Deal" }))),
            React.createElement(Form.Item, { label: 'Account', ...formItemLayout, help: React.createElement("div", null,
                    "If you don't see the account?\u00A0",
                    React.createElement("a", { target: "_blank", href: `${ZOHO_BASE_URL}/Accounts/create`, rel: "noreferrer" }, "Create here")) }, getFieldDecorator('account', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a value!',
                    },
                ],
                initialValue: quote?.account
                    ? {
                        key: quote?.account.id,
                        label: quote?.account.name,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "Account" }))),
            React.createElement(Form.Item, { label: 'Contact', ...formItemLayout }, getFieldDecorator('contact', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a value!',
                    },
                ],
                initialValue: {
                    key: quote?.contact?.id,
                    label: quote?.contact?.fullname,
                },
            })(React.createElement(GenericSelect, { recordType: "Contact" }))),
            React.createElement(Form.Item, { label: 'Sales Rep.', ...formItemLayout }, getFieldDecorator('owner', {
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: quote?.owner
                    ? {
                        key: quote?.owner.id,
                        label: quote?.owner.fullname,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "User" }))),
            React.createElement(Form.Item, { label: 'Design Engineer.', ...formItemLayout }, getFieldDecorator('design_engineer', {
                initialValue: quote?.design_engineer
                    ? {
                        key: quote?.design_engineer.id,
                        label: quote?.design_engineer.fullname,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "User" }))),
            React.createElement(Form.Item, { label: 'Type', ...formItemLayout }, getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: quote?.type,
            })(React.createElement(Select, { dropdownMatchSelectWidth: false },
                React.createElement(Option, { value: "Standard" }, " Standard "),
                React.createElement(Option, { value: "Requote" }, " Requote "),
                React.createElement(Option, { value: "Rapid Quote" }, " Rapid Quote")))),
            React.createElement(Form.Item, { label: 'Name.', ...formItemLayout }, getFieldDecorator('name', {
                rules: [
                    {
                        required: false,
                        message: 'Please select a value!',
                    },
                ],
                initialValue: quote?.name,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: 'High Risk', ...formItemLayout }, getFieldDecorator('high_risk', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: quote?.high_risk,
            })(React.createElement(Checkbox, null))),
            React.createElement(Form.Item, { label: 'European', ...formItemLayout }, getFieldDecorator('european_customer', {
                valuePropName: 'checked',
                initialValue: quote?.european_customer,
            })(React.createElement(Checkbox, null))),
            React.createElement(Form.Item, { label: 'Shipping Zip Code', ...formItemLayout }, getFieldDecorator('shipping_zip_code', {
                initialValue: quote?.shipping_zip_code,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: 'Order of Items', ...formItemLayout }, getFieldDecorator('ordering_line_items', {
                initialValue: quote?.ordering_line_items,
            })(React.createElement(Select, { dropdownMatchSelectWidth: false },
                React.createElement(Option, { value: "created_time" }, " Created Time "),
                React.createElement(Option, { value: "quantity" }, " Quantity ")))),
            quote.editable && (React.createElement(ButtonGroup, { className: 'add-button' },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }), loading: loading }, "Save"))))));
};
export default Form.create()(QuoteEditForm);
