import React, { useEffect, useState } from 'react';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { OrderedListOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Modal, notification, Radio, Row } from 'antd';
import ChangeRequiredQuantitiesForm from '../DynamicQuantities/ChangeRequiredQuantitiesForm';
import { ErrorRender, TablePrices } from '@partsbadger/library';
import { QuoteLineItemForm } from '../../Forms';
import { QuoteLineItemQuantityForm, styleBody } from '../DynamicQuantities';
import { ModalERPNotes } from '../ModalERPNotes';
const DynamicQuantities = observer(({ item, partVol, totalVol, editable }) => {
    const [isEditingItem, setIsEditingItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPricesList, setShowPricesList] = useState(false);
    const [isLoadingPricesList, setIsLoadingPricesList] = useState(false);
    const [pricesList, setPricesList] = useState();
    const [leadTimeRange, setLeadTimeRange] = useState({
        lower: item.lead_time_adjust_range_min,
        upper: item.lead_time_adjust_range_max,
    });
    const [uniPriceRange, setUnitPriceRange] = useState({
        lower: item.unit_price_adjust_range_min,
        upper: item.unit_price_adjust_range_max,
    });
    const [uniPriceAdjustmentType, setUnitPriceAdjustmentType] = useState(item.unit_price_adjust_type ?? '%');
    const [showModalERPNote, setShowModalERPNote] = useState(false);
    const [dataERPNote, setDataERPNote] = useState();
    const handleSubmit = async (values) => {
        const payload = {
            id: item.id,
            ...values,
        };
        try {
            await QuoteStore.saveItem(payload);
            await QuoteStore.recalculatePrices({
                quoted_products: [item.id],
            }).catch(err => {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'One of the line items has an error',
                    description: React.createElement(ErrorRender, { error: err }),
                    placement: 'top',
                    maxCount: 1,
                });
            });
            QuoteStore.getQuote(item.quote);
        }
        catch (error) {
            notification.error({
                message: 'Error',
                description: React.createElement(ErrorRender, { error: error }),
            });
        }
        setIsEditingItem(false);
    };
    const getPricesList = async (page = 1) => {
        setIsLoadingPricesList(true);
        const response = await QuoteStore.getDynamicPricesList(item.id, page);
        setPricesList(response);
        setIsLoadingPricesList(false);
    };
    const handleSendLeadAdjustment = async () => {
        await QuoteStore.makeDynamicQuantityAdjustment(item.id, {
            lead_time_adjust_range_min: leadTimeRange?.lower,
            lead_time_adjust_range_max: leadTimeRange?.upper,
        }).catch((error) => {
            notification.error({
                placement: 'topRight',
                message: 'Error, please verify your inputs',
                description: React.createElement(ErrorRender, { error: error }),
            });
        });
        QuoteStore.getQuote(item.quote);
    };
    const handleSendUnitPriceAdjustment = async () => {
        await QuoteStore.makeDynamicQuantityAdjustment(item.id, {
            unit_price_adjust_type: uniPriceAdjustmentType,
            unit_price_adjust_range_min: uniPriceRange?.lower,
            unit_price_adjust_range_max: uniPriceRange?.upper,
        }).catch(error => {
            notification.error({
                placement: 'topRight',
                message: 'Error, please verify your inputs',
                description: React.createElement(ErrorRender, { error: error }),
            });
        });
        QuoteStore.getQuote(item.quote);
    };
    const initialQuantities = item.quantities?.length ? item.quantities : [item.quantity];
    useEffect(() => {
        setLeadTimeRange({
            lower: item.lead_time_adjust_range_min,
            upper: item.lead_time_adjust_range_max,
        });
    }, [item]);
    const handleAddERPQuotingNotes = async (quote_line_item_id, notes) => {
        setDataERPNote({
            id: quote_line_item_id,
            partsbadger_production_quoting_notes: notes,
        });
        setShowModalERPNote(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: 'shadow' },
            React.createElement(Col, { md: 24, className: 'row-quote-item ml-4' },
                React.createElement(ChangeRequiredQuantitiesForm, { item_id: item.id, isReadOnly: !editable, quantities: initialQuantities, handleChangeQuantities: async (quantities) => {
                        try {
                            await QuoteStore.updateRequiredQuantities(item.id, {
                                quantities: quantities,
                            });
                        }
                        catch (e) {
                            notification.error({
                                key: 'form-',
                                duration: 8,
                                description: 'One of the line items has an error',
                                message: 'Error',
                                placement: 'top',
                                maxCount: 1,
                            });
                        }
                    } })),
            React.createElement(Col, { md: 24, className: 'row-quote-item ml-4 mt-1' },
                item.debug?.face_directions ? 'FD' : 'BF:',
                ' ',
                item.debug?.face_directions?.toFixed(2) ?? item.debug?.badger?.toFixed(2),
                React.createElement(QuoteLineItemForm, { isReadOnly: !editable, item: {
                        id: item.id,
                        drawing_file: item.product.drawing_file,
                        manufacturing_type: item.manufacturing_type,
                        reinforcement_percent: item.reinforcement_percent || 0,
                        formula: item.formula,
                        has_material_cert: item.has_material_cert,
                        batch_shipment: item.batch_shipment,
                        reinforcement: item.reinforcement || '',
                        design_time: item.product.design_time,
                        product__sides: item.product.sides,
                        is_fai_required: item.is_fai_required,
                        is_prod_candidate: item.is_prod_candidate,
                        manual_adjustment: item.manual_adjustment,
                        vendor_price_adjust: item.vendor_price_adjust,
                        vendor_price: item.vendor_price,
                        totalVol: totalVol,
                        partVol: partVol,
                        product__material: item.product.material?.id
                            ? {
                                key: item.product.material.id.toString(),
                                label: item.product.material.name,
                                value: item.product.material.id.toString(),
                            }
                            : null,
                        product__finish: item.product.finish
                            ? {
                                key: item.product.finish.id.toString(),
                                label: item.product.finish.name,
                                value: item.product.finish.id.toString(),
                            }
                            : null,
                        product__coating: item.product.coating
                            ? {
                                key: item.product.coating.id.toString(),
                                label: item.product.coating.name,
                                value: item.product.coating.id.toString(),
                            }
                            : null,
                        has_file_3d: item.product.has_file_3d,
                        custom_material: item.product.custom_material ?? '',
                        custom_finish: item.product.custom_finish ?? '',
                        custom_coating: item.product.custom_coating ?? '',
                        partsbadger_production_method: item.partsbadger_production_method,
                        partsbadger_production_quoting_notes: item.partsbadger_production_quoting_notes,
                    }, handleSubmit: handleSubmit, addPartsbadgerProductionQuotingNotes: handleAddERPQuotingNotes })),
            React.createElement(Col, { md: 24, className: 'row-quantities mt-1' },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Displayed on the quote?")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Lead Time"),
                        React.createElement("div", { className: 'adjustment-field', style: {
                                marginTop: 20,
                            } },
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 100, placeholder: "Min", value: leadTimeRange?.lower || '', style: { width: '100%', textAlign: 'center' }, onChange: event => {
                                    setLeadTimeRange({
                                        ...leadTimeRange,
                                        lower: event.target.value,
                                    });
                                }, onPressEnter: handleSendLeadAdjustment, onBlur: handleSendLeadAdjustment }))),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Vendor Price")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Material Price")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Shipping")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Unit Price Adjustment"),
                        React.createElement("div", { className: 'adjustment-field' },
                            React.createElement(Radio.Group, { disabled: !editable, value: uniPriceAdjustmentType, onChange: event => {
                                    setUnitPriceAdjustmentType(event.target.value);
                                    setTimeout(handleSendUnitPriceAdjustment, 300);
                                }, className: 'flex flex-row items-end justify-center' },
                                React.createElement(Radio, { id: "action", value: '%' }, "%"),
                                React.createElement(Radio, { id: "action", value: '$' }, "$")),
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 201, placeholder: "Min", value: uniPriceRange?.lower || '', style: { width: '100%', textAlign: 'center' }, onChange: event => {
                                    setUnitPriceRange({
                                        ...uniPriceRange,
                                        lower: event.target.value,
                                    });
                                }, onPressEnter: handleSendUnitPriceAdjustment, onBlur: handleSendUnitPriceAdjustment }))),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Unit Price")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Total Price"))),
                React.createElement(Row, null, item.quote_item_quantities?.map(quote_item_quantity => {
                    return (React.createElement(QuoteLineItemQuantityForm, { key: quote_item_quantity.id, isReadOnly: !editable, variation: quote_item_quantity, uniPriceAdjustmentType: uniPriceAdjustmentType, handleUpdate: async (values) => {
                            await QuoteStore.updateQuoteLineQuantityItem(item.id, quote_item_quantity.id, values);
                            QuoteStore.getQuote(item.quote);
                        } }));
                })),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { className: 'adjustment-field' },
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 100, placeholder: "Max", value: leadTimeRange?.upper ?? '', style: { width: '100%', textAlign: 'center' }, onChange: event => {
                                    setLeadTimeRange({
                                        ...leadTimeRange,
                                        upper: event.target.value,
                                    });
                                }, onPressEnter: handleSendLeadAdjustment, onBlur: handleSendLeadAdjustment }))),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { className: 'adjustment-field' },
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 202, placeholder: "Max", value: uniPriceRange?.upper || '', style: { width: '100%', textAlign: 'center' }, onChange: event => {
                                    setUnitPriceRange({
                                        ...uniPriceRange,
                                        upper: event.target.value,
                                    });
                                }, onPressEnter: handleSendUnitPriceAdjustment, onBlur: handleSendUnitPriceAdjustment }))),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 })))),
        React.createElement(Modal, { title: 'Price per Quantity', centered: true, visible: showPricesList, onCancel: () => setShowPricesList(false), footer: [] },
            React.createElement(TablePrices, { loading: isLoadingPricesList, prices: pricesList?.results ?? [], count: pricesList?.count ?? 30, current: pricesList?.current ?? 1, pageSize: pricesList?.pageSize ?? 1, handleSelect: async (qty) => {
                    await QuoteStore.addQuoteItemQuantity(item.id, qty);
                    QuoteStore.getQuote(item.quote);
                }, handlePageChange: page => {
                    getPricesList(page);
                } })),
        React.createElement(Row, { className: 'flex flex-row items-end p-2' },
            React.createElement("div", { className: 'flex justify-center w-2/3' },
                React.createElement(Button, { disabled: !editable, type: 'link', onClick: () => {
                        getPricesList(1);
                        setShowPricesList(true);
                    } },
                    React.createElement(OrderedListOutlined, null),
                    " More Prices"),
                React.createElement(Button, { disabled: !editable, type: 'link', onClick: () => {
                        QuoteStore.duplicateLineItem(item.id);
                    }, icon: React.createElement(LegacyIcon, { type: 'plus' }) }, "Variation"),
                React.createElement(Button, { disabled: !editable, type: 'link', onClick: () => QuoteStore.deleteLineItem(item.id), icon: React.createElement(LegacyIcon, { type: 'delete' }) }, "Delete Line")),
            React.createElement("div", { className: 'flex flex-row-reverse w-1/3' },
                React.createElement(Button, { loading: loading, disabled: !editable, className: 'ml-2', size: 'small', onClick: async () => {
                        if (item.quote) {
                            setLoading(true);
                            await QuoteStore.duplicateLineItem(item.id).finally(() => setLoading(false));
                        }
                    }, icon: React.createElement(LegacyIcon, { type: 'plus' }) }, "Variation"),
                React.createElement(Button, { loading: loading, disabled: !editable, className: 'ml-2', size: 'small', onClick: () => {
                        if (item.quote) {
                            setLoading(true);
                            QuoteStore.duplicateQuotedProduct(item.product.master_product).finally(() => setLoading(false));
                        }
                    }, icon: React.createElement(LegacyIcon, { type: 'copy' }) }, "Duplicate Product"))),
        dataERPNote && (React.createElement(ModalERPNotes, { quote_id: item.quote, showModalERPNote: showModalERPNote, quote_line_item_id: dataERPNote.id, notes: dataERPNote.partsbadger_production_quoting_notes, setShowModalERPNote: setShowModalERPNote, setDataERPNote: setDataERPNote }))));
});
export default Form.create()(DynamicQuantities);
