import React from 'react';
import { message, Table, Button } from 'antd';
import { Price } from '../Price';
export const TablePrices = ({ loading, prices, handleSelect, ...props }) => {
    const columns = [
        {
            title: 'Qty',
            dataIndex: 'quantity',
            align: 'center',
            className: 'one-unit',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            align: 'center',
            render: (t, r) => {
                return React.createElement(Price, { value: r.unit_price });
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            align: 'center',
            render: (t, r) => {
                return React.createElement(Price, { value: r.total_price });
            },
        },
        {
            title: 'Lead Time',
            dataIndex: 'lead_time',
        },
        {
            title: 'Actions',
            align: 'center',
            render: (t, r) => {
                if (handleSelect) {
                    return (React.createElement(Button, { type: "primary", onClick: () => {
                            handleSelect(r.quantity).then(r => message.success('New Quantity Added'));
                        } }, "Select"));
                }
            },
        },
    ];
    const rendered_columns = handleSelect ? columns : columns.filter(c => c.title !== 'Actions');
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { rowKey: 'quantity', loading: loading, bordered: true, columns: rendered_columns, dataSource: prices, size: "middle", pagination: {
                pageSize: props.pageSize,
                current: props.current,
                total: props.count,
                onChange: page => props.handlePageChange(page),
            } })));
};
