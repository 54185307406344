import { SalesOrderProps } from '@partsbadger/types';
import { action, makeObservable } from 'mobx';
import { get as get2 } from '../shared';
import FileDownload from 'js-file-download';
import { notification } from 'antd';

class QualityIssue {
    order?: SalesOrderProps | null = null;

    constructor() {
        makeObservable(this, {
            download_all_files: action,
        });
    }

    download_all_files = async (quality_issue_id: number, sales_order_name: string) => {
        const url = `staff/quality-issues/${quality_issue_id}/download-files/`;

        get2(url, {}, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data instanceof Blob) {
                    FileDownload(response.data, `${sales_order_name}-quality-issue-${quality_issue_id}-files.zip`);
                    notification.success({
                        message: 'Files downloaded successfully.',
                        duration: 5,
                    });
                } else {
                    throw new Error('Unexpected response format');
                }
            })
            .catch(async (error: { response: { data: { text: () => any } }; message: string }) => {
                let errorMessage = 'An unknown error occurred';
                if (error.response) {
                    try {
                        const errorText = await error.response.data.text();
                        const jsonObject = JSON.parse(errorText);
                        errorMessage = jsonObject.error || 'An unknown error occurred';
                    } catch (readError) {
                        errorMessage = 'Failed to process error response';
                    }
                } else if (error.message) {
                    errorMessage = error.message;
                }

                notification.error({
                    message: 'Error downloading files.',
                    description: errorMessage,
                    duration: 5,
                });
            });
    };
}

export const QualityIssueStore = new QualityIssue();
