import React, { useState, useRef } from 'react';
import { Modal, Divider, Empty } from 'antd';
import Draggable from 'react-draggable';
import TicketView from './TicketView';
export default function TicketModal({ handleCloseTicketModal, product }) {
    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef(null);
    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    return (React.createElement(Modal, { title: React.createElement("div", { style: {
                width: '100%',
                cursor: 'move',
            }, onMouseOver: () => {
                if (disabled) {
                    setDisabled(false);
                }
            }, onMouseOut: () => {
                setDisabled(true);
            }, onFocus: () => { }, onBlur: () => { } },
            "Product Tickets - ",
            product?.product_name), visible: true, footer: [], bodyStyle: { padding: '8px', borderColor: 'light-gray', borderWidth: '4px' }, onCancel: () => handleCloseTicketModal(), modalRender: modal => (React.createElement(Draggable, { disabled: disabled, bounds: bounds, onStart: (event, uiData) => onStart(event, uiData) },
            React.createElement("div", { ref: draggleRef }, modal))), width: '40%' },
        product?.ticket_products.map(item => {
            return (React.createElement("div", { key: item.id },
                React.createElement(Divider, null, "Chat Ticket"),
                React.createElement(TicketView, { arrayMessages: item.messages })));
        }),
        product?.ticket_products.length === 0 && React.createElement(Empty, { description: "No messages" })));
}
