import React, { useState } from 'react';
import { observer } from 'mobx-react';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Col, Divider, Form, Row, Upload, Input, Radio, InputNumber, notification } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
import { Contacts, Quotes } from '@partsbadger/library';
import { UploadOutlined } from '@ant-design/icons';
const formItemLayout = {
    labelCol: { md: 4, xs: 24 },
    wrapperCol: { md: 20, xs: 24 },
};
export const styleBody = {
    padding: '3px 0',
    textAlign: 'center',
    color: 'black',
};
const CreditCardSubmissionToolForm = () => {
    const [form] = Form.useForm();
    const [total, setTotal] = useState(0);
    const { account, contact } = SalesOrderStore;
    const handleSubmit = (values) => {
        const payload = {
            amount: values.amount,
            customer_email: values.customer_email,
            representative_email: values.representative_email,
            notes: values.notes,
            ship_cost: values.ship_cost,
            tax_cost: values.tax_cost,
            zip_code: values.zip_code,
            quotes: values.quotes,
            files: values?.files ? values?.files?.fileList?.map(f => f.originFileObj) : [],
        };
        SalesOrderStore.sendCreditCard(payload);
        openNotificationWithIcon('success');
        onReset();
    };
    const onFinishFailed = (errorInfo) => {
        openNotificationWithIcon('error');
    };
    const onReset = () => {
        form.setFieldsValue({
            quotes: [],
        });
        form.resetFields();
    };
    const onChangeContact = () => {
        setTotal(0);
        form.setFieldsValue({
            quotes: [],
            amount: 0,
            customer_email: '',
            representative_email: '',
            zip_code: '',
            files: [],
            notes: '',
        });
    };
    const openNotificationWithIcon = (type) => {
        if (type === 'success') {
            notification[type]({
                message: 'Credit Card Submission.',
                description: 'SUCCESS.',
            });
        }
        else {
            notification[type]({
                message: 'Credit Card Submission.',
                description: 'Please review the red-colored field.',
            });
        }
    };
    const validateNumberGreaterThan0 = (rule, value, callback) => {
        if (isNaN(value) || value == 0 || !value) {
            callback('Required');
        }
        callback();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, name: "Credit Card Form", className: 'sales-order-form', onFinish: handleSubmit, onFinishFailed: onFinishFailed },
            React.createElement("div", { className: 'row-order mt-5 m-auto' },
                React.createElement(Col, { sm: 24 },
                    React.createElement(Form.Item, { label: 'Contact', name: "contact", ...formItemLayout, rules: [
                            {
                                required: true,
                                message: 'Please select a contact to start',
                            },
                        ] },
                        React.createElement(Contacts, { account: SalesOrderStore?.account?.id, onSelect: (item) => {
                                SalesOrderStore.getContactById(item?.key);
                            }, onChange: () => {
                                onChangeContact();
                            }, onDeselect: () => {
                                onChangeContact();
                            } })))),
            contact?.id && !account?.id && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                React.createElement(Alert, { type: 'error', showIcon: true, message: "This contact doesn't have an account related, please fix this before to continue!" }))),
            contact?.id && account?.id && (React.createElement(React.Fragment, null,
                form.setFieldsValue({
                    amount: 0,
                    customer_email: contact?.email,
                    representative_email: contact?.owner?.email,
                    zip_code: contact?.default_addresses?.shipping_address?.zip_code,
                    files: [],
                    notes: '',
                    tax_cost: 1,
                    ship_cost: 1,
                }),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, { name: "quotes", label: 'Quote Number(s)', ...formItemLayout, rules: [{ required: true, message: 'Please select a value!' }] },
                            React.createElement(Quotes, { mode: 'multiple', account: account?.id, filters: {
                                    is_purchasable: true,
                                }, 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                onSelect: (item) => {
                                    SalesOrderStore.getQuote(parseInt(item.key)).then((response) => {
                                        const current_value = response.grand_total + total;
                                        setTotal(current_value);
                                        form.setFieldsValue({
                                            amount: current_value.toFixed(2),
                                        });
                                    });
                                }, 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                onDeselect: (item) => {
                                    form.setFieldsValue({ amount: 0 });
                                    SalesOrderStore.getQuote(parseInt(item.key)).then((response) => {
                                        const current_value = total - response.grand_total;
                                        setTotal(current_value);
                                        form.setFieldsValue({
                                            amount: current_value.toFixed(2),
                                        });
                                    });
                                } })))),
                React.createElement(Row, { className: 'row-order mt-5 text-left' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, { name: 'amount', label: "Confirm Amount to be charged", rules: [{ required: true }, { validator: validateNumberGreaterThan0 }], ...formItemLayout },
                            React.createElement(InputNumber, null)))),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, { name: 'customer_email', label: "Customer's Email Address", 
                            // initialValue={contact?.email}
                            rules: [{ required: true, type: 'email' }], ...formItemLayout },
                            React.createElement(Input, null)),
                        React.createElement(Form.Item, { name: 'representative_email', label: "Representative Email", 
                            // initialValue={contact?.owner?.email}
                            rules: [{ required: true, type: 'email' }], ...formItemLayout },
                            React.createElement(Input, null)))),
                React.createElement(Divider, null,
                    React.createElement("div", { className: 'title' }, "This needs to be THE Shipping information from the Customer for the invoice to have the correct Sales Tax on the invoice.")),
                React.createElement(Col, { sm: 24 },
                    React.createElement(Form.Item, { name: 'zip_code', label: "Shipping Zip Code", rules: [{ required: true }], ...formItemLayout },
                        React.createElement(Input, null)),
                    React.createElement(Form.Item, { name: 'ship_cost', label: "Add shipping cost?", ...formItemLayout },
                        React.createElement(Radio.Group, null,
                            React.createElement(Radio, { value: 1 }, "Yes"),
                            React.createElement(Radio, { value: 0 }, "No"))),
                    React.createElement(Form.Item, { name: 'tax_cost', label: "Add Tax cost?", ...formItemLayout },
                        React.createElement(Radio.Group, null,
                            React.createElement(Radio, { value: 1 }, "Yes"),
                            React.createElement(Radio, { value: 0 }, "No"))),
                    React.createElement(Form.Item, { name: 'files', label: "Upload Files", ...formItemLayout, rules: [{ required: true }] },
                        React.createElement(Upload, { multiple: true, accept: ".pdf", beforeUpload: () => false },
                            React.createElement(Button, { icon: React.createElement(UploadOutlined, null) }, "Click to Upload"))),
                    React.createElement(Form.Item, { name: 'notes', label: "Notes", ...formItemLayout },
                        React.createElement(Input.TextArea, null))),
                React.createElement(Col, { sm: 24, style: { textAlign: 'center' } },
                    React.createElement(Form.Item, { ...formItemLayout },
                        React.createElement(Button, { type: "primary", htmlType: "submit" }, "Submit"))))))));
};
export default observer(CreditCardSubmissionToolForm);
