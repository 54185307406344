import React, { useState } from 'react';
import { Button, Dropdown, Menu, message, Table, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, CheckOutlined, DownOutlined, FilePdfOutlined } from '@ant-design/icons';
import { PaginationComponent } from 'quotes-frontend/src/components/Inputs/Pagination';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
export const OrdersTable = (props) => {
    const history = useHistory();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total_with_shipping',
            key: 'grand_total_with_shipping',
        },
        {
            title: 'Zoho',
            dataIndex: 'zoho_id',
            key: 'zoho_id',
            render: (value, record) => (React.createElement(React.Fragment, null, record.zoho_id && (React.createElement("a", { style: {
                    fontSize: 12,
                    marginLeft: 4,
                }, target: '_blank', rel: "noreferrer", href: `${ZOHO_BASE_URL}/PurchaseOrders/${record.zoho_id}` }, "View In Zoho")))),
        },
        {
            title: 'Receive status',
            dataIndex: 'receive_status',
        },
        {
            title: 'Status',
            dataIndex: 'approved',
            key: 'approved',
            render: (value, record) => (React.createElement(React.Fragment, null, record.approved ? (React.createElement(Tag, { color: 'green' }, 'Approved')) : (React.createElement(Tag, { color: 'red' }, 'Not Approved')))),
        },
        {
            title: 'Sent',
            dataIndex: 'sent',
            key: 'sent',
            render: (value, record) => (React.createElement(React.Fragment, null, record.send_to_vendor ? React.createElement(CheckOutlined, null) : 'No')),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (value, record) => {
                const menu = (React.createElement(Menu, null,
                    React.createElement(Menu.Item, { onClick: () => {
                            history.push(`/purchase-orders/${record.id}/`);
                        } },
                        ' ',
                        React.createElement(CheckCircleOutlined, null),
                        " Review Purchase Order"),
                    record.approved && (React.createElement(Menu.Item, { onClick: () => {
                            if (record.id && record.category) {
                                const template_name = record.category == 'PartsBadger, LLC'
                                    ? 'finance/po_pb.html'
                                    : 'finance/po_pb.html';
                                props.downloadPdf(record.id, record.name, template_name);
                            }
                        } },
                        React.createElement(FilePdfOutlined, null),
                        "Download PDF")),
                    record.approved && (React.createElement(Menu.Item, { onClick: () => {
                            if (record.id) {
                                try {
                                    PurchaseOrderStore.send_to_vendor(record.id).then(() => {
                                        props.onReload();
                                    });
                                }
                                catch (e) {
                                    message.error('Error sending to vendor');
                                }
                            }
                        } },
                        React.createElement(CheckOutlined, null),
                        "Mark as Sent"))));
                return (React.createElement(Dropdown, { overlay: menu },
                    React.createElement("span", { className: 'link' },
                        "Actions ",
                        React.createElement(DownOutlined, null))));
            },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-row p-4' },
            React.createElement(Button, { type: 'link', disabled: selectedRowKeys.length <= 0, onClick: () => props.downloadAllPdF(selectedRowKeys) }, "Download PDFs")),
        React.createElement(Table, { rowKey: (record) => String(record.id), rowSelection: rowSelection, columns: columns, pagination: false, footer: () => {
                return React.createElement(PaginationComponent, { ...props.pagination });
            }, dataSource: props.purchase_orders })));
};
