import React, { useEffect, useState } from 'react';

import {
    FilePdfOutlined,
    FileTextOutlined,
    LinkOutlined,
    MailOutlined,
    MessageOutlined,
    MinusCircleOutlined,
    OrderedListOutlined,
    PullRequestOutlined,
    ScheduleOutlined,
    SettingOutlined,
    ThunderboltFilled,
    UnorderedListOutlined,
    UploadOutlined,
    UserOutlined,
    WechatOutlined,
} from '@ant-design/icons';

import '@ant-design/compatible/assets/index.css';

import {
    Badge,
    Button,
    Card,
    DatePicker,
    Divider,
    Input,
    List,
    message,
    Modal,
    notification,
    Row,
    Select,
    Skeleton,
    Upload,
} from 'antd';

import { useHistory, useParams } from 'react-router-dom';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import { get } from '../../../shared';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import Shipments from '../../../components/SalesOrder/Shipments';
import QualityDocs from '../../../components/SalesOrder/QualityDocs';
import Invoices from '../../../components/SalesOrder/Invoices';
import { observer } from 'mobx-react';
import moment from 'moment';
import dayjs from 'dayjs';
import { ItypeUpdateNotification, OrderLineItemProps } from '@partsbadger/types/lib/SalesOrderInterfaces';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { hasGroup } from '../../../components/User';

import TotalUnreadMessagesAlert from '../TicketSystem/TotalUnreadMessagesAlert';
import TicketMainPage from '../TicketSystem/TicketMainPage';
import CancelItemsHistory from './CancelItems/CancelItemsHistory';
import RequestOrderAdjustment from '../../../components/SalesOrder/RequestOrderAdjustment';

import { TicketStore } from '../../../stores/TicketStore';
import { BadgerPage } from '../../../components/badger-ui';

import SalesOrderMessages from '../../../components/SalesOrder/SalesOrderMessages';
import SalesOrdersNotes from '../../../components/SalesOrder/SalesOrderNotes';
import SalesOrderLineItems from '../../../components/SalesOrder/SalesOrderLineItems/SalesOrderLineItems';
import SalesOrderDetailHeader from '../../../components/SalesOrder/SalesOrderDetailHeader';
import SalesOrderAddresses from '../../../components/SalesOrder/SalesOrderAddresses';
import { QualityIssuesList } from '../QualityIssues/QualityIssuesList';

const FileDownload = require('js-file-download');

interface ISelectedDate {
    id: number;
    name: string;
    date_target: string;
}
type Props = FormComponentProps;

type Params = {
    sales_order_id: string;
};

function capitalizeWords(str: string) {
    return str
        .replaceAll('_', ' ')
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const Details = (props: Props) => {
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalShipping, setShowModalShipping] = useState<boolean>(false);
    const [request_type_update, setRequest_type_update] = useState<ItypeUpdateNotification>();
    const [typeShipment, settypeShipment] = useState<string>('');
    const [notesShipment, setNotesShipment] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<ISelectedDate[]>([]);
    const [notes, setNotes] = useState<string>('');

    const [fileList, setFileList] = useState<any[]>([]);
    const [showRequestOrderAdjustment, setShowRequestOrderAdjustment] = useState<boolean>(false);

    const [totalUnreadMessagesSalesRequest, setTotalUnreadMessagesSalesRequest] = useState<number>(0);
    const [totalCancelledItems, setTotalCancelledItems] = useState<number>(0);
    const [selectedSection, setSelectedSection] = useState('basicInfo');
    const [selectLineItems, setSelectLineItems] = useState<OrderLineItemProps[]>([]);
    const [keyLineItems, setKeyLineItems] = useState<Array<any>>([]);

    const UploadProps = {
        name: 'file',
        multiple: true,
        accept: 'pdf,.jpeg,.png,.jpg',
        onRemove: () => {
            setFileList([]);
        },
        beforeUpload: (file: any, newFileList: any[]) => {
            setFileList([...fileList, ...newFileList]);
            return false;
        },
        fileList,
    };

    const { sales_order_id } = useParams<Params>();

    const history = useHistory();

    const { addresses, loading, order, shipments } = SalesOrderStore;

    async function getSalesOrderDetails(id: string | number) {
        await SalesOrderStore.getById(id).catch(error => {
            message.error(`Error loading order: ${error}`);
        });
    }

    const getVendorInformation = async (id: any) => {
        try {
            await SalesOrderStore.getTrackingNumbersBySalesOrderId(id);
        } catch (error) {
            notification.error({ message: error });
        }
    };

    const getUnReadMessagesProductionSales = async (zohoId: string) => {
        if (zohoId) {
            try {
                const response = await TicketStore.getTotalUnreadMessages(zohoId, 'production-sales');
                setTotalUnreadMessagesSalesRequest(response);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const getTotalItemsCancelled = (sales_order_id: string) => {
        const url = `staff/sales-orders/${sales_order_id}/total-cancelled-items/`;
        get(url).then((response: any) => {
            setTotalCancelledItems(response.data.total_cancelled_items);
        });
    };

    const handleBack = () => {
        history.goBack();
    };

    useEffect(() => {
        if (order?.contact?.id) {
            SalesOrderStore.getContactById(order.contact.id);
        }
    }, [order?.contact?.id]);

    useEffect(() => {
        if (sales_order_id) {
            getSalesOrderDetails(sales_order_id).then(() => {
                SalesOrderStore.getInvoiceList({
                    sales_order: sales_order_id,
                });

                SalesOrderStore.getNotifications(sales_order_id);
                SalesOrderStore.getNotes(sales_order_id);
                SalesOrderStore.getShipmentsBySalesOrderId(sales_order_id);
                getTotalItemsCancelled(sales_order_id);
                getVendorInformation(sales_order_id);
                SalesOrderStore.getQualityDocumentsBySalesOrderId(sales_order_id).catch(error =>
                    message.error(`Error loading quality documents: ${error}`)
                );
            });

            //Load vendor information
        }
    }, [sales_order_id]);

    useEffect(() => {
        if (order?.zoho_id) {
            getUnReadMessagesProductionSales(order.zoho_id);
        }
    }, [order?.zoho_id]);

    const downloadPdf = (order_id: string, order_name: string | undefined) => {
        get(`staff/sales-orders/${order_id}/download/`, {}, { responseType: 'blob' })
            .then((response: any) => {
                const data = response.data;

                FileDownload(data, `${order_name}.pdf`);
                notification.success({ message: 'Document downloaded' });
            })
            .catch((error: any) => notification.error(error));
    };
    const send_to_shipping_team = () => {
        const payload: {
            id: string;
            type_message: string;
            notes: string;
            files: any;
        } = {
            id: sales_order_id,
            type_message: typeShipment,
            notes: notesShipment,
            files: fileList ? fileList?.map(f => f) : [],
        };
        SalesOrderStore?.order?.id &&
            SalesOrderStore.notify_shipping_team(payload)
                .then(() => {
                    notification.success({ message: 'Message sent to the team' });
                    setFileList([]);
                    settypeShipment('');
                    setNotesShipment('');
                })
                .catch((error: any) => notification.error({ message: error }));
    };

    const onSubmit = async (request_type_update: string) => {
        try {
            const requestData = {
                username: 'staff',
                first_name: 'staff',
                last_name: 'staff',
                company_name: 'staff',
                order: order?.name,
                email: 'staff@gmail.com',
                notes: notes,
                type_update: request_type_update,
                line_items: selectedDate.length > 0 ? selectedDate : selectLineItems,
            };

            if (order) {
                await SalesOrderStore.sendUpdateRequest(order.id, requestData);

                message.success('Your Update request has been sent successfully');

                return true;
            }

            message.error('The order does not exist.');
        } catch (err) {
            message.error(`Error loading shipments: ${err}`);
        }
    };

    const isCancelButtonVisible =
        order && hasGroup('Production') && !['Shipped in Full', 'Shipped & Paid', 'Completed'].includes(order?.status);

    const renderSectionContent = () => {
        if (!order) {
            return <Skeleton active />;
        }

        switch (selectedSection) {
            case 'basicInfo':
                return (
                    <>
                        <>
                            <>
                                <TotalUnreadMessagesAlert zoho_id={order.zoho_id ?? '0'} />

                                <Card className={'header'}>
                                    <SalesOrderDetailHeader order={order} />

                                    <SalesOrderAddresses order={order} addresses={addresses} />
                                </Card>

                                {keyLineItems && keyLineItems?.length > 0 && (
                                    <>
                                        <div
                                            style={{
                                                marginTop: 20,
                                            }}
                                        >
                                            <Button
                                                className="mr-2"
                                                id="action-button"
                                                type="primary"
                                                onClick={() => {
                                                    setRequest_type_update({ type_notification: 'request_expedite' });
                                                    setSelectedDate([]);
                                                    setShowModal(!showModal);
                                                }}
                                                style={{
                                                    color: '#fff',
                                                    backgroundColor: '#d92923',
                                                    borderColor: '#d92923',
                                                }}
                                            >
                                                Request Expedite
                                            </Button>

                                            <Button
                                                className="mr-2"
                                                id="action-button"
                                                type="primary"
                                                onClick={() => {
                                                    setRequest_type_update({
                                                        type_notification: 'request_batch_date_change',
                                                    });
                                                    setSelectedDate([]);
                                                    setShowModal(!showModal);
                                                }}
                                                style={{
                                                    color: '#fff',
                                                    backgroundColor: '#d92923',
                                                    borderColor: '#d92923',
                                                }}
                                            >
                                                Request Batch Date Change
                                            </Button>
                                        </div>
                                    </>
                                )}

                                <Row
                                    style={{
                                        marginTop: 20,
                                    }}
                                >
                                    <SalesOrderLineItems
                                        order={order}
                                        selectLineItems={selectLineItems}
                                        setSelectLineItems={setSelectLineItems}
                                        keyLineItems={keyLineItems}
                                        setKeyLineItems={setKeyLineItems}
                                    />
                                </Row>
                                <Row
                                    style={{
                                        marginTop: 20,
                                    }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                        }}
                                        title={
                                            <span className="flex items-center font-bold">
                                                <Badge
                                                    count={order.additional_requirements?.length ?? 0}
                                                    overflowCount={100}
                                                >
                                                    <OrderedListOutlined className="mr-2" />
                                                </Badge>
                                                &nbsp; Additional Requirements
                                            </span>
                                        }
                                        key="2"
                                    >
                                        <List
                                            size="small"
                                            bordered
                                            // loading={loading}
                                            dataSource={order.additional_requirements ?? []}
                                            renderItem={item => <List.Item>{item}</List.Item>}
                                        />
                                    </Card>
                                </Row>
                            </>
                        </>
                    </>
                );
            case 'notes':
                return <SalesOrdersNotes sales_order_id={order.id} />;
            case 'shipments':
                return <Shipments order={order} />;
            case 'qualityDocuments':
                return <QualityDocs sales_order_id={order.id} />;
            case 'invoices':
                return <Invoices order_id={order.id} />;
            case 'messages':
                return (
                    <Card>
                        <SalesOrderMessages sales_order_id={order.id} />
                    </Card>
                );
            case 'ticketSupport':
                return (
                    <Card>
                        <TicketMainPage />
                    </Card>
                );
            case 'salesRequest':
                return (
                    <Card>
                        <TicketMainPage message_type="production-sales" />
                    </Card>
                );
            case 'cancellationHistory':
                return (
                    <Card>
                        <CancelItemsHistory sales_order_id={sales_order_id} />
                    </Card>
                );
            case 'qualityOrderSubmission':
                return <QualityIssuesList sales_order_id={sales_order_id} />;
            default:
                return null;
        }
    };

    const renderModals = () => {
        return (
            <>
                {showModalShipping && (
                    <>
                        <Modal
                            closable={true}
                            title={'Send Rocket To Shipping Team'}
                            open={showModalShipping}
                            onCancel={() => {
                                setShowModalShipping(false);
                                settypeShipment('');
                            }}
                            destroyOnClose={true}
                            footer={[]}
                        >
                            <>
                                <Select
                                    defaultValue=""
                                    style={{ width: 400 }}
                                    onChange={e => {
                                        settypeShipment(e);
                                    }}
                                    options={[
                                        {
                                            value: 'Provide Feedback on a Shipment',
                                            label: 'Provide Feedback on a Shipment',
                                        },
                                        {
                                            value: 'Request Assistance',
                                            label: 'Request Assistance',
                                        },
                                        {
                                            value: 'Request Expedite',
                                            label: 'Request Expedite',
                                        },
                                        {
                                            value: 'Notify of Change',
                                            label: 'Notify of Change',
                                        },
                                    ]}
                                />
                                {typeShipment && (
                                    <>
                                        <Divider className={'spacing'} />
                                        <Input.TextArea
                                            value={notesShipment}
                                            onChange={e => {
                                                setNotesShipment(e.target.value);
                                            }}
                                        />

                                        {typeShipment == 'Provide Feedback on a Shipment' && (
                                            <>
                                                <br />
                                                <br />
                                                <Upload {...UploadProps}>
                                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                </Upload>
                                            </>
                                        )}
                                        <br />
                                        <br />
                                        <Button
                                            loading={loadingButton}
                                            key="button-request-update"
                                            type="primary"
                                            style={{
                                                color: '#fff',
                                                backgroundColor: '#d92923',
                                                borderColor: '#d92923',
                                                marginLeft: 'auto',
                                            }}
                                            onClick={async () => {
                                                setLoadingButton(true);
                                                SalesOrderStore?.order && send_to_shipping_team();
                                                SalesOrderStore?.order?.id && setShowModalShipping(false);
                                                setLoadingButton(false);
                                            }}
                                        >
                                            Send to Rocket
                                        </Button>
                                    </>
                                )}
                            </>
                        </Modal>
                    </>
                )}

                {showRequestOrderAdjustment && order?.id && (
                    <RequestOrderAdjustment
                        onClose={() => setShowRequestOrderAdjustment(false)}
                        order={order}
                        showRequestOrderAdjustment={showRequestOrderAdjustment}
                    />
                )}

                {showModal && (
                    <>
                        <Modal
                            closable={true}
                            title={
                                <span className="flex items-center font-bold">
                                    {request_type_update?.type_notification == 'request_update' && (
                                        <PullRequestOutlined className="mr-1" />
                                    )}
                                    {request_type_update?.type_notification == 'request_expedite' && (
                                        <ThunderboltFilled className="mr-1" />
                                    )}
                                    {request_type_update?.type_notification == 'request_batch_date_change' && (
                                        <ScheduleOutlined className="mr-1" />
                                    )}
                                    {request_type_update && capitalizeWords(request_type_update.type_notification)}
                                </span>
                            }
                            open={showModal}
                            onCancel={() => setShowModal(false)}
                            destroyOnClose={true}
                            footer={[
                                <Button
                                    loading={loadingButton}
                                    key="button-request-update"
                                    type="primary"
                                    style={{
                                        color: '#fff',
                                        backgroundColor: '#d92923',
                                        borderColor: '#d92923',
                                        marginLeft: 'auto',
                                    }}
                                    onClick={async () => {
                                        setLoadingButton(true);
                                        request_type_update && (await onSubmit(request_type_update.type_notification));
                                        setSelectedDate([]);
                                        setShowModal(false);
                                        setNotes('');
                                        setLoadingButton(false);
                                    }}
                                >
                                    Send Request
                                </Button>,
                            ]}
                        >
                            {request_type_update?.type_notification == 'request_update' && selectLineItems.length > 0 && (
                                <>
                                    <table>
                                        <tr>
                                            <th>Id</th>
                                            <th>
                                                <p className="font-bold">Line Items Selected</p>
                                            </th>
                                        </tr>
                                        {selectLineItems.map(lines => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <span>{lines.name}</span>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </table>
                                    <br />
                                </>
                            )}
                            {request_type_update?.type_notification == 'request_expedite' && (
                                <>
                                    <table>
                                        <tr>
                                            <th>
                                                <p className="font-bold">Line Items Selected</p>
                                            </th>
                                            <th>
                                                <p className="font-bold">Current Ship date</p>
                                            </th>
                                            <th>
                                                <p className="font-bold">Target Date</p>
                                            </th>
                                        </tr>
                                        {selectLineItems &&
                                            selectLineItems.map(lines => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>
                                                                <span>{lines.name}</span>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                <span>{lines?.required_part_ship_date} </span>
                                                            </td>
                                                            <td>
                                                                <DatePicker
                                                                    disabledDate={current => {
                                                                        return (
                                                                            current && current < moment().add(-1, 'day')
                                                                        );
                                                                    }}
                                                                    onChange={(date: any | null) => {
                                                                        const payload = {
                                                                            id: lines.id,
                                                                            name: lines.name,
                                                                            date_target: dayjs(date).format('M/D/YYYY'),
                                                                        };
                                                                        setSelectedDate([...selectedDate, payload]);
                                                                    }}
                                                                    size="small"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </table>
                                    <br />
                                </>
                            )}
                            {request_type_update?.type_notification == 'request_batch_date_change' && (
                                <>
                                    <table>
                                        <tr>
                                            <th>
                                                <p className="font-bold">Line Items Selected</p>
                                            </th>
                                            <th>
                                                <p className="font-bold">Current Ship date</p>
                                            </th>
                                            <th>
                                                <p className="font-bold">Target Date</p>
                                            </th>
                                        </tr>
                                        {selectLineItems &&
                                            selectLineItems.map(lines => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>
                                                                <span>{lines.name}</span>
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                <span>{lines?.required_part_ship_date} </span>
                                                            </td>
                                                            <td>
                                                                <DatePicker
                                                                    disabledDate={current => {
                                                                        return (
                                                                            current && current < moment().add(-1, 'day')
                                                                        );
                                                                    }}
                                                                    onChange={(date: any | null) => {
                                                                        const payload = {
                                                                            id: lines.id,
                                                                            name: lines.name,
                                                                            date_target: dayjs(date).format('M/D/YYYY'),
                                                                        };
                                                                        setSelectedDate([...selectedDate, payload]);
                                                                    }}
                                                                    size="small"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </table>
                                    <br />
                                </>
                            )}

                            <p className="font-bold">Add any additional notes:</p>
                            <Input.TextArea
                                value={notes}
                                onChange={e => {
                                    setNotes(e.target.value);
                                }}
                            />
                        </Modal>
                    </>
                )}
            </>
        );
    };

    const approved_quality_documents_count = SalesOrderStore.quality_documents
        .filter(item => item.documents.length > 0)
        .reduce((acc, item) => acc + item.documents.length, 0);

    return (
        <BadgerPage
            title={order?.name}
            isLoading={loading}
            handleBack={handleBack}
            subtitle={<Badge count={order?.status ?? ''} style={{ backgroundColor: 'green' }} />}
            historyChanges={{
                model_name: 'sales-orders',
                pk: order?.id ?? 0,
            }}
            sidebarLinks={
                order
                    ? [
                          { title: 'Basic Information', link: 'basicInfo', icon: <UserOutlined /> },
                          {
                              title: 'Shipments',
                              link: 'shipments',
                              icon: <MailOutlined />,
                              counter: shipments.length,
                          },
                          {
                              title: 'Quality Documents',
                              link: 'qualityDocuments',
                              icon: <FileTextOutlined />,
                              counter: approved_quality_documents_count,
                          },
                          {
                              title: 'Quality Order Submission',
                              link: 'qualityOrderSubmission',
                              icon: <UnorderedListOutlined />,
                              counter: 0,
                          },
                          {
                              title: 'Invoices',
                              link: 'invoices',
                              icon: <FilePdfOutlined />,
                              counter: SalesOrderStore.invoices.length,
                          },
                          {
                              title: 'Messages',
                              link: 'messages',
                              icon: <MessageOutlined />,
                              counter: SalesOrderStore.notifications.length,
                          },
                          {
                              title: 'Production Support',
                              link: 'ticketSupport',
                              icon: <WechatOutlined />,
                              counter: TicketStore.ticketsProductionSupportCount,
                          },
                          {
                              title: 'Sales Support',
                              link: 'salesRequest',
                              icon: <WechatOutlined />,
                              counter: TicketStore.ticketSalesSupportCount,
                          },
                          {
                              title: 'Cancellation History',
                              link: 'cancellationHistory',
                              icon: <MinusCircleOutlined />,
                              counter: totalCancelledItems,
                          },
                          {
                              title: 'Notes',
                              link: 'notes',
                              icon: <FileTextOutlined />,
                              counter: SalesOrderStore.notes.pagination.total,
                          },
                      ]
                    : []
            }
            handleSidebarClick={key => setSelectedSection(key)}
            topActions={[
                <Button
                    key={'download-pdf'}
                    onClick={() => {
                        downloadPdf(sales_order_id, SalesOrderStore?.order?.name);
                    }}
                >
                    <FilePdfOutlined />
                    Download
                </Button>,

                <Button
                    key={'request-order-adjustment'}
                    onClick={() => {
                        setShowRequestOrderAdjustment(true);
                    }}
                >
                    <SettingOutlined />
                    Request Adjustment
                </Button>,

                <Button
                    key={'request-update'}
                    onClick={() => {
                        setRequest_type_update({ type_notification: 'request_update' });
                        setSelectedDate([]);
                        setShowModal(!showModal);
                    }}
                >
                    <MessageOutlined />
                    Request Update
                </Button>,

                <Button
                    key={'message-to-shipping-team'}
                    icon={<MessageOutlined />}
                    onClick={() => {
                        setShowModalShipping(!showModalShipping);
                    }}
                >
                    Message Shipping Team
                </Button>,
                <Button
                    disabled={!isCancelButtonVisible}
                    key={'cancel-items'}
                    onClick={() => {
                        history.push(`/new-sales-order-detail/${sales_order_id}/cancellation`);
                    }}
                >
                    <MinusCircleOutlined />
                    Cancel Items
                </Button>,
                <Button
                    key={'view-in-zoho'}
                    disabled={!order?.zoho_id}
                    onClick={() => window.open(`${ZOHO_BASE_URL}/SalesOrders/${order?.zoho_id}`)}
                >
                    <LinkOutlined />
                    View in Zoho
                </Button>,
            ]}
        >
            {renderSectionContent()}
            {renderModals()}
        </BadgerPage>
    );
};

export default observer(Details);
