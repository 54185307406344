import { action, observable, makeObservable } from 'mobx';
import { get } from '@partsbadger/utils';
class Contact {
    constructor() {
        this.isLoading = true;
        this.requirements = [];
        this.documents = [];
        makeObservable(this, {
            // Observables
            isLoading: observable,
            requirements: observable,
            documents: observable,
            // Actions
            getAdditionalRequirementsContactAccount: action,
        });
    }
    async getAdditionalRequirementsContactAccount(contact_id) {
        this.isLoading = true;
        return await get(`/customer/contacts/${contact_id}/additional-requirements-documents/`)
            .then((response) => {
            this.requirements = response.requirements;
            this.documents = response.documents;
        })
            .finally(() => {
            this.isLoading = false;
        });
    }
}
export const ContactStore = new Contact();
