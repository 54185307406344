import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { get, patch, post, put, remove } from '@partsbadger/utils';
import { denormalize_data, denormalize_data_product, normalize_data } from '../components/LineItems/helpers';
import { message, notification } from 'antd';
const STATUS_IN_PROGRESS = ['In Progress', 'In Review'];
class Quote {
    constructor() {
        this.quote = null;
        this.currentUser = null;
        this.quoteAccountOverview = null;
        this.rfq = null;
        this.loading = false;
        this.masterProductsByPosition = [];
        this.similarProducts = undefined;
        this.lineItems = [];
        this.unitPricesOriginals = [];
        this.item = {}; // Dynamic Quantities
        this.quote_notifications = [];
        this.error = null;
        this.mcMasterProductResponse = {
            message: '',
            status: '',
            minQuantity: 0,
            detail: '',
        };
        this.additional_requirements = [];
        this.selected_master_product = null;
        this.partTolerances = [];
        this.holeTolerances = [];
        this.materials = [];
        this.finishes = [];
        this.coatings = [];
        this.master_products_with_replace_files = [];
        this.users = [];
        this.createLineItemFrom3dFile = async (file_id) => {
            if (!this.quote?.id) {
                return;
            }
            return await post(`/staff/quotes/${this.quote?.id}/create-from-step-file/`, {
                step_file: file_id,
            }).then((response) => {
                this.masterProductsByPosition.push(response.quote_has_master_product);
                this.lineItems.push(normalize_data(response.quoted_product));
                this.getQuote(this.quote?.id);
            });
        };
        this.createLineItemFrom2dFile = async (file_id) => {
            return await post(`/staff/quotes/${this.quote?.id}/create-from-drawing-file/`, {
                drawing_file: file_id,
                duplicate: true,
            }).then((response) => {
                this.masterProductsByPosition.push(response.quote_has_master_product);
                this.lineItems.push(normalize_data(response.quoted_product));
                this.getQuote(this.quote?.id);
            });
        };
        this.createLineItemFromExistingLineItems = async (line_items) => {
            return await post(`/staff/quotes/${this.quote?.id}/create-from-exiting-quoted-product/`, {
                quoted_products: line_items,
            }).then((response) => {
                response.quote_has_master_products.map((item) => this.masterProductsByPosition.push(item));
                response.quoted_products.map((item) => this.lineItems.push(normalize_data(item)));
                this.getQuote(this.quote?.id);
            });
        };
        this.createLineItemWithDimensions = async (payload) => {
            return await post(`/staff/quotes/${this.quote?.id}/create-with-dimensions/`, payload).then((response) => {
                this.masterProductsByPosition.push(response.quote_has_master_product);
                this.lineItems.push(normalize_data(response.quoted_product));
                this.getQuote(this.quote?.id);
            });
        };
        this.duplicate_line_item = async (line_items, quote_id) => {
            return await post(`/staff/quotes/${quote_id}/create-from-exiting-quoted-product/`, {
                quoted_products: line_items,
                new_quote: this.quote?.id,
            }).then((response) => {
                response.quote_has_master_products.map((item) => this.masterProductsByPosition.push(item));
                if (this.quote?.id) {
                    this.getMasterProducts(this.quote.id);
                    this.getLineItems(this.quote.id);
                }
            });
        };
        makeObservable(this, {
            // Observables
            quote: observable,
            currentUser: observable,
            quoteAccountOverview: observable,
            rfq: observable,
            loading: observable,
            masterProductsByPosition: observable,
            similarProducts: observable,
            lineItems: observable,
            unitPricesOriginals: observable,
            item: observable,
            quote_notifications: observable,
            error: observable,
            mcMasterProductResponse: observable,
            additional_requirements: observable,
            selected_master_product: observable,
            partTolerances: observable,
            holeTolerances: observable,
            materials: observable,
            finishes: observable,
            coatings: observable,
            master_products_with_replace_files: observable,
            users: observable,
            // Actions
            setQuote: action,
            setQuoteEditable: action,
            setUpdatedMasterProduct: action,
            setSelectedMasterProduct: action,
            setCurrentUser: action,
            getQuote: action,
            getRFQ: action,
            getQuoteAccountOverview: action,
            getTolerances: action,
            getMaterials: action,
            getFinishes: action,
            getCoatings: action,
            updateQuote: action,
            updateAccountsInQuoteAndRelatedItems: action,
            sendToPartners: action,
            duplicateQuote: action,
            deleteQuote: action,
            getMasterProducts: action,
            getMasterProductSimilarProducts: action,
            duplicateMasterProduct: action,
            duplicateQuotedProduct: action,
            deleteMasterProduct: action,
            getLineItems: action,
            getAdditionalRequirements: action,
            createLineItems: action,
            createLineItemFrom3dFile: action,
            createLineItemFrom2dFile: action,
            createLineItemFromExistingLineItems: action,
            createLineItemWithDimensions: action,
            duplicateLineItem: action,
            updateMultipleLineItems: action,
            recalculatePrices: action,
            deleteLineItem: action,
            sendToSalesRepresentative: action,
            saveItem: action,
            getCost: action,
            updateLT: action,
            getNotifications: action,
            updateAllLeadTime: action,
            analyzeAllItemsFiles: action,
            sendNotification: action,
            getRequote: action,
            upload3dRedactedFile: action,
            upload2dRedactedFile: action,
            removeRedactedFile: action,
            getMcMasterProduct: action,
            dynamicQuantitiesLeadTime: action,
            updateRequiredQuantities: action,
            updateQuoteLineQuantityItem: action,
            makeDynamicQuantityAdjustment: action,
            getDynamicPricesList: action,
            addQuoteItemQuantity: action,
            replaceFilesMasterProduct: action,
            syncDataByReplaceMasterProductFiles: action,
            duplicate_line_item: action,
            updateMasterProduct: action,
            addVendorQuote: action,
            exportQuotedProductsAsCSV: action,
            addLeadTine: action,
            getUsers: action,
            //Computed
            inProgress: computed,
            flagsAccountOverview: computed,
            flagsRfqInformation: computed,
        });
    }
    clean() {
        this.quote = null;
        this.quoteAccountOverview = null;
        this.rfq = null;
        this.loading = false;
        this.masterProductsByPosition = [];
        this.similarProducts = undefined;
        this.lineItems = [];
        this.unitPricesOriginals = [];
        this.item = {};
        this.quote_notifications = [];
        this.error = null;
        this.mcMasterProductResponse = {
            message: '',
            status: '',
            minQuantity: 0,
            detail: '',
        };
        this.additional_requirements = [];
        this.selected_master_product = null;
    }
    setQuote(data) {
        this.quote = data;
    }
    setQuoteEditable(isEditable) {
        if (this.quote) {
            this.quote.editable = isEditable;
        }
    }
    setUpdatedMasterProduct(updated_master_product) {
        runInAction(() => {
            this.masterProductsByPosition = this.masterProductsByPosition.map(item => {
                if (item.master_product.id == updated_master_product.id) {
                    return {
                        ...item,
                        master_product: {
                            ...updated_master_product,
                        },
                    };
                }
                return item;
            });
        });
    }
    setSelectedMasterProduct(master_product) {
        runInAction(() => {
            this.selected_master_product = master_product;
        });
    }
    get inProgress() {
        if (this.quote) {
            return STATUS_IN_PROGRESS.includes(this.quote.stage);
        }
        return false;
    }
    setCurrentUser(user) {
        this.currentUser = user;
    }
    async getQuote(id) {
        const quote_id = id ? id : this.quote?.id;
        this.loading = true;
        await get(`/staff/quotes/${quote_id}/`)
            .then(data => {
            this.quote = data;
            this.loading = false;
        })
            .catch((error) => (this.error = error));
    }
    async getRFQ(id) {
        await get(`/staff/rfq/${id}/`)
            .then(data => {
            this.rfq = data;
        })
            .catch((error) => (this.error = error));
    }
    async getQuoteAccountOverview(id) {
        this.loading = true;
        await get(`/staff/quotes/${id}/account-overview/`).then(data => {
            this.quoteAccountOverview = data;
            this.loading = false;
        });
    }
    async getTolerances() {
        await get(`/staff/part-tolerances/`)
            .then(data => {
            this.partTolerances = data.results;
        })
            .catch(error => {
            console.error(error);
        });
        await get(`/staff/hole-tolerances/`)
            .then(data => {
            this.holeTolerances = data.results;
        })
            .catch(error => {
            console.error(error);
        });
    }
    async getMaterials(params) {
        await get(`/staff/materials/`, {
            params: params,
        })
            .then(data => {
            this.materials = data.results;
        })
            .catch(error => {
            console.error(error);
        });
    }
    async getFinishes(params) {
        await get(`/staff/finishes/`, {
            params: params,
        })
            .then(data => {
            this.finishes = data.results;
        })
            .catch(error => {
            console.error(error);
        });
    }
    async getCoatings(params) {
        await get(`/staff/coatings/`, {
            params: params,
        })
            .then(data => {
            this.coatings = data.results;
        })
            .catch(error => {
            console.error(error);
        });
    }
    async updateQuote(id, payload) {
        this.loading = true;
        this.error = null;
        return await patch(`/staff/quotes/${id}/`, payload)
            .then(() => {
            this.getQuote(id);
            this.getMasterProducts(id);
        })
            .catch((error) => (this.error = error))
            .finally(() => {
            this.loading = false;
        });
    }
    async updateAccountsInQuoteAndRelatedItems(quote_id, payload) {
        this.loading = true;
        this.error = null;
        await patch(`/staff/quotes/${quote_id}/update-related-data/`, payload)
            .then((data) => {
            this.loading = false;
            this.setQuote(data);
        })
            .catch((error) => {
            this.error = error;
        });
    }
    async sendToPartners(id, payload) {
        this.loading = true;
        this.error = null;
        return await patch(`/staff/quotes/${id}/sent-to-partners/`, payload)
            .then(() => {
            this.loading = false;
            this.getQuote(id);
            this.getMasterProducts(id);
        })
            .catch((error) => {
            this.error = error;
        })
            .finally(() => {
            this.loading = false;
        });
    }
    async duplicateQuote(id) {
        this.loading = true;
        return await post(`/staff/quotes/${id}/duplicate/`);
    }
    async deleteQuote(id, history) {
        return await post(`/staff/quotes/${id}/delete/`)
            .then((response) => {
            history.push(`/quotes/`);
        })
            .catch((error) => console.log(error));
    }
    async getMasterProducts(quote_id) {
        return await get(`/staff/quotes/${quote_id}/master-products/`).then((response) => {
            this.masterProductsByPosition = response;
            this.master_products_with_replace_files = response.map(resp => resp.master_product);
        });
    }
    async getMasterProductSimilarProducts(quote_id) {
        return await get(`/staff/quotes/${quote_id}/similar-products/`).then((response) => {
            this.similarProducts = response;
        });
    }
    async duplicateMasterProduct(master_product_id) {
        await post(`/staff/quotes/${this.quote?.id}/duplicate-master-product/`, {
            master_product: master_product_id,
        }).then((response) => {
            const new_item = response.quote_has_master_product;
            this.masterProductsByPosition = this.masterProductsByPosition.map((item) => item.master_product.id === master_product_id ? new_item : item);
            const data = response.quoted_products.map((r) => normalize_data(r));
            this.lineItems = this.lineItems.map(previous_item => {
                const found = data.find((new_item) => new_item.id === previous_item.id);
                return found ? found : previous_item;
            });
            this.getQuote(this.quote?.id);
        });
    }
    async duplicateQuotedProduct(master_product_id) {
        await post(`/staff/quotes/${this.quote?.id}/duplicate-quoted-product/`, {
            master_product: master_product_id,
        }).then(() => {
            if (this.quote) {
                this.getQuote(this.quote.id);
                this.getMasterProducts(this.quote.id);
                this.getLineItems(this.quote.id);
            }
        });
    }
    async deleteMasterProduct(master_product_id) {
        this.masterProductsByPosition = this.masterProductsByPosition.filter(item => item.master_product.id !== master_product_id);
        await post(`/staff/quotes/${this.quote?.id}/delete-master-product/`, {
            master_product: master_product_id,
        })
            .then(() => this.getQuote(this.quote?.id))
            .catch((error) => notification.error({
            message: 'Error',
        }));
    }
    async getLineItems(quote_id) {
        try {
            await get(`/staff/quotes/${quote_id}/quoted-products/`).then(response => {
                runInAction(() => {
                    this.lineItems = response.results.map((record) => normalize_data(record));
                    this.unitPricesOriginals = response.results.map((record) => {
                        return {
                            line_item_id: record.id,
                            unit_price: record.unit_price,
                            manual_adjustment: Number(record.manual_adjustment),
                        };
                    });
                });
            });
        }
        catch (err) {
            console.log(`GetLineItems Error: ${err}`);
        }
    }
    async getAdditionalRequirements() {
        await get(`/customer/additional-requirements/`).then((response) => {
            this.additional_requirements = response.results;
        });
    }
    async createLineItems(id, payload) {
        await post(`/staff/quotes/${id}/create-variation/`, payload).then((response) => {
            this.lineItems.push(normalize_data(response.quoted_product));
            if (this.quote?.id) {
                this.getQuote(this.quote?.id);
            }
        });
    }
    async addNoCoatingVariation(id) {
        await post(`/staff/quoted-products/${id}/add-no-coating-variation/`, []).then((response) => {
            this.lineItems.push(normalize_data(response));
            if (this.quote?.id) {
                this.getQuote(this.quote?.id);
            }
        });
    }
    async duplicateLineItem(id) {
        await post('/staff/quoted-products/' + id + '/duplicate/', [])
            .then((response) => {
            this.lineItems.push(normalize_data(response));
            this.getQuote(this.quote?.id);
        })
            .catch((error) => notification.error({
            message: 'Error',
        }));
    }
    async updateMultipleLineItems(payload) {
        return await patch(`/staff/quotes/${this.quote?.id}/update-multiple/`, payload)
            .then((response) => {
            const data = response.map((r) => normalize_data(r));
            this.lineItems = this.lineItems.map(previous_item => {
                const found = data.find((new_item) => new_item.id === previous_item.id);
                return found ? found : previous_item;
            });
            this.getQuote(this.quote?.id);
        })
            .catch((error) => notification.error({
            message: 'Error',
        }));
    }
    async recalculatePrices(payload) {
        this.loading = true;
        return await patch(`/staff/quotes/${this.quote?.id}/recalculate-prices/`, payload).then(response => {
            this.loading = false;
            const data = response.map((r) => normalize_data(r));
            this.lineItems = this.lineItems.map(previous_item => {
                const found = data.find((new_item) => new_item.id === previous_item.id);
                return found ? found : previous_item;
            });
            this.getQuote(this.quote?.id);
            message.success('Prices were updated');
        });
    }
    async deleteLineItem(id) {
        this.lineItems = this.lineItems.filter(r => r.id !== id);
        await remove(`/staff/quoted-products/${id}/`)
            .then(() => this.getQuote(this.quote?.id))
            .catch((error) => notification.error({ message: 'Error' }));
    }
    async sendToSalesRepresentative(id, payload) {
        return await post(`/staff/quotes/${id}/send-to-sales-representative/`, payload).catch(() => notification.error({ message: 'Error' }));
    }
    async saveItem(record) {
        const payload = denormalize_data_product(record);
        return await patch(`/staff/quoted-products/${record.id}/`, payload).then((response) => {
            const item = normalize_data(response);
            this.lineItems = this.lineItems.map(r => (r.id === item.id ? item : r));
            this.getQuote(this.quote?.id);
        });
    }
    async getCost(record) {
        const payload = denormalize_data(record);
        try {
            return await put(`/staff/quoted-products/${record.id}/get-cost/`, payload).then((response) => {
                const updated_record = {
                    ...record,
                    safe_auto_quote: response.safe_auto_quote,
                    setup_price: response.setup_price,
                    vendor_price: response.vendor_price,
                    work_price: response.work_price,
                    unit_price: response.unit_price,
                    total_price: response.total_price,
                    shipping_cost: response.shipping_cost,
                };
                this.lineItems = this.lineItems.map(r => (r.id === record.id ? normalize_data(updated_record) : r));
            });
        }
        catch (err) {
            console.log(`GetCost Error: ${err}`);
        }
    }
    async updateLT(record) {
        const payload = denormalize_data(record);
        const response = await put(`/staff/quoted-products/${record.id}/update-lt/`, payload);
        const updated_record = {
            ...record,
            lead_time: response.lead_time,
        };
        this.lineItems = this.lineItems.map(r => (r.id === record.id ? updated_record : r));
        return response.lead_time;
    }
    async getNotifications() {
        if (this.quote?.id) {
            await get(`/staff/quotes/${this.quote?.id}/notifications/`).then((response) => {
                this.quote_notifications = response;
            });
        }
    }
    async updateAllLeadTime() {
        if (this.quote?.id) {
            return await post(`/staff/quotes/${this.quote?.id}/update-lt-in-all-items/`);
        }
    }
    async analyzeAllItemsFiles(id) {
        if (this.quote) {
            post('/staff/master-products/' + id + '/analyze/').catch(e => (this.error = e));
        }
    }
    async sendNotification(payload) {
        const formData = new FormData();
        if (payload.body) {
            formData.append('body', payload.body);
        }
        payload.attachments.forEach(item => {
            formData.append('attachments_files', item);
        });
        if (payload?.notify_owner) {
            formData.append('notify_owner', String(payload.notify_owner));
        }
        if (payload.carbon_copy) {
            payload.carbon_copy.forEach((item) => {
                formData.append('carbon_copy', item?.key);
            });
        }
        await post(`/staff/quotes/${this.quote?.id}/notifications/`, formData, {}, true)
            .then((response) => {
            this.quote_notifications = response;
        })
            .catch((error) => (this.error = error));
    }
    async getRequote(id) {
        return await post(`/staff/quotes/${id}/requote/`);
    }
    async upload3dRedactedFile(step_id, file) {
        const formData = new FormData();
        formData.append('redacted_file', file);
        return await patch(`/staff/step-files/${step_id}/`, formData, true);
    }
    async upload2dRedactedFile(drawing_id, file) {
        const formData = new FormData();
        formData.append('redacted_file', file);
        return await patch(`/staff/drawing-files/${drawing_id}/`, formData, true);
    }
    async removeRedactedFile(drawing_id, payload) {
        const formData = new FormData();
        formData.append('master-product', drawing_id + '');
        return await post(`/staff/master-products/${drawing_id}/remove-redacted-file/`, payload);
    }
    async getMcMasterProduct(product) {
        return await post(`/staff/quotes/${this.quote?.id}/create-mcmaster-product/`, product).then((response) => {
            if (response.status === 201) {
                this.masterProductsByPosition.push(response.quote_has_master_product);
                this.lineItems.push(normalize_data(response.quoted_product));
                this.getQuote(this.quote?.id);
                // var material = response.Specifications.filter(function(e){return e.Attribute == 'Material'})
                const price = response.json.Links.filter(function (e) {
                    return e.Key === 'Price';
                });
                this.mcMasterProductResponse = {
                    message: response.message,
                    status: response.status,
                    minQuantity: price[0].Value[0].MinimumQuantity,
                    detail: response.json.DetailDescription,
                };
            }
            else {
                this.mcMasterProductResponse = {
                    message: response.message,
                    status: response.status,
                };
            }
        });
    }
    // Dynamic Quantities
    async dynamicQuantitiesLeadTime(payload) {
        return await post(`/staff/dynamic_quantities/lead_time/`, payload).then((response) => {
            const item = normalize_data(response);
            this.lineItems = this.lineItems.map(r => (r.id === item.id ? item : r));
            this.item = item;
        });
    }
    async updateRequiredQuantities(id, payload) {
        const response = await post(`/staff/quoted-products/${id}/get_dynamic_quantities/`, payload);
        runInAction(() => {
            this.getQuote(this.quote?.id);
        });
        this.lineItems = this.lineItems.map(item => {
            if (item.id === response.id) {
                return normalize_data(response);
            }
            return item;
        });
    }
    async updateQuoteLineQuantityItem(quote_item_id, quote_item_quantity_id, payload) {
        const response = await patch(`/staff/quoted-products/${quote_item_id}/quote-item-quantity/${quote_item_quantity_id}/`, payload);
        this.lineItems = this.lineItems.map(item => {
            if (item.id === quote_item_id) {
                item.quote_item_quantities = item.quote_item_quantities.map(item_quantity => {
                    if (item_quantity.id === response.id) {
                        return response;
                    }
                    return item_quantity;
                });
                return item;
            }
            return item;
        });
    }
    async makeDynamicQuantityAdjustment(quote_item_id, payload) {
        const response = await post(`/staff/quoted-products/${quote_item_id}/adjust_quote_item_quantities/`, payload);
        this.lineItems = this.lineItems.map(item => {
            if (item.id === response.id) {
                return normalize_data(response);
            }
            return item;
        });
    }
    async getDynamicPricesList(quote_item_id, page = 1) {
        return await get(`/staff/quoted-products/${quote_item_id}/all-dynamic-quantities/`, {
            params: {
                page: page,
            },
        });
    }
    async addQuoteItemQuantity(quote_item_id, quantity) {
        const response = await post(`/staff/quoted-products/${quote_item_id}/add_quantity/`, {
            quantity: quantity,
        });
        this.lineItems = this.lineItems.map(item => {
            if (item.id === quote_item_id) {
                item.quote_item_quantities.push(response);
                item.quote_item_quantities = item.quote_item_quantities.sort((a, b) => a.quantity > b.quantity ? 1 : -1);
                return item;
            }
            return item;
        });
    }
    async replaceFilesMasterProduct(quote_id) {
        if (this.master_products_with_replace_files.length > 0) {
            return await post(`/staff/quotes/${quote_id}/replace-files-master-product/`, this.master_products_with_replace_files);
        }
    }
    syncDataByReplaceMasterProductFiles(data) {
        this.master_products_with_replace_files = data;
    }
    async updateMasterProduct(master_product_id, payload) {
        const response = await patch('/staff/master-products/' + master_product_id + '/', payload);
        if (this.quote) {
            await this.getLineItems(this.quote.id);
        }
        return response;
    }
    async addVendorQuote(quote_id, payload) {
        await post('/staff/quotes/' + quote_id + '/vendor-quote/', payload);
    }
    exportQuotedProductsAsCSV(quote_id) {
        return get(`/staff/quoted-products/export-quoted-products-csv/?quote_id=${quote_id}`);
    }
    async addLeadTine(payload) {
        if (this.quote) {
            await post('/staff/quotes/' + this.quote.id + '/add-lead-time-in-all-items/', payload);
            await this.getLineItems(this.quote.id);
        }
    }
    async getUsers(params) {
        return await get(`/staff/users/`, {
            params: params,
        }).then(data => {
            this.users = data.results;
        });
    }
    async checkIfExpiredQuote(quote_id) {
        return await get('/staff/quotes/' + quote_id + '/check-expired-price/');
    }
    get flagsAccountOverview() {
        let flags = 0;
        if (this.quoteAccountOverview && this.quoteAccountOverview.lifetime_value > 10000) {
            flags++;
        }
        return flags;
    }
    get flagsRfqInformation() {
        let flags = 0;
        if (this.quote && Number(this.quote.whole_quote_adjust) > 100) {
            flags++;
        }
        if (this.rfq && this.rfq.needs_to_create_3d_files) {
            flags++;
        }
        if (this.rfq && this.rfq.needs_to_create_2d_files) {
            flags++;
        }
        if (this.rfq && this.rfq.itar) {
            flags++;
        }
        if (this.rfq && this.rfq.other_notes && this.rfq.other_notes.length > 0) {
            flags++;
        }
        if (this.quote && this.quote.has_batch_shipments) {
            flags++;
        }
        return flags;
    }
}
export const QuoteStore = new Quote();
