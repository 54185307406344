import { Link } from 'react-router-dom';
import React from 'react';
import { Menu } from 'antd';
import { hasGroup, hasPermission } from './User';
import { MenuProps } from 'antd/lib/menu';

interface Props extends MenuProps {
    user: any;
}

const HeaderMenu = (props: Props) => {
    const isAuthenticated = props.user?.id;

    return (
        <Menu style={{ lineHeight: '64px' }} mode={props.mode ? props.mode : 'inline'}>
            <Menu.SubMenu title={<span className="submenu-title-wrapper">RFQs</span>}>
                {(hasPermission('quotes.view_rfq') || hasPermission('quotes.view_own_rfq')) && (
                    <Menu.Item key="rfq-list">
                        <Link to="/rfq">
                            <span className="nav-text"> RFQ List</span>
                        </Link>
                    </Menu.Item>
                )}
                {hasPermission('quotes.add_quote') && (
                    <Menu.Item key="get-quote">
                        <Link to="/get-quote/">
                            <span className="nav-text"> New RFQ</span>
                        </Link>
                    </Menu.Item>
                )}
            </Menu.SubMenu>

            {isAuthenticated && (
                <Menu.SubMenu title={<span className="submenu-title-wrapper">Accounts/Contacts</span>}>
                    {isAuthenticated &&
                        (hasPermission('accounts.view_account') || hasPermission('accounts.view_own_account')) && (
                            <Menu.Item key="accounts">
                                <Link to="/accounts/">
                                    <span className="nav-text"> Accounts List</span>
                                </Link>
                            </Menu.Item>
                        )}

                    {isAuthenticated &&
                        (hasPermission('accounts.view_contact') || hasPermission('accounts.view_own_contact')) && (
                            <Menu.Item key="contacts">
                                <Link to="/contacts/">
                                    <span className="nav-text"> Contacts List</span>
                                </Link>
                            </Menu.Item>
                        )}

                    {isAuthenticated && hasGroup('Lead Department') && (
                        <Menu.Item key={'leads'}>
                            <Link to="/leads/conversion-tool">
                                <span className="nav-text">Convert Leads</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('accounts.change_own_contactrequirement') && (
                        <Menu.Item key="accounts-requirements">
                            <Link to="/accounts-requirements">
                                <span className="nav-text">Accounts Requirements</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {hasPermission('accounts.change_own_contactrequirement') && (
                        <Menu.Item key="100">
                            <Link to="/additional-requirements">
                                <span className="nav-text">Contact Requirements</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {(hasPermission('accounts.change_own_contact') || hasPermission('accounts.change_contact')) && (
                        <Menu.Item key="101">
                            <Link to="/contact/taxes-form">
                                <span className="nav-text"> Tax Exempt Form</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {(hasPermission('accounts.change_own_contact') || hasPermission('accounts.change_contact')) && (
                        <Menu.Item key="102">
                            <Link to="/contact/addresses">
                                <span className="nav-text"> Addresses</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {(hasPermission('accounts.change_own_contact') || hasPermission('accounts.change_contact')) && (
                        <Menu.Item key="103">
                            <Link to="/contact/nda-form">
                                <span className="nav-text"> NDA Form</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('accounts.change_contact') && (
                        <Menu.Item key="104">
                            <Link to="/contact/merge-contacts">
                                <span className="nav-text"> Merge Contacts</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('accounts.change_contact') && (
                        <Menu.Item key="105">
                            <Link to="/contact/merge-accounts">
                                <span className="nav-text"> Merge Accounts</span>
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}

            {hasPermission('quotes.view_deal') && (
                <Menu.Item key="5">
                    <Link to="/deals">
                        <span className="nav-text">Deals</span>
                    </Link>
                </Menu.Item>
            )}
            {(hasPermission('quotes.view_quote') || hasPermission('quotes.view_own_quote')) && (
                <Menu.SubMenu title={<span className="submenu-title-wrapper">Quotes</span>}>
                    <Menu.Item key="6">
                        <Link to="/quotes">
                            <span className="nav-text">Quotes List</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="6-1">
                        <Link to="/quoted-products">
                            <span className="nav-text">Quoted Products</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="6-2">
                        <Link to="/partsbadger-production">
                            <span className="nav-text">PartsBadger Production</span>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            )}
            {/*{(hasPermission('quotes.view_quote') || hasPermission('quotes.view_own_quote')) && (*/}
            {/*    <Menu.SubMenu title={<span className="submenu-title-wrapper">Parts</span>}>*/}
            {/*        <Menu.Item key="6">*/}
            {/*            <Link to="/parts">*/}
            {/*                <span className="nav-text">Parts List</span>*/}
            {/*            </Link>*/}
            {/*        </Menu.Item>*/}
            {/*    </Menu.SubMenu>*/}
            {/*)}*/}
            {isAuthenticated && (
                <Menu.SubMenu title={<span className="submenu-title-wrapper">Order</span>}>
                    {(hasPermission('salesorders.view_salesorder') ||
                        hasPermission('salesorders.view_own_salesorder')) && (
                        <Menu.Item key="sales-orders-list">
                            <Link to="/sales-orders/">
                                <span className="nav-text">Sales Orders</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="quickturn-sales-orders-list">
                            <Link to="/quickturn/sales-orders/">
                                <span className="nav-text">QuickTurn Orders</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.add_salesorder') && (
                        <Menu.Item key="sales-orders">
                            <Link to="/sales-order/new/">
                                <span className="nav-text">New Sales Order</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.change_salesorder') && (
                        <Menu.Item key="update-hts-sales-order">
                            <Link to="/sales-orders/hts/">
                                <span className="nav-text">Update HTS Code</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_partqualityissuereport') && (
                        <Menu.Item key="pqir">
                            <Link to="/sales-orders/quality-issue/">
                                <span className="nav-text">Report Quality Issue</span>
                            </Link>
                        </Menu.Item>
                    )}

                    <Menu.Item key="credit_card_submission">
                        <Link to="/sales-orders/credit-card-submission/">
                            <span className="nav-text">Credit Card Submission</span>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            )}
            {isAuthenticated && (
                <Menu.SubMenu title={<span className="submenu-title-wrapper">Production</span>}>
                    <Menu.Item key="shipping-receiving">
                        <Link to="/shipping-receiving/">
                            <span className="nav-text">Shipping and Receiving</span>
                        </Link>
                    </Menu.Item>
                    {hasPermission('inventory.view_inventory') && (
                        <Menu.Item key="view-inventory">
                            <Link to="/inventory/">
                                <span className="nav-text">Inventory</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {hasPermission('inventory.view_inventory') && (
                        <Menu.Item key="view-storage-locations">
                            <Link to="/storage-location/">
                                <span className="nav-text">Storage Location</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {hasGroup('Production') && (
                        <Menu.Item key="view-vendor-users">
                            <Link to="/production/vendors/">
                                <span className="nav-text">Vendors</span>
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key="view-domestic-vendors">
                        <Link to="/production/domestic-vendors">
                            <span className="nav-text">Domestic Vendors</span>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            )}
            {isAuthenticated && hasGroup('Finance') && (
                <Menu.SubMenu title={<span className="nav-text">Finance</span>}>
                    {hasPermission('finance.view_own_purchaseorder') && (
                        <Menu.Item key="purchase-orders">
                            <Link to="/purchase-orders/">
                                <span className="nav-text">Purchase Orders</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {hasPermission('finance.view_own_purchaseorder') && (
                        <Menu.Item key="invoices">
                            <Link to="/invoices/">
                                <span className="nav-text">Invoices</span>
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}

            {isAuthenticated && hasPermission('reports.view_report') && (
                <Menu.SubMenu title={<span className="nav-text">Reports</span>}>
                    {hasPermission('quotes.view_quote') && (
                        <Menu.Item key="111">
                            <Link to="/reports/quotes">
                                <span className="nav-text">Quoted Products</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('quotes.view_quote') && (
                        <Menu.SubMenu title={<span className="submenu-title-wrapper">Outtake</span>}>
                            <Menu.Item key="118">
                                <Link to="/reports/outtake-audit-report/">
                                    <span className="nav-text">Outtake Audit Report</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="119">
                                <Link to="/reports/purchase-orders-z-outtakes/">
                                    <span className="nav-text">PO Z Outtakes Report</span>
                                </Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    )}

                    {hasPermission('quotes.view_quote') && (
                        <Menu.SubMenu title={<span className="submenu-title-wrapper">Intake</span>}>
                            <Menu.Item key="117">
                                <Link to="/reports/line-items-only-intake/">
                                    <span className="nav-text">Line Items Only Intake</span>
                                </Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    )}

                    {hasPermission('quotes.view_quote') && (
                        <Menu.Item key="112">
                            <Link to="/reports/requotes">
                                <span className="nav-text">Requotes</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('quotes.view_quote') && (
                        <Menu.Item key="report-avg-times">
                            <Link to="/reports/report-avg-times/">
                                <span className="nav-text">Quote Time Metrics</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="113">
                            <Link to="/reports/must-ship-today">
                                <span className="nav-text">Must Ship Today</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="114">
                            <Link to="/reports/today-intakes">
                                <span className="nav-text">Today Intakes</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="115">
                            <Link to="/reports/cancelled-intake">
                                <span className="nav-text">Cancelled Intake</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="116">
                            <Link to="/reports/quality-kpis">
                                <span className="nav-text">Quality KPIs</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="120">
                            <Link to="/reports/lead-time-tracking/">
                                <span className="nav-text">Lead Time Tracking</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="121">
                            <Link to="/reports/order-time-tracking/">
                                <span className="nav-text">Order Time Tracking</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="122">
                            <Link to="/reports/fast-lane-products-report/">
                                <span className="nav-text">FastLane Products Report</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="123">
                            <Link to="/reports/yesterday-transactions/">
                                <span className="nav-text">Yesterday Transactions</span>
                            </Link>
                        </Menu.Item>
                    )}
                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="124">
                            <Link to="/reports/orders-ready-to-be-processed/">
                                <span className="nav-text">Orders Ready to be processed</span>
                            </Link>
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.view_salesorder') && (
                        <Menu.Item key="125">
                            <Link to="/reports/sales-ticker/">
                                <span className="nav-text">Sales Ticker</span>
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}

            <Menu.SubMenu title={<span className="submenu-title-wrapper">System</span>}>
                {hasPermission('rules.view_businessrule') && (
                    <Menu.Item key="business-rules">
                        <Link to="/business-rules/">
                            <span className="nav-text">Rules</span>
                        </Link>
                    </Menu.Item>
                )}

                {hasPermission('rules.view_businessrule') && (
                    <Menu.Item key="templates">
                        <Link to="/templates/">
                            <span className="nav-text">Templates</span>
                        </Link>
                    </Menu.Item>
                )}
            </Menu.SubMenu>

            {isAuthenticated && (
                <Menu.Item key="12">
                    <Link to="/viewer">
                        <span className="nav-text">3D Viewer</span>
                    </Link>
                </Menu.Item>
            )}

            <Menu.Item key="/">
                <Link to="/">
                    <span className="nav-text"> Changes Log</span>
                </Link>
            </Menu.Item>
        </Menu>
    );
};

export default HeaderMenu;
