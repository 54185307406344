import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, notification, Spin, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import moment from 'moment';
import { InputsInformationForm, ItemsTable } from '../components';
import { InputsTotalsForm } from '../components/InputsTotalsForm';
import { observer } from 'mobx-react';
import { ErrorRender, Users } from '@partsbadger/library';
import { hasGroup } from 'quotes-frontend/src/components/User';
const PurchaseOrderEditForm = observer((props) => {
    const { form } = props;
    const [loading, setLoading] = useState(false);
    const [change_address, setChangeAddress] = useState(false);
    const [category, setCategory] = useState(null);
    const { getFieldDecorator } = form;
    const history = useHistory();
    const getPurchaseOrder = async (id) => {
        await PurchaseOrderStore.get(id);
        if (PurchaseOrderStore.purchase_order?.line_items) {
            PurchaseOrderStore.sync_line_items(PurchaseOrderStore.purchase_order?.line_items);
            PurchaseOrderStore.recalculate_totals();
        }
        if (PurchaseOrderStore.purchase_order && PurchaseOrderStore.purchase_order.category) {
            setCategory(PurchaseOrderStore.purchase_order.category);
        }
    };
    const approved = async () => {
        setLoading(true);
        try {
            await PurchaseOrderStore.approve_purchase_order(props.purchase_order_id);
            notification.success({
                message: 'Success',
                description: 'Order successfully approved',
            });
            setLoading(false);
            await getPurchaseOrder(props.purchase_order_id);
        }
        catch (error) {
            notification.error({
                message: 'Error',
                description: React.createElement(ErrorRender, { error: error }),
            });
            setLoading(false);
        }
    };
    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (PurchaseOrderStore.purchase_order && PurchaseOrderStore.line_items.length > 0) {
                    const getUniqueSalesOrder = PurchaseOrderStore.hasUniqueSalesOrderId();
                    const payload = {
                        billing_city: values.billing_city,
                        billing_zipcode: values.billing_code,
                        billing_country: values.billing_country,
                        billing_state: values.billing_state,
                        billing_street: values.billing_street,
                        carrier: values.carrier,
                        category: values.category,
                        pb_point_of_contact: values.pb_point_of_contact,
                        due_date: moment(values.due_date).format('YYYY-MM-DD'),
                        line_items: PurchaseOrderStore.line_items.map(item => {
                            return {
                                ...item,
                                sales_order_line_items: item.sales_order_line_items?.map(({ id }) => id),
                            };
                        }),
                        name: 'PO-1',
                        notes: values.notes,
                        payment_terms: values.payment_terms,
                        requisition_number: values.requisition_number,
                        sales_order: values.sales_order && getUniqueSalesOrder ? getUniqueSalesOrder.soId : null,
                        shipping_city: values.change_address ? values.billing_city : values.shipping_city,
                        shipping_zipcode: values.change_address ? values.billing_code : values.shipping_code,
                        shipping_country: values.change_address ? values.billing_country : values.shipping_country,
                        shipping_state: values.change_address ? values.billing_state : values.shipping_state,
                        shipping_street: values.change_address ? values.billing_street : values.shipping_street,
                        ship_method: values.shipping_method,
                        vendor: values.vendor ? values.vendor.key : null,
                        terms_and_conditions: values.terms_and_conditions,
                        review_and_authorized: values.review_and_authorized,
                        discount_type: purchase_order.discount_type,
                        discount: purchase_order.discount ? purchase_order.discount : 0,
                        taxes_rate: purchase_order.taxes_rate ? purchase_order.taxes_rate.toFixed(2) : 0,
                        tax: purchase_order.tax ? purchase_order.tax.toFixed(2) : 0,
                        adjustment: purchase_order.adjustment ? purchase_order.adjustment : 0,
                        shipping_cost: purchase_order.shipping_cost ? purchase_order.shipping_cost : 0,
                        internal_reference: values.internal_reference,
                        owner: values.owner,
                        address: change_address,
                    };
                    setLoading(true);
                    PurchaseOrderStore.patch(payload, props.purchase_order_id)
                        .then(() => {
                        PurchaseOrderStore.sync_line_items();
                        props.onSubmitted();
                    })
                        .catch((err) => {
                        notification.error({
                            message: React.createElement(ErrorRender, { error: err }),
                            placement: 'topRight',
                        });
                    })
                        .finally(() => setLoading(false));
                }
                else {
                    message.error('You must first add one or more line items');
                }
            }
        });
    };
    useEffect(() => {
        if (props.purchase_order_id) {
            getPurchaseOrder(props.purchase_order_id);
        }
        PurchaseOrderStore.sync_line_items();
    }, [props.purchase_order_id]);
    useEffect(() => {
        const params = new URLSearchParams();
        if (category == 'PartsBadger Production') {
            params.append('category', 'Form31');
        }
        history.push({ search: params.toString() });
    }, [category]);
    if (!PurchaseOrderStore.purchase_order) {
        return React.createElement(Spin, { spinning: true, tip: 'Loading...' });
    }
    const purchase_order = PurchaseOrderStore.purchase_order;
    const sales_order = PurchaseOrderStore.sales_order;
    return (React.createElement("div", { className: 'flex flex-col' },
        purchase_order && purchase_order.name && (React.createElement("div", { className: 'flex flex-row' },
            React.createElement("h1", { className: 'text-2xl font-bold pl-5' }, purchase_order.name))),
        React.createElement("div", { className: 'flex flex-row pl-5' }, (purchase_order.category === 'PartsBadger Production' || category === 'PartsBadger Production') && (React.createElement("span", { className: 'font-bold' },
            React.createElement(Tag, { color: 'green' }, "Form 31 11/05/2020 Authorized by: Brandon Spenneberg, Ops. Mgr.")))),
        React.createElement("div", { className: 'flex flex-row-reverse' },
            React.createElement("span", { className: 'font-bold' },
                "Order Status:",
                ' ',
                React.createElement(Tag, { color: purchase_order.approved ? 'green' : 'red' }, purchase_order.approved ? 'Approved' : 'Not Aproved'))),
        React.createElement("div", null,
            React.createElement(Spin, { spinning: loading },
                React.createElement(Form, { onSubmit: handleSubmit },
                    React.createElement(InputsInformationForm, { getFieldDecorator: getFieldDecorator, change_address: change_address, setChangeAddress: setChangeAddress, setCategory: setCategory, purchase_order: purchase_order, sales_order: sales_order, onSalesOrderChange: async (value) => {
                            await PurchaseOrderStore.get_sales_order(value);
                            if (value === '') {
                                PurchaseOrderStore.removeSalesOrder();
                            }
                        }, setZXLineItems: () => {
                            PurchaseOrderStore.set_zx_line_items();
                        }, setFieldsValue: form.setFieldsValue, isFieldTouched: form.isFieldTouched }),
                    React.createElement(ItemsTable, { form: form, line_items: PurchaseOrderStore.line_items, create: false, po_id: props.purchase_order_id, category: category }),
                    React.createElement("div", { className: "flex flex-row p-4" },
                        React.createElement("div", { className: 'w-1/3' },
                            React.createElement(Form.Item, { label: 'Terms and Conditions' }, getFieldDecorator('terms_and_conditions', {
                                initialValue: PurchaseOrderStore.purchase_order
                                    ? PurchaseOrderStore.purchase_order.terms_and_conditions
                                    : '',
                            })(React.createElement(Input.TextArea, { rows: 3 }))),
                            category === 'PartsBadger Production' && (React.createElement(React.Fragment, null,
                                React.createElement(Form.Item, { label: 'Review and Authorized' }, getFieldDecorator('review_and_authorized', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input a name!',
                                        },
                                    ],
                                    initialValue: PurchaseOrderStore.purchase_order
                                        ? PurchaseOrderStore.purchase_order.review_and_authorized
                                        : '',
                                })(React.createElement(Input, { placeholder: 'Input a name' }))))),
                            React.createElement(Form.Item, { label: 'Owner' }, getFieldDecorator('owner', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select a user!',
                                    },
                                ],
                                initialValue: {
                                    key: props.current_user.id,
                                    label: props.current_user.fullname,
                                },
                            })(React.createElement(Users, { users: props.users })))),
                        React.createElement("div", { className: 'w-2/3' },
                            React.createElement(InputsTotalsForm, null))),
                    React.createElement("div", { className: "flex justify-end pt-2" },
                        React.createElement(Button, { type: "default", className: 'mx-2', onClick: () => history.replace('/purchase-orders') }, "Cancel"),
                        React.createElement(Button, { type: "primary", className: 'mx-4', loading: loading, htmlType: "submit" }, "Save Changes"),
                        hasGroup('Purchase Order Approvers') && purchase_order.approved === false && (React.createElement(Button, { type: "primary", className: 'mx-4', loading: loading, style: { backgroundColor: 'green', borderColor: 'green' }, onClick: approved }, "Approve"))))))));
});
export default Form.create()(PurchaseOrderEditForm);
