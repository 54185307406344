import axios from 'axios';
import { API_ROOT } from '@partsbadger/utils';
//@ts-ignore
const qs = require('qs');
/**
 * @deprecated Use @partsbadger/utils instead
 */
function getHeaders() {
    const token = localStorage.getItem('token');
    if (token) {
        return {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
        };
    }
    else {
        return {
            'Content-Type': 'application/json',
        };
    }
}
/**
 * @deprecated Use @partsbadger/utils instead
 */
export const get = (url, params = {}, options = {}) => {
    return axios.get(API_ROOT + url, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        headers: getHeaders(),
        ...options,
    });
};
/**
 * @deprecated Use @partsbadger/utils instead
 */
export const post = (url, payload = {}, options = {}) => {
    return axios.post(API_ROOT + url, payload, {
        headers: getHeaders(),
        ...options,
    });
};
export const patch = (url, payload = {}) => {
    return axios.patch(API_ROOT + url, payload, {
        headers: getHeaders(),
    });
};
/**
 * @deprecated Use @partsbadger/utils instead
 */
export const remove = url => {
    return axios.delete(API_ROOT + url, {
        headers: getHeaders(),
    });
};
export const getFile = url => {
    return fetch(API_ROOT + url, {
        method: 'GET',
        //@ts-ignore
        headers: getHeaders(),
    });
};
export const printSalesOrder = url => {
    return fetch(API_ROOT + url, {
        method: 'POST',
        //@ts-ignore
        headers: getHeaders(),
    });
};
export const get_order_documents = (url, params = {}, options = {}) => {
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };
    return axios.get(API_ROOT + url, { headers });
};
export const update_domestic_order_status = (url, params = {}, options = {}) => {
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };
    return axios.get(API_ROOT + url, { headers });
};
