import React from 'react';
import Humanize from 'humanize-plus';
export const Price = ({ value, loading, style }) => {
    const val = typeof value === 'string' ? parseFloat(value) : value;
    if (value >= 0) {
        return React.createElement("span", { style: style },
            "$",
            Humanize.formatNumber(val, 2));
    }
    return React.createElement("span", { style: style }, "$0.00");
};
