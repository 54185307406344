import React from 'react';
const FormErrorParser = ({ errors, isAntd4 = false }) => {
    const items = Object.keys(errors).map(key => {
        const error_field = errors[key];
        if (isAntd4) {
            return error_field.errors.map((e, index) => React.createElement("li", { key: key }, e));
        }
        return error_field.errors.map(e => React.createElement("li", { key: e.field }, e.message));
    });
    return React.createElement("ul", null, items);
};
export default FormErrorParser;
