import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Radio, Row, Upload, Spin, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { QuoteStore } from '../../Stores';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
export const VendorRFQSubmissionForm = observer(({ quote_id, loading, onSubmitForm, selected_items }) => {
    const lineItems = QuoteStore.lineItems.filter((line_item) => line_item.product && line_item.product.master_product);
    // get all master products that have dynamic quantities.
    const dynamicProducts = lineItems
        .filter((line_item) => line_item.has_dynamic_quantities && line_item.product && line_item.product.master_product)
        .map(line_item => line_item.product.master_product);
    // remove dynamic products from selected items
    selected_items = selected_items.filter(id => !dynamicProducts.includes(id));
    const [notes, setNotes] = useState('');
    const [rfqType, setRFQType] = useState('foreign');
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [selectedItems, setSelectedItems] = useState(selected_items);
    /**
     * Add or remove items from the selected array list
     */
    const handleSelect = (key, selected) => {
        if (selected) {
            setSelectedItems([...selectedItems, key]);
        }
        else {
            setSelectedItems(selectedItems.filter(id => id !== key));
        }
    };
    /**
     * Select all for bulk update but ignore dynamic quantities
     */
    const handleSelectAll = e => {
        if (e.target.checked) {
            // Get all master products
            const selected = _.map(master_products)
                .map(line_items => line_items[0].product.master_product)
                .filter(id => !dynamicProducts.includes(id)); // remove dynamic products
            setSelectedItems(selected);
        }
        else {
            setSelectedItems([]);
        }
    };
    const master_products = _.groupBy(lineItems, item => {
        return item.product.master_product;
    });
    const isIndeterminate = selectedItems.length > 0 && selectedItems.length < _.size(lineItems);
    const content = QuoteStore.masterProductsByPosition.map((item, index) => {
        const isSelected = selectedItems.includes(item.master_product.id);
        const isDynamic = dynamicProducts.includes(item.master_product.id);
        const disabledStyle = isDynamic ? 'pointer-events-none opacity-50' : '';
        return (React.createElement("div", { className: `fex flex-row border my-4 p-2 ${disabledStyle}`, key: index },
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("span", { style: {
                            float: 'left',
                            marginRight: 10,
                        } },
                        React.createElement(Checkbox, { checked: isSelected, onChange: e => {
                                handleSelect(item.master_product.id, e.target.checked);
                            } })),
                    React.createElement("span", { className: 'font-bold' }, item.master_product.name))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" }, item.master_product.step_file ? (React.createElement("div", null,
                        React.createElement("div", null,
                            item.master_product.step_file?.filename,
                            " ",
                            item.master_product.step_file.id),
                        React.createElement(Upload.Dragger, { style: {
                                maxWidth: 300,
                                margin: 'auto',
                            }, showUploadList: false, beforeUpload: (file) => {
                                setLoadingUpload(true);
                                QuoteStore.upload3dRedactedFile(item.master_product.step_file.id, file).finally(() => {
                                    QuoteStore.getMasterProducts(quote_id);
                                    setLoadingUpload(false);
                                });
                                return false;
                            } }, item.master_product.step_file.redacted_file ? (React.createElement("div", { className: "flex flex-row justify-center pl-2 pr-2" },
                            React.createElement("div", { className: "flex flex-col" },
                                React.createElement("span", { style: { fontSize: '13px' } },
                                    React.createElement(LegacyIcon, { className: "mr-1", type: 'check', style: {
                                            color: 'green',
                                        } }),
                                    item.master_product.step_file.redacted_file
                                        ?.split('/')
                                        ?.pop()
                                        ?.split('?')[0]),
                                React.createElement("span", { className: "text-green-600 ml-1 font-extrabold" }, "Uploaded!")),
                            React.createElement("div", null,
                                React.createElement(Button, { title: 'Remove redacted file', className: 'link text-red-600', onClick: e => {
                                        e.stopPropagation();
                                        setLoadingUpload(true);
                                        QuoteStore.removeRedactedFile(item.master_product.drawing_file.id, {
                                            master_product: item.master_product.id,
                                            file_type: '3d',
                                        }).finally(() => {
                                            QuoteStore.getMasterProducts(quote_id);
                                            setLoadingUpload(false);
                                        });
                                    } },
                                    React.createElement(DeleteOutlined, null))))) : (React.createElement("p", { className: "ant-upload-text", style: { color: 'red' } }, "Drag a redacted 3D file"))))) : null)),
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" }, item.master_product.drawing_file ? (React.createElement(React.Fragment, null,
                        React.createElement("div", null, item.master_product.drawing_file?.filename),
                        React.createElement(Upload.Dragger, { style: {
                                maxWidth: 300,
                                margin: 'auto',
                            }, showUploadList: false, beforeUpload: file => {
                                setLoadingUpload(true);
                                QuoteStore.upload2dRedactedFile(item.master_product.drawing_file.id, file).finally(() => {
                                    setLoadingUpload(false);
                                    QuoteStore.getMasterProducts(quote_id);
                                });
                                return false;
                            } }, item.master_product.drawing_file.redacted_file ? (React.createElement("div", { className: "flex flex-row justify-center pl-2 pr-2" },
                            React.createElement("div", { className: "flex flex-col" },
                                React.createElement("span", { style: { fontSize: '13px' } },
                                    React.createElement(LegacyIcon, { className: "mr-1", type: 'check', style: {
                                            color: 'green',
                                        } }),
                                    item.master_product.drawing_file.redacted_file
                                        ?.split('/')
                                        ?.pop()
                                        ?.split('?')[0]),
                                React.createElement("span", { className: "text-green-600 ml-1 font-extrabold" }, "Uploaded!")),
                            React.createElement("div", null,
                                React.createElement(Button, { title: 'Remove redacted file', className: 'link text-red-600', onClick: e => {
                                        e.stopPropagation();
                                        setLoadingUpload(true);
                                        QuoteStore.removeRedactedFile(item.master_product.drawing_file.id, {
                                            master_product: item.master_product.id,
                                            file_type: '2d',
                                        }).finally(() => {
                                            QuoteStore.getMasterProducts(quote_id);
                                            setLoadingUpload(false);
                                        });
                                    } },
                                    React.createElement(DeleteOutlined, null))))) : (React.createElement("p", { className: "ant-upload-text", style: { color: 'red' } }, "Drag a redacted 2D file"))))) : null)))));
    });
    return (React.createElement(Form, { className: 'm-4', layout: 'vertical' },
        React.createElement(Form.Item, { label: 'RFQ Type', className: 'my-5' },
            React.createElement(Radio.Group, { onChange: e => {
                    setRFQType(e.target.value);
                }, value: rfqType },
                React.createElement(Radio, { value: 'domestic' }, "Domestic Vendor RFQ"),
                React.createElement(Radio, { value: 'foreign' }, "Foreign Vendor RFQ"))),
        React.createElement(Form.Item, { label: 'Redacted Files', className: 'my-5' },
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("div", null, QuoteStore.masterProductsByPosition?.length > 0 && (React.createElement(Checkbox, { checked: selectedItems.length > 0, style: {
                        marginLeft: 25,
                    }, indeterminate: isIndeterminate, onChange: handleSelectAll }, "Select All")))),
            React.createElement(Spin, { spinning: loadingUpload }, content)),
        React.createElement(Form.Item, { label: 'Notes for Vendor', className: 'my-5' },
            React.createElement(Input.TextArea, { placeholder: 'Notes: Optional', rows: 4, onChange: event => {
                    setNotes(event.target.value);
                } })),
        React.createElement("div", { className: 'my-5 text-center' },
            React.createElement(Button, { loading: loading, type: 'primary', onClick: () => {
                    onSubmitForm(rfqType, notes, selectedItems);
                } }, "Submit"))));
});
