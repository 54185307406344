import React, { useEffect, useRef, useState } from 'react';
import { ErrorRender, Price } from '@partsbadger/library';
import { CloseOutlined, CopyOutlined, DownOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Badge, Button, Dropdown, Menu, message, Modal, notification, Table, Tag, Tooltip } from 'antd';
import { Cell } from './Cell';
import { QuoteStore } from '../../Stores';
import Formula26Safe from './Formula26Safe';
import VendorQuotedPriceForm from '../../Forms/Quote/VendorQuotedPriceForm';
import { TooltipFlag } from '../TooltipFlag';
const LineItems = props => {
    const [editingKey, setEditingKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [dataSource, setDataSource] = useState([]);
    const [showVendorQuoteForm, setShowVendorQuoteForm] = useState(null);
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current) {
                // ANTD Select Option are render outside the component
                try {
                    if (event.target?.className?.includes('ant-select-dropdown-menu-item')) {
                        return;
                    }
                    //for antd4
                    if (event.target?.className?.includes('ant-select-item-option-content')) {
                        return;
                    }
                    if (event.target?.className?.includes('dropdown-item-helper')) {
                        return;
                    }
                    if (!ref.current.contains(event.target)) {
                        setEditingKey(null);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        };
        if (editingKey) {
            document.addEventListener('click', handleClickOutside, true);
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
            };
        }
    }, [editingKey]);
    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.code === 'Escape') {
                event.preventDefault();
                setEditingKey(null);
            }
        });
    }, []);
    useEffect(() => {
        setLoading(true);
        setDataSource(props.line_items);
        setLoading(false);
        window.addEventListener('resize', () => {
            setInnerWidth(window.innerWidth);
        });
    }, [props.line_items]);
    const quote_id = QuoteStore.quote?.id;
    const isEditing = record => record.id === editingKey;
    const handleEdit = (id) => {
        if (props.editable) {
            if (QuoteStore.inProgress) {
                setEditingKey(id);
            }
            else {
                message.error('Please switch to In Progress to allow modifications', 4);
            }
        }
    };
    let columns = [
        {
            title: 'Manufact',
            dataIndex: 'manufacturing_type',
            width: 100,
            editable: true,
            render: (text, record) => (React.createElement(React.Fragment, null,
                React.createElement("div", { style: { fontSize: 12, textAlign: 'center' } },
                    record.manufacturing_type,
                    React.createElement("br", null),
                    record.debug?.face_directions ? 'FD' : 'BF:',
                    ' ',
                    record.debug?.face_directions?.toFixed(2) ?? record.debug?.badger?.toFixed(2),
                    React.createElement("br", null),
                    record.product.step_file?.step_file_analysis_data?.face_directions ? 'FD2' : '',
                    ' ',
                    record.product.step_file?.step_file_analysis_data?.face_directions?.toFixed(2) ??
                        record.product.step_file?.step_file_analysis_data?.face_directions?.toFixed(2),
                    React.createElement(TooltipFlag, { field_name: 'manufacturing_type', item_id: record.id })))),
        },
        {
            title: 'Formula',
            dataIndex: 'formula',
            width: 100,
            editable: true,
            render: (text, record) => (React.createElement(React.Fragment, null, record.formula +
                Formula26Safe(record.formula, props.master_product_id, record.product.material?.name, null, null))),
        },
        {
            title: 'Material',
            dataIndex: 'product__material',
            width: 150,
            editable: true,
            render: (text, record) => (React.createElement("div", null,
                React.createElement("span", null,
                    record.product.material?.name,
                    " ",
                    React.createElement(TooltipFlag, { field_name: 'material', item_id: record.id })),
                record.product.material?.name.toLowerCase() === 'custom' ? (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    " ",
                    record.product.custom_material)) : (''),
                record.material_group_lt_adjustment > 0 ? (React.createElement("span", { style: { color: 'indianred' } },
                    React.createElement("br", null),
                    "LT: +",
                    record.material_group_lt_adjustment,
                    " days")) : (''))),
        },
        {
            title: 'FAI Required',
            dataIndex: 'is_fai_required',
            width: 100,
            editable: true,
            render: (text, record) => React.createElement("span", null, record.is_fai_required ? 'Yes' : 'No'),
        },
        {
            title: React.createElement(Tooltip, { title: "Parts Badger Production Candidate" }, "PB Prod. Candidate"),
            dataIndex: 'is_prod_candidate',
            width: 100,
            editable: false,
            render: (text, record) => (React.createElement("span", null, record.is_prod_candidate ? React.createElement(Tag, { color: 'blue' }, "Yes") : React.createElement(Tag, { color: 'red' }, "No"))),
        },
        {
            title: 'PartsBadger Production',
            dataIndex: 'partsbadger_production_method',
            width: 150,
            editable: true,
            render: (text, record) => (React.createElement("div", { style: { display: 'flex' } },
                React.createElement("div", null, record.partsbadger_production_method),
                React.createElement("div", null, record.partsbadger_production_method != null &&
                    record.partsbadger_production_method != '' &&
                    record.partsbadger_production_method != 'No' && (React.createElement(Tooltip, { title: "Add a note with information that may be helpful for PartsBadger\n                                        Production. This can include quoted materials, custom tooling, etc. Notes will be\n                                        visible in the ERP." },
                    React.createElement(Button, { size: 'small', type: 'link', disabled: QuoteStore.quote?.completed, onClick: event => {
                            setLoading(true),
                                event.stopPropagation(),
                                props.addPartsbadgerProductionQuotingNotes &&
                                    props.addPartsbadgerProductionQuotingNotes(record.id, record.partsbadger_production_quoting_notes);
                        } }, record.partsbadger_production_quoting_notes != null &&
                        record.partsbadger_production_quoting_notes !== '' ? (React.createElement("span", null, "Edit Note")) : (React.createElement("span", null, "Add Note")))))))),
        },
        {
            title: 'Reinforcement',
            dataIndex: 'reinforcement',
            width: 100,
            editable: true,
            render: (text, record) => (record?.manufacturing_type === '3D PRINTING' ? text : null),
        },
        {
            title: 'Reinforcement Percent',
            dataIndex: 'reinforcement_percent',
            width: 100,
            editable: true,
            render: (text, record) => (record?.manufacturing_type === '3D PRINTING' ? text : null),
        },
        {
            title: 'Design Time',
            dataIndex: 'product__design_time',
            width: 120,
            editable: true,
            // render: (text, record) => parseFloat(text),
        },
        //========== Columns Domestic
        {
            title: 'Setup Hours',
            dataIndex: 'setup_hours',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Programming Hours',
            dataIndex: 'programming_hours',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'RunTime',
            dataIndex: 'runtime',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Setup',
            dataIndex: 'setup',
            width: 100,
            editable: false,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Program',
            dataIndex: 'program',
            width: 100,
            editable: false,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'RT',
            dataIndex: 'rt',
            width: 100,
            editable: false,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Total Material',
            dataIndex: 'total_material',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Coating 1',
            dataIndex: 'coating1',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Coating 2',
            dataIndex: 'coating2',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Extra 1',
            dataIndex: 'extra1',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        {
            title: 'Extra 2',
            dataIndex: 'extra2',
            width: 100,
            editable: true,
            render: (text, record) => (record?.formula === 'Domestic' ? text : null),
        },
        //========== End columns Domestic
        {
            title: 'Sides',
            dataIndex: 'product__sides',
            width: 100,
            editable: true,
            max: 7,
        },
        {
            title: 'Finishes',
            dataIndex: 'product__finishes',
            width: 200,
            editable: true,
            render: (text, record) => (React.createElement("div", null,
                React.createElement("div", null,
                    record.product__finishes?.label,
                    React.createElement(TooltipFlag, { field_name: 'finish', item_id: record.id })),
                record.product.finish?.name.toLowerCase() === '* custom' ? record.product.custom_finish : '')),
        },
        {
            title: 'Coatings',
            dataIndex: 'product__coatings',
            width: 200,
            editable: true,
            render: (text, record) => (React.createElement("div", null,
                React.createElement("div", null,
                    record.product__coatings?.label,
                    " ",
                    React.createElement(TooltipFlag, { field_name: 'coating', item_id: record.id })),
                record.product.coating?.name.toLowerCase() === '* custom' ? record.product.custom_coating : '',
                record.product__coatings?.label != 'None' &&
                    record.product__coatings?.label != undefined &&
                    record.partsbadger_production_method != 'None' &&
                    record.partsbadger_production != false && (React.createElement(Tooltip, { title: "Click here to create a variation without coating" },
                    React.createElement("a", { onClick: event => {
                            setLoading(true),
                                event.stopPropagation(),
                                QuoteStore.addNoCoatingVariation(record.id)
                                    .then(() => {
                                    setLoading(false);
                                })
                                    .catch(err => {
                                    setLoading(false);
                                    notification.error({
                                        key: 'form-errors',
                                        duration: 8,
                                        message: 'Error',
                                        description: React.createElement(ErrorRender, { error: err }),
                                        placement: 'top',
                                        maxCount: 1,
                                    });
                                });
                        } }, "Add \"No Coating\" Variation"))))),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 80,
            editable: true,
            render: (text, record) => (React.createElement("div", null,
                record.quantity,
                React.createElement(TooltipFlag, { field_name: 'quantity', item_id: record.id }))),
        },
        {
            title: 'Qty Range',
            dataIndex: 'quantity_range',
            width: 100,
            editable: true,
            render: (text, record) => {
                return (React.createElement("span", null,
                    record.quantity_range?.lower,
                    " - ",
                    record.quantity_range?.upper));
            },
        },
        {
            title: 'LT',
            dataIndex: 'lead_time',
            width: 100,
            editable: true,
        },
        {
            title: 'LT Range',
            dataIndex: 'lead_time_range',
            width: 100,
            editable: true,
            render: (text, record) => {
                return (React.createElement("span", null,
                    record.lead_time_range?.lower,
                    " - ",
                    record.lead_time_range?.upper));
            },
        },
        {
            title: 'Adjust',
            dataIndex: 'manual_adjustment',
            width: 100,
            editable: true,
        },
        {
            title: 'Vendor Adjust',
            dataIndex: 'vendor_price_adjust',
            width: 100,
            editable: true,
        },
        {
            title: 'Vendor (Quoter)',
            dataIndex: 'vendor_name',
            width: 100,
            editable: false,
            render: (text, record) => {
                return (React.createElement("div", null,
                    React.createElement("div", null, record.vendor_name)));
            },
        },
        {
            title: 'Vendor Quoted Price',
            dataIndex: 'vendor_quoted_price',
            width: 100,
            editable: false,
        },
        {
            title: 'Vendor Price',
            dataIndex: 'vendor_price',
            width: 100,
            editable: false,
            render: (text, record) => {
                return React.createElement(Price, { value: record.vendor_price });
            },
        },
        {
            title: 'Material Price',
            dataIndex: 'material_price',
            width: 100,
            editable: false,
            render: (text, record) => {
                if (!record.fixed_unit_price)
                    return React.createElement(Price, { value: record.material_price });
            },
        },
        {
            title: 'Work Price',
            dataIndex: 'work_price',
            width: 100,
            editable: false,
            render: (text, record) => {
                return React.createElement(Price, { value: record.work_price });
            },
        },
        {
            title: 'Shipping',
            dataIndex: 'shipping_cost',
            width: 100,
            editable: true,
            render: (text, record) => {
                return React.createElement(Price, { value: record.shipping_cost });
            },
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            width: 100,
            editable: true,
            render: (t, record) => {
                return (React.createElement("span", { style: { backgroundColor: '#258125', color: '#fff', padding: '5px', width: '100%' } },
                    React.createElement(Price, { value: record.unit_price })));
            },
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            width: 100,
            editable: true,
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            width: 100,
            editable: false,
            render: (text, record) => {
                const expired = record.is_expired && (React.createElement("span", null,
                    React.createElement(Badge, { count: 'Expired' })));
                return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } },
                    React.createElement("span", null,
                        React.createElement(Price, { style: { display: 'flex', justifyContent: 'center' }, value: record?.total_price - record?.discount })),
                    React.createElement("span", { style: { display: 'flex', fontSize: '11px', justifyContent: 'center' } }, expired)));
            },
            canHide: false,
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            width: 120,
            fixed: true,
            render: (text, record) => {
                const menu = (React.createElement(Menu, null,
                    React.createElement(Menu.Item, { onClick: () => {
                            QuoteStore.duplicateLineItem(record.id);
                        } },
                        React.createElement(CopyOutlined, null),
                        " Duplicate Line"),
                    React.createElement(Menu.Item, { onClick: () => {
                            const payload = {
                                quoted_products: [record.id],
                            };
                            QuoteStore.recalculatePrices(payload).catch(err => {
                                notification.error({
                                    key: 'form-errors',
                                    duration: 8,
                                    message: 'Error',
                                    description: React.createElement(ErrorRender, { error: err }),
                                    placement: 'top',
                                    maxCount: 1,
                                });
                            });
                        } },
                        React.createElement(SyncOutlined, null),
                        " Recalculate Prices"),
                    React.createElement(Menu.Item, { onClick: () => QuoteStore.deleteLineItem(record.id) },
                        React.createElement(CloseOutlined, null),
                        " Delete Line"),
                    React.createElement(Menu.Item, { onClick: () => {
                            setShowVendorQuoteForm({
                                material: record.product.material?.name ?? '',
                                finish: record.product?.finish?.name ?? '',
                                coating: record.product?.coating?.name ?? '',
                                acceptance_date: '',
                                quantity: record.quantity,
                                vendor_price: record.vendor_price,
                                lead_time: record.lead_time,
                                vendor_notes: record.vendor_notes,
                                quote_line_item: record.id,
                                vendor_name: record.vendor_name,
                                vendor_quoted_price: record.vendor_quoted_price,
                            });
                        } },
                        React.createElement(EditOutlined, null),
                        " Vendor Quote")));
                return (React.createElement("div", null,
                    React.createElement(Dropdown, { overlay: menu },
                        React.createElement("span", { className: 'link' },
                            "Actions ",
                            React.createElement(DownOutlined, null)))));
            },
        },
    ];
    const components = {
        body: {
            cell: Cell,
        },
    };
    // domestic columns
    const domesticColumns = [
        'setup_hours',
        'programming_hours',
        'runtime',
        'setup',
        'program',
        'rt',
        'total_material',
        'coating1',
        'coating2',
        'extra1',
        'extra2',
    ];
    let hiddenColumns = ['quantity_range', 'lead_time_range'];
    const has_3d_printing = dataSource.filter(r => r.manufacturing_type === '3D PRINTING');
    const has_vendor_quotes = dataSource.filter(r => {
        return parseFloat(r.vendor_quoted_price) > 0;
    }).length;
    const isDomesticFormula = dataSource.filter(r => r.formula == 'Domestic').length > 0;
    const hasOnlyFormula30 = dataSource.filter(r => r.formula == '3.0').length == dataSource.length;
    const hasA2DFormula = dataSource.filter(r => r.formula && (r.formula?.startsWith('2') || r.formula?.startsWith('F2'))).length > 0;
    if (!isDomesticFormula)
        hiddenColumns = hiddenColumns.concat(domesticColumns);
    if (!hasA2DFormula) {
        hiddenColumns.push('product__sides');
    }
    if (isDomesticFormula) {
        hiddenColumns.push('product__sides');
        hiddenColumns.push('vendor_price_adjust');
        hiddenColumns.push('vendor_price');
        hiddenColumns.push('material_price');
        hiddenColumns.push('shipping_cost');
    }
    if (hasOnlyFormula30 && !has_vendor_quotes) {
        hiddenColumns.push('vendor_price_adjust');
    }
    if (!has_vendor_quotes) {
        hiddenColumns.push('vendor_name');
        hiddenColumns.push('vendor_quoted_price');
    }
    else {
        hiddenColumns.push('vendor_price_adjust');
        hiddenColumns.push('vendor_price');
    }
    if (!has_3d_printing.length) {
        hiddenColumns.push('reinforcement');
        hiddenColumns.push('reinforcement_percent');
        hiddenColumns.push('reinforcement_percent');
        hiddenColumns.push('product__design_time');
    }
    if (!QuoteStore.inProgress) {
        hiddenColumns.push('operation');
    }
    if (!props.editable) {
        hiddenColumns.push('operation');
    }
    columns = columns
        .filter(c => {
        if (c.dataIndex) {
            return hiddenColumns.indexOf(c.dataIndex) === -1;
        }
    })
        .map(col => {
        if (col.fixed) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            col.fixed = innerWidth <= 2200 ? 'right' : false;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                dataIndex: col.dataIndex,
                width: col.width,
                editable: col?.editable,
                editing: isEditing(record),
                handleEdit: handleEdit,
                lineItems: dataSource,
            }),
        };
    });
    return (React.createElement("div", { className: 'line-items-wrapper', ref: ref },
        React.createElement(Table, { rowKey: 'id', size: "small", loading: loading, components: components, dataSource: dataSource.sort((a, b) => a.position - b.position), className: 'variations', rowClassName: () => 'editable-row', onRow: record => {
                return {
                    title: `${record.id}`,
                    formula: record.formula,
                    position: `-${record.position}`,
                    style: {
                        backgroundColor: record.safe_auto_quote ? '#a9ffa94d' : 'inherit',
                    },
                };
            }, bordered: true, pagination: false, rowSelection: {
                selectedRowKeys: props.selectedQuotedProducts,
                onChange: selectedRowKeys => {
                    props.handleRowSelections && props.handleRowSelections(selectedRowKeys);
                },
            }, columns: columns, footer: () => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Button, { disabled: QuoteStore.quote?.completed, size: 'small', onClick: () => {
                            if (quote_id) {
                                setLoading(true);
                                QuoteStore.duplicateQuotedProduct(props.master_product_id).finally(() => setLoading(false));
                            }
                        }, icon: React.createElement(LegacyIcon, { type: 'copy' }) }, "Duplicate Product"),
                    ' ',
                    React.createElement(Button, { disabled: QuoteStore.quote?.completed, size: 'small', onClick: () => {
                            if (quote_id) {
                                QuoteStore.createLineItems(quote_id, {
                                    master_product: props.master_product_id,
                                });
                            }
                        }, icon: React.createElement(LegacyIcon, { type: 'plus' }) }, "Variation")));
            }, scroll: { x: '1200' } }),
        showVendorQuoteForm != null && (React.createElement(Modal, { title: 'Add or Edit Vendor Quote', bodyStyle: {
                height: 330,
            }, visible: true, onCancel: () => {
                setShowVendorQuoteForm(null);
            }, footer: [] },
            React.createElement(VendorQuotedPriceForm, { vendor_quoted_unit_price: showVendorQuoteForm.vendor_quoted_price, vendor_name: showVendorQuoteForm.vendor_name, vendor_notes: showVendorQuoteForm.vendor_notes, handleSave: async ({ vendor_name, vendor_quoted_unit_price, vendor_notes }) => {
                    const payload = {
                        id: showVendorQuoteForm.quote_line_item,
                        material: showVendorQuoteForm.material,
                        finish: showVendorQuoteForm.finish,
                        coating: showVendorQuoteForm.coating,
                        acceptance_date: new Date(),
                        vendor_name: vendor_name,
                        quantity: showVendorQuoteForm.quantity,
                        vendor_price: vendor_quoted_unit_price,
                        lead_time: showVendorQuoteForm.lead_time,
                        vendor_notes: vendor_notes,
                    };
                    await QuoteStore.addVendorQuote(quote_id, payload).catch(e => {
                        notification.error({
                            message: 'Error',
                            description: React.createElement(ErrorRender, { error: e }),
                        });
                    });
                    if (quote_id) {
                        QuoteStore.getLineItems(quote_id);
                        QuoteStore.getMasterProducts(quote_id);
                        QuoteStore.getQuote(quote_id);
                    }
                    setShowVendorQuoteForm(null);
                } })))));
};
export default Form.create()(LineItems);
