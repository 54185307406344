import React from 'react';
import { Button, Checkbox, InputNumber, Popconfirm, Table } from 'antd';
import { IPartQualityItem } from '@partsbadger/types';

type Item = {
    id: number;
    name: string;
    reason?: string[];
    quantity: number;
    quantity_ordered: number;
};

type Props = {
    handleUpdateItem: (id: number, key: string, value: any) => void;
    handleRemove: (id: number, issue_quantity: number) => void;
    items: Item[];
    loading: boolean;
    itemsWithZeroQuantity: number[];
};

export const ReworkTable = (props: Props) => {
    const columns = [
        {
            title: 'Parts Name',
            key: 'parts_name',
            align: 'center',
            render: (r: Item) => <>{r.name}</>,
        },
        ...(props.items[0]?.reason
            ? [
                  {
                      title: 'Reason',
                      key: 'reason',
                      align: 'center',
                      render: (r: Item) => <>{r.reason?.join(', ')}</>,
                  },
              ]
            : []),
        {
            title: 'Quantity Rework',
            key: 'quantity_rework',
            align: 'center',
            render: (r: Item) => (
                <div className={'flex flex-column items-center'}>
                    <InputNumber
                        min={1}
                        max={r.quantity_ordered}
                        value={r.quantity}
                        onChange={value => props.handleUpdateItem(r.id, 'quantity', value)}
                    />
                    {props.itemsWithZeroQuantity.includes(r.id) && (
                        <span className={'text-red-500'}>Quantity required</span>
                    )}
                    <span className={'text-gray-500'}>Qty Ordered: {r.quantity_ordered}</span>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'remove',
            render: (r: Item) => (
                <Button size="small" type="link" onClick={() => props.handleRemove(r.id, r.quantity)}>
                    Remove
                </Button>
            ),
        },
    ];

    return (
        <Table
            //@ts-ignore
            columns={columns}
            dataSource={props.items}
            loading={props.loading}
            pagination={false}
        />
    );
};
