import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { ISalesOrderListStaff } from '@partsbadger/types';
import { useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import { ColumnProps } from 'antd/es/table';
import { DateRender, Price } from '@partsbadger/library';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import ActionMenu from '../TableActions/TableActions';
import { BadgerTable, BadgerSearch } from '../badger-ui';

const AccountSalesOrders = (props: { account_id: number }) => {
    const { account_id } = props;
    const history = useHistory();

    const searchParams = useQueryParameters();

    const page = parseInt(searchParams.get('page') ?? '1');
    const search = searchParams.get('search') ?? '';

    useEffect(() => {
        SalesOrderStore.getByAccount(account_id, {
            limit: 10,
            search: search,
            page: page,
        });
        return () => {};
    }, [account_id, search, page]);

    useEffect(() => {
        return () => {};
    }, [account_id]);

    const columns: ColumnProps<ISalesOrderListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            disabled={record?.status == 'Draft'}
                            onClick={() => history.push(`/new-sales-order-detail/${record?.id}/`)}
                        >
                            {record?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return <div>{record.status}</div>;
            },
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            render: (text, record) => {
                return <div>{record.contact?.fullname}</div>;
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                const actions =
                    record.status === 'Draft'
                        ? [
                              {
                                  key: 'continue-submitting',
                                  icon: <EditOutlined />,
                                  text: 'Continue Submitting',

                                  // link: `/get-quote/?uid/${record.uid}/`,
                              },
                          ]
                        : [
                              {
                                  key: 'view-in-zoho',
                                  icon: <FilePdfOutlined />,
                                  text: 'View in Zoho',
                                  link: record.zoho_id ? `${ZOHO_BASE_URL}/Sales_Orders/${record.zoho_id}` : undefined,
                              },
                          ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    const { entities, pagination, loading } = SalesOrderStore;

    return (
        <Row>
            <Col>
                <BadgerSearch />
            </Col>
            <Col span={24}>
                <BadgerTable<ISalesOrderListStaff>
                    rowKey={'id'}
                    dataSource={entities}
                    loading={loading}
                    columns={columns}
                    pagination={{
                        defaultPageSize: pagination.defaultPageSize,
                        pageSize: pagination.defaultPageSize,
                        totalRecords: pagination.total,
                    }}
                ></BadgerTable>
            </Col>
        </Row>
    );
};

export default observer(AccountSalesOrders);
