import React, { useState } from 'react';
import { ALLOWED_FILES } from './FileExtensions';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
const UploadFile = React.forwardRef((props, ref) => {
    const [countFiles, setCountFiles] = useState(0);
    const { multiple, onChange, onUpload, onRemove, value, fileType, renderAs, maxCount, maxSize } = props;
    const token = localStorage.getItem('token');
    const handleChange = (info) => {
        const { file } = info;
        let fileList = info.fileList;
        if (maxSize !== undefined && file?.size) {
            const isLt2M = file?.size / 1024 / 1024 <= maxSize;
            if (!isLt2M) {
                fileList = [];
            }
        }
        if (!multiple) {
            fileList = info.fileList.slice(-1);
        }
        if (onChange) {
            onChange(fileList);
        }
        if (info.file.status === 'done' && !!onUpload) {
            onUpload(info.file);
        }
        if (info.file.status === 'removed' && !!onRemove) {
            onRemove(file);
        }
    };
    let url = `customer/files/`;
    let allowed_files = '';
    if (!props.accept) {
        switch (fileType) {
            case '3D':
                url = `customer/step-files/`;
                allowed_files = ALLOWED_FILES.allowed_3d.map(r => `.${r.toLowerCase()}`).toString();
                break;
            case '2D':
                url = `customer/drawing-files/`;
                allowed_files = ALLOWED_FILES.allowed_2d.map(r => `.${r.toLowerCase()}`).toString();
                break;
        }
    }
    else {
        switch (fileType) {
            case '3D':
                url = `customer/step-files/`;
                allowed_files = props.accept.map(r => `.${r.toLowerCase()}`).toString();
                break;
            case '2D':
                url = `customer/drawing-files/`;
                allowed_files = props.accept.map(r => `.${r.toLowerCase()}`).toString();
                break;
            default:
                allowed_files = props.accept.map(r => `.${r.toLowerCase()}`).toString();
                break;
        }
    }
    let otherProps = {};
    if (value) {
        otherProps = {
            fileList: value,
        };
    }
    const headers = {};
    if (token) {
        headers['Authorization'] = `Token ${token}`;
    }
    const showUploadList = props.showUploadList;
    if (renderAs == 'button') {
        return (React.createElement(Upload, { className: props.className, name: "file", action: BASE_URL + '/' + url, headers: headers, accept: allowed_files, ...otherProps, showUploadList: showUploadList, onChange: handleChange, style: props.styles, beforeUpload: file => {
                if (props.accept) {
                    const type = file.name.split('.').pop();
                    const file_type = props.accept.filter(file => file === type?.toLowerCase());
                    if (type?.toLowerCase() === file_type[0])
                        return true;
                    message.error('The file type is not allowed, try again.', 3);
                    return false;
                }
                return true;
            } }, props.children ?? (React.createElement(Button, { size: 'small' },
            React.createElement(UploadOutlined, null),
            " Upload"))));
    }
    return (React.createElement(Upload.Dragger, { className: props.className, name: "file", action: BASE_URL + '/' + url, headers: headers, multiple: true, accept: allowed_files, ...otherProps, showUploadList: showUploadList, onChange: handleChange, style: props.styles, maxCount: maxCount, onRemove: () => {
            if (countFiles >= 1) {
                setCountFiles(countFiles - 1);
            }
        }, beforeUpload: (file, fileList) => {
            if (maxSize !== undefined && file?.size) {
                const isLt2M = file?.size / 1024 / 1024 <= maxSize;
                if (!isLt2M) {
                    message.error(`File must be smaller than ${maxSize}MB!`, 10);
                    return false;
                }
            }
            if (maxCount !== undefined) {
                if (countFiles >= maxCount) {
                    message.error('Please be aware that you can only upload a maximum of 10 files.', 3);
                    return false;
                }
                setCountFiles(countFiles + fileList.length);
            }
            if (props.accept) {
                const type = file.name.split('.').pop();
                const file_type = props.accept.filter(file => file === type?.toLowerCase());
                if (type?.toLowerCase() === file_type[0])
                    return true;
                message.error('The file type is not allowed, try again.', 3);
                return false;
            }
            return true;
        } }, props.children ??
        (fileType === '3D' ? (React.createElement("div", { className: "flex flex-col justify-center items-center", style: { height: '309px' } },
            React.createElement(UploadOutlined, { style: { fontSize: '96px', color: '#405764' } }),
            React.createElement("p", { className: "font-bold text-2xl  text-center pt-6", style: { width: '227px' } }, "Select STEP, STP or IGES File"),
            React.createElement("p", { className: "text-base font-bold ml-1  pt-10", style: { color: '#595959' } },
                "Drop your files here or ",
                React.createElement("span", { className: "text-red-600 font-bold" }, "Browse")))) : (React.createElement("div", { className: "flex justify-center items-center align-middle", style: { height: '87px' } },
            React.createElement(UploadOutlined, { style: { fontSize: '34px', color: '#405764' } }),
            React.createElement("span", { className: "text-base ml-1" }, "Click or drag files"))))));
});
/**
 * Map the response of the api to a local format to be used in UploadFile component
 * @param file
 * @returns {{uid: *, response: {id: *, url: *}, name: *, status: string}}
 */
export const normalize_files = (file) => {
    if (!file) {
        return;
    }
    return {
        uid: file.id,
        name: file.filename,
        status: 'done',
        response: {
            id: file.id,
            url: file.file,
        },
    };
};
UploadFile.displayName = 'UploadFile';
export default UploadFile;
